import './ButtonToggle.css';

const ButtonToggle = ({
  classes = '',
  disabled,
  id,
  onClick,
  text = '',
  title,
  toggled,
  uniqueRef = '',
  margin,
}) => {
  const style = {};

  if (margin) style.margin = margin;

  return (
    <div
      id={'button-toggle' + uniqueRef}
      className={`button-toggle ${disabled ? 'button-toggle-disabled' : ''} ${
        toggled ? 'button-toggle--toggled' : ''
      } ${classes}`}
      style={style}
    >
      <button
        disabled={disabled}
        id={id}
        onClick={onClick}
        title={title}
        type='button'
      >
        <span>{text}</span>
      </button>
    </div>
  );
};

export default ButtonToggle;
