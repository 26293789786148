import { BrowserRouter } from 'react-router-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';

import App from './App';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import smoothscroll from 'smoothscroll-polyfill';

import './css/index.css';

smoothscroll.polyfill();

const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 4000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.FADE,
};

var BaseIcon = function BaseIcon(_ref) {
  var color = _ref.color,
    _ref$pushRight = _ref.pushRight,
    pushRight = _ref$pushRight === undefined ? true : _ref$pushRight,
    children = _ref.children;
  return React.createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      width: '24',
      height: '24',
      viewBox: '0 0 24 24',
      fill: 'none',
      stroke: color,
      strokeWidth: '2',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      style: { marginRight: pushRight ? '20px' : '0', minWidth: 24 },
    },
    children
  );
};

var InfoIcon = function InfoIcon() {
  return React.createElement(
    BaseIcon,
    { color: '#2E9AFE', className: 'react-alert-class' },
    React.createElement('circle', {
      cx: '12',
      cy: '12',
      r: '10',
      className: 'react-alert-class',
    }),
    React.createElement('line', {
      x1: '12',
      y1: '16',
      x2: '12',
      y2: '12',
      className: 'react-alert-class',
    }),
    React.createElement('line', {
      x1: '12',
      y1: '8',
      x2: '12',
      y2: '8',
      className: 'react-alert-class',
    })
  );
};

var SuccessIcon = function SuccessIcon() {
  return React.createElement(
    BaseIcon,
    { color: '#31B404', className: 'react-alert-class' },
    React.createElement('path', {
      d: 'M22 11.08V12a10 10 0 1 1-5.93-9.14',
      className: 'react-alert-class',
    }),
    React.createElement('polyline', {
      points: '22 4 12 14.01 9 11.01',
      className: 'react-alert-class',
    })
  );
};

var ErrorIcon = function ErrorIcon() {
  return React.createElement(
    BaseIcon,
    { color: '#FF0040', className: 'react-alert-class' },
    React.createElement('circle', {
      cx: '12',
      cy: '12',
      r: '10',
      className: 'react-alert-class',
    }),
    React.createElement('line', {
      x1: '12',
      y1: '8',
      x2: '12',
      y2: '12',
      className: 'react-alert-class',
    }),
    React.createElement('line', {
      x1: '12',
      y1: '16',
      x2: '12',
      y2: '16',
      className: 'react-alert-class',
    })
  );
};

var CloseIcon = function CloseIcon() {
  return React.createElement(
    BaseIcon,
    { color: '#FFFFFF', pushRight: false, className: 'react-alert-class' },
    React.createElement('line', {
      x1: '18',
      y1: '6',
      x2: '6',
      y2: '18',
      className: 'react-alert-class',
    }),
    React.createElement('line', {
      x1: '6',
      y1: '6',
      x2: '18',
      y2: '18',
      className: 'react-alert-class',
    })
  );
};

var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

var alertStyle = {
  backgroundColor: '#151515',
  color: 'white',
  padding: '10px',
  textTransform: 'uppercase',
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Arial',
  width: '300px',
  boxSizing: 'border-box',
};

var buttonStyle = {
  marginLeft: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#FFFFFF',
};

var AlertTemplate = function AlertTemplate(_ref) {
  var message = _ref.message,
    options = _ref.options,
    style = _ref.style,
    close = _ref.close;

  return React.createElement(
    'div',
    { style: _extends({}, alertStyle, style), className: 'react-alert-class' },
    options.type === 'info' && React.createElement(InfoIcon, null),
    options.type === 'success' && React.createElement(SuccessIcon, null),
    options.type === 'error' && React.createElement(ErrorIcon, null),
    React.createElement(
      'span',
      { style: { flex: 2 }, className: 'react-alert-class' },
      message
    ),
    React.createElement(
      'button',
      { onClick: close, style: buttonStyle, className: 'react-alert-class' },
      React.createElement(CloseIcon, null)
    )
  );
};

ReactDOM.render(
  <React.StrictMode>
    <link
      rel='stylesheet'
      type='text/css'
      href='//fonts.googleapis.com/css?family=Quicksand'
    />
    {/* <Auth0Provider
      domain=process.env.REACT_APP_AUTH0_DOMAIN
      clientId=process.env.REACT_APP_AUTH0_CLIENT_ID
      redirectUri={'http://localhost:3000/dashboard/'}
    > */}
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <AlertProvider
          template={AlertTemplate}
          {...alertOptions}
          className='react-alert-class'
        >
          <App />
        </AlertProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
