import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { calculateTrendLine } from '../../../utils/reports';
import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiDataServiceReports from '../../../api/reports';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import GeneralBarChart from './GeneralBarChart';
import GeneralLineChart from './GeneralLineChart';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InfoText from '../../Elements/InfoText';
import InputStandard from '../../Elements/InputStandard';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import MainOnly from '../MainAndOptions/MainOnly';
import moment from 'moment';
import Panels from '../../Elements/Panels';
import PanelsControlBar from '../../Elements/PanelsControlBar';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import PortalPieChart from './PortalPieChart';
import Text from '../../Elements/Text';
import useViewport from '../../../components/useViewport';

import './ReportsQa.css';

const ReportsQa = ({ demo }) => {
  const navigate = useNavigate();

  const { screenWidth } = useViewport();

  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);

  const [isLoading, setIsLoading] = useState(true);

  const [reportParamsChanged, setReportParamsChanged] = useState(false);
  const [reportParamsInvalid, setReportParamsInvalid] = useState(false);

  const [availableBars, setAvailableBars] = useState([
    { label: 'Marked', colour: '#D0D02Abf' },
    { label: 'Fed Back', colour: '#2ad0d0bf' },
  ]);

  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'weeks').add(1, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [feedbackTarget, setFeedbackTarget] = useState('');

  const [pieData, setPieData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [total, setTotal] = useState();

  const getReportData = async () => {
    try {
      setIsLoading(true);

      const params = [
        `start_date=${encodeURIComponent(startDate)}`,
        `end_date=${encodeURIComponent(endDate)}`,
        `feedback_target=${encodeURIComponent(feedbackTarget)}`,
      ];

      const { barData, lineData, pieData, total } =
        await apiDataServiceReports.getQa({
          params,
          portalId: demo ? 'demo' : portalId,
          token: demo ? undefined : await getAccessTokenSilently(),
          userId: demo ? 1 : userId,
        });

      setPieData(pieData);
      setBarData(barData);
      setLineData(lineData);
      setTotal(total);

      setReportParamsChanged(false);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setReportParamsInvalid(
      moment(startDate).format('YYYY-MM-DD') === 'Invalid date' ||
        moment(endDate).format('YYYY-MM-DD') === 'Invalid date'
    );
  }, [startDate, endDate]);

  useEffect(() => {
    if (!demo && !userFromDb.permissions?.includes('reports.qa')) {
      navigate(`/${demo ? 'demo' : portalId}/reports`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      setBreadcrumbs([
        [
          'Dashboard',
          'dashboard',
          '',
          `/${demo ? 'demo' : portalId}/dashboard`,
        ],
        ['Reports', 'chart-pie', '', `/${demo ? 'demo' : portalId}/reports`],
        ['QA', 'magnifying-glass'],
      ]);

      getReportData();
    }

    return () => {
      setPieData();
      setBarData();
      setLineData();
      setTotal();
      setReportParamsChanged();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const evalValues = lineData.map((item) => item['Evaluations']);

  return (
    <MainOnly>
      <Panels>
        <PanelsHeaderPrimary
          iconName='chart-pie'
          subIcon='circle-check'
          text='QA'
        />

        <PanelsControlBar>
          <ButtonStandardNoFill
            classes='grid-control-bar-button'
            disabled={reportParamsInvalid || isLoading}
            iconName='chart-pie'
            onClick={getReportData}
            text='Re-generate Report'
          />
        </PanelsControlBar>

        <PanelsSection margin='0 30px 20px'>
          <Text
            text='Evaluation and feedback volumes by QA'
            textAlign='center'
          />
        </PanelsSection>

        <FlexContainer flexWrap='wrap' margin='0 20px 25px'>
          <InputStandard
            classes={startDate ? 'focus-selected-param-date' : ''}
            direction='vertical'
            disabled={isLoading}
            id='qa-date-select-from'
            labelText='From'
            margin='10px 5px 0'
            onChange={(event) => {
              setStartDate(event.target.value);

              setReportParamsChanged(true);
            }}
            type='date'
            value={startDate || ''}
          />

          <InputStandard
            classes={endDate ? 'focus-selected-param-date' : ''}
            direction='vertical'
            disabled={isLoading}
            id='qa-date-select-to'
            labelText='To'
            margin='10px 5px 0'
            onChange={(event) => {
              setEndDate(event.target.value);

              setReportParamsChanged(true);
            }}
            type='date'
            value={endDate || ''}
          />

          <InputStandard
            classes={`${
              feedbackTarget || feedbackTarget === 0
                ? 'focus-selected-param-date'
                : ''
            } feedback-target-input`}
            direction='vertical'
            disabled={isLoading}
            id='qa-feedback-target-input'
            labelText='Feedback Target (days)'
            margin='10px 10px 0'
            onChange={(event) => {
              const newValue = event.target.value;
              const isNewValueBlank = newValue === '';

              setFeedbackTarget(isNewValueBlank ? '' : Math.abs(newValue));

              if (availableBars.length === 2 && !isNewValueBlank) {
                setAvailableBars([
                  { label: 'Marked', colour: '#D0D02Abf' },
                  { label: 'Fed Back', colour: '#2ad0d0bf' },
                  { label: 'Fed Back Within Target', colour: '#d02aa4a1' },
                ]);
              } else if (availableBars.length === 3 && isNewValueBlank) {
                setAvailableBars([
                  { label: 'Marked', colour: '#D0D02Abf' },
                  { label: 'Fed Back', colour: '#2ad0d0bf' },
                ]);
              }

              setReportParamsChanged(true);
            }}
            placeholder='-'
            type='number'
            value={feedbackTarget ?? ''}
          />
        </FlexContainer>

        {isLoading ? (
          <LoadingSpinnerNew />
        ) : reportParamsInvalid ? (
          <PanelsSection margin='0 30px 600px'>
            <Text text='Report parameters are invalid' textAlign='center' />
          </PanelsSection>
        ) : reportParamsChanged ? (
          <PanelsSection margin='0 30px 600px'>
            <Text
              text="Report parameters have changed, click the 'Re-generate Report' button"
              textAlign='center'
            />
          </PanelsSection>
        ) : (
          <>
            <PanelsSection alignSelf='center' margin='0 30px 30px'>
              <HeaderSecondary
                textAlign='center'
                text='Total Evaluations Marked'
              />

              <InfoText text={total} />
            </PanelsSection>

            <HeaderSecondary
              textAlign='center'
              marginBottom='20px'
              text='By Scorecard'
            />

            <div className='qa-report-pie-container'>
              <PortalPieChart
                alternateInterval={2}
                barChartMarginBottom={30}
                barChartXLabelOffset={-20}
                chartKey='qa-by-scorecard-pie'
                mutatedReportRows={pieData.map((dataItem) => {
                  return [
                    {
                      col: 'col_1',
                      rawVal: dataItem.count,
                      mutatedVal: parseInt(dataItem.count),
                    },
                    {
                      col: 'col_2',
                      rawVal: dataItem.name,
                      mutatedVal: dataItem.name,
                    },
                  ];
                })}
                originalReportRows={pieData.map((dataItem) => ({
                  col_1: dataItem.count,
                  col_2: dataItem.name,
                }))}
                showPieValues={screenWidth > 370}
                showPieLabels={screenWidth > 500}
                shouldIncludeLegend={screenWidth <= 500}
                valueDataLabel='col_1'
                xDataKey='col_2'
              />
            </div>

            <HeaderSecondary textAlign='center' margin='20px 0' text='By QA' />

            <GeneralBarChart
              barData={availableBars}
              classes='qa-report-bar-chart'
              data={barData}
              xDataKey='QA'
              yLabel='Evaluations'
            />

            <HeaderSecondary
              margin='30px 0 10px'
              textAlign='center'
              text='QA Trend'
            />

            <GeneralLineChart
              classes='qa-report-line-chart'
              data={lineData}
              hideLegend
              lineDataKeys={['Evaluations']}
              trendLine={calculateTrendLine(lineData, 'Evaluations', [
                0,
                Math.max(...evalValues),
              ])}
              xDataKey='Created'
              yLabel='Evaluations'
              yLabelOffsets={[-90, 30]}
            />

            <InfoText className='chart-axis-label' text='Marked Date' />
          </>
        )}
      </Panels>
    </MainOnly>
  );
};

export default ReportsQa;
