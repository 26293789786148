import { useState } from 'react';

import CreatePortal from './CreatePortal';
import FlexContainer from '../../Elements/FlexContainer';
import GridOption from '../DataGrid/GridContent/GridOption';

import './AddPortal.css';

const AddPortal = ({ userFromDb }) => {
  const [showAddingNewPortal, setShowAddingNewPortal] = useState('');

  return (
    <FlexContainer
      alignItems='stretch'
      flexDirection='column'
      classes='grid-panel account-add-portal'
      padding={showAddingNewPortal ? '10px 5px 5px 5px' : '10px 5px'}
      marginBottom='0'
    >
      <GridOption
        headerText='Create new portal'
        showGridOption={showAddingNewPortal}
        setShowGridOption={setShowAddingNewPortal}
        gridOption='addingNewPortal'
      />

      {showAddingNewPortal && (
        <FlexContainer alignItems='stretch' flexDirection='column' margin='0'>
          <FlexContainer classes='grid-options-background' paddingBottom='20px'>
            <CreatePortal isAddingNew userFromDb={userFromDb} />
          </FlexContainer>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default AddPortal;
