import getNestEndIndex from './getNestEndIndex';
import { generateUniqueRef } from '../helpers';

const extractUrlQuery = (queryStr) => {
  const segmentArr = [];
  let condition = '';

  let i = 0;
  while (i < queryStr.length) {
    const isGroup = queryStr.slice(i, i + 2) === '((';

    if (isGroup) {
      const groupEndIndex = getNestEndIndex(queryStr, i);
      const groupContentsStartIndex = i + 2;
      const groupContentsEndIndex = groupEndIndex - 2;
      const groupContents = queryStr.slice(
        groupContentsStartIndex,
        groupContentsEndIndex
      );

      segmentArr.push(extractUrlQuery(groupContents));

      const potentialCondition = queryStr.slice(
        groupEndIndex,
        groupEndIndex + 2
      );

      if (!condition)
        condition =
          potentialCondition === '&&' || potentialCondition === ',,'
            ? potentialCondition
            : '';

      i = groupEndIndex + 2;
    } else {
      const nextGroupStartIndex = queryStr.indexOf('((', i);
      const endIndex =
        nextGroupStartIndex === -1 ? queryStr.length : nextGroupStartIndex - 2;

      if (!condition) {
        const containsAnd = queryStr.indexOf('&&') !== -1;
        const containsOr = queryStr.indexOf(',,') !== -1;

        if (endIndex !== queryStr.length)
          condition = queryStr.slice(endIndex, endIndex + 2);
        else if ((!containsAnd && !containsOr) || containsAnd) condition = '&&';
        else condition = ',,';
      }

      const clauseArray = queryStr.slice(i, endIndex).split(condition);

      segmentArr.push(
        ...clauseArray.map((clause) => {
          const equalsIndex = clause.lastIndexOf('==');
          const colonIndex = clause.indexOf('::');

          const column = clause.slice(0, equalsIndex);
          const operator = clause.slice(equalsIndex + 2, colonIndex);
          let criteria = clause.slice(colonIndex + 2);

          if (operator.includes('bet') || operator === 'prev')
            criteria = criteria.split('--');

          return {
            uniqueRef: generateUniqueRef(),
            clause: [column, operator, criteria],
          };
        })
      );

      i = endIndex + 2;
    }
  }

  if (condition === '&&') condition = 'and';
  else if (condition === ',,') condition = 'or';
  else return segmentArr[0];

  return { uniqueRef: generateUniqueRef(), [condition]: segmentArr };
};

export default extractUrlQuery;
