import { capitalise } from '../../../utils/helpers';
import { useState } from 'react';

import apiDataServiceEvaluations from '../../../api/evaluations';
import ButtonIcon from '../../Elements/ButtonIcon';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InfoText from '../../Elements/InfoText';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import moment from 'moment';
import PanelsSection from '../../Elements/PanelsSection';
import useViewport from '../../useViewport';

const AgentRankedSections = ({
  agent,
  alert,
  demo,
  getAccessTokenSilently,
  handleApiError,
  headerText,
  portalId,
  rankedSections,
  topOrBottom,
  userId,
}) => {
  const { screenWidth } = useViewport();

  const [isViewingBreakdown, setIsViewingBreakdown] = useState(false);
  const [isLoadingBreakdown, setIsLoadingBreakdown] = useState(false);
  const [breakdown, setBreakdown] = useState({});

  const handleClickViewBreakdown = async (rankedSection) => {
    const hasClickedPreviouslyViewing =
      breakdown.title === rankedSection.title &&
      breakdown.scorecard_name === rankedSection.scorecard_name;

    if (isViewingBreakdown === true && hasClickedPreviouslyViewing === true) {
      setIsViewingBreakdown(false);
    } else {
      setIsViewingBreakdown(true);

      if (hasClickedPreviouslyViewing) {
        return;
      } else {
        try {
          setIsLoadingBreakdown(true);

          const { evaluations } =
            await apiDataServiceEvaluations.getEvaluationsBySectionResults({
              agent: encodeURIComponent(agent),
              endDate: encodeURIComponent(moment().format('YYYY-MM-DD')),
              portalId: demo ? 'demo' : portalId,
              scorecardName: encodeURIComponent(rankedSection.scorecard_name),
              startDate: encodeURIComponent(
                moment()
                  .subtract(3, 'months')
                  .add(1, 'days')
                  .format('YYYY-MM-DD')
              ),
              title: encodeURIComponent(rankedSection.title),
              token: demo ? undefined : await getAccessTokenSilently(),
              type: topOrBottom === 'top' ? 'pass' : 'fail',
              userId: demo ? 1 : userId,
            });

          setBreakdown({
            title: rankedSection.title,
            scorecard_name: rankedSection.scorecard_name,
            evaluations,
          });
        } catch (error) {
          alert.error(handleApiError(error), { timeout: 10000 });
        } finally {
          setIsLoadingBreakdown(false);
        }
      }
    }
  };

  const showGrade = breakdown.evaluations?.some(
    (evaluation) => evaluation.pass_fail
  );

  const showBrag = breakdown.evaluations?.some((evaluation) => evaluation.brag);

  const tableData = [
    { code: 'id', name: 'ID' },
    { code: 'quality_score', name: 'Quality Score' },
    { code: 'pass_fail', name: 'Grade' },
    { code: 'brag', name: 'RAG' },
    { code: 'created_at', name: 'Created' },
  ].filter(
    (item) =>
      (item.code !== 'brag' || (screenWidth > 500 && showBrag)) &&
      (item.code !== 'pass_fail' || (screenWidth > 410 && showGrade)) &&
      (item.code !== 'created_at' || screenWidth > 350)
  );

  return (
    <PanelsSection
      alignItems='stretch'
      classes='agent-ranked-sections-panel'
      noMaxWidth
      typeStyle='type-2'
    >
      <HeaderSecondary
        marginBottom='15px'
        text={headerText}
        textAlign='center'
      />

      {rankedSections?.length ? (
        <>
          <FlexContainer
            classes={`agents-ranked-sections-table-container ${
              isViewingBreakdown
                ? `is-viewing-breakdown is-viewing-breakdown-${rankedSections.length}`
                : ''
            }`}
            flexDirection='column'
            alignItems='stretch'
            justifyContent='flex-start'
          >
            <table
              className={`report-results-table agents-ranked-sections-table ${
                topOrBottom === 'bottom' ? 'bottom-rank' : 'top-rank'
              }`}
            >
              <thead>
                <tr className='report-results-table-header-row'>
                  <th>Section</th>
                  <th>Scorecard</th>
                  <th>View</th>
                </tr>
              </thead>

              <tbody>
                {rankedSections.map((rankedSection, index) => {
                  const { title, scorecard_name } = rankedSection;

                  return (
                    <tr key={index}>
                      <td>{title}</td>
                      <td>{scorecard_name}</td>
                      <td>
                        <ButtonIcon
                          disabled={isLoadingBreakdown}
                          onClick={() =>
                            handleClickViewBreakdown(rankedSection)
                          }
                          iconName='eye'
                          style={{
                            fill:
                              title === breakdown.title &&
                              scorecard_name === breakdown.scorecard_name &&
                              isViewingBreakdown &&
                              !isLoadingBreakdown
                                ? '#0c4c14'
                                : '#4c3b4d',
                            margin: 'auto',
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </FlexContainer>

          {isViewingBreakdown && (
            <FlexContainer
              classes='grid-options-background ranked-sections-breakdown-area'
              flexDirection='column'
              alignItems='stretch'
              marginTop='10px'
            >
              {isLoadingBreakdown ? (
                <LoadingSpinnerNew />
              ) : (
                <>
                  <HeaderSecondary
                    margin='5px 0 10px 0'
                    text={`${breakdown.title} - ${breakdown.scorecard_name}`}
                    textAlign='center'
                  />

                  <InfoText
                    text={`${
                      topOrBottom === 'bottom' ? 'Failed' : 'Passed'
                    } on ${breakdown.evaluations?.length ?? '3'} evaluation${
                      breakdown.evaluations?.length === 1 ? '' : 's'
                    }`}
                  />

                  <InfoText
                    text={`(since ${moment()
                      .subtract(3, 'months')
                      .add(1, 'days')
                      .format('Do MMMM YYYY')}):`}
                  />

                  <FlexContainer
                    classes='table-container table-container-table ranked-sections-evaluations-table'
                    marginTop='10px'
                  >
                    <ul className='table'>
                      <li className='header-row'>
                        {tableData.map((attribute) => (
                          <HeaderSecondary
                            key={attribute.code}
                            classes='header-cell'
                            text={attribute.name}
                          />
                        ))}
                      </li>

                      {breakdown.evaluations?.map((evaluation, index) => {
                        return (
                          <a
                            key={evaluation.id}
                            href={`/${demo ? 'demo' : portalId}/evaluations/${
                              evaluation.id
                            }`}
                            className={`${
                              index === 0 ? 'first-grid-row ' : ''
                            } grid-row`}
                          >
                            {tableData.map((attribute) => (
                              <div
                                key={attribute.code}
                                className='grid-row-cell'
                              >
                                <div className='grid-row-cell-data'>
                                  {!evaluation[attribute.code]
                                    ? '-'
                                    : attribute.code === 'pass_fail' ||
                                      attribute.code === 'brag'
                                    ? capitalise(evaluation[attribute.code])
                                    : attribute.code === 'created_at'
                                    ? moment(evaluation[attribute.code]).format(
                                        'Do MMMM YYYY'
                                      )
                                    : evaluation[attribute.code]}
                                </div>
                              </div>
                            ))}
                          </a>
                        );
                      })}
                    </ul>
                  </FlexContainer>
                </>
              )}
            </FlexContainer>
          )}
        </>
      ) : (
        <InfoText text='Not enough data' />
      )}
    </PanelsSection>
  );
};

export default AgentRankedSections;
