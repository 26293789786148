import tickSmall from '../../images/tick-small.png';

import './About.css';

const CalibrationsFeatures = () => {
  return (
    <ul className='about-screen-list'>
      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Invite any portal user to participate in a calibration, regardless of
          their role
        </p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Choose calibration type</p>

        <ul className='about-screen-list about-screen-list-nested'>
          <li>
            <p>
              Benchmark - The results will be calculated based on the variance
              between the participants' evaluations and the benchmark evaluation
              (this will be completed by the calibration host)
            </p>
          </li>

          <li>
            <p>
              Group Average - The results will be calculated based on the
              variance between the participants' evaluations and the average
              results of all participants' evaluations combined
            </p>
          </li>
        </ul>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Enter calibration details to provide participants with useful
          information, eg. where to locate the call the group will be listening
          to
        </p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Track which participants have submitted their calibration evaluations
        </p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          The host can lock calibrations to prevent further submissions or
          edits, and then share the results when ready
        </p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Review the results of individual calibrations, and view a dedicated
          built-in calibration report with various options for drilling down on
          the results
        </p>
      </li>
    </ul>
  );
};

export default CalibrationsFeatures;
