import { useOutletContext } from 'react-router-dom';

import MainContainer from './MainContainer';

import './MainOnly.css';

const MainOnly = ({ children }) => {
  const { isNavOpen } = useOutletContext();

  return (
    <div className='main-full-screen'>
      <MainContainer isNavOpen={isNavOpen} mainOnly>
        {children}
      </MainContainer>
    </div>
  );
};

export default MainOnly;
