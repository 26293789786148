import {
  draftsAvailableColumns,
  draftsDefaultColumns,
  scorecardsAvailableColumns,
} from '../columns';
import apiDataService from '../../../api/drafts';
import DataGrid from '../DataGrid/DataGrid';

const Drafts = ({ demo }) => {
  const getAvailableColumns = (columns = {}) => {
    const availableColumns = {
      ...draftsAvailableColumns,
      ...scorecardsAvailableColumns,
      ...columns,
    };

    delete availableColumns.last_modified_at;
    delete availableColumns.last_modified_by;
    delete availableColumns.count_evaluations_vcid;

    return availableColumns;
  };

  const defaultOptions = {
    customColumns: null,
    limit: 10,
    page: 1,
    query: null,
    sortBy: null,
    view: 'table',
  };

  return (
    <DataGrid
      apiDataService={apiDataService}
      defaultColumns={draftsDefaultColumns}
      defaultOptions={defaultOptions}
      demo={demo}
      excludeFromFilterPanel={['filter', 'sort', 'records']}
      getAvailableColumns={getAvailableColumns}
      gridName='drafts'
      iconName='draft'
      noEditCanDelete
      noNew
    />
  );
};

export default Drafts;
