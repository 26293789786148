import { useEffect, useState } from 'react';

import ButtonText from '../../../Elements/ButtonText';
import ExpandableSection from '../../../Elements/ExpandableSection';
import SelectStandard from '../../../Elements/SelectStandard';
import Text from '../../../Elements/Text';

import './Records.css';

const Records = ({
  disabledDueToEditingText,
  isEditing,
  limit,
  numberOfPages,
  onUpdateOptions,
  page,
  showRecords,
}) => {
  const [pageSelection, setPageSelection] = useState([]);

  useEffect(() => {
    if (numberOfPages) {
      const pageNumberArray = [];

      const startPage = page - 2 < 1 ? 1 : page - 2;
      const endPage = page + 2 > numberOfPages ? numberOfPages : page + 2;

      for (let i = startPage; i <= endPage; i++) {
        pageNumberArray.push(i);
      }

      setPageSelection(pageNumberArray);
    }
  }, [numberOfPages, page]);

  return (
    <ExpandableSection
      containerClasses={`records-select-expand-area ${
        showRecords ? 'grid-options-background' : ''
      }`}
      isOpen={showRecords}
    >
      <SelectStandard
        id='records-select-label'
        containerClasses='records-select-container'
        disabled={isEditing}
        labelClasses='records-select-label'
        labelText='Results per page'
        onChange={(event) =>
          onUpdateOptions('limit', parseInt(event.target.value))
        }
        options={[
          [10, 10],
          [20, 20],
          [30, 30],
          [50, 50],
          [100, 100],
        ]}
        title={isEditing ? disabledDueToEditingText : undefined}
        value={limit}
      />

      <div className='go-to-page'>
        <label>Go to page</label>

        <div className='paging-options'>
          <div className='page-buttons'>
            <div className='go-to-page-selections'>
              <ButtonText
                disabled={page === 1 || isEditing}
                onClick={() => onUpdateOptions('page', 1)}
                text='<< First'
                title={isEditing ? disabledDueToEditingText : undefined}
              />
              <div className='page-selections-divider'>-</div>
              {pageSelection.map((pageNumber, index) => {
                return (
                  <div className='page-selection-buttons-container' key={index}>
                    <ButtonText
                      text={pageNumber}
                      disabled={pageNumber === page || isEditing}
                      // classes={
                      //   pageNumber === page
                      //     ? 'page-selections-current-page'
                      //     : ''
                      // }
                      onClick={() =>
                        onUpdateOptions('page', parseInt(pageNumber))
                      }
                      title={isEditing ? disabledDueToEditingText : undefined}
                    />
                    <div className='page-selections-divider'>-</div>
                  </div>
                );
              })}
              <ButtonText
                disabled={page === numberOfPages || isEditing}
                onClick={() => onUpdateOptions('page', parseInt(numberOfPages))}
                text='Last >>'
                title={isEditing ? disabledDueToEditingText : undefined}
              />
            </div>
          </div>

          {numberOfPages <= 100 ? (
            <div className='page-select'>
              <SelectStandard
                containerStyle={{ marginRight: '4px' }}
                disabled={isEditing}
                onChange={(event) =>
                  onUpdateOptions('page', parseInt(event.target.value))
                }
                options={Array.from(
                  Array(numberOfPages > 100 ? 100 : numberOfPages)
                ).map((na, index) => {
                  return [index + 1, index + 1];
                })}
                title={isEditing ? disabledDueToEditingText : undefined}
                value={page}
              />

              <Text text={`of ${numberOfPages}`} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </ExpandableSection>
  );
};

export default Records;
