import MainOnly from '../MainAndOptions/MainOnly';
import DataListsNewContent from './DataListsNewContent';

const DataListsNew = ({ demo }) => {
  return (
    <MainOnly>
      <DataListsNewContent demo={demo} />
    </MainOnly>
  );
};

export default DataListsNew;
