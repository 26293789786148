import { useEffect, useRef } from 'react';

import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import PanelsSections from '../../Elements/PanelsSections';
import Text from '../../Elements/Text';

import './ReportsFocusBreakdown.css';

const ReportsFocusBreakdown = ({ breakdown, getBrag, isLoadingBreakdown }) => {
  const breakdownRef = useRef(null);

  useEffect(() => {
    if (breakdown.breakdownData)
      breakdownRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [breakdown]);

  return (
    <FlexContainer
      alignItems='stretch'
      flexDirection='column'
      flexRef={breakdownRef}
      justifyContent='flex-start'
      width='100%'
    >
      <PanelsHeaderPrimary iconName='sitemap' text='Detail' />

      <PanelsSections>
        <PanelsSection
          alignItems='stretch'
          noMaxWidth
          typeStyle='type-2'
          width='100%'
        >
          {breakdown.orgLevels ? (
            <Text
              text={`Showing ${breakdown.orgLevels
                ?.map(
                  ({ name }, index, arr) =>
                    `${name}${
                      index < arr.length - 2
                        ? ', '
                        : index === arr.length - 2
                        ? ' and '
                        : ''
                    }`
                )
                .join('')} data for the '${breakdown.sectionName}' ${
                breakdown.tableType === 'cat' ? 'category' : 'section'
              } in the ${breakdown.scorecardName} scorecard`}
            />
          ) : (
            <LoadingSpinnerNew />
          )}
        </PanelsSection>

        {!breakdown.orgLevels ? (
          <PanelsSection
            alignItems='stretch'
            classes='focus-results-panel'
            noMaxWidth
            typeStyle='type-2'
          >
            <LoadingSpinnerNew />
          </PanelsSection>
        ) : (
          breakdown.orgLevels.map((orgLevel) => (
            <PanelsSection
              key={orgLevel.id}
              alignItems='stretch'
              classes='focus-results-panel focus-breakdown-orglevel-panel'
              noMaxWidth
              typeStyle='type-2'
            >
              {isLoadingBreakdown ? (
                <LoadingSpinnerNew />
              ) : (
                <FlexContainer flexDirection='column' alignItems='stretch'>
                  <HeaderSecondary
                    margin='5px 0 10px 0'
                    text={orgLevel.name}
                    textAlign='center'
                  />

                  <FlexContainer
                    classes='focus-results-table-container'
                    flexDirection='column'
                    alignItems='stretch'
                    justifyContent='flex-start'
                  >
                    <table>
                      <thead>
                        <tr className='report-results-table-header-row'>
                          <th>Name</th>
                          <th>Total Marked</th>
                          <th>
                            {breakdown.tableType === 'scoring'
                              ? 'Pass %'
                              : 'Average Score'}
                          </th>
                          <th>Trend</th>
                        </tr>
                      </thead>

                      <tbody>
                        {breakdown.breakdownData
                          .filter(
                            ({ dd_list_id }) => dd_list_id === orgLevel.id
                          )
                          .map(
                            (
                              {
                                avg_score,
                                data,
                                marked_total,
                                pass_rate,
                                trend,
                              },
                              index
                            ) => {
                              const scoreRag = getBrag(
                                'Score',
                                breakdown.tableType === 'scoring'
                                  ? pass_rate
                                  : avg_score
                              );
                              const trendRag = getBrag('Trend', trend);

                              return (
                                <tr
                                  key={index}
                                  className={
                                    index === 0
                                      ? 'focus-results-first-grid-row'
                                      : ''
                                  }
                                >
                                  <td className='centred-table-cell'>{data}</td>
                                  <td className='centred-table-cell'>
                                    {marked_total}
                                  </td>
                                  <td
                                    className={`centred-table-cell focus-results-cell-brag-${scoreRag}`}
                                  >
                                    {breakdown.tableType === 'scoring'
                                      ? pass_rate
                                      : avg_score}
                                  </td>
                                  <td
                                    className={`centred-table-cell focus-results-cell-brag-${trendRag}`}
                                  >
                                    {trend ?? '-'}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </FlexContainer>
                </FlexContainer>
              )}
            </PanelsSection>
          ))
        )}
      </PanelsSections>
    </FlexContainer>
  );
};

export default ReportsFocusBreakdown;
