import { Link, useLocation } from 'react-router-dom';
import { useDetectClickOutside } from 'react-detect-click-outside';

import ButtonIcon from '../Elements/ButtonIcon';
import FlexContainer from '../Elements/FlexContainer';

import './HamburgerNav.css';

const HamburgerNav = ({
  clickedOutside,
  handleInstallClick,
  isPromptVisible,
  setShowHamburgerNav,
}) => {
  const detectClickOutsideRef = useDetectClickOutside({
    onTriggered: (event) => {
      clickedOutside(event);
    },
  });

  const location = useLocation();

  const currentRoute = location.pathname;

  return (
    <FlexContainer
      classes='hamburger-nav'
      flexDirection='column'
      flexRef={detectClickOutsideRef}
    >
      <Link onClick={() => setShowHamburgerNav(false)} to='/about'>
        <button className='nav-left-button'>
          <div
            className={`nav-button-inner ${
              currentRoute === '/about' ? 'selected-nav-button' : ''
            }`}
          >
            About
          </div>
        </button>
      </Link>

      <div className='hamburger-nav-divider'></div>

      <Link onClick={() => setShowHamburgerNav(false)} to='/pricing'>
        <button className='nav-left-button'>
          <div
            className={`nav-button-inner ${
              currentRoute === '/pricing' ? 'selected-nav-button' : ''
            }`}
          >
            Pricing
          </div>
        </button>
      </Link>

      <div className='hamburger-nav-divider'></div>

      {/* <Link onClick={() => setShowHamburgerNav(false)} to='/faq'>
        <button className='nav-left-button'>
          <div
            className={`nav-button-inner ${
              currentRoute === '/faq' ? 'selected-nav-button' : ''
            }`}
          >
            FAQ's
          </div>
        </button>
      </Link>

      <div className='hamburger-nav-divider'></div> */}

      <Link onClick={() => setShowHamburgerNav(false)} to='/contact'>
        <button className='nav-left-button'>
          <div
            className={`nav-button-inner ${
              currentRoute === '/contact' ? 'selected-nav-button' : ''
            }`}
          >
            Contact
          </div>
        </button>
      </Link>

      {isPromptVisible && (
        <>
          <div className='hamburger-nav-divider'></div>

          <ButtonIcon
            buttonClass='nav-left-button install-app-button'
            iconName='install'
            onClick={handleInstallClick}
            title='Install as App'
          />
        </>
      )}
    </FlexContainer>
  );
};

export default HamburgerNav;
