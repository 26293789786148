import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import FlexContainer from '../../Elements/FlexContainer';

import './AggregateItem.css';

const AggregateItem = ({ handleChangeAggregate, aggItem }) => {
  return (
    <FlexContainer margin='5px 10px'>
      {aggItem.percentOf?.length ? (
        <FlexContainer classes='aggregate-item-description' flexWrap='wrap'>
          <span>{aggItem.value.slice(0, -10)} - </span>
          <span className='aggregate-item-span-header'>Percent of</span>
          {aggItem.percentOf.map((item, index, arr) => (
            <span key={`percentOf-${item}-${index}`}>
              <span className='span-with-margin'>{`'${item}'`}</span>

              {index < arr.length - 2 ? (
                <span key={index}>{','}</span>
              ) : index === arr.length - 1 ? (
                <></>
              ) : (
                <span className='span-with-margin' key={index}>
                  {'and'}
                </span>
              )}
            </span>
          ))}

          <span className='span-with-margin aggregate-item-span-header'>
            out of
          </span>
          {aggItem.outOf.map((item, index, arr) => (
            <span key={`outOf-${item}-${index}`}>
              <span className='span-with-margin'>{`'${item}'`}</span>

              {index < arr.length - 2 ? (
                <span key={index}>{','}</span>
              ) : index === arr.length - 1 ? (
                <></>
              ) : (
                <span className='span-with-margin' key={index}>
                  {'and'}
                </span>
              )}
            </span>
          ))}
        </FlexContainer>
      ) : (
        <span>{aggItem.value}</span>
      )}

      <ButtonIconOnly
        iconName='minus'
        marginLeft='10px'
        onClick={() => handleChangeAggregate(aggItem.id)}
        typeStyle='type-2'
      />
    </FlexContainer>
  );
};

export default AggregateItem;
