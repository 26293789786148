import './FormInfoText.css';

const FormInfoText = ({
  className = '',
  size = 'small',
  style,
  text,
  type,
}) => {
  return (
    <p
      className={`form-info-text form-info-text--${size} ${
        type ? `form-info-text--${type}` : ''
      } ${className}`}
      style={style}
    >
      {text}
    </p>
  );
};

export default FormInfoText;
