import { capitalise } from '../../../utils/helpers';

import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import TextBreak from '../../Elements/TextBreak';

import './PermissionsListItem.css';

const PermissionsListItem = ({
  copyFromUser,
  firstRow,
  hasChangePermission,
  isAdminUserAndUsersRoute,
  permissionRoute,
  setChanges,
  setPermissions,
}) => {
  return (
    <li
      className={`${
        firstRow ? 'first-grid-row ' : ''
      } grid-row permissions-grid-row`}
    >
      <div className='grid-row-cell permissions-grid-row-cell'>
        <TextBreak
          text={permissionRoute
            .split('.')
            .map((text) =>
              text === 'qa'
                ? 'QA'
                : text === 'own'
                ? '(Own)'
                : text.includes('_')
                ? text
                    .split('_')
                    .map((text) => (text === 'dd' ? 'DD' : capitalise(text)))
                    .join(' ')
                : capitalise(text)
            )
            .join(' - ')
            .replaceAll(' - (', ' (')}
          classes='grid-row-cell-data'
        />
      </div>

      {hasChangePermission ? (
        <div
          className={`grid-row-cell ${
            isAdminUserAndUsersRoute ? '' : 'permissions-unlink-button'
          }`}
        >
          <ButtonIconOnly
            disabled={copyFromUser || isAdminUserAndUsersRoute}
            onClick={
              isAdminUserAndUsersRoute
                ? null
                : () => {
                    setPermissions((curr) => {
                      const newPermissions = curr.filter(
                        (item) => item !== permissionRoute
                      );

                      return newPermissions;
                    });

                    setChanges((curr) => {
                      return {
                        ...curr,
                        permissionsChanged: true,
                        usingRoleDefaultPermissions: false,
                      };
                    });
                    // }
                  }
            }
            title={
              isAdminUserAndUsersRoute
                ? 'Unable to remove this permission from Admin user'
                : 'Remove'
            }
            iconName='minus'
            typeStyle='type-2'
          />
        </div>
      ) : (
        <></>
      )}
    </li>
  );
};

export default PermissionsListItem;
