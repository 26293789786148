import './ManageOption.css';

const ManageOption = ({
  checked,
  id,
  handleChangeCheckedOptions,
  optionsName,
}) => {
  const htmlId = `manage-options-checkbox-${id}`;

  return (
    <li className='manage-option-list-item'>
      <input
        checked={checked}
        id={htmlId}
        onChange={handleChangeCheckedOptions}
        type='checkbox'
        value={id}
      />
      <label htmlFor={htmlId}>{optionsName}</label>
    </li>
  );
};

export default ManageOption;
