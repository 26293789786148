import { BreadcrumbsContext, UserPortal } from '../../contexts';
import {
  generateUniqueRef,
  hasDuplicates,
  isBannedChars,
  validatePresenceRequired,
} from '../../utils/helpers';
import { handleApiError } from '../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiDataService from '../../api/org-levels';
import ButtonIconOnly from '../Elements/ButtonIconOnly';
import ButtonStandardNoFill from '../Elements/ButtonStandardNoFill';
import FlexContainer from '../Elements/FlexContainer';
import HeaderSecondary from '../Elements/HeaderSecondary';
import InputStandard from '../Elements/InputStandard';
import LoadingSpinner from '../Elements/LoadingSpinner';
import MainOnly from './MainAndOptions/MainOnly';
import Panels from '../Elements/Panels';
import PanelsControlBar from '../Elements/PanelsControlBar';
import PanelsHeaderPrimary from '../Elements/PanelsHeaderPrimary';
import PanelsSection from '../Elements/PanelsSection';
import PanelsSections from '../Elements/PanelsSections';
import Text from '../Elements/Text';

import './OrgStructure.css';

const OrgStructure = ({ demo }) => {
  const alert = useAlert();

  const navigate = useNavigate();

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const hasChangePermission =
    demo || userFromDb.permissions?.includes('org_structure.change');

  const { getAccessTokenSilently } = useAuth0();

  const [orgLevels, setOrgLevels] = useState();
  const [orgLevelsObject, setOrgLevelsObject] = useState();
  const [distinctLevels, setDistinctLevels] = useState();
  const [hasChanges, setHasChanges] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const [showInvalid, setShowInvalid] = useState(false);

  const getOrgLevels = async () => {
    try {
      const { distinctLevels, orgLevels } =
        await apiDataService.getDistinctLevels({
          portalId: demo ? 'demo' : portalId,
          userId: demo ? 1 : userId,
          token: demo ? undefined : await getAccessTokenSilently(),
        });

      setOrgLevels(orgLevels.sort((a, b) => a.level - b.level));

      const orgLevelsObj = {};

      orgLevels.forEach((orgLevel) => {
        orgLevelsObj[orgLevel.level] = orgLevel.name;
      });

      setOrgLevelsObject(orgLevelsObj);
      setDistinctLevels(distinctLevels);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const handleAddLevel = () => {
    setHasChanges(true);

    const uniqueRef = generateUniqueRef();

    setOrgLevels((curr) => {
      const copiedCurr = [...curr];

      return [...copiedCurr, { level: uniqueRef, name: '' }];
    });

    setOrgLevelsObject((curr) => {
      const copiedCurr = { ...curr };

      copiedCurr[uniqueRef] = '';

      return copiedCurr;
    });
  };

  const handleDeleteLevel = (orgLevel) => {
    setHasChanges(true);

    setOrgLevels((curr) => {
      const copiedCurr = [...curr];

      const levelIndex = copiedCurr.findIndex(
        (copiedOrgLevel) => copiedOrgLevel.level === orgLevel.level
      );

      copiedCurr.splice(levelIndex, 1);

      return copiedCurr;
    });
  };

  const handleMoveLevel = (level, direction) => {
    setHasChanges(true);

    setOrgLevels((curr) => {
      const copiedCurr = [...curr];

      const levelIndex = copiedCurr.findIndex(
        (orgLevel) => orgLevel.level === level
      );

      const spliced = copiedCurr.splice(levelIndex, 1)[0];

      copiedCurr.splice(
        levelIndex + (direction === 'right' ? 1 : -1),
        0,
        spliced
      );

      return copiedCurr;
    });
  };

  const handleSaveLevels = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    setIsSaving(true);
    setShowInvalid(true);

    if (orgLevels.some((orgLevel) => !orgLevelsObject[orgLevel.level])) {
      setIsSaving(false);

      return alert.error('Org Level names are required');
    }

    if (
      hasDuplicates(
        orgLevels.map((orgLevel) => orgLevelsObject[orgLevel.level])
      )
    ) {
      setIsSaving(false);

      return alert.error('Org Level names must be unique');
    }

    const reqBody = {
      newOrgLevels: orgLevels.map((orgLevel) => {
        return { ...orgLevel, name: orgLevelsObject[orgLevel.level] };
      }),
      userId: demo ? 1 : userId,
    };

    try {
      await apiDataService.post({
        portalId: demo ? 'demo' : portalId,
        reqBody,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      alert.success(`Org Structure saved`);

      getOrgLevels();

      setHasChanges(false);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }

    setIsSaving(false);
  };

  useEffect(() => {
    if (!demo && !userFromDb.permissions?.includes('org_structure.view')) {
      navigate(`/${demo ? 'demo' : portalId}/dashboard`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      setBreadcrumbs([
        [
          'Dashboard',
          'dashboard',
          '',
          `/${demo ? 'demo' : portalId}/dashboard`,
        ],
        ['Org Structure', 'sitemap'],
      ]);

      getOrgLevels();
    }

    return () => {
      setOrgLevels();
      setOrgLevelsObject();
      setDistinctLevels();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (orgLevels && orgLevelsObject && distinctLevels)
    return (
      <MainOnly>
        <Panels>
          {hasChangePermission ? (
            <PanelsControlBar justifyContent='space-between'>
              <ButtonIconOnly iconName='plus' onClick={handleAddLevel} />

              <ButtonStandardNoFill
                disabled={!hasChanges || isSaving}
                iconName='save-solid'
                onClick={handleSaveLevels}
                text='Save'
                typeStyle='type-4'
              />
            </PanelsControlBar>
          ) : (
            <></>
          )}

          <PanelsHeaderPrimary iconName='sitemap' text='Org Structure' />

          <PanelsSections alignItems='flex-start' margin='0 0 30px 0'>
            {orgLevels?.map((orgLevel, index, arr) => (
              <FlexContainer
                key={orgLevel.level}
                classes='org-structure-item'
                flexDirection='column'
              >
                <HeaderSecondary text={`Org Level ${index + 1}`} />

                <InputStandard
                  classes={
                    hasChangePermission
                      ? ''
                      : 'org-structure-no-change-permission'
                  }
                  hasPermission={hasChangePermission}
                  invalid={
                    orgLevelsObject[orgLevel.level]
                      ? null
                      : [
                          validatePresenceRequired(
                            orgLevelsObject[orgLevel.level],
                            'Org Level name'
                          ),
                        ]
                  }
                  isRequired
                  onChange={(event) => {
                    const newVal = event.target.value;

                    const isBannedCharsText = isBannedChars(newVal);

                    if (isBannedCharsText)
                      return alert.error(isBannedCharsText, { timeout: 10000 });

                    setHasChanges(true);

                    setOrgLevelsObject((curr) => {
                      const copiedCurr = { ...curr };

                      copiedCurr[orgLevel.level] = event.target.value;

                      return copiedCurr;
                    });
                  }}
                  maxLength='31'
                  placeholder={`Org Level ${index + 1}`}
                  showInvalid={showInvalid}
                  value={orgLevelsObject[orgLevel.level] || ''}
                />

                <PanelsSection
                  height='200px'
                  width='160px'
                  justifyContent='flex-start'
                  overflowY='auto'
                  overflowX='hidden'
                >
                  {distinctLevels[orgLevel.level]?.length ? (
                    distinctLevels[orgLevel.level].map((item, index) => (
                      <Text key={index} text={item} />
                    ))
                  ) : (
                    <Text classes='org-levels-no-data' text='< No data >' />
                  )}
                </PanelsSection>

                {orgLevel.level !== 0 && hasChangePermission ? (
                  <FlexContainer
                    alignSelf='stretch'
                    justifyContent='space-evenly'
                  >
                    <ButtonIconOnly
                      disabled={index === 1}
                      iconName='circle-left'
                      onClick={() => handleMoveLevel(orgLevel.level, 'left')}
                      typeStyle='type-4'
                    />

                    <ButtonStandardNoFill
                      alignSelf='center'
                      iconName='trash'
                      onClick={() => handleDeleteLevel(orgLevel)}
                      text='Delete'
                      typeStyle='type-5'
                    />

                    <ButtonIconOnly
                      disabled={index === arr.length - 1}
                      iconName='circle-right'
                      onClick={() => handleMoveLevel(orgLevel.level, 'right')}
                      typeStyle='type-4'
                    />
                  </FlexContainer>
                ) : (
                  <></>
                )}
              </FlexContainer>
            ))}
          </PanelsSections>
        </Panels>
      </MainOnly>
    );
  else return <LoadingSpinner />;
};

export default OrgStructure;
