import DataGrid from '../DataGrid/DataGrid';
import {
  dataListsAvailableColumns,
  dataListsDefaultColumns,
  dataListsRequiredFields,
  dataListsUnEditableColumns,
  dataListsValidationColumns,
} from '../columns';
import apiDataService from '../../../api/data-lists';

const DataLists = ({ demo }) => {
  const getAvailableColumns = (columns = {}) => {
    return { ...dataListsAvailableColumns, ...columns };
  };

  const defaultOptions = {
    customColumns: null,
    limit: 10,
    page: 1,
    query: null,
    sortBy: null,
    view: 'grid',
  };

  return (
    <DataGrid
      apiDataService={apiDataService}
      defaultColumns={dataListsDefaultColumns}
      defaultOptions={defaultOptions}
      demo={demo}
      getAvailableColumns={getAvailableColumns}
      gridName='data-lists'
      iconName='list-ol'
      newScreenRoute='new'
      requiredFields={dataListsRequiredFields}
      unEditableColumns={dataListsUnEditableColumns}
      validationColumns={dataListsValidationColumns}
    />
  );
};

export default DataLists;
