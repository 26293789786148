import { useAlert } from 'react-alert';
import { useEffect, useState } from 'react';

import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import ClauseCriteria from './clause-components/ClauseCriteria';
import ClauseSelect from './clause-components/ClauseSelect';
import ConditionButton from './ConditionButton';
import operators from '../../../utils/columns/operators';

import './Filter.css';

const Filter = ({
  columnTypes,
  condition,
  disabled,
  disabledText,
  filter,
  handleChangeCondition,
  hasConditionButton,
  isChangingCriteria,
  isChildOfParentGroup,
  options,
  parentCondition,
  parentGroup,
  selectFrom,
  setIsChangingCriteria,
  setExternalIsChangingCriteria,
  setParentGroup,
  uniqueRef,
  setNoOfFiltersTracker,
  setLatestQuery,
}) => {
  const alert = useAlert();

  const getInitialOperator = () => {
    const [, operator, criteria] = filter.clause;

    if (operator === 'eq' && criteria === '') return 'null';
    else if (operator === 'nt' && criteria === '') return 'ntnull';
    else return operator;
  };

  const [column, setColumn] = useState(filter.clause[0]);
  const [operator, setOperator] = useState(getInitialOperator());
  const [valueCriteria, setValueCriteria] = useState(
    Array.isArray(filter.clause[2]) ? '' : filter.clause[2]
  );
  const [rangeCriteria, setRangeCriteria] = useState(
    Array.isArray(filter.clause[2]) ? filter.clause[2] : ['', '']
  );

  const [columnType, setColumnType] = useState(columnTypes[filter.clause[0]]);
  const [hasInitialised, setHasInitialised] = useState(false);

  useEffect(() => {
    if (!hasInitialised) {
      setHasInitialised(true);
      return;
    }

    setIsChangingCriteria(true);
    setExternalIsChangingCriteria?.(true);

    const debounceUpdateParentGroup = setTimeout(() => {
      handleUpdateParentGroup(column, operator, valueCriteria, rangeCriteria);
      setIsChangingCriteria(false);
      setExternalIsChangingCriteria?.(false);
    }, 800);

    return () => clearTimeout(debounceUpdateParentGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueCriteria, rangeCriteria]);

  useEffect(() => {
    setNoOfFiltersTracker((curr) => ({ ...curr, [uniqueRef]: true }));

    return () =>
      setNoOfFiltersTracker((curr) => {
        const newObj = { ...curr };
        delete newObj[uniqueRef];
        return newObj;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOperatorAndCriteria = (operator, valueCriteria, rangeCriteria) => {
    if (operator === 'null') return ['eq', ''];
    else if (operator === 'ntnull') return ['nt', ''];
    else if (operator.includes('bet') || operator === 'prev')
      return [operator, rangeCriteria];
    else return [operator, valueCriteria];
  };

  const getOperatorOptions = () =>
    operators[columnType]?.options?.map((operatorCode) => [
      operatorCode,
      operators[
        columnType === 'datetime' || columnType === 'time'
          ? 'codeToValueDatetime'
          : 'codeToValue'
      ][operatorCode],
    ]);

  const handleUpdateParentGroup = (
    column,
    operator,
    valueCriteria,
    rangeCriteria
  ) => {
    setParentGroup((currentParentGroup) => {
      return {
        uniqueRef: currentParentGroup.uniqueRef,
        [parentCondition]: currentParentGroup[parentCondition].map((filter) => {
          if (filter.uniqueRef === uniqueRef) {
            return {
              uniqueRef,
              clause: [
                column,
                ...getOperatorAndCriteria(
                  operator,
                  valueCriteria,
                  rangeCriteria
                ),
              ],
            };
          } else return filter;
        }),
      };
    });
  };

  const handleChangeColumn = (event) => {
    const newColumn = event.target.value;

    let newColumnType;
    let newOperator;
    let newValueCriteria;
    let newRangeCriteria;

    if (newColumn === '') {
      newColumnType = '';
      newOperator = '';
      newValueCriteria = '';
      newRangeCriteria = ['', ''];
    } else {
      const typeOfNewColumn = columnTypes[newColumn];

      if (columnType !== typeOfNewColumn) {
        newColumnType = typeOfNewColumn;
        newValueCriteria = '';
        newRangeCriteria = ['', ''];
      }

      const columnDefaultOperator = operators[typeOfNewColumn]?.default;
      const columnOperatorOptions = operators[typeOfNewColumn]?.options;

      if (!columnOperatorOptions?.includes(operator)) {
        newOperator = columnDefaultOperator;
      }
    }

    setColumn(newColumn);

    if (newColumnType !== undefined) setColumnType(newColumnType);
    if (newOperator !== undefined) setOperator(newOperator);
    if (newValueCriteria !== undefined) setValueCriteria(newValueCriteria);
    if (newRangeCriteria !== undefined) setRangeCriteria(newRangeCriteria);

    handleUpdateParentGroup(
      newColumn,
      newOperator !== undefined ? newOperator : operator,
      newValueCriteria !== undefined ? newValueCriteria : valueCriteria,
      newRangeCriteria !== undefined ? newRangeCriteria : rangeCriteria
    );
  };

  const handleChangeOperator = (event) => {
    const newOperator = event.target.value;

    const [newValueCriteria, newRangeCriteria] =
      newCriteriaOnOperatorChange(newOperator);

    if (newValueCriteria !== undefined) setValueCriteria(newValueCriteria);
    if (newRangeCriteria !== undefined) setRangeCriteria(newRangeCriteria);

    setOperator(newOperator);

    handleUpdateParentGroup(
      column,
      newOperator,
      newValueCriteria !== undefined ? newValueCriteria : valueCriteria,
      newRangeCriteria !== undefined ? newRangeCriteria : rangeCriteria
    );
  };

  const handleChangeRangeCriteria1 = (event) => {
    setRangeCriteria([event.target.value, rangeCriteria[1]]);
  };

  const handleChangeRangeCriteria2 = (event) => {
    setRangeCriteria([rangeCriteria[0], event.target.value]);
  };

  const handleChangeValueCriteria = (event) => {
    setValueCriteria(event.target.value);
  };

  const handleDeleteFilter = () => {
    if (!isChildOfParentGroup && parentGroup[parentCondition].length <= 2) {
      alert.error(
        `The group containing this filter must have at least 2 conditions`,
        { timeout: 5000 }
      );

      return;
    } else {
      setParentGroup((currentParentGroup) => {
        return {
          uniqueRef: currentParentGroup.uniqueRef,
          [parentCondition]: currentParentGroup[parentCondition].filter(
            (filter) => filter.uniqueRef !== uniqueRef
          ),
        };
      });

      setLatestQuery('');
    }
  };

  const newCriteriaOnOperatorChange = (newOperator) => {
    let newValueCriteria;
    let newRangeCriteria;

    if (operator === 'prev') {
      newValueCriteria = '';
      newRangeCriteria = ['', ''];
    } else if (newOperator.includes('null')) {
      if (valueCriteria !== '') newValueCriteria = '';
      if (
        !Array.isArray(rangeCriteria) ||
        rangeCriteria.length !== 2 ||
        rangeCriteria[0] !== '' ||
        rangeCriteria[1] !== ''
      )
        newRangeCriteria = ['', ''];
    } else if (operator.includes('bet') && !newOperator.includes('bet')) {
      if (valueCriteria !== rangeCriteria[0])
        newValueCriteria = rangeCriteria[0];
      if (
        !Array.isArray(rangeCriteria) ||
        rangeCriteria.length !== 2 ||
        rangeCriteria[0] !== '' ||
        rangeCriteria[1] !== ''
      )
        newRangeCriteria = ['', ''];
    } else if (!operator.includes('bet') && newOperator.includes('bet')) {
      if (
        !Array.isArray(rangeCriteria) ||
        rangeCriteria.length !== 2 ||
        rangeCriteria[0] !== valueCriteria ||
        rangeCriteria[1] !== valueCriteria
      )
        newRangeCriteria = [valueCriteria, valueCriteria];
      if (valueCriteria !== '') newValueCriteria = '';
    }

    return [newValueCriteria, newRangeCriteria];
  };

  return (
    <li className='filter'>
      <div className='condition-button-container'>
        {hasConditionButton ? (
          <ConditionButton
            condition={condition}
            disabled={disabled || isChangingCriteria}
            title={disabled ? disabledText : undefined}
            handleChangeCondition={handleChangeCondition}
          />
        ) : (
          <></>
        )}
      </div>

      <ButtonIconOnly
        disabled={disabled}
        classes='delete-filter-button'
        iconName='list-ul-single'
        subIconName='xmark'
        typeStyle='type-2'
        onClick={handleDeleteFilter}
        title={disabled ? disabledText : undefined}
      />

      <label className='delete-filter-label'>Delete filter</label>

      <div className='clause'>
        <ClauseSelect
          disabled={disabled}
          title={disabled ? disabledText : undefined}
          value={column}
          handleChangeOption={handleChangeColumn}
          options={options}
          placeholder={['', '< column >']}
        />

        {columnType ? (
          <>
            <ClauseSelect
              disabled={disabled}
              title={disabled ? disabledText : undefined}
              value={operator}
              handleChangeOption={handleChangeOperator}
              options={getOperatorOptions()}
            />

            <ClauseCriteria
              column={column}
              columnType={columnType}
              disabled={disabled}
              title={disabled ? disabledText : undefined}
              operator={operator}
              handleChangeValueCriteria={handleChangeValueCriteria}
              handleChangeRangeCriteria1={handleChangeRangeCriteria1}
              handleChangeRangeCriteria2={handleChangeRangeCriteria2}
              valueCriteria={valueCriteria}
              rangeCriteria={rangeCriteria}
              selectFrom={selectFrom}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </li>
  );
};

export default Filter;
