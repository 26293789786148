import { doArrayValuesMatch, organiseColumns } from '../../../../utils/helpers';
import { useEffect, useState } from 'react';

import DraggableToggleList from '../../../Elements/DraggableToggleList';
import ExpandableSection from '../../../Elements/ExpandableSection';

const Columns = ({
  customColumns,
  defaultColumns,
  disabledDueToEditingText,
  displayColumns,
  isEditing,
  onUpdateOptions,
  setDisplayColumns,
  showColumns,
  unEditableColumns,
  view,
}) => {
  const handlePreToggle = (card) => {
    if (isEditing && !unEditableColumns.includes(card.id)) return false;
    else return true;
  };

  const handleToggleColumn = (column) => {
    setDisplayColumns((currentColumns) => {
      const newColumns = currentColumns.map((columnObj) => {
        if (columnObj.id === column)
          return { ...columnObj, toggled: !columnObj.toggled };
        else return columnObj;
      });

      return organiseColumns(newColumns);
    });
  };

  const [dndDisplayColumns, setDndDisplayColumns] = useState();
  const [isMovingDisplayColumns, setIsMovingDisplayColumns] = useState();

  useEffect(() => {
    const debounceHandleDnd = setTimeout(() => {
      if (isMovingDisplayColumns) {
        setDisplayColumns(dndDisplayColumns);
        setIsMovingDisplayColumns(false);
      }
    }, 350);

    return () => clearTimeout(debounceHandleDnd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dndDisplayColumns]);

  useEffect(() => {
    setDndDisplayColumns(displayColumns);

    const displayedColumns = displayColumns
      .filter((column) => column.toggled)
      .map((column) => column.id);

    if (doArrayValuesMatch(defaultColumns, displayedColumns)) {
      if (customColumns !== null) onUpdateOptions('customColumns', null);
    } else {
      const newCustomColumns = displayedColumns.join(',,');

      if (
        customColumns !== newCustomColumns &&
        (customColumns || newCustomColumns)
      ) {
        onUpdateOptions('customColumns', newCustomColumns);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayColumns]);

  return (
    <>
      <ExpandableSection
        isOpen={showColumns}
        containerClasses={showColumns ? 'grid-options-background' : ''}
      >
        <DraggableToggleList
          cards={dndDisplayColumns}
          setCards={setDndDisplayColumns}
          itemType='column-item'
          setIsMoving={setIsMovingDisplayColumns}
          handleToggle={handleToggleColumn}
          handlePreToggle={handlePreToggle}
          title={isEditing ? disabledDueToEditingText : undefined}
          toggleHeight={40}
          uniqueSectionId='columns'
          disableHeaderColumn={view === 'grid'}
        />
      </ExpandableSection>
    </>
  );
};

export default Columns;
