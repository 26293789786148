import {
  reportsAvailableColumns,
  reportsDefaultColumns,
  reportsRequiredFields,
  reportsUnEditableColumns,
  reportsValidationColumns,
} from '../columns';

import apiDataService from '../../../api/reports';
import DataGrid from '../DataGrid/DataGrid';

const Reports = ({ demo }) => {
  const getAvailableColumns = (columns = {}) => {
    return { ...reportsAvailableColumns, ...columns };
  };

  const defaultOptions = {
    customColumns: null,
    limit: 10,
    page: 1,
    query: null,
    sortBy: null,
    view: 'grid',
  };

  return (
    <DataGrid
      apiDataService={apiDataService}
      defaultColumns={reportsDefaultColumns}
      defaultOptions={defaultOptions}
      demo={demo}
      getAvailableColumns={getAvailableColumns}
      gridName='reports'
      iconName='chart-pie'
      newScreenRoute='new'
      requiredFields={reportsRequiredFields}
      unEditableColumns={reportsUnEditableColumns}
      validationColumns={reportsValidationColumns}
    />
  );
};

export default Reports;
