import './Text.css';

const Text = ({
  classes = '',
  color,
  flex,
  fontWeight,
  id,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  maxWidth,
  onClick,
  text = '',
  textAlign,
  textDecoration,
  title = '',
}) => {
  const style = {};

  if (color) style.color = color;
  if (flex) style.flex = flex;
  if (margin) style.margin = margin;
  if (marginBottom) style.marginBottom = marginBottom;
  if (marginLeft) style.marginLeft = marginLeft;
  if (marginRight) style.marginRight = marginRight;
  if (marginTop) style.marginTop = marginTop;
  if (textAlign) style.textAlign = textAlign;
  if (textDecoration) style.textDecoration = textDecoration;
  if (fontWeight) style.fontWeight = fontWeight;
  if (maxWidth) style.maxWidth = maxWidth;

  return (
    <span
      className={`portal-text ${classes}`}
      id={id}
      onClick={onClick}
      style={style}
      title={title}
    >
      {text}
    </span>
  );
};

export default Text;
