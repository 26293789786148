import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiDataServiceBilling from '../../../api/billing';
import ExpandableSection from '../../Elements/ExpandableSection';
import GridOption from '../DataGrid/GridContent/GridOption';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InvoiceTable from './InvoiceTable';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import MainOnly from '../MainAndOptions/MainOnly';
import moment from 'moment';
import NextInvoice from './NextInvoice';
import Panels from '../../Elements/Panels';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import ManageSubscription from './ManageSubscription';
import Text from '../../Elements/Text';

const Billing = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [showHistoricInvoices, setShowHistoricInvoices] = useState('');

  const [customer, setCustomer] = useState();
  const [currentInvoices, setCurrentInvoices] = useState([]);
  const [previousInvoices, setPreviousInvoices] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  const [dailyUsage, setDailyUsage] = useState();
  const [loggedUsage, setLoggedUsage] = useState();

  const getBilling = async () => {
    try {
      setIsLoadingInvoices(true);

      const {
        activeSubscriptions,
        customer,
        dailyUsage,
        invoices: returnedInvoices,
        loggedUsage,
      } = await apiDataServiceBilling.getInvoices({
        portalId,
        token: await getAccessTokenSilently(),
        userId,
      });

      const currentInvoices = [];
      const previousInvoices = [];

      returnedInvoices.forEach((invoice) => {
        if (
          invoice.collection_method === 'send_invoice' &&
          invoice.due_date > moment().unix()
        ) {
          currentInvoices.push(invoice);
        } else {
          previousInvoices.push(invoice);
        }
      });

      setDailyUsage(dailyUsage);
      setLoggedUsage(loggedUsage);

      setCurrentInvoices(currentInvoices);
      setPreviousInvoices(previousInvoices);
      setSubscriptions(activeSubscriptions);
      setCustomer(customer);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoadingInvoices(false);
    }
  };

  useEffect(() => {
    if (!userFromDb.permissions?.includes('billing.view')) {
      navigate(`/${portalId}/dashboard`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      setBreadcrumbs([['Billing', 'credit-card']]);

      getBilling();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainOnly>
      <Panels>
        <PanelsHeaderPrimary iconName='credit-card' text='Billing' />

        {!subscriptions.length &&
          (isLoadingInvoices ? (
            <LoadingSpinnerNew />
          ) : (
            <>
              <HeaderSecondary
                marginBottom='20px'
                text={
                  userFromDb.portal_trial_info.isInTrial
                    ? 'Creating a subscription will end your free trial. Your portal will become chargeable immediately.'
                    : 'A subscription is required for continued use of QA Portal.'
                }
                textAlign='center'
              />

              <HeaderSecondary
                marginBottom='10px'
                text='Create Subscription'
                textAlign='center'
              />

              <ManageSubscription
                customer={customer}
                setCustomer={setCustomer}
              />
            </>
          ))}

        {currentInvoices.length ? (
          <>
            <HeaderSecondary
              textAlign='center'
              marginBottom='10px'
              text={`Current Invoice${currentInvoices.length === 1 ? '' : 's'}`}
            />

            <PanelsSection>
              <InvoiceTable invoices={currentInvoices} />
            </PanelsSection>
          </>
        ) : (
          <></>
        )}

        <HeaderSecondary
          textAlign='center'
          marginBottom='10px'
          text='Next Invoice'
        />

        <NextInvoice
          collectionMethod={subscriptions[0]?.collection_method}
          currentPeriodEnd={subscriptions[0]?.current_period_end}
          currentPeriodStart={subscriptions[0]?.current_period_start}
          dailyUsage={dailyUsage}
          hasSubscriptions={subscriptions.length !== 0}
          isLoadingInvoices={isLoadingInvoices}
          loggedUsage={loggedUsage}
        />

        <HeaderSecondary
          textAlign='center'
          marginBottom='10px'
          text='Previous Invoices'
        />

        <PanelsSection>
          {isLoadingInvoices ? (
            <LoadingSpinnerNew />
          ) : (
            <GridOption
              headerText=''
              showGridOption={showHistoricInvoices}
              setShowGridOption={setShowHistoricInvoices}
              gridOption='historicInvoices'
            />
          )}

          <ExpandableSection
            isOpen={showHistoricInvoices === 'historicInvoices'}
          >
            {previousInvoices.length ? (
              <InvoiceTable invoices={previousInvoices} />
            ) : (
              <Text
                marginTop='10px'
                text='No invoices to display'
                textAlign='center'
              />
            )}
          </ExpandableSection>
        </PanelsSection>

        {subscriptions.length > 0 &&
          (isLoadingInvoices ? (
            <LoadingSpinnerNew />
          ) : (
            <>
              <HeaderSecondary
                marginBottom='10px'
                text='Make Changes'
                textAlign='center'
              />

              {subscriptions.map((subscription) => (
                <ManageSubscription
                  key={subscription.id}
                  customer={customer}
                  setCustomer={setCustomer}
                  subscription={subscription}
                />
              ))}
            </>
          ))}
      </Panels>
    </MainOnly>
  );
};

export default Billing;
