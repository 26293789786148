import { apiUrl, headers } from './config';

const ddListsApi = {
  getAll: async ({ portalId, token, userId }) => {
    const url = `/api/portals/${portalId}/dd-lists`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getDdListById: async ({ table, tableId, portalId, token, userId }) => {
    const url = `/api/portals/${portalId}/dd-lists/${table}/${tableId}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },
};

export default ddListsApi;
