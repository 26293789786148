import InputStandard from '../../Elements/InputStandard';
import './ReportFilter.css';

const ReportCustomColumnHeaders = ({
  columnOrder,
  customColumnHeaders,
  setCustomColumnHeaders,
}) => {
  return (
    <div className='report-aggregate-area'>
      {columnOrder.map((column) => (
        <InputStandard
          id={'report-custom-column-header-input-' + column.id}
          inputWidth='180px'
          key={column.id}
          labelFontWeight='300'
          labelText={column.value}
          maxLength={400}
          onChange={(event) =>
            setCustomColumnHeaders((curr) => ({
              ...curr,
              [column.id]: event.target.value,
            }))
          }
          placeholder={column.value}
          value={customColumnHeaders[column.id] || ''}
          wrap='wrap'
        />
      ))}
    </div>
  );
};

export default ReportCustomColumnHeaders;
