export const agents = async (orgLevels) => {
  return { ...orgLevels.map((orgLevel) => ({ [orgLevel]: 'string' })) };

  /*   example:
    {
      level0: 'string',
      level1: 'string',
      level2: 'string',
      level3: 'string',
    } */
};

export const created = { created_by: 'string', created_at: 'datetime' };

export const dataLists = { id: 'int', list: 'array', name: 'string' };

export const evaluations = {
  agent_id: 'int',
  brag: 'string',
  calibration_id: 'int',
  comments_main: 'string',
  fed_back: 'bool',
  id: 'int',
  overwrite_id: 'int',
  pass_fail: 'string',
  quality_score: 'int',
  scorecard_id: 'int',
  scorecard_vcid: 'int',
  status: 'string',
};

export const lastModified = {
  last_modified_by: 'string',
  last_modified_at: 'datetime',
};

export const logs = {
  id: 'int',
  table_name: 'string',
  row_id: 'int',
  vcid: 'int',
  method: 'string',
  details: 'string',
  by_system: 'bool',
  created_at: 'datetime',
};

export const orgLevels = { id: 'int', level: 'int', name: 'string' };

export const permissionDefaults = {
  id: 'int',
  route: 'string',
  admin: 'bool',
  qa: 'bool',
  manager: 'bool',
  agent: 'bool',
};

export const users = {
  email: 'string',
  first_name: 'string',
  id: 'int',
  invite_accepted: 'bool',
  last_name: 'string',
  linked_agents: 'array',
  portal_id: 'int',
  role: 'string',
  status: 'string',
  sub: 'string',
  tier: 'string',
  username: 'string',
  // value: 'int', - don't return value
};
