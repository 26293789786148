import { handleApiError } from '../../../utils/error-handling';
import { iconsList } from '../../../utils/constants';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useContext, useState } from 'react';
import { UserPortal } from '../../../contexts';

import apiDataServiceUsers from '../../../api/users';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import Icon from '../../Elements/Icon';
import IconWithSub from '../../Elements/IconWithSub';
import SelectStandard from '../../Elements/SelectStandard';
import InputStandard from '../../Elements/InputStandard';
import FlexContainer from '../../Elements/FlexContainer';

import './AddFavourite.css';

const AddFavourite = ({
  demo,
  navFavourites,
  navItemsMain,
  navItemsNew,
  setIsAddingNewFavourite,
  setNavFavourites,
}) => {
  const alert = useAlert();

  const { getAccessTokenSilently } = useAuth0();
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const [mainMenuItem, setMainMenuItem] = useState('');
  const [addNewItem, setAddNewItem] = useState('');
  const [customName, setCustomName] = useState('');
  const [customLink, setCustomLink] = useState('');
  const [customIcon, setCustomIcon] = useState('');
  const [customSubIcon, setCustomSubIcon] = useState('');

  const detectClickOutsideRef = useDetectClickOutside({
    onTriggered: () => {
      setIsAddingNewFavourite(false);
    },
  });

  const handleChangeMainMenuItem = (event) => {
    setAddNewItem('');
    setCustomName('');
    setCustomLink('');
    setCustomIcon('');
    setCustomSubIcon('');
    setMainMenuItem(event.target.value);
  };
  const handleChangeAddNewItem = (event) => {
    setAddNewItem(event.target.value);
    setCustomName('');
    setCustomLink('');
    setCustomIcon('');
    setCustomSubIcon('');
    setMainMenuItem('');
  };
  const handleChangeCustomName = (event) => {
    setAddNewItem('');
    setCustomName(event.target.value);
    setMainMenuItem('');
  };
  const handleChangeCustomLink = (event) => {
    setAddNewItem('');
    setCustomLink(event.target.value);
    setMainMenuItem('');
  };
  const handleChangeCustomIcon = (event) => {
    setAddNewItem('');
    setCustomIcon(event.target.value);
    setMainMenuItem('');
  };
  const handleChangeCustomSubIcon = (event) => {
    setAddNewItem('');
    setCustomSubIcon(event.target.value);
    setMainMenuItem('');
  };

  const handleAddNewFavourite = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (
      !mainMenuItem &&
      !addNewItem &&
      (!customName || !customLink || !customIcon)
    ) {
      return alert.show('Invalid selection');
    }

    const pathPrefix = `/${demo ? 'demo' : portalId}/`;

    const indexOfPathPrefix = customLink.indexOf(pathPrefix);

    if (customLink && indexOfPathPrefix === -1)
      return alert.show('Invalid Portal URL');

    const parsedLink = customLink.slice(
      customLink.indexOf(pathPrefix) + pathPrefix.length
    );

    const indexOfQuery = parsedLink.indexOf('?');
    const indexOfSlash = parsedLink.indexOf('/');

    let newParsedLink = parsedLink;

    if (
      (indexOfQuery !== -1 && indexOfSlash === -1) ||
      (indexOfQuery !== -1 && indexOfQuery < indexOfSlash)
    ) {
      newParsedLink = parsedLink.slice(0, indexOfQuery);
    }

    const customNavFavourite = `custom--${customIcon}--${
      customSubIcon ? `${customSubIcon}--` : 'none--'
    }${customName}--..//${newParsedLink}`;

    const alreadyAdded = mainMenuItem
      ? navFavourites.includes(mainMenuItem)
      : addNewItem
      ? navFavourites.includes(addNewItem)
      : customLink
      ? navFavourites.find(
          (favourite) =>
            favourite.slice(-newParsedLink.length) === newParsedLink
        )
      : false;

    if (alreadyAdded) return alert.show('Already added to favourites');

    try {
      const reqBody = {
        updates: [
          {
            id: demo ? 1 : userId,
            nav_favourites: [
              ...navFavourites,
              mainMenuItem || addNewItem || customNavFavourite,
            ],
          },
        ],
        userId: demo ? 1 : userId,
      };

      await apiDataServiceUsers.update({
        portalId: demo ? 'demo' : portalId,
        reqBody,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      alert.success('Favourite Added');

      setNavFavourites([
        ...navFavourites,
        mainMenuItem || addNewItem || customNavFavourite,
      ]);

      setIsAddingNewFavourite(false);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  return (
    <FlexContainer
      classes='add-nav-favourite ignore-detect-click-outside'
      flexDirection='column'
      flexRef={detectClickOutsideRef}
    >
      <div
        className='nav-favourite-cross add-nav-favourite-close-menu ignore-detect-click-outside'
        onClick={() => setIsAddingNewFavourite(false)}
      >
        <Icon className='ignore-detect-click-outside' iconName='circle-cross' />
      </div>

      <SelectStandard
        containerClasses='ignore-detect-click-outside'
        labelText='Main Menu'
        onChange={handleChangeMainMenuItem}
        options={[
          { code: '', name: '< Select >' },
          ...navItemsMain
            .filter(
              (item) =>
                demo ||
                !item.permissions ||
                item.permissions.some((permission) =>
                  userFromDb.permissions?.includes(permission)
                )
            )
            .map((item) => ({
              code: item.link,
              name: item.name,
            })),
        ]}
        selectClasses='ignore-detect-click-outside'
        selectContainerClasses='ignore-detect-click-outside'
        value={mainMenuItem}
      />
      <SelectStandard
        containerClasses='ignore-detect-click-outside'
        labelText='Add New'
        marginTop='10px'
        onChange={handleChangeAddNewItem}
        options={[
          { code: '', name: '< Select >' },
          ...navItemsNew
            .filter(
              (item) =>
                demo ||
                !item.permissions ||
                item.permissions.some((permission) =>
                  userFromDb.permissions?.includes(permission)
                )
            )
            .map((item) => ({
              code: item.link,
              name: item.name,
            })),
        ]}
        selectClasses='ignore-detect-click-outside'
        selectContainerClasses='ignore-detect-click-outside'
        value={addNewItem}
      />

      <HeaderSecondary marginTop='10px' text='Custom' />

      <InputStandard
        classes='ignore-detect-click-outside add-nav-item-input'
        inputClasses='ignore-detect-click-outside'
        isRequired
        labelText='Link'
        onChange={handleChangeCustomLink}
        placeholder='Link'
        value={customLink}
        wrap='wrap'
      />

      <InputStandard
        classes='ignore-detect-click-outside add-nav-item-input'
        inputClasses='ignore-detect-click-outside'
        isRequired
        labelText='Name'
        onChange={handleChangeCustomName}
        placeholder='Name'
        value={customName}
        wrap='wrap'
      />

      <SelectStandard
        containerClasses='ignore-detect-click-outside select-icon-nav-favourites'
        isRequired
        labelText='Icon'
        onChange={handleChangeCustomIcon}
        options={[{ code: '', name: '< Select >' }, ...iconsList]}
        selectClasses='ignore-detect-click-outside'
        selectContainerClasses='ignore-detect-click-outside'
        value={customIcon}
      />

      <SelectStandard
        containerClasses='ignore-detect-click-outside select-icon-nav-favourites'
        labelText='Sub-icon'
        onChange={handleChangeCustomSubIcon}
        options={[{ code: '', name: '< Select >' }, ...iconsList]}
        selectClasses='ignore-detect-click-outside'
        selectContainerClasses='ignore-detect-click-outside'
        value={customSubIcon}
      />

      {customIcon ? (
        <FlexContainer
          classes='ignore-detect-click-outside'
          flexDirection='column'
        >
          <HeaderSecondary marginTop='5px' text='Icon Preview:' />

          <div className='ignore-detect-click-outside new-custom-nav-icon-preview'>
            {customSubIcon ? (
              <IconWithSub
                classes='ignore-detect-click-outside'
                subClasses='ignore-detect-click-outside'
                iconName={customIcon}
                subIconName={customSubIcon}
              />
            ) : (
              <Icon
                className='ignore-detect-click-outside'
                style={{ width: '30px', height: '30px' }}
                iconName={customIcon}
              />
            )}
          </div>
        </FlexContainer>
      ) : (
        <></>
      )}

      <ButtonStandardNoFill
        classes='ignore-detect-click-outside'
        disabled={
          !mainMenuItem &&
          !addNewItem &&
          (!customName || !customLink || !customIcon)
        }
        iconClasses='ignore-detect-click-outside'
        iconClassName='ignore-detect-click-outside'
        iconName='plus'
        marginTop='10px'
        onClick={handleAddNewFavourite}
        text='Add'
        typeStyle='type-4'
      />
    </FlexContainer>
  );
};

export default AddFavourite;
