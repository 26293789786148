import './AdminSection.css';

import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useAlert } from 'react-alert';

import updateAdminSections from '../../../utils/createScorecardUtils/updateAdminSections';
import ButtonIcon from '../../Elements/ButtonIcon';

const AdminSection = memo(function Card({
  adminSections,
  currentlyEditing,
  findCard,
  handleSetHasChanges,
  hasChangePermission,
  id,
  isDndOn,
  moveCard,
  section,
  setAdminSections,
  setControl,
  setCurrentlyEditing,
  setDropdownList,
  setIsNewListChecked,
  setIsRequired,
  setSectionName,
}) {
  const ItemTypes = {
    CARD: 'admin-card',
  };
  const originalIndex = findCard(id).index;
  const [, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        handleSetHasChanges?.();

        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      canDrop: () => false,
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );
  // const opacity = isDragging ? 0 : 1;

  const alert = useAlert();

  const { title, control, required, uniqueRef } = section;

  const dndRefFunc = (node) => drag(drop(node));

  let addedSectionStyle = 'create-admin-section-grid';

  if (!isDndOn) addedSectionStyle += ' cursor-default';
  if (currentlyEditing === uniqueRef)
    addedSectionStyle += ' edit-section-background';

  return (
    <li
      id={uniqueRef}
      className={addedSectionStyle}
      ref={isDndOn ? dndRefFunc : null}
    >
      <div className='create-as-group-left'>
        {hasChangePermission ? (
          <>
            <ButtonIcon
              iconClass='admin-section-delete-icon'
              width='15px'
              height='15px'
              iconName='trash'
              style={{
                marginLeft: '8px',
                marginRight: '16px',
              }}
              title='Delete'
              onClick={() => {
                updateAdminSections({
                  del: {
                    sectionUniqueRef: uniqueRef,
                    adminSections: adminSections,
                    setAdminSections: setAdminSections,
                    currentlyEditing: currentlyEditing,
                    setCurrentlyEditing: setCurrentlyEditing,
                  },
                });

                handleSetHasChanges?.();

                alert.success(`Deleted: ${title}`);
              }}
            />

            <ButtonIcon
              iconClass='admin-section-edit-icon'
              width='15px'
              height='15px'
              iconName='edit-solid'
              style={{ marginRight: '8px' }}
              title='Edit'
              onClick={() => {
                updateAdminSections({
                  edit: {
                    sectionToEdit: section,
                    setSectionName: setSectionName,
                    setControl: setControl,
                    setDropdownList: setDropdownList,
                    setIsNewListChecked: setIsNewListChecked,
                    setIsRequired: setIsRequired,
                    currentlyEditing: currentlyEditing,
                    setCurrentlyEditing: setCurrentlyEditing,
                  },
                });
              }}
            />
          </>
        ) : (
          <>
            <div style={{ width: '5px', height: '15px' }}></div>

            <div style={{ width: '5px', height: '15px' }}></div>
          </>
        )}

        {title ? (
          <p className='create-as-title-text'>{title}</p>
        ) : (
          <p className='create-as-title-none'>No title</p>
        )}
      </div>
      <div className='create-as-group-right'>
        {control ? (
          control === 'DD: no list set' ? (
            <p className='create-as-control-no-list'>{control}</p>
          ) : (
            <p className='create-as-control-text'>{control}</p>
          )
        ) : (
          <p className='create-as-control-none'>No control</p>
        )}

        <p
          className={
            required === 'Required' ? 'create-as-req' : 'create-as-opt'
          }
        >
          {required}
        </p>
      </div>
    </li>
  );
});

export default AdminSection;
