import { prices } from '../../utils/constants';

import HeaderSecondary from '../Elements/HeaderSecondary';
import Text from '../Elements/Text';
import tickSmall from '../../images/tick-small.png';

import './PricingTier.css';

const PricingTier = ({ features, tier }) => {
  return (
    <div className='pricing-tier'>
      <HeaderSecondary text={tier} />

      {tier === 'QA' ? (
        <Text classes='qa-subheader' text='(At least one required)' />
      ) : (
        <></>
      )}

      <ul>
        {features.map((feature, index) => (
          <li className='about-features-main-list-item' key={index}>
            <img className='about-features-tick' src={tickSmall} alt='' />

            <p>{feature}</p>
          </li>
        ))}
      </ul>

      <HeaderSecondary
        classes='pricing-tier-amount'
        text={`£${prices[tier.toLowerCase()]} pp monthly`}
      />
    </div>
  );
};

export default PricingTier;
