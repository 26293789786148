import { BreadcrumbsContext } from '../../../contexts';
import { useContext, useEffect } from 'react';

import DashboardContent from './DashboardContent';
import MainOnly from '../MainAndOptions/MainOnly';

// import './Dashboard.css';

const Dashboard = ({ dashboardId, demo }) => {
  const { setBreadcrumbs } = useContext(BreadcrumbsContext);

  useEffect(() => {
    setBreadcrumbs([['Dashboard', 'dashboard']]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainOnly>
      <DashboardContent dashboardId={dashboardId} demo={demo} />
    </MainOnly>
  );
};

export default Dashboard;
