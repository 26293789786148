import Icon from './Icon';
import './InputInvalidIcon.css';

const InputInvalidIcon = ({ right = 10, top }) => {
  const style = { right: `${right}px` };

  if (top) style.top = `${top}px`;

  return (
    <div className='input-invalid-icon' style={style}>
      <Icon iconName='circle-exclamation' />
    </div>
  );
};

export default InputInvalidIcon;
