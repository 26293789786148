import ButtonIconOnly from './ButtonIconOnly';
import ButtonStandardNoFill from './ButtonStandardNoFill';

import './NewScreenControlPanel.css';

const NewScreenControlPanel = ({
  handleAddLine,
  handleSave,
  saveButtonRef,
  saveButtonDisabled,
}) => {
  return (
    <div className='new-screen-control-panel'>
      <ButtonIconOnly
        margin='5px'
        iconName='plus'
        onClick={handleAddLine}
        title='Add line'
      />
      <ButtonStandardNoFill
        margin='5px'
        disabled={saveButtonDisabled}
        iconName='save-solid'
        onClick={handleSave}
        text='Save'
        buttonRef={saveButtonRef}
        typeStyle='type-4'
      />
    </div>
  );
};

export default NewScreenControlPanel;
