import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { capitalise, doArrayValuesMatchAnyOrder } from '../../../utils/helpers';
import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiDataServiceReports from '../../../api/reports';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InfoText from '../../Elements/InfoText';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import MainOnly from '../MainAndOptions/MainOnly';
import moment from 'moment';
import Panels from '../../Elements/Panels';
import PanelsControlBar from '../../Elements/PanelsControlBar';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import PanelsSections from '../../Elements/PanelsSections';
import Text from '../../Elements/Text';
import Toggle from '../../Elements/Toggle';

import './ReportsHotTopics.css';

const ReportsHotTopics = ({ demo }) => {
  const navigate = useNavigate();

  const alert = useAlert();

  const { getAccessTokenSilently } = useAuth0();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const hasChangePermission =
    demo || userFromDb.permissions?.includes('reports.change');

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [bragAmber, setBragAmber] = useState('');
  const [bragGreen, setBragGreen] = useState('');
  const [bragRed, setBragRed] = useState('');
  const [bragInvalid, setBragInvalid] = useState('');

  const [ragFromApi, setRagFromApi] = useState();

  const [reportDescription, setReportDescription] = useState('');

  const [filteredSortedRagFromApi, setFilteredSortedRagFromApi] = useState();

  const [reportId, setReportId] = useState();

  const [hotTopicsScorecardSections, setHotTopicsScorecardSections] = useState(
    {}
  );
  const [
    hotTopicsScorecardSectionsFromApi,
    setHotTopicsScorecardSectionsFromApi,
  ] = useState({});

  const [hotTopicsStats, setHotTopicsStats] = useState();

  const [hotTopicsSectionAverages, setHotTopicsSectionAverages] = useState();

  const [scorecardData, setScorecardData] = useState();

  const validateBrag = (newBragVal, changedBrag) => {
    const bragSettings = {
      r: bragRed?.toString(),
      a: bragAmber?.toString(),
      g: bragGreen?.toString(),
    };

    if (newBragVal && changedBrag) {
      bragSettings[changedBrag] = newBragVal;
    }

    const bragSettingsArr = Object.values(bragSettings)
      .filter((brag) => brag)
      .sort((a, b) => a - b);

    let isOutsideBounds = false;

    bragSettingsArr.forEach((brag) => {
      if (!/^[0-9][0-9]?$|^100$/.test(brag)) isOutsideBounds = true;
    });

    let invalidMsg = '';

    if (isOutsideBounds) {
      invalidMsg += 'Must be whole numbers from 0 to 100';
    }

    if (new Set(bragSettingsArr).size !== bragSettingsArr.length) {
      if (invalidMsg.length) invalidMsg += '. ';

      invalidMsg += 'No duplicate values';
    }

    setBragInvalid(invalidMsg);
  };

  const getReportData = async (withData) => {
    try {
      setIsLoading(true);

      const {
        scorecardData,
        hotTopicsData,
        hotTopicsSectionAverages,
        hotTopicsStats,
      } = await apiDataServiceReports.getHotTopics({
        portalId: demo ? 'demo' : portalId,
        token: demo ? undefined : await getAccessTokenSilently(),
        userId: demo ? 1 : userId,
        withData,
      });

      if (hotTopicsStats) setHotTopicsStats(hotTopicsStats);

      if (hotTopicsSectionAverages)
        setHotTopicsSectionAverages(hotTopicsSectionAverages);

      if (hotTopicsData) {
        setReportId(hotTopicsData.id);

        setBragAmber(hotTopicsData.report_data.amber ?? '');
        setBragGreen(hotTopicsData.report_data.green ?? '');
        setBragRed(hotTopicsData.report_data.red ?? '');

        setRagFromApi({
          amber: hotTopicsData.report_data.amber ?? '',
          green: hotTopicsData.report_data.green ?? '',
          red: hotTopicsData.report_data.red ?? '',
        });

        setHotTopicsScorecardSectionsFromApi(
          hotTopicsData.report_data.scorecard_sections
        );
        setHotTopicsScorecardSections(
          hotTopicsData.report_data.scorecard_sections
        );

        setReportDescription(hotTopicsData.description);
      }

      if (scorecardData) {
        Object.keys(hotTopicsData.report_data.scorecard_sections).forEach(
          (scorecardVcid) => {
            const scorecardIndex = scorecardData.findIndex(
              (scorecardDataItem) =>
                parseInt(scorecardDataItem.vcid) === parseInt(scorecardVcid)
            );

            scorecardData[scorecardIndex].sections.sort((a, b) => {
              const includesA = hotTopicsData.report_data.scorecard_sections?.[
                scorecardVcid
              ]?.includes(a.id);
              const includesB = hotTopicsData.report_data.scorecard_sections?.[
                scorecardVcid
              ]?.includes(b.id);

              if (includesA !== includesB) return includesA ? -1 : 1;
              else if (a.title !== b.title)
                return a.title.localeCompare(b.title);
              else return a.id - b.id;
            });
          }
        );

        setScorecardData(scorecardData);
      }
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickToEdit = async () => {
    setIsEditing((curr) => !curr);
  };

  const handleToggleSection = (scorecardVcid, section, scorecardIndex) => {
    const copiedHotTopics = { ...hotTopicsScorecardSections };

    const copiedScorecardSections = copiedHotTopics[scorecardVcid]
      ? [...copiedHotTopics[scorecardVcid]]
      : [];

    if (copiedScorecardSections.includes(section.id)) {
      copiedScorecardSections.splice(
        copiedScorecardSections.indexOf(section.id),
        1
      );
    } else {
      copiedScorecardSections.push(section.id);
    }

    if (copiedScorecardSections.length) {
      copiedHotTopics[scorecardVcid] = copiedScorecardSections;
    } else {
      delete copiedHotTopics[scorecardVcid];
    }

    setHotTopicsScorecardSections(copiedHotTopics);

    setTimeout(() => {
      setScorecardData((curr) => {
        const copiedScorecardData = [...curr];

        copiedScorecardData[scorecardIndex].sections.sort((a, b) => {
          const includesA = copiedHotTopics?.[scorecardVcid]?.includes(a.id);
          const includesB = copiedHotTopics?.[scorecardVcid]?.includes(b.id);

          if (includesA !== includesB) return includesA ? -1 : 1;
          else if (a.title !== b.title) return a.title.localeCompare(b.title);
          else return a.id - b.id;
        });

        return copiedScorecardData;
      });
    }, 300);

    setHasChanges(true);
  };

  const hasBragChanged = (currentBrag, apiBrag) => {
    const currentEmpty = currentBrag !== 0 && !currentBrag;
    const apiEmpty = apiBrag !== 0 && !apiBrag;

    if (currentEmpty !== apiEmpty) return true;

    const currentNum = parseInt(apiBrag);
    const apiNum = parseInt(currentBrag);

    return currentNum !== apiNum;
  };

  const saveChanges = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (bragInvalid) return alert.error('RAG values are invalid');

    setIsSaving(true);

    const hotTopicsScorecards = Object.keys(hotTopicsScorecardSections);

    const hotTopicsFromApiScorecards = Object.keys(
      hotTopicsScorecardSectionsFromApi
    );

    const doScorecardsMatch = doArrayValuesMatchAnyOrder(
      hotTopicsScorecards,
      hotTopicsFromApiScorecards
    );

    if (doScorecardsMatch) {
      const doSectionsMatch = hotTopicsScorecards.every((scorecardVcid) => {
        return doArrayValuesMatchAnyOrder(
          hotTopicsScorecardSections[scorecardVcid],
          hotTopicsScorecardSectionsFromApi[scorecardVcid]
        );
      });

      if (
        doSectionsMatch &&
        !hasBragChanged(bragAmber, ragFromApi.amber) &&
        !hasBragChanged(bragGreen, ragFromApi.green) &&
        !hasBragChanged(bragRed, ragFromApi.red)
      ) {
        alert.success('Saved');

        setHasChanges(false);
        setIsEditing(false);

        setIsSaving(false);

        return;
      }
    }

    try {
      const sortedHotTopicsScorecardSections = {};

      const scorecardVcids = Object.keys(hotTopicsScorecardSections);

      scorecardVcids.forEach((scorecardVcid) => {
        const copiedArr = [...hotTopicsScorecardSections[scorecardVcid]];

        copiedArr.sort((a, b) => {
          const scorecardDataItem = scorecardData.find(
            (obj) => parseInt(obj.vcid) === parseInt(scorecardVcid)
          );

          return scorecardDataItem.sections
            .find((sec) => sec.id === a)
            .title.localeCompare(
              scorecardDataItem.sections.find((sec) => sec.id === b).title
            );
        });

        sortedHotTopicsScorecardSections[scorecardVcid] = copiedArr;
      });

      const reqBody = {
        updates: [
          {
            id: reportId,
            report_data: {
              scorecard_sections: sortedHotTopicsScorecardSections,
              red: bragRed || bragRed === 0 ? parseInt(bragRed) : null,
              amber: bragAmber || bragAmber === 0 ? parseInt(bragAmber) : null,
              green: bragGreen || bragGreen === 0 ? parseInt(bragGreen) : null,
            },
          },
        ],
        userId: demo ? 1 : userId,
      };

      await apiDataServiceReports.update({
        portalId: demo ? 'demo' : portalId,
        reqBody,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      alert.success('Saved');

      setHasChanges(false);
      setIsEditing(false);

      await getReportData(['hot_topics', 'scorecard_data']);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    validateBrag();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bragRed, bragAmber, bragGreen]);

  useEffect(() => {
    setFilteredSortedRagFromApi(
      ragFromApi
        ? Object.entries(ragFromApi)
            .filter(([_, rag]) => rag || rag === 0)
            .sort((a, b) => a[1] - b[1])
        : undefined
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ragFromApi]);

  useEffect(() => {
    if (!demo && !userFromDb.permissions?.includes('reports.hot_topics')) {
      navigate(`/${demo ? 'demo' : portalId}/reports`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      setBreadcrumbs([
        [
          'Dashboard',
          'dashboard',
          '',
          `/${demo ? 'demo' : portalId}/dashboard`,
        ],
        ['Reports', 'chart-pie', '', `/${demo ? 'demo' : portalId}/reports`],
        ['Hot Topics', 'magnifying-glass'],
      ]);

      getReportData(['hot_topics', 'scorecard_data']);
    }

    return () => {
      setHotTopicsStats();
      setHotTopicsSectionAverages();
      setReportId();
      setBragAmber();
      setBragGreen();
      setBragRed();
      setRagFromApi();
      setHotTopicsScorecardSectionsFromApi();
      setHotTopicsScorecardSections();
      setReportDescription();
      setScorecardData();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LoadingSpinner />;
  return (
    <MainOnly>
      <Panels>
        <PanelsHeaderPrimary
          iconName='chart-pie'
          subIcon='fire'
          text='Hot Topics'
        />

        {hasChangePermission ? (
          <PanelsControlBar>
            <ButtonStandardNoFill
              classes={`grid-control-bar-button ${
                isEditing
                  ? ''
                  : 'cancel-transition-duration data-grid-control-hide-button'
              }`}
              disabled={isLoading || !hasChanges || isSaving}
              iconName='save-solid'
              onClick={saveChanges}
              text='Save Changes'
              typeStyle='type-4'
            />

            <ButtonStandardNoFill
              classes='grid-control-bar-button'
              disabled={isLoading || isSaving}
              iconName='edit-solid'
              onClick={handleClickToEdit}
              text='Edit'
              typeStyle={isEditing ? 'type-3' : 'type-1'}
            />
          </PanelsControlBar>
        ) : (
          <></>
        )}

        <PanelsSections justifyContent={isEditing ? 'center' : 'flex-start'}>
          {isEditing ? (
            <>
              <FlexContainer
                classes='brag-container-hot-topics'
                flexDirection='column'
              >
                <div className='create-brag-container'>
                  <div className='create-brag-item-container'>
                    <label htmlFor='create-brag-r' className='brag-red-label'>
                      Red
                    </label>
                    <input
                      id='create-brag-r'
                      className='create-brag-input'
                      type='number'
                      placeholder='none'
                      value={bragRed}
                      onChange={(event) => {
                        setBragRed(event.target.value);
                        setHasChanges(true);
                      }}
                    />
                  </div>
                  <div className='create-brag-item-container'>
                    <label htmlFor='create-brag-a' className='brag-amber-label'>
                      Amber
                    </label>
                    <input
                      id='create-brag-a'
                      className='create-brag-input'
                      type='number'
                      placeholder='none'
                      value={bragAmber}
                      onChange={(event) => {
                        setBragAmber(event.target.value);
                        setHasChanges(true);
                      }}
                    />
                  </div>

                  <div className='create-brag-item-container'>
                    <label htmlFor='create-brag-g' className='brag-green-label'>
                      Green
                    </label>
                    <input
                      id='create-brag-g'
                      className='create-brag-input'
                      type='number'
                      placeholder='none'
                      value={bragGreen}
                      onChange={(event) => {
                        setBragGreen(event.target.value);
                        setHasChanges(true);
                      }}
                    />
                  </div>
                </div>

                {bragInvalid && (
                  <p className='create-brag-selection-invalid'>{bragInvalid}</p>
                )}
              </FlexContainer>

              {scorecardData?.map((scorecardDataItem, scorecardIndex) => (
                <PanelsSection
                  classes='hot-topics-scorecard'
                  noMaxWidth
                  typeStyle='type-2'
                  key={scorecardDataItem.vcid}
                >
                  <FlexContainer
                    classes='hot-topics-scorecard-table-container'
                    alignItems='flex-start'
                  >
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={2}>
                            <HeaderSecondary text={scorecardDataItem.name} />
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {scorecardDataItem.sections.map((section, index) => {
                          return (
                            <tr
                              className={
                                index === 0
                                  ? 'hot-topics-scorecard-first-grid-row'
                                  : ''
                              }
                              key={section.id}
                            >
                              <td>
                                <label htmlFor={section.id}>
                                  {section.title}
                                </label>
                              </td>
                              <td>
                                <Toggle
                                  disabled={isSaving || isLoading}
                                  id={section.id}
                                  onChange={() =>
                                    handleToggleSection(
                                      scorecardDataItem.vcid,
                                      section,
                                      scorecardIndex
                                    )
                                  }
                                  toggled={
                                    hotTopicsScorecardSections?.[
                                      scorecardDataItem.vcid
                                    ]?.includes(section.id)
                                      ? true
                                      : false
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </FlexContainer>
                </PanelsSection>
              ))}
            </>
          ) : (
            <>
              {filteredSortedRagFromApi ? (
                <FlexContainer classes='hot-topics-brag-info'>
                  {filteredSortedRagFromApi.map(([colour, rag]) => (
                    <FlexContainer
                      classes='hot-topics-brag-info-label-and-num'
                      key={`${colour}${rag}`}
                    >
                      <InfoText
                        classes={`hot-topics-brag-info-${colour}`}
                        text={capitalise(colour)}
                      />
                      <InfoText classes='hot-topics-brag-info-num' text={rag} />
                    </FlexContainer>
                  ))}
                </FlexContainer>
              ) : (
                <></>
              )}

              <PanelsSection typeStyle='type-2' width='100%' noMaxWidth>
                <Text textAlign='center' text={reportDescription} />
              </PanelsSection>

              {scorecardData
                ?.filter(
                  (scorecardDataItem) =>
                    hotTopicsScorecardSectionsFromApi[scorecardDataItem.vcid]
                )
                .map((scorecardDataItem) => {
                  return (
                    <PanelsSection
                      classes='hot-topics-stats'
                      noMaxWidth
                      typeStyle='type-2'
                      key={scorecardDataItem.vcid}
                    >
                      <table className='hot-topics-stats-table'>
                        <thead>
                          <tr>
                            <th className='hot-topics-stats-table-left-field'>
                              <HeaderSecondary text={scorecardDataItem?.name} />
                            </th>
                            {[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map(
                              (weeksAgo) => (
                                <th key={weeksAgo}>
                                  {moment().subtract(weeksAgo, 'weeks').week()}
                                </th>
                              )
                            )}
                            <th className='hot-topics-cell-avg'>AVG</th>
                          </tr>
                        </thead>

                        <tbody>
                          {hotTopicsScorecardSectionsFromApi[
                            scorecardDataItem.vcid
                          ].map((sectionId, index, arr) => (
                            <tr
                              className={
                                index === 0
                                  ? 'hot-topics-stats-table-first-row'
                                  : ''
                              }
                              key={sectionId}
                            >
                              <td className='hot-topics-stats-table-left-field'>
                                {
                                  scorecardDataItem?.sections.find(
                                    (section) => section.id === sectionId
                                  ).title
                                }
                              </td>

                              {[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map(
                                (weeksAgoStats, index) => {
                                  const cellStat =
                                    hotTopicsStats.find(
                                      (hotTopicStat) =>
                                        hotTopicStat.section_id === sectionId &&
                                        moment(
                                          hotTopicStat.week_start_date
                                        ).week() ===
                                          moment()
                                            .subtract(weeksAgoStats, 'weeks')
                                            .week()
                                    )?.pass_rate || '-';

                                  return (
                                    <td
                                      className={`hot-topics-stats-cell ${
                                        index === 0
                                          ? 'first-hot-topics-stats-cell'
                                          : ''
                                      } hot-topics-stats-cell-colour-${
                                        filteredSortedRagFromApi.find(
                                          ([_, num], index) =>
                                            parseInt(cellStat) >=
                                              parseInt(num) &&
                                            (filteredSortedRagFromApi.length -
                                              1 ===
                                              index ||
                                              parseInt(cellStat) <
                                                parseInt(
                                                  filteredSortedRagFromApi[
                                                    index + 1
                                                  ][1]
                                                ))
                                        )?.[0] || 'none'
                                      }`}
                                      key={weeksAgoStats}
                                    >
                                      {cellStat}
                                    </td>
                                  );
                                }
                              )}

                              <td
                                className={`hot-topics-stats-cell hot-topics-cell-avg hot-topics-stats-cell-colour-${
                                  filteredSortedRagFromApi.find(
                                    ([_, num], index) =>
                                      parseInt(
                                        hotTopicsSectionAverages[sectionId]
                                      ) >= parseInt(num) &&
                                      (filteredSortedRagFromApi.length - 1 ===
                                        index ||
                                        parseInt(
                                          hotTopicsSectionAverages[sectionId]
                                        ) <
                                          parseInt(
                                            filteredSortedRagFromApi[
                                              index + 1
                                            ][1]
                                          ))
                                  )?.[0] || 'none'
                                } ${
                                  index === arr.length - 1
                                    ? 'last-hot-topics-cell-avg'
                                    : ''
                                }`}
                              >
                                {hotTopicsSectionAverages[sectionId] || '-'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </PanelsSection>
                  );
                })}
            </>
          )}
        </PanelsSections>
      </Panels>
    </MainOnly>
  );
};

export default ReportsHotTopics;
