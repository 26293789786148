const updateScoringCommentsBoxes = (command) => {
  const { add } = command;

  if (add) {
    const { type, scoringSections, setScoringSections, setShouldScrollToBtm } =
      add;

    const copiedScoringSections = scoringSections.map((section, index) => {
      return { ...section, id: index };
    });

    const newUniqueRef = `${Math.floor(
      Math.random() * 100000000000
    )}${Date.now()}`;

    copiedScoringSections.push({
      type: type,
      uniqueRef: newUniqueRef,
      id: copiedScoringSections.length,
    });
    setShouldScrollToBtm(true);
    setScoringSections(copiedScoringSections);
  }
};

export default updateScoringCommentsBoxes;
