import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserPortal } from '../../../contexts';
import {
  shouldNavigateOnNotFound,
  validatePresenceRequired,
} from '../../../utils/helpers';

import apiDataService from '../../../api/scorecard-templates';
import apiDataServiceDdLists from '../../../api/dd-lists';
import apiDataServiceScorecards from '../../../api/scorecards';
import BulletPoint from '../../Elements/BulletPoint';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import MainOnly from '../MainAndOptions/MainOnly';
import moduleStyles from './NewScorecard.module.css';
import portalsDataService from '../../../api/portals';
import ScorecardsNewContent from './ScorecardsNewContent';

const ScorecardsNew = ({ demo, fromDrafts, fromTemplates }) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const hasScorecardsChangePermission =
    demo || userFromDb.permissions?.includes('scorecards.change');

  const hasScorecardTemplatesChangePermission =
    demo || userFromDb.permissions?.includes('scorecard_templates.change');

  const { getAccessTokenSilently } = useAuth0();

  const alert = useAlert();

  const [isLoadingScorecard, setIsLoadingScorecard] = useState(true);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);

  const [ddLists, setDdLists] = useState();

  const [scorecardTemplates, setScorecardTemplates] = useState();

  const [scorecardName, setScorecardName] = useState('');
  const [scorecardDescription, setScorecardDescription] = useState('');

  const [adminSections, setAdminSections] = useState([]);
  const [scoringSections, setScoringSections] = useState([]);

  const [passMark, setPassMark] = useState('');
  const [bragBlack, setBragBlack] = useState('');
  const [bragRed, setBragRed] = useState('');
  const [bragAmber, setBragAmber] = useState('');
  const [bragGreen, setBragGreen] = useState('');

  const [sectionSelect, setSectionSelect] = useState('');
  const [sectionSelectPos, setSectionSelectPos] = useState('');
  const [sectionSelectNeg, setSectionSelectNeg] = useState('');

  const [orgName, setOrgName] = useState('');
  const [imageFile, setImageFile] = useState('');

  //

  const [currentlyEditingAdmin, setCurrentlyEditingAdmin] = useState('');
  const [currentlyEditingCat, setCurrentlyEditingCat] = useState('');
  const [currentlyEditingSec, setCurrentlyEditingSec] = useState('');
  const [categoryCount, setCategoryCount] = useState(0);
  const [sectionCount, setSectionCount] = useState(0);
  const [isUsingDefault, setIsUsingDefault] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');

  const [status, setStatus] = useState('');

  const [defaultOptions, setDefaultOptions] = useState();

  const saveScorecard = async (type) => {
    if (demo) return alert.info('Unable to save changes in demo');

    const invalid =
      sectionSelectInvalid ||
      sectionSelectPosInvalid ||
      sectionSelectNegInvalid ||
      scorecardNameInvalid ||
      (statusInvalid && type !== 'draft' && type !== 'template') ||
      passMarkInvalid ||
      bragInvalid;

    if (!invalid) {
      const typeStatuses = {
        draft: 'draft',
        scorecard: status,
        template: 'template',
      };

      const sc_secs_cat = [];
      const sc_secs_comments = [];
      const sc_secs_scoring = [];
      const posNeg =
        sectionSelect === 'custom'
          ? [sectionSelectPos, sectionSelectNeg]
          : sectionSelect === 'pf'
          ? ['Pass', 'Fail']
          : ['Yes', 'No'];

      let prevCatPosition;
      let prevSecPosition;

      scoringSections.forEach(
        (
          {
            csm,
            description,
            originalRequired,
            ra,
            title,
            type: scoringType,
            weight,
          },
          index
        ) => {
          if (scoringType === 'scoring-category') {
            sc_secs_cat.push({
              position: index + 1,
              title,
              status: typeStatuses[type],
            });

            prevCatPosition = index + 1;
          } else if (scoringType === 'comments-box') {
            sc_secs_comments.push({
              position: index + 1,
              cat_position: prevCatPosition,
              sec_position: prevSecPosition,
              status: typeStatuses[type],
            });
          } else if ((scoringType = 'scoring-section')) {
            sc_secs_scoring.push({
              position: index + 1,
              cat_position: prevCatPosition,
              title,
              description,
              weight: parseFloat(weight) || null,
              ra: parseFloat(ra) || null,
              csm: parseFloat(csm) || null,
              required: originalRequired,
              status: typeStatuses[type],
            });

            prevSecPosition = index + 1;
          }
        }
      );

      // if (sectionSelect === 'custom')
      //   markingChoice.push(sectionSelectPos, sectionSelectNeg);
      // else if (sectionSelect === 'pf') markingChoice.push('Pass', 'Fail');
      // else if (sectionSelect === 'yn') markingChoice.push('Yes', 'No');

      const reqBody = {
        scorecards: [
          {
            name: scorecardName,
            description: scorecardDescription,
            pass_mark: passMark === '' ? null : passMark,
            black: bragBlack === '' ? null : bragBlack,
            red: bragRed === '' ? null : bragRed,
            amber: bragAmber === '' ? null : bragAmber,
            green: bragGreen === '' ? null : bragGreen,
            pos: posNeg[0],
            neg: posNeg[1],
            org_name: orgName,
            logo_url: logoUrl,
            status: typeStatuses[type],
            sc_secs_admin: adminSections.map(
              (
                {
                  originalControl,
                  originalDdListId,
                  originalDdListTable,
                  originalRequired,
                  title,
                },
                index
              ) => {
                const amendedDdListId =
                  originalDdListId === null ||
                  originalDdListId === undefined ||
                  originalDdListId === ''
                    ? null
                    : parseInt(originalDdListId);

                return {
                  control: originalControl,
                  dd_list_id: amendedDdListId,
                  dd_list_table: originalDdListTable || null,
                  position: index + 1,
                  required: originalRequired,
                  title,
                  status: typeStatuses[type],
                };
              }
            ),
            sc_secs_scoring,
            sc_secs_cat,
            sc_secs_comments,
          },
        ],
        userId: demo ? 1 : userId,
      };

      try {
        await apiDataServiceScorecards.post({
          portalId: demo ? 'demo' : portalId,
          reqBody,
          token: demo ? undefined : await getAccessTokenSilently(),
        });

        return true;
      } catch (error) {
        alert.error(handleApiError(error), { timeout: 10000 });
      }
    } else {
      alert.error(
        <div>
          <p>Unable to save.</p>
          <p>Please check the following areas for errors:</p>
          <br />
          <ul>
            {[
              { validation: scorecardNameInvalid, msg: ' Scorecard Name' },
              { validation: passMarkInvalid, msg: ' Pass Mark' },
              {
                validation:
                  statusInvalid && type !== 'draft' && type !== 'template',
                msg: ' Status',
              },
              { validation: bragInvalid, msg: ' RAG' },
              { validation: sectionSelectInvalid, msg: ' Marking Choices' },
              {
                validation: sectionSelectPosInvalid,
                msg: ' Positive Marking Choice',
              },
              {
                validation: sectionSelectNegInvalid,
                msg: ' Negative Marking Choice',
              },
            ]
              .filter(({ validation }) => validation)
              .map(({ msg }, index) => {
                return (
                  <li key={index}>
                    <BulletPoint />

                    <span>{msg}</span>
                  </li>
                );
              })}
          </ul>
        </div>,
        { timeout: 10000 }
      );

      setShowInvalid(true);
    }
  };

  // VALIDATIONS

  const [showInvalid, setShowInvalid] = useState(false);

  const [sectionSelectInvalid, setSectionSelectInvalid] = useState('');
  const [sectionSelectPosInvalid, setSectionSelectPosInvalid] = useState();
  const [sectionSelectNegInvalid, setSectionSelectNegInvalid] = useState();

  const [scorecardNameInvalid, setScorecardNameInvalid] = useState();
  const [passMarkInvalid, setPassMarkInvalid] = useState();

  const [bragInvalid, setBragInvalid] = useState('');
  const [statusInvalid, setStatusInvalid] = useState('');

  const validateMarkingChoice = (type) => {
    if (sectionSelect === 'custom') {
      const notPresent = validatePresenceRequired(
        type === 'pos' ? sectionSelectPos : sectionSelectNeg,
        `${type === 'pos' ? 'Positive' : 'Negative'} marking choice`
      );

      if (notPresent) return [notPresent];

      if (sectionSelectPos === sectionSelectNeg)
        return ['Positive and negative marking choices must not be the same'];

      if (
        (type === 'pos' && sectionSelectPos.toLowerCase().trim() === 'na') ||
        (type === 'neg' && sectionSelectNeg.toLowerCase().trim() === 'na')
      )
        return ["Can't be NA"];
    }

    return '';
  };

  const validateBrag = (newBragVal, changedBrag) => {
    const bragSettings = {
      b: bragBlack.toString(),
      r: bragRed.toString(),
      a: bragAmber.toString(),
      g: bragGreen.toString(),
    };

    if (newBragVal && changedBrag) {
      bragSettings[changedBrag] = newBragVal;
    }

    const bragSettingsArr = Object.values(bragSettings)
      .filter((brag) => brag)
      .sort((a, b) => a - b);

    let isOutsideBounds = false;

    bragSettingsArr.forEach((brag) => {
      if (!/^[0-9][0-9]?$|^100$/.test(brag)) isOutsideBounds = true;
    });

    let invalidMsg = '';

    if (bragSettingsArr.length > 0 && bragSettingsArr[0] !== '0')
      invalidMsg += 'Lowest RAG value must be 0';

    if (isOutsideBounds) {
      if (invalidMsg.length) invalidMsg += '. ';

      invalidMsg += 'Must be whole numbers from 0 to 100';
    }

    if (new Set(bragSettingsArr).size !== bragSettingsArr.length) {
      if (invalidMsg.length) invalidMsg += '. ';

      invalidMsg += 'No duplicate values';
    }

    setBragInvalid(invalidMsg);
  };

  useEffect(() => {
    setSectionSelectPosInvalid(validateMarkingChoice('pos'));
    setSectionSelectNegInvalid(validateMarkingChoice('neg'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionSelectPos, sectionSelectNeg]);

  useEffect(() => {
    if (sectionSelect !== 'custom') {
      if (sectionSelectPosInvalid !== '') setSectionSelectPosInvalid('');
      if (sectionSelectNegInvalid !== '') setSectionSelectNegInvalid('');
    } else {
      setSectionSelectPosInvalid(validateMarkingChoice('pos'));
      setSectionSelectNegInvalid(validateMarkingChoice('neg'));
    }

    if (!sectionSelect) {
      const val = validatePresenceRequired(sectionSelect, "'Marking Choices'");

      if (val) setSectionSelectInvalid([val]);
      else setSectionSelectInvalid(null);
    } else if (sectionSelectInvalid !== '') setSectionSelectInvalid('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionSelect]);

  useEffect(() => {
    const val = validatePresenceRequired(scorecardName, "'Scorecard Name'");

    if (val) setScorecardNameInvalid([val]);
    else setScorecardNameInvalid(null);
  }, [scorecardName]);

  useEffect(() => {
    const val = validatePresenceRequired(status, "'Status'");

    if (val) setStatusInvalid([val]);
    else setStatusInvalid(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    validateBrag();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bragBlack, bragRed, bragAmber, bragGreen]);

  useEffect(() => {
    // const val = validatePresenceRequired(scorecardName, "'Scorecard Name'");
    // if (val) setScorecardNameInvalid([val]);
    // else setScorecardNameInvalid(null);

    if (passMark && !/^[1-9][0-9]?$|^100$/.test(passMark))
      setPassMarkInvalid(['Must be a whole number from 1 to 100']);
    else setPassMarkInvalid('');
  }, [passMark]);

  // END OF VALIDATIONS

  const getSectionPos = () => {
    if (sectionSelect === 'pf') return 'Pass';
    else if (sectionSelect === 'yn') return 'Yes';
    else return sectionSelectPos;
  };
  const getSectionNeg = () => {
    if (sectionSelect === 'pf') return 'Fail';
    else if (sectionSelect === 'yn') return 'No';
    else return sectionSelectNeg;
  };

  const getAllTemplates = async () => {
    setIsLoadingTemplates(true);

    try {
      const data = await apiDataService.getAll({
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setScorecardTemplates(data);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoadingTemplates(false);
    }
  };

  const getDefaultOptions = async () => {
    // setIsLoadingTemplates(true);

    try {
      const {
        portal: { scorecard_defaults },
      } = await portalsDataService.getById({
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setDefaultOptions(scorecard_defaults);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      // setIsLoadingTemplates(false);
    }
  };

  const getDdLists = async () => {
    try {
      const { ddLists } = await apiDataServiceDdLists.getAll({
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setDdLists(ddLists);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const getDataService = (type) => {
    if (type === 'portal') return apiDataService.getPortalTemplateById;
    else if (type === 'public') return apiDataService.getPublicTemplateById;
    else if (type === 'existing')
      return apiDataServiceScorecards.getScorecardById;
  };

  const fillScorecardWithTemplate = async (type, id) => {
    if (!type) {
      setScorecardName('');
      setScorecardDescription('');
      setAdminSections([]);
      setScoringSections([]);
      setCategoryCount(0);
      setSectionCount(0);
      setIsUsingDefault(false);
      setPassMark('');
      setBragBlack('');
      setBragRed('');
      setBragAmber('');
      setBragGreen('');
      setSectionSelect('');
      setSectionSelectPos('');
      setSectionSelectNeg('');
      setOrgName('');
      setImageFile('');
      setLogoUrl('');
      setCurrentlyEditingAdmin('');
      setCurrentlyEditingCat('');
      setCurrentlyEditingSec('');
      setStatus('');
    } else {
      setIsLoadingScorecard(true);

      try {
        const params = {
          token: demo ? undefined : await getAccessTokenSilently(),
          userId: demo ? 1 : userId,
        };

        if (type === 'existing') {
          params.portalId = demo ? 'demo' : portalId;
          params.scorecardId = id;
          if (fromDrafts) params.fromDrafts = true;
        } else if (type === 'portal') {
          params.portalId = demo ? 'demo' : portalId;
          params.templateId = id;
        } else if (type === 'public') {
          params.templateId = id;
        }

        const dataService = getDataService(type);

        const { scorecard } = await dataService(params);

        const newAdminSections = scorecard.adminSecs.map((sec, index) => {
          let newControl = sec.control === 'Drop down' ? 'DD: ' : sec.control;

          let originalDdListId = '';
          let originalDdListTable = '';
          let originalDdList = '';

          if (sec.control === 'Drop down') {
            newControl = 'DD: ';

            if (sec.dd_list_id) {
              const sectionDropDownInfo = ddLists.find(
                (ddList) =>
                  ddList.id === sec.dd_list_id &&
                  ddList.table === sec.dd_list_table
              );

              if (sectionDropDownInfo) {
                originalDdList = sectionDropDownInfo.name;
                originalDdListTable = sectionDropDownInfo.table;
                originalDdListId = sectionDropDownInfo.id;

                newControl += originalDdList;
              } else newControl += 'no list set';
            } else newControl += 'no list set';
          }

          const newAdminObj = {
            type: 'admin-section',
            uniqueRef: `a${sec.id}`,
            title: sec.title,
            control: newControl,
            required: sec.required ? 'Required' : 'Optional',
            originalRequired: !!sec.required,
            originalControl: sec.control,
            originalDdList,
            originalDdListId,
            originalDdListTable,
            id: index,
          };

          return newAdminObj;
        });

        let catCount = 0;
        let secCount = 0;

        const newScoringSections = scorecard.scoringSecs.map((category) => {
          if (category.type === 'no-cat') {
            return category.sections.map((sec) => {
              if (sec.type === 'scoring') {
                secCount++;

                return {
                  ...sec,
                  type: 'scoring-section',
                  csm: sec.csm ? parseInt(sec.csm) : '',
                  ra: sec.ra ? parseInt(sec.ra) : '',
                  weight: sec.weight ? parseInt(sec.weight) : '',
                  description: sec.description ?? '',
                  title: sec.title ?? '',
                  required: sec.required ? 'Required' : 'Optional',
                  originalRequired: !!sec.required,
                };
              } else return { ...sec, type: 'comments-box' };
            });
          } else {
            catCount++;

            return [
              {
                title: category.title ?? '',
                type: 'scoring-category',
                position: category.position,
              },
              ...category.sections.map((sec) => {
                if (sec.type === 'scoring') {
                  secCount++;

                  return {
                    ...sec,
                    type: 'scoring-section',
                    csm: sec.csm ? parseInt(sec.csm) : '',
                    ra: sec.ra ? parseInt(sec.ra) : '',
                    weight: sec.weight ? parseInt(sec.weight) : '',
                    description: sec.description ?? '',
                    title: sec.title ?? '',
                    required: sec.required ? 'Required' : 'Optional',
                    originalRequired: !!sec.required,
                  };
                } else return { ...sec, type: 'comments-box' };
              }),
            ];
          }
        });

        const isSectionSelectPf =
          scorecard.pos === 'Pass' && scorecard.neg === 'Fail';
        const isSectionSelectYn =
          scorecard.pos === 'Yes' && scorecard.neg === 'No';

        const isSectionSelectEmpty = !scorecard.pos && !scorecard.neg;

        const isSectionSelectCustom =
          !isSectionSelectEmpty && !isSectionSelectPf && !isSectionSelectYn;

        setScorecardName(scorecard.name);
        setScorecardDescription(scorecard.description);
        setAdminSections(newAdminSections);
        setScoringSections(
          newScoringSections.flat().map((sec, index) => ({
            ...sec,
            id: index,
            uniqueRef: `s${index}`,
          }))
        );
        setCategoryCount(catCount);
        setSectionCount(secCount);
        setIsUsingDefault(false);
        setPassMark(scorecard.pass_mark ?? '');
        setBragBlack(scorecard.black ?? '');
        setBragRed(scorecard.red ?? '');
        setBragAmber(scorecard.amber ?? '');
        setBragGreen(scorecard.green ?? '');
        setSectionSelect(
          isSectionSelectCustom
            ? 'custom'
            : isSectionSelectEmpty
            ? ''
            : isSectionSelectPf
            ? 'pf'
            : 'yn'
        );
        setSectionSelectPos(isSectionSelectCustom ? scorecard.pos || '' : '');
        setSectionSelectNeg(isSectionSelectCustom ? scorecard.neg || '' : '');
        setOrgName(scorecard.org_name ?? '');
        setImageFile(scorecard.logo_url ?? '');
        setLogoUrl(scorecard.logo_url ?? '');
        setStatus(scorecard.status === 'template' ? '' : scorecard.status);
        setCurrentlyEditingAdmin('');
        setCurrentlyEditingCat('');
        setCurrentlyEditingSec('');
      } catch (error) {
        alert.error(handleApiError(error), { timeout: 10000 });

        if (shouldNavigateOnNotFound(error)) {
          navigate(
            `/${demo ? 'demo' : portalId}/${
              fromDrafts ? 'drafts' : 'scorecards'
            }`,
            {
              state: { isNavigating: true },
            }
          );
        }
      } finally {
        setIsLoadingScorecard(false);
      }
    }
  };

  useEffect(() => {
    if (
      !fromDrafts &&
      ((!fromTemplates && !hasScorecardsChangePermission) ||
        (fromTemplates && !hasScorecardTemplatesChangePermission))
    ) {
      navigate(`/${demo ? 'demo' : portalId}/dashboard`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      getAllTemplates();
      getDdLists();
      getDefaultOptions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (ddLists) {
      if (fromDrafts) fillScorecardWithTemplate('existing', id);
      else fillScorecardWithTemplate('public', 11);
    }

    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ddLists, fromTemplates, fromDrafts]);

  if (!ddLists) return <LoadingSpinner />;
  else
    return (
      <MainOnly>
        <ScorecardsNewContent
          adminSections={adminSections}
          bragBlack={bragBlack}
          bragAmber={bragAmber}
          bragGreen={bragGreen}
          bragRed={bragRed}
          categoryCount={categoryCount}
          categoryOnlyList={[]}
          currentlyEditingAdmin={currentlyEditingAdmin}
          currentlyEditingCat={currentlyEditingCat}
          currentlyEditingSec={currentlyEditingSec}
          ddLists={ddLists}
          defaultOptions={defaultOptions}
          demo={demo}
          fillScorecardWithTemplate={fillScorecardWithTemplate}
          fromDrafts={fromDrafts}
          fromDraftsId={id}
          fromTemplates={fromTemplates}
          isLoadingScorecard={isLoadingScorecard}
          isLoadingTemplates={isLoadingTemplates}
          logo={imageFile}
          moduleStyles={moduleStyles}
          orgName={orgName}
          passMark={passMark}
          saveScorecard={saveScorecard}
          scorecardDescription={scorecardDescription}
          scorecardName={scorecardName}
          scorecardNameInvalid={scorecardNameInvalid}
          scorecardTemplates={scorecardTemplates}
          scoringSections={scoringSections}
          sectionCount={sectionCount}
          sectionNeg={getSectionNeg()}
          sectionPos={getSectionPos()}
          setAdminSections={setAdminSections}
          setBragAmber={setBragAmber}
          setBragBlack={setBragBlack}
          setBragGreen={setBragGreen}
          setBragRed={setBragRed}
          setCategoryCount={setCategoryCount}
          setCurrentlyEditingAdmin={setCurrentlyEditingAdmin}
          setCurrentlyEditingCat={setCurrentlyEditingCat}
          setCurrentlyEditingSec={setCurrentlyEditingSec}
          setIsUsingDefault={setIsUsingDefault}
          setPassMark={setPassMark}
          setScorecardDescription={setScorecardDescription}
          setScorecardName={setScorecardName}
          setScoringSections={setScoringSections}
          setSectionCount={setSectionCount}
          showInvalid={showInvalid}
          setDefaultOptions={setDefaultOptions}
          imageFile={imageFile}
          isUsingDefault={isUsingDefault}
          logoUrl={logoUrl}
          saveType={'scorecard'}
          sectionSelect={sectionSelect}
          sectionSelectNeg={sectionSelectNeg}
          sectionSelectPos={sectionSelectPos}
          setLogoUrl={setLogoUrl}
          setImageFile={setImageFile}
          setOrgName={setOrgName}
          setSectionSelect={setSectionSelect}
          setSectionSelectNeg={setSectionSelectNeg}
          setSectionSelectPos={setSectionSelectPos}
          status={status}
          setStatus={setStatus}
          setBragInvalid={setBragInvalid}
          bragInvalid={bragInvalid}
          sectionSelectInvalid={sectionSelectInvalid}
          sectionSelectNegInvalid={sectionSelectNegInvalid}
          sectionSelectPosInvalid={sectionSelectPosInvalid}
          statusInvalid={statusInvalid}
          passMarkInvalid={passMarkInvalid}
        />
      </MainOnly>
    );
};

export default ScorecardsNew;
