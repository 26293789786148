import { BreadcrumbsContext } from '../../../contexts';
import { handleApiError } from '../../../utils/error-handling';
import { Link } from 'react-router-dom';
import { Outlet, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { UserPortal } from '../../../contexts';

import apiDataServiceUsers from '../../../api/users';
import Breadcrumbs from '../../Elements/Breadcrumbs';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import Nav from '../Nav/Nav';

import './PortalLayout.css';

const PortalLayout = ({ demo, isHomeScreen }) => {
  const location = useLocation();

  const navigate = useNavigate();

  const alert = useAlert();

  const { getAccessTokenSilently, isLoading, isAuthenticated, user } =
    useAuth0();

  const { userFromDb, setUserFromDb } = useContext(UserPortal);

  const [isLoadingUserFromDb, setIsLoadingUserFromDb] = useState(false);
  const [isUpdatingLastLogin, setIsUpdatingLastLogin] = useState(false);

  const { portalId } = useParams();

  const { breadcrumbs } = useContext(BreadcrumbsContext);

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [navFavourites, setNavFavourites] = useState([]);

  const [isOptionsOpen, setIsOptionsOpen] = useState();

  const [isRespondingToInvite, setIsRespondingToInvite] = useState(false);

  // issues with all this. The usersub should always be the logged in user.
  // However the dashboard data in the demo should be the demo users.
  // But I think the dashboard gets it's data from the userFromDb object.

  // solution - the userFromDb obj will be the actual users data, and the
  // dashboard will use hardcoded data in the demo, and changes can't be saved anyway.

  const getUserFromDb = async () => {
    try {
      setIsLoadingUserFromDb(true);

      const token = await getAccessTokenSilently();

      const params = [
        `email=${encodeURIComponent(user.email)}`,
        `sub=${encodeURIComponent(user.sub)}`,
      ];
      if (portalId) params.push(`portal_id=${encodeURIComponent(portalId)}`);

      const { users } = await apiDataServiceUsers.getWithoutPortalId({
        token,
        params,
      });

      setUserFromDb(users ? users[0] : {});
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      if (portalId) {
        navigate('/', {
          state: { isNavigating: true },
        });
      }

      setUserFromDb({});
    } finally {
      setIsLoadingUserFromDb(false);
    }
  };

  // useEffect(() => {
  //   console.log('userFromDb', userFromDb);
  // }, [userFromDb]);

  const updateLastLogin = async () => {
    if (!userFromDb?.id || !userFromDb?.portal_id) return;

    try {
      setIsUpdatingLastLogin(true);

      const token = await getAccessTokenSilently();

      await apiDataServiceUsers.update({
        token,
        portalId: userFromDb.portal_id,
        reqBody: {
          updates: [
            { id: userFromDb.id, last_login: new Date().toISOString() },
          ],
        },
      });
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsUpdatingLastLogin(false);
    }
  };

  useEffect(() => {
    if (isRespondingToInvite) return;

    if (user) getUserFromDb();
    else {
      setUserFromDb({});

      if (portalId && !isLoading) {
        navigate('/', {
          state: { isNavigating: true },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, portalId, isRespondingToInvite]);

  useEffect(() => {
    updateLastLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFromDb?.id]);

  useEffect(() => {
    if (!isLoading && portalId && !user) {
      alert.info('Please log in or sign up to access this page', {
        timeout: 5000,
      });

      navigate('/', {
        state: { isNavigating: true },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    setNavFavourites(
      demo
        ? ['dashboard', 'reports', 'evaluations/new', 'scorecards/new']
        : userFromDb?.nav_favourites || []
    );
  }, [demo, userFromDb?.nav_favourites]);

  // useEffect(() => {
  //   const canRender =
  //     (isAuthenticated && parseInt(paramsPortalId) === userFromDb?.portal_id) ||
  //     demo ||
  //     isHomeScreen;

  //   if (!canRender) {
  //     if (
  //       isAuthenticated &&
  //       paramsPortalId &&
  //       parseInt(paramsPortalId) !== userFromDb?.portal_id
  //     ) {
  //       alert.error("You don't have permission to access this portal", {
  //         timeout: 10000,
  //       });

  //       return navigate('/', {
  //         state: { isNavigating: true },
  //       });
  //     }

  //     if (!demo && !isHomeScreen && !isAuthenticated && paramsPortalId) {
  //       alert.error('You need to log in before accessing the portal', {
  //         timeout: 10000,
  //       });

  //       return navigate('/', {
  //         state: { isNavigating: true },
  //       });
  //     }
  //     alert.error('Error accessing portal, please contact support', {
  //       timeout: 10000,
  //     });

  //     return navigate('/', {
  //       state: { isNavigating: true },
  //     });
  //   }
  // }, [isAuthenticated, paramsPortalId]);

  // all of above logic should be incorporated into the getUser func in <App/>

  const canRender =
    (isAuthenticated && parseInt(portalId) === userFromDb?.portal_id) ||
    demo ||
    isHomeScreen;

  const isSubscriptionRequired =
    userFromDb?.portal_id &&
    !userFromDb?.portal_trial_info?.isInTrial &&
    !userFromDb?.portal_has_subscription;

  if (
    isSubscriptionRequired &&
    !isHomeScreen &&
    !demo &&
    location.pathname !== `/${portalId}/billing`
  )
    return (
      <div style={{ margin: '30px', textAlign: 'center' }}>
        Your free trial has ended -{' '}
        <Link to={`/${portalId}/billing`}>
          Click here to arrange a subscription
        </Link>
      </div>
    );
  else if (!canRender || isLoading || (user?.sub && !userFromDb)) {
    return <LoadingSpinner />;
  } else
    return (
      <>
        <Nav
          demo={demo}
          isNavOpen={isNavOpen}
          navFavourites={navFavourites}
          setIsNavOpen={setIsNavOpen}
          setNavFavourites={setNavFavourites}
        />

        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          demo={demo}
          isHomeScreen={isHomeScreen}
          isLoadingUserFromDb={isLoadingUserFromDb}
          isUpdatingLastLogin={isUpdatingLastLogin}
          isNavOpen={isNavOpen}
          isRespondingToInvite={isRespondingToInvite}
          setIsNavOpen={setIsNavOpen}
          setIsRespondingToInvite={setIsRespondingToInvite}
        />

        <div className='main-content'>
          <Outlet
            context={{
              demo,
              getUserFromDb,
              isNavOpen,
              setIsNavOpen,
              isOptionsOpen,
              setIsOptionsOpen,
            }}
          />
        </div>

        {isHomeScreen && !isNavOpen ? (
          <div className='footer-home'>
            {location.pathname !== `/terms` ? (
              <Link to='/terms'>Terms & Conditions</Link>
            ) : (
              <div></div>
            )}

            {location.pathname !== `/privacy` ? (
              <Link to='/privacy'>Privacy Policy</Link>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <></>
        )}
      </>
    );
};

export default PortalLayout;
