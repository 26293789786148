import DataListsShowContent from './DataListsShowContent';
import MainOnly from '../MainAndOptions/MainOnly';

const DataListsShow = ({ demo }) => {
  return (
    <MainOnly>
      <DataListsShowContent demo={demo} />
    </MainOnly>
  );
};

export default DataListsShow;
