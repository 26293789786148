import Icon from './Icon';
import IconWithSub from './IconWithSub';

import './ButtonStandardNoFill.css';

const ButtonStandardNoFill = ({
  alignSelf,
  buttonRef,
  classes = '',
  iconClasses = '',
  iconClassName = '',
  disabled = false,
  iconName,
  margin,
  marginBottom,
  marginTop,
  onClick,
  subIconName,
  text = '',
  title,
  type = 'button',
  typeStyle = 'type-1',
}) => {
  const style = {};

  if (alignSelf) style.alignSelf = alignSelf;
  if (margin) style.margin = margin;
  if (marginBottom) style.marginBottom = marginBottom;
  if (marginTop) style.marginTop = marginTop;

  return (
    <div
      className={`button-standard-no-fill button-standard-no-fill-${typeStyle} ${
        disabled ? 'button-standard-no-fill-disabled' : ''
      } ${classes}`}
      style={style}
    >
      <button
        ref={buttonRef}
        title={title}
        type={type}
        disabled={disabled}
        onClick={onClick}
      >
        {iconName ? (
          <div className={`button-standard-no-fill-icon ${iconClasses}`}>
            {subIconName ? (
              <IconWithSub iconName={iconName} subIconName={subIconName} />
            ) : (
              <Icon className={iconClassName} iconName={iconName} />
            )}
          </div>
        ) : (
          <></>
        )}
        {text}
      </button>
    </div>
  );
};

export default ButtonStandardNoFill;
