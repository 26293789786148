import './DashboardContent.css';
import './grid-styles.css';
import './resizable-styles.css';

import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { UserPortal } from '../../../contexts';

import apiDataServiceReports from '../../../api/reports';
import DynamicGridLayout from './DynamicGridLayout';

const DashboardContent = ({ dashboardId, demo }) => {
  const { userFromDb } = useContext(UserPortal);

  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();

  const [reports, setReports] = useState();

  const getReports = async () => {
    try {
      const { reports } = await apiDataServiceReports.getAll({
        token: demo ? undefined : await getAccessTokenSilently(),
        userId: demo ? 1 : userFromDb.id,
        portalId: demo ? 'demo' : userFromDb.portal_id,
        params: [],
      });

      setReports(reports);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  useEffect(() => {
    getReports();
    return () => {
      setReports();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DynamicGridLayout
      dashboardId={dashboardId}
      demo={demo}
      reports={reports}
    />
  );
};

export default DashboardContent;
