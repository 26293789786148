import { selectOrDeselectArrayValue } from '../../../../utils/helpers';
import { useAlert } from 'react-alert';
import { useContext, useState } from 'react';
import { UserPortal } from '../../../../contexts';

import ButtonStandardNoFill from '../../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../../Elements/FlexContainer';
import HeaderSecondary from '../../../Elements/HeaderSecondary';
import InputStandard from '../../../Elements/InputStandard';
import ManageOption from './ManageOption';
import portalConfirmAlert from '../../../../utils/portalConfirmAlert';
import Text from '../../../Elements/Text';

import './ManageOptions.css';

const ManageOptions = ({
  demo,
  deleteMultiple,
  getAll,
  savedOptions,
  setSavedOptions,
  selectedOptions,
  setSelectedOptions,
  setIsSavingOptions,
  isSavingOptions,
  gridNameSnake,
  page,
  limit,
  query,
  sortBy,
  view,
  customColumns,
  defaultColumns,
  post,
}) => {
  const alert = useAlert();

  const { userFromDb } = useContext(UserPortal);

  const userId = userFromDb.id;

  const handleChangeCheckedOptions = (event) => {
    setCheckedOptions((curr) => {
      return selectOrDeselectArrayValue(parseInt(event.target.value), curr);
    });
  };

  const handleDeleteOptions = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    const confirmed = await portalConfirmAlert({
      message: 'Are you sure you want to delete?',
    });

    if (!confirmed) return;

    setIsDeletingOptions(true);

    try {
      await deleteMultiple({ deletes: checkedOptions, userId });

      if (checkedOptions.includes(selectedOptions?.id))
        setSelectedOptions(null);

      setCheckedOptions([]);

      const { gridOptions } = await getAll();

      setSavedOptions(gridOptions);

      alert.success('Options deleted');
    } catch {
      //handle error

      alert.error('Error deleting options');
    } finally {
      setIsDeletingOptions(false);
    }
  };

  const [isDeletingOptions, setIsDeletingOptions] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState([]);

  const [optionsName, setOptionsName] = useState('');

  const handleSaveOptions = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (!optionsName.length) {
      alert.show('Enter options name');
      return;
    }

    setIsSavingOptions(true);

    try {
      const requestBody = {
        gridOptions: [
          {
            grid_name: gridNameSnake,
            options_name: optionsName,
            options: {
              page,
              limit,
              query,
              sortBy,
              view,
              customColumns: customColumns || defaultColumns.join(',,'),
            },
          },
        ],
        userId: demo ? 1 : userId,
      };

      await post(requestBody);

      const { gridOptions } = await getAll();

      setOptionsName('');
      setSavedOptions(gridOptions);

      alert.success('Options saved');
    } catch (error) {
      alert.error('Error saving options');

      //   // handle error
      //   // setErrorMsg(err.response.data.msg);
    } finally {
      setIsSavingOptions(false);
    }
  };

  return (
    <>
      <FlexContainer
        classes='manage-grid-options'
        flexWrap='wrap-reverse'
        justifyContent='space-between'
        margin='15px 5px 0 5px'
      >
        <FlexContainer classes='manage-grid-options-buttons' flexWrap='wrap'>
          <ButtonStandardNoFill
            disabled={checkedOptions.length === savedOptions.length}
            iconName='square-tick-solid'
            onClick={() => {
              setCheckedOptions(savedOptions.map(({ id }) => id));
            }}
            text='Select all'
          />

          <ButtonStandardNoFill
            disabled={!checkedOptions.length}
            iconName='square-cross'
            onClick={() => setCheckedOptions([])}
            text='De-select all'
          />

          <ButtonStandardNoFill
            classes='delete-options'
            iconName='trash'
            disabled={!checkedOptions.length || isDeletingOptions}
            onClick={handleDeleteOptions}
            text='Delete'
            typeStyle='type-5'
          />
        </FlexContainer>

        <FlexContainer classes='save-grid-options-container' flexWrap='wrap'>
          <InputStandard
            wrap='wrap'
            disabled={isSavingOptions}
            htmlFor={'save-grid-options-input'}
            labelText='Save current options'
            onChange={(event) => {
              setOptionsName(event.target.value);
            }}
            placeholder='Save as...'
            value={optionsName}
          />

          <ButtonStandardNoFill
            classes='save-grid-options-button'
            onClick={handleSaveOptions}
            disabled={isSavingOptions || selectedOptions}
            iconName='save-solid'
            // size='small'
            text='Save'
            typeStyle='type-4'
          />
        </FlexContainer>
      </FlexContainer>

      <HeaderSecondary textAlign='center' text='Saved Options' />

      {savedOptions.length ? (
        <ul className='manage-options'>
          {savedOptions.map((savedOption) => {
            const { id, options_name } = savedOption;

            return (
              <ManageOption
                checked={checkedOptions.includes(id)}
                key={id}
                id={id}
                handleChangeCheckedOptions={handleChangeCheckedOptions}
                optionsName={options_name}
              />
            );
          })}
        </ul>
      ) : (
        <Text margin='5px' text='No saved options' textAlign='center' />
      )}
    </>
  );
};

export default ManageOptions;
