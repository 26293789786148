import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { useAlert } from 'react-alert';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import FlexContainer from '../../Elements/FlexContainer';
import InfoText from '../../Elements/InfoText';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import MainOnly from '../MainAndOptions/MainOnly';
import NewReport from './NewReport';
import Panels from '../../Elements/Panels';
import PanelsSection from '../../Elements/PanelsSection';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import Print from '../../Elements/Print';
import ReportsChart from './ReportsChart';
import ReportsTable from './ReportsTable';
import Text from '../../Elements/Text';

const ReportsNew = ({ demo, isShowScreen, savedReport, id }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const alert = useAlert();

  const navigate = useNavigate();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;

  const hasChangePermission =
    demo || userFromDb.permissions?.includes('reports.change');

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);

  const [reportData, setReportData] = useState();

  const [isEditing, setIsEditing] = useState(false);

  const [isLoadingReportData, setIsLoadingReportData] = useState(false);

  const [description, setDescription] = useState(
    savedReport?.description || ''
  );
  const [name, setName] = useState(savedReport?.name || '');

  useEffect(() => {
    if (!isShowScreen && !hasChangePermission) {
      navigate(`/${demo ? 'demo' : portalId}/reports`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      setBreadcrumbs([
        [
          'Dashboard',
          'dashboard',
          '',
          `/${demo ? 'demo' : portalId}/dashboard`,
        ],
        ['Reports', 'chart-pie', '', `/${demo ? 'demo' : portalId}/reports`],
        isShowScreen
          ? [savedReport?.name || 'Report', 'magnifying-glass']
          : ['New', 'plus'],
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedReport]);

  return (
    <MainOnly>
      <Panels>
        <PanelsHeaderPrimary
          iconName='chart-pie'
          subIcon={isShowScreen ? '' : 'plus'}
          text={isShowScreen ? savedReport?.name || 'Report' : 'New Report'}
        />
        {isShowScreen && !isEditing && savedReport?.description ? (
          <PanelsSection>
            <Text text={savedReport.description} textAlign='center' />
          </PanelsSection>
        ) : (
          <></>
        )}

        <NewReport
          demo={demo}
          description={description}
          handlePrint={handlePrint}
          hasChangePermission={hasChangePermission}
          hasReportRows={!!reportData?.reportRows?.length}
          isEditing={isEditing}
          isLoadingReportData={isLoadingReportData}
          isShowScreen={isShowScreen}
          name={name}
          savedReport={savedReport}
          setDescription={setDescription}
          setIsEditing={setIsEditing}
          setIsLoadingReportData={setIsLoadingReportData}
          setName={setName}
          setReportData={setReportData}
          id={id}
        />

        {isLoadingReportData ? (
          <LoadingSpinnerNew marginTop='30px' />
        ) : reportData?.reportRows?.length ? (
          <>
            <FlexContainer
              classes='generated-report-area'
              flexWrap='wrap'
              marginTop='30px'
              alignItems='flex-start'
            >
              <ReportsTable reportData={reportData} />

              <ReportsChart reportData={reportData} />

              <div style={{ marginBottom: '100px', width: '100%' }}></div>
            </FlexContainer>

            <div
              style={{
                visibility: 'hidden',
                position: 'fixed',
                zIndex: '-1',
              }}
            >
              <Print
                content={
                  <FlexContainer flexDirection='column'>
                    <Text margin='10px' text={name} textAlign='center' />

                    <Text margin='10px' text={description} textAlign='center' />

                    <FlexContainer flexWrap='wrap' alignItems='flex-start'>
                      <ReportsTable fromPrintable reportData={reportData} />

                      <ReportsChart fromPrintable reportData={reportData} />
                    </FlexContainer>
                  </FlexContainer>
                }
                ref={componentRef}
              />
            </div>
          </>
        ) : !reportData?.reportRows ? (
          <></>
        ) : (
          <PanelsSection marginTop='30px'>
            <InfoText text='No data' />
          </PanelsSection>
        )}
      </Panels>
    </MainOnly>
  );
};

export default ReportsNew;
