import { handleApiError } from '../../../utils/error-handling';
import { shouldNavigateOnNotFound } from '../../../utils/helpers';
import { useAuth0 } from '@auth0/auth0-react';
import { useAlert } from 'react-alert';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserPortal } from '../../../contexts';

import apiDataService from '../../../api/reports';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import ReportsNew from './ReportsNew';

const ReportsShow = ({ demo }) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const { id } = useParams();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { getAccessTokenSilently } = useAuth0();

  const [savedReport, setSavedReport] = useState();

  const getReport = async () => {
    try {
      const { report } = await apiDataService.getReportById({
        token: demo ? undefined : await getAccessTokenSilently(),
        userId: demo ? 1 : userId,
        portalId: demo ? 'demo' : portalId,
        reportId: id,
      });

      setSavedReport(report);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      if (shouldNavigateOnNotFound(error)) {
        navigate(`/${demo ? 'demo' : portalId}/reports`, {
          state: { isNavigating: true },
        });
      }
    }
  };

  useEffect(() => {
    getReport();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!savedReport) return <LoadingSpinner />;
  else
    return (
      <ReportsNew demo={demo} isShowScreen savedReport={savedReport} id={id} />
    );
};

export default ReportsShow;
