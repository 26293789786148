const guideContents = [
  ['Navigating', ['Navigation Bar', 'Navigation Menu']],
  [
    'Adding Basic Data',
    [
      'Creating an Organistation Structure',
      'Adding Agents',
      'Adding Users',
      'Adding Data Lists',
    ],
  ],
  [
    'Creating Scorecards',
    [
      'Basic Scorecard Data',
      'Admin Sections',
      'Scoring Sections',
      'Scorecard Options',
      'Preview & Save',
    ],
  ],
  [
    'Using the Data Grid',
    [
      'Table View',
      'Grid View',
      'Filtering',
      'Sorting',
      'Columns',
      'Records',
      'Saved Options',
    ],
  ],
  [
    'Adding Evaluations',
    [
      'Choosing a Scorecard',
      'Completing the Evaluation',
      'Viewing the Results',
    ],
  ],
  ['Reporting', ['Built-In Reports', 'Creating Reports']],
  ['Dashboard', ['Adding a Report']],
  [
    'Calibrations',
    ['Creating a Calibration', 'Completing a Calibration Session'],
  ],
];

export default guideContents;
