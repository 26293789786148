import { memo, useCallback, useRef } from 'react';
import { useDrop } from 'react-dnd';

import DraggableNavListItem from './DraggableNavListItem';
import update from 'immutability-helper';

import './DraggableToggleList.css';

const DraggableNavList = memo(function Container({
  cards,
  disabled,
  disabledText,
  setCards,
  setIsMoving,
  itemType,
  containerClasses = '',
  listClasses = '',
  title,
  uniqueSectionId,
  navFavourites,
  setIsNavOpen,
  setNavFavourites,
  demo,
}) {
  const scrollableSections = useRef(null);

  const ItemTypes = {
    CARD: itemType,
  };

  const findCard = useCallback(
    (id) => {
      const card = cards.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards]
  );
  const moveCard = useCallback(
    (id, atIndex) => {
      if (setIsMoving) setIsMoving(true);
      const { card, index } = findCard(id);
      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [findCard, cards, setCards]
  );
  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

  if (!cards) return <div>loading</div>;

  return (
    <div
      className={`draggable-toggles-container ${containerClasses}`}
      ref={drop}
    >
      <ul
        className={`draggable-toggles-list draggable-nav-list ${listClasses}`}
        ref={scrollableSections}
      >
        {cards.map((card, index) => {
          return (
            <DraggableNavListItem
              key={card.id}
              id={card.id}
              cardsLength={cards.length}
              disabledText={disabledText}
              moveCard={moveCard}
              findCard={findCard}
              card={card}
              itemType={itemType}
              title={title}
              uniqueSectionId={uniqueSectionId}
              disabled={disabled}
              setIsNavOpen={setIsNavOpen}
              setNavFavourites={setNavFavourites}
              demo={demo}
              navFavourites={navFavourites}
            />
          );
        })}
      </ul>
    </div>
  );
});

export default DraggableNavList;
