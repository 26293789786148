import { apiUrl, headers } from './config';

const scorecardTemplatesApi = {
  getAll: async ({ portalId, token, userId }) => {
    const url = `/api/portals/${portalId}/scorecard-templates/all`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getAllPortal: async ({ params, portalId, token, userId }) => {},

  getAllPublic: async ({ params, portalId, token, userId }) => {},

  getPortalTemplateById: async ({ portalId, templateId, token, userId }) => {
    const url = `/api/portals/${portalId}/scorecard-templates/${templateId}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getPublicTemplateById: async ({ templateId, token, userId }) => {
    const url = `/api/scorecard-templates/${templateId}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  gridDeleteMultiple: async ({
    selectedRecordIds,
    recordData,
    portalId,
    reqBody,
    token,
  }) => {
    reqBody.deletes = selectedRecordIds.map((id) => {
      const { vcid } = recordData.find((record) => record.id === id);

      return {
        id,
        vcid,
        hasEvaluations: false,
      };
    });

    const url = `/api/portals/${portalId}/scorecards`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  gridUpdate: async ({
    gridChanges,
    portalId,
    reqBody,
    token,
    returnedRecords,
  }) => {
    reqBody.updates = {
      scorecards: {
        toUpdate: Object.entries(gridChanges).map(([id, changes]) => {
          return {
            id,
            vcid: returnedRecords.find((record) => record.id === parseInt(id))
              .vcid,
            ...changes,
          };
        }),
      },
    };

    const url = `/api/portals/${portalId}/scorecards`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },
};

export default scorecardTemplatesApi;
