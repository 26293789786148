import { handleApiError } from '../../../utils/error-handling';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';

import apiDataServicePortals from '../../../api/portals';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InputStandard from '../../Elements/InputStandard';
import Text from '../../Elements/Text';

import './AccountDetails.css';

const AccountDetails = ({ setShowAccount, setUserFromDb, userFromDb }) => {
  const alert = useAlert();

  const { getAccessTokenSilently } = useAuth0();

  const [isEditingOrgDetails, setIsEditingOrgDetails] = useState(false);
  const [isSavingOrgDetails, setIsSavingOrgDetails] = useState(false);

  const [orgName, setOrgName] = useState(userFromDb.org);
  const [logoUrl, setLogoUrl] = useState(userFromDb.logo_url);
  const [imageFile, setImageFile] = useState(userFromDb.logo_url);
  // const [imageFileInputValue, setImageFileInputValue] = useState('');

  const handleSaveUpdateOrgDetails = async () => {
    const updateDetails = {};

    const newLogo = logoUrl || 'https://i.ibb.co/bb7szFx/logo3.png';

    if (userFromDb.org !== orgName) updateDetails.org = orgName;
    if (userFromDb.logo_url !== logoUrl) updateDetails.logo_url = newLogo;

    const portalId = userFromDb.portal_id;
    const userId = userFromDb.id;

    const reqBody = { updates: [{ updateDetails, id: portalId }], userId };

    try {
      setIsSavingOrgDetails(true);

      const token = await getAccessTokenSilently();

      await apiDataServicePortals.update({
        token,
        reqBody,
        portalId,
      });

      setUserFromDb((curr) => {
        const copiedCurr = { ...curr };

        copiedCurr.org = orgName;
        copiedCurr.logo_url = newLogo;

        return copiedCurr;
      });

      setLogoUrl(newLogo);
      setImageFile(newLogo);
      // setImageFileInputValue('');

      setIsEditingOrgDetails(false);

      alert.success(`Organisation Details updated`);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsSavingOrgDetails(false);
    }
  };

  const handleChangeOrgName = (event) => {
    setOrgName(event.target.value);
  };

  const handleLogoUrlChange = (event) => {
    setLogoUrl(event.target.value);
    setImageFile(event.target.value);
    // setImageFileInputValue('');
  };

  // const handleImageChange = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setImageFile(URL.createObjectURL(event.target.files[0]));
  //     setLogoUrl('');
  //     // setImageFileInputValue(event.target.value);
  //   } else {
  //     setImageFile('');
  //     // setImageFileInputValue('');
  //   }
  // };

  return (
    <FlexContainer
      classes='account-details-container'
      alignItems='flex-start'
      flexWrap='wrap'
      justifyContent='flex-start'
    >
      <FlexContainer
        classes='account-details-section-container'
        alignItems='flex-start'
        flexDirection='column'
        justifyContent='flex-start'
      >
        <HeaderSecondary
          margin='30px 0 20px 0'
          text='Your Details'
          textDecoration='underline'
        />

        <FlexContainer
          classes='account-details-item-container'
          flexWrap='wrap'
          justifyContent='flex-start'
        >
          <HeaderSecondary margin='0 10px 5px 0' text='First Name:' />

          <Text marginBottom='5px' text={userFromDb.first_name} />
        </FlexContainer>

        <FlexContainer
          classes='account-details-item-container'
          flexWrap='wrap'
          justifyContent='flex-start'
        >
          <HeaderSecondary margin='0 10px 5px 0' text='Last Name:' />

          <Text marginBottom='5px' text={userFromDb.last_name} />
        </FlexContainer>

        <FlexContainer
          classes='account-details-item-container'
          flexWrap='wrap'
          justifyContent='flex-start'
        >
          <HeaderSecondary margin='0 10px 5px 0' text='Username:' />

          <Text marginBottom='5px' text={userFromDb.username} />
        </FlexContainer>

        <FlexContainer
          classes='account-details-item-container'
          flexWrap='wrap'
          justifyContent='flex-start'
        >
          <HeaderSecondary margin='0 10px 5px 0' text='Role:' />

          <Text marginBottom='5px' text={userFromDb.role.toUpperCase()} />
        </FlexContainer>

        <FlexContainer
          classes='account-details-item-container'
          flexWrap='wrap'
          justifyContent='flex-start'
        >
          <HeaderSecondary margin='0 10px 5px 0' text='User ID:' />

          <Text marginBottom='5px' text={userFromDb.id} />
        </FlexContainer>

        {userFromDb.permissions?.includes('users.change') ? (
          <span style={{ maxWidth: '150px', fontStyle: 'italic' }}>
            <Text text='Edit your details on the User screen ' />

            <Link
              onClick={() => setShowAccount(false)}
              to={`/${userFromDb.portal_id}/users/${userFromDb.id}`}
            >
              <Text text='here' textDecoration='underline' />
            </Link>
          </span>
        ) : (
          <></>
        )}
      </FlexContainer>

      <FlexContainer
        classes='account-details-section-container'
        alignItems='flex-start'
        flexDirection='column'
        justifyContent='flex-start'
        marginRight='0'
      >
        <HeaderSecondary
          margin='30px 0 20px 0'
          text='Organisation Details'
          textDecoration='underline'
        />

        <FlexContainer
          classes={`account-details-item-container ${
            isEditingOrgDetails ? 'editing-account-details-item-container' : ''
          }`}
          flexWrap='wrap'
          justifyContent='flex-start'
          position='relative'
        >
          <HeaderSecondary margin='0 10px 5px 0' text='Organisation Name:' />

          {isEditingOrgDetails && (
            <InputStandard
              classes='account-org-details-input'
              onChange={handleChangeOrgName}
              placeholder='Organisation Name'
              value={orgName}
            />
          )}

          <Text
            classes={isEditingOrgDetails ? 'hide-element' : ''}
            marginBottom='5px'
            text={userFromDb.org}
          />
        </FlexContainer>

        <FlexContainer
          classes={`account-details-item-container ${
            isEditingOrgDetails ? 'account-details-item-container-editing' : ''
          }`}
          flexWrap='wrap'
          justifyContent='flex-start'
        >
          <HeaderSecondary margin='0 10px 5px 0' text='Logo:' />

          <div className='account-logo-img-container'>
            <img className='create-logo-img' src={imageFile} alt='Logo' />
          </div>
        </FlexContainer>

        {isEditingOrgDetails && (
          <FlexContainer
            classes='account-org-details-edit-logo-inputs'
            alignItems='stretch'
            flexDirection='column'
          >
            <InputStandard
              classes='create-portal-logo-url-input'
              onChange={handleLogoUrlChange}
              placeholder='< URL >'
              type='url'
              value={logoUrl}
            />

            {/* <InputStandard
              classes='create-portal-logo-file-input'
              inputClasses={`${
                logoUrl || !imageFile ? 'preview-logo-input-no-file' : ''
              }`}
              onChange={handleImageChange}
              type='file'
              value={imageFileInputValue}
            /> */}
          </FlexContainer>
        )}

        <FlexContainer
          classes='account-details-item-container'
          flexWrap='wrap'
          justifyContent='flex-start'
        >
          <HeaderSecondary margin='0 10px 5px 0' text='Portal ID:' />

          <Text marginBottom='5px' text={userFromDb.portal_id} />
        </FlexContainer>

        {userFromDb.permissions?.includes('portals.edit') ? (
          <FlexContainer
            alignItems='flex-start'
            classes='org-details-buttons'
            justifyContent='flex-start'
          >
            <ButtonStandardNoFill
              classes={`org-details-edit-button ${
                isEditingOrgDetails ? 'org-details-edit-button-editing' : ''
              }`}
              disabled={isSavingOrgDetails}
              onClick={() => setIsEditingOrgDetails((curr) => !curr)}
              text='Edit'
              typeStyle={isEditingOrgDetails ? 'type-3' : 'type-1'}
            />

            {isEditingOrgDetails && (
              <ButtonStandardNoFill
                disabled={
                  (userFromDb.org === orgName &&
                    (userFromDb.logo_url === logoUrl ||
                      userFromDb.logo_url === imageFile)) ||
                  isSavingOrgDetails
                }
                iconName='save-solid'
                onClick={handleSaveUpdateOrgDetails}
                text='Save Changes'
                typeStyle='type-4'
              />
            )}
          </FlexContainer>
        ) : (
          <></>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default AccountDetails;
