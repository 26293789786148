import EvaluationsShow from '../Evaluations/EvaluationsShow';
import ScorecardsNew from '../Scorecards/ScorecardsNew';

const DraftsShow = ({ demo, type }) => {
  if (type === 'scorecard') return <ScorecardsNew fromDrafts demo={demo} />;
  else if (type === 'evaluation')
    return <EvaluationsShow fromDrafts demo={demo} />;
  else return <div></div>;
};

export default DraftsShow;
