import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';

import apiDataServiceUsers from '../../../api/users';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import GridOption from '../DataGrid/GridContent/GridOption';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import portalConfirmAlert from '../../../utils/portalConfirmAlert';
import Text from '../../Elements/Text';

import './AddPortal.css';
import './PortalInvite.css';
import './SwitchPortal.css';

const PortalInvite = ({
  isRespondingToInvite,
  portalInvite,
  setIsRespondingToInvite,
}) => {
  const alert = useAlert();
  const { getAccessTokenSilently, user } = useAuth0();

  const [showInvite, setShowInvite] = useState('');

  const handleRespondToInvite = async (response) => {
    try {
      if (response === 'accept') {
        setIsRespondingToInvite(true);

        const reqBody = {
          updates: [{ sub: user.sub, id: portalInvite.user_id }],
          userId: portalInvite.user_id,
        };

        await apiDataServiceUsers.update({
          portalId: portalInvite.id,
          reqBody,
          token: await getAccessTokenSilently(),
        });

        alert.success(`Joined ${portalInvite.org}`);
      } else if (response === 'decline') {
        const confirmed = await portalConfirmAlert({
          message:
            'This invite will be deleted, are you sure you want to decline?',
        });

        if (!confirmed) return;

        setIsRespondingToInvite(true);

        await apiDataServiceUsers.gridDeleteMultiple({
          selectedRecordIds: [portalInvite.user_id],
          portalId: portalInvite.id,
          reqBody: { userId: portalInvite.user_id },
          token: await getAccessTokenSilently(),
        });

        alert.success('Invite declined');
      }
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsRespondingToInvite(false);
    }
  };

  return (
    <FlexContainer
      classes='grid-panel account-portal-invite-container'
      padding={showInvite ? '10px 5px 5px 5px' : '10px 5px'}
      marginBottom='0'
      flexDirection='column'
      alignItems='stretch'
    >
      <FlexContainer>
        <div className='account-logo-img-container'>
          <img
            className='create-logo-img'
            src={portalInvite.logo_url}
            alt='Logo'
          />
        </div>

        <GridOption
          headerText={portalInvite.org}
          showGridOption={showInvite}
          setShowGridOption={setShowInvite}
          gridOption='portalInvite'
        />
      </FlexContainer>

      {showInvite && (
        <FlexContainer alignItems='stretch' flexDirection='column' margin='0'>
          <FlexContainer
            classes='grid-options-background'
            flexDirection='column'
            paddingBottom='20px'
          >
            <FlexContainer flexWrap='wrap'>
              <FlexContainer flexDirection='column' margin='10px'>
                <HeaderSecondary text='Organisation:' />

                <Text text={portalInvite.org} textAlign='center' />
              </FlexContainer>

              <FlexContainer flexDirection='column' margin='10px'>
                <HeaderSecondary text='ID:' />

                <Text text={portalInvite.id} />
              </FlexContainer>
            </FlexContainer>

            <HeaderSecondary
              marginTop='20px'
              text={`Your ${portalInvite.org} details`}
              textAlign='center'
              textDecoration='underline'
            />

            <FlexContainer flexWrap='wrap'>
              <FlexContainer flexDirection='column' margin='10px'>
                <HeaderSecondary text='First name:' textAlign='center' />

                <Text
                  text={portalInvite.first_name || '-'}
                  textAlign='center'
                />
              </FlexContainer>

              <FlexContainer flexDirection='column' margin='10px'>
                <HeaderSecondary text='Last name:' textAlign='center' />

                <Text text={portalInvite.last_name || '-'} textAlign='center' />
              </FlexContainer>

              <FlexContainer flexDirection='column' margin='10px'>
                <HeaderSecondary text='Username:' textAlign='center' />

                <Text text={portalInvite.username || '-'} textAlign='center' />
              </FlexContainer>

              <FlexContainer flexDirection='column' margin='10px'>
                <HeaderSecondary text='Role:' textAlign='center' />

                <Text
                  text={portalInvite.role.toUpperCase() || '-'}
                  textAlign='center'
                />
              </FlexContainer>

              <FlexContainer flexDirection='column' margin='10px'>
                <HeaderSecondary text='ID:' />

                <Text text={portalInvite.user_id} />
              </FlexContainer>
            </FlexContainer>

            <FlexContainer flexWrap='wrap'>
              <ButtonStandardNoFill
                disabled={isRespondingToInvite}
                iconName='tick'
                margin='30px 25px 0'
                onClick={() => handleRespondToInvite('accept')}
                text='Accept'
                typeStyle='type-4'
              />

              <ButtonStandardNoFill
                disabled={isRespondingToInvite}
                iconName='xmark'
                margin='30px 25px 0'
                onClick={() => handleRespondToInvite('decline')}
                text='Decline'
                typeStyle='type-5'
              />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default PortalInvite;
