import { useEffect } from 'react';

import BarChartData from './BarChartData';
import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import FlexContainer from '../../Elements/FlexContainer';
import InputStandard from '../../Elements/InputStandard';
import SelectStandard from '../../Elements/SelectStandard';
import Toggle from '../../Elements/Toggle';

import './ReportAddChart.css';

const ReportAddChart = ({
  chartData,
  columnOrder,
  customColumnHeaders,
  handleUpdateAndReorderChartValues,
  handleUpdateChartProp,
  handleUpdateChartType,
  handleUpdateChartValues,
  hasGroupingOrAggregate,
  setCharts,
  uniqueRef,
}) => {
  const handleUpdateXLabelHandling = (event) => {
    handleUpdateChartProp(uniqueRef, 'xLabelHandling', event.target.value);
  };

  useEffect(() => {
    // const values = chartData.values?.filter((value) => {
    //   return columnOrder.some((col) => {
    //     return col.id === value;
    //   });
    // });

    handleUpdateAndReorderChartValues(uniqueRef, chartData.values);

    // reorder values based on new column order

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnOrder]);

  // make sure chart is sorted by the sort order of the table

  return (
    <FlexContainer flexDirection='column'>
      <ButtonIconOnly
        classes='report-chart-minus-button'
        marginBottom='10px'
        typeStyle='type-2'
        iconName='minus'
        onClick={() => {
          setCharts((curr) => {
            const copiedCurr = { ...curr };

            delete copiedCurr[uniqueRef];

            return copiedCurr;
          });
        }}
      />

      <FlexContainer
        classes='report-add-chart grid-card'
        flexDirection='column'
      >
        <FlexContainer flexWrap='wrap' margin='10px 0'>
          <SelectStandard
            id={`${uniqueRef}-report-add-chart-type`}
            labelText='Chart Type'
            margin='0 5px'
            onChange={(event) =>
              handleUpdateChartType(uniqueRef, event.target.value)
            }
            options={[
              { code: '', name: '< Select >' },
              { code: 'bar', name: 'Bar' },
              { code: 'line', name: 'Line' },
              { code: 'pie', name: 'Pie' },
              { code: 'area', name: 'Area' },
              { code: 'needle', name: 'Needle' },
              { code: 'scatter', name: 'Scatter' },
            ]}
            value={chartData.type}
          />
          <SelectStandard
            id={`${uniqueRef}-report-add-chart-size`}
            labelText='Chart Size'
            margin='0 5px'
            onChange={(event) => {
              handleUpdateChartProp(uniqueRef, 'size', event.target.value);
            }}
            options={[
              { code: 'small', name: 'Small' },
              { code: 'medium', name: 'Medium' },
              { code: 'large', name: 'Large' },
            ]}
            value={chartData.size || 'medium'}
          />

          {chartData.type !== 'needle' && (
            <Toggle
              flexDirection='row'
              flexWrap='wrap'
              labelText='Legend'
              margin='0 5px'
              onChange={(event) => {
                handleUpdateChartProp(
                  uniqueRef,
                  'shouldIncludeLegend',
                  event.target.checked
                );
              }}
              toggled={chartData.shouldIncludeLegend || false}
            />
          )}
        </FlexContainer>

        {chartData.type !== 'pie' && chartData.type !== 'needle' && (
          <FlexContainer flexWrap='wrap' margin='10px 0'>
            <InputStandard
              checked={chartData.xLabelHandling === 'hideAllX'}
              id={`${uniqueRef}-reports-hide-all-labels`}
              labelText='Hide all labels?'
              margin='5px'
              name={`${uniqueRef}-xLabelOptions`}
              onChange={handleUpdateXLabelHandling}
              type='radio'
              value='hideAllX'
            />

            <InputStandard
              checked={chartData.xLabelHandling === 'hideXOverlap'}
              id={`${uniqueRef}-reports-hide-labels-dont-fit`}
              labelText="Hide labels that don't fit?"
              margin='0 5px'
              name={`${uniqueRef}-xLabelOptions`}
              onChange={handleUpdateXLabelHandling}
              type='radio'
              value='hideXOverlap'
            />

            {/* // if there's more than 1 chart the input names will conflict */}
            <FlexContainer>
              <InputStandard
                checked={chartData.xLabelHandling === 'alternateX'}
                id={`${uniqueRef}-reports-alternate-labels`}
                labelText='Alternate?'
                margin='0 0 0 5px'
                name={`${uniqueRef}-xLabelOptions`}
                onChange={handleUpdateXLabelHandling}
                type='radio'
                value='alternateX'
              />

              {chartData.xLabelHandling === 'alternateX' && (
                <SelectStandard
                  margin='0 5px 0 0'
                  onChange={(event) => {
                    handleUpdateChartProp(
                      uniqueRef,
                      'alternateInterval',
                      event.target.value
                    );
                  }}
                  options={['2', '3', '4', '5', '6', '7', '8', '9', '10']}
                  value={chartData.alternateInterval}
                />
              )}
            </FlexContainer>
          </FlexContainer>
        )}

        {chartData.type !== 'pie' && chartData.type !== 'needle' && (
          <InputStandard
            labelText='Y Axis Label'
            onChange={(event) => {
              handleUpdateChartProp(
                uniqueRef,
                'yAxisLabel',
                event.target.value
              );
            }}
            placeholder='Y Axis Label'
            value={chartData.yAxisLabel || ''}
            wrap='wrap'
          />
        )}

        <BarChartData
          type={chartData.type}
          hasGroupingOrAggregate={hasGroupingOrAggregate}
          handleUpdateChartProp={handleUpdateChartProp}
          handleUpdateChartValues={handleUpdateChartValues}
          handleUpdateAndReorderChartValues={handleUpdateAndReorderChartValues}
          columnOrder={columnOrder}
          customColumnHeaders={customColumnHeaders}
          uniqueRef={uniqueRef}
          chartData={chartData}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default ReportAddChart;
