import './InputTextareaNew.css';

const InputTextareaNew = ({
  alignItems,
  classes = '',
  direction = 'horizontal',
  elementRef,
  hasPermission = true,
  id,
  labelPosition = 'before',
  labelText,
  marginBottom,
  marginRight,
  name,
  onChange,
  placeholder,
  resize,
  rows,
  typeStyle = 'type-1',
  value,
  wrap = 'no-wrap',
}) => {
  const style = {};
  const textareaStyle = {};

  if (alignItems) style.alignItems = alignItems;
  if (marginBottom) style.marginBottom = marginBottom;
  if (marginRight) style.marginRight = marginRight;

  if (resize) textareaStyle.resize = resize;

  return (
    <div
      className={`input-textarea-new input-textarea-new-${typeStyle} input-textarea-new-${direction}-${labelPosition} input-textarea-new-${wrap} ${classes}`}
      style={style}
    >
      {labelText ? <label htmlFor={id}>{labelText}</label> : <></>}

      <textarea
        id={id}
        disabled={!hasPermission}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        ref={elementRef}
        rows={rows}
        style={textareaStyle}
        type='text'
        value={value}
      />
    </div>
  );
};

export default InputTextareaNew;
