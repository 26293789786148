import Icon from './Icon';
import InputLabel from './InputLabel';
import InputValidationText from './InputValidationText';
import InputValidityIcon from './InputValidityIcon';

import './InputSelect.css';

const InputSelect = ({
  className = '',
  containerClassName = '',
  disabled,
  labelClassName = '',
  id,
  invalid,
  labelText = '',
  name,
  onChange,
  options,
  isRequired,
  showInvalid,
  elementRef,
  containerStyle,
  labelStyle = {},
  optionSelected,
  style,
  value,
}) => {
  return (
    <>
      {labelText && (
        <InputLabel
          htmlFor={id}
          style={{ ...labelStyle, paddingBottom: '5px' }}
          text={labelText}
          className={labelClassName}
        />
      )}

      <div
        className={`input-select-and-icon-container ${
          disabled && 'input-select-disabled'
        } ${containerClassName}`}
        style={containerStyle}
      >
        {/* {invalidText && <InputInvalidIcon right={30} />} */}

        <InputValidityIcon
          isInvalid={invalid?.length}
          isRequired={isRequired}
          right='30'
          showInvalid={showInvalid}
        />

        <select
          id={id}
          className={`input-select ${className} ${
            optionSelected ? 'option-selected-style' : ''
          } ${invalid?.length > 0 && showInvalid ? 'input-invalid' : ''}`}
          disabled={disabled}
          name={name}
          onChange={onChange}
          ref={elementRef}
          style={style}
          value={value}
        >
          {options.map((option) => {
            if (typeof option === 'string')
              return <option key={option}>{option}</option>;
            else
              return (
                <option
                  disabled={option.disabled}
                  key={option.key ?? option.code}
                  value={option.code}
                >
                  {option.name}
                </option>
              );
          })}
        </select>

        {invalid?.length > 0 && <InputValidationText text={invalid} />}

        {/* <InputValidationText text={[invalidText]} /> */}

        <div className='input-select-icon-container'>
          <Icon
            className={`input-select-icon ${
              disabled && 'input-select-icon-disabled'
            }`}
            iconName='circle-chevron-down'
          />
        </div>
      </div>
    </>
  );
};

export default InputSelect;
