import axios from 'axios';

export const apiUrl = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

export const headers = ({ sub, token, userId }) => {
  const headers = {};

  if (sub) headers.sub = sub;
  if (token) headers.Authorization = `Bearer ${token}`;
  if (userId) headers.userid = userId;

  return { headers };
};
