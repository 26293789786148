import {
  capitalise,
  lettersOnly,
  validateContainsALetter,
  validatePresenceRequired,
} from '../../../utils/helpers';
import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import apiDataServicePortal from '../../../api/portals';
import BulletPoint from '../../Elements/BulletPoint';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import Icon from '../../Elements/Icon';
import InputStandard from '../../Elements/InputStandard';
import party, { Color } from 'party-js';
import Text from '../../Elements/Text';

import './CreatePortal.css';

const CreatePortal = ({ hasInvites, isAddingNew, onCreated, userFromDb }) => {
  const navigate = useNavigate();

  const alert = useAlert();
  const { getAccessTokenSilently, user } = useAuth0();

  const [isCreatingPortal, setIsCreatingPortal] = useState();

  const [logoUrl, setLogoUrl] = useState('');
  const [imageFile, setImageFile] = useState('');
  // const [imageFileInputValue, setImageFileInputValue] = useState('');
  const [firstName, setFirstName] = useState(
    isAddingNew ? userFromDb.first_name : ''
  );
  const [lastName, setLastName] = useState(
    isAddingNew ? userFromDb.last_name : ''
  );
  const [username, setUsername] = useState(
    isAddingNew ? userFromDb.username : ''
  );
  const [orgName, setOrgName] = useState('');
  const [firstNameLettersOnly, setFirstNameLettersOnly] = useState(
    isAddingNew ? lettersOnly(userFromDb.first_name) : ''
  );
  const [lastNameLettersOnly, setLastNameLettersOnly] = useState(
    isAddingNew ? lettersOnly(userFromDb.last_name) : ''
  );

  const [showInvalid, setShowInvalid] = useState();

  const [firstNameInvalid, setFirstNameInvalid] = useState(
    isAddingNew ? null : ["'First Name' is required"]
  );
  const [lastNameInvalid, setLastNameInvalid] = useState(
    isAddingNew ? null : ["'Last Name' is required"]
  );
  const [orgNameInvalid, setOrgNameInvalid] = useState([
    "'Organisation Name' is required",
  ]);

  const handleLogoUrlChange = (event) => {
    setLogoUrl(event.target.value);
    setImageFile(event.target.value);
    // setImageFileInputValue('');
  };

  // const handleImageChange = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setImageFile(URL.createObjectURL(event.target.files[0]));
  //     setLogoUrl('');
  //     setImageFileInputValue(event.target.value);
  //   } else {
  //     setImageFile('');
  //     setImageFileInputValue('');
  //   }
  // };

  const handleChangeFirstName = (event) => {
    const newFirstName = event.target.value;

    const letters = lettersOnly(newFirstName);

    const validatePresence = validatePresenceRequired(
      newFirstName,
      "'First Name'"
    );
    const validateContains = validateContainsALetter(letters, "'First Name'");

    const validations = [];

    if (validatePresence) validations.push(validatePresence);
    if (validateContains) validations.push(validateContains);

    if (validations.length) setFirstNameInvalid([validations[0]]);
    else setFirstNameInvalid(null);

    setFirstName(newFirstName);
    setFirstNameLettersOnly(letters);

    setUsername(
      `${capitalise(lastNameLettersOnly.toLowerCase())}${capitalise(
        letters.slice(0, 1)
      )}`
    );
  };

  const handleChangeLastName = (event) => {
    const newLastName = event.target.value;

    const letters = lettersOnly(newLastName);

    const validatePresence = validatePresenceRequired(
      newLastName,
      "'Last Name'"
    );
    const validateContains = validateContainsALetter(letters, "'Last Name'");

    const validations = [];

    if (validatePresence) validations.push(validatePresence);
    if (validateContains) validations.push(validateContains);

    if (validations.length) setLastNameInvalid([validations[0]]);
    else setLastNameInvalid(null);

    setLastName(newLastName);
    setLastNameLettersOnly(letters);

    setUsername(
      `${capitalise(letters.toLowerCase())}${capitalise(
        firstNameLettersOnly.slice(0, 1)
      )}`
    );
  };

  const handleChangeOrgName = (event) => {
    const newOrgName = event.target.value;

    const validate = validatePresenceRequired(
      newOrgName,
      "'Organisation Name'"
    );

    if (validate) setOrgNameInvalid([validate]);
    else setOrgNameInvalid(null);

    setOrgName(newOrgName);
  };

  const handleCreatePortal = async () => {
    if (firstNameInvalid || lastNameInvalid || orgNameInvalid) {
      alert.error(
        <div className='ignore-account-detect-click-outside'>
          <p>Unable to create portal.</p>
          <p>Please check the following areas for errors:</p>
          <br />
          <ul>
            {[
              { validation: firstNameInvalid, msg: ' First Name' },
              { validation: lastNameInvalid, msg: ' Last Name' },
              { validation: orgNameInvalid, msg: ' Organisation Name' },
            ]
              .filter(({ validation }) => validation)
              .map(({ msg }, index) => {
                return (
                  <li
                    className='ignore-account-detect-click-outside'
                    key={index}
                  >
                    <BulletPoint />

                    <span>{msg}</span>
                  </li>
                );
              })}
          </ul>
        </div>,
        { timeout: 10000 }
      );

      setShowInvalid(true);

      return;
    } else {
      setIsCreatingPortal(true);

      try {
        const reqBody = {
          email: user.email,
          sub: user.sub,
          firstName,
          lastName,
          username: lettersOnly(username),
          orgName,
          logoUrl: logoUrl || 'https://i.ibb.co/bb7szFx/logo3.png',
        };

        const { portalId } = await apiDataServicePortal.post({
          reqBody,
          token: await getAccessTokenSilently(),
        });

        if (portalId) {
          const green = new Color(12, 76, 20);

          alert.success('Portal Created!');

          party.sparkles(
            document.getElementById('__react-alert__').querySelector('span'),
            { color: green }
          );

          setIsCreatingPortal(false);

          navigate(`/${portalId}/dashboard`, {
            state: { isNavigating: true, hasCreatedPortal: true },
          });

          onCreated?.();
        } else {
          setIsCreatingPortal(false);

          alert.error('Error creating portal, please contact support', {
            timeout: 10000,
          });
        }
      } catch (error) {
        setIsCreatingPortal(false);

        alert.error(handleApiError(error), { timeout: 10000 });
      }
    }
  };

  return (
    <FlexContainer
      alignItems='flex-start'
      classes={`create-portal ${hasInvites ? 'create-portal-has-invites' : ''}`}
      flexDirection='column'
    >
      <FlexContainer marginBottom='30px'>
        {isAddingNew ? (
          <></>
        ) : (
          <Icon
            className='nav-notification-icon'
            iconName='circle-exclamation'
          />
        )}

        <Text
          text={
            isAddingNew
              ? 'Create a portal for a different organisation'
              : `${
                  hasInvites ? 'Or create ' : 'Get started by creating'
                } a portal for your organisation`
          }
        />
      </FlexContainer>

      <HeaderSecondary
        marginBottom='20px'
        text='Your Details'
        textDecoration='underline'
      />

      <InputStandard
        classes='create-portal-standard-inputs'
        id='create-portal-first-name'
        labelText='First Name:'
        onChange={handleChangeFirstName}
        invalid={firstNameInvalid}
        isRequired
        placeholder='First Name'
        showInvalid={showInvalid}
        value={firstName}
      />

      <InputStandard
        classes='create-portal-standard-inputs'
        id='create-portal-last-name'
        labelText='Last Name:'
        onChange={handleChangeLastName}
        invalid={lastNameInvalid}
        isRequired
        placeholder='Last Name'
        showInvalid={showInvalid}
        value={lastName}
      />

      <FlexContainer margin='5px 0' classes='create-portal-set-data'>
        <HeaderSecondary text='Username:' />

        <Text text={username} />
      </FlexContainer>

      <FlexContainer margin='5px 0' classes='create-portal-set-data'>
        <HeaderSecondary text='Email:' />

        <Text text={user.email} />
      </FlexContainer>

      <FlexContainer margin='5px 0' classes='create-portal-set-data'>
        <HeaderSecondary text='Role:' />

        <Text text='Admin' />
      </FlexContainer>

      <HeaderSecondary
        marginTop='15px'
        marginBottom='20px'
        text='Organisation Details'
        textDecoration='underline'
      />

      <InputStandard
        classes='create-portal-standard-inputs'
        id='create-portal-org-name'
        labelText='Organisation Name:'
        onChange={handleChangeOrgName}
        invalid={orgNameInvalid}
        isRequired
        placeholder='Organisation Name'
        showInvalid={showInvalid}
        value={orgName}
      />

      <FlexContainer classes='create-portal-set-data'>
        <HeaderSecondary text='Logo:' />

        <div className='create-logo-img-container'>
          <img className='create-logo-img' src={imageFile} alt='' />
        </div>
      </FlexContainer>

      {/* <FlexContainer alignItems='stretch' flexDirection='column'> */}
      <InputStandard
        classes='create-portal-logo-url-input'
        onChange={handleLogoUrlChange}
        placeholder='< URL >'
        type='url'
        value={logoUrl}
      />

      {/* <InputStandard
          classes='create-portal-logo-file-input'
          inputClasses={`${
            logoUrl || !imageFile ? 'preview-logo-input-no-file' : ''
          }`}
          onChange={handleImageChange}
          type='file'
          value={imageFileInputValue}
        /> */}
      {/* </FlexContainer> */}

      <ButtonStandardNoFill
        alignSelf='center'
        disabled={isCreatingPortal}
        iconName='plus'
        margin='10px 0 0 0'
        onClick={handleCreatePortal}
        text='Create Portal'
        typeStyle='type-4'
      />
    </FlexContainer>
  );
};

export default CreatePortal;
