import { useEffect, useState } from 'react';

import createUrlQuery from '../../../utils/urlQuery/createUrlQuery';
import extractUrlQuery from '../../../utils/urlQuery/extractUrlQuery';
import FilterGroup from './FilterGroup';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';

import './Filters.css';

const Filters = ({
  columnTypes,
  disabled,
  disabledText,
  onClearFilters,
  clearFilters,
  setClearFilters,
  noOfFilters,
  options,
  query,
  savedQuery,
  selectFrom,
  setSavedQuery,
  setOnClearFilters,
  onFilterChange,
  setNoOfFilters,
  setExternalIsChangingCriteria,
  masterFilterInitialState,
  masterFilter,
  setMasterFilter,
}) => {
  const [latestQuery, setLatestQuery] = useState(query);

  const [savedQueryFilter, setSavedQueryFilter] = useState();

  const [isChangingCriteria, setIsChangingCriteria] = useState(false);

  const [noOfFiltersTracker, setNoOfFiltersTracker] = useState({});

  const [hasInitialised, setHasInitialised] = useState(false);

  useEffect(() => {
    setNoOfFilters(Object.keys(noOfFiltersTracker).length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfFiltersTracker]);

  useEffect(() => {
    if (hasInitialised) {
      const newQuery = createUrlQuery(masterFilter) || null;

      onFilterChange(newQuery);
      setSavedQuery(null);
      setSavedQueryFilter(null);
    } else {
      if (
        JSON.stringify(masterFilterInitialState) !==
        JSON.stringify(masterFilter)
      ) {
        setHasInitialised(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterFilter, hasInitialised]);

  useEffect(() => {
    if (savedQuery !== null && savedQuery !== latestQuery) {
      setSavedQueryFilter(
        savedQuery ? extractUrlQuery(savedQuery) : { uniqueRef: 1, and: [] }
      );

      setLatestQuery(savedQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedQuery]);

  if (!Object.keys(columnTypes).length) return <LoadingSpinnerNew />;
  else
    return (
      <div className='filters'>
        <FilterGroup
          clearFilters={clearFilters}
          disabled={disabled}
          disabledText={disabledText}
          setClearFilters={setClearFilters}
          columnTypes={columnTypes}
          filterGroup={masterFilter}
          isChangingCriteria={isChangingCriteria}
          noOfFilters={noOfFilters}
          setIsChangingCriteria={setIsChangingCriteria}
          setExternalIsChangingCriteria={setExternalIsChangingCriteria}
          isParent={true}
          onClearFilters={onClearFilters}
          options={options}
          savedQueryFilter={savedQueryFilter}
          selectFrom={selectFrom}
          setOnClearFilters={setOnClearFilters}
          setParentGroup={setMasterFilter}
          uniqueRef={masterFilter.uniqueRef}
          setNoOfFiltersTracker={setNoOfFiltersTracker}
          setLatestQuery={setLatestQuery}
        />
      </div>
    );
};

export default Filters;
