import moment from 'moment';
import SelectStandard from '../../../Elements/SelectStandard';

import './GridItemInput.css';

const GridItemInput = ({
  column,
  columnType,
  disabled,
  isInvalid,
  isRequired,
  onChange,
  selectFrom,
  showInvalid,
  value,
  valueFromGridChanges,
  view,
}) => {
  if (columnType === 'string') {
    return (
      <input
        className={`grid-item-input ${
          valueFromGridChanges !== undefined ? 'grid-item-input-changes' : ''
        } ${isInvalid && showInvalid ? 'input-invalid' : ''} ${
          isRequired && view === 'table' ? 'grid-item-input-required' : ''
        }`}
        disabled={disabled}
        onChange={onChange}
        value={value === null ? '' : value}
      />
    );
  } else if (columnType === 'int') {
    return (
      <input
        className={`${
          view === 'table' ? 'grid-item-number' : 'grid-item-input'
        } ${
          valueFromGridChanges !== undefined ? 'grid-item-input-changes' : ''
        } ${isInvalid && showInvalid ? 'input-invalid' : ''} ${
          isRequired && view === 'table' ? 'grid-item-input-required' : ''
        }`}
        disabled={disabled}
        onChange={onChange}
        type='number'
        value={value === null ? '' : value}
      />
    );
  } else if (columnType === 'datetime') {
    return (
      <input
        className={`${
          view === 'table' ? 'grid-item-date' : 'grid-item-input'
        } ${
          valueFromGridChanges !== undefined ? 'grid-item-input-changes' : ''
        }`}
        disabled={disabled}
        value={moment(value).format('YYYY-MM-DD')}
        onChange={onChange}
        type='date'
      />
    );
  } else if (columnType === 'time') {
    return (
      <input
        className={`${
          view === 'table' ? 'grid-item-date' : 'grid-item-input'
        } ${
          valueFromGridChanges !== undefined ? 'grid-item-input-changes' : ''
        }`}
        disabled={disabled}
        value={moment(value).format('YYYY-MM-DD')}
        onChange={onChange}
        type='time'
      />
    );
  } else if (columnType === 'bool') {
    return (
      <SelectStandard
        selectContainerClasses={`grid-item-select ${
          view === 'grid' ? 'grid-item-select-grid' : ''
        } ${
          valueFromGridChanges !== undefined ? 'grid-item-input-changes' : ''
        }`}
        disabled={disabled}
        onChange={onChange}
        options={[
          [true, 'Yes'],
          [false, 'No'],
        ]}
        value={value ?? false}
      />
    );
  } else if (columnType === 'selectFrom') {
    return (
      <SelectStandard
        containerClasses={
          isInvalid && showInvalid ? 'grid-select-container-invalid' : ''
        }
        selectContainerClasses={`grid-item-select ${
          view === 'grid' ? 'grid-item-select-grid' : ''
        } ${
          valueFromGridChanges !== undefined ? 'grid-item-input-changes' : ''
        } ${isInvalid && showInvalid ? 'input-invalid' : ''} ${
          isRequired && view === 'table'
            ? 'grid-item-select-input-required'
            : ''
        }`}
        disabled={disabled}
        onChange={onChange}
        options={selectFrom[column]}
        value={value}
      />
    );
  }
  if (columnType === 'array') {
    const copiedValue = Array.isArray(value) ? [...value] : null;

    return (
      <textarea
        className={`grid-item-textarea ${
          valueFromGridChanges !== undefined ? 'grid-item-input-changes' : ''
        }`}
        disabled={disabled}
        onChange={onChange}
        value={copiedValue === null ? '' : copiedValue.join('\n')}
      />
    );
  } else
    return (
      <input
        className={`grid-item-input ${
          valueFromGridChanges !== undefined ? 'grid-item-input-changes' : ''
        } ${isInvalid && showInvalid ? 'input-invalid' : ''}`}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
    );
};

export default GridItemInput;
