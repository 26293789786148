import { useContext, useState } from 'react';
import { UserPortal } from '../../../contexts';

import AutomaticPayments from './BillingOptions/AutomaticPayments';
import BillingOptionButtons from './BillingOptionButtons';
import ChangeBillingDate from './BillingOptions/ChangeBillingDate';
import ChangeEmail from './BillingOptions/ChangeEmail';
import ChangePaymentCard from './BillingOptions/ChangePaymentCard';
import ChangeToAutomatic from './BillingOptions/ChangeToAutomatic';
import ChangeToManual from './BillingOptions/ChangeToManual';
import ExpandableSection from '../../Elements/ExpandableSection';
import ManualPayments from './BillingOptions/ManualPayments';
import PanelsSection from '../../Elements/PanelsSection';
import SubscriptionInfo from './SubscriptionInfo';

import './ManageSubscription.css';

const ManageSubscription = ({ customer, setCustomer, subscription }) => {
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const [selectedBillingOption, setSelectedBillingOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const hasBillingChangePermission =
    userFromDb.permissions?.includes('billing.change');

  return (
    <PanelsSection>
      <SubscriptionInfo customer={customer} subscription={subscription} />

      <BillingOptionButtons
        customer={customer}
        hasBillingChangePermission={hasBillingChangePermission}
        isLoading={isLoading}
        selectedBillingOption={selectedBillingOption}
        setSelectedBillingOption={setSelectedBillingOption}
        subscription={subscription}
      />

      {hasBillingChangePermission && (
        <ExpandableSection isOpen={selectedBillingOption}>
          <div className='billing-expand-area'>
            {selectedBillingOption === 'Automatic payments' && (
              <AutomaticPayments />
            )}

            {selectedBillingOption === 'Manual payments' && (
              <ManualPayments
                customer={customer}
                isLoading={isLoading}
                portalId={portalId}
                setIsLoading={setIsLoading}
                userId={userId}
              />
            )}

            {selectedBillingOption === 'Change email' && (
              <ChangeEmail
                customer={customer}
                isLoading={isLoading}
                portalId={portalId}
                setCustomer={setCustomer}
                setIsLoading={setIsLoading}
                userId={userId}
              />
            )}

            {selectedBillingOption === 'Change invoice date' && (
              <ChangeBillingDate />
            )}

            {selectedBillingOption === 'Change to manual payment' && (
              <ChangeToManual
                billingEmail={customer?.email}
                isLoading={isLoading}
                portalId={portalId}
                setIsLoading={setIsLoading}
                subscriptionId={subscription.id}
                userId={userId}
              />
            )}

            {selectedBillingOption === 'Change to automatic payment' && (
              <ChangeToAutomatic
                customerId={customer.id}
                subscriptionId={subscription.id}
              />
            )}

            {selectedBillingOption === 'Change payment card' && (
              <ChangePaymentCard
                customerId={customer.id}
                subscriptionId={subscription.id}
              />
            )}
          </div>
        </ExpandableSection>
      )}
    </PanelsSection>
  );
};

export default ManageSubscription;
