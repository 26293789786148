import FlexContainer from '../../Elements/FlexContainer';
import GroupingItem from './GroupingItem';

const ReportGrouping = ({
  grouping,
  handleChangeGrouping,
  selectedReportData,
}) => {
  return (
    <FlexContainer classes='report-aggregate-area' flexWrap='wrap'>
      {Object.entries(selectedReportData)
        .sort((a, b) => {
          const titleA = a[1].title.toLowerCase();
          const titleB = b[1].title.toLowerCase();
          if (titleA < titleB) return -1;
          if (titleA > titleB) return 1;
          return 0;
        })
        .map(([itemLabel, itemData]) => {
          const uniqueRef = `groupitem_${itemLabel}`;

          return (
            <GroupingItem
              key={uniqueRef}
              grouping={grouping}
              handleChangeGrouping={handleChangeGrouping}
              itemLabel={itemLabel}
              itemData={itemData}
              uniqueRef={uniqueRef}
            />
          );
        })}
    </FlexContainer>
  );
};

export default ReportGrouping;
