import { useEffect } from 'react';

import AdminSectionDropDown from './AdminSectionDropDown';
import InputValidityIcon from '../Elements/InputValidityIcon';
import moment from 'moment';

const AdminSection = ({
  demo,
  adminResults,
  elementId,
  fromPrintable,
  handleUpdateAdminSection,
  isEvaluation,
  section,
  handleSetInvalidSections,
  showInvalid,
  isEditingEvaluation,
  isEditingScorecard,
}) => {
  const title = section.title;
  const isRequired = isEvaluation ? section.required : section.originalRequired;
  const control = isEvaluation ? section.control : section.originalControl;
  const ddListTable = isEvaluation
    ? section.dd_list_table
    : section.originalDdListTable;
  const ddListId = isEvaluation ? section.dd_list_id : section.originalDdListId;

  const onChange = (event) => {
    handleUpdateAdminSection(
      section.id,
      control === 'Check box'
        ? event.target.checked
          ? 'Yes'
          : 'No'
        : control === 'Date'
        ? moment(event.target.value).format('YYYY-MM-DD')
        : event.target.value,
      ddListTable,
      ddListId
    );
  };

  useEffect(() => {
    handleSetInvalidSections(
      `a${section.id}`,
      section.required && !adminResults[section.id]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminResults[section.id]]);

  return (
    <li
      className={`admin-section-container${
        !isEditingEvaluation && !isEditingScorecard
          ? ' admin-section-container-not-editing'
          : ''
      }`}
    >
      {isEditingEvaluation && (
        <InputValidityIcon
          isInvalid={section.required && !adminResults[section.id]}
          isRequired={isRequired}
          showInvalid={showInvalid}
        />
      )}

      <label
        htmlFor={elementId}
        className={`admin-section-title${
          !isEditingEvaluation ? ' admin-section-title-not-editing' : ''
        }`}
      >
        {title}
      </label>

      {(isEditingEvaluation || isEditingScorecard) &&
        control === 'Drop down' && (
          <AdminSectionDropDown
            demo={demo}
            elementId={elementId}
            fromPrintable={fromPrintable}
            ddListTable={ddListTable}
            ddListId={ddListId}
            onChange={onChange}
            title={title}
            value={adminResults[section.id] || ''}
          />
        )}

      {(isEditingEvaluation || isEditingScorecard) &&
        control === 'Check box' && (
          <input
            id={elementId}
            className='admin-section-control-checkbox'
            onChange={onChange}
            type='checkbox'
            value={adminResults[section.id] === 'Yes'}
            checked={adminResults[section.id] === 'Yes'}
          />
        )}

      {(isEditingEvaluation || isEditingScorecard) && control === 'Date' && (
        <input
          id={elementId}
          className='admin-section-control-date'
          onChange={onChange}
          type='date'
          value={adminResults[section.id] || ''}
        />
      )}

      {(isEditingEvaluation || isEditingScorecard) && control === 'Time' && (
        <input
          id={elementId}
          className='admin-section-control-time'
          onChange={onChange}
          type='time'
          value={adminResults[section.id] || ''}
        />
      )}

      {(isEditingEvaluation || isEditingScorecard) &&
        control === 'Text box' && (
          <input
            id={elementId}
            className='admin-section-control-text'
            maxLength='500'
            onChange={onChange}
            type='text'
            value={adminResults[section.id] || ''}
          />
        )}

      {(isEditingEvaluation || isEditingScorecard) && control === 'Number' && (
        <input
          id={elementId}
          className='admin-section-control-number'
          onChange={onChange}
          type='number'
          value={adminResults[section.id] || ''}
        />
      )}

      {!isEditingEvaluation &&
        !isEditingScorecard &&
        control === 'Date' &&
        adminResults[section.id] && (
          <p className='admin-section-control-text admin-section-control-text-not-editing'>
            {moment(adminResults[section.id]).format('Do MMMM YYYY')}
          </p>
        )}

      {!isEditingEvaluation &&
        !isEditingScorecard &&
        (control !== 'Date' || !adminResults[section.id]) && (
          <p className='admin-section-control-text admin-section-control-text-not-editing'>
            {adminResults[section.id] || `${fromPrintable ? '' : '-'}`}
          </p>
        )}
    </li>
  );
};

export default AdminSection;
