import { handleApiError } from '../../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import {
  validateEmail,
  validatePresenceRequired,
} from '../../../../utils/helpers';

import apiDataServiceBilling from '../../../../api/billing';
import ButtonStandardNoFill from '../../../Elements/ButtonStandardNoFill';
import InputStandard from '../../../Elements/InputStandard';

const ChangeEmail = ({
  customer,
  isLoading,
  portalId,
  setCustomer,
  setIsLoading,
  userId,
}) => {
  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();

  const [billingEmail, setBillingEmail] = useState(customer?.email || '');
  const [billingEmailInvalid, setBillingEmailInvalid] = useState();
  const [showInvalid, setShowInvalid] = useState(false);

  const handleAction = async () => {
    setShowInvalid(true);

    if (billingEmailInvalid || !billingEmail)
      return alert.error(`Invalid email`);

    try {
      setIsLoading(true);

      await apiDataServiceBilling.update({
        portalId,
        reqBody: {
          updates: [{ customerId: customer.id, email: billingEmail }],
          userId,
        },
        token: await getAccessTokenSilently(),
      });

      alert.success(`Email changed`);

      setCustomer((curr) => ({ ...curr, email: billingEmail }));
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const val = validatePresenceRequired(billingEmail, "'Email'");

    if (val) setBillingEmailInvalid([val]);
    else {
      setBillingEmailInvalid(
        validateEmail(billingEmail) ? null : ['Invalid email']
      );
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingEmail]);

  return (
    <>
      <InputStandard
        classes='input-bill-email'
        direction='vertical'
        disabled={isLoading}
        id='input-bill-email'
        invalid={billingEmailInvalid}
        isRequired
        labelText='New Email'
        marginBottom='10px'
        onChange={(event) => setBillingEmail(event.target.value)}
        placeholder='Email'
        showInvalid={showInvalid}
        value={billingEmail}
      />

      <ButtonStandardNoFill
        alignSelf='center'
        disabled={isLoading || customer.email === billingEmail}
        iconName='save-solid'
        onClick={handleAction}
        text='Save Email'
        typeStyle='type-4'
      />
    </>
  );
};

export default ChangeEmail;
