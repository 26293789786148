import { handleApiError } from '../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { UserPortal } from '../../contexts';

import apiDataServiceUsers from '../../api/users';
import Icon from './Icon';
import DraggableNavList from './DraggableNavList';

import './DataBox.css';

const DataBox = ({
  addNavButton,
  canEditDataBox,
  classes = '',
  demo,
  handleEditDataBox,
  headerBackgroundColour = '#ffffff',
  isCollapsible,
  isEditingFavourites,
  items,
  name,
  navFavourites = [],
  setIsNavOpen,
  setNavFavourites,
  style = {},
}) => {
  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();

  const [isExpanded, setIsExpanded] = useState(true);
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const [isMovingNavItem, setIsMovingNavItem] = useState();

  const handleSaveNewOrder = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    const newNavFavourites = draggableItems.map(({ id }) => id);

    try {
      const reqBody = {
        updates: [
          {
            id: demo ? 1 : userId,
            nav_favourites: newNavFavourites,
          },
        ],
        userId: demo ? 1 : userId,
      };

      await apiDataServiceUsers.update({
        portalId: demo ? 'demo' : portalId,
        reqBody,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setNavFavourites(newNavFavourites);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const [draggableItems, setDraggableItems] = useState(
    navFavourites?.map((item, index) => {
      let link = '';

      if (item.slice(0, 8) === 'custom--') {
        const afterIsCustom = item.slice(8);

        const icon = afterIsCustom.slice(0, afterIsCustom.indexOf('--'));

        const afterIcon = afterIsCustom.slice(icon.length + 2);

        const subIcon = afterIcon.slice(0, afterIcon.indexOf('--'));

        const afterSubIcon = afterIcon.slice(subIcon.length + 2);

        const name = afterSubIcon.slice(0, afterSubIcon.indexOf('--..//'));

        link = afterSubIcon.slice(name.length + 6);
      } else {
        link = item;
      }

      return {
        id: item,
        order: index,
        value: item,
        toggled: true,
        icon: items[index].props.icon,
        subIcon: items[index].props.subIcon,
        name: items[index].props.name,
        link,
      };
    })
  );

  useEffect(() => {
    const debounceHandleDnd = setTimeout(() => {
      if (isMovingNavItem) {
        handleSaveNewOrder();
        setIsMovingNavItem(false);
      }
    }, 350);

    return () => clearTimeout(debounceHandleDnd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggableItems]);

  useEffect(() => {
    setDraggableItems(
      navFavourites?.map((item, index) => {
        let link = '';

        if (item.slice(0, 8) === 'custom--') {
          const afterIsCustom = item.slice(8);

          const icon = afterIsCustom.slice(0, afterIsCustom.indexOf('--'));

          const afterIcon = afterIsCustom.slice(icon.length + 2);

          const subIcon = afterIcon.slice(0, afterIcon.indexOf('--'));

          const afterSubIcon = afterIcon.slice(subIcon.length + 2);

          const name = afterSubIcon.slice(0, afterSubIcon.indexOf('--..//'));

          link = afterSubIcon.slice(name.length + 6);
        } else {
          link = item;
        }

        return {
          id: item,
          order: index,
          value: item,
          toggled: true,
          icon: items[index].props.icon,
          subIcon: items[index].props.subIcon,
          name: items[index].props.name,
          link,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navFavourites.length]);

  return (
    <div
      className={`data-box ${!isExpanded ? 'data-box-hidden' : ''} ${classes}`}
      style={style}
    >
      <div
        className={`data-box-container ${
          isCollapsible ? 'data-box-container-collapsible' : ''
        }`}
        onClick={
          isCollapsible ? () => setIsExpanded((curr) => !curr) : undefined
        }
      >
        <h2 style={{ backgroundColor: headerBackgroundColour }}>{name}</h2>
      </div>

      {isEditingFavourites ? (
        <>
          <DraggableNavList
            cards={draggableItems}
            setCards={setDraggableItems}
            itemType='draggable-nav-item'
            handleToggle={() => {}}
            uniqueSectionId='navfav'
            noToggle
            toggleHeight={40}
            setIsMoving={setIsMovingNavItem}
            items={items}
            navFavourites={navFavourites}
            setIsNavOpen={setIsNavOpen}
            setNavFavourites={setNavFavourites}
            demo={demo}
          />
          {addNavButton}
        </>
      ) : (
        isExpanded && items
      )}

      {isExpanded && canEditDataBox && (
        <Icon
          className={`edit-data-box-icon ${
            isEditingFavourites ? 'edit-data-box-icon-is-editing' : ''
          }`}
          iconName='pen'
          title='Edit'
          onClick={handleEditDataBox}
        />
      )}
    </div>
  );
};

export default DataBox;
