import { useState } from 'react';

import ButtonIcon from '../../Elements/ButtonIcon';
import ButtonToggle from '../../Elements/ButtonToggle';
import FlexContainer from '../../Elements/FlexContainer';
import InfoText from '../../Elements/InfoText';
import moment from 'moment';

import './ReportsFocusResultsTable.css';

const ReportsFocusResultsTable = ({
  breakdown,
  focusData,
  focusResults,
  getBrag,
  handleClickViewBreakdown,
  isDefaultParamsOnly,
  isLoadingBreakdown,
  isViewingBreakdown,
  reportQueryHasChanged,
  tableType,
  validParamsLength,
}) => {
  const [showComparisonData, setShowComparisonData] = useState(false);

  const allDataText = `All data from ${moment()
    .subtract(3, 'months')
    .add(1, 'days')
    .format('Do MMMM YYYY')} - ${moment().format('Do MMMM YYYY')}:`;

  if (reportQueryHasChanged)
    return (
      <>
        <InfoText
          style={{ marginTop: '10px' }}
          text='Report parameters have changed'
        />
        <InfoText
          style={{ marginTop: '10px' }}
          text="Click 'Generate Report' to update"
        />
      </>
    );

  if (validParamsLength && !isDefaultParamsOnly && !focusResults?.length)
    return (
      <InfoText
        style={{ marginTop: '10px' }}
        text='No data for chosen parameters'
      />
    );

  if ((!validParamsLength || isDefaultParamsOnly) && !focusData?.length)
    return <InfoText style={{ marginTop: '10px' }} text='No recent data' />;

  return (
    <FlexContainer
      classes='focus-results-table-container'
      flexDirection='column'
      alignItems='stretch'
      justifyContent='flex-start'
    >
      <table>
        <thead>
          <tr>
            <th className='focus-table-pre-header' colSpan={6}>
              <FlexContainer alignItems='flex-end'>
                <InfoText
                  text={validParamsLength ? 'Filtered data:' : allDataText}
                />

                {focusResults?.length ? (
                  <ButtonToggle
                    classes='focus-comparison-data-button'
                    disabled={isDefaultParamsOnly}
                    onClick={() => setShowComparisonData((curr) => !curr)}
                    text={`${
                      showComparisonData ? 'Hide' : 'Show'
                    } Comparison Data`}
                    title={
                      isDefaultParamsOnly
                        ? 'Comparison Data is the same as the filtered data'
                        : ''
                    }
                    toggled={showComparisonData}
                  />
                ) : (
                  <></>
                )}
              </FlexContainer>
            </th>

            {showComparisonData && focusResults.length && validParamsLength ? (
              <th
                className='focus-table-pre-header focus-comparison-data'
                colSpan={3}
              >
                <InfoText text={allDataText} />
              </th>
            ) : (
              <></>
            )}
          </tr>

          <tr className='report-results-table-header-row'>
            <th>{tableType === 'scoring' ? 'Section' : 'Category'}</th>
            <th>Scorecard</th>
            <th>Total Marked</th>
            <th>{tableType === 'scoring' ? 'Pass %' : 'Average Score'}</th>
            <th>Trend</th>
            <th>View</th>
            {showComparisonData && focusResults.length && validParamsLength ? (
              <>
                <th className='focus-comparison-data'>Total Marked</th>
                <th>{tableType === 'scoring' ? 'Pass %' : 'Average Score'}</th>
                <th>Trend</th>
              </>
            ) : (
              <></>
            )}
          </tr>
        </thead>

        <tbody>
          {(validParamsLength && !isDefaultParamsOnly
            ? focusResults
            : focusData
          ).map(
            (
              {
                avg_score,
                marked_total,
                pass_rate,
                scorecard_name,
                scorecard_vcid,
                section_vcid,
                section_name,
                trend,
              },
              index
            ) => {
              const focusComparisonData = focusData.find(
                (dataItem) => dataItem.section_vcid === section_vcid
              );

              const scoreRag = getBrag(
                'Score',
                tableType === 'scoring' ? pass_rate : avg_score
              );
              const trendRag = getBrag('Trend', trend);

              const comparisonScoreRag = getBrag(
                'Score',
                tableType === 'scoring'
                  ? focusComparisonData?.pass_rate
                  : focusComparisonData?.avg_score
              );
              const comparisonTrendRag = getBrag(
                'Trend',
                focusComparisonData?.trend
              );

              return (
                <tr
                  key={section_vcid}
                  className={index === 0 ? 'focus-results-first-grid-row' : ''}
                >
                  <td className='centred-table-cell'>{section_name}</td>
                  <td className='centred-table-cell'>{scorecard_name}</td>
                  <td className='centred-table-cell'>{marked_total}</td>
                  <td
                    className={`centred-table-cell focus-results-cell-brag-${scoreRag}`}
                  >
                    {tableType === 'scoring' ? pass_rate : avg_score}
                  </td>
                  <td
                    className={`centred-table-cell focus-results-cell-brag-${trendRag}`}
                  >
                    {trend ?? '-'}
                  </td>
                  <td>
                    <ButtonIcon
                      disabled={isLoadingBreakdown}
                      onClick={() =>
                        handleClickViewBreakdown(
                          scorecard_vcid,
                          section_vcid,
                          tableType,
                          section_name,
                          scorecard_name
                        )
                      }
                      iconName='eye'
                      style={{
                        fill:
                          section_vcid === breakdown.sectionVcid &&
                          scorecard_vcid === breakdown.scorecardVcid &&
                          tableType === breakdown.tableType &&
                          isViewingBreakdown &&
                          !isLoadingBreakdown
                            ? '#0c4c14'
                            : '#4c3b4d',
                        margin: 'auto',
                      }}
                      title='View Detail'
                    />
                  </td>
                  {showComparisonData &&
                  focusResults?.length &&
                  validParamsLength ? (
                    <>
                      <td className='centred-table-cell focus-comparison-data'>
                        {focusComparisonData?.marked_total ?? '-'}
                      </td>
                      <td
                        className={`centred-table-cell focus-results-cell-brag-${comparisonScoreRag}`}
                      >
                        {tableType === 'scoring'
                          ? focusComparisonData?.pass_rate ?? '-'
                          : focusComparisonData?.avg_score ?? '-'}
                      </td>
                      <td
                        className={`centred-table-cell focus-results-cell-brag-${comparisonTrendRag}`}
                      >
                        {focusComparisonData?.trend ?? '-'}
                      </td>
                    </>
                  ) : (
                    <></>
                  )}
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </FlexContainer>
  );
};

export default ReportsFocusResultsTable;
