import { handleApiError } from '../../../utils/error-handling';
import { mutateDataNew } from '../../../utils/reports';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { UserPortal } from '../../../contexts';

import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import reportsApi from '../../../api/reports';

import './AggOnPercent.css';

const AggOnPercent = ({
  aggBy,
  control,
  demo,
  source,
  title,
  outOf,
  setOutOf,
  percentOf,
  setPercentOf,
}) => {
  const alert = useAlert();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { getAccessTokenSilently } = useAuth0();

  const [options, setOptions] = useState([]);

  const [isLoadingAggregateData, setIsLoadingAggregateData] = useState(false);

  const removeOption = (curr, option) => {
    const copiedCurr = [...curr];

    if (copiedCurr.includes(option))
      copiedCurr.splice(copiedCurr.indexOf(option), 1);

    return copiedCurr;
  };

  const addOption = (curr, option) => {
    const copiedCurr = [...curr];

    if (!copiedCurr.includes(option)) copiedCurr.push(option);

    return copiedCurr;
  };

  const handleChangeOutOf = (option) => {
    setOutOf((curr) => {
      const copiedCurr = [...curr];

      if (copiedCurr.includes(option)) {
        copiedCurr.splice(copiedCurr.indexOf(option), 1);

        setPercentOf((curr) => {
          return removeOption(curr, option);
        });
      } else {
        copiedCurr.push(option);
      }

      return copiedCurr;
    });
  };

  const handleChangePercentOf = (option) => {
    setPercentOf((curr) => {
      const copiedCurr = [...curr];

      if (copiedCurr.includes(option))
        copiedCurr.splice(copiedCurr.indexOf(option), 1);
      else {
        copiedCurr.push(option);

        setOutOf((curr) => {
          return addOption(curr, option);
        });
      }

      return copiedCurr;
    });
  };

  const getAggregateColumnData = async () => {
    setIsLoadingAggregateData(true);

    const params = [`table=${source}`, `column=${encodeURIComponent(title)}`];

    try {
      const { aggregateDataRows } = await reportsApi.getAggregateColumnData({
        params,
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      const uniqueItems = aggregateDataRows.map(({ col_1 }) => {
        return {
          displayData: mutateDataNew({
            aggOn: 'percent',
            source,
            control,
            column: title,
            data: col_1,
          }),
          rawData: col_1,
        };
      });

      setOptions(uniqueItems);
      setOutOf(aggregateDataRows.map(({ col_1 }) => col_1));
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoadingAggregateData(false);
    }
  };

  useEffect(() => {
    if (aggBy === 'evals_Text box_Grade') {
      setOptions([
        { displayData: 'Pass', rawData: 'pass' },
        { displayData: 'Fail', rawData: 'fail' },
      ]);
      setOutOf(['pass', 'fail']);
    } else if (aggBy === 'evals_Text box_RAG') {
      setOptions([
        { displayData: 'Green', rawData: 'green' },
        { displayData: 'Amber', rawData: 'amber' },
        { displayData: 'Red', rawData: 'red' },
      ]);
      setOutOf(['green', 'amber', 'red']);
    } else if (aggBy.slice(0, 8) === 'scoring_') {
      setOptions([
        { displayData: 'Pass', rawData: 'pass' },
        { displayData: 'Fail', rawData: 'fail' },
        { displayData: 'NA', rawData: 'na' },
      ]);
      setOutOf(['pass', 'fail', 'na']);
    } else if (
      aggBy === 'evals_Check box_Fed Back' ||
      (aggBy.slice(0, 6) === 'admin_' && control === 'Check box')
    ) {
      setOptions([
        { displayData: 'Yes', rawData: 'Yes' },
        { displayData: 'No', rawData: 'No' },
      ]);
      setOutOf(['Yes', 'No']);
    } else {
      getAggregateColumnData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingAggregateData) {
    return (
      <div>
        <LoadingSpinnerNew />
      </div>
    );
  } else
    return (
      <FlexContainer flexWrap='wrap' margin='5px 5px 0'>
        <FlexContainer flexWrap='wrap' margin='5px 10px'>
          <HeaderSecondary textAlign='center' text='Percent of:' />

          <FlexContainer flexWrap='wrap'>
            {options.map((option) => (
              <FlexContainer
                key={`agg-percent-of-${option.rawData}`}
                margin='5px'
              >
                <label
                  className='reports-available-section-label agg-percent-label'
                  htmlFor={`agg-percent-of-${option.rawData}`}
                >
                  {option.displayData}
                </label>

                <input
                  id={`agg-percent-of-${option.rawData}`}
                  onChange={() => handleChangePercentOf(option.rawData)}
                  checked={percentOf.includes(option.rawData)}
                  type='checkbox'
                />
              </FlexContainer>
            ))}
          </FlexContainer>
        </FlexContainer>

        <FlexContainer flexWrap='wrap' margin='5px 10px'>
          <HeaderSecondary textAlign='center' text='Out of:' />

          <FlexContainer flexWrap='wrap'>
            {options.map((option) => (
              <FlexContainer key={`agg-out-of-${option.rawData}`} margin='5px'>
                <label
                  className='reports-available-section-label agg-percent-label'
                  htmlFor={`agg-out-of-${option.rawData}`}
                >
                  {option.displayData}
                </label>

                <input
                  id={`agg-out-of-${option.rawData}`}
                  onChange={() => handleChangeOutOf(option.rawData)}
                  checked={outOf.includes(option.rawData)}
                  type='checkbox'
                />
              </FlexContainer>
            ))}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    );
};

export default AggOnPercent;
