import { capitalise } from '../../../../utils/helpers';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserPortal } from '../../../../contexts';

import ButtonIconOnly from '../../../Elements/ButtonIconOnly';
import GridItem from './GridItem';
import logsTableNameTranslations from '../../Logs/logsTableNameTranslations';
import moment from 'moment';

import './GridRow.css';

const GridRow = ({
  firstRow,
  applyToAll,
  availableColumns,
  canApplyToAll,
  columns,
  columnTypes,
  demo,
  firstColumn,
  gridName,
  handleApplyToAll,
  handleSelectOne,
  handleUndoOne,
  handleUpdateGridChanges,
  noShow,
  isEditing,
  isLoading,
  isSelected,
  mainColumn,
  noEditCanDelete,
  record,
  requiredFields,
  selectFrom,
  showInvalid,
  showRecordProp,
  unEditableColumns,
  validationColumns,
  valueFromGridChanges,
  view,
}) => {
  const navigate = useNavigate();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;

  const [lastMouseDown, setLastMouseDown] = useState();

  const getData = (rawData, column) => {
    if (rawData === undefined || rawData === null || rawData === '')
      return ' - ';
    else if (columnTypes[column] === 'datetime')
      return moment(record[column]).format('Do MMMM YYYY');
    else if (columnTypes[column] === 'bool')
      return rawData === true ? 'Yes' : 'No';
    else if (columnTypes[column] === 'selectFrom' && column === 'status')
      return capitalise(rawData);
    else if (columnTypes[column] === 'selectFrom' && column === 'method')
      return capitalise(rawData);
    else if (columnTypes[column] === 'selectFrom' && column === 'table_name') {
      return (
        logsTableNameTranslations(demo, portalId).find(
          (item) => item[0] === rawData
        )?.[1] || rawData
      );
    } else if (columnTypes[column] === 'selectFrom' && column === 'role') {
      if (rawData === 'qa') return 'QA';
      else return capitalise(rawData);
    } else if (column === 'brag' || column === 'pass_fail')
      return capitalise(rawData);
    else if (column === 'type' && rawData === 'consensus')
      return 'Group Average';
    else if (column === 'type' && rawData === 'benchmark') return 'Benchmark';
    else return rawData;
  };

  const handleClickRecordMouseDown = (event) => {
    setLastMouseDown(event.target);
  };

  const handleClickRecordMouseUp = (event) => {
    if (
      !lastMouseDown ||
      lastMouseDown.tagName === 'INPUT' ||
      lastMouseDown.tagName === 'SELECT' ||
      lastMouseDown.tagName === 'TEXTAREA' ||
      lastMouseDown.classList?.contains('cancel-click-record') ||
      lastMouseDown.parentElement?.classList?.contains('cancel-click-record')
    )
      return;

    if (
      event.target.tagName === 'INPUT' ||
      event.target.tagName === 'SELECT' ||
      event.target.tagName === 'TEXTAREA' ||
      event.target.classList.contains('cancel-click-record') ||
      event.target.parentElement.classList.contains('cancel-click-record')
    )
      return;
    else if (isEditing)
      handleSelectOne(
        gridName === 'drafts' ? `${record.type}-${record.id}` : record.id
      );
    else {
      if (!noShow) {
        navigate(
          `/${demo ? 'demo' : portalId}/${gridName}/${
            record[showRecordProp] ?? record.id
          }`
        );
      }
    }
  };

  const onHandleUndoOne = () => {
    handleUndoOne(record.id);
  };

  return !isEditing && !noShow ? (
    <a
      href={`/${demo ? 'demo' : portalId}/${
        gridName === 'drafts'
          ? record.type === 'Scorecard'
            ? 'drafts/scorecards'
            : record.type === 'Evaluation'
            ? 'drafts/evaluations'
            : 'drafts'
          : gridName
      }/${record[showRecordProp] ?? record.id}`}
      className={`${firstRow ? 'first-grid-row ' : ''}${
        view === 'grid' ? 'grid-card' : 'grid-row'
      } ${
        isLoading ? `grid-${view === 'grid' ? 'card' : 'row'}-disabled` : ''
      } ${isSelected ? 'grid-row-selected' : ''} ${
        isEditing ? 'grid-item-editing' : ''
      } ${noShow ? 'no-show-screen' : ''}`}
    >
      {isEditing && view === 'table' && (
        <div className='grid-row-cell'>
          <ButtonIconOnly
            classes={` cancel-click-record grid-edit-icon-${view}`}
            iconClasses='cancel-click-record'
            disabled={!valueFromGridChanges || isLoading}
            iconName='undo'
            onClick={onHandleUndoOne}
            title='Undo row changes'
            typeStyle='type-4'
          />
        </div>
      )}

      {isEditing && (
        <div
          className={
            view === 'table' ? 'grid-row-cell' : 'grid-view-item-buttons'
          }
        >
          <ButtonIconOnly
            classes={`grid-edit-icon-${view}`}
            disabled={isLoading}
            iconName={isSelected ? 'square-tick-solid' : 'square-tick-regular'}
            title={`${isSelected ? 'De-s' : 'S'}elect ${
              view === 'table' ? 'row' : 'record'
            }`}
            typeStyle='type-4'
          />

          {view === 'grid' && (
            <ButtonIconOnly
              classes='cancel-click-record grid-item-undo-button'
              disabled={!valueFromGridChanges || isLoading}
              iconClasses='cancel-click-record'
              iconName='undo'
              onClick={onHandleUndoOne}
              title='Undo record changes'
              typeStyle='type-4'
            />
          )}
        </div>
      )}

      {columns.map((column) => (
        <GridItem
          key={column}
          canApplyToAll={canApplyToAll}
          column={column}
          columnType={columnTypes[column]}
          displayData={getData(record[column], column)}
          gridItemSubHeaderText={availableColumns[column]}
          handleApplyToAll={handleApplyToAll}
          handleUpdateGridChanges={handleUpdateGridChanges}
          isAppliedToAll={applyToAll.includes(column)}
          isEditable={!unEditableColumns.includes(column) && !noEditCanDelete}
          isFirstColumn={firstColumn === column}
          isLoading={isLoading}
          isMainColumn={mainColumn === column}
          isSelected={isSelected}
          noOfColumns={columns.length}
          rawData={record[column]}
          record={record}
          recordId={record.id}
          requiredFields={requiredFields}
          gridChangesRow={valueFromGridChanges}
          selectFrom={selectFrom}
          showInvalid={showInvalid}
          validationColumns={validationColumns}
          valueFromGridChanges={valueFromGridChanges?.[column]}
          view={view}
        />
      ))}
    </a>
  ) : (
    <li
      className={`${firstRow ? 'first-grid-row ' : ''}${
        view === 'grid' ? 'grid-card' : 'grid-row'
      } ${
        isLoading ? `grid-${view === 'grid' ? 'card' : 'row'}-disabled` : ''
      } ${isSelected ? 'grid-row-selected' : ''} ${
        isEditing ? 'grid-item-editing' : ''
      } ${noShow ? 'no-show-screen' : ''}`}
      onMouseDown={isLoading ? () => {} : handleClickRecordMouseDown}
      onMouseUp={isLoading ? () => {} : handleClickRecordMouseUp}
    >
      {isEditing && view === 'table' && !noEditCanDelete && (
        <div className='grid-row-cell'>
          <ButtonIconOnly
            classes={` cancel-click-record grid-edit-icon-${view}`}
            iconClasses='cancel-click-record'
            disabled={!valueFromGridChanges || isLoading}
            iconName='undo'
            onClick={onHandleUndoOne}
            title='Undo row changes'
            typeStyle='type-4'
          />
        </div>
      )}

      {isEditing && (
        <div
          className={
            view === 'table' ? 'grid-row-cell' : 'grid-view-item-buttons'
          }
        >
          <ButtonIconOnly
            classes={`grid-edit-icon-${view}`}
            disabled={isLoading}
            iconName={isSelected ? 'square-tick-solid' : 'square-tick-regular'}
            title={`${isSelected ? 'De-s' : 'S'}elect ${
              view === 'table' ? 'row' : 'record'
            }`}
            typeStyle='type-4'
          />

          {view === 'grid' && !noEditCanDelete && (
            <ButtonIconOnly
              classes='cancel-click-record grid-item-undo-button'
              disabled={!valueFromGridChanges || isLoading}
              iconClasses='cancel-click-record'
              iconName='undo'
              onClick={onHandleUndoOne}
              title='Undo record changes'
              typeStyle='type-4'
            />
          )}
        </div>
      )}

      {columns.map((column) => (
        <GridItem
          key={column}
          canApplyToAll={canApplyToAll}
          column={column}
          columnType={columnTypes[column]}
          displayData={getData(record[column], column)}
          gridItemSubHeaderText={availableColumns[column]}
          handleApplyToAll={handleApplyToAll}
          handleUpdateGridChanges={handleUpdateGridChanges}
          isAppliedToAll={applyToAll.includes(column)}
          isEditable={!unEditableColumns.includes(column) && !noEditCanDelete}
          isFirstColumn={firstColumn === column}
          isLoading={isLoading}
          isMainColumn={mainColumn === column}
          isSelected={isSelected}
          noOfColumns={columns.length}
          rawData={record[column]}
          record={record}
          recordId={record.id}
          requiredFields={requiredFields}
          gridChangesRow={valueFromGridChanges}
          selectFrom={selectFrom}
          showInvalid={showInvalid}
          validationColumns={validationColumns}
          valueFromGridChanges={valueFromGridChanges?.[column]}
          view={view}
        />
      ))}
    </li>
  );
};

export default GridRow;
