import './TextBreak.css';

const TextBreak = ({ text = '', onClick, classes = '' }) => {
  return (
    <div className={`text-break ${classes}`} onClick={onClick}>
      {text}
    </div>
  );
};

export default TextBreak;
