import Icon from './Icon';
import Text from './Text';

import './Breadcrumbs.css';

const UserBreadcrumb = ({
  demo,
  handleClickUserAccount,
  notificationCount = 0,
  showAccount,
  userFromDb,
}) => {
  return (
    <>
      {!demo && userFromDb?.org ? <Text text={userFromDb.org} /> : <></>}

      <div
        className={`user-account-option ${
          showAccount ? 'user-account-option-showing-account' : ''
        } ignore-account-detect-click-outside`}
        onClick={handleClickUserAccount}
        title={`Account${
          notificationCount
            ? ` (${notificationCount} notification${
                notificationCount === 1 ? '' : 's'
              })`
            : ''
        }`}
      >
        <Icon
          className='ignore-account-detect-click-outside'
          iconName='user-solid'
        />

        {notificationCount ? (
          <Icon
            className='nav-notification-icon ignore-account-detect-click-outside'
            iconName='circle-exclamation'
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default UserBreadcrumb;
