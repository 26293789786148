import Icon from '../../Elements/Icon';

import './ConditionButton.css';

const ConditionButton = ({
  condition,
  disabled,
  handleChangeCondition,
  title,
}) => {
  return (
    <button
      className='condition-button'
      disabled={disabled}
      onClick={handleChangeCondition}
      title={title}
      type='button'
    >
      <p>{condition}</p>

      <div className='arrows-up-down'>
        <Icon iconName='arrows-up-down' />
      </div>
    </button>
  );
};

export default ConditionButton;
