import { Link } from 'react-router-dom';

import Icon from './Icon';
import IconWithSub from './IconWithSub';

import './Breadcrumb.css';

const Breadcrumb = ({ name, icon, subIcon, link, lastBreadcrumb, cbFunc }) => {
  if (link)
    return (
      <>
        <Link
          className='breadcrumb breadcrumb-link'
          to={link || ''}
          state={{ isNavigating: true }}
          onClick={cbFunc}
        >
          <div className='breadcrumb-container'>
            <div className='breadcrumbs-icon-container'>
              {subIcon ? (
                <IconWithSub iconName={icon} subIconName={subIcon} />
              ) : (
                <Icon iconName={icon} />
              )}
            </div>

            <span>{name}</span>
          </div>
        </Link>

        {!lastBreadcrumb && <span className='breadcrumb-divider'></span>}
      </>
    );

  return (
    <>
      <div className='breadcrumb'>
        <div className='breadcrumb-container'>
          <div className='breadcrumbs-icon-container'>
            {subIcon ? (
              <IconWithSub iconName={icon} subIconName={subIcon} />
            ) : (
              <Icon iconName={icon} />
            )}
          </div>

          <span>{name}</span>
        </div>
      </div>

      {!lastBreadcrumb && <span className='breadcrumb-divider'></span>}
    </>
  );
};

export default Breadcrumb;
