import { emptyValue } from '../../../../utils/helpers';
import { useEffect, useRef, useState } from 'react';

import './ClauseSelect.css';

const ClauseSelect = ({
  disabled,
  title,
  value,
  handleChangeOption,
  options,
  placeholder,
  initialWidth = 80,
}) => {
  const [optionWidth, setOptionWidth] = useState(initialWidth);
  const [refCurrent, setRefCurrent] = useState({ offsetWidth: 0 });

  const ref = useRef(null);

  useEffect(() => {
    if (refCurrent.offsetWidth === 0) setRefCurrent(ref.current);

    if (!value) setOptionWidth(initialWidth);
    else setOptionWidth(ref.current ? ref.current.offsetWidth : initialWidth);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, refCurrent.offsetWidth, value]);

  return (
    <div className='clause-select-container'>
      <div className='clause-select-text' ref={ref}>
        {value
          ? options?.filter((option) => {
              return option[0] === value;
            })[0][1]
          : ''}
      </div>

      <select
        className={`clause-select ${
          emptyValue(value) ? 'placeholder' : 'has-value'
        }`}
        disabled={disabled}
        title={title}
        style={{
          width: `${optionWidth + 4}px`,
        }}
        value={value}
        onChange={handleChangeOption}
      >
        {placeholder ? (
          <option className='select-option' value={placeholder[0]}>
            {placeholder[1]}
          </option>
        ) : (
          <></>
        )}
        {options?.length ? (
          options.map((option) => (
            <option className='select-option' key={option[0]} value={option[0]}>
              {option[1]}
            </option>
          ))
        ) : (
          <option className='select-option'>{' - '}</option>
        )}
      </select>
    </div>
  );
};

export default ClauseSelect;
