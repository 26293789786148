import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import {
  buildFocusQuery,
  getTableCode,
  tableOrder,
} from '../../../utils/reports';
import { capitalise, doArrayValuesMatch } from '../../../utils/helpers';
import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GridLayout, { WidthProvider } from 'react-grid-layout';

import apiDataServiceReports from '../../../api/reports';
import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import MainOnly from '../MainAndOptions/MainOnly';
import moment from 'moment';
import Panels from '../../Elements/Panels';
import PanelsControlBar from '../../Elements/PanelsControlBar';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import ReportsFocusAvailableSectionsPanel from './ReportsFocusAvailableSectionsPanel';
import ReportsFocusParameters from './ReportsFocusParameters';
import ReportsFocusResults from './ReportsFocusResults';
import ReportsFocusToggledAvailableSectionPanel from './ReportsFocusToggledAvailableSectionPanel';
import useViewport from '../../useViewport';

import './ReportsFocus.css';

const ReactGridLayout = WidthProvider(GridLayout);

const ReportsFocus = ({ demo }) => {
  const navigate = useNavigate();

  const { screenWidth } = useViewport();

  const columnsCount = Math.max(1, Math.floor(screenWidth / 300 - 0.11));

  const alert = useAlert();
  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { getAccessTokenSilently } = useAuth0();

  const defaultQuery = `((evals_Date_Created Date==bet::${moment()
    .subtract(3, 'months')
    .add(1, 'days')
    .format('YYYY-MM-DD')}--${moment().format('YYYY-MM-DD')}))`;

  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [isLoadingAvailableSections, setIsLoadingAvailableSections] =
    useState(false);

  // currently expanded source table
  const [sourceTable, setSourceTable] = useState('evals');
  // // // // //

  // available sections for each source table
  const availableSectionsEvals = [
    {
      title: 'Created By',
      source_table: 'evals',
      control: 'Text box',
      code: 'evals_Text box_null_null_Created By',
    },
    {
      title: 'Created Date',
      source_table: 'evals',
      control: 'Date',
      code: 'evals_Date_null_null_Created Date',
    },
    {
      title: 'Fed Back',
      source_table: 'evals',
      control: 'Check box',
      code: 'evals_Check box_null_null_Fed Back',
    },
    {
      title: 'Grade',
      source_table: 'evals',
      control: 'Text box',
      code: 'evals_Text box_null_null_Grade',
    },
    {
      title: 'Last Modified By',
      source_table: 'evals',
      control: 'Text box',
      code: 'evals_Text box_null_null_Last Modified By',
    },
    {
      title: 'Last Modified Date',
      source_table: 'evals',
      control: 'Date',
      code: 'evals_Date_null_null_Last Modified Date',
    },
    {
      title: 'Main Comments',
      source_table: 'evals',
      control: 'Text box',
      code: 'evals_Text box_null_null_Main Comments',
    },
    {
      title: 'Quality Score',
      source_table: 'evals',
      control: 'Number',
      code: 'evals_Number_null_null_Quality Score',
    },
    {
      title: 'RAG',
      source_table: 'evals',
      control: 'Text box',
      code: 'evals_Text box_null_null_RAG',
    },
    {
      title: 'Scorecard',
      source_table: 'evals',
      control: 'Text box',
      code: 'evals_Text box_null_null_Scorecard',
    },
  ];
  const [availableSectionsAdmin, setAvailableSectionsAdmin] = useState();
  const [availableSectionsCat, setAvailableSectionsCat] = useState();
  const [availableSectionsComments, setAvailableSectionsComments] = useState();
  // // // // //

  // toggled available sections and their data
  const [toggledAvailableSections, setToggledAvailableSections] = useState({
    'evals_Date_null_null_Created Date': {
      title: 'Created Date',
      source_table: 'evals',
      control: 'Date',
    },
  });
  const [availableSectionsDistinctData, setAvailableSectionsDistinctData] =
    useState({});
  // // // // //

  const [elementHeights, setElementHeights] = useState({});

  const [brag, setBrag] = useState({
    Score: [
      { colour: 'red', rag: 0 },
      { colour: 'amber', rag: 80 },
      { colour: 'green', rag: 90 },
    ],
    Trend: [
      { colour: 'red', rag: -100 },
      { colour: 'amber', rag: -10 },
      { colour: 'green', rag: 0 },
    ],
  });

  const [reportQuery, setReportQuery] = useState(defaultQuery);

  const [isShowingFilters, setIsShowingFilters] = useState(false);

  const [reportParameters, setReportParameters] = useState({
    'evals_Date_null_null_Created Date': {
      control: 'Date',
      params: [
        moment().subtract(3, 'months').add(1, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ],
      title: 'Created Date',
      type: 'bet',
      invalid: false,
    },
  });

  const [focusLayout, setFocusLayout] = useState([
    {
      x: 0,
      y: 0,
      w: 1,
      h: 57.4,
      i: 'available-sections-panel',
    },
    {
      x: 1,
      y: 0,
      w: 1,
      h: 25.7,
      i: 'focus-panel-evals_Date_null_null_Created Date',
    },
  ]);

  const [sortedValidParameters, setSortedValidParameters] = useState([]);

  const handleReorder = () => {
    if (!Object.keys(elementHeights).length) return;

    const preferredOrder = Object.keys(toggledAvailableSections);

    const availableSectionsHeight =
      (elementHeights.availableSections + 10) / 11 + 1.8;

    const columnData = {
      0: { count: 1, height: availableSectionsHeight + 1 },
    };

    const newLayout = [
      {
        x: 0,
        y: 0,
        w: 1,
        h: availableSectionsHeight,
        i: 'available-sections-panel',
      },
    ];

    const newToggledSectionsLayout = preferredOrder.map((code, index) => {
      const panelHeight = ((elementHeights[code] || 0) + 10) / 11 + 1.8;

      if (index + 2 <= columnsCount) {
        columnData[index + 1] = { height: panelHeight + 1, count: 1 };

        return {
          x: index + 1,
          y: 0,
          w: 1,
          h: panelHeight,
          i: `focus-panel-${code}`,
        };
      } else {
        const shortestColumn = Object.keys(columnData).sort(
          (a, b) => columnData[a].height - columnData[b].height
        )[0];

        columnData[shortestColumn].height =
          columnData[shortestColumn].height + panelHeight + 1;

        columnData[shortestColumn].count = columnData[shortestColumn].count + 1;

        return {
          x: parseInt(shortestColumn),
          y: columnData[shortestColumn].count,
          w: 1,
          h: panelHeight,
          i: `focus-panel-${code}`,
        };
      }
    });

    if (newToggledSectionsLayout.length)
      newLayout.push(...newToggledSectionsLayout);

    setFocusLayout(newLayout);
  };

  const handleAvailableSectionsHeightChanges = (code, height) => {
    if (elementHeights[code] !== height)
      setElementHeights((curr) => ({ ...curr, [code]: height }));
  };

  const handleBragChange = (type, colour, value) => {
    const parsedValue =
      value === '' || value === null || value === undefined
        ? ''
        : parseInt(value);

    if (isNaN(parsedValue)) return;

    setBrag((curr) => {
      const copiedCurr = { ...curr };

      copiedCurr[type] = copiedCurr[type]
        .map((bragItem) => {
          if (bragItem.colour === colour)
            return { ...bragItem, rag: parsedValue };

          return bragItem;
        })
        .sort((a, b) => a.rag - b.rag);

      return copiedCurr;
    });
  };

  const getInitialReportData = async () => {
    setIsScreenLoading(true);

    await getAvailableSections(sourceTable);

    setIsScreenLoading(false);
  };

  const getAvailableSections = async (sourceTable) => {
    if (!sourceTable || sourceTable === 'evals') return;

    try {
      setIsLoadingAvailableSections(true);

      const params = [`source_tables=${sourceTable}`, 'with_code=true'];

      const { availableSections } =
        await apiDataServiceReports.getReportingData({
          params,
          portalId: demo ? 'demo' : portalId,
          userId: demo ? 1 : userId,
          token: demo ? undefined : await getAccessTokenSilently(),
        });

      if (sourceTable === 'admin') {
        setAvailableSectionsAdmin(availableSections);
      } else if (sourceTable === 'cat') {
        setAvailableSectionsCat(availableSections);
      } else if (sourceTable === 'comments') {
        setAvailableSectionsComments(availableSections);
      }
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoadingAvailableSections(false);
    }
  };

  const handleToggleAvailableSection = (code, section) => {
    setToggledAvailableSections((curr) => {
      const copiedCurr = { ...curr };

      if (copiedCurr[code]) delete copiedCurr[code];
      else copiedCurr[code] = section;

      return copiedCurr;
    });
  };

  const generateLayout = () => {
    return focusLayout.map((item) => ({
      x: item.x,
      y: item.y,
      w: item.w,
      h: item.h,
      i: item.i,
    }));
  };

  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [focusResultsScoring, setFocusResultsScoring] = useState([]);
  const [focusResultsCat, setFocusResultsCat] = useState([]);
  const [reportQueryHasChanged, setReportQueryHasChanged] = useState(false);

  const [focusDataScoring, setFocusDataScoring] = useState([]);
  const [focusDataCat, setFocusDataCat] = useState([]);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingBreakdown, setIsLoadingBreakdown] = useState(false);

  const [breakdown, setBreakdown] = useState({});
  const [isViewingBreakdown, setIsViewingBreakdown] = useState(false);

  const getFocusResults = async () => {
    if (reportQuery === defaultQuery || reportQuery === '(())') {
      setFocusResultsScoring([]);
      setFocusResultsCat([]);
      setReportQueryHasChanged(false);
      setBreakdown({});
      setIsViewingBreakdown(false);

      return;
    }

    try {
      setIsLoadingResults(true);

      const params = [`type=results`, `q=${encodeURIComponent(reportQuery)}`];

      const { focusResultsScoring, focusResultsCat } =
        await apiDataServiceReports.getFocus({
          params,
          portalId: demo ? 'demo' : portalId,
          userId: demo ? 1 : userId,
          token: demo ? undefined : await getAccessTokenSilently(),
        });

      setFocusResultsScoring(focusResultsScoring);
      setFocusResultsCat(focusResultsCat);
      setReportQueryHasChanged(false);
      setBreakdown({});
      setIsViewingBreakdown(false);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoadingResults(false);
    }
  };

  useEffect(() => {
    if (!demo && !userFromDb.permissions?.includes('reports.focus')) {
      navigate(`/${demo ? 'demo' : portalId}/reports`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      setBreadcrumbs([
        [
          'Dashboard',
          'dashboard',
          '',
          `/${demo ? 'demo' : portalId}/dashboard`,
        ],
        ['Reports', 'chart-pie', '', `/${demo ? 'demo' : portalId}/reports`],
        ['Focus', 'magnifying-glass'],
      ]);

      getInitialReportData();
    }

    return () => {
      setAvailableSectionsAdmin();
      setAvailableSectionsCat();
      setAvailableSectionsComments();
      setIsLoadingAvailableSections();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newSortedValidParameters = Object.keys(reportParameters)
      .filter((reportParameter) => !reportParameters[reportParameter].invalid)
      .sort((a, b) => {
        const tableCodeA = getTableCode(a);
        const tableCodeB = getTableCode(b);

        if (tableCodeA === tableCodeB) {
          return reportParameters[a].title
            .toLowerCase()
            .localeCompare(reportParameters[b].title.toLowerCase());
        }

        return tableOrder[tableCodeA] - tableOrder[tableCodeB];
      });

    const arrMatchResult = doArrayValuesMatch(
      sortedValidParameters,
      newSortedValidParameters
    );

    if (!arrMatchResult) setSortedValidParameters(newSortedValidParameters);

    const query = buildFocusQuery(
      newSortedValidParameters.map((validParam) => ({
        ...reportParameters[validParam],
        tableCode: getTableCode(validParam),
      }))
    );

    if (query !== reportQuery) {
      setReportQuery(query);
      setReportQueryHasChanged(true);
      setBreakdown({});
      setIsViewingBreakdown(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportParameters]);

  useEffect(() => {
    if (
      (sourceTable === 'admin' && !availableSectionsAdmin) ||
      (sourceTable === 'cat' && !availableSectionsCat) ||
      (sourceTable === 'comments' && !availableSectionsComments)
    ) {
      getAvailableSections(sourceTable);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceTable]);

  useEffect(() => {
    const debounceHandleReorder = setTimeout(() => {
      handleReorder();
    }, 50);

    return () => clearTimeout(debounceHandleReorder);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementHeights, screenWidth, toggledAvailableSections]);

  if (isScreenLoading) return <LoadingSpinner />;
  else
    return (
      <MainOnly>
        <Panels>
          <PanelsHeaderPrimary
            iconName='chart-pie'
            subIcon='arrows-to-dot'
            text='Focus'
          />

          <FlexContainer classes='focus-brag-container' flexDirection='column'>
            {['Score', 'Trend'].map((bragType) => (
              <FlexContainer key={bragType} classes='focus-brag-and-header'>
                <HeaderSecondary text={bragType} />

                <FlexContainer classes='focus-brag-items'>
                  {['red', 'amber', 'green'].map((ragColour) => (
                    <FlexContainer
                      key={ragColour}
                      classes='focus-brag-info-label-and-num'
                    >
                      <label
                        className={`hot-topics-brag-info-${ragColour}`}
                        htmlFor={`focus-brag-${ragColour}-${bragType}`}
                      >
                        {capitalise(ragColour)}
                      </label>

                      <input
                        className='focus-brag-info-num'
                        id={`focus-brag-${ragColour}-${bragType}`}
                        onChange={(event) =>
                          handleBragChange(
                            bragType,
                            ragColour,
                            event.target.value
                          )
                        }
                        placeholder='-'
                        text={0}
                        type='number'
                        value={
                          brag[bragType].find(
                            (brag) => brag.colour === ragColour
                          ).rag
                        }
                      />
                    </FlexContainer>
                  ))}
                </FlexContainer>
              </FlexContainer>
            ))}
          </FlexContainer>

          <PanelsControlBar>
            {/* <PanelsControlBar justifyContent='space-between'>
            {isLoadingData ||
            isScreenLoading ||
            isLoadingResults ||
            isLoadingBreakdown ||
            isLoadingAvailableSections ||
            reportQueryHasChanged ? (
              <ButtonStandardNoFill
                disabled
                classes='grid-control-bar-button'
                iconName='cloud-download'
                onClick={() => {}}
                text='Export'
              />
            ) : (
              <CSVLink
                className='export-data-grid-link'
                data={getExportData()}
                filename='focus'
                headers={[
                  { label: 'Type', key: 'type' },
                  { label: 'Title', key: 'section_name' },
                  { label: 'Scorecard', key: 'scorecard_name' },
                  { label: 'Total Marked', key: 'marked_total' },
                  { label: 'Score', key: 'score' },
                  { label: 'Trend', key: 'trend' },
                ]}
                target='_blank'
              >
                <ButtonStandardNoFill
                  classes='grid-control-bar-button'
                  iconName='cloud-download'
                  onClick={() => {}}
                  text='Export'
                />
              </CSVLink>
            )}

            <FlexContainer alignItems='flex-end' flexWrap='wrap'> */}
            <ButtonStandardNoFill
              classes='grid-control-bar-button'
              disabled={!reportQueryHasChanged || isLoadingResults}
              iconName='chart-pie'
              onClick={getFocusResults}
              marginTop='auto'
              text='Generate Report'
              title={`${
                reportQueryHasChanged
                  ? ''
                  : 'Change report parameters to generate new report data'
              }`}
            />

            <ButtonIconOnly
              classes={`grid-control-bar-button focus-show-filters-button${
                isShowingFilters ? ' focus-show-filters-button-showing' : ''
              }`}
              iconName='filters'
              subIconName='eye'
              onClick={() => setIsShowingFilters((curr) => !curr)}
              title={`${isShowingFilters ? 'Hide' : 'Show'} filters`}
            />
            {/* </FlexContainer> */}
          </PanelsControlBar>

          <ReportsFocusParameters
            handleToggleAvailableSection={handleToggleAvailableSection}
            isShowingFilters={isShowingFilters}
            reportParameters={reportParameters}
            sortedValidParameters={sortedValidParameters}
          />

          <div
            className={`focus-panels ${
              isShowingFilters ? '' : 'hide-focus-filters'
            }`}
          >
            <ReactGridLayout
              className='layout'
              layout={generateLayout()}
              cols={columnsCount}
              rowHeight={1}
              isDraggable={false}
              isResizable={false}
            >
              <div key='available-sections-panel' className='focus-grid-item'>
                <ReportsFocusAvailableSectionsPanel
                  availableSectionsAdmin={availableSectionsAdmin}
                  availableSectionsCat={availableSectionsCat}
                  availableSectionsComments={availableSectionsComments}
                  availableSectionsEvals={availableSectionsEvals}
                  handleAvailableSectionsHeightChanges={
                    handleAvailableSectionsHeightChanges
                  }
                  handleToggleAvailableSection={handleToggleAvailableSection}
                  isLoadingAvailableSections={isLoadingAvailableSections}
                  reportParameters={reportParameters}
                  setSourceTable={setSourceTable}
                  sourceTable={sourceTable}
                  toggledAvailableSections={toggledAvailableSections}
                />
              </div>

              {focusLayout
                .slice(1)
                .filter(
                  ({ i: focusPanelCode }) =>
                    toggledAvailableSections[focusPanelCode.slice(12)]
                )
                .map(({ i: focusPanelCode }) => {
                  const code = focusPanelCode.slice(12);

                  return (
                    <div key={focusPanelCode} className='focus-grid-item'>
                      <ReportsFocusToggledAvailableSectionPanel
                        availableSectionsDistinctData={
                          availableSectionsDistinctData
                        }
                        code={code}
                        demo={demo}
                        handleAvailableSectionsHeightChanges={
                          handleAvailableSectionsHeightChanges
                        }
                        handleToggleAvailableSection={
                          handleToggleAvailableSection
                        }
                        reportParameters={reportParameters}
                        section={toggledAvailableSections[code]}
                        setAvailableSectionsDistinctData={
                          setAvailableSectionsDistinctData
                        }
                        setReportParameters={setReportParameters}
                      />
                    </div>
                  );
                })}
            </ReactGridLayout>
          </div>

          <ReportsFocusResults
            demo={demo}
            brag={{
              Score: brag.Score.filter(
                (item) =>
                  item.rag !== '' && item.rag !== null && item.rag !== undefined
              ),
              Trend: brag.Trend.filter(
                (item) =>
                  item.rag !== '' && item.rag !== null && item.rag !== undefined
              ),
            }}
            breakdown={breakdown}
            defaultQuery={defaultQuery}
            focusDataCat={focusDataCat}
            focusDataScoring={focusDataScoring}
            focusResultsCat={focusResultsCat}
            focusResultsScoring={focusResultsScoring}
            isLoadingBreakdown={isLoadingBreakdown}
            isLoadingData={isLoadingData}
            isLoadingResults={isLoadingResults}
            isViewingBreakdown={isViewingBreakdown}
            reportParameters={reportParameters}
            reportQuery={reportQuery}
            reportQueryHasChanged={reportQueryHasChanged}
            setBreakdown={setBreakdown}
            setFocusDataCat={setFocusDataCat}
            setFocusDataScoring={setFocusDataScoring}
            setIsLoadingBreakdown={setIsLoadingBreakdown}
            setIsLoadingData={setIsLoadingData}
            setIsViewingBreakdown={setIsViewingBreakdown}
            sortedValidParameters={sortedValidParameters}
          />
        </Panels>
      </MainOnly>
    );
};

export default ReportsFocus;
