import { demoDashboard } from '../../../utils/constants';
import { handleApiError } from '../../../utils/error-handling';
import { Link, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { UserPortal } from '../../../contexts';

import React, { useContext, useEffect, useRef, useState } from 'react';

import apiDataService from '../../../api/users';
import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import GridItem from './GridItem';
import GridLayout, { WidthProvider } from 'react-grid-layout';
import HeaderPrimary from '../../Elements/HeaderPrimary';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InputStandard from '../../Elements/InputStandard';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import PanelsControlBar from '../../Elements/PanelsControlBar';
import PortalCreated from '../PortalCreated';
import Text from '../../Elements/Text';
import useViewport from '../../useViewport';

const ReactGridLayout = WidthProvider(GridLayout);

function DynamicGridLayout({ demo, dashboardId, reports }) {
  const { screenWidth } = useViewport();

  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();

  const location = useLocation();

  const { userFromDb, setUserFromDb } = useContext(UserPortal);

  const [userDashboards, setUserDashboards] = useState(
    demo ? demoDashboard : userFromDb?.dashboard || {}
  );

  const [isSaving, setIsSaving] = useState();

  const [hasChanges, setHasChanges] = useState(false);

  const [currentDashboard, setCurrentDashboard] = useState(
    dashboardId ? parseInt(dashboardId) : screenWidth >= 700 ? 1 : 2
  );

  const saveButtonRef = useRef(null);

  const [currentlyOpenOptions, setCurrentlyOpenOptions] = useState('');

  const [isLocked, setIsLocked] = useState(true);
  const [preventDragResize, setPreventDragResize] = useState(false);

  const [shouldDisplayPortalCreated, setShouldDisplayPortalCreated] = useState(
    location.state?.hasCreatedPortal
  );

  const generateLayout = () => {
    return userDashboards?.[currentDashboard]?.layout?.map((item) => ({
      x: item.x,
      y: item.y,
      w: item.w,
      h: item.h,
      i: item.i,
    }));
  };

  const handleChangeLayout = (layout) => {
    if (
      (layout?.length === 0 && !userDashboards?.[currentDashboard]?.layout) ||
      JSON.stringify(layout) ===
        JSON.stringify(userDashboards?.[currentDashboard]?.layout)
    )
      return;

    setUserDashboards((curr) => {
      const copiedCurr = JSON.parse(JSON.stringify(curr));

      if (!copiedCurr[currentDashboard]) copiedCurr[currentDashboard] = {};

      copiedCurr[currentDashboard].layout = layout;

      return copiedCurr;
    });
  };

  const handleChangeGridItemsData = (
    header,
    selectedReport,
    display,
    itemKey
  ) => {
    setUserDashboards((curr) => {
      const copiedCurr = JSON.parse(JSON.stringify(curr));

      if (!copiedCurr[currentDashboard].gridItemsData)
        copiedCurr[currentDashboard].gridItemsData = {};

      copiedCurr[currentDashboard].gridItemsData[itemKey] = {
        header,
        selectedReport,
        display,
      };

      return copiedCurr;
    });
  };

  const addGridItem = () => {
    const newItem = {
      x: 0,
      y: Infinity,
      w: 20,
      h: 16,
      i: `${currentDashboard}-item-${
        userDashboards[currentDashboard]?.counter || 1
      }`,
    };

    setUserDashboards((curr) => {
      const copiedCurr = JSON.parse(JSON.stringify(curr));

      const currentLayout = copiedCurr[currentDashboard]?.layout;

      const newLayout = currentLayout ? [...currentLayout, newItem] : [newItem];

      if (!copiedCurr[currentDashboard]) copiedCurr[currentDashboard] = {};

      copiedCurr[currentDashboard].layout = newLayout;
      copiedCurr[currentDashboard].counter =
        (copiedCurr[currentDashboard].counter || 1) + 1;

      return copiedCurr;
    });
  };

  const removeGridItem = (itemToRemove) => {
    setUserDashboards((curr) => {
      const copiedCurr = JSON.parse(JSON.stringify(curr));

      const currentLayout = copiedCurr[currentDashboard].layout;

      const newLayout = currentLayout.filter(
        (item) => item.i !== itemToRemove.i
      );

      copiedCurr[currentDashboard].layout = newLayout;

      return copiedCurr;
    });
  };

  const handleChangeDashboardTitle = (event) => {
    setUserDashboards((curr) => {
      const copiedCurr = JSON.parse(JSON.stringify(curr));

      if (!copiedCurr[currentDashboard]) copiedCurr[currentDashboard] = {};

      copiedCurr[currentDashboard].title = event.target.value;

      return copiedCurr;
    });
  };

  // const handleChangeShowingDefaultDashboardItems = () => {
  //   setUserDashboards((curr) => {
  //     const copiedCurr = JSON.parse(JSON.stringify(curr));

  //     if (!copiedCurr[currentDashboard]) copiedCurr[currentDashboard] = {};

  //     copiedCurr[currentDashboard].showDefault =
  //       copiedCurr[currentDashboard].showDefault === false;

  //     return copiedCurr;
  //   });
  // };

  const getUser = async () => {
    if (demo) return setUserDashboards(demoDashboard);

    try {
      const token = await getAccessTokenSilently();

      const params = [
        `email=${encodeURIComponent(userFromDb.email)}`,
        `sub=${encodeURIComponent(userFromDb.sub)}`,
      ];

      if (userFromDb.portal_id)
        params.push(`portal_id=${encodeURIComponent(userFromDb.portal_id)}`);

      const { users } = await apiDataService.getWithoutPortalId({
        token,
        params,
      });

      setUserFromDb(users[0]);

      setUserDashboards(users[0]?.dashboard || {});
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const setUserDashboard = async () => {
    await getUser();
  };

  const handleChangeCurrentDashboard = async (changeTo) => {
    setCurrentDashboard(changeTo);
  };

  const handleSaveDashboard = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (isSaving || saveButtonRef?.current?.disabled) return;
    if (!saveButtonRef?.current?.disabled && saveButtonRef?.current)
      saveButtonRef.current.disabled = true;

    setIsSaving(true);

    try {
      const newDashboards = {};

      const dashboards = Object.keys(userDashboards);

      dashboards.forEach((screen) => {
        const newGridItemsData = {};

        userDashboards[screen]?.layout?.forEach((item) => {
          const itemName = item.i;

          const gridItemData = {
            ...userDashboards[screen].gridItemsData[itemName],
          };

          if (gridItemData.selectedReport.report_data) {
            delete gridItemData.selectedReport.report_data;
          }

          newGridItemsData[itemName] = gridItemData;
        });

        newDashboards[screen] = {
          ...userDashboards[screen],
        };

        if (Object.keys(newGridItemsData).length)
          newDashboards[screen].gridItemsData = newGridItemsData;
      });

      const reqBody = {
        updates: [{ id: demo ? 1 : userFromDb.id, dashboard: newDashboards }],
        userId: demo ? 1 : userFromDb.id,
      };

      await apiDataService.update({
        portalId: demo ? 'demo' : userFromDb.portal_id,
        reqBody,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;

      setUserDashboard();
      setHasChanges(false);
      setIsLocked(true);

      alert.success('Changes saved');
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;
    }
  };

  useEffect(() => {
    if (!isLocked && !hasChanges) setHasChanges(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDashboards]);

  if (!userDashboards || !reports) return <LoadingSpinner />;

  const maxElementLimitReached =
    userDashboards?.[currentDashboard]?.layout?.length >= 50;

  // const isShowingDefaultDashboardItems =
  //   userDashboards?.[currentDashboard]?.showDefault !== false;

  return (
    <div>
      {shouldDisplayPortalCreated ? (
        <PortalCreated
          setShouldDisplayPortalCreated={setShouldDisplayPortalCreated}
        />
      ) : (
        <></>
      )}

      <PanelsControlBar>
        <Link to={`/${demo ? 'demo' : userFromDb.portal_id}/dashboard/1`}>
          <ButtonIconOnly
            classes={
              currentDashboard === 1
                ? 'button-with-options-ellipses-showing'
                : ''
            }
            disabled={isSaving}
            iconName='one'
            title='Dashboard 1'
            margin='5px'
            onClick={() => handleChangeCurrentDashboard(1)}
          />
        </Link>

        <Link to={`/${demo ? 'demo' : userFromDb.portal_id}/dashboard/2`}>
          <ButtonIconOnly
            classes={
              currentDashboard === 2
                ? 'button-with-options-ellipses-showing'
                : ''
            }
            disabled={isSaving}
            iconName='two'
            title='Dashboard 2'
            margin='5px'
            onClick={() => handleChangeCurrentDashboard(2)}
          />
        </Link>

        <Link to={`/${demo ? 'demo' : userFromDb.portal_id}/dashboard/3`}>
          <ButtonIconOnly
            classes={
              currentDashboard === 3
                ? 'button-with-options-ellipses-showing'
                : ''
            }
            disabled={isSaving}
            iconName='three'
            title='Dashboard 3'
            margin='5px'
            onClick={() => handleChangeCurrentDashboard(3)}
          />
        </Link>

        {hasChanges && (
          <ButtonStandardNoFill
            margin='5px'
            buttonRef={saveButtonRef}
            disabled={isLocked || isSaving}
            iconName='save-solid'
            onClick={handleSaveDashboard}
            text='Save Changes'
            typeStyle='type-4'
          />
        )}

        <ButtonIconOnly
          disabled={isLocked || isSaving || maxElementLimitReached}
          iconName='plus'
          title={
            isLocked
              ? 'Unlock dashboard to add an element'
              : maxElementLimitReached
              ? 'Maximum element limit reached'
              : 'Add element'
          }
          margin='5px'
          onClick={maxElementLimitReached ? null : addGridItem}
        />

        <ButtonIconOnly
          disabled={isSaving}
          classes={isLocked ? '' : 'lock-dashboard-button'}
          iconName={isLocked ? 'locked' : 'unlocked'}
          title={isLocked ? 'Locked' : 'Unlocked'}
          margin='5px'
          onClick={() => setIsLocked((curr) => !curr)}
        />
      </PanelsControlBar>

      <FlexContainer
        classes='dashboard-header-data'
        justifyContent='space-between'
      >
        <Text
          classes='dashboard-header-item dashboard-header-item-org'
          text={demo ? 'Demo Company' : userFromDb?.org}
        />

        <HeaderSecondary
          classes='dashboard-header-item dashboard-header-item-user'
          text={
            demo
              ? 'Demo User'
              : `${userFromDb?.first_name} ${userFromDb.last_name}`
          }
        />
      </FlexContainer>

      <FlexContainer
        classes='dashboard-header-title'
        justifyContent='flex-start'
      >
        {isLocked ? (
          <HeaderPrimary
            classes='dashboard-header-item dashboard-header-item-title'
            text={
              userDashboards?.[currentDashboard]?.title ??
              `Dashboard${currentDashboard === 1 ? '' : ` ${currentDashboard}`}`
            }
          />
        ) : (
          <InputStandard
            classes='dashboard-header-item'
            onChange={handleChangeDashboardTitle}
            placeholder='Dashboard Title'
            value={
              userDashboards?.[currentDashboard]?.title ??
              `Dashboard${currentDashboard === 1 ? '' : ` ${currentDashboard}`}`
            }
          />
        )}

        {/* <ButtonIconOnly
          iconName={
            isShowingDefaultDashboardItems ? 'arrows-up' : 'arrows-down'
          }
          marginLeft='20px'
          onClick={handleChangeShowingDefaultDashboardItems}
          title={
            isShowingDefaultDashboardItems
              ? 'Hide default dashboard items'
              : 'Show default dashboard items'
          }
        /> */}
      </FlexContainer>

      <ReactGridLayout
        className='layout'
        layout={generateLayout()}
        cols={60}
        rowHeight={7}
        width={1200}
        onLayoutChange={(layout) => handleChangeLayout(layout)}
        isDraggable={!isLocked && !preventDragResize}
        isResizable={!isLocked && !preventDragResize}
      >
        {userDashboards?.[currentDashboard]?.layout?.map((item) => {
          return (
            <div
              key={item.i}
              className={`dashboard-grid-item ${
                currentlyOpenOptions === item.i
                  ? 'dashboard-item-options-open'
                  : ''
              } ${isLocked ? 'dashboard-locked' : ''}`}
            >
              <GridItem
                currentlyOpenOptions={currentlyOpenOptions}
                demo={demo}
                gridItemsData={
                  userDashboards?.[currentDashboard]?.gridItemsData
                }
                handleChangeGridItemsData={handleChangeGridItemsData}
                isLocked={isLocked}
                item={item}
                removeGridItem={removeGridItem}
                reports={reports}
                setCurrentlyOpenOptions={setCurrentlyOpenOptions}
                setPreventDragResize={setPreventDragResize}
                userDashboards={userDashboards}
              />
            </div>
          );
        })}
      </ReactGridLayout>
    </div>
  );
}

export default DynamicGridLayout;
