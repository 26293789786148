import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { copyLines, generateUniqueRef } from '../../../utils/helpers';
import { handleApiError } from '../../../utils/error-handling';
import { useAuth0 } from '@auth0/auth0-react';
import { useAlert } from 'react-alert';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiDataServiceAgents from '../../../api/agents';
import apiDataServiceOrgLevels from '../../../api/org-levels';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import NewLine from '../../Elements/NewLine';
import NewScreenControlPanel from '../../Elements/NewScreenControlPanel';
import Panels from '../../Elements/Panels';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import PanelsSections from '../../Elements/PanelsSections';

const AgentsNewContent = ({ demo }) => {
  const navigate = useNavigate();

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { getAccessTokenSilently } = useAuth0();

  const alert = useAlert();

  const saveButtonRef = useRef(null);

  const [orgLevels, setOrgLevels] = useState();
  const [lines, setLines] = useState([]);
  const [isSaving, setIsSaving] = useState();
  const [hasChanges, setHasChanges] = useState();

  const getOrgLevels = async () => {
    try {
      const { orgLevels } = await apiDataServiceOrgLevels.getAll({
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setOrgLevels(orgLevels);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const handleAddLine = () => {
    const line = { key: generateUniqueRef() };

    orgLevels.forEach(
      (orgLevel) =>
        (line[orgLevel.level] = {
          title: orgLevel.name,
          value: '',
          type: 'text',
        })
    );

    setLines((curr) => [...curr, line]);
  };

  const handleChangeValue = (event, lineIndex, itemKey) => {
    setLines((curr) => {
      const newLines = copyLines(curr);

      newLines[lineIndex][itemKey].value = event.target.value;

      return newLines;
    });

    if (!hasChanges) setHasChanges(true);
  };

  const handleSave = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (isSaving || saveButtonRef?.current?.disabled) return;
    if (!saveButtonRef?.current?.disabled && saveButtonRef?.current)
      saveButtonRef.current.disabled = true;

    setIsSaving(true);

    try {
      const reqBody = {
        agents: [],
        userId: demo ? 1 : userId,
      };

      lines.forEach((line) => {
        const newObj = {};
        let hasValue;

        Object.entries(line).forEach((item) => {
          if (item[0] !== 'key') {
            newObj[`level${item[0]}`] = item[1].value;

            if (item[1].value) hasValue = true;
          }
        });

        if (hasValue) reqBody.agents.push(newObj);
      });

      if (reqBody.agents.length) {
        await apiDataServiceAgents.post({
          portalId: demo ? 'demo' : portalId,
          reqBody,
          token: demo ? undefined : await getAccessTokenSilently(),
        });
      } else {
        alert.show('No agents to add');
      }

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;

      if (reqBody.agents.length) {
        navigate(
          `/${demo ? 'demo' : portalId}/${
            userFromDb.permissions?.includes('agents.view')
              ? 'agents'
              : 'dashboard'
          }`,
          {
            state: { isNavigating: true },
          }
        );

        alert.success(`Agent${reqBody.agents.length === 1 ? '' : 's'} added`);
      }
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;
    }
  };

  useEffect(() => {
    if (!demo && !userFromDb.permissions?.includes('agents.change')) {
      navigate(`/${demo ? 'demo' : portalId}/dashboard`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      setBreadcrumbs([
        [
          'Dashboard',
          'dashboard',
          '',
          `/${demo ? 'demo' : portalId}/dashboard`,
        ],
        ['Agents', 'headset', '', `/${demo ? 'demo' : portalId}/agents`],
        ['New', 'plus'],
      ]);

      getOrgLevels();
    }

    return () => {
      setOrgLevels();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orgLevels) handleAddLine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgLevels]);

  if (!lines.length) return <LoadingSpinner />;
  else
    return (
      <Panels>
        <NewScreenControlPanel
          handleAddLine={handleAddLine}
          handleSave={handleSave}
          saveButtonRef={saveButtonRef}
          saveButtonDisabled={!hasChanges || isSaving}
        />

        <PanelsHeaderPrimary
          iconName='headset'
          subIcon='plus'
          text='New Agent'
        />

        <PanelsSections>
          {lines.map((line, lineIndex, lineArray) => {
            return (
              <PanelsSection key={line.key} typeStyle='type-3'>
                <NewLine
                  lineIndex={lineIndex}
                  line={line}
                  setLines={setLines}
                  noOfLines={lineArray.length}
                  handleChangeValue={handleChangeValue}
                />
              </PanelsSection>
            );
          })}
        </PanelsSections>
      </Panels>
    );
};

export default AgentsNewContent;
