import { isBannedChars, validatePresenceRequired } from '../../utils/helpers';

export const agentsDefaultColumns = ['level0', 'level1', 'level2', 'level3'];

export const agentsAvailableColumns = {
  created_at: 'Created Date',
  created_by: 'Created By',
  id: 'ID',
  last_modified_at: 'Last Modified Date',
  last_modified_by: 'Last Modified By',
};

export const agentsUnEditableColumns = [
  'created_at',
  'created_by',
  'id',
  'last_modified_at',
  'last_modified_by',
];

export const agentsValidationColumns = (columns) => {
  const validationColumns = {};

  columns.forEach((column) => {
    validationColumns[column] = (value) => {
      const isBannedCharsText = isBannedChars(value);

      if (isBannedCharsText) return [isBannedCharsText];
    };
  });

  return validationColumns;
};

export const calibrationsDefaultColumns = [
  'scorecard_name',
  'host_username',
  'details',
  'calibration_date',
  'submitted_percent',
  'variance',
];

export const calibrationsAvailableColumns = {
  id: 'ID',
  created_at: 'Created Date',
  created_by: 'Created By',
  benchmark_evaluation_id: 'Benchmark Evaluation ID',
  calibration_date: 'Calibration Date',
  details: 'Details',
  host_username: 'Host',
  last_modified_at: 'Last Modified Date',
  last_modified_by: 'Last Modified By',
  locked: 'Locked',
  pf_variance: 'Variance (Pass / Fail)',
  qs_variance: 'Variance (Quality Score)',
  released: 'Shared',
  scorecard_name: 'Scorecard',
  submitted_percent: 'Submitted %',
  type: 'Type',
  variance: 'Variance',
};

export const calibrationsUnEditableColumns = [
  'id',
  'benchmark_evaluation_id',
  'created_at',
  'created_by',
  'host_username',
  'last_modified_at',
  'last_modified_by',
  'locked',
  'scorecard_name',
  'submitted_percent',
  'variance',
  'qs_variance',
  'pf_variance',
  'released',
  'type',
];

export const dataListsDefaultColumns = ['name', 'list'];

export const dataListsAvailableColumns = {
  created_at: 'Created Date',
  created_by: 'Created By',
  id: 'ID',
  last_modified_at: 'Last Modified Date',
  last_modified_by: 'Last Modified By',
  list: 'List',
  name: 'Name',
};

export const dataListsUnEditableColumns = [
  'created_at',
  'created_by',
  'id',
  'last_modified_at',
  'last_modified_by',
];

export const dataListsValidationColumns = {
  name: (value, property) => {
    const invalidText = validatePresenceRequired(value, `'${property}'`);

    if (invalidText) return [invalidText];
  },
};

export const dataListsRequiredFields = ['name'];

export const draftsDefaultColumns = ['type', 'scorecard', 'created_at'];

export const draftsAvailableColumns = {
  created_at: 'Created Date',
  created_by: 'Created By',
  scorecard: 'Scorecard',
  type: 'Type',
};

export const draftsUnEditableColumns = [
  'type',
  'created_at',
  'created_by',
  'scorecard',
];

export const evaluationsDefaultColumns = [
  'scorecard_name',
  'quality_score',
  'brag',
  'pass_fail',
  'fed_back',
  'created_by',
  'created_at',
];

export const evaluationsAvailableColumns = {
  id: 'ID',
  brag: 'RAG',
  comments_main: 'Main Comments',
  created_at: 'Created Date',
  created_by: 'Created By',
  fed_back: 'Fed Back',
  fed_back_at: 'Fed Back Date',
  fed_back_by_user_id: 'Fed Back By (User ID)',
  imported: 'Imported',
  last_modified_at: 'Last Modified Date',
  last_modified_by: 'Last Modified By',
  pass_fail: 'Grade',
  quality_score: 'Score',
  scorecard_name: 'Scorecard',
};

export const evaluationsUnEditableColumns = [
  'id',
  'brag',
  // 'fed_back_at',
  // 'fed_back_by_user_id',
  'imported',
  'pass_fail',
  'quality_score',
  'scorecard_name',
  'created_at',
  'created_by',
  'last_modified_at',
  'last_modified_by',
  'status',
];

export const logsDefaultColumns = [
  'table_name',
  'method',
  'details',
  'by_system',
  'created_by_username',
  'created_at',
];

export const logsAvailableColumns = {
  id: 'ID',
  table_name: 'Table',
  row_id: 'Table Row ID',
  method: 'Method',
  details: 'Details',
  by_system: 'By System?',
  created_at: 'Created Date',
  created_by_username: 'Created By',
};

export const logsUnEditableColumns = [
  'id',
  'table_name',
  'method',
  'details',
  'created_at',
  'by_system',
  'row_id',
  'created_by_username',
];

export const reportsDefaultColumns = ['name', 'description'];

export const reportsAvailableColumns = {
  created_at: 'Created Date',
  created_by: 'Created By',
  id: 'ID',
  last_modified_at: 'Last Modified Date',
  last_modified_by: 'Last Modified By',
  description: 'Description',
  name: 'Name',
};

export const reportsUnEditableColumns = [
  'created_at',
  'created_by',
  'id',
  'last_modified_at',
  'last_modified_by',
];

export const reportsValidationColumns = {
  name: (value, property) => {
    const invalidText = validatePresenceRequired(value, `'${property}'`);

    if (invalidText) return [invalidText];
  },
};

export const reportsRequiredFields = ['name'];

export const scorecardsDefaultColumns = ['name', 'description', 'status'];

export const scorecardsAvailableColumns = {
  amber: 'RAG: Amber',
  created_at: 'Created Date',
  created_by: 'Created By',
  count_evaluations_vcid: 'Evaluations',
  description: 'Description',
  green: 'RAG: Green',
  id: 'ID',
  last_modified_at: 'Last Modified Date',
  last_modified_by: 'Last Modified By',
  logo_url: 'Logo URL',
  name: 'Name',
  neg: 'Fail Label',
  org_name: 'Org name',
  pass_mark: 'Pass Mark',
  pos: 'Pass Label',
  red: 'RAG: Red',
  status: 'Status',
};

export const scorecardsUnEditableColumns = [
  'count_evaluations_vcid',
  'created_at',
  'created_by',
  'id',
  'last_modified_at',
  'last_modified_by',
  'status',
];

export const scorecardTemplatesAvailableColumns = {
  amber: 'RAG: Amber',
  created_at: 'Created Date',
  created_by: 'Created By',
  description: 'Description',
  green: 'RAG: Green',
  id: 'ID',
  last_modified_at: 'Last Modified Date',
  last_modified_by: 'Last Modified By',
  logo_url: 'Logo URL',
  name: 'Name',
  neg: 'Fail Label',
  org_name: 'Org name',
  pass_mark: 'Pass Mark',
  pos: 'Pass Label',
  red: 'RAG: Red',
};

export const scorecardTemplatesDefaultColumns = ['name', 'description'];

export const scorecardTemplatesUnEditableColumns = [
  'created_at',
  'created_by',
  'id',
  'last_modified_at',
  'last_modified_by',
];

const ragValidator = (value, property, record, gridChangesRow) => {
  const invalidText = [];

  const rag = [
    gridChangesRow && Object.keys(gridChangesRow).includes('green')
      ? gridChangesRow.green
      : record.green,
    gridChangesRow && Object.keys(gridChangesRow).includes('amber')
      ? gridChangesRow.amber
      : record.amber,
    gridChangesRow && Object.keys(gridChangesRow).includes('red')
      ? gridChangesRow.red
      : record.red,
  ]
    .filter((brag) => brag !== undefined && brag !== null)
    .sort((a, b) => a - b);

  if (
    value !== undefined &&
    value !== null &&
    !/^[0-9][0-9]?$|^100$/.test(value)
  )
    invalidText.push('RAG value must be a whole number from 0 to 100');

  if (rag.length > 0 && rag[0] !== 0)
    invalidText.push('Lowest RAG value must be 0');

  if (rag.slice(rag.indexOf(value) + 1).indexOf(value) !== -1)
    invalidText.push('No duplicate RAG values');

  if (invalidText.length) return invalidText;
};

export const scorecardsValidationColumns = {
  green: ragValidator,

  red: ragValidator,

  amber: ragValidator,

  name: (value, property) => {
    const invalidText = validatePresenceRequired(value, `'${property}'`);

    if (invalidText) return [invalidText];

    const isBannedCharsText = isBannedChars(value, 'Scorecard Name');

    if (isBannedCharsText) return [isBannedCharsText];
  },

  pos: (value, property, record, gridChangesRow) => {
    const invalidText = [];

    const invalidPresenceRequired = validatePresenceRequired(
      value,
      `'${property}'`
    );

    if (invalidPresenceRequired) invalidText.push(invalidPresenceRequired);

    const sameAsNeg =
      gridChangesRow && Object.keys(gridChangesRow).includes('neg')
        ? value === gridChangesRow.neg
        : value === record.neg;

    if (sameAsNeg)
      invalidText.push(
        'Positive and negative marking choices must not be the same'
      );

    if (value?.toLowerCase().trim() === 'na') invalidText.push("Can't be NA");

    if (invalidText.length) return invalidText;
  },

  neg: (value, property, record, gridChangesRow) => {
    const invalidText = [];

    const invalidPresenceRequired = validatePresenceRequired(
      value,
      `'${property}'`
    );

    if (invalidPresenceRequired) invalidText.push(invalidPresenceRequired);

    const sameAsPos =
      gridChangesRow && Object.keys(gridChangesRow).includes('pos')
        ? value === gridChangesRow.pos
        : value === record.pos;

    if (sameAsPos)
      invalidText.push(
        'Positive and negative marking choices must not be the same'
      );

    if (value?.toLowerCase().trim() === 'na') invalidText.push("Can't be NA");

    if (invalidText.length) return invalidText;
  },
  pass_mark: (value, property) => {
    if (value && !/^[1-9][0-9]?$|^100$/.test(value))
      return ['Pass Mark must be a whole number from 1 to 100'];
  },
};

export const scorecardsRequiredFields = ['name', 'pos', 'neg'];

export const usersDefaultColumns = [
  'username',
  'first_name',
  'last_name',
  'email',
  'role',
];

export const usersAvailableColumns = {
  created_at: 'Created Date',
  created_by: 'Created By',
  email: 'Email',
  first_name: 'First Name',
  last_modified_at: 'Last Modified Date',
  last_modified_by: 'Last Modified By',
  last_name: 'Last Name',
  role: 'Role',
  // status: 'Status',
  username: 'Username',
  id: 'ID',
  invite_accepted: 'Invite Accepted',
};

export const usersUnEditableColumns = [
  'created_at',
  'created_by',
  'email',
  'first_name',
  'id',
  'invite_accepted',
  'last_modified_at',
  'last_modified_by',
  'last_name',
  'role',
  'username',
];

export const usersValidationColumns = {
  username: (value, property) => {
    const invalidText = validatePresenceRequired(value, `'${property}'`);

    if (invalidText) return [invalidText];
  },
  first_name: (value, property) => {
    const invalidText = validatePresenceRequired(value, `'${property}'`);

    if (invalidText) return [invalidText];
  },
  last_name: (value, property) => {
    const invalidText = validatePresenceRequired(value, `'${property}'`);

    if (invalidText) return [invalidText];
  },
  email: (value, property) => {
    const invalidText = validatePresenceRequired(value, `'${property}'`);

    if (invalidText) return [invalidText];
  },
  role: (value, property) => {
    const invalidText = validatePresenceRequired(value, `'${property}'`);

    if (invalidText) return [invalidText];
  },
  status: (value, property) => {
    const invalidText = validatePresenceRequired(value, `'${property}'`);

    if (invalidText) return [invalidText];
  },
};

export const usersRequiredFields = [
  'email',
  'first_name',
  'last_name',
  'role',
  'status',
  'username',
];
