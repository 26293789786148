import './FlexContainer.css';

const FlexContainer = ({
  children,
  alignItems = 'center',
  alignSelf,
  classes = '',
  flex,
  flexDirection = 'row',
  flexRef,
  flexWrap,
  height,
  justifyContent = 'center',
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  maxWidth,
  padding,
  paddingBottom,
  paddingRight,
  position,
  width,
}) => {
  const style = {
    alignItems,
    flexDirection,
    justifyContent,
  };

  if (alignSelf) style.alignSelf = alignSelf;
  if (flex) style.flex = flex;
  if (flexWrap) style.flexWrap = flexWrap;
  if (height) style.height = height;
  if (margin) style.margin = margin;
  if (marginBottom) style.marginBottom = marginBottom;
  if (marginLeft) style.marginLeft = marginLeft;
  if (marginRight) style.marginRight = marginRight;
  if (marginTop) style.marginTop = marginTop;
  if (maxWidth) style.maxWidth = maxWidth;
  if (padding) style.padding = padding;
  if (paddingBottom) style.paddingBottom = paddingBottom;
  if (paddingRight) style.paddingRight = paddingRight;
  if (position) style.position = position;
  if (width) style.width = width;

  return (
    <div className={`flex-container ${classes}`} ref={flexRef} style={style}>
      {children}
    </div>
  );
};

export default FlexContainer;
