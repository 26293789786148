import { apiUrl, headers } from './config';
import evaluationsDataService from './evaluations';
import scorecardsDataService from './scorecards';

const draftsApi = {
  getAll: async ({ params, portalId, token, userId }) => {
    const baseUrl = `/api/portals/${portalId}/drafts`;
    const urlParams = `${params.length ? `?${params.join('&')}` : ''}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  gridDeleteMultiple: async ({
    selectedRecordIds,
    portalId,
    recordData,
    reqBody,
    token,
  }) => {
    const scorecardIds = [];
    const evaluationIds = [];

    selectedRecordIds.forEach((record) => {
      const splitRecord = record.split('-');

      if (splitRecord[0] === 'Scorecard') scorecardIds.push(splitRecord[1]);
      else if (splitRecord[0] === 'Evaluation')
        evaluationIds.push(splitRecord[1]);
    });

    if (scorecardIds.length) {
      await scorecardsDataService.gridDeleteMultiple({
        selectedRecordIds: scorecardIds,
        portalId,
        recordData,
        reqBody,
        token,
      });
    }

    if (evaluationIds.length) {
      await evaluationsDataService.gridDeleteMultiple({
        selectedRecordIds: evaluationIds,
        portalId,
        reqBody,
        token,
      });
    }
  },
};

export default draftsApi;
