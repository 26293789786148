import Icon from './Icon';

import './ButtonIcon.css';

const ButtonIcon = ({
  buttonClass = '',
  disabled,
  height = '16px',
  iconClass = '',
  iconName,
  onClick,
  style = {},
  title,
  type = 'button',
  width = '16px',
}) => {
  return (
    <button
      className={`button-icon ${buttonClass}`}
      disabled={disabled}
      onClick={onClick}
      style={{ ...style, width, height }}
      title={title}
      type={type}
    >
      <Icon
        className={`button-icon--icon ${iconClass}`}
        iconName={iconName}
        style={{ width, height }}
      />
    </button>
  );
};

export default ButtonIcon;
