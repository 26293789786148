import { copyLines } from '../../utils/helpers';
import ButtonIconOnly from './ButtonIconOnly';
import FlexContainer from './FlexContainer';
import NewLineItem from './NewLineItem';

import './NewLine.css';

const NewLine = ({
  demo,
  handleChangeValue,
  lineIndex,
  line,
  noOfLines,
  setLines,
  showInvalid,
  showInvalidIfEdited,
}) => {
  const handleRemoveLine = () => {
    setLines((curr) => {
      const newLines = copyLines(curr);

      if (newLines.length === 1) return newLines;
      else {
        newLines.splice(lineIndex, 1);

        return newLines;
      }
    });
  };

  return (
    <FlexContainer
      classes='new-line'
      margin={noOfLines > 1 ? '10px' : '5px 10px'}
      position='relative'
      justifyContent='flex-start'
    >
      {noOfLines > 1 && (
        <ButtonIconOnly
          classes='delete-line-button'
          disabled={noOfLines < 1}
          iconName='minus'
          onClick={() => handleRemoveLine(line)}
          title='Remove line'
          typeStyle='type-2'
        />
      )}

      <FlexContainer
        classes='new-line-items'
        marginLeft={noOfLines > 1 ? '40px' : '0'}
        flexWrap='wrap'
        justifyContent='flex-start'
      >
        {Object.entries(line)
          .filter((prop) => prop[0] !== 'key')
          .map(([itemKey, lineItem]) => {
            return (
              <NewLineItem
                demo={demo}
                key={`${lineIndex}${itemKey}`}
                lineItem={lineItem}
                lineIndex={lineIndex}
                itemId={`${lineIndex}${itemKey}`}
                itemKey={itemKey}
                handleChangeValue={handleChangeValue}
                showInvalid={showInvalid}
                showInvalidIfEdited={showInvalidIfEdited}
              />
            );
          })}
      </FlexContainer>
    </FlexContainer>
  );
};

export default NewLine;
