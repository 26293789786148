import { useEffect, useState } from 'react';

import calculateQualityScore from '../../utils/scoringAndFeedbackUtils/calculateQualityScore';
import Icon from '../Elements/Icon';
import InputValidityIcon from '../Elements/InputValidityIcon';

import './FillScoringSections.css';

const FillScoringSections = ({
  categoryId,
  section,
  sectionPos,
  sectionNeg,
  commentsResults,
  fromPrintable,
  handleSetCommentsResults,
  handleSetScoringResults,
  uniqueRef,
  sectionResults,
  scorecardBehaviourTotals,
  sectionResultsCat,
  categoryBehaviourTotals,
  setSectionResults,
  setScorecardBehaviourTotals,
  setQualityScore,
  setSectionResultsCat,
  setCategoryBehaviourTotals,
  setCategoryScores,
  brag,
  setBragBackground,
  handleSetInvalidSections,
  showInvalid,
  evaluatedScoringSection,
  setEditChangesCat,
  showScreen,
  isEditingEvaluation,
}) => {
  const { title, type, description, weight, ra, csm } = section;
  const markingChoice = [sectionPos, sectionNeg];

  const [psmsClass, setPsmsClass] = useState(
    evaluatedScoringSection?.type === 'scoring'
      ? evaluatedScoringSection.data === 'pass'
        ? 'section-mark-pass'
        : evaluatedScoringSection.data === 'fail'
        ? 'section-mark-fail'
        : evaluatedScoringSection.data === 'na'
        ? 'section-mark-na'
        : 'section-mark-none'
      : 'psms-none'
  );
  const [previousMark, setPreviousMark] = useState(
    evaluatedScoringSection?.type === 'scoring'
      ? evaluatedScoringSection.data === 'pass'
        ? sectionPos
        : evaluatedScoringSection.data === 'fail'
        ? sectionNeg
        : 'NA'
      : 'NA'
  );

  const [selectedMark, setSelectedMark] = useState(
    evaluatedScoringSection?.type === 'scoring'
      ? evaluatedScoringSection.data === 'pass'
        ? sectionPos
        : evaluatedScoringSection.data === 'fail'
        ? sectionNeg
        : evaluatedScoringSection.data === 'na'
        ? 'NA'
        : ''
      : ''
  );

  const handleMarkStatusChange = (event) => {
    const selectedOption = event.target.value;

    handleSetScoringResults(
      section.id,
      sectionPos === selectedOption
        ? 'pass'
        : sectionNeg === selectedOption
        ? 'fail'
        : selectedOption === 'NA'
        ? 'na'
        : null
    );

    setSelectedMark(selectedOption);

    if (selectedOption === sectionPos) {
      setPsmsClass('section-mark-pass');
    } else if (selectedOption === sectionNeg) {
      setPsmsClass('section-mark-fail');
    } else if (selectedOption === 'NA') {
      setPsmsClass('section-mark-na');
    } else {
      setPsmsClass('section-mark-none');
    }

    const passFailNa = selectedOption === '' ? 'NA' : selectedOption;

    const {
      // preCsm,
      newQualityScore,
      newBehaviourTotals,
      // wasCsmUsed,
      newSectionResults,
    } = calculateQualityScore(
      scorecardBehaviourTotals,
      previousMark,
      passFailNa,
      section,
      markingChoice,
      sectionResults,
      uniqueRef
    );

    const {
      newQualityScore: newQualityScoreCat,
      newBehaviourTotals: newBehaviourTotalsCat,
      newSectionResults: newSectionResultsCat,
    } = calculateQualityScore(
      categoryBehaviourTotals,
      previousMark,
      passFailNa,
      section,
      markingChoice,
      sectionResultsCat,
      uniqueRef
    );

    setSectionResults(newSectionResults);
    setScorecardBehaviourTotals(newBehaviourTotals);
    setQualityScore(newQualityScore);

    setSectionResultsCat(newSectionResultsCat);
    setCategoryBehaviourTotals(newBehaviourTotalsCat);
    setCategoryScores((currCatScores) => {
      const newTotals = { ...currCatScores };
      if (newSectionResultsCat.markedCount > 0) {
        return { ...newTotals, [categoryId]: newQualityScoreCat };
      } else if (newTotals.hasOwnProperty(categoryId)) {
        delete newTotals[categoryId];
        return newTotals;
      } else {
        return newTotals;
      }
    });

    if (showScreen && categoryId !== 'catnocat')
      setEditChangesCat((curr) => {
        const currCopy = { ...curr };

        currCopy[categoryId.slice(3)] = newQualityScoreCat;

        return currCopy;
      });

    for (let i = 0; i < brag.length; i++) {
      if (newQualityScore >= parseInt(brag[i].value)) {
        setBragBackground(`scorecard-bragback-${brag[i].colour}`);
        break;
      }
    }

    setPreviousMark(passFailNa);
  };

  useEffect(() => {
    handleSetInvalidSections(
      `s${section.id}`,
      section.required && !selectedMark
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMark]);

  if (type === 'comments') {
    return (
      <li className='scorecard-comments-box-container' key={uniqueRef}>
        {fromPrintable ? (
          <div className='scorecard-comments-box'>
            {commentsResults[section.id]}
          </div>
        ) : (
          <textarea
            disabled={!isEditingEvaluation}
            className='scorecard-comments-box'
            onChange={(event) =>
              handleSetCommentsResults(section.id, event.target.value)
            }
            placeholder='Comments'
            value={commentsResults[section.id]}
          />
        )}
      </li>
    );
  } else {
    return (
      <li key={uniqueRef} className='scorecard-section-container'>
        {title && (
          <div className='scorecard-section-title-container'>
            <label
              htmlFor={'a' + uniqueRef}
              className='scorecard-section-title wrap-unbroken'
            >
              {title}
            </label>
          </div>
        )}

        <div className='scorecard-section-select-container'>
          {isEditingEvaluation && (
            <InputValidityIcon
              isInvalid={section.required && !selectedMark}
              isRequired={section.required}
              showInvalid={showInvalid}
            />
          )}

          <select
            disabled={!isEditingEvaluation}
            value={selectedMark}
            id={'a' + uniqueRef}
            className={`scorecard-section-select ${psmsClass}`}
            onChange={handleMarkStatusChange}
          >
            <option value=''></option>
            <option value={sectionPos}>{sectionPos}</option>
            <option value={sectionNeg}>{sectionNeg}</option>
            <option value='NA'>NA</option>
          </select>

          {isEditingEvaluation && (
            <div
              className={`scorecard-section-select-icon-container icon-container-${psmsClass}`}
            >
              <Icon
                className={`scorecard-section-select-icon icon-${psmsClass}`}
                iconName='circle-chevron-down'
              />
            </div>
          )}
        </div>

        {description && (
          <div className='scorecard-section-description-container'>
            <label
              htmlFor={'a' + uniqueRef}
              className='scorecard-section-description wrap-unbroken'
            >
              {description}
            </label>
          </div>
        )}

        {(weight || ra || csm) && (
          <div className='scorecard-behaviours-container'>
            {weight && (
              <div className='scorecard-behaviour-container'>
                <p className='scorecard-behaviour-name'>Weight: </p>
                <p className='scorecard-behaviour-amount'>
                  {parseFloat(weight)}
                </p>
              </div>
            )}

            {ra && (
              <div className='scorecard-behaviour-container'>
                <p className='scorecard-behaviour-name'>RA: </p>
                <p className='scorecard-behaviour-amount'>{parseFloat(ra)}</p>
              </div>
            )}

            {csm && (
              <div className='scorecard-behaviour-container'>
                <p className='scorecard-behaviour-name'>CSM: </p>
                <p className='scorecard-behaviour-amount'>{parseFloat(csm)}</p>
              </div>
            )}
          </div>
        )}
      </li>
    );
  }
};

export default FillScoringSections;
