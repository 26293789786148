import './ScoringSection.css';

import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useAlert } from 'react-alert';

import updateScoringSections from '../../../utils/createScorecardUtils/updateScoringSections';
import ButtonIcon from '../../Elements/ButtonIcon';

const ScoringSection = memo(function Card({
  id,
  moveCard,
  findCard,
  hasChangePermission,
  isDndOn,
  section,
  scoringSections,
  setScoringSections,
  setCategoryName,
  setSectionName,
  setSectionDescription,
  setWeight,
  setRa,
  setCsm,
  setIsRequired,
  currentlyEditingCat,
  setCurrentlyEditingCat,
  currentlyEditingSec,
  setCurrentlyEditingSec,
  setCategoryCount,
  setSectionCount,
  handleSetHasChanges,
}) {
  const ItemTypes = {
    CARD: 'scoring-card',
  };

  const originalIndex = findCard(id).index;
  const [, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        handleSetHasChanges?.();

        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      canDrop: () => false,
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );
  // const opacity = isDragging ? 0 : 1;

  const alert = useAlert();

  const { title, description, weight, ra, csm, required, uniqueRef, type } =
    section;

  const dndRefFunc = (node) => drag(drop(node));

  let addedSectionStyle = 'create-scoring-section-grid';

  if (type === 'scoring-category') addedSectionStyle += ' c-ss-cat';
  else if (type === 'comments-box') addedSectionStyle += ' c-ss-com';
  else {
    type === 'scoring-section' && description
      ? (addedSectionStyle += ' c-ss-all')
      : (addedSectionStyle += ' c-ss-d');
  }

  if (!isDndOn) addedSectionStyle += ' cursor-default';
  if (currentlyEditingCat === uniqueRef || currentlyEditingSec === uniqueRef)
    addedSectionStyle += ' edit-section-background';

  const deleteMessage =
    type === 'comments-box' ? 'Deleted comments box' : `Deleted: ${title}`;

  return (
    <li
      id={uniqueRef}
      className={addedSectionStyle}
      ref={isDndOn ? dndRefFunc : null}
    >
      {hasChangePermission ? (
        <ButtonIcon
          buttonClass='create-ss-del'
          iconClass='admin-section-delete-icon'
          width='15px'
          height='15px'
          iconName='trash'
          style={{
            marginLeft: '8px',
            marginRight: '8px',
          }}
          title='Delete'
          onClick={() => {
            updateScoringSections({
              del: {
                sectionType: type,
                sectionUniqueRef: uniqueRef,
                scoringSections: scoringSections,
                setScoringSections: setScoringSections,
                setCategoryCount: setCategoryCount,
                setSectionCount: setSectionCount,
                currentlyEditingCat: currentlyEditingCat,
                setCurrentlyEditingCat: setCurrentlyEditingCat,
                currentlyEditingSec: currentlyEditingSec,
                setCurrentlyEditingSec: setCurrentlyEditingSec,
              },
            });

            handleSetHasChanges?.();

            alert.success(deleteMessage);
          }}
        />
      ) : (
        <></>
      )}

      {type === 'comments-box' && (
        <p className='create-ss-com-text'>Comments box </p>
      )}

      {type !== 'comments-box' && hasChangePermission ? (
        <ButtonIcon
          buttonClass='create-ss-edit'
          iconClass='admin-section-edit-icon'
          width='15px'
          height='15px'
          iconName='edit-solid'
          style={{
            marginLeft: '8px',
            marginRight: '8px',
          }}
          title='Edit'
          onClick={() => {
            updateScoringSections({
              edit: {
                sectionToEdit: section,
                setCategoryName: setCategoryName,
                setSectionName: setSectionName,
                setSectionDescription: setSectionDescription,
                setWeight: setWeight,
                setRa: setRa,
                setCsm: setCsm,
                setIsRequired: setIsRequired,
                currentlyEditingCat: currentlyEditingCat,
                setCurrentlyEditingCat: setCurrentlyEditingCat,
                currentlyEditingSec: currentlyEditingSec,
                setCurrentlyEditingSec: setCurrentlyEditingSec,
              },
            });
          }}
        />
      ) : (
        <div style={{ width: '10px' }}></div>
      )}

      {type === 'scoring-section' &&
        (title ? (
          <p className='create-ss-title-text'>{title}</p>
        ) : (
          <p className='create-ss-title-none'>No title</p>
        ))}
      {type === 'scoring-category' &&
        (title ? (
          <p className='create-ss-cat-text'>{title}</p>
        ) : (
          <p className='create-ss-cat-none'>No title</p>
        ))}

      {type === 'scoring-section' &&
        (weight || ra || csm ? (
          <div className='create-ss-behavreq-container'>
            <div className='create-ss-behav-sub'>
              {!!weight && (
                <div className='create-ss-behav-item'>
                  <p className='create-ss-behav-text'>WT:</p>
                  <p className='create-ss-behav-num'>{weight}</p>
                </div>
              )}
              {!!ra && (
                <div className='create-ss-behav-item'>
                  <p className='create-ss-behav-text'>RA:</p>
                  <p className='create-ss-behav-num'>{ra}</p>
                </div>
              )}
              {!!csm && (
                <div className='create-ss-behav-item'>
                  <p className='create-ss-behav-text'>CSM:</p>
                  <p className='create-ss-behav-num'>{csm}</p>
                </div>
              )}
            </div>
            <p
              className={
                required === 'Required' ? 'create-ss-req' : 'create-ss-opt'
              }
            >
              {required}
            </p>
          </div>
        ) : (
          <div className='create-ss-behavreq-container'>
            <p className='create-ss-behav-none'>No behaviours</p>
            <p
              className={
                required === 'Required' ? 'create-ss-req' : 'create-ss-opt'
              }
            >
              {required}
            </p>
          </div>
        ))}
      {description && <p className='create-ss-desc-text'>{description}</p>}
    </li>
  );
});

export default ScoringSection;
