import { sortAlphaCaseInsensitive } from '../../../../utils/helpers';

import ExpandableSection from '../../../Elements/ExpandableSection';
import Filters from '../../Filters/Filters';

const Filter = ({
  availableColumns,
  clearFilters,
  columnTypes,
  isEditing,
  disabledDueToEditingText,
  onUpdateOptions,
  query,
  savedQuery,
  selectFrom,
  setClearFilters,
  setSavedQuery,
  showFilter,
  isReportsFilter,
  setExternalIsChangingCriteria,
  noOfFilters,
  setNoOfFilters,
  masterFilterInitialState,
  masterFilter,
  setMasterFilter,
}) => {
  return isReportsFilter ? (
    <Filters
      noOfFilters={noOfFilters}
      clearFilters={clearFilters}
      columnTypes={columnTypes}
      disabled={isEditing}
      disabledText={disabledDueToEditingText}
      options={Object.entries(availableColumns).sort((a, b) =>
        sortAlphaCaseInsensitive(a[1], b[1])
      )}
      query={query}
      savedQuery={savedQuery}
      selectFrom={selectFrom}
      setClearFilters={setClearFilters}
      setSavedQuery={setSavedQuery}
      onFilterChange={(query) => {
        onUpdateOptions('query', query);
      }}
      setNoOfFilters={setNoOfFilters}
      masterFilterInitialState={masterFilterInitialState}
      masterFilter={masterFilter}
      setMasterFilter={setMasterFilter}
    />
  ) : (
    <>
      <ExpandableSection
        isOpen={showFilter}
        containerClasses={`filter-container ${
          showFilter ? 'grid-options-background' : ''
        }`}
      >
        <Filters
          noOfFilters={noOfFilters}
          clearFilters={clearFilters}
          columnTypes={columnTypes}
          disabled={isEditing}
          disabledText={disabledDueToEditingText}
          options={Object.entries(availableColumns).sort((a, b) =>
            sortAlphaCaseInsensitive(a[1], b[1])
          )}
          query={query}
          savedQuery={savedQuery}
          selectFrom={selectFrom}
          setClearFilters={setClearFilters}
          setSavedQuery={setSavedQuery}
          onFilterChange={(query) => {
            onUpdateOptions('query', query);
          }}
          setNoOfFilters={setNoOfFilters}
          masterFilterInitialState={masterFilterInitialState}
          masterFilter={masterFilter}
          setMasterFilter={setMasterFilter}
        />
      </ExpandableSection>
    </>
  );
};

export default Filter;
