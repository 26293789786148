import EvaluationsShowContent from './EvaluationsShowContent';
import MainOnly from '../MainAndOptions/MainOnly';

const EvaluationsShow = ({
  demo,
  fromDrafts,
  fromSubmittedEvaluations,
  fromEvaluationBenchmark,
}) => {
  return (
    <MainOnly>
      <EvaluationsShowContent
        demo={demo}
        fromDrafts={fromDrafts}
        fromSubmittedEvaluations={fromSubmittedEvaluations}
        fromEvaluationBenchmark={fromEvaluationBenchmark}
      />
    </MainOnly>
  );
};

export default EvaluationsShow;
