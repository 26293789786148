import { useContext } from 'react';
import { UserPortal } from '../../../contexts';

import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import TextBreak from '../../Elements/TextBreak';

const AddUsersListItem = ({
  addedUsers,
  demo,
  firstRow,
  hasUsersViewPermission,
  id,
  isShowScreen,
  setAddedUsers,
  username,
}) => {
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;

  return (
    <li
      className={`${
        firstRow ? 'first-grid-row first-grid-row-add-users' : ''
      } grid-row linked-agents-grid-row grid-row-add-users ${
        hasUsersViewPermission ? '' : 'grid-row-no-view-permission'
      }`}
    >
      {hasUsersViewPermission ? (
        <a
          className='grid-row-cell grid-row-cell-participants linked-agents-grid-row-cell'
          href={`/${demo ? 'demo' : portalId}/users/${id}`}
        >
          <TextBreak text={username} classes='grid-row-cell-data' />
        </a>
      ) : (
        <div className='grid-row-cell grid-row-cell-participants linked-agents-grid-row-cell'>
          {' '}
          <TextBreak text={username} classes='grid-row-cell-data' />
        </div>
      )}

      <div className='grid-row-cell linked-agents-unlink-button'>
        <ButtonIconOnly
          disabled={addedUsers.length === 1}
          onClick={() => {
            if (addedUsers.length === 1) return;

            setAddedUsers((curr) => {
              const newAddedUsers = curr.filter((item) => item.id !== id);

              return newAddedUsers;
            });
          }}
          title={
            addedUsers.length === 1
              ? 'At least one user must have access'
              : 'Remove'
          }
          iconName='minus'
          typeStyle='type-2'
        />
      </div>
    </li>
  );
};

export default AddUsersListItem;
