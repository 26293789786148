import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { useAlert } from 'react-alert';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CSVUploader from './CSVUploader';
import ExpandableSection from '../../Elements/ExpandableSection';
import GridOption from '../DataGrid/GridContent/GridOption';
import MainOnly from '../MainAndOptions/MainOnly';
import Panels from '../../Elements/Panels';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';

import './Imports.css';

const Imports = ({ demo }) => {
  const navigate = useNavigate();

  const alert = useAlert();

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const hasAgentsPermission =
    demo || userFromDb.permissions?.includes('agents.change');
  const hasEvaluationsPermission =
    demo || userFromDb.permissions?.includes('evaluations.change');
  const hasUsersPermission =
    demo || userFromDb.permissions?.includes('users.change');

  const isInTrial = userFromDb.portal_trial_info?.isInTrial;

  const [showAgentImports, setShowAgentImports] = useState('');
  const [showEvaluationImports, setShowEvaluationImports] = useState('');
  const [showUserImports, setShowUserImports] = useState('');

  useEffect(() => {
    if (
      !hasAgentsPermission &&
      !hasEvaluationsPermission &&
      !hasUsersPermission
    ) {
      navigate(`/${demo ? 'demo' : portalId}/dashboard`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      setBreadcrumbs([
        [
          'Dashboard',
          'dashboard',
          '',
          `/${demo ? 'demo' : portalId}/dashboard`,
        ],
        ['Imports', 'file-import'],
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainOnly>
      <Panels>
        <PanelsHeaderPrimary iconName='file-import' text='Imports' />

        <PanelsSection>
          <GridOption
            disabled={!demo && (!hasAgentsPermission || isInTrial)}
            headerText='Agents'
            showGridOption={showAgentImports}
            setShowGridOption={setShowAgentImports}
            gridOption='agentImports'
            title={
              isInTrial && !demo
                ? 'Unable to import data during free trial'
                : hasAgentsPermission
                ? ''
                : "You don't have permission to import agents"
            }
          />

          {demo || (hasAgentsPermission && !isInTrial) ? (
            <ExpandableSection isOpen={showAgentImports === 'agentImports'}>
              <CSVUploader
                agents
                demo={demo}
                portalId={portalId}
                userId={userId}
              />
            </ExpandableSection>
          ) : (
            <></>
          )}
        </PanelsSection>

        <PanelsSection>
          <GridOption
            disabled={!demo && (!hasUsersPermission || isInTrial)}
            headerText='Users'
            showGridOption={showUserImports}
            setShowGridOption={setShowUserImports}
            gridOption='userImports'
            title={
              isInTrial && !demo
                ? 'Unable to import data during free trial'
                : hasUsersPermission
                ? ''
                : "You don't have permission to import users"
            }
          />

          {demo || (hasUsersPermission && !isInTrial) ? (
            <ExpandableSection isOpen={showUserImports === 'userImports'}>
              <CSVUploader
                demo={demo}
                portalId={portalId}
                userId={userId}
                users
              />
            </ExpandableSection>
          ) : (
            <></>
          )}
        </PanelsSection>

        <PanelsSection>
          <GridOption
            disabled={!demo && (!hasEvaluationsPermission || isInTrial)}
            headerText='Evaluations'
            showGridOption={showEvaluationImports}
            setShowGridOption={setShowEvaluationImports}
            gridOption='evaluationImports'
            title={
              isInTrial && !demo
                ? 'Unable to import data during free trial'
                : hasEvaluationsPermission
                ? ''
                : "You don't have permission to import evaluations"
            }
          />

          {demo || (hasEvaluationsPermission && !isInTrial) ? (
            <ExpandableSection
              isOpen={showEvaluationImports === 'evaluationImports'}
            >
              <CSVUploader
                demo={demo}
                evaluations
                portalId={portalId}
                userId={userId}
              />
            </ExpandableSection>
          ) : (
            <></>
          )}
        </PanelsSection>
      </Panels>
    </MainOnly>
  );
};

export default Imports;
