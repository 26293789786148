import {
  calibrationsAvailableColumns,
  calibrationsDefaultColumns,
  calibrationsUnEditableColumns,
} from '../columns';

import apiDataService from '../../../api/calibrations';
import DataGrid from '../DataGrid/DataGrid';

const Calibrations = ({ demo }) => {
  const getAvailableColumns = (columns = {}) => {
    return { ...calibrationsAvailableColumns, ...columns };
  };

  const defaultOptions = {
    customColumns: null,
    limit: 10,
    page: 1,
    query: null,
    sortBy: null,
    view: 'table',
  };

  const selectFrom = {
    type: [
      ['consensus', 'Group Average'],
      ['benchmark', 'Benchmark'],
    ],
  };

  return (
    <DataGrid
      apiDataService={apiDataService}
      defaultColumns={calibrationsDefaultColumns}
      defaultOptions={defaultOptions}
      demo={demo}
      getAvailableColumns={getAvailableColumns}
      gridName='calibrations'
      iconName='bullseye'
      newScreenRoute='new'
      selectFrom={selectFrom}
      unEditableColumns={calibrationsUnEditableColumns}
    />
  );
};

export default Calibrations;
