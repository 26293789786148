import { memo, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { UserPortal } from '../../contexts';

import NavItem from '../Portal/Nav/NavItem';

import './DraggableToggleListItem.css';

const DraggableNavListItem = memo(function Card({
  id,
  disabled,
  moveCard,
  findCard,
  card,
  itemType,
  setIsNavOpen,
  setNavFavourites,
  navFavourites,
  demo,
}) {
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;

  const ItemTypes = {
    CARD: itemType,
  };
  const originalIndex = findCard(id).index;
  const [, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      canDrop: () => false,
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );

  const dndRefFunc = (node) => drag(drop(node));

  const portalIdPath = demo ? 'demo' : portalId;

  return (
    <li id={card.id} ref={card.toggled && !disabled ? dndRefFunc : null}>
      <NavItem
        name={card.name}
        icon={card.icon}
        idName={card.id}
        isEditingFavourites={true}
        link={`/${portalIdPath}/${card.link}`}
        itemLink={card.link}
        subIcon={card.subIcon}
        setIsNavOpen={setIsNavOpen}
        setNavFavourites={setNavFavourites}
        navFavourites={navFavourites}
        demo={demo}
      />
    </li>
  );
});

export default DraggableNavListItem;
