import Text from '../../../Elements/Text';

const ChangeBillingDate = () => {
  return (
    <>
      <span style={{ textAlign: 'center' }}>
        <Text text='Send us an email with your preferred new invoice date ' />
        <a href='mailto:info@qaportal.com'>info@qaportal.com</a>
        <Text text='. ' />
      </span>

      <Text
        marginTop='10px'
        text="The email must be sent from the email associated with your subscription, or an email address belonging to a user with the 'Billing - Change' permission."
        textAlign='center'
      />
    </>
  );
};

export default ChangeBillingDate;
