import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { handleApiError } from '../../../utils/error-handling';
import { shouldNavigateOnNotFound } from '../../../utils/helpers';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import apiDataService from '../../../api/evaluations';
import FlexContainer from '../../Elements/FlexContainer';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import Panels from '../../Elements/Panels';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import Print from '../../Elements/Print';
import PrintableScorecard from '../../ScoringAndFeedback/PrintableScorecard';
import Scorecard from '../../ScoringAndFeedback/Scorecard';

const EvaluationsShow = ({
  demo,
  fromDrafts,
  fromEvaluationBenchmark,
  fromSubmittedEvaluations,
}) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { id, benchmarkId, evaluationId } = useParams();
  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { getAccessTokenSilently } = useAuth0();
  const alert = useAlert();
  const navigate = useNavigate();

  const [evaluation, setEvaluation] = useState();
  const [orgLevelAgentId, setOrgLevelAgentId] = useState();
  const [isEditingEvaluation, setIsEditingEvaluation] = useState();

  const getEvaluation = async () => {
    try {
      const params = {
        token: demo ? undefined : await getAccessTokenSilently(),
        userId: demo ? 1 : userId,
        portalId: demo ? 'demo' : portalId,
        evaluationId: fromSubmittedEvaluations
          ? evaluationId || benchmarkId
          : id,
      };

      if (fromDrafts) params.fromDrafts = true;
      if (fromSubmittedEvaluations) params.fromSubmittedEvaluations = true;

      const { evaluation, orgLevelAgentId } =
        await apiDataService.getEvaluationById(params);

      setEvaluation(evaluation);
      setOrgLevelAgentId(orgLevelAgentId);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      if (shouldNavigateOnNotFound(error)) {
        navigate(
          `/${demo ? 'demo' : portalId}/${
            fromDrafts
              ? 'drafts'
              : fromSubmittedEvaluations
              ? `calibrations/${id}`
              : 'evaluations'
          }`,
          {
            state: { isNavigating: true },
          }
        );
      }
    }
  };

  useEffect(() => {
    getEvaluation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, evaluationId]);

  const agent = fromDrafts
    ? null
    : evaluation?.evaluatedSections?.adminSecs.find(
        (sec) =>
          sec.dd_list_table === 'org_levels' &&
          sec.dd_list_id === orgLevelAgentId
      )?.data;

  const scorecardName = evaluation?.scorecard_name;

  useEffect(() => {
    const breadcrumbs = [
      ['Dashboard', 'dashboard', '', `/${demo ? 'demo' : portalId}/dashboard`],
    ];

    if (fromDrafts) {
      breadcrumbs.push(
        ['Drafts', 'draft', '', `/${demo ? 'demo' : portalId}/drafts`],
        [
          'Evaluations',
          'evaluations',
          '',
          `/${demo ? 'demo' : portalId}/evaluations`,
        ],
        ['New', 'plus']
      );
    } else if (fromSubmittedEvaluations && fromEvaluationBenchmark) {
      breadcrumbs.push(
        [
          'Calibrations',
          'bullseye',
          '',
          `/${demo ? 'demo' : portalId}/calibrations`,
        ],
        [
          scorecardName,
          'magnifying-glass',
          '',
          `/${demo ? 'demo' : portalId}/calibrations/${id}`,
        ],
        ['Evaluation', 'evaluations']
      );
    } else if (fromSubmittedEvaluations && !fromEvaluationBenchmark) {
      breadcrumbs.push(
        [
          'Calibrations',
          'bullseye',
          '',
          `/${demo ? 'demo' : portalId}/calibrations`,
        ],
        [
          scorecardName,
          'magnifying-glass',
          '',
          `/${demo ? 'demo' : portalId}/calibrations/${id}`,
        ],
        [evaluation?.created_by, 'users']
      );
    } else {
      breadcrumbs.push(
        [
          'Evaluations',
          'evaluations',
          '',
          `/${demo ? 'demo' : portalId}/evaluations`,
        ],
        [
          agent && scorecardName
            ? `${agent} - ${scorecardName}`
            : agent
            ? agent
            : scorecardName
            ? scorecardName
            : 'Evaluation',
          'magnifying-glass',
        ]
      );
    }

    setBreadcrumbs(breadcrumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent, scorecardName]);

  if (evaluation?.evaluatedSections) {
    return (
      <Panels>
        <PanelsHeaderPrimary
          iconName='evaluations'
          subIcon={
            fromDrafts ? 'plus' : fromSubmittedEvaluations ? 'bullseye' : ''
          }
          text={`${
            fromDrafts ? 'New ' : fromSubmittedEvaluations ? 'Calibration ' : ''
          }${evaluation.status === 'benchmark' ? 'Benchmark ' : ''}Evaluation${
            agent ? ` - ${agent}` : ''
          }${scorecardName ? ` - ${scorecardName}` : ''}${
            fromSubmittedEvaluations && !fromEvaluationBenchmark
              ? ` - ${evaluation?.created_by}`
              : ''
          }`}
        />

        <FlexContainer
          alignSelf='center'
          padding='0 20px 20px 20px'
          marginBottom='110px'
          flexDirection='column'
        >
          <Scorecard
            adminSections={evaluation.evaluatedSections.adminSecs}
            bragAmber={evaluation.originalScorecard.amber}
            bragBlack={evaluation.originalScorecard.black}
            bragGreen={evaluation.originalScorecard.green}
            bragRed={evaluation.originalScorecard.red}
            categoryOnlyList={evaluation.evaluatedSections.catOnlyList}
            demo={demo}
            evaluatedSections={evaluation.evaluatedSections}
            evaluation={evaluation}
            fromDrafts={fromDrafts}
            fromEvaluationBenchmark={fromEvaluationBenchmark}
            fromSubmittedEvaluations={fromSubmittedEvaluations}
            handlePrint={handlePrint}
            id={evaluation.originalScorecard.id}
            isEditingEvaluation={isEditingEvaluation || fromDrafts}
            isEvaluation
            logo={evaluation.originalScorecard.logo_url}
            orgName={evaluation.originalScorecard.org_name}
            passMark={evaluation.originalScorecard.pass_mark}
            scorecardName={evaluation.scorecard_name}
            scoringSections={evaluation.evaluatedSections.scoringSecs}
            sectionNeg={evaluation.originalScorecard.neg}
            sectionPos={evaluation.originalScorecard.pos}
            setIsEditingEvaluation={setIsEditingEvaluation}
            vcid={evaluation.originalScorecard.vcid}
          />
        </FlexContainer>

        <div style={{ display: 'none' }}>
          <Print
            content={
              <PrintableScorecard
                adminSections={evaluation.evaluatedSections.adminSecs}
                bragAmber={evaluation.originalScorecard.amber}
                bragBlack={evaluation.originalScorecard.black}
                bragGreen={evaluation.originalScorecard.green}
                bragRed={evaluation.originalScorecard.red}
                categoryOnlyList={evaluation.evaluatedSections.catOnlyList}
                demo={demo}
                evaluatedSections={evaluation.evaluatedSections}
                evaluation={evaluation}
                fromDrafts={fromDrafts}
                fromEvaluationBenchmark={fromEvaluationBenchmark}
                fromSubmittedEvaluations={fromSubmittedEvaluations}
                id={evaluation.originalScorecard.id}
                isEditingEvaluation={isEditingEvaluation || fromDrafts}
                isEvaluation
                logo={evaluation.originalScorecard.logo_url}
                orgName={evaluation.originalScorecard.org_name}
                passMark={evaluation.originalScorecard.pass_mark}
                scorecardName={evaluation.scorecard_name}
                scoringSections={evaluation.evaluatedSections.scoringSecs}
                sectionNeg={evaluation.originalScorecard.neg}
                sectionPos={evaluation.originalScorecard.pos}
                setIsEditingEvaluation={setIsEditingEvaluation}
                vcid={evaluation.originalScorecard.vcid}
              />
            }
            ref={componentRef}
          />
        </div>
      </Panels>
    );
  } else return <LoadingSpinner />;
};

export default EvaluationsShow;
