import ButtonIconOnly from '../../../Elements/ButtonIconOnly';
import FlexContainer from '../../../Elements/FlexContainer';
import HeaderSecondary from '../../../Elements/HeaderSecondary';
import Icon from '../../../Elements/Icon';

import './GridOption.css';

const GridOption = ({
  disabled,
  headerText,
  iconName,
  showGridOption,
  setShowGridOption,
  gridOption,
  setManageOptions,
  title = '',
}) => {
  return (
    <FlexContainer
      classes={`grid-option-container ${
        disabled ? 'grid-option-disabled' : ''
      }`}
      margin='5px'
    >
      {iconName ? (
        <Icon
          className='grid-option-icon'
          iconName={iconName}
          style={{ height: '14px', width: '14px' }}
        />
      ) : (
        <></>
      )}

      <HeaderSecondary
        classes='grid-option-header'
        textAlign='center'
        text={headerText}
      />

      <ButtonIconOnly
        classes={showGridOption === gridOption ? 'showing-grid-option' : ''}
        disabled={disabled}
        onClick={() => {
          if (setManageOptions) setManageOptions(false);
          if (setShowGridOption)
            setShowGridOption((curr) =>
              curr === gridOption ? '' : gridOption
            );
        }}
        iconName={showGridOption === gridOption ? 'arrows-up' : 'arrows-down'}
        title={title}
        typeStyle='type-1'
        marginLeft={headerText ? '5px' : '0'}
      />
    </FlexContainer>
  );
};

export default GridOption;
