import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { UserPortal } from '../../contexts';

import Account from '../Portal/Account/Account';
import AuthenticationButton from '../Authentication/AuthenticationButton';
import Breadcrumb from './Breadcrumb';
import FlexContainer from './FlexContainer';
import Hamburger from 'hamburger-react';
import HamburgerNav from '../Nav/HamburgerNav';
import HomeNav from '../Nav/HomeNav';
import Icon from './Icon';
import LoadingSpinnerNew from './LoadingSpinnerNew';
import UserBreadcrumb from './UserBreadcrumb';

import './Breadcrumbs.css';
import '../Nav/HomeNav.css';

const Breadcrumbs = ({
  breadcrumbs,
  demo,
  isHomeScreen,
  isLoadingUserFromDb,
  isNavOpen,
  isRespondingToInvite,
  isUpdatingLastLogin,
  setIsNavOpen,
  setIsRespondingToInvite,
}) => {
  const { isAuthenticated, isLoading } = useAuth0();

  const [showAccount, setShowAccount] = useState(false);
  const [showHamburgerNav, setShowHamburgerNav] = useState(false);

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isPromptVisible, setIsPromptVisible] = useState(false);

  const { userFromDb } = useContext(UserPortal);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        setDeferredPrompt(null);
        setIsPromptVisible(false);
      });
    }
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsPromptVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, []);

  useEffect(() => {
    if (showAccount) setShowHamburgerNav(false);
  }, [showAccount]);

  useEffect(() => {
    if (showHamburgerNav) setShowAccount(false);
  }, [showHamburgerNav]);

  return (
    <>
      <div
        style={{
          left: '0',
          width: '100%',
          height: '50px',
        }}
        className={`breadcrumbs ${
          !isHomeScreen ? 'portal-breadcrumbs' : 'home-screen-breadcrumbs'
        } ${isAuthenticated ? 'authenticated-breadcrumbs' : ''}`}
      >
        <Link className='home-button-container' to='/'>
          <img
            className='home-button'
            src={
              demo ||
              (isHomeScreen && !isAuthenticated) ||
              !userFromDb?.logo_url
                ? 'https://i.ibb.co/bb7szFx/logo3.png'
                : userFromDb.logo_url
            }
            alt=''
            title='Home'
          />
        </Link>

        {isHomeScreen ? (
          <HomeNav
            handleInstallClick={handleInstallClick}
            isPromptVisible={isPromptVisible}
            isNavOpen={isNavOpen}
            setIsNavOpen={setIsNavOpen}
            userFromDb={userFromDb}
          />
        ) : (
          <>
            <button
              className={`nav-expander ${
                isNavOpen ? 'nav-expander-nav-open' : ''
              } ignore-detect-click-outside `}
              onClick={() => setIsNavOpen((curr) => !curr)}
            >
              <div className='nav-expander-icon'>
                <Icon
                  className='ignore-detect-click-outside'
                  iconName='navigation'
                />
              </div>
            </button>

            <FlexContainer
              classes='breadcrumbs-container'
              justifyContent='flex-start'
            >
              {breadcrumbs?.map(
                ([name, icon, subIcon, link, cbFunc], index, arr) => (
                  <Breadcrumb
                    key={index}
                    name={name}
                    icon={icon}
                    subIcon={subIcon}
                    link={link}
                    cbFunc={cbFunc}
                    lastBreadcrumb={index === arr.length - 1}
                  />
                )
              )}
            </FlexContainer>
          </>
        )}

        {!demo && !isAuthenticated ? (
          <></>
        ) : (
          <div className='user-breadcrumb'>
            {demo ? (
              <div className='demo-label-container'>
                <Link
                  className='nav-favourite-cross cancel-demo-cross'
                  title='Exit demo'
                  to='/'
                >
                  <Icon iconName='circle-cross' />
                </Link>

                <label
                  className={`in-demo-label ${
                    isAuthenticated ? 'in-demo-label-is-authd' : ''
                  }`}
                >
                  Demo
                </label>
              </div>
            ) : (
              <></>
            )}

            {isLoading || isLoadingUserFromDb || isUpdatingLastLogin ? (
              <div>
                <LoadingSpinnerNew />
              </div>
            ) : isAuthenticated ? (
              <UserBreadcrumb
                demo={demo}
                handleClickUserAccount={() => setShowAccount((curr) => !curr)}
                notificationCount={
                  (userFromDb?.portal_invites?.length ? 1 : 0) +
                  (!userFromDb?.id ? 1 : 0)
                }
                showAccount={showAccount}
                userFromDb={userFromDb}
              />
            ) : (
              <div className='home-nav-buttons in-portal-nav-buttons'>
                <div className='home-nav-special-buttons'>
                  <AuthenticationButton classes='login-button' />
                </div>
              </div>
            )}
          </div>
        )}

        {isHomeScreen ? (
          <Hamburger toggled={showHamburgerNav} toggle={setShowHamburgerNav} />
        ) : (
          <></>
        )}
      </div>

      {showAccount ? (
        <Account
          clickedOutside={(event) => {
            if (
              event?.target?.parentElement?.classList?.value?.includes(
                'ignore-account-detect-click-outside'
              ) ||
              event?.target?.classList?.value?.includes(
                'ignore-account-detect-click-outside'
              ) ||
              event?.target?.parentElement?.classList?.value?.includes(
                'react-alert-class'
              ) ||
              event?.target?.classList?.value?.includes('react-alert-class') ||
              event?.target?.parentElement?.classList?.value?.includes(
                'react-confirm-alert'
              ) ||
              event?.target?.classList?.value?.includes('react-confirm-alert')
            )
              return;

            setShowAccount(false);
          }}
          isRespondingToInvite={isRespondingToInvite}
          setIsRespondingToInvite={setIsRespondingToInvite}
          setShowAccount={setShowAccount}
        />
      ) : (
        <></>
      )}

      {showHamburgerNav ? (
        <HamburgerNav
          clickedOutside={(event) => {
            if (
              event?.target?.parentElement?.classList?.value?.includes(
                'ignore-hamburger-nav-detect-click-outside'
              ) ||
              event?.target?.classList?.value?.includes(
                'ignore-hamburger-nav-detect-click-outside'
              )
            )
              return;

            setShowHamburgerNav(false);
          }}
          handleInstallClick={handleInstallClick}
          isPromptVisible={isPromptVisible}
          setShowHamburgerNav={setShowHamburgerNav}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Breadcrumbs;
