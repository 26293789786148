import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { calculateTrendLine, getMax } from '../../../utils/reports';
import { doArrayValuesMatchAnyOrder } from '../../../utils/helpers';
import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiDataServiceReports from '../../../api/reports';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import ButtonToggle from '../../Elements/ButtonToggle';
import FlexContainer from '../../Elements/FlexContainer';
import GeneralLineChart from './GeneralLineChart';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InfoText from '../../Elements/InfoText';
import InputStandard from '../../Elements/InputStandard';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import MainOnly from '../MainAndOptions/MainOnly';
import moment from 'moment';
import Panels from '../../Elements/Panels';
import PanelsControlBar from '../../Elements/PanelsControlBar';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import SelectStandard from '../../Elements/SelectStandard';
import Text from '../../Elements/Text';
import VarianceResultChart from '../Calibrations/VarianceResultChart';
import VarianceResultLists from '../Calibrations/VarianceResultLists';

import './ReportsCalibrations.css';

const ReportsCalibrations = ({ demo }) => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);

  const [isLoading, setIsLoading] = useState(true);
  const [reportParamsChanged, setReportParamsChanged] = useState(false);
  const [reportParamsInvalid, setReportParamsInvalid] = useState(false);

  const [trendLine, setTrendLine] = useState('Overall');
  const [maxValue, setMaxValue] = useState(0);
  const [lineKeys, setLineKeys] = useState(['Overall', 'Quality Score']);

  // from server
  const [count, setCount] = useState(0);
  const [lineData, setLineData] = useState();
  const [scorecards, setScorecards] = useState([]);
  const [overall, setOverall] = useState(0);
  const [qs, setQs] = useState(0);
  const [pf, setPf] = useState(0);
  const [secsAdmin, setSecsAdmin] = useState([]);
  const [secsScoring, setSecsScoring] = useState([]);
  const [secsCat, setSecsCat] = useState([]);
  const [usersScoring, setUsersScoring] = useState([]);
  const [usersQs, setUsersQs] = useState([]);
  const [usersPf, setUsersPf] = useState([]);
  /////////////////////////////

  const [selectedScorecards, setSelectedScorecards] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().subtract(6, 'months').add(1, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const lineColoursLookup = {
    Overall: '#000000',
    'Quality Score': '#0F0FFC',
    'Pass / Fail': '#fc0fc0',
  };

  const handleChangeScorecard = (scorecardVcid) => {
    setSelectedScorecards((curr) => {
      const copiedCurr = [...curr];

      if (!scorecardVcid) {
        if (
          doArrayValuesMatchAnyOrder(
            copiedCurr,
            scorecards.map(({ scorecard_vcid }) => scorecard_vcid)
          )
        ) {
          return [];
        } else {
          return scorecards.map(({ scorecard_vcid }) => scorecard_vcid);
        }
      } else {
        const vcidIndex = copiedCurr.indexOf(scorecardVcid);

        if (vcidIndex !== -1) {
          copiedCurr.splice(vcidIndex, 1);

          return copiedCurr;
        } else {
          copiedCurr.push(scorecardVcid);

          return copiedCurr;
        }
      }
    });

    setReportParamsChanged(true);
  };

  const getReportData = async () => {
    try {
      setIsLoading(true);

      const params = [
        `scorecard_vcids=${encodeURIComponent(selectedScorecards)}`,
        `start_date=${encodeURIComponent(startDate)}`,
        `end_date=${encodeURIComponent(endDate)}`,
      ];

      const {
        count,
        lineData,
        overall,
        pf,
        qs,
        scorecards,
        secsAdmin,
        secsCat,
        secsScoring,
        usersPf,
        usersQs,
        usersScoring,
      } = await apiDataServiceReports.getCalibrations({
        params,
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setCount(count);
      setLineData(lineData);
      setOverall(overall);
      setPf(pf);
      setQs(qs);
      setScorecards(scorecards);
      setSecsAdmin(secsAdmin);
      setSecsCat(secsCat);
      setSecsScoring(secsScoring);
      setUsersPf(usersPf);
      setUsersQs(usersQs);
      setUsersScoring(usersScoring);

      const newLineKeys = ['Overall', 'Quality Score'];

      if (lineData.some((item) => item['Pass / Fail']))
        newLineKeys.push('Pass / Fail');

      setMaxValue(getMax(lineData, newLineKeys));
      setLineKeys(newLineKeys);

      if (!selectedScorecards.length)
        setSelectedScorecards(
          scorecards.map(({ scorecard_vcid }) => scorecard_vcid)
        );

      setReportParamsChanged(false);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setReportParamsInvalid(
      moment(startDate).format('YYYY-MM-DD') === 'Invalid date' ||
        moment(endDate).format('YYYY-MM-DD') === 'Invalid date'
    );
  }, [startDate, endDate]);

  useEffect(() => {
    if (!demo && !userFromDb.permissions?.includes('reports.calibrations')) {
      navigate(`/${demo ? 'demo' : portalId}/reports`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      setBreadcrumbs([
        [
          'Dashboard',
          'dashboard',
          '',
          `/${demo ? 'demo' : portalId}/dashboard`,
        ],
        ['Reports', 'chart-pie', '', `/${demo ? 'demo' : portalId}/reports`],
        ['Calibrations', 'magnifying-glass'],
      ]);

      getReportData();
    }
    return () => {
      setCount();
      setLineData();
      setOverall();
      setPf();
      setQs();
      setScorecards();
      setSecsAdmin();
      setSecsCat();
      setSecsScoring();
      setUsersPf();
      setUsersQs();
      setUsersScoring();
      setMaxValue();
      setLineKeys();
      setSelectedScorecards();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainOnly>
      <Panels>
        <PanelsHeaderPrimary
          iconName='chart-pie'
          subIcon='bullseye'
          text='Calibrations'
        />

        <PanelsControlBar>
          <ButtonStandardNoFill
            classes='grid-control-bar-button'
            disabled={reportParamsInvalid || isLoading}
            iconName='chart-pie'
            onClick={getReportData}
            text='Re-generate Report'
          />
        </PanelsControlBar>

        <PanelsSection margin='0 30px 20px'>
          <Text
            text='View calibration results between selected dates'
            textAlign='center'
          />
        </PanelsSection>

        <FlexContainer flexWrap='wrap' margin='0 20px 25px'>
          <InputStandard
            classes={startDate ? 'focus-selected-param-date' : ''}
            direction='vertical'
            disabled={isLoading}
            id='qa-date-select-from'
            labelText='From'
            margin='10px 5px 0'
            onChange={(event) => {
              setStartDate(event.target.value);

              setReportParamsChanged(true);
            }}
            type='date'
            value={startDate || ''}
          />

          <InputStandard
            classes={endDate ? 'focus-selected-param-date' : ''}
            direction='vertical'
            disabled={isLoading}
            id='qa-date-select-to'
            labelText='To'
            margin='10px 5px 0'
            onChange={(event) => {
              setEndDate(event.target.value);

              setReportParamsChanged(true);
            }}
            type='date'
            value={endDate || ''}
          />
        </FlexContainer>

        <HeaderSecondary textAlign='center' text='Scorecards' />

        <FlexContainer flexWrap='wrap' margin='10px 30px 15px 15px'>
          <ButtonToggle
            margin='0 0 15px 15px'
            onClick={() => handleChangeScorecard()}
            text='All'
            toggled={doArrayValuesMatchAnyOrder(
              selectedScorecards,
              scorecards.map(({ scorecard_vcid }) => scorecard_vcid)
            )}
          />
          {scorecards.map(({ scorecard_name, scorecard_vcid }) => (
            <ButtonToggle
              key={scorecard_vcid}
              margin='0 0 15px 15px'
              onClick={() => handleChangeScorecard(scorecard_vcid)}
              text={scorecard_name}
              toggled={selectedScorecards.includes(scorecard_vcid)}
            />
          ))}
        </FlexContainer>

        {isLoading ? (
          <LoadingSpinnerNew />
        ) : reportParamsInvalid ? (
          <PanelsSection margin='0 30px 600px'>
            <Text text='Report parameters are invalid' textAlign='center' />
          </PanelsSection>
        ) : reportParamsChanged ? (
          <PanelsSection margin='0 30px 600px'>
            <Text
              text="Report parameters have changed, click the 'Re-generate Report' button"
              textAlign='center'
            />
          </PanelsSection>
        ) : (
          <>
            <PanelsSection alignSelf='center' margin='0 30px 30px'>
              <HeaderSecondary
                textAlign='center'
                text='Calibrations Completed'
              />

              <InfoText text={count} />
            </PanelsSection>

            <PanelsSection margin='0 30px 30px'>
              <HeaderSecondary
                marginBottom='15px'
                text='Variance'
                textAlign='center'
                textDecoration='underline'
              />

              <FlexContainer flexWrap='wrap'>
                {(overall || overall === 0) && (
                  <VarianceResultChart score={overall} text='Overall' />
                )}

                {(qs || qs === 0) && (
                  <VarianceResultChart score={qs} text='Quality Score' />
                )}

                {(pf || pf === 0) && (
                  <VarianceResultChart score={pf} text='Pass / Fail' />
                )}
              </FlexContainer>

              <SelectStandard
                containerClasses='calibrations-report-trend-line-select'
                labelText='Trend Line'
                onChange={(event) => setTrendLine(event.target.value)}
                options={['None', ...lineKeys]}
                value={trendLine}
              />

              <GeneralLineChart
                classes='qa-report-line-chart'
                data={lineData}
                extraTooltipHeaders={({ count }) =>
                  `(${count} Calibration${parseInt(count) === 1 ? '' : 's'})`
                }
                lineColoursLookup={lineColoursLookup}
                lineDataKeys={lineKeys}
                minMax={[0, maxValue]}
                trendLine={calculateTrendLine(lineData, trendLine, [
                  0,
                  trendLine === 'None' ? 0 : maxValue,
                ])}
                xDataKey='Date'
                yLabel='Variance'
                yLabelOffsets={[-90, 30]}
              />

              <InfoText className='chart-axis-label' text='Calibration Date' />
            </PanelsSection>

            <InfoText
              style={{ margin: '0 30px 30px', padding: '0' }}
              text="'Overall' score is calculated using the scoring section variances from the calibration evaluations"
            />

            <PanelsSection
              classes='calibration-report-results-list'
              margin='0 30px 30px'
            >
              <VarianceResultLists
                calibrationResults={{ secsAdmin, secsScoring, secsCat }}
                fromCalibrationReport
              />
            </PanelsSection>

            <PanelsSection
              classes='calibration-report-results-list'
              margin='0 30px 30px'
            >
              <VarianceResultLists
                calibrationResults={{ usersScoring, usersQs, usersPf }}
                fromCalibrationReport
              />
            </PanelsSection>
          </>
        )}
      </Panels>
    </MainOnly>
  );
};

export default ReportsCalibrations;
