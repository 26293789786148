import { extractColumnFromBarId } from '../../../utils/reports';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useState } from 'react';

import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import FlexContainer from '../../Elements/FlexContainer';
import InputStandard from '../../Elements/InputStandard';
import SelectStandard from '../../Elements/SelectStandard';
import Text from '../../Elements/Text';
import Toggle from '../../Elements/Toggle';

import './BarChartData.css';

const BarChartData = ({
  columnOrder,
  customColumnHeaders,
  hasGroupingOrAggregate,
  handleUpdateChartProp,
  uniqueRef,
  chartData,
  handleUpdateChartValues,
  handleUpdateAndReorderChartValues,
  type,
}) => {
  const [barId, setBarId] = useState('');
  const [paletteOpen, setPaletteOpen] = useState(false);

  const columnOptions = columnOrder.length
    ? columnOrder.map((column, index) => {
        return {
          code: column.id,
          name: customColumnHeaders[column.id] || column.value,
        };
      })
    : [{ code: null, name: '< No report data >' }];

  const detectClickOutsideRef = useDetectClickOutside({
    onTriggered: (event) => {
      if (
        event?.target?.parentElement?.classList?.value?.includes(
          'ignore-detect-click-outside'
        ) ||
        event?.target?.classList?.value?.includes('ignore-detect-click-outside')
      )
        return;

      setPaletteOpen(false);
    },
  });

  return (
    <div className='bar-chart-data'>
      <FlexContainer
        flexWrap={
          chartData.type === 'pie' || chartData.type === 'needle'
            ? 'wrap'
            : 'nowrap'
        }
      >
        <SelectStandard
          containerClasses='bar-chart-data-bars'
          id={`${uniqueRef}-bar-chart-data-bars`}
          labelText={
            type === 'line'
              ? 'Lines'
              : type === 'area'
              ? 'Areas'
              : type === 'scatter'
              ? 'Dots'
              : type === 'pie'
              ? 'Values'
              : type === 'needle'
              ? 'Data'
              : 'Bars'
          }
          onChange={(event) => {
            setBarId(event.target.value);

            handleUpdateChartProp(uniqueRef, 'pieValue', event.target.value);
          }}
          options={[
            { code: '', name: '< Select report data >' },
            ...columnOptions,
          ]}
          value={
            type === 'pie' || type === 'needle' ? chartData.pieValue : barId
          }
        />

        {chartData.type === 'pie' || chartData.type === 'needle' ? (
          <Toggle
            classes='show-x-axis-label-toggle'
            margin='0 0 0 5px'
            onChange={() =>
              handleUpdateChartProp(
                uniqueRef,
                'showPieValues',
                !chartData.showPieValues
              )
            }
            title={`Show ${
              chartData.type === 'needle' ? 'Data Label' : 'Values'
            }`}
            toggled={chartData.showPieValues}
          />
        ) : (
          <ButtonIconOnly
            alignSelf='flex-end'
            disabled={!barId || chartData.values?.includes(barId)}
            iconName='plus'
            marginBottom='5px'
            marginLeft='5px'
            onClick={() => {
              handleUpdateAndReorderChartValues(uniqueRef, [
                ...chartData.values,
                barId,
              ]);
            }}
            title={
              type === 'line'
                ? 'Add line'
                : type === 'area'
                ? 'Add Area'
                : type === 'scatter'
                ? 'Add Dot'
                : 'Add bar'
            }
          />
        )}
      </FlexContainer>

      {chartData.type === 'needle' && (
        <FlexContainer flexDirection='column'>
          <FlexContainer marginTop='5px' flexWrap='wrap'>
            <InputStandard
              inputWidth='60px'
              labelText='Red'
              margin='5px'
              onChange={(event) =>
                handleUpdateChartProp(
                  uniqueRef,
                  'needleRed',
                  event.target.value
                )
              }
              placeholder='Red'
              type='number'
              value={chartData.needleRed}
            />

            <InputStandard
              inputWidth='60px'
              labelText='Amber'
              margin='5px'
              onChange={(event) =>
                handleUpdateChartProp(
                  uniqueRef,
                  'needleAmber',
                  event.target.value
                )
              }
              placeholder='Amber'
              type='number'
              value={chartData.needleAmber}
            />

            <InputStandard
              inputWidth='60px'
              labelText='Green'
              margin='5px'
              onChange={(event) =>
                handleUpdateChartProp(
                  uniqueRef,
                  'needleGreen',
                  event.target.value
                )
              }
              placeholder='Green'
              type='number'
              value={chartData.needleGreen}
            />
          </FlexContainer>

          <FlexContainer>
            <InputStandard
              inputWidth='60px'
              labelText='Min'
              margin='5px'
              onChange={(event) =>
                handleUpdateChartProp(
                  uniqueRef,
                  'needleMin',
                  event.target.value
                )
              }
              placeholder='Min'
              type='number'
              value={chartData.needleMin}
            />

            <InputStandard
              inputWidth='60px'
              labelText='Max'
              margin='5px'
              onChange={(event) =>
                handleUpdateChartProp(
                  uniqueRef,
                  'needleMax',
                  event.target.value
                )
              }
              placeholder='Max'
              type='number'
              value={chartData.needleMax}
            />
          </FlexContainer>
        </FlexContainer>
      )}

      {chartData.type !== 'pie' &&
        chartData.type !== 'needle' &&
        chartData.values?.map((barId) => {
          return (
            <FlexContainer key={barId} margin='5px' position='relative'>
              <Text
                textAlign='center'
                text={
                  customColumnHeaders[barId] ||
                  extractColumnFromBarId(barId, hasGroupingOrAggregate)
                }
              />

              <ButtonIconOnly
                backgroundColor={
                  chartData.colours[barId]
                    ? `${chartData.colours[barId]}`
                    : '#978ba6'
                }
                classes='ignore-detect-click-outside'
                iconClasses='ignore-detect-click-outside'
                // iconName='palette'
                marginLeft='10px'
                onClick={() => {
                  setPaletteOpen((curr) => {
                    if (!curr) return barId;
                    else return false;
                  });
                }}
              />

              <ButtonIconOnly
                classes='report-add-chart-minus-button'
                iconName='minus'
                marginLeft='10px'
                onClick={() => {
                  handleUpdateChartValues(uniqueRef, barId);
                }}
                typeStyle='type-2'
              />

              {paletteOpen === barId && (
                <div
                  className='floating-colour-picker'
                  ref={detectClickOutsideRef}
                >
                  <HexColorPicker
                    color={chartData.colours[barId] || '#978ba6'}
                    onChange={(val) => {
                      handleUpdateChartProp(uniqueRef, 'colours', val, barId);
                    }}
                  />
                  <HexColorInput
                    color={chartData.colours[barId] || '#978ba6'}
                    onChange={(val) => {
                      handleUpdateChartProp(uniqueRef, 'colours', val, barId);
                    }}
                  />
                </div>
              )}
            </FlexContainer>
          );
        })}

      {chartData.type !== 'needle' && (
        <FlexContainer flexWrap='wrap'>
          <SelectStandard
            id={`${uniqueRef}-bar-chart-data-x-axis`}
            labelText={chartData.type === 'pie' ? 'Labels' : 'X Axis'}
            onChange={(event) =>
              handleUpdateChartProp(uniqueRef, 'xAxis', event.target.value)
            }
            options={[
              { code: '', name: '< Select report data >' },
              ...columnOptions,
            ]}
            value={chartData.xAxis}
          />

          <Toggle
            classes='show-x-axis-label-toggle'
            margin='0 0 0 5px'
            onChange={() =>
              handleUpdateChartProp(
                uniqueRef,
                'showXAxisLabel',
                !chartData.showXAxisLabel
              )
            }
            title={
              chartData.type === 'pie' ? 'Show Labels' : 'Show X Axis Title'
            }
            toggled={chartData.showXAxisLabel}
          />
        </FlexContainer>
      )}
    </div>
  );
};

export default BarChartData;
