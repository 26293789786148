import { calculateTrendLine } from '../../../utils/reports';
import { useState } from 'react';

import ButtonToggle from '../../Elements/ButtonToggle';
import FlexContainer from '../../Elements/FlexContainer';
import GaugeChart from 'react-gauge-chart';
import GeneralLineChart from '../Reports/GeneralLineChart';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InfoText from '../../Elements/InfoText';
import moment from 'moment';
import PanelsSection from '../../Elements/PanelsSection';
import Text from '../../Elements/Text';

const AgentScores = ({ qualityScores }) => {
  const [range, setRange] = useState('month');

  const values = qualityScores[range]?.map((item) => item['Quality Score']);

  return (
    <PanelsSection
      alignItems='stretch'
      classes='agent-scores-panel'
      noMaxWidth
      typeStyle='type-2'
    >
      <HeaderSecondary
        marginBottom='15px'
        text='Quality Scores'
        textAlign='center'
      />

      <FlexContainer alignItems='stretch' flexDirection='column'>
        <FlexContainer flexWrap='wrap'>
          <ButtonToggle
            margin='5px'
            onClick={() => setRange('month')}
            text='1 Month'
            toggled={range === 'month'}
          />

          <ButtonToggle
            margin='5px'
            onClick={() => setRange('sixMonth')}
            text='6 Months'
            toggled={range === 'sixMonth'}
          />

          <ButtonToggle
            margin='5px'
            onClick={() => setRange('year')}
            text='1 Year'
            toggled={range === 'year'}
          />
        </FlexContainer>

        <span style={{ marginTop: '15px', textAlign: 'center' }}>
          <Text
            fontWeight='400'
            marginRight='5px'
            text={
              range === 'month'
                ? moment()
                    .subtract(1, 'months')
                    .add(1, 'days')
                    .format('Do MMMM YYYY')
                : range === 'sixMonth'
                ? moment()
                    .subtract(6, 'months')
                    .add(1, 'days')
                    .format('Do MMMM YYYY')
                : moment()
                    .subtract(1, 'years')
                    .add(1, 'days')
                    .format('Do MMMM YYYY')
            }
            textAlign='center'
          />

          <Text marginRight='5px' text='to' textAlign='center' />

          <Text
            fontWeight='400'
            text={`${moment().format('Do MMMM YYYY')}`}
            textAlign='center'
          />
        </span>

        {qualityScores[range]?.length ? (
          <>
            <FlexContainer flexDirection='column' marginTop='20px'>
              <HeaderSecondary
                marginBottom='5px'
                text='Average Quality Score'
                textAlign='center'
              />

              <GaugeChart
                animate={false}
                id={`gauge-chart-qs-agent`}
                colors={['#c82728', '#169928']}
                arcWidth={0.25}
                arcPadding={0.02}
                cornerRadius={10}
                arcsLength={[
                  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                ]}
                percent={qualityScores[range][0].average_quality_score / 100}
                needleColor='#978aa670'
                needleBaseColor='#978aa6bf'
                textColor='#333333'
                formatTextValue={() =>
                  qualityScores[range][0].average_quality_score
                }
              />
            </FlexContainer>

            <HeaderSecondary
              marginTop='15px'
              marginBottom='10px'
              text='Score Progression'
              textAlign='center'
            />

            <GeneralLineChart
              classes='agents-show-line-chart'
              data={qualityScores[range]}
              hideLegend
              lineDataKeys={['Quality Score']}
              trendLine={calculateTrendLine(
                qualityScores[range],
                'Quality Score',
                [0, Math.max(...values)]
              )}
              xDataKey='Created'
              yLabel='Quality Score'
            />

            <InfoText
              classes='chart-axis-label'
              text={`${qualityScores[range].length} Evaluations`}
            />
          </>
        ) : (
          <Text margin='15px 0' text='No data' textAlign='center' />
        )}
      </FlexContainer>
    </PanelsSection>
  );
};

export default AgentScores;
