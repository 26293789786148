import './_CreateScoringSections.css';

import { useState } from 'react';

import CreateScoringSectionForm from './CreateScoringSectionForm';
import ScoringSectionsContainer from './ScoringSectionsContainer';

const CreateScoringSections = ({
  edgeMargins,
  hasChangePermission,
  scoringSections,
  setScoringSections,
  categoryCount,
  setCategoryCount,
  sectionCount,
  setSectionCount,
  currentlyEditingCat,
  setCurrentlyEditingCat,
  currentlyEditingSec,
  setCurrentlyEditingSec,
  handleSetHasChanges,
}) => {
  const [categoryName, setCategoryName] = useState('');
  const [sectionName, setSectionName] = useState('');
  const [sectionDescription, setSectionDescription] = useState('');
  const [weight, setWeight] = useState('');
  const [ra, setRa] = useState('');
  const [csm, setCsm] = useState('');
  const [isRequired, setIsRequired] = useState(true);
  const [shouldScrollToBtm, setShouldScrollToBtm] = useState(false);

  return (
    <div className='create-scorecard-sections-area'>
      <div className='create-scorecard-scoring-form-and-sections'>
        {hasChangePermission ? (
          <CreateScoringSectionForm
            edgeMargins={edgeMargins}
            scoringSections={scoringSections}
            setScoringSections={setScoringSections}
            categoryName={categoryName}
            setCategoryName={setCategoryName}
            sectionName={sectionName}
            setSectionName={setSectionName}
            sectionDescription={sectionDescription}
            setSectionDescription={setSectionDescription}
            weight={weight}
            setWeight={setWeight}
            ra={ra}
            setRa={setRa}
            csm={csm}
            setCsm={setCsm}
            isRequired={isRequired}
            setIsRequired={setIsRequired}
            currentlyEditingCat={currentlyEditingCat}
            setCurrentlyEditingCat={setCurrentlyEditingCat}
            currentlyEditingSec={currentlyEditingSec}
            setCurrentlyEditingSec={setCurrentlyEditingSec}
            sectionCount={sectionCount}
            setSectionCount={setSectionCount}
            categoryCount={categoryCount}
            setCategoryCount={setCategoryCount}
            setShouldScrollToBtm={setShouldScrollToBtm}
            handleSetHasChanges={handleSetHasChanges}
          />
        ) : (
          <></>
        )}

        {scoringSections.length > 0 && (
          <ScoringSectionsContainer
            edgeMargins={edgeMargins}
            cards={scoringSections}
            categoryCount={categoryCount}
            hasChangePermission={hasChangePermission}
            sectionCount={sectionCount}
            setCards={setScoringSections}
            setCategoryName={setCategoryName}
            setSectionName={setSectionName}
            setSectionDescription={setSectionDescription}
            setWeight={setWeight}
            setRa={setRa}
            setCsm={setCsm}
            setIsRequired={setIsRequired}
            currentlyEditingCat={currentlyEditingCat}
            setCurrentlyEditingCat={setCurrentlyEditingCat}
            currentlyEditingSec={currentlyEditingSec}
            setCurrentlyEditingSec={setCurrentlyEditingSec}
            setCategoryCount={setCategoryCount}
            setSectionCount={setSectionCount}
            shouldScrollToBtm={shouldScrollToBtm}
            setShouldScrollToBtm={setShouldScrollToBtm}
            handleSetHasChanges={handleSetHasChanges}
          />
        )}
      </div>
    </div>
  );
};

export default CreateScoringSections;
