import tickSmall from '../../images/tick-small.png';

import './About.css';

const ReportsFeatures = () => {
  return (
    <ul className='about-screen-list'>
      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Built-in reports</p>

        <ul className='about-screen-list about-screen-list-nested'>
          <li>
            <p>
              Hot Topics - Track selected sections from each scorecard over a 12
              week period
            </p>
          </li>

          <li>
            <p>
              Focus - Allows for an in-depth analysis of the results from
              scorecard sections and categories
            </p>
          </li>

          <li>
            <p>
              Risk - We calculate the risk level associated with all of your
              scorecard sections and rank them
            </p>
          </li>

          <li>
            <p>
              QA - Monitor evaluation volumes by scorecard and QA staff, as well
              as feedback volumes and trends
            </p>
          </li>

          <li>
            <p>
              Calibrations - Analyse QA variances and trends across scorecards,
              with the ability to drill down on individual section and category
              variances.
            </p>
          </li>
        </ul>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Build custom flexible reports</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Report on any of the parameters you have created within your
          scorecards, and filter, aggregate, group, and sort the data however
          you please
        </p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Add charts to you reports</p>

        <ul className='about-screen-list about-screen-list-nested'>
          <li>
            <p>Bar / Line / Pie / Needle / Area / Scatter</p>
          </li>
        </ul>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Customise the features and look of your charts</p>

        <ul className='about-screen-list about-screen-list-nested'>
          <li>
            <p>Legend / Labels / Size / Y axis label / X axis label</p>
          </li>

          <li>
            <p>Select the colours of bars / lines / areas / scatter points</p>
          </li>

          <li>
            <p>Choose the parameters and RAG boundaries of needle charts</p>
          </li>
        </ul>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Most report charts display additional data when hovered over</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Set / restrict access to your reports for other portal users</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Choose custom column names (and change the column order) of your
          report results
        </p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Give your reports a name and description</p>
      </li>
    </ul>
  );
};

export default ReportsFeatures;
