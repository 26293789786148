import { Outlet, useOutletContext } from 'react-router-dom';

const EvaluationsScorecardWrapper = ({
  demo,
  handleSelectScorecard,
  id,
  selectedScorecardId,
  widthOffsets,
}) => {
  const { isNavOpen } = useOutletContext();

  return (
    <Outlet
      context={{
        demo,
        handleSelectScorecard,
        id,
        isNavOpen,
        selectedScorecardId,
        widthOffsets,
      }}
    />
  );
};

export default EvaluationsScorecardWrapper;
