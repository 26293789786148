import { ThreeDots } from 'react-loader-spinner';

import './LoadingSpinner.css';

const LoadingSpinner = () => {
  return (
    <ThreeDots
      visible={true}
      height='80'
      width='80'
      color='#4c3b4d'
      radius='9'
      ariaLabel='three-dots-loading'
      wrapperStyle={{}}
      wrapperClass='loading-spinner'
    />
  );
};

export default LoadingSpinner;
