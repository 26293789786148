import {
  scorecardsRequiredFields,
  scorecardsValidationColumns,
  scorecardTemplatesAvailableColumns,
  scorecardTemplatesDefaultColumns,
  scorecardTemplatesUnEditableColumns,
} from '../columns';

import apiDataService from '../../../api/scorecard-templates';
import DataGrid from '../DataGrid/DataGrid';

const ScorecardTemplatesAll = ({ demo }) => {
  const getAvailableColumns = (columns = {}) => {
    return { ...scorecardTemplatesAvailableColumns, ...columns };
  };

  const defaultOptions = {
    customColumns: null,
    limit: 10,
    page: 1,
    query: null,
    sortBy: null,
    view: 'grid',
  };

  // default page / limit / view / sort

  return (
    <DataGrid
      altApiKey='portalTemplates'
      apiDataService={apiDataService}
      defaultColumns={scorecardTemplatesDefaultColumns}
      defaultOptions={defaultOptions}
      demo={demo}
      getAvailableColumns={getAvailableColumns}
      gridName='scorecard-templates'
      iconName='templates'
      newScreenRoute='new'
      requiredFields={scorecardsRequiredFields}
      showRecordProp='vcid'
      unEditableColumns={scorecardTemplatesUnEditableColumns}
      validationColumns={scorecardsValidationColumns}
    />
  );
};

export default ScorecardTemplatesAll;
