import {
  getSortOrderCustom,
  getTableCode,
  translateSourceTablesTitles,
} from '../../../utils/reports';
import { useEffect, useRef, useState } from 'react';

import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import moment from 'moment';
import PanelsSection from '../../Elements/PanelsSection';
import Text from '../../Elements/Text';

import './ReportsFocusParameters.css';

const ReportsFocusParameters = ({
  handleToggleAvailableSection,
  isShowingFilters,
  reportParameters,
  sortedValidParameters,
}) => {
  const focusParametersRef = useRef(null);

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [areReportParametersExpanded, setAreReportParametersExpanded] =
    useState(false);

  const getParameterDataBet = (reportParameter) => {
    const betFrom = reportParameters[reportParameter].params[0];
    const betTo = reportParameters[reportParameter].params[1];

    if (reportParameters[reportParameter].control === 'Date') {
      return `${moment(betFrom).format('Do MMMM YYYY')} - ${moment(
        betTo
      ).format('Do MMMM YYYY')}`;
    } else {
      return `${betFrom} - ${betTo}`;
    }
  };

  const getParameterDataInc = (reportParameter) => {
    return Object.keys(reportParameters[reportParameter].params).sort(
      (a, b) => {
        if (reportParameters[reportParameter].control === 'Number') {
          if (a === b) return 0;
          if (a === '-') return 1;
          if (b === '-') return -1;

          return a - b;
        }

        if (reportParameters[reportParameter].control === 'Date') {
          if (a === b) return 0;
          if (b === '-') return -1;

          return (
            new Date(moment(b, 'Do MMMM YYYY')) -
            new Date(moment(a, 'Do MMMM YYYY'))
          );
        }

        const sortOrderCustom = getSortOrderCustom(
          a,
          b,
          getTableCode(reportParameter),
          reportParameters[reportParameter].control,
          reportParameters[reportParameter].title
        );

        if (sortOrderCustom !== 'unsorted') return sortOrderCustom;

        if (a === b) return 0;
        if (a === '-') return 1;
        if (b === '-') return -1;

        return a.toLowerCase().localeCompare(b.toLowerCase());
      }
    );
  };

  useEffect(() => {
    if (!isOverflowing) setAreReportParametersExpanded(false);
  }, [isOverflowing]);

  useEffect(() => {
    const focusParametersElement = focusParametersRef.current;

    if (focusParametersElement) {
      const handleOverflow = () => {
        setIsOverflowing(focusParametersElement.scrollHeight > 76);
      };

      // Initial check
      handleOverflow();

      // Check on window resize
      const resizeObserver = new ResizeObserver(handleOverflow);
      resizeObserver.observe(focusParametersElement);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <PanelsSection
      alignItems='stretch'
      margin={`0 30px ${isShowingFilters ? '2' : '3'}0px`}
      noMaxWidth
      position='relative'
      typeStyle='type-2'
      width='unset'
    >
      <span
        className={`focus-parameters ${
          areReportParametersExpanded ? 'expand-focus-parameters' : ''
        }`}
        ref={focusParametersRef}
      >
        <Text
          classes='report-parameters-header-text'
          text='Report Parameters: '
        />

        {sortedValidParameters.map((validParameter, index, arr) => {
          const tableCode = getTableCode(validParameter);
          const previousTableCode = index !== 0 && getTableCode(arr[index - 1]);
          const newTable = tableCode !== previousTableCode;

          const section = {
            code: validParameter,
            control: reportParameters[validParameter].control,
            title: reportParameters[validParameter].title,
            source_table: tableCode,
          };

          return (
            <span key={validParameter}>
              {newTable && index !== 0 ? (
                <>
                  <Text text=', ' />

                  <Text classes='report-parameters-header-text' text='and ' />
                </>
              ) : (
                <></>
              )}

              {newTable && (
                <Text
                  classes='report-parameters-table-title-text'
                  text={`${translateSourceTablesTitles[tableCode]} `}
                />
              )}

              {!newTable ? (
                <>
                  <Text text=', ' />

                  <Text classes='report-parameters-header-text' text='and ' />
                </>
              ) : (
                <></>
              )}

              <Text
                classes={`report-parameters-section-title-text ${
                  !isShowingFilters
                    ? 'report-parameters-section-title-text-not-allowed'
                    : ''
                }`}
                onClick={() => {
                  if (!isShowingFilters) return;

                  handleToggleAvailableSection(validParameter, section);
                }}
                text={`${section.title}`}
                title={
                  isShowingFilters
                    ? 'Toggle parameters'
                    : 'Show filters to toggle parameters'
                }
              />

              {reportParameters[validParameter].type === 'bet' ? (
                <Text text={` = ${getParameterDataBet(validParameter)}`} />
              ) : (
                getParameterDataInc(validParameter).map((param, index) => {
                  return (
                    <div key={param} style={{ display: 'contents' }}>
                      {index !== 0 ? (
                        <Text
                          classes='report-parameters-header-text'
                          text=' or'
                        />
                      ) : (
                        <Text text=' =' />
                      )}
                      <Text text={` ${param}`} />
                    </div>
                  );
                })
              )}
            </span>
          );
        })}
      </span>

      {isOverflowing && (
        <ButtonIconOnly
          classes='focus-parameters-expand-button'
          iconName={`arrows-${areReportParametersExpanded ? 'up' : 'down'}`}
          onClick={() => setAreReportParametersExpanded((curr) => !curr)}
          title={areReportParametersExpanded ? 'Collapse' : 'Expand'}
        />
      )}
    </PanelsSection>
  );
};

export default ReportsFocusParameters;
