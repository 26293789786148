import tickSmall from '../../images/tick-small.png';

import './About.css';

const DashboardFeatures = () => {
  return (
    <ul className='about-screen-list'>
      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Create up to 3 personalised dashboards</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Design your dashboards to fit with the screen sizes of the devices you
          will typically be using
        </p>

        <ul className='about-screen-list about-screen-list-nested'>
          <li>
            <p>Wide screens - Dashboard 1 will open by default</p>
          </li>

          <li>
            <p>Small screens - Dashboard 2 will open by default</p>
          </li>
        </ul>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Give your dashboards a title</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Drag and resize dashboard elements</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Select header text for dashboard elements</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Select table / chart data for dashboard elements</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Where report data is being used in a dashboard element, a link to the
          original report will be available from the element
        </p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Hover over most charts for further detail</p>
      </li>
    </ul>
  );
};

export default DashboardFeatures;
