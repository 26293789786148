import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { Navigate } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';

import apiDataService from '../../../api/billing';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import MainOnly from '../MainAndOptions/MainOnly';
import SubscriptionSuccess from './SubscriptionSuccess';

const Return = () => {
  const { userFromDb } = useContext(UserPortal);

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);

  const portalId = userFromDb.portal_id;

  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const billingEmail = urlParams.get('email');
  const hasChanged = urlParams.get('has_changed');
  const sessionId = urlParams.get('session_id');

  const getSessionStatus = async () => {
    if (sessionId) {
      const response = await apiDataService.getSessionStatus({
        portalId,
        sessionId,
      });

      setStatus(response.status);
      setCustomerEmail(response.customer_email);
    } else {
      setStatus(billingEmail ? 'manual' : 'open');
      setCustomerEmail(billingEmail);
    }
  };

  useEffect(() => {
    getSessionStatus();

    setBreadcrumbs([['Subscription Confirmation', 'thumbs-up']]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === 'open') {
    return <Navigate to='/billing' />;
  }

  if (status === 'complete' || status === 'manual')
    return (
      <MainOnly>
        <SubscriptionSuccess
          customerEmail={customerEmail}
          portalId={portalId}
          hasChanged={hasChanged}
        />
      </MainOnly>
    );

  return <LoadingSpinner />;
};

export default Return;
