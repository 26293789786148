import { cloneElement } from 'react';

import './MainContainer.css';

const MainContainer = ({ isNavOpen, children, moduleStyles }) => {
  return (
    <div className={`mid-content ${moduleStyles?.midContent ?? ''}`}>
      <div>
        {cloneElement(children, {
          isNavOpen,
        })}
      </div>
    </div>
  );
};

export default MainContainer;
