import { useState } from 'react';

import AdminSection from './AdminSection';
import FillScoringCategories from './FillScoringCategories';
import FillScoringSections from '../CreateScorecard/FillScoringSections';

import './Scorecard.css';
import './PrintableScorecard.css';

const PrintableScorecard = ({
  adminSections,
  bragAmber,
  bragBlack,
  bragGreen,
  bragRed,
  demo,
  evaluation,
  fromDrafts,
  isEditingEvaluation,
  isEvaluation,
  isNew,
  logo,
  moduleStyles,
  orgName,
  passMark,
  scorecardName,
  scoringSections,
  sectionPos,
  sectionNeg,
}) => {
  const brag = [
    { colour: 'green', value: bragGreen },
    { colour: 'amber', value: bragAmber },
    { colour: 'red', value: bragRed },
    { colour: 'black', value: bragBlack },
  ].sort((a, b) => b.value - a.value);

  const [qualityScore, setQualityScore] = useState(
    evaluation?.quality_score ?? 100
  );

  const [bragBackground, setBragBackground] = useState(
    `scorecard-bragback-${evaluation?.brag ?? 'plain'}`
  );

  const categoryScoresFromEval = {};

  let ra = 0;
  let weightOutOf = 0;
  let weightTotal = 0;
  const csm = [];

  let markedCount = 0;
  const passedSections = [];
  const failedSections = [];
  const failedRa = [];
  const failedWeight = [];
  const failedCsm = [];

  const scoringResultsFromEval = {};

  const commentsResultsFromEval = {};

  evaluation?.evaluatedSections?.scoringSecs.forEach((cat) => {
    if (cat.type !== 'no-cat')
      categoryScoresFromEval[`cat${cat.id}`] = cat.data;

    cat.sections.forEach((sec) => {
      if (sec.data === 'pass' || sec.data === 'fail') {
        markedCount++;

        if (sec.data === 'fail') {
          if (sec.ra) ra += parseInt(sec.ra);
          if (sec.csm) {
            csm.push({
              csmAmount: sec.csm,
              sectionName: sec.title,
              sectionRef: sec.type + sec.id,
            });

            csm.sort((a, b) => a.csmAmount - b.csmAmount);
          }
        }

        if (sec.weight) {
          weightOutOf += Number(sec.weight);

          if (sec.data === 'pass') weightTotal += Number(sec.weight);
        }

        if (sec.data === 'pass') {
          passedSections.push({
            ...sec,
            uniqueRef: sec.type + sec.id,
          });
        } else {
          failedSections.push({
            ...sec,
            uniqueRef: sec.type + sec.id,
          });
          if (sec.weight)
            failedWeight.push({
              ...sec,
              uniqueRef: sec.type + sec.id,
            });
          if (sec.ra)
            failedRa.push({
              ...sec,
              uniqueRef: sec.type + sec.id,
            });
          if (sec.csm)
            failedCsm.push({
              ...sec,
              uniqueRef: sec.type + sec.id,
            });
        }
      }

      if (sec.data === 'pass' || sec.data === 'fail' || sec.data === 'na') {
        scoringResultsFromEval[sec.id] = sec.data;
      }

      if (sec.type === 'comments' && sec.data)
        commentsResultsFromEval[sec.id] = sec.data;
    });
  });

  const [categoryScores, setCategoryScores] = useState(
    evaluation ? categoryScoresFromEval : {}
  );

  const [behaviourTotals, setBehaviourTotals] = useState(
    evaluation
      ? { weightOutOf, weightTotal, ra, csm }
      : {
          weightOutOf: 0,
          weightTotal: 0,
          ra: 0,
          csm: [],
        }
  );

  const [sectionResults, setSectionResults] = useState(
    evaluation
      ? {
          markedCount,
          passedSections,
          failedSections,
          failedRa,
          failedWeight,
          failedCsm,
        }
      : {
          markedCount: 0,
          passedSections: [],
          failedSections: [],
          failedRa: [],
          failedWeight: [],
          failedCsm: [],
        }
  );

  const adminResults = {};

  evaluation?.evaluatedSections?.adminSecs.forEach((sec) => {
    adminResults[sec.id] = sec.data;
  });

  const commentsResults = evaluation ? commentsResultsFromEval : {};
  const scoringResults = evaluation ? scoringResultsFromEval : {};

  const mainComments = evaluation?.comments_main ?? '';

  const convertPassMark = (passMark) => {
    if (passMark === 'pass') return 'Pass';
    if (passMark === 'fail') return 'Fail';
    return '';
  };

  return (
    <div
      className={`printable-scorecard-background scorecard-background ${
        isNew ? moduleStyles?.scorecardBackground ?? '' : ''
      }`}
    >
      <div className='scorecard-header'>
        {logo && (
          <img
            className={`scorecard-logo ${moduleStyles?.scorecardLogo ?? ''}`}
            src={logo}
            alt='logo'
          />
        )}

        {orgName && (
          <h2
            className={`scorecard-org-name ${
              moduleStyles?.scorecardOrgName ?? ''
            } wrap-unbroken`}
          >
            {orgName}
          </h2>
        )}

        <h1
          className={`scorecard-title ${
            moduleStyles?.scorecardTitle ?? ''
          } wrap-unbroken`}
        >
          {scorecardName ? scorecardName : '<scorecard name>'}
        </h1>
      </div>

      <div className='scorecard-top-results-container'>
        <div
          className={`scorecard-top-results ${
            moduleStyles?.scorecardTopResults ?? ''
          }`}
        >
          {evaluation?.fed_back ? (
            <div
              className={`scorecard-top-result-container scorecard-top-result-container-fed-back`}
            >
              <h1 className='scorecard-top-result'>Fed Back</h1>
            </div>
          ) : (
            <></>
          )}

          <div
            className={`scorecard-top-result-container ${
              sectionResults.markedCount ? bragBackground : ''
            }`}
          >
            <h1 className='scorecard-top-result'>
              {sectionResults.markedCount ? qualityScore : ''}
            </h1>
          </div>

          {passMark && (
            <div className='scorecard-top-result-container scorecard-passmark-container'>
              <h1 className='scorecard-top-result'>
                {evaluation?.imported && evaluation?.pass_fail
                  ? convertPassMark(evaluation.pass_fail)
                  : sectionResults.markedCount
                  ? qualityScore >= passMark
                    ? 'Pass'
                    : 'Fail'
                  : ''}
              </h1>
            </div>
          )}
        </div>
      </div>

      {adminSections.length > 0 && (
        <div className='scorecard-admin-sections-container'>
          <ul
            className={`scorecard-admin-sections ${
              (scoringSections.length &&
                scoringSections[0]?.type === 'scoring-category') ||
              scoringSections[0]?.type === 'cat'
                ? 'scorecard-admin-sections-less-margin'
                : ''
            } ${moduleStyles?.scorecardAdminSections ?? ''}`}
          >
            {adminSections.map((section) => {
              const sectionId = isEvaluation ? section.id : section.uniqueRef;
              const elementId = 'a' + sectionId;

              return (
                <AdminSection
                  key={elementId}
                  adminResults={adminResults}
                  demo={demo}
                  elementId={elementId}
                  fromPrintable
                  handleSetInvalidSections={() => {}}
                  handleUpdateAdminSection={() => {}}
                  isEvaluation
                  section={section}
                  sectionId={sectionId}
                />
              );
            })}
          </ul>
        </div>
      )}

      {adminSections.length &&
      scoringSections.length &&
      scoringSections[0]?.type !== 'scoring-category' &&
      scoringSections[0]?.type !== 'cat' ? (
        <div style={{ marginBottom: '10px' }}></div>
      ) : (
        <></>
      )}

      {scoringSections.length > 0 && (
        <ul className='scorecard-scoring-sections'>
          {isNew
            ? scoringSections.map((section) => {
                return (
                  <FillScoringSections
                    key={section.uniqueRef || section.position}
                    fromPrintable
                    section={section}
                    sectionPos={sectionPos}
                    sectionNeg={sectionNeg}
                  />
                );
              })
            : scoringSections.map((category) => {
                return (
                  <FillScoringCategories
                    key={`cat${category.id}`}
                    sections={category.sections}
                    sectionPos={sectionPos}
                    sectionNeg={sectionNeg}
                    catTitle={category.title}
                    commentsResults={commentsResults}
                    fromPrintable
                    scoringResults={scoringResults}
                    type={category.type}
                    handleSetInvalidSections={() => {}}
                    id={`cat${category.id || 'nocat'}`}
                    sectionResults={sectionResults}
                    setSectionResults={setSectionResults}
                    scorecardBehaviourTotals={behaviourTotals}
                    setScorecardBehaviourTotals={setBehaviourTotals}
                    setQualityScore={setQualityScore}
                    setCategoryScores={setCategoryScores}
                    brag={brag}
                    setBragBackground={setBragBackground}
                    categoryScores={categoryScores}
                    evaluatedCategorySection={evaluation?.evaluatedSections?.scoringSecs.find(
                      (cat) => cat.id === category.id
                    )}
                    showScreen={!!evaluation && !fromDrafts}
                    isEditingEvaluation={isEditingEvaluation}
                  />
                );
              })}
        </ul>
      )}
      {(adminSections.length > 0 || scoringSections.length > 0) && (
        <div className='scorecard-comments-divider'></div>
      )}

      <div className='scorecard-main-comments-container'>
        <div className='scorecard-main-comments'>{mainComments}</div>
      </div>
    </div>
  );
};

export default PrintableScorecard;
