function updateScoringCategories(command) {
  const { add } = command;

  if (add) {
    const {
      type,
      scoringSections,
      setScoringSections,
      categoryName,
      setCategoryName,
      setCategoryCount,
      currentlyEditingCat,
      setCurrentlyEditingCat,
      setShouldScrollToBtm,
    } = add;

    let overwrittenRef = '';
    let overwrittenCat = '';

    const copiedScoringSections = scoringSections.map((section, index) => {
      if (section.uniqueRef === currentlyEditingCat) {
        const { uniqueRef, title } = section;

        overwrittenRef = uniqueRef;
        overwrittenCat = title;

        return {
          ...section,
          type: type,
          uniqueRef: uniqueRef,
          title: categoryName,
          id: index,
        };
      } else return { ...section, id: index };
    });
    if (overwrittenRef) setCurrentlyEditingCat('');
    else {
      const newUniqueRef = `${Math.floor(
        Math.random() * 100000000000
      )}${Date.now()}`;

      copiedScoringSections.push({
        type: type,
        uniqueRef: newUniqueRef,
        title: categoryName,
        id: copiedScoringSections.length,
      });
      setCategoryCount((currVal) => currVal + 1);
      setShouldScrollToBtm(true);
    }
    setScoringSections(copiedScoringSections);
    setCategoryName('');

    return { overwrittenRef: overwrittenRef, overwrittenCat: overwrittenCat };
  }
}

export default updateScoringCategories;
