import FlexContainer from '../../Elements/FlexContainer';

import './ReportsAllPortalReports.css';

const ReportsAllPortalReports = ({ demo, portalId, userFromDb }) => {
  return (
    <FlexContainer
      alignItems='stretch'
      classes='grid-panel portal-reports-grid-panel'
      marginBottom='40px'
      padding='5px'
    >
      {demo || userFromDb.permissions?.includes('reports.focus') ? (
        <a
          className='grid-card portal-reports-card'
          href={`/${demo ? 'demo' : portalId}/reports/focus`}
        >
          <p className='grid-card-header'>Focus</p>
        </a>
      ) : (
        <></>
      )}

      {demo || userFromDb.permissions?.includes('reports.hot_topics') ? (
        <a
          className='grid-card portal-reports-card'
          href={`/${demo ? 'demo' : portalId}/reports/hot-topics`}
        >
          <p className='grid-card-header'>Hot Topics</p>
        </a>
      ) : (
        <></>
      )}

      {demo || userFromDb.permissions?.includes('reports.risk') ? (
        <a
          className='grid-card portal-reports-card'
          href={`/${demo ? 'demo' : portalId}/reports/risk`}
        >
          <p className='grid-card-header'>Risk</p>
        </a>
      ) : (
        <></>
      )}

      {demo || userFromDb.permissions?.includes('reports.qa') ? (
        <a
          className='grid-card portal-reports-card'
          href={`/${demo ? 'demo' : portalId}/reports/qa`}
        >
          <p className='grid-card-header'>QA</p>
        </a>
      ) : (
        <></>
      )}

      {demo || userFromDb.permissions?.includes('reports.calibrations') ? (
        <a
          className='grid-card portal-reports-card'
          href={`/${demo ? 'demo' : portalId}/reports/calibrations`}
        >
          <p className='grid-card-header'>Calibrations</p>
        </a>
      ) : (
        <></>
      )}
    </FlexContainer>
  );
};

export default ReportsAllPortalReports;
