import {
  logsAvailableColumns,
  logsDefaultColumns,
  logsUnEditableColumns,
} from '../columns';
import { useContext } from 'react';
import { UserPortal } from '../../../contexts';

import apiDataService from '../../../api/logs';
import DataGrid from '../DataGrid/DataGrid';
import logsTableNameTranslations from './logsTableNameTranslations';

const Logs = ({ demo }) => {
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;

  const getAvailableColumns = (columns = {}) => {
    return { ...logsAvailableColumns, ...columns };
  };

  const defaultOptions = {
    customColumns: null,
    limit: 10,
    page: 1,
    query: null,
    sortBy: null,
    view: 'table',
  };

  const selectFrom = {
    method: [
      ['CREATE', 'Create'],
      ['delete', 'Delete'],
      ['insert', 'Insert'],
      ['update', 'Update'],
    ],

    // Make sure logs is only showing data relating to this schema!

    // Will prob need to add to this

    table_name: logsTableNameTranslations(demo, portalId),
  };

  return (
    <DataGrid
      apiDataService={apiDataService}
      defaultColumns={logsDefaultColumns}
      defaultOptions={defaultOptions}
      demo={demo}
      getAvailableColumns={getAvailableColumns}
      gridName='logs'
      iconName='book-open'
      noEditNew
      noShow
      selectFrom={selectFrom}
      unEditableColumns={logsUnEditableColumns}
    />
  );
};

export default Logs;
