import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

import Divider from '../Elements/Divider';
import Icon from '../Elements/Icon';
import iconCalibrate from '../../images/icon-calibrate.png';
import iconDatabase from '../../images/icon-database.png';
import iconMobile from '../../images/icon-mobile.png';
import iconScorecard from '../../images/icon-scorecard.png';
import iconPerson from '../../images/icon-person.png';
import iconReport from '../../images/icon-report.png';
import iconRisk from '../../images/icon-risk.png';

import './HomeFeatures.css';

const HomeFeatures = () => {
  return (
    <div className='home-features'>
      <div className='home-features-banner'>
        <h2>Features</h2>

        <h4>Explore some of the features below by trying out our demo</h4>
      </div>

      <ul className='home-features-list'>
        <li className='home-features-list-item'>
          <img
            className='home-features-icon'
            src={iconScorecard}
            alt='scorecard icon'
          />

          <h3 className='home-features-title'>Custom Scorecards</h3>

          <p className='home-features-text'>
            Our scorecard building tool is easy to use and has a wide range of
            options and scoring behaviours available, giving you maximum
            precision in determining overall quality.
            <br />
            <br />
            We also provide scorecard templates, and you can create your own
            templates, saving you time and ensuring consistency.
          </p>

          <Link className='home-features-demo-btn' to='/demo/scorecards/new'>
            Demo
          </Link>

          <HashLink
            className='home-features-learn-btn'
            smooth
            to='/#about-scorecards'
          >
            Learn More
          </HashLink>
        </li>
        {/* 
        <li className='home-features-list-item'>
          <img
            className='home-features-icon'
            src={iconI}
            alt='intelligent icon'
          />
          <h3 className='home-features-title'>Intelligent Feedback</h3>
          <p className='home-features-text'>
            Evaluation feedback forms suggests coaching areas based on the
            agent's and the wider company's performance.
            <br />
            We also calculate the key development areas and top sections for
            each agent.
            <br />
            <br />
            This drives thorough and effective coaching experiences.
          </p>
          <Link to='/scoring-feedback-demo' className='home-features-demo-btn'>
            Demo
          </Link>
          <button className='home-features-learn-btn'>Learn More</button>
        </li> */}

        <li className='home-features-list-item'>
          <img
            className='home-features-icon'
            src={iconReport}
            alt='report icon'
          />
          <h3 className='home-features-title'>In-depth Reports</h3>

          <p className='home-features-text'>
            You will have extensive reporting capabilities, creating custom
            targeted reports based on any of the metrics you have applied to
            your scorecards.
            <br />
            <br />
            We provide reports that show scorecard section focus areas, QA staff
            performance, calibration, and trend analysis, that you can drill
            down on with ease.
          </p>

          <Link className='home-features-demo-btn' to='/demo/reports'>
            Demo
          </Link>

          <HashLink
            className='home-features-learn-btn'
            smooth
            to='/#about-reports'
          >
            Learn More
          </HashLink>
        </li>

        <li className='home-features-list-item'>
          <img
            className='home-features-icon'
            src={iconCalibrate}
            alt='calibrate icon'
          />

          <h3 className='home-features-title'>Calibration Centre</h3>

          <p className='home-features-text'>
            Develop your Quality Assurance team through calibration sessions
            using our calibration tools, which provide a rich insight into the
            variances within your team.
            <br />
            <br />
            You can invite agents and their managers to join in, ensuring a
            unified and consistent message across all levels of the business.
          </p>

          <Link className='home-features-demo-btn' to='/demo/calibrations'>
            Demo
          </Link>

          <HashLink
            className='home-features-learn-btn'
            smooth
            to='/#about-calibrations'
          >
            Learn More
          </HashLink>
        </li>

        <li className='home-features-list-item'>
          <img
            className='home-features-icon'
            src={iconPerson}
            alt='person icon'
          />

          <h3 className='home-features-title'>Personalised Dashboards</h3>

          <p className='home-features-text'>
            Create your own dashboard from your custom reports and give yourself
            a clear, comprehensive and immediate overview of the key focus areas
            and any developing trends.
            <br />
            <br />
            With up to 3 customisable dashboards, you will have everything you
            need at your fingertips.
          </p>

          <Link className='home-features-demo-btn' to='/demo/dashboard'>
            Demo
          </Link>

          <HashLink
            className='home-features-learn-btn'
            smooth
            to='/#about-dashboard'
          >
            Learn More
          </HashLink>
        </li>

        <li className='home-features-list-item'>
          <img className='home-features-icon' src={iconRisk} alt='risk icon' />

          <h3 className='home-features-title'>Risk Analysis</h3>

          <p className='home-features-text'>
            We calculate the risk level of all the scorecard sections you have
            created in your portal.
            <br />
            <br />
            Our algorithm looks at current performance, historic trends, the
            scoring behaviours you have applied, and the volume of evaluations
            relating to the area you are viewing risk for.
          </p>

          <Link className='home-features-demo-btn' to='/demo/reports/risk'>
            Demo
          </Link>

          <HashLink
            className='home-features-learn-btn'
            smooth
            to='/#about-risk'
          >
            Learn More
          </HashLink>
        </li>

        <li className='home-features-list-item'>
          <img
            className='home-features-icon'
            src={iconDatabase}
            alt='database icon'
          />

          <h3 className='home-features-title'>Easy Integration</h3>

          <p className='home-features-text'>
            Import your existing evaluations, as well as staff lists and users
            for an effortless transition to the QA Portal.
            <br />
            <br />
            Use our templates to make it quicker and simpler.
            <br />
            <br />
            Export your portal data and print/save evaluations, scorecards, and
            reports.
          </p>

          <Link className='home-features-demo-btn' to='/demo/imports'>
            Demo
          </Link>

          <HashLink
            className='home-features-learn-btn'
            smooth
            to='/#about-integration'
          >
            Learn More
          </HashLink>
        </li>

        <li className='home-features-list-item'>
          <Icon
            className='home-features-icon home-features-icon-new'
            iconName='tag'
          />

          <h3 className='home-features-title'>Branding</h3>

          <p className='home-features-text'>
            Personalise your portal experience with your company branding.
            <br />
            <br />
            You can replace the QA Portal logo in the nav bar with your company
            logo, and optionally apply your logo to some or all of your
            scorecards / evaluations. Add your company name too for a branded
            professional look.
          </p>

          <HashLink
            className='home-features-onlylearn-btn'
            smooth
            to='/#about-branding'
          >
            Learn More
          </HashLink>
        </li>

        <li className='home-features-list-item'>
          <img
            className='home-features-icon'
            src={iconMobile}
            alt='smartphone icon'
          />

          <h3 className='home-features-title'>Optimized & Accessible</h3>

          <p className='home-features-text'>
            We've built the QA Portal to be accessible regardless of screen size
            by incorporating responsive web design.
            <br />
            <br />
            So whether you're in the office at a monitor, in a meeting with a
            tablet, or out and about on your smartphone, the content you are
            viewing will adapt accordingly.
          </p>
        </li>
      </ul>

      <Divider classes='home-screen-features-divider' />
    </div>
  );
};

export default HomeFeatures;
