import InputValidationText from './InputValidationText';
import InputValidityIcon from './InputValidityIcon';
import Text from './Text';

import './InputStandard.css';

const InputStandard = ({
  accept,
  checked,
  classes = '',
  direction = 'horizontal',
  disabled,
  elementRef,
  hasPermission = true,
  labelFontWeight,
  id,
  inputClasses = '',
  inputWidth,
  invalid,
  isRequired,
  justifyContent,
  labelPosition = 'before',
  labelText,
  margin,
  marginBottom,
  marginRight,
  maxLength,
  name,
  onChange,
  onClick,
  placeholder,
  showInvalid,
  step,
  type = 'text',
  typeStyle = 'type-1',
  value,
  wrap = 'no-wrap',
}) => {
  const style = {};
  const inputStyle = {};
  const labelStyle = {};

  if (margin) style.margin = margin;
  if (marginBottom) style.marginBottom = marginBottom;
  if (marginRight) style.marginRight = marginRight;
  if (justifyContent) style.justifyContent = justifyContent;
  if (inputWidth) inputStyle.width = inputWidth;
  if (labelFontWeight) labelStyle.fontWeight = labelFontWeight;
  if (disabled && hasPermission) labelStyle.color = '#cccccc';

  return (
    <div
      className={`input-standard input-standard-${typeStyle} input-standard-${type} input-standard-${direction}-${labelPosition} input-standard-${wrap} ${classes}`}
      style={style}
    >
      {labelText ? (
        <label htmlFor={id} style={labelStyle}>
          {labelText}
        </label>
      ) : (
        <></>
      )}

      <div
        className={`input-text-container ${
          !hasPermission ? 'input-text-container-no-permission' : ''
        } ${
          invalid?.length > 0 && showInvalid ? 'input-container-invalid' : ''
        }`}
      >
        {hasPermission ? (
          <>
            <InputValidityIcon
              isInvalid={invalid?.length}
              isRequired={isRequired}
              showInvalid={showInvalid}
            />

            <input
              accept={accept}
              checked={checked}
              className={`${isRequired ? 'input-standard-required' : ''} ${
                invalid?.length > 0 && showInvalid ? 'input-invalid' : ''
              } ${inputClasses}`}
              disabled={disabled}
              id={id}
              name={name}
              onChange={onChange}
              onClick={onClick}
              placeholder={placeholder}
              ref={elementRef}
              step={step}
              style={inputStyle}
              type={type}
              value={value}
              maxLength={maxLength}
            />

            {invalid?.length > 0 && <InputValidationText text={invalid} />}
          </>
        ) : (
          <Text classes='input-no-permission-text' text={value} />
        )}
      </div>
    </div>
  );
};

export default InputStandard;
