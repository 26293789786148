import './_CreateScorecardOptions.css';

import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { UserPortal } from '../../../contexts';

import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InputStandard from '../../Elements/InputStandard';
import portalConfirmAlert from '../../../utils/portalConfirmAlert';
import portalsDataService from '../../../api/portals';
import SelectStandard from '../../Elements/SelectStandard';
import Text from '../../Elements/Text';
import Toggle from '../../Elements/Toggle';

const CreateScorecardOptions = ({
  defaultOptions,
  demo,
  edgeMargins,
  fromDrafts,
  hasChangePermission,
  isUsingDefault,
  setIsUsingDefault,
  passMark,
  setPassMark,
  bragBlack,
  setBragBlack,
  setDefaultOptions,
  bragRed,
  setBragRed,
  bragAmber,
  setBragAmber,
  bragGreen,
  setBragGreen,
  sectionSelect,
  setSectionSelect,
  sectionSelectPos,
  setSectionSelectPos,
  sectionSelectNeg,
  setSectionSelectNeg,
  orgName,
  setOrgName,
  logoUrl,
  setLogoUrl,
  imageFile,
  setImageFile,
  setStatus,

  status,
  saveType,

  bragInvalid,
  sectionSelectInvalid,
  sectionSelectNegInvalid,
  sectionSelectPosInvalid,
  statusInvalid,
  passMarkInvalid,
  showInvalid,
  handleSetHasChanges,
}) => {
  saveType = 'template';

  // const [passMarkInvalid, setPassMarkInvalid] = useState('');

  const alert = useAlert();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const hasScorecardDefaultsPermission =
    demo || userFromDb.permissions?.includes('scorecard_defaults.change');

  const { getAccessTokenSilently } = useAuth0();

  const handleUsingDefaultChange = () => {
    setPassMark(isUsingDefault ? '' : defaultOptions?.passMark || '');
    setBragBlack(
      isUsingDefault ? '' : defaultOptions?.brag.black.toString() || ''
    );
    setBragRed(isUsingDefault ? '' : defaultOptions?.brag.red.toString() || '');
    setBragAmber(
      isUsingDefault ? '' : defaultOptions?.brag.amber.toString() || ''
    );
    setBragGreen(
      isUsingDefault ? '' : defaultOptions?.brag.green.toString() || ''
    );
    setSectionSelect(isUsingDefault ? '' : defaultOptions?.sectionSelect || '');
    setSectionSelectPos(
      isUsingDefault ? '' : defaultOptions?.sectionSelectPos || ''
    );
    setSectionSelectNeg(
      isUsingDefault ? '' : defaultOptions?.sectionSelectNeg || ''
    );
    setOrgName(isUsingDefault ? '' : defaultOptions?.branding.orgName || '');
    setImageFile(isUsingDefault ? '' : defaultOptions?.branding.logoUrl || '');
    setLogoUrl(isUsingDefault ? '' : defaultOptions?.branding.logoUrl || '');
    setStatus(isUsingDefault ? '' : defaultOptions?.status || '');

    handleSetHasChanges?.();

    // if (isUsingDefault) {
    //   setBragInvalid('');
    // } else {
    //   validateBrag(defaultOptions?.brag.amber, 'a');
    //   validateBrag(defaultOptions?.brag.black, 'b');
    //   validateBrag(defaultOptions?.brag.green, 'g');
    //   validateBrag(defaultOptions?.brag.red, 'r');
    // }

    setIsUsingDefault((currState) => !currState);
  };

  const handlePassMarkChange = (event) => {
    const newPassMark = event.target.value;
    setPassMark(newPassMark);
    setIsUsingDefault(false);

    handleSetHasChanges?.();
    // if (newPassMark && !/^[1-9][0-9]?$|^100$/.test(newPassMark))
    //   setPassMarkInvalid('Must be a whole number from 1 to 100');
    // else setPassMarkInvalid('');
  };

  const handleBragRedChange = (event) => {
    const newBragVal = event.target.value;
    setBragRed(newBragVal);
    // validateBrag(newBragVal, 'r');
    setIsUsingDefault(false);

    handleSetHasChanges?.();
  };
  const handleBragAmberChange = (event) => {
    const newBragVal = event.target.value;
    setBragAmber(newBragVal);
    // validateBrag(newBragVal, 'a');
    setIsUsingDefault(false);

    handleSetHasChanges?.();
  };
  const handleBragGreenChange = (event) => {
    const newBragVal = event.target.value;
    setBragGreen(newBragVal);
    // validateBrag(newBragVal, 'g');
    setIsUsingDefault(false);

    handleSetHasChanges?.();
  };
  const handleSectionSelectChange = (event) => {
    setSectionSelect(event.target.value);

    if (event.target.value !== 'custom') {
      setSectionSelectPos('');
      setSectionSelectNeg('');
    }
    setIsUsingDefault(false);

    handleSetHasChanges?.();
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);

    setIsUsingDefault(false);

    handleSetHasChanges?.();
  };

  const handleSectionSelectPosChange = (event) => {
    setSectionSelectPos(event.target.value);
    setIsUsingDefault(false);

    handleSetHasChanges?.();
  };
  const handleSectionSelectNegChange = (event) => {
    setSectionSelectNeg(event.target.value);
    setIsUsingDefault(false);

    handleSetHasChanges?.();
  };

  const handleOrgNameChange = (event) => {
    setOrgName(event.target.value);
    setIsUsingDefault(false);

    handleSetHasChanges?.();
  };

  // const [imageFileInputValue, setImageFileInputValue] = useState('');

  // const handleImageChange = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setImageFile(URL.createObjectURL(event.target.files[0]));
  //     setLogoUrl('');
  //     setImageFileInputValue(event.target.value);
  //     setIsUsingDefault(false);

  //     handleSetHasChanges?.();
  //   } else {
  //     setImageFileInputValue('');
  //     setImageFile('');
  //   }
  // };

  const handleLogoUrlChange = (event) => {
    setLogoUrl(event.target.value);
    setImageFile(event.target.value);
    // setImageFileInputValue('');
    setIsUsingDefault(false);

    handleSetHasChanges?.();
  };

  const patchDefaultOptions = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    const confirmed = await portalConfirmAlert({
      message: 'Overwrite existing default settings?',
    });

    if (!confirmed) return;

    const scorecardDefaults = {
      brag: {
        amber: bragAmber,
        black: bragBlack,
        green: bragGreen,
        red: bragRed,
      },
      branding: { orgName, logoUrl },
      passMark,
      sectionSelect,
      sectionSelectNeg,
      sectionSelectPos,
      status,
    };

    await portalsDataService.update({
      portalId: demo ? 'demo' : portalId,
      reqBody: {
        updates: [
          {
            updateDetails: {
              scorecard_defaults: scorecardDefaults,
            },
            id: demo ? 1 : portalId,
          },
        ],
        userId,
      },
      token: demo ? undefined : await getAccessTokenSilently(),
    });

    // if no errors...

    setDefaultOptions(scorecardDefaults);

    setIsUsingDefault(true);

    alert.success('Default settings saved');
  };

  return (
    <div className='create-scorecard-options-background'>
      <div className='create-options-container'>
        {hasChangePermission ? (
          <Toggle
            id='default-settings-toggle'
            marginTop='15px'
            labelText='Default settings'
            onChange={handleUsingDefaultChange}
            toggled={isUsingDefault}
          />
        ) : (
          <div style={{ height: '5px' }}></div>
        )}

        <FlexContainer alignItems='flex-end' flexWrap='wrap' maxWidth='100%'>
          <InputStandard
            hasPermission={hasChangePermission}
            margin='5px'
            classes='options-passmark-input'
            direction='vertical'
            id='create-passmark-input'
            invalid={passMarkInvalid}
            labelText='Pass Mark'
            onChange={handlePassMarkChange}
            placeholder='none'
            showInvalid={showInvalid}
            type='number'
            value={passMark}
          />

          <FlexContainer
            margin='10px'
            flexDirection='column'
            position='relative'
          >
            <HeaderSecondary text='RAG' />

            <div
              className='create-brag-container'
              style={{
                marginLeft: edgeMargins.left,
                marginRight: edgeMargins.right,
              }}
            >
              <div className='create-brag-item-container'>
                <label htmlFor='create-brag-r' className='brag-red-label'>
                  Red
                </label>

                <input
                  disabled={!hasChangePermission}
                  id='create-brag-r'
                  className='create-brag-input'
                  type='number'
                  placeholder='none'
                  value={bragRed}
                  onChange={handleBragRedChange}
                />
              </div>
              <div className='create-brag-item-container'>
                <label htmlFor='create-brag-a' className='brag-amber-label'>
                  Amber
                </label>
                <input
                  disabled={!hasChangePermission}
                  id='create-brag-a'
                  className='create-brag-input'
                  type='number'
                  placeholder='none'
                  value={bragAmber}
                  onChange={handleBragAmberChange}
                />
              </div>

              <div className='create-brag-item-container'>
                <label htmlFor='create-brag-g' className='brag-green-label'>
                  Green
                </label>
                <input
                  disabled={!hasChangePermission}
                  id='create-brag-g'
                  className='create-brag-input'
                  type='number'
                  placeholder='none'
                  value={bragGreen}
                  onChange={handleBragGreenChange}
                />
              </div>
            </div>

            {bragInvalid && (
              <p className='create-brag-selection-invalid'>{bragInvalid}</p>
            )}
          </FlexContainer>

          <FlexContainer
            classes='marking-choices-select'
            margin='5px'
            flexDirection='column'
          >
            <SelectStandard
              hasPermission={hasChangePermission}
              id='marking-choices-select'
              invalid={sectionSelectInvalid}
              isPlaceholderSelected={!sectionSelect}
              isRequired
              labelText='Marking Choices'
              onChange={handleSectionSelectChange}
              options={[
                { code: '', name: '< Marking Choices >' },
                { code: 'pf', name: 'Pass / Fail / NA' },
                { code: 'yn', name: 'Yes / No / NA' },
                { code: 'custom', name: 'Custom' },
              ]}
              showInvalid={showInvalid}
              value={sectionSelect}
            />

            {sectionSelect === 'custom' && (
              <>
                <FlexContainer flexDirection='column'>
                  <HeaderSecondary text='Custom Marking Choices' />

                  <FlexContainer
                    classes='custom-marking-choice-inputs'
                    flexWrap='wrap'
                  >
                    <InputStandard
                      hasPermission={hasChangePermission}
                      isRequired
                      invalid={sectionSelectPosInvalid}
                      onChange={handleSectionSelectPosChange}
                      placeholder='+'
                      showInvalid={showInvalid}
                      value={sectionSelectPos}
                    />

                    <Text text='/' />

                    <InputStandard
                      hasPermission={hasChangePermission}
                      isRequired
                      invalid={sectionSelectNegInvalid}
                      onChange={handleSectionSelectNegChange}
                      placeholder='-'
                      showInvalid={showInvalid}
                      value={sectionSelectNeg}
                    />

                    <Text text='/ NA' />
                  </FlexContainer>
                </FlexContainer>
              </>
            )}
          </FlexContainer>

          <InputStandard
            hasPermission={hasChangePermission}
            margin='5px 15px'
            direction='vertical'
            id='create-branding-org-name-input'
            labelText='Organisation Name'
            name='section-name'
            onChange={handleOrgNameChange}
            placeholder='Organisation Name'
            value={orgName}
          />

          <FlexContainer margin='5px' flexDirection='column'>
            <FlexContainer marginBottom='5px'>
              <HeaderSecondary marginRight='10px' text='Logo' />

              <div className='create-logo-img-container'>
                <img className='create-logo-img' src={imageFile} alt='' />
              </div>
            </FlexContainer>

            {/* <FlexContainer flexWrap='wrap' classes='logo-url-file-inputs'> */}
            <InputStandard
              classes='create-logo-url-input'
              hasPermission={hasChangePermission}
              onChange={handleLogoUrlChange}
              placeholder='< URL >'
              maxLength={1000}
              type='url'
              value={logoUrl}
            />

            {/* <InputStandard
                classes='create-logo-file-input'
                hasPermission={hasChangePermission}
                inputClasses={`${
                  logoUrl || !imageFile ? 'preview-logo-input-no-file' : ''
                }`}
                maxLength={1000}
                onChange={handleImageChange}
                type='file'
                value={imageFileInputValue}
              /> */}
          </FlexContainer>
          {/* </FlexContainer> */}

          {!hasChangePermission && fromDrafts ? (
            <></>
          ) : (
            <SelectStandard
              containerStyle={{ margin: '0 10px 10px 10px' }}
              hasPermission={hasChangePermission}
              id='create-scorecard-status'
              invalid={statusInvalid}
              isPlaceholderSelected={!status}
              isRequired
              labelText='Status'
              onChange={handleStatusChange}
              options={[
                { code: '', name: '< Status >' },
                { code: 'active', name: 'Active' },
                { code: 'inactive', name: 'Inactive' },
              ]}
              showInvalid={showInvalid}
              value={status === 'template' ? '' : status}
            />
          )}
        </FlexContainer>

        {hasChangePermission && hasScorecardDefaultsPermission ? (
          <ButtonStandardNoFill
            margin='10px 0 15px'
            iconName='save-solid'
            onClick={patchDefaultOptions}
            text='Save Default Settings'
            typeStyle='type-4'
          />
        ) : (
          <div style={{ height: '5px' }}></div>
        )}
      </div>
    </div>
  );
};
export default CreateScorecardOptions;
