import addAgents from '../images/adding-agents.png';
import addDataLists from '../images/adding-data-lists.png';
import addUsers from '../images/adding-users.png';
import builtInReports from '../images/built-in-reports.png';
import calibration from '../images/calibration.png';
import calibrationResults from '../images/calibration-results.png';
import completedCalibration from '../images/completed-calibration.png';
import dashboard from '../images/dashboard.png';
import dashboardAddElement from '../images/dashboard-add-element.png';
import dataGridColumns from '../images/data-grid-columns.png';
import dataGridFilter from '../images/data-grid-filter.png';
import dataGridFilterDelete from '../images/data-grid-filter-delete.png';
import dataGridFilterSubFilter from '../images/data-grid-filter-sub-filter.png';
import dataGridFilterSubFilterDelete from '../images/data-grid-filter-sub-filter-delete.png';
import dataGridGrid from '../images/data-grid-grid.png';
import dataGridRecords from '../images/data-grid-records.png';
import dataGridSaveOptions from '../images/data-grid-save-options.png';
import dataGridSort from '../images/data-grid-sort.png';
import dataGridTable from '../images/data-grid-table.png';
import evalChooseSc from '../images/eval-choose-sc.png';
import evalFillSc from '../images/eval-fill-sc.png';
import evalResults from '../images/eval-results.png';
import navBar from '../images/nav-bar.png';
import navMenu from '../images/nav-menu.png';
import newScorecardAdminSecs from '../images/new-scorecard-admin-secs.png';
import newScorecardInfo from '../images/new-scorecard-info.png';
import newScorecardOptions from '../images/new-scorecard-options.png';
import newScorecardPreview from '../images/new-scorecard-preview.png';
import newScorecardScoringSecs from '../images/new-scorecard-scoring-secs.png';
import newScorecardTemplates from '../images/new-scorecard-templates.png';
import orgStructure from '../images/org-structure.png';
import reportsAddFilters from '../images/reports-add-filters.png';
import reportsAggregate from '../images/reports-aggregate.png';
import reportsCharts from '../images/reports-charts.png';
import reportsColumnHeaders from '../images/reports-column-headers.png';
import reportsColumnOrder from '../images/reports-column-order.png';
import reportsGroupBy from '../images/reports-group-by.png';
import reportsNameAndDesc from '../images/reports-name-and-desc.png';
import reportsPreview from '../images/reports-preview.png';
import reportsSelectData from '../images/reports-select-data.png';
import reportsSort from '../images/reports-sort.png';
import reportsUsersWithAccess from '../images/reports-users-with-access.png';
import usersShow from '../images/users-show.png';

const guideMainInfo = {
  'Adding a Report': [
    { type: 'image', content: dashboardAddElement },
    {
      type: 'text',
      content:
        'Click the padlock button to edit the dashboard, then click the plus button to add a dashboard element.',
    },
    {
      type: 'text',
      content:
        'Drag the element to your desired position, and resize it by clicking and dragging the bottom right hand corner.',
    },
    {
      type: 'text',
      content:
        'Click the ellipses button in the top left of the element to give it a header, and select the report data you want to include in the element.',
    },
    {
      type: 'text',
      content: "Click 'Save Changes' when you are happy with it.",
    },
  ],
  'Adding Agents': [
    { type: 'image', content: addAgents },
    {
      type: 'text',
      content:
        'Agents are the people you will be monitoring and scoring. You can also add organisation information relating to the agents here (for example their managers, teams, departments etc, depending on your org structure).',
    },
  ],
  'Adding Basic Data': [
    {
      type: 'text',
      content:
        "Agents, Users, and Evaluations can be added using 'Imports' if you are out of your free trial period.",
    },
  ],
  'Adding Data Lists': [
    { type: 'image', content: addDataLists },
    {
      type: 'text',
      content:
        'You can add lists of single data items that you might want to reference later when creating scorecards.',
    },
    {
      type: 'text',
      content:
        "For example, if you are going to have a scorecard with an admin field called 'Refund Codes', you can create a data list that contains all the refund codes, and then add a drop down box to your scorecard that references your data list.",
    },
    {
      type: 'text',
      content:
        'This will mean that users can only select from preset options, rather than free-typing and potentially making errors.',
    },
    {
      type: 'text',
      content:
        "You don't need to create data lists for the columns in your Agents table. These lists are automatically available for you when you create a scorecard.",
    },
  ],
  'Adding Users': [
    { type: 'image', content: addUsers },
    {
      content:
        'A user is anyone you want to use your portal and have an account with QA Portal.',
      type: 'text',
    },
    {
      content:
        "You can link agents that you have created to users, allowing those users to view evaluations and stats relating to the agent's performance if they don't already have that permission.",
      type: 'text',
    },
    {
      content:
        'So you might create a link between a Manager user and the agents they manage. You might also create a link between an Agent user and their record in the agents table.',
      type: 'text',
    },
    { type: 'image', content: usersShow },
    {
      content:
        'To set custom permissions for a user, go to Main Menu > Users > then click on the user whose permissions you want to change.',
      type: 'text',
    },
  ],
  'Admin Sections': [
    {
      type: 'text',
      content:
        'Admin sections are there to record information relating to your evaluations that can be referenced later when creating reports.',
    },
    {
      type: 'text',
      content:
        "For example, you might add an admin section called 'Call Length', and later create a report that looks at the average quality scores for calls that are above / below / between certain call lengths.",
    },
    { type: 'image', content: newScorecardAdminSecs },
    {
      type: 'text',
      content:
        "The 'Control Type' is the way you want the data to be collected on your evaluations / the type of data you will be expecting.",
    },
    {
      type: 'text',
      content:
        "If you select 'Drop down' you will be prompted to select the data list from which users will be asked to select a value.",
    },
    {
      type: 'text',
      content:
        "Setting the section as 'Required' will require users to enter a value for the section before submitting the evaluation. (Checkboxes don't have a 'Required' option because a blank checkbox counts as a 'No' value. Choose a Yes / No drop down box if this better suits your needs.)",
    },
    {
      type: 'text',
      content:
        'Drag added sections up and down to change their position on the scorecard.',
    },
    {
      type: 'text',
      content:
        "Create admin sections that reference your org levels by selecting 'Drop down' as the Control Type, then choose the org level from the 'Drop Down Data'.",
    },
  ],
  'Basic Scorecard Data': [
    {
      type: 'image',
      content: newScorecardTemplates,
    },
    {
      type: 'text',
      content: 'Choose from a template or create your scorecard from scratch.',
    },
    {
      type: 'image',
      content: newScorecardInfo,
    },
    {
      type: 'text',
      content: 'Give your scorecard a name and (optional) description.',
    },
  ],
  'Built-In Reports': [
    { type: 'image', content: builtInReports },
    {
      type: 'text',
      content:
        'Explore our built-in reports for an in-depth performance analysis covering different metrics within your portal.',
    },
  ],
  Calibrations: [
    {
      type: 'text',
      content:
        'Invite other users to a calibration session where you all mark the same call and share your outcomes.',
    },
  ],
  'Choosing a Scorecard': [
    { type: 'image', content: evalChooseSc },
    { type: 'text', content: 'Select from your saved scorecards.' },
  ],
  Columns: [
    { type: 'image', content: dataGridColumns },
    {
      type: 'text',
      content:
        'Click a column to show / hide it. Drag columns to change their order.',
    },
  ],
  'Completing a Calibration Session': [
    { type: 'image', content: completedCalibration },
    {
      type: 'text',
      content:
        'Participants complete their evaluations by clicking the icon to the right of the scorecard name.',
    },
    {
      type: 'text',
      content:
        "Hosts can complete the benchmark evaluation (if applicable) by clicking the icon to the right of 'Benchmark' under the 'Type' header.",
    },
    {
      type: 'text',
      content:
        "'Lock' the calibration to prevent further submissions and to view the calibration results.",
    },
    {
      type: 'text',
      content:
        "'Share' the calibration to share the results with all participants.",
    },
    {
      type: 'text',
      content:
        "Once shared, participants can view each others submitted evaluations by clicking the icon to the right of a participant's name.",
    },
    { type: 'image', content: calibrationResults },
    {
      type: 'text',
      content:
        'The calibration results show overall variance across different metrics, with additional breakdowns of variance across individual sections, and the variance of individual participants.',
    },
  ],
  'Completing the Evaluation': [
    { type: 'image', content: evalFillSc },
    { type: 'text', content: 'Fill in the admin and scoring sections.' },
  ],
  'Creating a Calibration': [
    { type: 'image', content: calibration },
    {
      type: 'text',
      content:
        "Users must have the 'Calibrations - Change' permission to host a calibration.",
    },
    {
      type: 'text',
      content:
        "'Group Average' calibrations compare each participant's evaluation with the average scores from all participant's evaluations combined.",
    },
    {
      type: 'text',
      content:
        "'Benchmark' calibrations compare each participant's evaluation with a single benchmark evaluation that the host completes.",
    },
  ],
  'Creating Reports': [
    { type: 'image', content: reportsNameAndDesc },
    {
      type: 'text',
      content: 'Give your report a name and (optional) description.',
    },
    { type: 'image', content: reportsSelectData },
    {
      type: 'text',
      content: 'Select the data you will be using to compile your report.',
    },
    { type: 'image', content: reportsAddFilters },
    {
      type: 'text',
      content: 'Add filters to your selected data.',
    },
    { type: 'image', content: reportsAggregate },
    {
      type: 'text',
      content:
        'Choose columns to aggregate and the type of aggregation you want to apply.',
    },
    { type: 'image', content: reportsGroupBy },
    {
      type: 'text',
      content:
        'If you are aggregating data, you will also need to choose how you want the results to be grouped.',
    },
    { type: 'image', content: reportsSort },
    {
      type: 'text',
      content: 'Choose how you want the results to be sorted.',
    },
    { type: 'image', content: reportsColumnOrder },
    {
      type: 'text',
      content: "Change the order of your report's table columns.",
    },
    { type: 'image', content: reportsColumnHeaders },
    {
      type: 'text',
      content: "Rename any of the report's column headers.",
    },
    { type: 'image', content: reportsUsersWithAccess },
    {
      type: 'text',
      content: 'Add users that you would like to share your report with.',
    },
    { type: 'image', content: reportsCharts },
    {
      type: 'text',
      content: 'Add charts to your report.',
    },
    { type: 'image', content: reportsPreview },
    {
      type: 'text',
      content:
        "Click 'Generate Report Preview' to see what it will look like based on the settings you have selected. You can amend your settings and generate a new preview if you wish.",
    },
    {
      type: 'text',
      content:
        "Use the 'Print' button to print the report or save it as a PDF. Click 'Save' if you are happy with the report (you can always edit it later).",
    },
  ],
  'Creating an Organistation Structure': [
    { type: 'image', content: orgStructure },
    {
      type: 'text',
      content:
        'This is the management structure of your company. By default the levels are Agent, Manager, Team, and Department, but you can change this to anything you want.',
    },
  ],
  Dashboard: [
    { type: 'image', content: dashboard },
    {
      type: 'text',
      content:
        'You can create 3 dashboards. Dashboard 1 will automatically load when you are using a standard / large screen device. Dashboard 2 will automatically load when you are using a small screen device.',
    },
    {
      type: 'text',
      content:
        'We advise you to create your dashboard layouts with this in mind.',
    },
    {
      type: 'text',
      content: 'Take a look at our demo dashboards for ideas.',
    },
  ],
  Filtering: [
    { type: 'image', content: dataGridFilter },
    {
      type: 'text',
      content: 'Filter records by your chosen criteria.',
    },
    { type: 'image', content: dataGridFilterDelete },
    {
      type: 'text',
      content: 'Delete filters.',
    },
    { type: 'image', content: dataGridFilterSubFilter },
    {
      type: 'text',
      content: 'Add filter sub groups.',
    },
    { type: 'image', content: dataGridFilterSubFilterDelete },
    {
      type: 'text',
      content: 'Delete filter sub groups.',
    },
    {
      type: 'text',
      content:
        "Clicking the 'and' / 'or' button will change the filter condition.",
    },
  ],
  'Grid View': [
    { type: 'image', content: dataGridGrid },
    {
      type: 'text',
      content:
        "Clicking the 'Grid' or 'Table' icon in the top panel will switch between views.",
    },
  ],
  'Navigation Bar': [
    { type: 'image', content: navBar },
    {
      type: 'text',
      content:
        'When you are in your portal, the left hand side of the navigation bar displays your company logo, a button that opens the navigation menu, and your current location in the portal.',
    },
    {
      type: 'text',
      content:
        'The right hand side of the navigation bar displays your company name and a button that opens your account information.',
    },
  ],
  'Navigation Menu': [
    { type: 'image', content: navMenu },
    {
      type: 'text',
      content:
        'The navigation menu has links to the different areas of your portal. Your favourites are customisable.',
    },
  ],
  'Preview & Save': [
    {
      type: 'text',
      content:
        "Preview your scorecard before saving to make sure you're happy with it.",
    },
    { type: 'image', content: newScorecardPreview },
    {
      type: 'text',
      content:
        "Click 'Save Scorecard' to save it as completed, or select 'Save as Draft' or 'Save as Template' depending on your needs.",
    },
    {
      type: 'text',
      content:
        "The 'Print' button allows you to print the scorecard or save it in PDF format.",
    },
  ],
  Records: [
    { type: 'image', content: dataGridRecords },
    {
      type: 'text',
      content:
        'Choose how many records you want to view per page, and which page to view.',
    },
  ],
  'Saved Options': [
    { type: 'image', content: dataGridSaveOptions },
    {
      type: 'text',
      content:
        'Save your data grid settings to load the grid using the same criteria again later. You can always delete your saved options and / or use the default settings at any time.',
    },
  ],
  'Scorecard Options': [
    { type: 'image', content: newScorecardOptions },
    {
      type: 'text',
      content:
        "'Pass Mark' is optional. If you set one, the evaluation will pass if the quality score is equal to or higher than the amount you have set.",
    },
    {
      type: 'text',
      content:
        "'RAG' boundaries are optional and any combination of some or all can be used. The quality score must be equal to or higher than a boundary for the evaluation to achieve that RAG outcome.",
    },
    {
      type: 'text',
      content:
        "'Marking Choices' are the options that users will select when scoring a section. Default marking choices are 'Pass / Fail' or 'Yes / No'. You can also set custom marking choices. 'NA' will be included as a third option.",
    },
    {
      type: 'text',
      content:
        "The 'Organisation Name' and 'Logo' will appear at the top right of the scorecard if you provide them.",
    },
    {
      type: 'text',
      content:
        "Set the scorecard 'Status' to 'Active' for scorecards that can be used straight away. 'Inactive' scorecards can't be used for evaluations but they can be used for calibrations. You can change the status later.",
    },
  ],
  'Scoring Sections': [
    {
      type: 'text',
      content:
        "Scoring sections will be used to calculate the evaluation's quality score, grade, and RAG outcome (if you choose to include these).",
    },
    { type: 'image', content: newScorecardScoringSecs },
    {
      type: 'text',
      content:
        "Add a 'Category Title' if you wish to group your scoring sections by category. You can then report on category scores.",
    },
    {
      type: 'text',
      content:
        "The 'Section Title' should be brief. It can be elaborated on when adding a description for the section if you choose to do this.",
    },
    {
      type: 'text',
      content:
        "An optional 'Description' can be added to provide further context and / or aid users' understanding of how the section should be marked",
    },
    {
      type: 'text',
      content:
        'Your scoring sections can include any combination of the following scoring behaviours:',
    },
    {
      type: 'text',
      content:
        "'Weight': Weighted sections will produce a percentage that is calculated based on how many points you have assigned to them. For example if you have 2 sections worth 1 point and one fails, the percentage score will be 50%. However if the section that fails is worth 3 points and the section that passes is worth 1 point, the percentage score will be 25%.",
    },
    {
      type: 'text',
      content:
        "'RA' (Reduction Amount): This is a fixed amount by which the final score of the evaluation will be reduced if the section is failed.",
    },
    {
      type: 'text',
      content:
        "'CSM' (Call Score Maximum): If an agent fails a section with a CSM, the maximum quality score for the evaluation will be limited to the amount you have set.",
    },
    {
      type: 'text',
      content:
        'When producing quality scores, the percentage score from weighted sections is calculated first. If there are no weighted sections then the score will begin at 100. Then the RA amount is deducted if applicable. Then a check is made to see if there are any failed CSM sections and if the current subtotal is higher than the lowest failed CSM section amount, the score is reduced to that CSM amount. Fractional quality scores are rounded down.',
    },
    {
      type: 'text',
      content:
        'Additional comments boxes can be added at any position on your scorecard.',
    },
  ],
  Sorting: [
    { type: 'image', content: dataGridSort },
    {
      type: 'text',
      content:
        'Click the column(s) you want to sort by. Drag them to change sort priority. Click the arrows to change sort order.',
    },
  ],
  'Table View': [
    { type: 'image', content: dataGridTable },
    {
      type: 'text',
      content:
        "The 'Export' button will download a csv file with the data that is currently displayed.",
    },
    {
      type: 'text',
      content:
        "The 'Edit' button will allow you to edit / delete records directly from the data grid. Not all columns are editable from the data grid.",
    },
    {
      type: 'text',
      content: "The 'New' button will allow you to add a new record.",
    },
  ],
  'Using the Data Grid': [
    {
      type: 'text',
      content:
        "This is an example of the 'Agents' data grid where you can view your saved agents.",
    },
  ],
  'Viewing the Results': [
    { type: 'image', content: evalResults },
    {
      type: 'text',
      content:
        'Scroll down for a breakdown of the scores. Save your evaluation as completed or as draft.',
    },
  ],
};

export default guideMainInfo;
