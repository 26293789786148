import { getFilterCondition, generateUniqueRef } from '../../../utils/helpers';
import { useAlert } from 'react-alert';
import { useEffect, useState } from 'react';

import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import ConditionButton from './ConditionButton';
import Filter from './Filter';
import FlexContainer from '../../Elements/FlexContainer';

import './FilterGroup.css';

const FilterGroup = ({
  clearFilters,
  setClearFilters,
  columnTypes,
  disabled,
  disabledText,
  filterGroup,
  handleChangeParentCondition,
  hasConditionButton,
  isChangingCriteria,
  setExternalIsChangingCriteria,
  setIsChangingCriteria,
  isChildOfParentGroup,
  isParent,
  noOfFilters,
  onClearFilters,
  options,
  parentCondition,
  parentGroup,
  savedQueryFilter,
  selectFrom,
  setOnClearFilters,
  setParentGroup,
  uniqueRef,
  setNoOfFiltersTracker,
  setLatestQuery,
}) => {
  const alert = useAlert();

  const [condition, setCondition] = useState(getFilterCondition(filterGroup));
  const [group, setGroup] = useState(filterGroup);

  useEffect(() => {
    if (clearFilters || onClearFilters) {
      setCondition('and');
      setGroup({
        uniqueRef: 1,
        and: [],
      });

      if (clearFilters) setClearFilters(false);
      if (onClearFilters) setOnClearFilters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilters, onClearFilters]);

  // on condition update: do i need this
  useEffect(() => {
    if (!clearFilters && !onClearFilters) {
      setGroup((currentGroup) => {
        const currentCondition = getFilterCondition(currentGroup);

        return {
          uniqueRef,
          [condition]: currentGroup[currentCondition],
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  // on group update:
  useEffect(() => {
    if (isParent) setParentGroup(group);
    else
      setParentGroup((currentParentGroup) => {
        const parentCondition = getFilterCondition(currentParentGroup);
        const parentFilterObjectArray = currentParentGroup[parentCondition];

        return {
          uniqueRef: currentParentGroup.uniqueRef,
          [parentCondition]: parentFilterObjectArray.map((filterObject) => {
            if (filterObject.uniqueRef === uniqueRef) {
              return group;
            } else return filterObject;
          }),
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  useEffect(() => {
    if (isParent && savedQueryFilter) {
      setCondition(getFilterCondition(savedQueryFilter));
      setGroup(savedQueryFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedQueryFilter]);

  const handleAddGroup = () => {
    const groupUniqueRef = generateUniqueRef();
    const secUniqueRef1 = generateUniqueRef();
    const secUniqueRef2 = generateUniqueRef();

    setGroup((currentGroup) => {
      const groupArray = [...currentGroup[condition]];

      groupArray.push({
        uniqueRef: groupUniqueRef,
        and: [
          {
            uniqueRef: secUniqueRef1,
            clause: ['', '', ''],
          },
          {
            uniqueRef: secUniqueRef2,
            clause: ['', '', ''],
          },
        ],
      });

      return { uniqueRef, [condition]: groupArray };
    });

    setLatestQuery('');
  };

  const handleAddFilter = () => {
    const secRef = generateUniqueRef();

    setGroup((currentGroup) => {
      const groupArray = [...currentGroup[condition]];
      groupArray.push({
        uniqueRef: secRef,
        clause: ['', '', ''],
      });

      return { uniqueRef, [condition]: groupArray };
    });

    setLatestQuery('');
  };

  const handleChangeCondition = () =>
    setCondition((curr) => (curr === 'and' ? 'or' : 'and'));

  const handleDeleteGroup = () => {
    if (!isChildOfParentGroup && parentGroup[parentCondition].length <= 2) {
      alert.error(
        `The group containing this sub-group must have at least 2 conditions`,
        { timeout: 5000 }
      );

      return;
    } else {
      setParentGroup((currentParentGroup) => {
        const parentCondition = getFilterCondition(currentParentGroup);

        return {
          uniqueRef: currentParentGroup.uniqueRef,
          [parentCondition]: currentParentGroup[parentCondition].filter(
            (filterObject) => filterObject.uniqueRef !== uniqueRef
          ),
        };
      });

      setLatestQuery('');
    }
  };

  return (
    <div className='filter-group'>
      {!isParent ? (
        <div className='condition-button-container'>
          {hasConditionButton ? (
            <ConditionButton
              condition={parentCondition}
              disabled={disabled || isChangingCriteria}
              title={disabled ? disabledText : undefined}
              handleChangeCondition={handleChangeParentCondition}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      <FlexContainer
        classes={`filter-group-buttons ${
          disabled ? 'filter-group-buttons-disabled' : ''
        }`}
      >
        <ButtonIconOnly
          disabled={disabled}
          classes='add-filter-button'
          onClick={handleAddFilter}
          iconName='list-ul-single'
          title={disabled ? disabledText : undefined}
        />
        <label className='add-filter-label'>Add filter</label>
        <ButtonIconOnly
          disabled={noOfFilters === 0 || disabled}
          onClick={handleAddGroup}
          iconName='list-ul'
          title={disabled ? disabledText : undefined}
        />
        <label className='add-group-label'>Add sub-group</label>
      </FlexContainer>

      {!isParent ? (
        <>
          <ButtonIconOnly
            disabled={disabled}
            classes='delete-group-button options-delete'
            onClick={handleDeleteGroup}
            iconName='list-ul'
            subIconName='xmark'
            typeStyle='type-2'
            title={disabled ? disabledText : undefined}
          />
          <label className='delete-group-label'>Delete group</label>
        </>
      ) : (
        <></>
      )}

      <ul
        className={`filter-list${
          !isParent
            ? ' child-filter-list'
            : !group[getFilterCondition(group)].length
            ? ' parent-filter-list'
            : ''
        }`}
      >
        {group[getFilterCondition(group)].map((filterObject, index) => {
          if (Array.isArray(filterObject.clause)) {
            return (
              <Filter
                key={filterObject.uniqueRef}
                columnTypes={columnTypes}
                condition={condition}
                disabled={disabled}
                disabledText={disabledText}
                filter={filterObject}
                handleChangeCondition={handleChangeCondition}
                hasConditionButton={index !== 0}
                isChangingCriteria={isChangingCriteria}
                setIsChangingCriteria={setIsChangingCriteria}
                setExternalIsChangingCriteria={setExternalIsChangingCriteria}
                isChildOfParentGroup={isParent}
                options={options}
                parentCondition={condition}
                parentGroup={group}
                selectFrom={selectFrom}
                setParentGroup={setGroup}
                uniqueRef={filterObject.uniqueRef}
                setNoOfFiltersTracker={setNoOfFiltersTracker}
                setLatestQuery={setLatestQuery}
              />
            );
          } else {
            return (
              <FilterGroup
                key={filterObject.uniqueRef}
                columnTypes={columnTypes}
                disabled={disabled}
                disabledText={disabledText}
                filterGroup={filterObject}
                handleChangeParentCondition={handleChangeCondition}
                hasConditionButton={index !== 0}
                isChangingCriteria={isChangingCriteria}
                setIsChangingCriteria={setIsChangingCriteria}
                setExternalIsChangingCriteria={setExternalIsChangingCriteria}
                isChildOfParentGroup={isParent}
                options={options}
                parentCondition={condition}
                parentGroup={group}
                selectFrom={selectFrom}
                setParentGroup={setGroup}
                uniqueRef={filterObject.uniqueRef}
                setNoOfFiltersTracker={setNoOfFiltersTracker}
                setLatestQuery={setLatestQuery}
              />
            );
          }
        })}
      </ul>
    </div>
  );
};

export default FilterGroup;
