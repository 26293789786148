export const scrollToBottom = (elementToScroll) => {
  elementToScroll.current.scrollTo({
    top: elementToScroll.current.scrollHeight,
    behavior: 'smooth',
  });
};

export const scrollToTop = (elementToScroll) => {
  elementToScroll.current.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
