import { useAlert } from 'react-alert';
import { useState } from 'react';
import { validatePresenceRequired } from '../../utils/helpers';

import BulletPoint from '../Elements/BulletPoint';
import Divider from '../Elements/Divider';
import emailjs from '@emailjs/browser';
import FlexContainer from '../Elements/FlexContainer';
import HeaderPrimary from '../Elements/HeaderPrimary';
import HeaderSecondary from '../Elements/HeaderSecondary';
import Icon from '../Elements/Icon';
import Text from '../Elements/Text';

import './Contact.css';

const Contact = () => {
  const alert = useAlert();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);

    const invalid = [];

    const nameInvalid = validatePresenceRequired(e.target.user_name.value);
    const emailInvalid = validatePresenceRequired(e.target.user_email.value);
    const messageInvalid = validatePresenceRequired(e.target.message.value);

    if (nameInvalid) invalid.push(nameInvalid);
    if (emailInvalid) invalid.push(emailInvalid);
    if (messageInvalid) invalid.push(messageInvalid);

    if (invalid.length) {
      alert.error(
        <div>
          <p>Unable to send please check the following:</p>
          <br />
          <ul>
            {[
              { validation: nameInvalid, msg: ' Your Name' },
              { validation: emailInvalid, msg: ' Your Email' },
              { validation: messageInvalid, msg: ' Message' },
            ]
              .filter(({ validation }) => validation)
              .map(({ msg }, index) => {
                return (
                  <li key={index}>
                    <BulletPoint />

                    <span>{msg}</span>
                  </li>
                );
              })}
          </ul>
        </div>,
        { timeout: 10000 }
      );

      setIsSubmitting(false);

      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setIsSubmitting(false);

          alert.success('Thank you for your message');
        },
        (error) => {
          alert.error(error, { timeout: 10000 });

          setIsSubmitting(false);
        }
      );

    // Clears the form after sending the email
    e.target.reset();
  };

  return (
    <div className='contact-screen home-screen'>
      <HeaderPrimary margin='30px auto 30px auto' text='Contact' />

      <FlexContainer flexWrap='wrap'>
        <Icon
          style={{
            height: '16px',
            marginRight: '10px',
            minWidth: '16px',
            width: '16px',
          }}
          iconName='envelope'
        />

        <HeaderSecondary marginRight='5px' text='Email:' />

        <a href='mailto: info@qaportal.co.uk'>info@qaportal.co.uk</a>
      </FlexContainer>

      <Divider />

      <FlexContainer>
        <Icon
          style={{
            height: '16px',
            marginRight: '10px',
            minWidth: '16px',
            width: '16px',
          }}
          iconName='clipboard'
        />
        <span>
          <Text classes='contact-screen-header-text' text='Send a message ' />

          <Text text='using this form:' />
        </span>
      </FlexContainer>

      <form className='contact-screen-form' onSubmit={sendEmail}>
        <label htmlFor='contact-screen-name-input'>Your Name</label>
        <input
          className='contact-screen-input'
          id='contact-screen-name-input'
          type='text'
          name='user_name'
        />

        <label htmlFor='contact-screen-email-input'>Your Email</label>
        <input
          className='contact-screen-input'
          id='contact-screen-email-input'
          type='email'
          name='user_email'
        />

        <label htmlFor='contact-screen-msg-input'>Message</label>
        <textarea
          className='contact-screen-textarea'
          id='contact-screen-msg-input'
          name='message'
        />

        <div className='contact-screen-submit-input-and-icon'>
          <Icon className='contact-screen-submit-icon' iconName='plane' />

          <input
            className='contact-screen-submit-input'
            type='submit'
            value={isSubmitting ? 'Sending' : 'Send'}
            disabled={isSubmitting}
          />
        </div>
      </form>

      <Divider />

      <FlexContainer flexWrap='wrap' marginBottom='160px'>
        <Icon
          style={{
            height: '16px',
            marginRight: '10px',
            minWidth: '16px',
            width: '16px',
          }}
          iconName='phone'
        />

        <HeaderSecondary marginRight='5px' text='Whatsapp / Text / Call:' />

        <Text text='07549038063' />
      </FlexContainer>

      {/* <Divider />

      <FlexContainer>
        <Icon
          style={{
            height: '16px',
            marginRight: '10px',
            minWidth: '16px',
            width: '16px',
          }}
          iconName='question'
        />

        <span>
          <Text text='Take a look at our ' />

          <Text
            classes='contact-screen-header-text'
            text='frequently asked questions '
          />

          <Text text='for common portal queries:' />
        </span>
      </FlexContainer>

      <FlexContainer
        alignSelf='center'
        justifyContent='space-evenly'
        margin=' 30px 0 180px 0'
        maxWidth='500px'
        width='100%'
      >
        <Link to='/faq'>
          <div>FAQ's</div>
        </Link>
      </FlexContainer> */}
    </div>
  );
};

export default Contact;
