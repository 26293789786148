import DataGrid from '../DataGrid/DataGrid';
import {
  scorecardsAvailableColumns,
  scorecardsDefaultColumns,
  scorecardsRequiredFields,
  scorecardsUnEditableColumns,
  scorecardsValidationColumns,
} from '../columns';
import apiDataService from '../../../api/scorecards';

const ScorecardsAll = ({ demo }) => {
  const getAvailableColumns = (columns = {}) => {
    return { ...scorecardsAvailableColumns, ...columns };
  };

  const defaultOptions = {
    customColumns: null,
    limit: 10,
    page: 1,
    query: null,
    sortBy: null,
    view: 'grid',
  };

  const selectFrom = {
    status: [
      ['active', 'Active'],
      ['inactive', 'Inactive'],
    ],
  };

  // default page / limit / view / sort

  return (
    <DataGrid
      apiDataService={apiDataService}
      defaultColumns={scorecardsDefaultColumns}
      defaultOptions={defaultOptions}
      demo={demo}
      getAvailableColumns={getAvailableColumns}
      gridName='scorecards'
      iconName='scorecards'
      newScreenRoute='new'
      requiredFields={scorecardsRequiredFields}
      selectFrom={selectFrom}
      showRecordProp='vcid'
      unEditableColumns={scorecardsUnEditableColumns}
      validationColumns={scorecardsValidationColumns}
    />
  );
};

export default ScorecardsAll;
