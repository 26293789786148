import MainOnly from '../MainAndOptions/MainOnly';
import AgentsNewContent from './AgentsNewContent';

const AgentsNew = ({ demo }) => {
  return (
    <MainOnly>
      <AgentsNewContent demo={demo} />
    </MainOnly>
  );
};

export default AgentsNew;
