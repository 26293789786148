import {
  amendedReportRows,
  extractColumnData,
  generateReportDataParams,
  mutateDataNew,
  translateEvaluationsToKey,
} from '../../../utils/reports';
import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserPortal } from '../../../contexts';
import { validatePresenceRequired } from '../../../utils/helpers';

import AddUsers from './AddUsers';
import apiDataServiceUsers from '../../../api/users';
import apiDataService from '../../../api/reports';
import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import ExpandableSection from '../../Elements/ExpandableSection';
import FlexContainer from '../../Elements/FlexContainer';
import GridOption from '../DataGrid/GridContent/GridOption';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InputStandard from '../../Elements/InputStandard';
import PanelsControlBar from '../../Elements/PanelsControlBar';
import PanelsSection from '../../Elements/PanelsSection';
import portalConfirmAlert from '../../../utils/portalConfirmAlert';
import ReportAddCharts from './ReportAddCharts';
import ReportAggregate from './ReportAggregate';
import ReportColumnOrder from './ReportColumnOrder';
import ReportCustomColumnHeaders from './ReportCustomColumnHeaders';
import ReportFilter from './ReportFilter';
import ReportGrouping from './ReportGrouping';
import reportsApi from '../../../api/reports';
import ReportSort from './ReportSort';
import SelectData from './SelectData';

import './NewReport.css';

const NewReport = ({
  demo,
  description,
  handlePrint,
  hasChangePermission,
  hasReportRows,
  isEditing,
  isLoadingReportData,
  isShowScreen,
  name,
  savedReport,
  setDescription,
  setIsEditing,
  setIsLoadingReportData,
  setName,
  setReportData,
  id,
}) => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { getAccessTokenSilently } = useAuth0();

  const [addedUsers, setAddedUsers] = useState(
    isShowScreen
      ? []
      : [
          {
            id: demo ? 1 : userFromDb.id,
            username: demo ? 'QAPortalP' : userFromDb.username,
          },
        ]
  );

  const [users, setUsers] = useState([]);

  const [filterColumns, setFilterColumns] = useState({});
  const [filterColumnTypes, setFilterColumnTypes] = useState({});

  const [selectedReportData, setSelectedReportData] = useState(
    savedReport?.report_data?.selectedReportData || {}
  );
  const [query, setQuery] = useState(savedReport?.report_data?.query);
  const [grouping, setGrouping] = useState(
    savedReport?.report_data?.grouping || {}
  );
  const [aggregate, setAggregate] = useState(
    savedReport?.report_data?.aggregate || []
  );
  const [sort, setSort] = useState(savedReport?.report_data?.sort || []);
  const [columnOrder, setColumnOrder] = useState(
    savedReport?.report_data?.columnOrder || []
  );
  const [customColumnHeaders, setCustomColumnHeaders] = useState(
    savedReport?.report_data?.customColumnHeaders || {}
  );

  const [charts, setCharts] = useState(savedReport?.report_data?.charts || {});

  const saveButtonRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showInvalid, setShowInvalid] = useState(false);

  const [hideEditReportSections, setHideEditReportSections] = useState(false);

  const [externalIsChangingCriteria, setExternalIsChangingCriteria] =
    useState();

  const [showGridOption, setShowGridOption] = useState('');

  const handleSetSelectedReportData = (section, sectionUniqueData) => {
    if (query?.includes(sectionUniqueData))
      return alert.show(
        <>
          <>'{section.title}' is being used in the report filter.</>
          <br></br>
          <br></br>
          <>Please remove from the report filter if you wish to de-select.</>
        </>,
        { timeout: 10000 }
      );

    setSelectedReportData((curr) => {
      const copiedCurr = { ...curr };

      if (copiedCurr[sectionUniqueData]) delete copiedCurr[sectionUniqueData];
      else copiedCurr[sectionUniqueData] = section;

      if (!Object.keys(copiedCurr).length) {
        setFilterColumns({});
        setFilterColumnTypes({});
      }

      return copiedCurr;
    });

    if (grouping[sectionUniqueData])
      setGrouping((curr) => {
        const copiedCurr = { ...curr };

        delete copiedCurr[sectionUniqueData];

        return copiedCurr;
      });

    const start = `${section.source_table}_${section.control}`;
    const end = section.title;

    setAggregate((curr) => {
      const copiedCurr = [...curr];

      const returnItem = copiedCurr.filter((aggItem) => {
        return (
          !aggItem.slice(0, start.length) !== start ||
          !aggItem.slice(-end.length) !== end
        );
      });

      return returnItem;
    });
  };

  const handleDeselectReportData = () => {
    if (query)
      return alert.show(
        <>
          <>Some of the selected data is being used in the report filter.</>
          <br></br>
          <br></br>
          <>Please remove from the report filter if you wish to de-select.</>
        </>,
        { timeout: 10000 }
      );

    setSelectedReportData({});
    setGrouping({});
    setAggregate([]);
    setFilterColumns({});
    setFilterColumnTypes({});
  };

  const handleChangeGrouping = (itemLabel, isDateField, dateFrequency) => {
    setGrouping((curr) => {
      const copiedCurr = { ...curr };

      const source = itemLabel.slice(0, itemLabel.indexOf('_'));
      const afterSource = itemLabel.slice(source.length + 1);
      const control = afterSource.slice(0, afterSource.indexOf('_'));
      const section = afterSource.slice(control.length + 1);

      if (copiedCurr[itemLabel]) {
        if (dateFrequency) {
          copiedCurr[itemLabel].dateFrequency = dateFrequency;
          copiedCurr[
            itemLabel
          ].id = `${source}_${control}_group_${dateFrequency}_${section}`;
          copiedCurr[itemLabel].title = section;
        } else {
          delete copiedCurr[itemLabel];
        }
      } else {
        if (isDateField) {
          copiedCurr[itemLabel] = {
            dateFrequency: 'week',
            id: `${source}_${control}_group_week_${section}`,
            title: section,
          };
        } else {
          copiedCurr[itemLabel] = {
            id: `${source}_${control}_group_${section}`,
            title: section,
          };
        }
      }

      return copiedCurr;
    });
  };

  const handleChangeAggregate = (itemLabel, aggType, percentOf, outOf) => {
    const source = itemLabel.slice(0, itemLabel.indexOf('_'));
    const afterSource = itemLabel.slice(source.length + 1);
    const control = afterSource.slice(0, afterSource.indexOf('_'));
    const section = afterSource.slice(control.length + 1);

    const aggStr = `${source}_${control}_null_${aggType}${
      aggType === 'percent'
        ? `_${percentOf
            .sort((a, b) => a?.localeCompare?.(b))
            .join('x___x')}x____x${outOf
            .sort((a, b) => a?.localeCompare?.(b))
            .join('x___x')}x____x`
        : '_'
    }${section}`;

    if (aggType) {
      if (aggregate.includes(aggStr)) {
        return alert.show('This aggregation already exists');
      } else
        setAggregate((curr) => {
          const copiedCurr = [...curr];

          copiedCurr.push(aggStr);

          return copiedCurr;
        });
    } else {
      setAggregate((curr) => {
        const copiedCurr = [...curr];

        copiedCurr.splice(copiedCurr.indexOf(itemLabel), 1);

        return copiedCurr;
      });
    }
  };

  const handleReportNameChange = (event) => {
    setName(event.target.value);
  };

  const handleReportDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const getReqBody = () => {
    if (isShowScreen) {
      return {
        updates: [
          {
            description,
            name,
            report_data: {
              aggregate,
              charts,
              columnOrder,
              customColumnHeaders,
              filterColumns,
              filterColumnTypes,
              grouping,
              query,
              selectedReportData,
              sort,
            },
            users_with_access: addedUsers.map(({ id }) => id),
            id,
          },
        ],
        userId: demo ? 1 : userId,
      };
    } else
      return {
        reports: [
          {
            description,
            name,
            report_data: {
              aggregate,
              charts,
              columnOrder,
              customColumnHeaders,
              filterColumns,
              filterColumnTypes,
              grouping,
              query,
              selectedReportData,
              sort,
            },
            users_with_access: addedUsers.map(({ id }) => id),
            is_portal_report: false,
          },
        ],
        userId: demo ? 1 : userId,
      };
  };

  const handleSaveReport = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (isSaving || saveButtonRef?.current?.disabled) return;
    if (!saveButtonRef?.current?.disabled && saveButtonRef?.current)
      saveButtonRef.current.disabled = true;

    setIsSaving(true);
    setShowInvalid(true);

    const reqBody = getReqBody();

    try {
      if (name) {
        if (isShowScreen) {
          await apiDataService.update({
            portalId: demo ? 'demo' : portalId,
            reqBody,
            token: demo ? undefined : await getAccessTokenSilently(),
          });
        } else {
          await apiDataService.post({
            portalId: demo ? 'demo' : portalId,
            reqBody,
            token: demo ? undefined : await getAccessTokenSilently(),
          });
        }
      } else {
        alert.show('Please enter a report name', { timeout: 5000 });
      }

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;

      if (name) {
        navigate(`/${demo ? 'demo' : portalId}/reports`, {
          state: { isNavigating: true },
        });

        alert.success(
          isShowScreen
            ? 'Report saved'
            : `Report${reqBody?.reports?.length === 1 ? '' : 's'} added`
        );
      }
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;
    }
  };

  const handleDeleteReport = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    const confirmed = await portalConfirmAlert({
      message: `Are you sure you want to delete this report?`,
    });

    if (!confirmed) return;

    try {
      await apiDataService.gridDeleteMultiple({
        selectedRecordIds: [id],
        portalId: demo ? 'demo' : portalId,
        reqBody: { userId: demo ? 1 : userId },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      alert.success('Report Deleted');

      navigate(`/${demo ? 'demo' : portalId}/reports`, {
        state: { isNavigating: true },
      });
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const generateReport = async () => {
    setIsLoadingReportData(true);

    const { columnsForReports, params } = generateReportDataParams({
      aggregate,
      columnOrder,
      grouping,
      query,
      sort,
    });

    if (!params.length) return setIsLoadingReportData(false);

    try {
      const { reportRows } = await reportsApi.generateReport({
        params,
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setReportData({
        reportRows: amendedReportRows({ columnsForReports, reportRows }),
        chartDataRows: reportRows,
        columnsForReports: columnsForReports,
        charts,
        customColumnHeaders,
      });
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoadingReportData(false);
    }
  };

  const getUsers = async () => {
    try {
      const { users } = await apiDataServiceUsers.getAll({
        token: demo ? undefined : await getAccessTokenSilently(),
        userId: demo ? 1 : userId,
        portalId: demo ? 'demo' : portalId,
        params: [],
      });

      setUsers(users);

      if (isShowScreen && savedReport?.users_with_access?.length) {
        setAddedUsers(
          savedReport.users_with_access.map((userWithAccess) =>
            users.find((user) => user.id === userWithAccess)
          )
        );
      }
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  useEffect(() => {
    getUsers();

    if (isShowScreen) {
      setHideEditReportSections(true);

      generateReport();
    }

    return () => {
      setUsers();
      setAddedUsers();
      setReportData();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCustomColumnHeaders((curr) => {
      const newHeaders = {};

      columnOrder.forEach((colObj) => {
        if (curr[colObj.id]) newHeaders[colObj.id] = curr[colObj.id];
      });

      return newHeaders;
    });
  }, [columnOrder]);

  useEffect(() => {
    const groupingCols = Object.values(grouping);

    const aggCols = aggregate.map((agg) => {
      const {
        aggOn,
        column,
        outOf,
        percentOf,
        control,
        table: source,
      } = extractColumnData(agg);

      return {
        title: `${column} (${aggOn.toUpperCase()})`,
        id: agg,
        outOf: outOf?.map((data) =>
          mutateDataNew({ aggOn, data, control, source, column })
        ),
        percentOf: percentOf?.map((data) =>
          mutateDataNew({ aggOn, data, control, source, column })
        ),
      };
    });

    let reportColumns = [];

    if (groupingCols.length || aggCols.length) {
      reportColumns = [...groupingCols, ...aggCols];
    } else {
      reportColumns = Object.values(selectedReportData);
    }

    if (!reportColumns.length) {
      setSort([]);
      setColumnOrder([]);
      return;
    }

    setSort((curr) => {
      const newCurr = curr.filter((sortItem) => {
        return reportColumns.some(
          (reportData) => reportData.id.replace('_', '_sort_') === sortItem.id
        );
      });

      reportColumns.forEach((reportData) => {
        if (
          !sort.some(
            (sortItem) => reportData.id.replace('_', '_sort_') === sortItem.id
          )
        ) {
          newCurr.push({
            id: reportData.id.replace('_', '_sort_'),
            direction: '',
            order: null,
            toggled: false,
            value: reportData.title,
            filterColumnTitle: reportData.id,
          });
        }
      });

      return newCurr;
    });

    setColumnOrder((curr) => {
      const newCurr = curr.filter((columnItem) => {
        return reportColumns.some(
          (reportData) => reportData.id === columnItem.id
        );
      });

      reportColumns.forEach((reportData) => {
        if (
          !columnOrder.some((columnItem) => reportData.id === columnItem.id)
        ) {
          newCurr.push({
            id: reportData.id,
            order: null,
            toggled: true,
            value: reportData.title,
            percentOf: reportData.percentOf,
            outOf: reportData.outOf,
            // altTitle: amendColumnOrder([{ id: reportData.id }])[0],
          });
        }
      });
      return newCurr;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregate, grouping, selectedReportData]);

  return (
    <div className='new-report'>
      <PanelsControlBar>
        <ButtonStandardNoFill
          disabled={!hasReportRows}
          margin='5px'
          iconName='print'
          text='Print'
          onClick={handlePrint}
        />

        {hasChangePermission ? (
          <>
            {isShowScreen ? (
              <>
                {isEditing && (
                  <>
                    <ButtonStandardNoFill
                      margin='5px'
                      buttonRef={saveButtonRef}
                      disabled={isSaving}
                      iconName='save-solid'
                      onClick={handleSaveReport}
                      text='Save Changes'
                      typeStyle='type-4'
                    />

                    <ButtonStandardNoFill
                      margin='5px'
                      disabled={isSaving}
                      iconName='trash'
                      onClick={handleDeleteReport}
                      text='Delete Report'
                      typeStyle='type-5'
                    />
                  </>
                )}

                <ButtonStandardNoFill
                  margin='5px'
                  disabled={isSaving}
                  iconName='edit-solid'
                  onClick={() => setIsEditing((curr) => !curr)}
                  text='Edit'
                  typeStyle={isEditing ? 'type-3' : 'type-1'}
                />
              </>
            ) : (
              <ButtonStandardNoFill
                margin='5px'
                buttonRef={saveButtonRef}
                disabled={isSaving}
                iconName='save-solid'
                onClick={handleSaveReport}
                text={isShowScreen ? 'Save changes' : 'Save'}
                typeStyle='type-4'
              />
            )}
          </>
        ) : (
          <></>
        )}
      </PanelsControlBar>

      {(!isShowScreen && !hideEditReportSections) || isEditing ? (
        <>
          <PanelsSection
            flexWrap='wrap'
            flexDirection='row'
            justifyContent='space-evenly'
          >
            <InputStandard
              classes='scorecard-name-input'
              id='report-name'
              invalid={name ? null : [validatePresenceRequired(name, 'Name')]}
              isRequired
              labelText='Report Name'
              name='report-name'
              onChange={handleReportNameChange}
              placeholder='< Report Name >'
              showInvalid={showInvalid}
              value={name}
            />

            <InputStandard
              classes='scorecard-description-input'
              id='report-description'
              labelText='Description'
              name='report-description'
              onChange={handleReportDescriptionChange}
              placeholder='< Description >'
              value={description}
            />
          </PanelsSection>

          <PanelsSection>
            <FlexContainer flexWrap='wrap'>
              <GridOption
                headerText='Select Data'
                showGridOption={showGridOption}
                setShowGridOption={setShowGridOption}
                gridOption='selectDataPanel'
              />

              <GridOption
                disabled={!Object.keys(selectedReportData).length}
                headerText='Add Filters'
                showGridOption={showGridOption}
                setShowGridOption={setShowGridOption}
                gridOption='addFiltersPanel'
              />

              <GridOption
                disabled={!Object.keys(selectedReportData).length}
                headerText='Aggregate'
                showGridOption={showGridOption}
                setShowGridOption={setShowGridOption}
                gridOption='aggregatePanel'
              />

              <GridOption
                disabled={!Object.keys(selectedReportData).length}
                headerText='Group By'
                showGridOption={showGridOption}
                setShowGridOption={setShowGridOption}
                gridOption='groupByPanel'
              />

              <GridOption
                disabled={
                  !Object.keys(selectedReportData).length || !sort.length
                }
                headerText='Sort'
                showGridOption={showGridOption}
                setShowGridOption={setShowGridOption}
                gridOption='sortPanel'
              />

              <GridOption
                disabled={
                  !Object.keys(selectedReportData).length || !columnOrder.length
                }
                headerText='Column Order'
                showGridOption={showGridOption}
                setShowGridOption={setShowGridOption}
                gridOption='columnOrderPanel'
              />

              <GridOption
                disabled={
                  !Object.keys(selectedReportData).length || !columnOrder.length
                }
                headerText='Custom Column Headers'
                showGridOption={showGridOption}
                setShowGridOption={setShowGridOption}
                gridOption='customColumnHeadersPanel'
              />
            </FlexContainer>

            <ExpandableSection isOpen={showGridOption === 'selectDataPanel'}>
              <SelectData
                demo={demo}
                handleSetSelectedReportData={handleSetSelectedReportData}
                handleDeselectReportData={handleDeselectReportData}
                selectedReportData={selectedReportData}
              />
            </ExpandableSection>

            <ExpandableSection isOpen={showGridOption === 'addFiltersPanel'}>
              <ReportFilter
                filterColumns={filterColumns}
                filterColumnTypes={filterColumnTypes}
                query={query}
                selectedReportData={selectedReportData}
                setExternalIsChangingCriteria={setExternalIsChangingCriteria}
                setFilterColumnTypes={setFilterColumnTypes}
                setFilterColumns={setFilterColumns}
                setQuery={setQuery}
                translateEvaluations={translateEvaluationsToKey}
              />
            </ExpandableSection>

            <ExpandableSection isOpen={showGridOption === 'aggregatePanel'}>
              <ReportAggregate
                aggregate={aggregate}
                demo={demo}
                handleChangeAggregate={handleChangeAggregate}
                selectedReportData={selectedReportData}
                columnOrder={columnOrder}
              />
            </ExpandableSection>

            <ExpandableSection isOpen={showGridOption === 'groupByPanel'}>
              <ReportGrouping
                grouping={grouping}
                handleChangeGrouping={handleChangeGrouping}
                selectedReportData={selectedReportData}
              />
            </ExpandableSection>

            <ExpandableSection isOpen={showGridOption === 'sortPanel'}>
              <ReportSort sort={sort} setSort={setSort} />
            </ExpandableSection>

            <ExpandableSection isOpen={showGridOption === 'columnOrderPanel'}>
              <ReportColumnOrder
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
              />
            </ExpandableSection>

            <ExpandableSection
              isOpen={showGridOption === 'customColumnHeadersPanel'}
            >
              <ReportCustomColumnHeaders
                columnOrder={columnOrder}
                customColumnHeaders={customColumnHeaders}
                setCustomColumnHeaders={setCustomColumnHeaders}
              />
            </ExpandableSection>
          </PanelsSection>

          <AddUsers
            addedUsers={addedUsers}
            demo={demo}
            isShowScreen={isShowScreen}
            setAddedUsers={setAddedUsers}
            users={users}
          />

          <FlexContainer marginBottom='15px'>
            <HeaderSecondary textAlign='center' text='Charts' />

            <ButtonIconOnly
              iconName='plus'
              margin='0 10px'
              onClick={() => {
                setCharts((curr) => ({
                  ...curr,
                  [`${Math.floor(Math.random() * 100000000000)}${Date.now()}`]:
                    {
                      type: '',
                      size: 'medium',
                      alternateInterval: '2',
                      xLabelHandling: 'hideXOverlap',
                      colours: {},
                      showXAxisLabel: true,
                      showPieValues: true,
                      needleMin: 0,
                      needleMax: 100,
                      needleRed: 0,
                      needleAmber: 80,
                      needleGreen: 90,
                    },
                }));
              }}
              title='Add chart'
            />
          </FlexContainer>

          <ReportAddCharts
            charts={charts}
            setCharts={setCharts}
            columnOrder={columnOrder}
            customColumnHeaders={customColumnHeaders}
            hasGroupingOrAggregate={
              Object.keys(grouping).length || aggregate.length
            }
          />
        </>
      ) : (
        <></>
      )}

      {hasChangePermission ? (
        <ButtonStandardNoFill
          disabled={
            externalIsChangingCriteria ||
            !Object.keys(filterColumns).length ||
            isLoadingReportData
          }
          alignSelf='center'
          iconName='chart-pie'
          onClick={generateReport}
          marginTop='10px'
          text={isShowScreen ? 'Re-generate Report' : 'Generate Report Preview'}
        />
      ) : (
        <></>
      )}
    </div>

    // give a suggested report toHaveDescription, based on the users queries
  );
};

export default NewReport;
