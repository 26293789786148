import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { capitalise } from '../../../utils/helpers';
import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';

import party, { Color } from 'party-js';

import apiDataService from '../../../api/scorecards';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import ButtonWithOptions from '../../Elements/ButtonWithOptions';
import ChooseScorecardTemplate from './ChooseScorecardTemplate';
import CreateAdminSections from '../../CreateScorecard/CreateAdminSections/_CreateAdminSections';
import CreateScorecardOptions from '../../CreateScorecard/CreateScorecardOptions/_CreateScorecardOptions';
import CreateScoringSections from '../../CreateScorecard/CreateScoringSections/_CreateScoringSections';
import ExpandableSection from '../../Elements/ExpandableSection';
import FlexContainer from '../../Elements/FlexContainer';
import GridOption from '../DataGrid/GridContent/GridOption';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import Panels from '../../Elements/Panels';
import PanelsControlBar from '../../Elements/PanelsControlBar';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import portalConfirmAlert from '../../../utils/portalConfirmAlert';
import Print from '../../Elements/Print';
import PrintableScorecard from '../../ScoringAndFeedback/PrintableScorecard';
import Scorecard from '../../ScoringAndFeedback/Scorecard';
import ScorecardInformation from '../../CreateScorecard/ScorecardInformation';

import './ScorecardsNewContent.css';

const ScorecardsNewContent = ({
  demo,
  adminSections,
  bragAmber,
  bragBlack,
  bragGreen,
  bragInvalid,
  bragRed,
  categoryCount,
  categoryOnlyList,
  countEvaluationsId,
  currentlyEditingAdmin,
  currentlyEditingCat,
  currentlyEditingSec,
  ddLists,
  defaultOptions,
  fillScorecardWithTemplate,
  fromDrafts,
  fromDraftsId,
  fromTemplates,
  handleSetHasChanges,
  hasChanges,
  id,
  imageFile,
  isLoadingScorecard,
  isLoadingTemplates,
  isUsingDefault,
  logo,
  logoUrl,
  moduleStyles,
  orgName,
  passMark,
  passMarkInvalid,
  saveScorecard,
  saveType,
  scorecardDescription,
  scorecardName,
  scorecardNameInvalid,
  scorecardTemplates,
  scoringSections,
  sectionCount,
  sectionNeg,
  sectionPos,
  sectionSelect,
  sectionSelectInvalid,
  sectionSelectNeg,
  sectionSelectNegInvalid,
  sectionSelectPos,
  sectionSelectPosInvalid,
  setAdminSections,
  setBragAmber,
  setBragBlack,
  setBragGreen,
  setBragInvalid,
  setBragRed,
  setCategoryCount,
  setCurrentlyEditingAdmin,
  setCurrentlyEditingCat,
  setCurrentlyEditingSec,
  setDefaultOptions,
  setImageFile,
  setIsUsingDefault,
  setLogoUrl,
  setOrgName,
  setPassMark,
  setScorecardDescription,
  setScorecardName,
  setScoringSections,
  setSectionCount,
  setSectionSelect,
  setSectionSelectNeg,
  setSectionSelectPos,
  setStatus,
  showInvalid,
  showScreen,
  status,
  statusInvalid,
  updateScorecard,
  vcid,
}) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const edgeMargins = { left: '10px', right: '10px' };

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const hasScorecardTemplatesChangePermission =
    userFromDb.permissions?.includes('scorecard_templates.change');

  const hasScorecardsChangePermission =
    userFromDb.permissions?.includes('scorecards.change');

  const hasChangePermission =
    demo ||
    (hasScorecardTemplatesChangePermission && fromTemplates) ||
    (hasScorecardsChangePermission && !fromTemplates) ||
    ((hasScorecardTemplatesChangePermission || hasScorecardsChangePermission) &&
      fromDrafts);

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const alert = useAlert();

  const [isSaving, setIsSaving] = useState(false);
  const saveButtonRef = useRef(null);

  const handleDeleteScorecard = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    const confirmed = await portalConfirmAlert({
      message: `Are you sure you want to delete '${scorecardName}'?`,
    });

    if (!confirmed) return;

    try {
      await apiDataService.gridDeleteMultiple({
        selectedRecordIds: [id || fromDraftsId],
        recordData: [
          {
            count_evaluations_id: countEvaluationsId || fromDraftsId,
            id: id || fromDraftsId,
            vcid: vcid || fromDraftsId,
          },
        ],
        portalId: demo ? 'demo' : portalId,
        reqBody: { userId: demo ? 1 : userId, fromTemplates },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      alert.success('Scorecard Deleted');

      // should it navigate back to scorecards rather than templates or drafts?
      navigate(
        `/${demo ? 'demo' : portalId}/${
          fromDrafts
            ? 'drafts'
            : `scorecard${fromTemplates ? '-template' : ''}s`
        }`,
        {
          state: { isNavigating: true },
        }
      );
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const handleSaveScorecard = async (type) => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (isSaving || saveButtonRef?.current?.disabled) return;

    if (!saveButtonRef?.current?.disabled && saveButtonRef?.current)
      saveButtonRef.current.disabled = true;

    setIsSaving(true);
    try {
      const wasSuccessful = showScreen
        ? await updateScorecard(type)
        : await saveScorecard(type);

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;

      if (wasSuccessful) {
        let url = `/${demo ? 'demo' : portalId}`;

        if (type === 'scorecard') url += '/scorecards';
        else if (type === 'draft') url += '/drafts';
        else if (type === 'template') url += '/scorecard-templates';

        const green = new Color(12, 76, 20);

        alert.success(`${capitalise(type)} ${showScreen ? 'saved' : 'added'}`);
        party.sparkles(
          document.getElementById('__react-alert__').querySelector('span'),
          { color: green }
        );

        navigate(url, {
          state: { isNavigating: true },
        });
      }
    } catch (error) {
      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;

      console.error(error);
    }
  };

  const [selectedTemplateUniqueId, setSelectedTemplateUniqueId] =
    useState('public11');

  const handleChangeSelectedTemplate = async (type, id, vcid) => {
    const uniqueId = `${type}${id}`;

    if (uniqueId === selectedTemplateUniqueId) return;
    else {
      setSelectedTemplateUniqueId(uniqueId);

      await fillScorecardWithTemplate(type, vcid);
    }
  };

  const [showAdminSections, setShowAdminSections] = useState('');
  const [showScoringSections, setShowScoringSections] = useState('');
  const [showScorecardOptions, setShowScorecardOptions] = useState('');
  const [buttonOptionsOpen, setButtonOptionsOpen] = useState(false);

  useEffect(() => {
    setBreadcrumbs(
      fromTemplates
        ? [
            [
              'Dashboard',
              'dashboard',
              '',
              `/${demo ? 'demo' : portalId}/dashboard`,
            ],
            [
              'Scorecard Templates',
              'templates',
              '',
              `/${demo ? 'demo' : portalId}/scorecard-templates`,
            ],
            showScreen
              ? [scorecardName || 'Template', 'magnifying-glass']
              : ['New', 'plus'],
          ]
        : fromDrafts
        ? [
            [
              'Dashboard',
              'dashboard',
              '',
              `/${demo ? 'demo' : portalId}/dashboard`,
            ],
            ['Drafts', 'draft', '', `/${demo ? 'demo' : portalId}/drafts`],
            [
              'Scorecards',
              'scorecards',
              '',
              `/${demo ? 'demo' : portalId}/scorecards`,
            ],
            ['New', 'plus'],
          ]
        : [
            [
              'Dashboard',
              'dashboard',
              '',
              `/${demo ? 'demo' : portalId}/dashboard`,
            ],
            [
              'Scorecards',
              'scorecards',
              '',
              `/${demo ? 'demo' : portalId}/scorecards`,
            ],
            showScreen
              ? [scorecardName || 'Scorecard', 'magnifying-glass']
              : ['New', 'plus'],
          ]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scorecardName, showScreen, fromTemplates]);

  useEffect(() => {
    setSelectedTemplateUniqueId('public11');
  }, [fromDrafts, fromTemplates]);

  // have an option to create a new evaluation using this scorecard
  // and link to stats relating to scorecard

  return (
    <Panels>
      <PanelsControlBar buttonOptionsOpen={buttonOptionsOpen}>
        <ButtonStandardNoFill
          margin='auto 5px 5px 5px'
          iconName='print'
          text='Print'
          onClick={handlePrint}
        />

        {hasChangePermission ? (
          <>
            {showScreen ? (
              <>
                {fromTemplates && hasScorecardTemplatesChangePermission ? (
                  <>
                    <ButtonStandardNoFill
                      margin='5px'
                      iconName='trash'
                      text='Delete Template'
                      typeStyle='type-5'
                      disabled={isLoadingScorecard || isSaving}
                      onClick={handleDeleteScorecard}
                    />

                    <ButtonStandardNoFill
                      margin='5px'
                      iconName='save-solid'
                      text='Save Changes'
                      typeStyle='type-4'
                      disabled={isLoadingScorecard || isSaving || !hasChanges}
                      onClick={() =>
                        handleSaveScorecard(
                          fromTemplates ? 'template' : 'scorecard'
                        )
                      }
                      buttonRef={saveButtonRef}
                    />
                  </>
                ) : (
                  <></>
                )}

                {!fromTemplates && hasScorecardsChangePermission ? (
                  <>
                    <ButtonStandardNoFill
                      margin='5px'
                      iconName='trash'
                      text='Delete Scorecard'
                      typeStyle='type-5'
                      disabled={isLoadingScorecard || isSaving}
                      onClick={handleDeleteScorecard}
                    />

                    <ButtonStandardNoFill
                      margin='5px'
                      iconName='save-solid'
                      text='Save Changes'
                      typeStyle='type-4'
                      disabled={isLoadingScorecard || isSaving || !hasChanges}
                      onClick={() =>
                        handleSaveScorecard(
                          fromTemplates ? 'template' : 'scorecard'
                        )
                      }
                      buttonRef={saveButtonRef}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {fromDrafts ? (
                  <ButtonStandardNoFill
                    margin='5px'
                    iconName='trash'
                    text='Delete Scorecard'
                    typeStyle='type-5'
                    disabled={isLoadingScorecard || isSaving}
                    onClick={handleDeleteScorecard}
                  />
                ) : (
                  <></>
                )}

                <ButtonWithOptions
                  buttonData={
                    fromTemplates
                      ? [
                          {
                            disabled:
                              isLoadingScorecard ||
                              isSaving ||
                              !hasScorecardTemplatesChangePermission,
                            iconName: 'save-solid',
                            onClick: () => handleSaveScorecard('template'),
                            text: 'Save Template',
                            // buttonRef: saveButtonRef,
                            typeStyle: 'type-4',
                          },
                          {
                            disabled: isLoadingScorecard || isSaving,
                            iconName: 'draft',
                            onClick: () => handleSaveScorecard('draft'),
                            text: 'Save as Draft',
                            // buttonRef: saveButtonRef,
                          },
                          {
                            disabled:
                              isLoadingScorecard ||
                              isSaving ||
                              !hasScorecardsChangePermission,
                            iconName: 'scorecards',
                            onClick: () => handleSaveScorecard('scorecard'),
                            text: 'Save as Scorecard',
                            // buttonRef: saveButtonRef,
                          },
                        ]
                      : fromDrafts
                      ? [
                          {
                            disabled: isLoadingScorecard || isSaving,
                            iconName: 'draft',
                            onClick: () => handleSaveScorecard('draft'),
                            text: 'Save Draft',
                            // buttonRef: saveButtonRef,
                            typeStyle: 'type-4',
                          },
                          {
                            disabled:
                              isLoadingScorecard ||
                              isSaving ||
                              !hasScorecardsChangePermission,
                            iconName: 'save-solid',
                            onClick: () => handleSaveScorecard('scorecard'),
                            text: 'Save as Scorecard',
                            // buttonRef: saveButtonRef,
                          },
                          {
                            disabled:
                              isLoadingScorecard ||
                              isSaving ||
                              !hasScorecardTemplatesChangePermission,
                            iconName: 'templates',
                            onClick: () => handleSaveScorecard('template'),
                            text: 'Save as Template',
                            // buttonRef: saveButtonRef,
                          },
                        ]
                      : [
                          {
                            disabled:
                              isLoadingScorecard ||
                              isSaving ||
                              !hasScorecardsChangePermission,
                            iconName: 'save-solid',
                            onClick: () => handleSaveScorecard('scorecard'),
                            text: 'Save Scorecard',
                            // buttonRef: saveButtonRef,
                            typeStyle: 'type-4',
                          },
                          {
                            disabled: isLoadingScorecard || isSaving,
                            iconName: 'draft',
                            onClick: () => handleSaveScorecard('draft'),
                            text: 'Save as Draft',
                            // buttonRef: saveButtonRef,
                          },
                          {
                            disabled:
                              isLoadingScorecard ||
                              isSaving ||
                              !hasScorecardTemplatesChangePermission,
                            iconName: 'templates',
                            onClick: () => handleSaveScorecard('template'),
                            text: 'Save as Template',
                            // buttonRef: saveButtonRef,
                          },
                        ]
                  }
                  direction='up'
                  margin='5px'
                  optionsDisabled={isLoadingScorecard || isSaving}
                  onTriggerOptions={(openOrClosed) =>
                    setButtonOptionsOpen(openOrClosed)
                  }
                />
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </PanelsControlBar>

      <PanelsHeaderPrimary
        iconName={fromTemplates ? 'templates' : 'scorecards'}
        subIcon={showScreen ? undefined : 'plus'}
        text={
          showScreen
            ? scorecardName
            : `New ${fromTemplates ? 'Template' : 'Scorecard'}`
        }
      />

      {!showScreen && !fromDrafts && (
        <>
          <HeaderSecondary classes='scorecard-panels-header' text='Templates' />

          <PanelsSection>
            <ChooseScorecardTemplate
              handleChangeSelectedTemplate={handleChangeSelectedTemplate}
              isLoading={isLoadingTemplates}
              scorecardTemplates={scorecardTemplates}
              selectedTemplateUniqueId={selectedTemplateUniqueId}
            />
          </PanelsSection>
        </>
      )}

      <HeaderSecondary
        classes='scorecard-panels-header'
        text='Scorecard Info'
      />

      <PanelsSection
        flexWrap='wrap'
        flexDirection='row'
        justifyContent='space-evenly'
      >
        <ScorecardInformation
          hasChangePermission={hasChangePermission}
          scorecardName={scorecardName}
          setScorecardName={setScorecardName}
          scorecardDescription={scorecardDescription}
          setScorecardDescription={setScorecardDescription}
          scorecardNameInvalid={scorecardNameInvalid}
          handleSetHasChanges={handleSetHasChanges}
          showInvalid={showInvalid}
        />
      </PanelsSection>

      {!hasChangePermission && !adminSections.length ? (
        <></>
      ) : (
        <>
          <HeaderSecondary
            classes='scorecard-panels-header'
            text='Admin Sections'
          />

          <PanelsSection>
            <GridOption
              headerText=''
              showGridOption={showAdminSections}
              setShowGridOption={setShowAdminSections}
              gridOption='adminSections'
            />

            <ExpandableSection isOpen={showAdminSections === 'adminSections'}>
              <CreateAdminSections
                hasChangePermission={hasChangePermission}
                status='new'
                adminSections={adminSections}
                ddLists={ddLists}
                edgeMargins={edgeMargins}
                setAdminSections={setAdminSections}
                currentlyEditingAdmin={currentlyEditingAdmin}
                setCurrentlyEditingAdmin={setCurrentlyEditingAdmin}
                handleSetHasChanges={handleSetHasChanges}
              />
            </ExpandableSection>
          </PanelsSection>
        </>
      )}

      <HeaderSecondary
        classes='scorecard-panels-header'
        text='Scoring Sections'
      />

      <PanelsSection>
        <GridOption
          headerText=''
          showGridOption={showScoringSections}
          setShowGridOption={setShowScoringSections}
          gridOption='scoringSections'
        />

        <ExpandableSection isOpen={showScoringSections === 'scoringSections'}>
          <CreateScoringSections
            hasChangePermission={hasChangePermission}
            edgeMargins={edgeMargins}
            scoringSections={scoringSections}
            setScoringSections={setScoringSections}
            categoryCount={categoryCount}
            setCategoryCount={setCategoryCount}
            sectionCount={sectionCount}
            setSectionCount={setSectionCount}
            currentlyEditingCat={currentlyEditingCat}
            setCurrentlyEditingCat={setCurrentlyEditingCat}
            currentlyEditingSec={currentlyEditingSec}
            setCurrentlyEditingSec={setCurrentlyEditingSec}
            handleSetHasChanges={handleSetHasChanges}
          />
        </ExpandableSection>
      </PanelsSection>

      <HeaderSecondary classes='scorecard-panels-header' text='Options' />

      <PanelsSection>
        <GridOption
          headerText=''
          showGridOption={showScorecardOptions}
          setShowGridOption={setShowScorecardOptions}
          gridOption='scorecardOptions'
        />

        <ExpandableSection isOpen={showScorecardOptions === 'scorecardOptions'}>
          <CreateScorecardOptions
            fromDrafts={fromDrafts}
            hasChangePermission={hasChangePermission}
            defaultOptions={defaultOptions}
            demo={demo}
            edgeMargins={edgeMargins}
            fromTemplates={fromTemplates}
            isUsingDefault={isUsingDefault}
            setIsUsingDefault={setIsUsingDefault}
            passMark={passMark}
            setPassMark={setPassMark}
            bragBlack={bragBlack}
            setBragBlack={setBragBlack}
            bragRed={bragRed}
            setBragRed={setBragRed}
            bragAmber={bragAmber}
            setBragAmber={setBragAmber}
            bragGreen={bragGreen}
            setDefaultOptions={setDefaultOptions}
            setBragGreen={setBragGreen}
            sectionSelect={sectionSelect}
            setSectionSelect={setSectionSelect}
            sectionSelectPos={sectionSelectPos}
            setSectionSelectPos={setSectionSelectPos}
            sectionSelectNeg={sectionSelectNeg}
            setSectionSelectNeg={setSectionSelectNeg}
            orgName={orgName}
            setOrgName={setOrgName}
            logoUrl={logoUrl}
            setLogoUrl={setLogoUrl}
            imageFile={imageFile}
            setImageFile={setImageFile}
            status={status}
            setStatus={setStatus}
            saveType={saveType}
            setBragInvalid={setBragInvalid}
            bragInvalid={bragInvalid}
            sectionSelectInvalid={sectionSelectInvalid}
            sectionSelectNegInvalid={sectionSelectNegInvalid}
            sectionSelectPosInvalid={sectionSelectPosInvalid}
            statusInvalid={statusInvalid}
            passMarkInvalid={passMarkInvalid}
            showInvalid={showInvalid}
            handleSetHasChanges={handleSetHasChanges}
          />
        </ExpandableSection>
      </PanelsSection>

      <HeaderSecondary classes='scorecard-panels-header' text='Preview' />

      <FlexContainer classes='scorecard-container'>
        {isLoadingScorecard ? (
          <LoadingSpinner />
        ) : (
          <Scorecard
            demo={demo}
            scorecardName={scorecardName}
            adminSections={adminSections}
            scoringSections={scoringSections}
            passMark={passMark}
            bragBlack={bragBlack}
            bragRed={bragRed}
            bragAmber={bragAmber}
            bragGreen={bragGreen}
            sectionPos={sectionPos}
            sectionNeg={sectionNeg}
            orgName={orgName}
            categoryOnlyList={categoryOnlyList}
            isNew
            logo={logo}
            moduleStyles={moduleStyles}
            isEditingScorecard
          />
        )}
      </FlexContainer>

      <div style={{ display: 'none' }}>
        <Print
          content={
            <PrintableScorecard
              demo={demo}
              scorecardName={scorecardName}
              adminSections={adminSections}
              scoringSections={scoringSections}
              passMark={passMark}
              bragBlack={bragBlack}
              bragRed={bragRed}
              bragAmber={bragAmber}
              bragGreen={bragGreen}
              sectionPos={sectionPos}
              sectionNeg={sectionNeg}
              orgName={orgName}
              categoryOnlyList={categoryOnlyList}
              isNew
              logo={logo}
              moduleStyles={moduleStyles}
              isEditingScorecard
            />
          }
          ref={componentRef}
        />
      </div>
    </Panels>
  );
};

export default ScorecardsNewContent;
