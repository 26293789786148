import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import {
  capitalise,
  copyLines,
  generateUniqueRef,
  isBannedChars,
  lettersOnly,
  validateContainsALetter,
  validatePresenceRequired,
} from '../../../utils/helpers';
import { handleApiError } from '../../../utils/error-handling';
import { tiers } from '../../../utils/constants';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiDataServiceUsers from '../../../api/users';
import apiDataServiceAgents from '../../../api/agents';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import MainOnly from '../MainAndOptions/MainOnly';
import NewLine from '../../Elements/NewLine';
import NewScreenControlPanel from '../../Elements/NewScreenControlPanel';
import Panels from '../../Elements/Panels';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import PanelsSections from '../../Elements/PanelsSections';
import portalConfirmAlert from '../../../utils/portalConfirmAlert';
import Text from '../../Elements/Text';

const UsersNew = ({ demo }) => {
  const alert = useAlert();
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;
  const hasAgentsViewPermission =
    demo || userFromDb.permissions?.includes('agents.view');

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);

  const saveButtonRef = useRef(null);

  const [hasChanges, setHasChanges] = useState();
  const [lines, setLines] = useState([]);
  const [agents, setAgents] = useState();

  const [isSaving, setIsSaving] = useState(false);

  const getAgents = async () => {
    try {
      const { agents } = await apiDataServiceAgents.getAll({
        token: demo ? undefined : await getAccessTokenSilently(),
        userId: demo ? 1 : userId,
        portalId: demo ? 'demo' : portalId,
        params: ['sort_by=level0'],
      });

      setAgents(agents);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const validationFunc = (value, title) => {
    const presence = validatePresenceRequired(value, title);
    const contains = validateContainsALetter(lettersOnly(value), title);

    const validations = [];

    if (presence) validations.push(presence);
    if (contains) validations.push(contains);

    if (validations.length) return [validations[0]];
    return null;
  };

  const handleAddLine = () => {
    const line = {
      key: generateUniqueRef(),
      0: {
        title: 'First name',
        value: '',
        type: 'text',
        isRequired: true,
        validation: validationFunc,
      },
      1: {
        title: 'Last name',
        value: '',
        type: 'text',
        isRequired: true,
        validation: validationFunc,
      },
      2: {
        title: 'Username',
        value: '',
        type: 'text',
        disabled: true,
      },
      3: {
        title: 'Email',
        value: '',
        type: 'text',
        isRequired: true,
        validation: (value, title) => {
          const presence = validatePresenceRequired(value.trim(), title);

          if (presence) return [presence];
          return null;
        },
      },
      4: { title: 'Role', value: 'qa', type: 'select', isRequired: true },
    };

    if (hasAgentsViewPermission)
      line['5'] = {
        title: 'Linked agents',
        value: '',
        type: 'addFromList',
        addFromList: agents,
        addFromListKey: 'alt_id',
        addFromListValue: 'level0',
        addFromListLabel: 'Add Agent',
        addFromListPlaceholder: '< Add agent >',
        addFromListIcon: 'link',
        addFromListRemoveIcon: 'unlink',
        addFromListRemoveTitle: 'Unlink',
        addFromListPath: 'agents',
      };

    setLines((curr) => [...curr, line]);
  };

  const handleChangeValue = (event, lineIndex, itemKey) => {
    if (itemKey === '0' || itemKey === '1') {
      const newValue = event.target.value;

      const isBannedCharsText = isBannedChars(
        newValue,
        itemKey === '0' ? 'First Name' : 'Last Name'
      );

      if (isBannedCharsText)
        return alert.error(isBannedCharsText, { timeout: 10000 });

      const letters = lettersOnly(newValue);

      const newUsername =
        itemKey === '0'
          ? `${capitalise(
              lettersOnly(lines[lineIndex]['1'].value).toLowerCase()
            )}${capitalise(letters.slice(0, 1))}`
          : `${capitalise(letters.toLowerCase())}${capitalise(
              lettersOnly(lines[lineIndex]['0'].value).slice(0, 1)
            )}`;

      handleChangeValue({ target: { value: newUsername } }, lineIndex, '2');
    }

    setLines((curr) => {
      const newLines = copyLines(curr);

      newLines[lineIndex][itemKey].value = event.target.value;

      return newLines;
    });

    if (!hasChanges) setHasChanges(true);
  };

  const handleSave = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (userFromDb.portal_has_subscription) {
      const confirmed = await portalConfirmAlert({
        message:
          'Adding users will increase the cost of your portal (Check the billing page for an updated invoice estimate). Do you wish to continue?',
      });

      if (!confirmed) return;
    }

    if (isSaving || saveButtonRef?.current?.disabled) return;
    if (!saveButtonRef?.current?.disabled && saveButtonRef?.current)
      saveButtonRef.current.disabled = true;

    setIsSaving(true);

    try {
      const reqBody = {
        users: [],
        userId: demo ? 1 : userId,
      };

      lines.forEach((line) => {
        const hasFirstName = lettersOnly(line['0'].value);
        const hasLastName = lettersOnly(line['1'].value);
        const hasUsername = line['2'].value;
        const hasEmail = line['3'].value.trim();
        const hasRole = line['4'].value;

        if (hasFirstName && hasLastName && hasUsername && hasEmail && hasRole) {
          reqBody.users.push({
            first_name: line['0'].value,
            last_name: line['1'].value,
            username: line['2'].value,
            email: line['3'].value.trim(),
            role: line['4'].value,
            linked_agents:
              line['5']?.value?.map?.((agent) => agent?.alt_id) || '',
            portal_id: demo ? 1 : portalId,
            tier: tiers[line['4'].value],
            setPermissions: 'default',
          });
        }
      });

      if (reqBody.users.length) {
        await apiDataServiceUsers.post({
          portalId: demo ? 'demo' : portalId,
          reqBody,
          token: demo ? undefined : await getAccessTokenSilently(),
        });
      } else {
        alert.show('No valid users to add');
      }

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;

      if (reqBody.users.length) {
        navigate(
          `/${demo ? 'demo' : portalId}/${
            userFromDb.permissions?.includes('users.view')
              ? 'users'
              : 'dashboard'
          }`,
          {
            state: { isNavigating: true },
          }
        );

        alert.success(`User${reqBody.users.length === 1 ? '' : 's'} added`);
      }
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;
    }
  };

  useEffect(() => {
    if (!demo && !userFromDb.permissions?.includes('users.change')) {
      navigate(`/${demo ? 'demo' : portalId}/dashboard`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      setBreadcrumbs([
        [
          'Dashboard',
          'dashboard',
          '',
          `/${demo ? 'demo' : portalId}/dashboard`,
        ],
        ['Users', 'users', '', `/${demo ? 'demo' : portalId}/users`],
        ['New', 'plus'],
      ]);

      if (hasAgentsViewPermission) getAgents();
      else setAgents([]);
    }

    return () => {
      setAgents();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agents) handleAddLine();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agents]);

  if (!lines.length) return <LoadingSpinner />;
  else
    return (
      <MainOnly>
        <Panels>
          <NewScreenControlPanel
            handleAddLine={handleAddLine}
            handleSave={handleSave}
            saveButtonRef={saveButtonRef}
            saveButtonDisabled={!hasChanges || isSaving}
          />

          <PanelsHeaderPrimary
            iconName='users'
            subIcon='plus'
            text='New User'
          />

          <PanelsSections>
            {lines.map((line, lineIndex, lineArray) => {
              return (
                <PanelsSection key={line.key} typeStyle='type-3'>
                  <NewLine
                    demo={demo}
                    lineIndex={lineIndex}
                    line={line}
                    setLines={setLines}
                    noOfLines={lineArray.length}
                    handleChangeValue={handleChangeValue}
                    showInvalidIfEdited
                  />
                </PanelsSection>
              );
            })}
          </PanelsSections>

          <Text
            margin='0 30px 30px 30px'
            text='* New users will have 30 days to accept their portal invite otherwise they will be deleted'
          />
        </Panels>
      </MainOnly>
    );
};

export default UsersNew;
