import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { distinctColourGenerator } from '../../../utils/reports';

import React, { useState } from 'react';

const PortalPieChart = ({
  height,
  mutatedReportRows,
  originalReportRows,
  shouldIncludeLegend,
  showPieLabels,
  showPieValues,
  width,
  xDataKey,
  valueDataLabel,
}) => {
  const [opacity, setOpacity] = useState({});

  const handleMouseEnter = (o) => {
    const { value } = o;

    setOpacity({ ...opacity, [value]: 0.5 });
  };

  const handleMouseLeave = (o) => {
    const { value } = o;

    setOpacity({ ...opacity, [value]: 1 });
  };

  const data = originalReportRows.map((row, index) => {
    const xVal = mutatedReportRows[index].find(
      (col) => col.col === xDataKey
    )?.mutatedVal;

    const yVal = mutatedReportRows[index].find(
      (col) => col.col === valueDataLabel
    )?.mutatedVal;

    const parsedYVal = parseInt(yVal);

    const displayedYVal = isNaN(parsedYVal) ? yVal : parsedYVal;

    return {
      ...row,
      [valueDataLabel]: displayedYVal,
      name: xVal,
      [xDataKey]: xVal,
    };
  });

  const colours = distinctColourGenerator(data.length);

  // a pie chart must only have 2 columns, one for the value and one for the grouped by.

  return (
    <ResponsiveContainer>
      <PieChart
        // margin={{ bottom: barChartMarginBottom }}
        width={width}
        height={height}
      >
        <Pie
          dataKey={valueDataLabel}
          isAnimationActive={false}
          data={data}
          cx='50%'
          cy='50%'
          outerRadius={
            height / 2 -
            (shouldIncludeLegend && (showPieLabels || showPieValues)
              ? 60
              : shouldIncludeLegend || showPieLabels || showPieValues
              ? 30
              : 0)
          }
          fill='#8884d8'
          labelLine={showPieLabels || showPieValues}
          label={(props) => {
            return `${showPieLabels ? props[xDataKey] || '' : ''}${
              showPieLabels && showPieValues ? ': ' : ''
            }${showPieValues ? props.value : ''}`;
          }}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              color='black'
              fill={`${colours[index]}${
                opacity[entry.name] === 0.5 ? '80' : ''
              }`}
            />
          ))}
        </Pie>

        <Tooltip />

        {shouldIncludeLegend && (
          <Legend
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            wrapperStyle={{ bottom: '-5px' }}
            // wrapperStyle={{ bottom: '-25px' }}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PortalPieChart;
