import { handleApiError } from '../../../utils/error-handling';
import { shouldNavigateOnNotFound } from '../../../utils/helpers';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { UserPortal } from '../../../contexts';

import apiDataService from '../../../api/scorecards';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import Scorecard from '../../ScoringAndFeedback/Scorecard';

const EvaluationsNewChosenScorecard = ({
  calibrationId,
  calibrationLocked,
  currentUserEvaluation,
  demo,
  fromCalibrations,
  isCalibrationBenchmark,
  isUserAParticipant,
  isUserHost,
  scorecardId,
}) => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const [scorecard, setScorecard] = useState();
  const [isLoadingScorecard, setIsLoadingScorecard] = useState(false);

  const { id: idFromOutlet } = useOutletContext();

  const id = fromCalibrations ? scorecardId : idFromOutlet;

  const getScorecard = async () => {
    setIsLoadingScorecard(true);

    try {
      const reqObj = {
        token: demo ? undefined : await getAccessTokenSilently(),
        userId: demo ? 1 : userId,
        portalId: demo ? 'demo' : portalId,
        scorecardId: id,
      };

      if (fromCalibrations) {
        reqObj.fromCalibrations = true;
        reqObj.calibrationId = calibrationId;
      }

      const { scorecard } = await apiDataService.getScorecardById(reqObj);

      setScorecard(scorecard);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      if (fromCalibrations && shouldNavigateOnNotFound(error)) {
        navigate(`/${demo ? 'demo' : portalId}/calibrations/${calibrationId}`, {
          state: { isNavigating: true },
        });
      }
    } finally {
      setIsLoadingScorecard(false);
    }
  };

  useEffect(() => {
    getScorecard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (scorecard && !isLoadingScorecard)
    return (
      <>
        <Scorecard
          adminSections={
            currentUserEvaluation?.evaluatedSections?.adminSecs ||
            scorecard.adminSecs
          }
          bragAmber={
            currentUserEvaluation?.originalScorecard?.amber || scorecard.amber
          }
          bragBlack={
            currentUserEvaluation?.originalScorecard?.black || scorecard.black
          }
          bragGreen={
            currentUserEvaluation?.originalScorecard?.green || scorecard.green
          }
          bragRed={
            currentUserEvaluation?.originalScorecard?.red || scorecard.red
          }
          calibrationId={calibrationId}
          calibrationLocked={calibrationLocked}
          categoryOnlyList={
            currentUserEvaluation?.evaluatedSections?.catOnlyList ||
            scorecard.catOnlyList
          }
          demo={demo}
          evaluation={currentUserEvaluation}
          fromCalibrations={fromCalibrations}
          id={currentUserEvaluation?.originalScorecard?.id || scorecard.id}
          isCalibrationBenchmark={isCalibrationBenchmark}
          isEditingEvaluation
          isEvaluation
          isNewEvaluation
          isUserAParticipant={isUserAParticipant}
          isUserHost={isUserHost}
          logo={
            currentUserEvaluation?.originalScorecard?.logo || scorecard.logo_url
          }
          orgName={
            currentUserEvaluation?.originalScorecard?.org_name ||
            scorecard.org_name
          }
          passMark={
            currentUserEvaluation?.originalScorecard?.pass_mark ||
            scorecard.pass_mark
          }
          scorecardName={
            currentUserEvaluation?.scorecard_name || scorecard.name
          }
          scoringSections={
            currentUserEvaluation?.evaluatedSections?.scoringSecs ||
            scorecard.scoringSecs
          }
          sectionNeg={
            currentUserEvaluation?.originalScorecard?.neg || scorecard.neg
          }
          sectionPos={
            currentUserEvaluation?.originalScorecard?.pos || scorecard.pos
          }
          vcid={
            currentUserEvaluation?.originalScorecard?.vcid || scorecard.vcid
          }
        />
      </>
    );
  else return <LoadingSpinnerNew />;
};

export default EvaluationsNewChosenScorecard;
