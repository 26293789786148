import './Toggle.css';

const Toggle = ({
  classes = '',
  disabled,
  flexDirection,
  hasPermission = true,
  id,
  sliderClasses = '',
  labelText,
  margin,
  marginBottom,
  marginTop,
  onChange,
  toggled,
  flexWrap,
  title,
}) => {
  const style = {};

  if (flexDirection) style.flexDirection = flexDirection;
  if (margin) style.margin = margin;
  if (marginBottom) style.marginBottom = marginBottom;
  if (marginTop) style.marginTop = marginTop;
  if (flexWrap) style.flexWrap = flexWrap;

  return (
    <div
      style={style}
      className={`portal-toggle-container ${
        disabled || !hasPermission ? 'toggle-disabled' : ''
      } ${classes}`}
      title={title}
    >
      <label htmlFor={id} className='portal-toggle-label'>
        {labelText}
      </label>

      <label className='toggle-switch'>
        <input
          disabled={disabled || !hasPermission}
          checked={toggled}
          onChange={onChange}
          id={id}
          type='checkbox'
        />
        <span className={`toggle-slider ${sliderClasses}`}></span>
      </label>
    </div>
  );
};

export default Toggle;
