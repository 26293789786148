import FlexContainer from '../../Elements/FlexContainer';
import GaugeChart from 'react-gauge-chart';
import HeaderSecondary from '../../Elements/HeaderSecondary';

import './VarianceResultChart.css';

const VarianceResultChart = ({ score, text }) => {
  return (
    <FlexContainer flexDirection='column'>
      <HeaderSecondary text={text} />

      <GaugeChart
        id={`gauge-chart-variance`}
        colors={['#169928', '#c82728']}
        arcWidth={0.25}
        arcPadding={0.02}
        cornerRadius={10}
        arcsLength={[
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        ]}
        percent={score / 100}
        needleColor='#978aa670'
        needleBaseColor='#978aa6bf'
        textColor='#333333'
        formatTextValue={() => score}
      />
    </FlexContainer>
  );
};

export default VarianceResultChart;
