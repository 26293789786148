import { useEffect, useState, useRef } from 'react';

import './Icon.css';

const Icon = ({ iconName, className = '', style, ...options }) => {
  const ImportedIconRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const importIcon = async () => {
      try {
        ImportedIconRef.current = (
          await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!../../icons/${iconName}.svg`
          )
        ).default;
      } catch (err) {
        console.log('error with Icon component');
      } finally {
        setIsLoading(false);
      }
    };

    importIcon();

    return () => {
      setIsLoading(false);
    };
  }, [iconName]);

  if (isLoading || !ImportedIconRef.current) return null;
  else {
    const { current: ImportedIcon } = ImportedIconRef;

    return (
      <ImportedIcon
        className={`icon-classes ${className}`}
        style={style}
        {...options}
      />
    );
  }
};

export default Icon;
