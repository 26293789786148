const createUrlQuery = (filterObj) => {
  const condition = Object.keys(filterObj).filter(
    (key) => key !== 'uniqueRef'
  )[0];

  const filterArr = filterObj[condition]
    .map((filter) => {
      const { clause } = filter;
      if (clause) {
        const column = clause[0];
        const operator = clause[1];
        let criteria = clause[2];

        if (operator.includes('bet')) {
          if (criteria[0] === '' || criteria[1] === '') return '';
          else criteria = criteria.join('--');
        }

        if (operator === 'prev') {
          if (criteria[0] === '' || criteria[1] === '') return '';
          else criteria = criteria.join('--');
        }

        if (
          !column.length ||
          !operator.length ||
          (!criteria.length &&
            ![
              'eq',
              'nt',
              'lk',
              'nlk',
              'wk',
              'lwk',
              'mnth',
              'lmnth',
              'yr',
              'lyr',
            ].includes(operator))
        )
          return '';
        else return `${column}==${operator}::${criteria}`;
      } else {
        return createUrlQuery(filter);
      }
    })
    .filter((filter) => filter !== '');

  if (filterArr.length > 1) {
    return `((${filterArr.join(condition === 'and' ? '&&' : ',,')}))`;
  } else if (filterArr.length === 1) {
    if (filterArr[0].slice(0, 2) === '((' && filterArr[0].slice(-2) === '))') {
      return filterArr[0];
    } else {
      return `((${filterArr[0]}))`;
    }
  } else {
    return '';
  }
};

export default createUrlQuery;
