import { BreadcrumbsContext, QueryParams, UserPortal } from './contexts';
import { DndProvider } from 'react-dnd';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useState } from 'react';

// import ProtectedRoute from './auth/protected-route';

import About from './components/Home/About';
import AgentsAll from './components/Portal/Agents/AgentsAll';
import AgentsNew from './components/Portal/Agents/AgentsNew';
import AgentsShow from './components/Portal/Agents/AgentsShow';
// import AuthenticationGuard from './components/AuthenticationGuard';
import Billing from './components/Portal/Stripe/Billing';
import CalibrationsEvaluation from './components/Portal/Calibrations/CalibrationsEvaluation';
import CalibrationsEvaluationBenchmark from './components/Portal/Calibrations/CalibrationsEvaluationBenchmark';
import CalibrationsAll from './components/Portal/Calibrations/CalibrationsAll';
import CalibrationsNew from './components/Portal/Calibrations/CalibrationsNew';
import CalibrationsShow from './components/Portal/Calibrations/CalibrationsShow';
import CalibrationSubmittedEvaluation from './components/Portal/Calibrations/CalibrationsSubmittedEvaluation';
import Contact from './components/Home/Contact';
import Dashboard from './components/Portal/Dashboard/Dashboard';
import DataListsAll from './components/Portal/DataLists/DataListsAll';
import DataListsNew from './components/Portal/DataLists/DataListsNew';
import DataListsShow from './components/Portal/DataLists/DataListsShow';
import DraftsAll from './components/Portal/Drafts/DraftsAll';
import DraftsShow from './components/Portal/Drafts/DraftsShow';
import EvaluationsAll from './components/Portal/Evaluations/EvaluationsAll';
import EvaluationsNew from './components/Portal/Evaluations/EvaluationsNew';
import EvaluationsNewChosenScorecard from './components/Portal/Evaluations/EvaluationsNewChosenScorecard';
import EvaluationsShow from './components/Portal/Evaluations/EvaluationsShow';
import Faq from './components/Home/Faq';
import Guide from './components/Home/Guide';
import HomeScreen from './components/Home/HomeScreen';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import Imports from './components/Portal/Imports/Imports';
import LogsAll from './components/Portal/Logs/LogsAll';
import MultiBackend from 'react-dnd-multi-backend';
import OrgStructure from './components/Portal/OrgStructure';
import PortalLayout from './components/Portal/PortalLayout/PortalLayout';
import Pricing from './components/Home/Pricing';
import PrivacyPolicy from './components/Home/PrivacyPolicy';
import ReportsAll from './components/Portal/Reports/ReportsAll';
import ReportsCalibrations from './components/Portal/Reports/ReportsCalibrations';
import ReportsFocus from './components/Portal/Reports/ReportsFocus';
import ReportsHotTopics from './components/Portal/Reports/ReportsHotTopics';
import ReportsNew from './components/Portal/Reports/ReportsNew';
import ReportsQa from './components/Portal/Reports/ReportsQa';
import ReportsRisk from './components/Portal/Reports/ReportsRisk';
import ReportsShow from './components/Portal/Reports/ReportsShow';
import Return from './components/Portal/Stripe/Return';
import ScorecardTemplatesAll from './components/Portal/Scorecards/ScorecardTemplatesAll';
import ScorecardsAll from './components/Portal/Scorecards/ScorecardsAll';
import ScorecardsNew from './components/Portal/Scorecards/ScorecardsNew';
import ScorecardsShow from './components/Portal/Scorecards/ScorecardsShow';
import TermsAndConditions from './components/Home/TermsAndConditions';
import Try from './components/Home/Try';
// import UnderConstruction from './components/UnderConstruction';
import UsersAll from './components/Portal/Users/UsersAll';
import UsersShow from './components/Portal/Users/UsersShow';
import UsersNew from './components/Portal/Users/UsersNew';

// import { fetchUsers } from './utils/api';

function App() {
  const [queryParams, setQueryParams] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [userFromDb, setUserFromDb] = useState();

  return (
    <UserPortal.Provider value={{ userFromDb, setUserFromDb }}>
      <QueryParams.Provider value={{ queryParams, setQueryParams }}>
        <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
          <div className='app'>
            <DndProvider backend={MultiBackend} options={HTML5toTouch}>
              <Routes>
                <Route path='/demo' element={<PortalLayout demo />}>
                  <Route index element={<Navigate to='/demo/dashboard' />} />

                  <Route path='agents'>
                    <Route index element={<AgentsAll demo />} />

                    <Route path='new' element={<AgentsNew demo />} />

                    <Route path=':id' element={<AgentsShow demo />} />
                  </Route>

                  <Route path='calibrations'>
                    <Route index element={<CalibrationsAll demo />} />

                    <Route path='new' element={<CalibrationsNew demo />} />

                    <Route
                      path=':id'
                      element={<CalibrationsShow demo />}
                    ></Route>

                    <Route
                      path=':id/scorecard'
                      element={<CalibrationsEvaluation demo />}
                    />

                    <Route
                      path=':id/benchmark'
                      element={<CalibrationsEvaluationBenchmark demo />}
                    />

                    <Route
                      path=':id/evaluations/:evaluationId'
                      element={<CalibrationSubmittedEvaluation demo />}
                    />
                  </Route>

                  <Route path='dashboard'>
                    <Route index element={<Dashboard demo />} />

                    <Route
                      path='1'
                      element={<Dashboard demo dashboardId='1' />}
                    />

                    <Route
                      path='2'
                      element={<Dashboard demo dashboardId='2' />}
                    />

                    <Route
                      path='3'
                      element={<Dashboard demo dashboardId='3' />}
                    />
                  </Route>

                  <Route path='data-lists'>
                    <Route index element={<DataListsAll demo />} />

                    <Route path='new' element={<DataListsNew demo />} />

                    <Route path=':id' element={<DataListsShow demo />} />
                  </Route>

                  <Route path='drafts'>
                    <Route index element={<DraftsAll demo />} />

                    <Route
                      path='scorecards/:id'
                      element={<DraftsShow demo type='scorecard' />}
                    />

                    <Route
                      path='evaluations/:id'
                      element={<DraftsShow demo type='evaluation' />}
                    />
                  </Route>

                  <Route path='evaluations'>
                    <Route index element={<EvaluationsAll demo />} />

                    <Route path='new' element={<EvaluationsNew demo />}>
                      <Route
                        path=':id'
                        element={<EvaluationsNewChosenScorecard demo />}
                      />
                    </Route>

                    <Route path=':id' element={<EvaluationsShow demo />} />
                  </Route>

                  <Route path='imports'>
                    <Route index element={<Imports demo />} />
                  </Route>

                  <Route path='logs'>
                    <Route index element={<LogsAll demo />} />
                  </Route>

                  <Route path='org-structure'>
                    <Route index element={<OrgStructure demo />} />
                  </Route>

                  <Route path='reports'>
                    <Route index element={<ReportsAll demo />} />

                    <Route path='new' element={<ReportsNew demo />} />

                    <Route
                      path='calibrations'
                      element={<ReportsCalibrations demo />}
                    />

                    <Route path='focus' element={<ReportsFocus demo />} />

                    <Route
                      path='hot-topics'
                      element={<ReportsHotTopics demo />}
                    />

                    <Route path='qa' element={<ReportsQa demo />} />

                    <Route path='risk' element={<ReportsRisk demo />} />

                    <Route path=':id' element={<ReportsShow demo />} />
                  </Route>

                  <Route path='scorecard-templates'>
                    <Route index element={<ScorecardTemplatesAll demo />} />

                    <Route
                      path='new'
                      element={<ScorecardsNew demo fromTemplates />}
                    />

                    <Route
                      path=':id'
                      element={<ScorecardsShow demo fromTemplates />}
                    />
                  </Route>

                  <Route path='scorecards'>
                    <Route index element={<ScorecardsAll demo />} />

                    <Route path='new' element={<ScorecardsNew demo />} />

                    <Route path=':id' element={<ScorecardsShow demo />} />
                  </Route>

                  <Route path='users'>
                    <Route index element={<UsersAll demo />} />

                    <Route path=':id' element={<UsersShow demo />} />

                    <Route path='new' element={<UsersNew demo />} />
                  </Route>
                </Route>

                <Route path='/' element={<PortalLayout isHomeScreen />}>
                  <Route index element={<HomeScreen />} />

                  <Route path='about' element={<About />} />

                  <Route path='contact' element={<Contact />} />

                  <Route path='faq' element={<Faq />} />

                  <Route path='guide' element={<Guide />} />

                  <Route path='pricing' element={<Pricing />} />

                  <Route path='privacy' element={<PrivacyPolicy />} />

                  <Route path='terms' element={<TermsAndConditions />} />

                  <Route path='try' element={<Try />} />
                </Route>

                <Route path='/:portalId' element={<PortalLayout />}>
                  <Route
                    index
                    element={
                      window?.location?.pathname?.split('/')?.[1] ? (
                        <Navigate
                          to={`/${
                            window.location.pathname.split('/')[1]
                          }/dashboard`}
                        />
                      ) : (
                        <Dashboard />
                      )
                    }
                  />

                  <Route path='agents'>
                    <Route index element={<AgentsAll />} />

                    <Route path='new' element={<AgentsNew />} />

                    <Route path=':id' element={<AgentsShow />} />
                  </Route>

                  <Route path='billing' element={<Billing />} />

                  <Route path='calibrations'>
                    <Route index element={<CalibrationsAll />} />

                    <Route path='new' element={<CalibrationsNew />} />

                    <Route path=':id' element={<CalibrationsShow />}></Route>

                    <Route
                      path=':id/scorecard'
                      element={<CalibrationsEvaluation />}
                    />

                    <Route
                      path=':id/benchmark'
                      element={<CalibrationsEvaluationBenchmark />}
                    />

                    <Route
                      path=':id/evaluations/:evaluationId'
                      element={<CalibrationSubmittedEvaluation />}
                    />
                  </Route>

                  <Route path='dashboard'>
                    <Route index element={<Dashboard />} />

                    <Route path='1' element={<Dashboard dashboardId='1' />} />

                    <Route path='2' element={<Dashboard dashboardId='2' />} />

                    <Route path='3' element={<Dashboard dashboardId='3' />} />
                  </Route>

                  <Route path='data-lists'>
                    <Route index element={<DataListsAll />} />

                    <Route path='new' element={<DataListsNew />} />

                    <Route path=':id' element={<DataListsShow />} />
                  </Route>

                  <Route path='drafts'>
                    <Route index element={<DraftsAll />} />

                    <Route
                      path='scorecards/:id'
                      element={<DraftsShow type='scorecard' />}
                    />

                    <Route
                      path='evaluations/:id'
                      element={<DraftsShow type='evaluation' />}
                    />
                  </Route>

                  <Route path='evaluations'>
                    <Route index element={<EvaluationsAll />} />

                    <Route path='new' element={<EvaluationsNew />}>
                      <Route
                        path=':id'
                        element={<EvaluationsNewChosenScorecard />}
                      />
                    </Route>

                    <Route path=':id' element={<EvaluationsShow />} />
                  </Route>

                  <Route path='imports'>
                    <Route index element={<Imports />} />
                  </Route>

                  <Route path='logs'>
                    <Route index element={<LogsAll />} />
                  </Route>

                  <Route path='org-structure'>
                    <Route index element={<OrgStructure />} />
                  </Route>

                  <Route path='return' element={<Return />} />

                  <Route path='reports'>
                    <Route index element={<ReportsAll />} />

                    <Route path='new' element={<ReportsNew />} />

                    <Route
                      path='calibrations'
                      element={<ReportsCalibrations />}
                    />

                    <Route path='focus' element={<ReportsFocus />} />

                    <Route path='hot-topics' element={<ReportsHotTopics />} />

                    <Route path='qa' element={<ReportsQa />} />

                    <Route path='risk' element={<ReportsRisk />} />

                    <Route path=':id' element={<ReportsShow />} />
                  </Route>

                  <Route path='scorecard-templates'>
                    <Route index element={<ScorecardTemplatesAll />} />

                    <Route
                      path='new'
                      element={<ScorecardsNew fromTemplates />}
                    />

                    <Route
                      path=':id'
                      element={<ScorecardsShow fromTemplates />}
                    />
                  </Route>

                  <Route path='scorecards'>
                    <Route index element={<ScorecardsAll />} />

                    <Route path='new' element={<ScorecardsNew />} />

                    <Route path=':id' element={<ScorecardsShow />} />
                  </Route>

                  <Route path='users'>
                    <Route index element={<UsersAll />} />

                    <Route path=':id' element={<UsersShow />} />

                    <Route path='new' element={<UsersNew />} />
                  </Route>
                </Route>
              </Routes>
            </DndProvider>
          </div>
        </BreadcrumbsContext.Provider>
      </QueryParams.Provider>
    </UserPortal.Provider>
  );
}

export default App;
