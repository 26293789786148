import { handleApiError } from '../../../utils/error-handling';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { UserPortal } from '../../../contexts';

import apiDataServiceUsers from '../../../api/users';
import Icon from '../../Elements/Icon';

import './NavItem.css';

const NavItem = ({
  disabled,
  name,
  icon,
  idName,
  isEditingFavourites,
  link,
  noPermission,
  setIsNavOpen,
  setNavFavourites,
  subIcon,
  navFavourites,
  demo,
}) => {
  const alert = useAlert();

  const { getAccessTokenSilently } = useAuth0();
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const location = useLocation();

  const isCurrentlyOnThisRoute = link === location.pathname;

  const isDisabled = disabled || isCurrentlyOnThisRoute || noPermission;

  const handleDeleteNavFavourite = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    const newNavFavourites = navFavourites.filter((item) => item !== idName);

    try {
      const reqBody = {
        updates: [
          {
            id: demo ? 1 : userId,
            nav_favourites: newNavFavourites,
          },
        ],
        userId: demo ? 1 : userId,
      };

      await apiDataServiceUsers.update({
        portalId: demo ? 'demo' : portalId,
        reqBody,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      alert.success('Favourite Deleted');

      setNavFavourites(newNavFavourites);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  return (
    <div
      className='nav-item-container'
      style={{ position: 'relative' }}
      title={
        noPermission
          ? 'You do not have the required permission to access this screen'
          : ''
      }
    >
      {isEditingFavourites ? (
        <div onClick={handleDeleteNavFavourite}>
          <Icon
            className='nav-favourite-cross ignore-detect-click-outside'
            iconName='circle-cross'
          />
        </div>
      ) : (
        <></>
      )}

      {isEditingFavourites ? (
        <div
          className={`nav-item-link ${
            isCurrentlyOnThisRoute ? 'nav-item-current' : ''
          } ${isDisabled ? 'disabled-nav-item' : ''} ${
            isEditingFavourites ? 'nav-favourite-editing' : ''
          }`}
        >
          <div className='nav-item'>
            <div className='nav-item-icon-container'>
              <Icon className='nav-item-icon' iconName={icon} />
              {!!subIcon && (
                <Icon className='nav-item-icon-sub' iconName={subIcon} />
              )}
            </div>
            <h3>{name}</h3>
          </div>
        </div>
      ) : (
        <Link
          to={link}
          className={`nav-item-link ${
            isCurrentlyOnThisRoute ? 'nav-item-current' : ''
          } ${isDisabled ? 'disabled-nav-item' : ''} ${
            isEditingFavourites ? 'nav-favourite-editing' : ''
          }`}
          onClick={() => setIsNavOpen(false)}
          state={{ isNavigating: true }}
        >
          <div className='nav-item'>
            <div className='nav-item-icon-container'>
              <Icon className='nav-item-icon' iconName={icon} />
              {!!subIcon && (
                <Icon className='nav-item-icon-sub' iconName={subIcon} />
              )}
            </div>
            <h3>{name}</h3>
          </div>
        </Link>
      )}
    </div>
  );
};

export default NavItem;
