import './ReportFilter.css';

import DraggableToggleList from '../../Elements/DraggableToggleList';

const ReportColumnOrder = ({ columnOrder, setColumnOrder }) => {
  return (
    <div className='report-aggregate-area'>
      <DraggableToggleList
        cards={columnOrder}
        setCards={setColumnOrder}
        itemType='column-item'
        handleToggle={() => {}}
        toggleHeight={40}
        uniqueSectionId='column'
        noToggle
      />
    </div>
  );
};

export default ReportColumnOrder;
