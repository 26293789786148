import InfoText from '../../Elements/InfoText';
import OptionsTemplatesListItem from './OptionsTemplatesListItem';

import './OptionsTemplates.css';

const OptionsTemplates = ({
  handleChangeSelectedTemplate,
  selectedTemplateUniqueId,
  templates,
  type,
  noTemplatesText = 'No templates',
}) => {
  return (
    <ul className='options-templates-list'>
      {templates?.length ? (
        templates.map((template) => {
          const uniqueId = `${type}${template.id}`;

          return (
            <OptionsTemplatesListItem
              key={uniqueId}
              description={template.description}
              handleChangeSelectedTemplate={handleChangeSelectedTemplate}
              id={template.id}
              name={template.name}
              selectedTemplateUniqueId={selectedTemplateUniqueId}
              type={type}
              uniqueId={uniqueId}
              vcid={template.vcid}
            />
          );
        })
      ) : (
        <InfoText
          style={{ flex: '1', padding: '20px 10px' }}
          text={noTemplatesText}
        />
      )}
    </ul>
  );
};

export default OptionsTemplates;
