import ButtonIconOnly from '../../../Elements/ButtonIconOnly';
import GridItemInput from './GridItemInput';
import InputValidityIcon from '../../../Elements/InputValidityIcon';
import InputValidationText from '../../../Elements/InputValidationText';
import TextBreak from '../../../Elements/TextBreak';

import './GridItem.css';

const GridItem = ({
  canApplyToAll,
  column,
  columnType,
  displayData,
  gridChangesRow,
  gridItemSubHeaderText,
  handleApplyToAll,
  handleUpdateGridChanges,
  isAppliedToAll,
  isEditable,
  isFirstColumn,
  isLoading,
  isMainColumn,
  isSelected,
  noOfColumns,
  rawData,
  record,
  recordId,
  requiredFields,
  selectFrom,
  showInvalid,
  validationColumns,
  valueFromGridChanges,
  view,
}) => {
  // will need to do different things for date/time/bool etc

  const getNewValue = (value, convertTo) => {
    if (convertTo === 'int') {
      const intVal = parseInt(value);
      if (isNaN(intVal)) return null;
      else return intVal;
    } else if (convertTo === 'bool') {
      return value === 'true' ? true : false;
    } else if (convertTo === 'array') {
      return value.length ? value.split('\n') : null;
    } else if (value === '') {
      return null;
    } else {
      return value;
    }
  };

  const handleChangeInputValue = (event) => {
    handleUpdateGridChanges(
      recordId,
      column,
      getNewValue(event.target.value, columnType),
      rawData,
      isAppliedToAll,
      columnType
    );
  };

  const invalidTextArray = validationColumns?.[column]?.(
    valueFromGridChanges === undefined ? rawData : valueFromGridChanges,
    gridItemSubHeaderText,
    record,
    gridChangesRow
  );

  if (view === 'table') {
    return (
      <div
        key={column}
        className={`grid-row-cell ${isMainColumn ? 'grid-header-column' : ''}`}
      >
        {isSelected && isEditable ? (
          <div className='grid-item-input-with-icon'>
            {isSelected && isEditable && (
              <InputValidityIcon
                right='25'
                showInvalid={showInvalid}
                isRequired={requiredFields?.includes(column)}
                isInvalid={invalidTextArray?.length}
              />
            )}

            <GridItemInput
              column={column}
              columnType={columnType}
              disabled={isLoading}
              isInvalid={invalidTextArray?.length}
              isRequired={requiredFields?.includes(column)}
              onChange={handleChangeInputValue}
              selectFrom={selectFrom}
              showInvalid={showInvalid}
              value={
                valueFromGridChanges === undefined
                  ? rawData
                  : valueFromGridChanges
              }
              valueFromGridChanges={valueFromGridChanges}
              view={view}
            />

            {showInvalid && invalidTextArray?.length > 0 && (
              <InputValidationText text={invalidTextArray} />
            )}

            <ButtonIconOnly
              classes='grid-item-apply-to-all-button cancel-click-record'
              disabled={!canApplyToAll || isLoading}
              iconClasses='cancel-click-record grid-item-apply-to-all-icon'
              iconName={isAppliedToAll ? 'circle-solid' : 'circle-dot-thin'}
              onClick={() => handleApplyToAll(column)}
              title={isAppliedToAll ? 'Cancel apply to all' : 'Apply to all'}
              typeStyle='type-5'
            />
          </div>
        ) : (
          <div className='grid-row-cell-data'>
            {columnType === 'array' ? displayData.join(', ') : displayData}
          </div>
        )}
      </div>
    );
  } else if (view === 'grid' && isFirstColumn) {
    return (
      <div key={column} className='grid-card-cell'>
        {isSelected && isEditable && (
          <InputValidityIcon
            right='40'
            showInvalid={showInvalid}
            isRequired={requiredFields?.includes(column)}
            isInvalid={invalidTextArray?.length}
          />
        )}

        {isSelected && isEditable ? (
          <>
            <GridItemInput
              column={column}
              columnType={columnType}
              disabled={isLoading}
              isInvalid={invalidTextArray?.length}
              isRequired={requiredFields?.includes(column)}
              onChange={handleChangeInputValue}
              selectFrom={selectFrom}
              showInvalid={showInvalid}
              value={
                valueFromGridChanges === undefined
                  ? rawData
                  : valueFromGridChanges
              }
              valueFromGridChanges={valueFromGridChanges}
              view={view}
            />

            {showInvalid && invalidTextArray?.length > 0 && (
              <InputValidationText text={invalidTextArray} />
            )}

            <ButtonIconOnly
              classes='grid-item-apply-to-all-button cancel-click-record'
              disabled={!canApplyToAll || isLoading}
              iconClasses='cancel-click-record grid-item-apply-to-all-icon'
              iconName={isAppliedToAll ? 'circle-solid' : 'circle-dot-thin'}
              onClick={() => handleApplyToAll(column)}
              title={isAppliedToAll ? 'Cancel apply to all' : 'Apply to all'}
              typeStyle='type-5'
            />
          </>
        ) : (
          <TextBreak
            classes={`grid-card-header ${
              noOfColumns === 1 ? 'grid-card-header-single-column' : ''
            }`}
            text={displayData}
          />
        )}
      </div>
    );
  } else if (view === 'grid' && !isFirstColumn) {
    return (
      <div
        key={column}
        className={`grid-card-cell ${isMainColumn ? 'grid-header-column' : ''}`}
      >
        {isSelected && isEditable && (
          <InputValidityIcon
            right='40'
            top='9'
            showInvalid={showInvalid}
            isRequired={requiredFields?.includes(column)}
            isInvalid={invalidTextArray?.length}
          />
        )}

        <TextBreak
          classes='grid-card-sub-header'
          text={gridItemSubHeaderText || ' - '}
        />
        {isSelected && isEditable ? (
          <>
            <GridItemInput
              column={column}
              columnType={columnType}
              disabled={isLoading}
              isInvalid={invalidTextArray?.length}
              isRequired={requiredFields?.includes(column)}
              onChange={handleChangeInputValue}
              selectFrom={selectFrom}
              showInvalid={showInvalid}
              value={
                valueFromGridChanges === undefined
                  ? rawData
                  : valueFromGridChanges
              }
              valueFromGridChanges={valueFromGridChanges}
              view={view}
            />

            {showInvalid && invalidTextArray?.length > 0 && (
              <InputValidationText text={invalidTextArray} />
            )}

            <ButtonIconOnly
              classes='grid-item-apply-to-all-button cancel-click-record'
              disabled={!canApplyToAll || isLoading}
              iconClasses='cancel-click-record grid-item-apply-to-all-icon'
              iconName={isAppliedToAll ? 'circle-solid' : 'circle-dot-thin'}
              onClick={() => handleApplyToAll(column)}
              title={isAppliedToAll ? 'Cancel apply to all' : 'Apply to all'}
              typeStyle='type-5'
            />
          </>
        ) : columnType === 'array' ? (
          displayData.map((dataItem, index) =>
            dataItem === '' ? (
              <div key={index} className='grid-array-item-gap'></div>
            ) : (
              <TextBreak key={index} text={dataItem} />
            )
          )
        ) : (
          <TextBreak text={displayData} />
        )}
      </div>
    );
  }
};

export default GridItem;
