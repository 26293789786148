import { doArrayValuesMatch } from '../../../utils/helpers';
import { handleApiError } from '../../../utils/error-handling';
import { translateSourceTablesTitles } from '../../../utils/reports';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect } from 'react';
import { UserPortal } from '../../../contexts';

import apiDataServiceReports from '../../../api/reports';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InfoText from '../../Elements/InfoText';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import moment from 'moment';
import PanelsSection from '../../Elements/PanelsSection';
import PanelsSections from '../../Elements/PanelsSections';
import ReportsFocusBreakdown from './ReportsFocusBreakdown';
import ReportsFocusResultsTable from './ReportsFocusResultsTable';

import './ReportsFocusResults.css';

const ReportsFocusResults = ({
  demo,
  brag,
  breakdown,
  defaultQuery,
  focusDataCat,
  focusDataScoring,
  focusResultsCat,
  focusResultsScoring,
  isLoadingBreakdown,
  isLoadingData,
  isLoadingResults,
  isViewingBreakdown,
  reportParameters,
  reportQuery,
  reportQueryHasChanged,
  setBreakdown,
  setFocusDataCat,
  setFocusDataScoring,
  setIsLoadingBreakdown,
  setIsLoadingData,
  setIsViewingBreakdown,
  sortedValidParameters,
}) => {
  const alert = useAlert();
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { getAccessTokenSilently } = useAuth0();

  const handleClickViewBreakdown = async (
    scorecardVcid,
    sectionVcid,
    tableType,
    sectionName,
    scorecardName
  ) => {
    const hasClickedPreviouslyViewing =
      breakdown.scorecardVcid === scorecardVcid &&
      breakdown.sectionVcid === sectionVcid &&
      breakdown.tableType === tableType;

    if (isViewingBreakdown === true && hasClickedPreviouslyViewing === true) {
      setIsViewingBreakdown(false);
    } else {
      setIsViewingBreakdown(true);

      if (hasClickedPreviouslyViewing) {
        return;
      } else {
        try {
          setIsLoadingBreakdown(true);

          document.body.classList.add('spinner-cursor');

          const params = [
            `type=breakdown`,
            `scorecard_vcid=${encodeURIComponent(scorecardVcid)}`,
            `section_vcid=${encodeURIComponent(sectionVcid)}`,
            `table_type=${encodeURIComponent(tableType)}`,
            `q=${encodeURIComponent(
              reportQuery === '(())' ? defaultQuery : reportQuery
            )}`,
          ];

          const { breakdownData, orgLevels } =
            await apiDataServiceReports.getFocus({
              params,
              portalId: demo ? 'demo' : portalId,
              userId: demo ? 1 : userId,
              token: demo ? undefined : await getAccessTokenSilently(),
            });

          setBreakdown({
            scorecardVcid,
            sectionVcid,
            tableType,
            sectionName,
            scorecardName,
            breakdownData,
            orgLevels,
          });
        } catch (error) {
          alert.error(handleApiError(error), { timeout: 10000 });
        } finally {
          setIsLoadingBreakdown(false);
          document.body.classList.remove('spinner-cursor');
        }
      }
    }
  };

  const getBrag = (type, value) => {
    if (value === '-' || value === undefined || value === null) return 'none';

    return (
      brag[type].find(
        (bragItem, index, arr) =>
          value >= bragItem.rag &&
          (index === arr.length - 1 || value < arr[index + 1].rag)
      )?.colour || 'none'
    );
  };

  const getFocusData = async () => {
    try {
      setIsLoadingData(true);

      const params = [`type=data`];

      const { focusDataScoring, focusDataCat } =
        await apiDataServiceReports.getFocus({
          params,
          portalId: demo ? 'demo' : portalId,
          userId: demo ? 1 : userId,
          token: demo ? undefined : await getAccessTokenSilently(),
        });

      setFocusDataScoring(focusDataScoring);
      setFocusDataCat(focusDataCat);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    getFocusData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PanelsSections>
        {['scoring', 'cat'].map((tableCode) => (
          <PanelsSection
            key={tableCode}
            alignItems='stretch'
            classes='focus-results-panel'
            noMaxWidth
            typeStyle='type-2'
          >
            <HeaderSecondary
              marginBottom='5px'
              text={translateSourceTablesTitles[tableCode]}
              textAlign='center'
            />
            {isLoadingResults || isLoadingData ? (
              <LoadingSpinnerNew />
            ) : (
              <ReportsFocusResultsTable
                breakdown={breakdown}
                focusData={
                  tableCode === 'scoring' ? focusDataScoring : focusDataCat
                }
                focusResults={
                  tableCode === 'scoring'
                    ? focusResultsScoring
                    : focusResultsCat
                }
                getBrag={getBrag}
                handleClickViewBreakdown={handleClickViewBreakdown}
                isDefaultParamsOnly={
                  doArrayValuesMatch(sortedValidParameters, [
                    'evals_Date_null_null_Created Date',
                  ]) &&
                  reportParameters['evals_Date_null_null_Created Date']
                    .params[0] ===
                    moment()
                      .subtract(3, 'months')
                      .add(1, 'days')
                      .format('YYYY-MM-DD') &&
                  reportParameters['evals_Date_null_null_Created Date']
                    .params[1] === moment().format('YYYY-MM-DD')
                }
                isLoadingBreakdown={isLoadingBreakdown}
                isViewingBreakdown={isViewingBreakdown}
                reportQueryHasChanged={reportQueryHasChanged}
                tableType={tableCode}
                validParamsLength={sortedValidParameters.length}
              />
            )}
          </PanelsSection>
        ))}
      </PanelsSections>

      <FlexContainer
        alignItems='flex-start'
        flexDirection='column'
        margin='0 20px'
      >
        <FlexContainer alignItems='flex-start'>
          <InfoText style={{ paddingRight: '0', textAlign: 'left' }} text='-' />

          <InfoText
            style={{ paddingLeft: '5px', textAlign: 'left' }}
            text='Trend is available for sections that have been marked 5 times or more'
          />
        </FlexContainer>

        <FlexContainer>
          <InfoText style={{ paddingRight: '0', textAlign: 'left' }} text='-' />

          <InfoText
            style={{ paddingLeft: '5px', textAlign: 'left' }}
            text='Total Marked and Pass % excludes sections marked as NA'
          />
        </FlexContainer>
      </FlexContainer>

      {isViewingBreakdown && (
        <ReportsFocusBreakdown
          breakdown={breakdown}
          getBrag={getBrag}
          isLoadingBreakdown={isLoadingBreakdown}
        />
      )}
    </>
  );
};

export default ReportsFocusResults;
