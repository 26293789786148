import { Link } from 'react-router-dom';

import FlexContainer from '../Elements/FlexContainer';
import HeaderPrimary from '../Elements/HeaderPrimary';
import Icon from '../Elements/Icon';
import Text from '../Elements/Text';

import './Contact.css';

const Faq = () => {
  return (
    <div className='contact-screen home-screen'>
      <HeaderPrimary margin='30px auto 30px auto' text="FAQ's" />

      <ul>
        <li></li>
      </ul>

      <FlexContainer>
        <Icon
          style={{
            height: '16px',
            marginRight: '10px',
            minWidth: '16px',
            width: '16px',
          }}
          iconName='question'
        />

        <Text text="Can't find what you're looking for?" textAlign='center' />
      </FlexContainer>

      <Link style={{ alignSelf: 'center', marginTop: '15px' }} to='/contact'>
        <div>Contact us</div>
      </Link>
    </div>
  );
};

export default Faq;
