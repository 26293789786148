import { getSortOrderCustom, getTableCode } from '../../../utils/reports';

import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import ButtonToggle from '../../Elements/ButtonToggle';
import FlexContainer from '../../Elements/FlexContainer';
import InfoText from '../../Elements/InfoText';
import moment from 'moment';

import './ReportsFocusDistinctDataList.css';

const ReportsFocusDistinctDataList = ({
  code,
  data,
  sectionControl,
  sectionParameters,
  sectionTitle,
  setReportParameters,
}) => {
  if (!data.length) return <InfoText text='No data' />;
  else
    return (
      <FlexContainer alignItems='stretch' flexDirection='column'>
        <ButtonStandardNoFill
          alignSelf='center'
          disabled={
            sectionParameters?.params &&
            Object.keys(sectionParameters.params).length === data.length
          }
          iconName='square-tick-solid'
          margin='5px 0 10px'
          onClick={() => {
            setReportParameters((curr) => {
              const paramsToSet = {};

              data.forEach((dataItem) => {
                const parsedDistinctDataItem =
                  dataItem === null
                    ? '-'
                    : sectionControl === 'Date'
                    ? moment(dataItem).format('Do MMMM YYYY')
                    : dataItem;

                paramsToSet[parsedDistinctDataItem] = true;
              });

              const copiedCurr = { ...curr };

              if (!copiedCurr[code] || copiedCurr[code].type === 'bet') {
                copiedCurr[code] = {
                  params: paramsToSet,
                  control: sectionControl,
                  title: sectionTitle,
                  type: 'inc',
                  invalid: false,
                };

                return copiedCurr;
              }

              const copiedSectionParameters = { ...copiedCurr[code] };

              copiedSectionParameters.params = paramsToSet;
              copiedSectionParameters.invalid = false;

              copiedCurr[code] = copiedSectionParameters;

              return copiedCurr;
            });
          }}
          text='Select all'
        />

        <ButtonStandardNoFill
          alignSelf='center'
          disabled={
            Array.isArray(sectionParameters?.params) ||
            !sectionParameters?.params ||
            (sectionParameters.params &&
              !Object.keys(sectionParameters.params).length)
          }
          iconName='square-cross'
          marginBottom='10px'
          onClick={() => {
            setReportParameters((curr) => {
              const copiedCurr = { ...curr };
              const copiedSectionParameters = { ...copiedCurr[code] };

              copiedSectionParameters.params = {};

              copiedSectionParameters.invalid = true;

              if (sectionControl === 'Date' || sectionControl === 'Number')
                copiedSectionParameters.type = 'bet';

              copiedCurr[code] = copiedSectionParameters;
              return copiedCurr;
            });
          }}
          text='De-select all'
        />

        <FlexContainer
          alignItems='stretch'
          classes={`focus-distinct-data-list ${
            sectionControl === 'Date'
              ? 'focus-distinct-data-list-date'
              : sectionControl === 'Number'
              ? 'focus-distinct-data-list-number'
              : ''
          }`}
          flexDirection='column'
          justifyContent='flex-start'
        >
          {data
            .sort((a, b) => {
              if (sectionControl === 'Number') {
                const parsedA = a === null ? '-' : a;
                const parsedB = b === null ? '-' : b;

                const aValid =
                  sectionParameters?.params[parsedA] &&
                  !sectionParameters.invalid;

                const bValid =
                  sectionParameters?.params[parsedB] &&
                  !sectionParameters.invalid;

                if (aValid === bValid) {
                  if (a === null && b === null) return 0;

                  if (a === null) return 1;
                  if (b === null) return -1;

                  return a - b;
                }

                if (aValid) return -1;
                return 1;
              }

              if (sectionControl === 'Date') {
                const parsedA =
                  a === null ? '-' : moment(a).format('Do MMMM YYYY');
                const parsedB =
                  b === null ? '-' : moment(b).format('Do MMMM YYYY');

                const aValid =
                  sectionParameters?.params[parsedA] &&
                  !sectionParameters.invalid;

                const bValid =
                  sectionParameters?.params[parsedB] &&
                  !sectionParameters.invalid;

                return aValid === bValid
                  ? new Date(b) - new Date(a)
                  : aValid
                  ? -1
                  : 1;
              }

              const parsedA = a === null ? '-' : a;
              const parsedB = b === null ? '-' : b;

              const aValid =
                sectionParameters?.params[parsedA] &&
                !sectionParameters.invalid;

              const bValid =
                sectionParameters?.params[parsedB] &&
                !sectionParameters.invalid;

              if (aValid === bValid) {
                if (a === null && b === null) return 0;

                if (a === null) return 1;
                if (b === null) return -1;

                const sortOrderCustom = getSortOrderCustom(
                  a,
                  b,
                  getTableCode(code),
                  sectionControl,
                  sectionTitle
                );

                if (sortOrderCustom !== 'unsorted') return sortOrderCustom;

                return parsedA
                  .toLowerCase()
                  .localeCompare(parsedB.toLowerCase());
              }

              if (aValid) return -1;
              return 1;
            })
            .map((distinctDataItem) => {
              const parsedDistinctDataItem =
                distinctDataItem === null
                  ? '-'
                  : sectionControl === 'Date'
                  ? moment(distinctDataItem).format('Do MMMM YYYY')
                  : distinctDataItem;

              return (
                <ButtonToggle
                  key={distinctDataItem}
                  onClick={() => {
                    setReportParameters((curr) => {
                      const copiedCurr = { ...curr };

                      if (
                        !copiedCurr[code] ||
                        copiedCurr[code].type === 'bet'
                      ) {
                        copiedCurr[code] = {
                          params: { [parsedDistinctDataItem]: true },
                          control: sectionControl,
                          title: sectionTitle,
                          type: 'inc',
                          invalid: false,
                        };

                        return copiedCurr;
                      }

                      const copiedSectionParameters = { ...copiedCurr[code] };
                      const copiedParams = {
                        ...copiedSectionParameters.params,
                      };

                      if (copiedParams[parsedDistinctDataItem]) {
                        delete copiedParams[parsedDistinctDataItem];

                        if (!Object.keys(copiedParams).length) {
                          copiedSectionParameters.invalid = true;
                        }
                      } else {
                        copiedParams[parsedDistinctDataItem] = true;

                        if (copiedSectionParameters.type === 'inc') {
                          copiedSectionParameters.invalid = false;
                        }
                      }

                      copiedSectionParameters.params = copiedParams;
                      copiedCurr[code] = copiedSectionParameters;

                      return copiedCurr;
                    });
                  }}
                  text={parsedDistinctDataItem}
                  toggled={sectionParameters?.params[parsedDistinctDataItem]}
                />
              );
            })}
        </FlexContainer>
      </FlexContainer>
    );
};

export default ReportsFocusDistinctDataList;
