const getNestIndex = (queryStr, parenthStartIndex) => {
  let parenthCount = 0;

  for (let i = parenthStartIndex; i < queryStr.length; i++) {
    const queryStrStart = queryStr.slice(i, i + 2);

    if (queryStrStart === '((') {
      parenthCount++;
      i++;
    } else if (queryStrStart === '))') {
      parenthCount--;
      i++;
    }

    if (parenthCount === 0) {
      const remainingStrAfterParenth = queryStr.slice(i + 1);
      let isEven;

      for (let j = 0; j < remainingStrAfterParenth.length; j++) {
        if (remainingStrAfterParenth[j] !== ')') {
          isEven = j % 2 === 0;
          break;
        } else if (
          j === remainingStrAfterParenth.length - 1 &&
          remainingStrAfterParenth[j] === ')'
        ) {
          isEven = j + (1 % 2) === 0;
          break;
        }
      }

      if (isEven || !remainingStrAfterParenth) return i + 1;
      else return i + 2;
    }
  }
};

export default getNestIndex;
