import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import {
  capitalise,
  isBannedChars,
  lettersOnly,
  shouldNavigateOnNotFound,
  validateContainsALetter,
  validatePresenceRequired,
} from '../../../utils/helpers';
import { handleApiError } from '../../../utils/error-handling';
import {
  rolesOptions,
  rolesTranslations,
  tiers,
} from '../../../utils/constants';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import apiDataService from '../../../api/users';
import apiDataServiceAgents from '../../../api/agents';
import BulletPoint from '../../Elements/BulletPoint';
import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InputStandard from '../../Elements/InputStandard';
import LinkedAgentsListItem from './LinkedAgentsListItem';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import MainOnly from '../MainAndOptions/MainOnly';
import Panels from '../../Elements/Panels';
import PanelsControlBar from '../../Elements/PanelsControlBar';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import PanelsSections from '../../Elements/PanelsSections';
import PermissionsListItem from './PermissionsListItem';
import portalConfirmAlert from '../../../utils/portalConfirmAlert';
import SelectStandard from '../../Elements/SelectStandard';

import './UsersShow.css';

const UsersShow = ({ demo }) => {
  const navigate = useNavigate();

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  const { userFromDb } = useContext(UserPortal);
  const { getUserFromDb } = useOutletContext();

  const [user, setUser] = useState();

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;
  const hasChangePermission =
    demo || userFromDb.permissions?.includes('users.change');
  const hasAgentsViewPermission =
    demo || userFromDb.permissions?.includes('agents.view');

  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();

  const alert = useAlert();

  const [users, setUsers] = useState([]);
  const [copyFromUser, setCopyFromUser] = useState(0);
  const [permissions, setPermissions] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [linkedAgents, setLinkedAgents] = useState([]);
  const [allAgents, setAllAgents] = useState([]);
  const [agentToLink, setAgentToLink] = useState();
  const [permissionToAdd, setPermissionToAdd] = useState();

  const [isSaving, setIsSaving] = useState();
  const [changes, setChanges] = useState({});
  const [isLoadingLinkedAgents, setIsLoadingLinkedAgents] = useState(false);

  const saveButtonRef = useRef(null);
  const [showInvalid, setShowInvalid] = useState(false);

  const updateUser = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (
      userFromDb.portal_has_subscription &&
      Object.keys(changes).includes('role')
    ) {
      const confirmed = await portalConfirmAlert({
        message:
          "Changing a user's role will affect the cost of your portal (Check the billing page for an updated invoice estimate). Do you wish to continue?",
      });

      if (!confirmed) return;
    }

    if (Object.keys(changes).includes('role') && changes.role !== 'admin') {
      const { users: adminUsers } = await apiDataService.getAll({
        params: [`q=${encodeURIComponent('((role==eq::admin))')}`],
        portalId: portalId,
        userId: userId,
        token: await getAccessTokenSilently(),
      });

      if (
        adminUsers.length === 1 &&
        parseInt(adminUsers[0].id) === parseInt(id)
      ) {
        return alert.info(
          'Unable to change role as no other admin users belong to this portal. Please assign another user to the role of ADMIN first.',
          { timeout: 10000 }
        );
      }
    }

    if (isSaving || saveButtonRef?.current?.disabled) return;
    if (!saveButtonRef?.current?.disabled && saveButtonRef?.current)
      saveButtonRef.current.disabled = true;

    setIsSaving(true);

    const hasFirstName =
      (lettersOnly(user.first_name) &&
        !Object.keys(changes).includes('first_name')) ||
      lettersOnly(changes.first_name);
    const hasLastName =
      (lettersOnly(user.last_name) &&
        !Object.keys(changes).includes('last_name')) ||
      lettersOnly(changes.last_name);
    const hasEmail =
      (user.email?.trim() && !Object.keys(changes).includes('email')) ||
      changes.email?.trim();
    const hasRole =
      (user.role && !Object.keys(changes).includes('role')) || changes.role;

    const userChanges = { ...changes };

    if (changes.email?.trim()) {
      userChanges.email = changes.email?.trim();
      userChanges.sub = 'not set';
    }

    delete userChanges.copyFromUser;
    delete userChanges.permissionsChanged;
    delete userChanges.usingRoleDefaultPermissions;

    if (userChanges.role) userChanges.tier = tiers[userChanges.role];

    try {
      const reqBody = {
        updates: Object.keys(userChanges).length
          ? [{ ...userChanges, id: user.id }]
          : undefined,
        permissionsChanges: [
          {
            ...changes,
            role: user.role,
            permissionsToInsert: changes.permissionsChanged
              ? permissions.map((permission) => ({
                  user_id: id,
                  permission_default_id: allPermissions.find(
                    (allPermission) => allPermission.route === permission
                  ).id,
                }))
              : undefined,
            id,
          },
        ],
        userId: demo ? 1 : userId,
      };

      if (hasFirstName && hasLastName && hasEmail && hasRole) {
        const { userRows } = await apiDataService.update({
          portalId: demo ? 'demo' : portalId,
          reqBody,
          token: demo ? undefined : await getAccessTokenSilently(),
        });

        if (userRows) setUser({ ...user, username: userRows[0].username });
      }

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;

      if (hasFirstName && hasLastName && hasEmail && hasRole) {
        alert.success('Changes saved');

        setChanges({});

        setCopyFromUser(0);

        if (userChanges.sub && parseInt(userId) === parseInt(id)) {
          return navigate('/', { state: { isNavigating: true } });
        }

        await getUser(true);
      } else {
        alert.show(
          <div>
            <p>Unable to save.</p>
            <p>Please check the following areas for errors:</p>
            <br />
            <ul>
              {[
                { validation: !hasFirstName, msg: ' First Name' },
                { validation: !hasLastName, msg: ' Last Name' },
                { validation: !hasEmail, msg: ' Email' },
                { validation: !hasRole, msg: ' Role' },
              ]
                .filter(({ validation }) => validation)
                .map(({ msg }, index) => {
                  return (
                    <li key={index}>
                      <BulletPoint />

                      <span>{msg}</span>
                    </li>
                  );
                })}
            </ul>
          </div>,
          { timeout: 10000 }
        );
      }
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;
    } finally {
      setShowInvalid(true);
    }
  };

  const getUser = async (shouldRefreshUserFromDb) => {
    try {
      const { user, permissions, allPermissions } =
        await apiDataService.getUserById({
          token: demo ? undefined : await getAccessTokenSilently(),
          userId: demo ? 1 : userId,
          portalId: demo ? 'demo' : portalId,
          idOfUser: id,
        });

      setUser(user);
      setPermissions(permissions.map(({ route }) => route));
      setAllPermissions(allPermissions);

      if (shouldRefreshUserFromDb) await getUserFromDb();
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      if (shouldNavigateOnNotFound(error)) {
        navigate(`/${demo ? 'demo' : portalId}/users`, {
          state: { isNavigating: true },
        });
      }
    }
  };

  const getUsers = async () => {
    try {
      const { users } = await apiDataService.getAll({
        token: demo ? undefined : await getAccessTokenSilently(),
        userId: demo ? 1 : userId,
        portalId: demo ? 'demo' : portalId,
        params: [],
      });

      setUsers(users);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const getAgents = async (linkedAgents) => {
    try {
      const { agents } = await apiDataServiceAgents.getAll({
        token: demo ? undefined : await getAccessTokenSilently(),
        userId: demo ? 1 : userId,
        portalId: demo ? 'demo' : portalId,
        params: ['sort_by=level0'],
      });

      setAllAgents(
        linkedAgents?.length
          ? agents.filter(
              (agent) =>
                !linkedAgents.some((linkedAgent) => linkedAgent.id === agent.id)
            )
          : agents
      );
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const getLinkedAgents = async () => {
    setIsLoadingLinkedAgents(true);

    try {
      const { agents } =
        user?.linked_agents?.length > 0
          ? await apiDataServiceAgents.getAll({
              token: demo ? undefined : await getAccessTokenSilently(),
              userId: demo ? 1 : userId,
              portalId: demo ? 'demo' : portalId,
              params: [
                `linked_agents=${encodeURIComponent(user.linked_agents)}`,
                'sort_by=level0',
              ],
            })
          : { agents: [] };

      setLinkedAgents(agents);

      getAgents(agents);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoadingLinkedAgents(false);
    }
  };

  const validationFunc = (value, title) => {
    const presence = validatePresenceRequired(value, title);
    const contains = validateContainsALetter(lettersOnly(value), title);

    const validations = [];

    if (presence) validations.push(presence);
    if (contains) validations.push(contains);

    if (validations.length) return [validations[0]];
    return null;
  };

  const handleChangeUser = (prop, value) => {
    if (prop === 'first_name' || prop === 'last_name') {
      const newVal = value;

      const isBannedCharsText = isBannedChars(
        newVal,
        prop === 'first_name' ? 'First Name' : 'Last Name'
      );

      if (isBannedCharsText)
        return alert.error(isBannedCharsText, { timeout: 10000 });

      const letters = lettersOnly(value);

      const newUsername =
        prop === 'first_name'
          ? `${capitalise(
              lettersOnly(user.last_name).toLowerCase()
            )}${capitalise(letters.slice(0, 1))}`
          : `${capitalise(letters.toLowerCase())}${capitalise(
              lettersOnly(user.first_name).slice(0, 1)
            )}`;

      handleChangeUser('username', newUsername);
    }

    setUser((curr) => ({ ...curr, [prop]: value }));

    setChanges((curr) => {
      const newChanges = { ...curr, [prop]: value };

      if (prop === 'role') {
        newChanges.permissionsChanged = false;
        newChanges.usingRoleDefaultPermissions = true;
        newChanges.copyFromUser = false;
      }

      return newChanges;
    });

    if (prop === 'role') setCopyFromUser(false);
  };

  const handleLinkAgent = () => {
    setLinkedAgents((curr) => {
      const newLinkedAgents = [
        ...curr,
        allAgents.find((agent) => agent.alt_id === parseInt(agentToLink)),
      ];

      setChanges((curr) => ({
        ...curr,
        linked_agents: newLinkedAgents.map((agent) => agent.alt_id),
      }));

      return newLinkedAgents.sort((a, b) => a.level0.localeCompare(b.level0));
    });

    setAllAgents((curr) => {
      return curr.filter((agent) => agent.alt_id !== parseInt(agentToLink));
    });

    setAgentToLink(undefined);
  };

  const handleAddPermission = () => {
    const addedPermissionRoute = allPermissions.find(
      (permission) => permission.id === parseInt(permissionToAdd)
    ).route;

    setChanges((curr) => {
      return {
        ...curr,
        permissionsChanged: true,
        usingRoleDefaultPermissions: false,
      };
    });
    // }

    setPermissions((curr) =>
      [...curr, addedPermissionRoute].sort((a, b) => a.localeCompare(b))
    );

    setPermissionToAdd(undefined);
  };

  const handleRoleDefaults = () => {
    setCopyFromUser(0);

    setPermissions(
      allPermissions
        .filter((permission) => permission[user.role] === true)
        .map(({ route }) => route)
    );

    setChanges((curr) => {
      return {
        ...curr,
        permissionsChanged: false,
        usingRoleDefaultPermissions: true,
        copyFromUser: false,
      };
    });
    // }
  };

  useEffect(() => {
    if (!demo && !userFromDb.permissions?.includes('users.view')) {
      navigate(`/${demo ? 'demo' : portalId}/dashboard`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      getUser();

      getUsers();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (user) getLinkedAgents();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.linked_agents]);

  useEffect(() => {
    setBreadcrumbs([
      ['Dashboard', 'dashboard', '', `/${demo ? 'demo' : portalId}/dashboard`],
      ['Users', 'users', '', `/${demo ? 'demo' : portalId}/users`],
      [
        (user?.first_name && user?.last_name
          ? `${user.first_name} ${user.last_name}`
          : user?.first_name
          ? user.first_name
          : user?.username) || 'User',
        'magnifying-glass',
      ],
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const headerTextUser =
    user?.first_name && user?.last_name
      ? `${user.first_name} ${user.last_name}`
      : user?.first_name
      ? user.first_name
      : user?.username;

  const headerText =
    headerTextUser && user?.role
      ? `${headerTextUser} - ${rolesTranslations[user.role]}`
      : user?.role
      ? rolesTranslations[user.role]
      : '';

  const handleDeleteUser = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    const { users: adminUsers } = await apiDataService.getAll({
      params: [
        `q=${encodeURIComponent('((role==eq::admin&&sub==nt::not set))')}`,
      ],
      portalId: portalId,
      userId: userId,
      token: await getAccessTokenSilently(),
    });

    if (
      adminUsers.length === 1 &&
      parseInt(adminUsers[0].id) === parseInt(id)
    ) {
      return alert.info(
        'Unable to delete as no other admin users belong to this portal. Please assign another user to the role of ADMIN first.',
        { timeout: 10000 }
      );
    }

    const confirmed = await portalConfirmAlert({
      message: `Are you sure you want to delete ${
        headerTextUser ? `'${headerTextUser}'` : 'this user'
      }?`,
    });

    if (!confirmed) return;

    try {
      await apiDataService.gridDeleteMultiple({
        selectedRecordIds: [id],
        portalId: demo ? 'demo' : portalId,
        reqBody: { userId: demo ? 1 : userId },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      alert.success('User Deleted');

      navigate(
        parseInt(id) === parseInt(userId)
          ? '/'
          : `/${demo ? 'demo' : portalId}/users`,
        { state: { isNavigating: true } }
      );
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  if (!user) return <LoadingSpinner />;
  return (
    <MainOnly>
      <Panels>
        <PanelsControlBar>
          {hasChangePermission ? (
            <ButtonStandardNoFill
              margin='5px'
              iconName='trash'
              text='Delete User'
              typeStyle='type-5'
              disabled={isSaving || !user}
              onClick={handleDeleteUser}
            />
          ) : (
            <></>
          )}

          {hasChangePermission ? (
            <ButtonStandardNoFill
              disabled={!Object.keys(changes).length || isSaving || !user}
              iconName='save-solid'
              margin='5px'
              onClick={updateUser}
              text='Save Changes'
              buttonRef={saveButtonRef}
              typeStyle='type-4'
            />
          ) : (
            <></>
          )}
        </PanelsControlBar>

        <PanelsHeaderPrimary iconName='users' text={headerText || 'User'} />

        <PanelsSections>
          <PanelsSection typeStyle='type-2'>
            <InputStandard
              hasPermission={hasChangePermission}
              direction='vertical'
              id='user-data-firstname'
              invalid={validationFunc(user?.first_name, "'First Name'")}
              isRequired
              labelText='First Name'
              marginBottom='5px'
              onChange={(event) => {
                handleChangeUser('first_name', event.target.value);
              }}
              placeholder={'First Name'}
              showInvalid={showInvalid}
              typeStyle='type-2'
              value={user?.first_name || ''}
            />

            <InputStandard
              hasPermission={hasChangePermission}
              direction='vertical'
              id='user-data-lastname'
              invalid={validationFunc(user?.last_name, "'Last Name'")}
              isRequired
              labelText='Last Name'
              marginBottom='5px'
              onChange={(event) => {
                handleChangeUser('last_name', event.target.value);
              }}
              placeholder={'Last Name'}
              showInvalid={showInvalid}
              typeStyle='type-2'
              value={user?.last_name || ''}
            />

            <InputStandard
              hasPermission={hasChangePermission}
              direction='vertical'
              disabled
              id='user-data-username'
              labelText='Username'
              marginBottom='5px'
              placeholder={'Username'}
              typeStyle='type-2'
              value={user?.username || ''}
            />

            <InputStandard
              hasPermission={hasChangePermission}
              direction='vertical'
              id='user-data-email'
              invalid={
                user?.email?.trim()
                  ? null
                  : [validatePresenceRequired(user?.email?.trim(), "'Email'")]
              }
              isRequired
              labelText='Email'
              marginBottom='5px'
              onChange={(event) => {
                handleChangeUser('email', event.target.value);
              }}
              placeholder={'Email'}
              showInvalid={showInvalid}
              typeStyle='type-2'
              value={user?.email || ''}
            />

            <SelectStandard
              hasPermission={hasChangePermission}
              marginBottom='5px'
              id='user-data-role'
              invalid={
                user?.role
                  ? null
                  : [validatePresenceRequired(user?.role, "'Role'")]
              }
              isRequired
              labelText='Role'
              onChange={(event) => {
                const newRole = event.target.value;

                handleChangeUser('role', event.target.value);
                setPermissions(
                  allPermissions
                    .filter((permission) => permission[newRole] === true)
                    .map(({ route }) => route)
                );
              }}
              options={rolesOptions}
              showInvalid={showInvalid}
              value={user?.role || ''}
            />
          </PanelsSection>

          {hasAgentsViewPermission ? (
            <PanelsSection maxHeight='330px' typeStyle='type-2'>
              <div
                className='table-container table-container-table'
                style={{
                  borderBottom: '1px solid #bbbbbb',
                  paddingBottom: '0',
                }}
              >
                <ul className='table'>
                  {isLoadingLinkedAgents ? (
                    <LoadingSpinnerNew />
                  ) : (
                    <>
                      <li className='header-row'>
                        <HeaderSecondary
                          classes='header-cell'
                          text='Linked Agents'
                        />
                      </li>

                      {!linkedAgents?.length ? (
                        <li
                          style={{
                            textAlign: 'center',
                            color: 'rgb(115, 115, 115)',
                          }}
                        >
                          None
                        </li>
                      ) : (
                        <></>
                      )}

                      {linkedAgents?.map((agent, index) => {
                        return (
                          <LinkedAgentsListItem
                            key={agent.id}
                            hasChangePermission={hasChangePermission}
                            agent={agent}
                            demo={demo}
                            firstRow={index === 0}
                            setAllAgents={setAllAgents}
                            setChanges={setChanges}
                            setLinkedAgents={setLinkedAgents}
                          />
                        );
                      })}
                    </>
                  )}
                </ul>
              </div>

              {hasChangePermission ? (
                <FlexContainer marginTop='10px'>
                  <SelectStandard
                    hasPermission={hasChangePermission}
                    labelText='Add Agent'
                    onChange={(event) => {
                      setAgentToLink(event.target.value);
                    }}
                    optionKeyValue={{ key: 'alt_id', value: 'level0' }}
                    options={[
                      { alt_id: 0, level0: '< Add agent >' },
                      ...allAgents,
                    ]}
                    selectStyle={{ maxWidth: '180px' }}
                    value={agentToLink || '0'}
                  />

                  <ButtonIconOnly
                    alignSelf='flex-end'
                    disabled={!agentToLink || agentToLink === '0'}
                    iconName='link'
                    title='Link'
                    margin='0 5px 5px'
                    onClick={handleLinkAgent}
                  />
                </FlexContainer>
              ) : (
                <></>
              )}
            </PanelsSection>
          ) : (
            <></>
          )}

          <PanelsSection
            classes='permissions-panel'
            maxHeight='521px'
            typeStyle='type-2'
          >
            <div
              className='table-container table-container-table'
              style={{
                borderBottom: '1px solid #bbbbbb',
                paddingBottom: '0',
              }}
            >
              <ul className='table'>
                <>
                  <li className='header-row' style={{ position: 'relative' }}>
                    <HeaderSecondary classes='header-cell' text='Permissions' />

                    {hasChangePermission ? (
                      <ButtonStandardNoFill
                        // disabled if just selected a role and not changed any roles
                        classes='role-default-button'
                        onClick={handleRoleDefaults}
                        text='Role Defaults'
                      />
                    ) : (
                      <></>
                    )}
                  </li>

                  {!copyFromUser &&
                    permissions
                      ?.sort((a, b) => a.localeCompare(b))
                      .map((permissionRoute, index) => {
                        return (
                          <PermissionsListItem
                            copyFromUser={copyFromUser}
                            isAdminUserAndUsersRoute={
                              user.role === 'admin' &&
                              (permissionRoute === 'users.change' ||
                                permissionRoute === 'users.view')
                            }
                            key={permissionRoute}
                            hasChangePermission={hasChangePermission}
                            permissionRoute={permissionRoute}
                            firstRow={index === 0}
                            setChanges={setChanges}
                            setPermissions={setPermissions}
                          />
                        );
                      })}
                </>
              </ul>
            </div>

            {hasChangePermission ? (
              <>
                {!copyFromUser && (
                  <FlexContainer marginTop='10px'>
                    <SelectStandard
                      disabled={copyFromUser}
                      hasPermission={hasChangePermission}
                      labelText='Add Permission'
                      onChange={(event) => {
                        setPermissionToAdd(event.target.value);
                      }}
                      optionKeyValue={{ key: 'id', value: 'route' }}
                      options={[
                        { id: 0, route: '< Add permission >' },
                        ...allPermissions
                          .filter(
                            (permission) =>
                              permission[user.role] !== false &&
                              !permissions?.includes(permission.route)
                          )
                          .sort((a, b) => a.route.localeCompare(b.route))
                          .map(({ id, route }) => ({
                            id,
                            route: route
                              .split('.')
                              .map((text) =>
                                text === 'qa'
                                  ? 'QA'
                                  : text === 'own'
                                  ? '(Own)'
                                  : text.includes('_')
                                  ? text
                                      .split('_')
                                      .map((text) =>
                                        text === 'dd' ? 'DD' : capitalise(text)
                                      )
                                      .join(' ')
                                  : capitalise(text)
                              )
                              .join(' - ')
                              .replaceAll(' - (', ' ('),
                          })),
                      ]}
                      selectStyle={{ maxWidth: '180px' }}
                      value={permissionToAdd}
                    />

                    <ButtonIconOnly
                      alignSelf='flex-end'
                      disabled={
                        !permissionToAdd ||
                        permissionToAdd === '0' ||
                        copyFromUser
                      }
                      iconName='plus'
                      title='Add'
                      margin='0 5px 5px'
                      onClick={handleAddPermission}
                    />
                  </FlexContainer>
                )}

                <FlexContainer marginTop='10px'>
                  <SelectStandard
                    labelText='Copy From User'
                    hasPermission={hasChangePermission}
                    onChange={(event) => {
                      if (event.target.value === '0') {
                        setCopyFromUser(false);

                        setChanges((curr) => ({
                          ...curr,
                          copyFromUser: false,
                        }));
                      } else {
                        setCopyFromUser(event.target.value);

                        setChanges((curr) => ({
                          ...curr,
                          copyFromUser: event.target.value,
                        }));
                      }
                    }}
                    optionKeyValue={{ key: 'id', value: 'username' }}
                    options={[
                      { id: 0, username: '< Copy From User >' },
                      ...users.filter(
                        ({ id, role }) => role === user?.role && id !== user?.id
                      ),
                    ]}
                    selectStyle={{ maxWidth: '180px' }}
                    value={copyFromUser}
                  />

                  <ButtonIconOnly
                    alignSelf='flex-end'
                    disabled={!copyFromUser || copyFromUser === '0'}
                    iconName='minus'
                    title='Clear'
                    margin='0 5px 5px'
                    onClick={() => {
                      setCopyFromUser(0);

                      setChanges((curr) => ({
                        ...curr,
                        copyFromUser: false,
                      }));
                    }}
                  />
                </FlexContainer>
              </>
            ) : (
              <></>
            )}
          </PanelsSection>
        </PanelsSections>
      </Panels>
    </MainOnly>
  );
};

export default UsersShow;
