import AboutPanel from './AboutPanel';
import addReportToDashboard from '../../images/add_report_to_dashboard.png';
import BrandingFeatures from './BrandingFeatures';
import calibrationAreas from '../../images/calibration_areas.png';
import calibrationReport from '../../images/calibration_report.png';
import CalibrationsFeatures from './CalibrationsFeatures';
import callCenter from '../../images/call_center.png';
import DashboardFeatures from './DashboardFeatures';
import editAdminSec from '../../images/edit_admin_sec.png';
import editDashboard from '../../images/edit_dashboard.png';
import FlexContainer from '../Elements/FlexContainer';
import focusParameters from '../../images/focus_parameters.png';
import focusResults from '../../images/focus_results.png';
import IntegrationFeatures from './IntegrationFeatures';
import laptopDashboard from '../../images/laptop-dashboard.png';
import manWithMagnifyingGlass from '../../images/man_with_magnifying_glass.png';
import mobileEvaluation from '../../images/mobile-evaluation.png';
import mobileScorecard from '../../images/mobile-scorecard.png';
import monitorAgent from '../../images/monitor-agent.png';
// import paul from '../../images/paul.jpg';
import reports2d from '../../images/reports_2d_no_background_no_magnifying_glass.png';
import reports3d from '../../images/reports_3d.png';
import ReportsFeatures from './ReportsFeatures';
import reportShow from '../../images/report_show.png';
import risk from '../../images/risk.png';
import RiskFeatures from './RiskFeatures';
import scorecardOptions from '../../images/scorecard_options.png';
import scorecardPreview from '../../images/scorecard_preview.png';
import ScorecardsFeatures from './ScorecardsFeatures';
import scoringSec from '../../images/scoring_sections.png';
import tabletMenu from '../../images/tablet-menu.png';

import './Contact.css';
import './About.css';

const About = () => {
  return (
    <div className='contact-screen home-screen'>
      <div className='about-screen-header-images'>
        <img
          alt=''
          className='about-screen-header-image-laptop-dashboard'
          src={laptopDashboard}
        />

        <img
          alt=''
          className='about-screen-header-image-mobile'
          src={mobileEvaluation}
        />
      </div>

      <h1 className='about-screen-header'>QA Portal</h1>

      <h1 className='about-screen-large-header'>Call the shots</h1>

      <h1 className='about-screen-large-header'>on quality</h1>

      <FlexContainer
        classes='call-centre-image-and-text-container'
        alignSelf='center'
        flexWrap='wrap'
      >
        <img alt='' className='call-centre-image' src={callCenter} />

        <p className='main-qaportal-about-text-1'>
          Customisable and flexible, the QA Portal gives you freedom.
        </p>

        <p className='main-qaportal-about-text-2'>
          Empowering you to enhance customer interactions and operational
          efficiency, driving performance and customer satisfaction.
        </p>

        <img alt='' className='call-centre-image' src={reports3d} />

        <p className='main-qaportal-about-text-3'>
          Streamlining the quality assurance process, QA Portal has robust
          features for call monitoring, scorecard creation, insightful
          reporting, and team calibration.
        </p>
      </FlexContainer>

      <div className='device-text-and-image-container'>
        <div className='device-text-and-image' style={{ maxWidth: '900px' }}>
          <h1
            className='about-screen-large-header'
            style={{ marginRight: 'auto', textAlign: 'left' }}
          >
            At your desk...
          </h1>

          <img
            alt=''
            className='about-screen-header-image-monitor'
            src={monitorAgent}
          />
        </div>

        <div
          className='device-text-and-image'
          style={{ flexWrap: 'wrap-reverse', alignSelf: 'center' }}
        >
          <img
            alt=''
            className='about-screen-header-image-tablet'
            src={tabletMenu}
          />

          <h1
            className='about-screen-large-header'
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            ...in a meeting...
          </h1>
        </div>

        <div
          className='device-text-and-image device-text-and-image-mobile'
          style={{
            alignSelf: 'flex-end',
            justifyContent: 'flex-end',
            maxWidth: '550px',
          }}
        >
          <h1
            className='about-screen-large-header'
            style={{ flex: '1', textAlign: 'right' }}
          >
            ...on the go
          </h1>

          <img alt='' src={mobileScorecard} />
        </div>
      </div>

      <p className='main-qaportal-about-text-4'>
        Accessible on all devices. So you can always get the information you
        need.
      </p>

      <FlexContainer
        classes='call-centre-image-and-text-container'
        alignSelf='center'
        flexWrap='wrap'
      >
        <p className='main-qaportal-about-text-5'>
          With new features being added all the time, use the QA Portal to
          unlock the full potential of your call centre's quality assurance
          efforts.
        </p>

        <img alt='' className='call-centre-image' src={reports2d} />
      </FlexContainer>

      <FlexContainer
        classes='closer-look-image-and-text-container'
        alignSelf='center'
        flexWrap='wrap'
      >
        <p className='main-qaportal-about-text-6'>
          Take a closer look at some of the key features below:
        </p>

        <img alt='' src={manWithMagnifyingGlass} />
      </FlexContainer>

      <AboutPanel
        headerText='Scorecards'
        id='about-scorecards'
        images={[
          { src: scorecardPreview, alt: '' },
          { src: editAdminSec, alt: '' },
          { src: scorecardOptions, alt: '' },
          { src: scoringSec, alt: '' },
        ]}
        link='/scorecards'
      >
        <ScorecardsFeatures />
      </AboutPanel>

      <AboutPanel
        featureGridOption='reportsFeatures'
        headerText='Reports'
        id='about-reports'
        images={[
          { src: reportShow, alt: '' },
          { src: focusParameters, alt: '' },
          { src: focusResults, alt: '' },
        ]}
        link='/reports'
        swap
      >
        <ReportsFeatures />
      </AboutPanel>

      <AboutPanel
        featureGridOption='calibrationsFeatures'
        headerText='Calibrations'
        id='about-calibrations'
        images={[
          { src: calibrationReport, alt: '' },
          { src: calibrationAreas, alt: '' },
        ]}
        link='/calibrations'
      >
        <CalibrationsFeatures />
      </AboutPanel>

      <AboutPanel
        featureGridOption='dashboardFeatures'
        headerText='Dashboard'
        id='about-dashboard'
        images={[
          { src: editDashboard, alt: '' },
          { src: addReportToDashboard, alt: '' },
        ]}
        link='/dashboard'
        swap
      >
        <DashboardFeatures />
      </AboutPanel>

      <AboutPanel
        featureGridOption='riskFeatures'
        headerText='Risk Analysis'
        id='about-risk'
        images={[{ src: risk, alt: '' }]}
        link='/reports/risk'
      >
        <RiskFeatures />
      </AboutPanel>

      <AboutPanel
        featureGridOption='integrationFeatures'
        headerText='Integration'
        id='about-integration'
        link='/imports'
      >
        <IntegrationFeatures />
      </AboutPanel>

      <AboutPanel
        featureGridOption='brandingFeatures'
        headerText='Branding'
        id='about-branding'
      >
        <BrandingFeatures />
      </AboutPanel>

      <div style={{ marginBottom: '120px' }}></div>
    </div>
  );
};

export default About;
