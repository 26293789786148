import TextBreak from '../../Elements/TextBreak';

import './OptionsTemplatesListItem.css';

const OptionsTemplatesListItem = ({
  description,
  handleChangeSelectedTemplate,
  id,
  name,
  selectedTemplateUniqueId,
  type,
  uniqueId,
  vcid,
}) => {
  return (
    <div
      className={`options-templates-list-item grid-card ${
        selectedTemplateUniqueId === uniqueId ? 'selected-options-template' : ''
      }`}
      onClick={() => handleChangeSelectedTemplate(type, id, vcid)}
    >
      <p
        className={`grid-card-header ${
          description?.length > 0 ? '' : 'grid-card-header-no-bottom'
        }`}
      >
        {name}
      </p>

      {description?.length > 0 && (
        <TextBreak
          classes='options-templates-list-item-description'
          text={description}
        />
      )}
    </div>
  );
};

export default OptionsTemplatesListItem;
