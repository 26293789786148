import {
  isBannedChars,
  validatePresenceRequired,
} from '../../../utils/helpers';
import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';

import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import InputStandard from '../../Elements/InputStandard';
import SelectStandard from '../../Elements/SelectStandard';
import Toggle from '../../Elements/Toggle';
import updateAdminSections from '../../../utils/createScorecardUtils/updateAdminSections';

import './CreateAdminSectionForm.css';

const CreateAdminSectionForm = ({
  adminSections,
  ddLists,
  setAdminSections,
  sectionName,
  setSectionName,
  control,
  setControl,
  dropdownList,
  setDropdownList,
  setIsNewListChecked,
  isRequired,
  setIsRequired,
  currentlyEditing,
  setCurrentlyEditing,
  setShouldScrollToBtm,
  handleSetHasChanges,
}) => {
  const alert = useAlert();

  const adminSecNameRef = useRef(null);

  useEffect(() => {
    if (currentlyEditing) adminSecNameRef.current.focus();
  }, [currentlyEditing]);

  const [showInvalidSection, setShowInvalidSection] = useState();
  const [sectionNameInvalid, setSectionNameInvalid] = useState();
  const [controlTypeInvalid, setControlTypeInvalid] = useState();
  const [dropdownListInvalid, setDropdownListInvalid] = useState();

  useEffect(() => {
    const val = validatePresenceRequired(sectionName, "'Section Title'");

    if (val) setSectionNameInvalid([val]);
    else setSectionNameInvalid(null);
  }, [sectionName]);

  useEffect(() => {
    const val = validatePresenceRequired(dropdownList, "'Drop Down Data'");

    if (val) setDropdownListInvalid([val]);
    else setDropdownListInvalid(null);
  }, [dropdownList]);

  useEffect(() => {
    const val = validatePresenceRequired(control, "'Control Type'");

    if (val) setControlTypeInvalid([val]);
    else setControlTypeInvalid(null);

    if (control === 'Drop down') {
      const val = validatePresenceRequired(dropdownList, "'Drop Down Data'");

      if (val) setDropdownListInvalid([val]);
      else setDropdownListInvalid(null);
    } else {
      setDropdownListInvalid(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control]);

  const handleSectionNameChange = (event) => {
    const newVal = event.target.value;

    const isBannedCharsText = isBannedChars(newVal, 'Section Title');

    if (isBannedCharsText)
      return alert.error(isBannedCharsText, { timeout: 10000 });

    setSectionName(newVal);
  };
  const handleControlChange = (event) => {
    const ddSelection = event.target.value;

    setControl(() => {
      return ddSelection === '< Control Type >' ? '' : ddSelection;
    });

    if (ddSelection !== 'Drop down') {
      setDropdownList('');
      setIsNewListChecked(false);
    }

    if (ddSelection === 'Check box') setIsRequired(false);
  };

  const handleChangeRequired = () => {
    setIsRequired((curr) => !curr);
  };

  const handleSaveAdminSection = () => {
    if (sectionNameInvalid || controlTypeInvalid || dropdownListInvalid) {
      return setShowInvalidSection(true);
    } else {
      setShowInvalidSection(false);

      handleSetHasChanges?.();
    }

    const addedSection = sectionName.trim();

    const { overwrittenRef, overwrittenSec } = updateAdminSections({
      add: {
        type: 'admin-section',
        adminSections: adminSections,
        setAdminSections: setAdminSections,
        sectionName: addedSection,
        setSectionName: setSectionName,
        control: control,
        setControl: setControl,
        isRequired: isRequired,
        setIsRequired: setIsRequired,
        dropdownList: dropdownList,
        setDropdownList: setDropdownList,
        currentlyEditing: currentlyEditing,
        setCurrentlyEditing: setCurrentlyEditing,
        setShouldScrollToBtm: setShouldScrollToBtm,
      },
    });

    if (overwrittenRef)
      alert.success(`Overwritten: ${overwrittenSec}`, { timeout: 5000 });
    else alert.success(`Added: ${addedSection}`, { timeout: 5000 });
  };

  return (
    <form
      className='add-admin-section-form'
      onSubmit={(event) => {
        event.preventDefault();

        handleSaveAdminSection();
      }}
    >
      <div className='add-admin-section-form-controls'>
        <InputStandard
          inputClasses={currentlyEditing ? 'edit-section-background' : ''}
          elementRef={adminSecNameRef}
          id='admin-section-title-input'
          invalid={sectionNameInvalid}
          isRequired
          labelText='Section Title'
          name='section-name'
          onChange={handleSectionNameChange}
          placeholder='< Section Title >'
          value={sectionName}
          showInvalid={showInvalidSection}
        />

        <SelectStandard
          id='admin-section-control-input'
          invalid={controlTypeInvalid}
          isPlaceholderSelected={!control}
          isRequired
          labelText='Control Type'
          onChange={handleControlChange}
          options={[
            '< Control Type >',
            'Check box',
            'Date',
            'Drop down',
            'Number',
            'Text box',
            'Time',
          ]}
          selectClasses={currentlyEditing ? 'edit-section-background' : ''}
          showInvalid={showInvalidSection}
          value={control}
        />

        {control === 'Drop down' && (
          <SelectStandard
            id='admin-section-dd-input'
            invalid={dropdownListInvalid}
            isPlaceholderSelected={!dropdownList}
            isRequired
            labelText='Drop Down Data'
            onChange={(event) => setDropdownList(event.target.value)}
            options={[
              { code: '', name: '< Drop Down Data >' },
              ...ddLists.map((ddList) => {
                const uniqueRef = `${ddList.id},${ddList.table},${ddList.name}`;

                return { code: uniqueRef, name: ddList.name };
              }),
            ]}
            selectClasses={currentlyEditing ? 'edit-section-background' : ''}
            showInvalid={showInvalidSection}
            value={dropdownList}
          />
        )}

        {control !== 'Check box' && (
          <Toggle
            id='admin-section-required-input'
            labelText='Required?'
            onChange={handleChangeRequired}
            sliderClasses={currentlyEditing ? 'edit-section-background' : ''}
            toggled={isRequired}
          />
        )}
      </div>

      <FlexContainer flexWrap='wrap'>
        <ButtonStandardNoFill
          alignSelf='center'
          classes='admin-section-add-section-button'
          iconName={currentlyEditing ? 'circle-check' : 'circle-plus'}
          margin='10px 0'
          text={currentlyEditing ? 'Confirm Edit' : 'Add section'}
          type='submit'
          typeStyle='type-4'
        />

        {currentlyEditing ? (
          <ButtonIconOnly
            iconName='xmark'
            marginLeft='10px'
            onClick={() => setCurrentlyEditing('')}
            title='Cancel edit section'
          />
        ) : (
          <></>
        )}
      </FlexContainer>
    </form>
  );
};

export default CreateAdminSectionForm;
