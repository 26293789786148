import FlexContainer from '../../Elements/FlexContainer';
import SelectStandard from '../../Elements/SelectStandard';

import './ReportFilter.css';

const GroupingItem = ({
  grouping,
  handleChangeGrouping,
  itemLabel,
  itemData,
  uniqueRef,
}) => {
  const { source_table, title, control } = itemData;

  const isDateField =
    (source_table === 'evals' && title === 'Created Date') ||
    (source_table === 'admin' && control === 'Date') ||
    (source_table === 'evals' && title === 'Last Modified Date');

  return (
    <FlexContainer margin='5px'>
      <label className='agg-percent-label' htmlFor={uniqueRef}>
        {title}
      </label>

      <input
        checked={grouping[itemLabel] || false}
        id={uniqueRef}
        type='checkbox'
        onChange={() =>
          handleChangeGrouping(
            itemLabel,
            isDateField,
            null,
            source_table,
            title,
            control
          )
        }
      />

      {grouping[itemLabel] && isDateField ? (
        <SelectStandard
          margin='0 5px'
          onChange={(event) =>
            handleChangeGrouping(itemLabel, isDateField, event.target.value)
          }
          options={[
            { code: 'day', name: 'Daily' },
            { code: 'week', name: 'Weekly' },
            { code: 'month', name: 'Monthly' },
            { code: 'year', name: 'Yearly' },
          ]}
          value={grouping[itemLabel].dateFrequency}
        />
      ) : (
        <></>
      )}
    </FlexContainer>
  );
};

export default GroupingItem;
