import Icon from './Icon';

import './HeaderSecondary.css';

const HeaderSecondary = ({
  alignSelf,
  classes = '',
  iconName,
  margin,
  marginRight,
  marginTop,
  marginBottom,
  text = '',
  textAlign,
  width,
  overflowX,
  textDecoration,
}) => {
  const style = {};

  if (alignSelf) style.alignSelf = alignSelf;
  if (margin) style.margin = margin;
  if (marginRight) style.marginRight = marginRight;
  if (marginTop) style.marginTop = marginTop;
  if (marginBottom) style.marginBottom = marginBottom;
  if (textAlign) style.textAlign = textAlign;
  if (width) style.width = width;
  if (overflowX) style.overflowX = overflowX;
  if (textDecoration) style.textDecoration = textDecoration;

  return (
    <h2 className={`header-secondary ${classes}`} style={style}>
      {iconName ? <Icon iconName={iconName} /> : <></>}

      {text}
    </h2>
  );
};

export default HeaderSecondary;
