import AgentsShowContent from './AgentsShowContent';
import MainOnly from '../MainAndOptions/MainOnly';

const AgentsShow = ({ demo }) => {
  return (
    <MainOnly>
      <AgentsShowContent demo={demo} />
    </MainOnly>
  );
};

export default AgentsShow;
