import { invoiceCollectionMethods } from '../../../utils/constants';

import FlexContainer from '../../Elements/FlexContainer';
import moment from 'moment';
import Text from '../../Elements/Text';

const SubscriptionInfo = ({ customer, subscription }) => {
  return (
    <FlexContainer flexWrap='wrap'>
      {customer?.email ? (
        <span
          style={{
            padding: '10px',
            textAlign: 'center',
          }}
        >
          <Text color='#505050' fontWeight='600' text='Email - ' />

          <Text text={customer.email} />
        </span>
      ) : (
        <></>
      )}

      {subscription ? (
        <>
          <span style={{ padding: '10px', textAlign: 'center' }}>
            <Text
              color='#505050'
              fontWeight='600'
              text='Collection Method - '
            />

            <Text
              text={invoiceCollectionMethods[subscription.collection_method]}
            />
          </span>

          <span style={{ padding: '10px', textAlign: 'center' }}>
            <Text color='#505050' fontWeight='600' text='Period Start - ' />

            <Text
              text={moment
                .unix(subscription.current_period_start)
                .format('Do MMMM YYYY')}
            />
          </span>

          <span style={{ padding: '10px', textAlign: 'center' }}>
            <Text color='#505050' fontWeight='600' text='Period End - ' />

            <Text
              text={moment
                .unix(subscription.current_period_end)
                .format('Do MMMM YYYY')}
            />
          </span>
        </>
      ) : (
        <></>
      )}
    </FlexContainer>
  );
};

export default SubscriptionInfo;
