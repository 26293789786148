import {
  evaluationsAvailableColumns,
  evaluationsDefaultColumns,
  evaluationsUnEditableColumns,
} from '../columns';
import apiDataService from '../../../api/evaluations';
import DataGrid from '../DataGrid/DataGrid';

const Evaluations = ({ demo }) => {
  const getAvailableColumns = (columns = {}) => {
    return { ...evaluationsAvailableColumns, ...columns };
  };

  const defaultOptions = {
    customColumns: null,
    limit: 10,
    page: 1,
    query: null,
    sortBy: null,
    view: 'table',
  };

  const selectFrom = {
    status: [
      ['completed', 'Completed'],
      // ['benchmark', 'Benchmark'],
      // ['draft', 'Draft'],
    ],
    brag: [
      ['green', 'Green'],
      ['amber', 'Amber'],
      ['red', 'Red'],
    ],
    pass_fail: [
      ['pass', 'Pass'],
      ['fail', 'Fail'],
    ],
  };

  return (
    <DataGrid
      apiDataService={apiDataService}
      defaultColumns={evaluationsDefaultColumns}
      defaultOptions={defaultOptions}
      demo={demo}
      getAvailableColumns={getAvailableColumns}
      gridName='evaluations'
      iconName='evaluations'
      newScreenRoute='new'
      selectFrom={selectFrom}
      unEditableColumns={evaluationsUnEditableColumns}
    />
  );
};

export default Evaluations;
