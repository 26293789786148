import { useAlert } from 'react-alert';
import { useContext, useState } from 'react';
import { UserPortal } from '../../../contexts';

import AddUsersListItem from './AddUsersListItem';
import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import ExpandableSection from '../../Elements/ExpandableSection';
import FlexContainer from '../../Elements/FlexContainer';
import GridOption from '../DataGrid/GridContent/GridOption';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import PanelsSection from '../../Elements/PanelsSection';
import SelectStandard from '../../Elements/SelectStandard';

const AddUsers = ({ addedUsers, demo, isShowScreen, setAddedUsers, users }) => {
  const { userFromDb } = useContext(UserPortal);

  const alert = useAlert();
  const [userToAdd, setUserToAdd] = useState();
  const [showUsersWithAccess, setShowUsersWithAccess] = useState();

  const hasUsersViewPermission =
    demo || userFromDb.permissions?.includes('users.view');

  const handleAddUser = () => {
    if (addedUsers.some((user) => parseInt(user.id) === parseInt(userToAdd)))
      return alert.show('Already added');

    setAddedUsers((curr) =>
      [
        ...curr,
        {
          id: userToAdd,
          username: users.find(
            (user) => parseInt(user.id) === parseInt(userToAdd)
          ).username,
        },
      ].sort((a, b) => a.username.localeCompare(b.username))
    );

    setUserToAdd('0');
  };

  return (
    <PanelsSection>
      <GridOption
        gridOption='usersWithAccess'
        headerText='Users With Access'
        setShowGridOption={setShowUsersWithAccess}
        showGridOption={showUsersWithAccess}
      />

      <ExpandableSection isOpen={showUsersWithAccess}>
        <FlexContainer classes='report-aggregate-area' flexDirection='column'>
          {addedUsers?.length > 0 && (
            <>
              <HeaderSecondary
                classes='header-cell'
                marginTop='10px'
                marginBottom='7px'
                text='Added Users'
              />

              <div
                className='table-container table-container-table'
                style={{
                  borderBottom: '1px solid #bbbbbb',
                  borderTop: '1px solid #bbbbbb',
                  paddingBottom: '0',
                  paddingTop: '0',
                  maxHeight: '450px',
                  overflow: 'auto',
                }}
              >
                <ul className='table'>
                  {addedUsers.map(({ id, username }, index) => {
                    return (
                      <AddUsersListItem
                        key={id}
                        addedUsers={addedUsers}
                        demo={demo}
                        hasUsersViewPermission={hasUsersViewPermission}
                        id={id}
                        isShowScreen={isShowScreen}
                        firstRow={index === 0}
                        setAddedUsers={setAddedUsers}
                        username={username}
                      />
                    );
                  })}
                </ul>
              </div>
            </>
          )}

          <FlexContainer marginTop='10px'>
            <SelectStandard
              labelText='Add User'
              onChange={(event) => {
                setUserToAdd(event.target.value);
              }}
              optionKeyValue={{
                key: 'id',
                value: 'username',
              }}
              options={[{ id: '0', username: '< Add user >' }, ...users]}
              selectStyle={{ maxWidth: '180px' }}
              value={userToAdd}
            />

            <ButtonIconOnly
              alignSelf='flex-end'
              disabled={!userToAdd || userToAdd === '0'}
              iconName='plus'
              title='Add'
              margin='0 0 0 10px'
              onClick={handleAddUser}
            />
          </FlexContainer>
        </FlexContainer>
      </ExpandableSection>
    </PanelsSection>
  );
};

export default AddUsers;
