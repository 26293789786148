const operators = {
  // don't allow '=' or ':' in operators - can break where clauses
  codeToValue: {
    bet: 'between',
    eq: 'equals',
    gt: 'greater than',
    gte: 'greater than or equal to',
    lk: 'contains',
    lt: 'less than',
    lte: 'less than or equal to',
    nbet: 'not between',
    nlk: "doesn't contain",
    nt: "doesn't equal",
    null: 'is empty',
    ntnull: "isn't empty",
  },
  codeToValueDatetime: {
    bet: 'between',
    eq: 'equals',
    gt: 'after',
    gte: 'after or equal to',
    lk: 'contains',
    lt: 'before',
    lte: 'before or equal to',
    nbet: 'not between',
    nlk: "doesn't contain",
    nt: "doesn't equal",
    prev: 'during previous',
    wk: 'this week',
    lwk: 'last week',
    mnth: 'this month',
    lmnth: 'last month',
    yr: 'this year',
    lyr: 'last year',
    null: 'is empty',
    ntnull: "isn't empty",
  },
  array: {
    options: ['lk', 'nlk', 'null', 'ntnull'],
    default: 'lk',
  },
  int: {
    options: [
      'bet',
      'nbet',
      'eq',
      'nt',
      'lt',
      'lte',
      'gt',
      'gte',
      'null',
      'ntnull',
    ],
    default: 'eq',
  },
  string: {
    options: ['eq', 'nt', 'lk', 'nlk', 'null', 'ntnull'],
    default: 'lk',
  },
  bool: { options: ['eq', 'nt', 'null', 'ntnull'], default: 'eq' },
  'string bool': { options: ['eq', 'nt', 'null', 'ntnull'], default: 'eq' },
  datetime: {
    options: [
      'bet',
      'nbet',
      'eq',
      'nt',
      'lt',
      'lte',
      'gt',
      'gte',
      'prev',
      'wk',
      'lwk',
      'mnth',
      'lmnth',
      'yr',
      'lyr',
      'null',
      'ntnull',
    ],
    default: 'bet',
  },
  time: {
    options: [
      'bet',
      'nbet',
      'eq',
      'nt',
      'lt',
      'lte',
      'gt',
      'gte',
      'null',
      'ntnull',
    ],
    default: 'bet',
  },
  selectFrom: { options: ['eq', 'nt', 'null', 'ntnull'], default: 'eq' },
};

export default operators;
