import { apiUrl, headers } from './config';

const calibrationsApi = {
  getAll: async ({ params, portalId, token, userId }) => {
    const baseUrl = `/api/portals/${portalId}/calibrations`;
    const urlParams = `${params.length ? `?${params.join('&')}` : ''}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getCalibrationById: async ({ calibrationId, portalId, token, userId }) => {
    const url = `/api/portals/${portalId}/calibrations/${calibrationId}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getDataForNewCalibration: async ({ portalId, token, userId }) => {
    const url = `/api/portals/${portalId}/calibrations/new`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  gridDeleteMultiple: async ({
    selectedRecordIds,
    portalId,
    reqBody,
    token,
  }) => {
    reqBody.deletes = selectedRecordIds;

    const url = `/api/portals/${portalId}/calibrations`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  gridUpdate: async ({ gridChanges, portalId, reqBody, token }) => {
    reqBody.calibrations = Object.entries(gridChanges).map(([id, changes]) => ({
      id,
      ...changes,
    }));

    const url = `/api/portals/${portalId}/calibrations`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  post: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/calibrations`;

    const { data } = await apiUrl.post(url, reqBody, headers({ token }));

    return data;
  },

  updateCalibrations: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/calibrations`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  updateParticipants: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/calibration-participants`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },
};

export default calibrationsApi;
