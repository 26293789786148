import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { scrollToBottom } from '../../../utils/createScorecardUtils/createScorecardUtils';
import { useDrop } from 'react-dnd';

import ButtonToggle from '../../Elements/ButtonToggle';
import FormInfoText from '../../Elements/FormInfoText';
import ScoringSection from './ScoringSection';
import update from 'immutability-helper';

import './ScoringSectionsContainer.css';

const ScoringSectionsContainer = memo(function Container({
  edgeMargins,
  cards,
  categoryCount,
  hasChangePermission,
  sectionCount,
  setCards,
  setCategoryName,
  setSectionName,
  setSectionDescription,
  setWeight,
  setRa,
  setCsm,
  setIsRequired,
  currentlyEditingCat,
  setCurrentlyEditingCat,
  currentlyEditingSec,
  setCurrentlyEditingSec,
  setCategoryCount,
  setSectionCount,
  shouldScrollToBtm,
  setShouldScrollToBtm,
  handleSetHasChanges,
}) {
  const scrollableSections = useRef(null);

  const [isDragOn, setIsDragOn] = useState(true);

  useEffect(() => {
    if (shouldScrollToBtm) {
      scrollToBottom(scrollableSections);
      setShouldScrollToBtm(false);
    }
  }, [shouldScrollToBtm, setShouldScrollToBtm]);

  const ItemTypes = {
    CARD: 'scoring-card',
  };

  const findCard = useCallback(
    (id) => {
      const card = cards.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards]
  );
  const moveCard = useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id);

      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      );
    },
    [findCard, cards, setCards]
  );
  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

  return (
    <div className='scoring-sections-container' ref={drop}>
      <ul
        ref={scrollableSections}
        className='create-scoring-sections-list'
        style={{
          marginLeft: edgeMargins.left,
          marginRight: edgeMargins.right,
        }}
      >
        {cards.map((card) => (
          <ScoringSection
            key={card.id}
            id={`${card.id}`}
            moveCard={moveCard}
            findCard={findCard}
            hasChangePermission={hasChangePermission}
            isDndOn={hasChangePermission && isDragOn}
            section={card}
            scoringSections={cards}
            setScoringSections={setCards}
            setCategoryName={setCategoryName}
            setSectionName={setSectionName}
            setSectionDescription={setSectionDescription}
            setWeight={setWeight}
            setRa={setRa}
            setCsm={setCsm}
            setIsRequired={setIsRequired}
            currentlyEditingCat={currentlyEditingCat}
            setCurrentlyEditingCat={setCurrentlyEditingCat}
            currentlyEditingSec={currentlyEditingSec}
            setCurrentlyEditingSec={setCurrentlyEditingSec}
            setCategoryCount={setCategoryCount}
            setSectionCount={setSectionCount}
            handleSetHasChanges={handleSetHasChanges}
          />
        ))}
      </ul>

      <div className='added-scoring-section-counters'>
        <ButtonToggle
          margin='10px'
          onClick={() => setIsDragOn((curr) => !curr)}
          text={`Dragging - ${isDragOn ? 'On' : 'Off'}`}
          toggled={isDragOn}
        />

        {categoryCount > 0 && (
          <FormInfoText
            style={{ margin: '10px' }}
            text={
              categoryCount === 1
                ? `${categoryCount} category`
                : `${categoryCount} categories`
            }
          />
        )}
        {sectionCount > 0 && (
          <FormInfoText
            style={{ margin: '10px' }}
            text={
              sectionCount === 1
                ? `${sectionCount} section`
                : `${sectionCount} sections`
            }
          />
        )}
      </div>
    </div>
  );
});

export default ScoringSectionsContainer;
