import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const GeneralBarChart = ({
  barData = [],
  classes = '',
  data,
  hideLegend,
  minMax,
  xDataKey,
  yLabel = '',
  yLabelOffsets = [-65, 30],
}) => {
  const getMinMax = () => {
    if (minMax) return minMax;
    else {
      let max = 0;

      data.forEach((dataItem) => {
        barData.forEach((barItem) => {
          const newVal = parseFloat(dataItem[barItem.label]);

          if (newVal > max) max = newVal;
        });
      });

      return [0, max];
    }
  };

  const CustomYAxisLabel = () => {
    return (
      <text
        className='chart-axis-label'
        x={yLabelOffsets[0]}
        y={yLabelOffsets[1]}
        textAnchor='end'
        transform='rotate(-90)'
      >
        {yLabel}
      </text>
    );
  };

  return (
    <div className={`general-bar-chart ${classes}`}>
      <ResponsiveContainer width='100%'>
        <BarChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />

          <XAxis dataKey={xDataKey} />

          <YAxis
            allowDataOverflow={true}
            domain={getMinMax()}
            label={<CustomYAxisLabel />}
            style={{ fill: '#333333' }}
          />

          <Tooltip
            content={({ label, payload, index }) => {
              if (payload && payload.length) {
                return (
                  <div className='recharts-tooltip-wrapper'>
                    <div
                      className='recharts-default-tooltip'
                      style={{
                        margin: '0',
                        padding: '10px',
                        backgroundColor: 'rgb(255, 255, 255)',
                        border: '1px solid rgb(204, 204, 204)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <p style={{ color: '#505050', fontWeight: '600' }}>
                        {label}
                      </p>

                      {payload.map((entry, index) => (
                        <p
                          key={index}
                          style={{ color: '#333333', margin: '5px 0' }}
                        >{`${entry.dataKey}: ${entry.value}`}</p>
                      ))}
                    </div>
                  </div>
                );
              }
              return null;
            }}
          />

          {!hideLegend && <Legend wrapperStyle={{ bottom: '0' }} />}

          {barData.map(({ label, colour }, index) => {
            return <Bar key={index} dataKey={label} fill={colour} />;
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GeneralBarChart;
