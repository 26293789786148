import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useContext } from 'react';
import { UserPortal } from '../../../../contexts';

import apiDataService from '../../../../api/billing';
import HeaderSecondary from '../../../Elements/HeaderSecondary';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ChangePaymentCard = ({ customerId, subscriptionId }) => {
  const { getAccessTokenSilently } = useAuth0();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const fetchClientSecret = useCallback(async () => {
    const response = await apiDataService.createCheckoutSession({
      customerId,
      portalId,
      subscriptionId,
      token: await getAccessTokenSilently(),
      userId,
    });

    return response.clientSecret;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = { fetchClientSecret };

  return (
    <>
      <HeaderSecondary
        marginBottom='10px'
        text='New Card Details'
        textAlign='center'
      />

      <div id='checkout'>
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </>
  );
};

export default ChangePaymentCard;
