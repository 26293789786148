import { apiUrl, headers } from './config';

const portalsApi = {
  delete: async ({ portalId, token, userId }) => {
    const url = `/api/portals/${portalId}`;

    await apiUrl.delete(url, headers({ token, userId }));
  },
  deleteAccount: async ({ sub, token, userId }) => {
    const url = `/api/delete-account`;

    await apiUrl.delete(url, headers({ sub, token, userId }));
  },
  getById: async ({ portalId, token, userId }) => {
    const url = `/api/portals/${portalId}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },
  post: async ({ reqBody, token }) => {
    const url = `/api/portals`;

    const { data } = await apiUrl.post(url, reqBody, headers({ token }));

    return data;
  },
  update: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },
};

export default portalsApi;
