import { rolesTranslations } from '../../../utils/constants';
import { useState } from 'react';

import GridOption from '../DataGrid/GridContent/GridOption';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import moment from 'moment';
import PanelsSection from '../../Elements/PanelsSection';
import Text from '../../Elements/Text';

const NextInvoice = ({
  collectionMethod,
  currentPeriodEnd,
  currentPeriodStart,
  dailyUsage,
  hasSubscriptions,
  isLoadingInvoices,
  loggedUsage,
}) => {
  const [showNextInvoiceDetails, setShowNextInvoiceDetails] = useState(false);

  const logsRemaining = () => {
    if (!currentPeriodEnd) return;

    const currentMoment = moment();
    const endMoment = moment.unix(currentPeriodEnd);

    let currentLogTime = moment().set({
      hour: 23,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    if (currentMoment.isAfter(currentLogTime)) {
      currentLogTime.add(1, 'day');
    }

    const daysRemaining = endMoment.diff(currentLogTime, 'days') + 1;

    return Math.max(daysRemaining, 0);
  };

  const getEstimate = () => {
    const futureCharges = hasSubscriptions
      ? logsRemaining() * dailyUsage?.[0]
      : dailyUsage?.[0] * 31;

    const pastCharges = loggedUsage?.reduce(
      (sum, item) => sum + item.amount,
      0
    );

    return (((futureCharges || 0) + (pastCharges || 0)) / 100).toFixed(2);
  };

  if (isLoadingInvoices) return <LoadingSpinnerNew />;

  return (
    <PanelsSection>
      <span style={{ margin: '10px 0', textAlign: 'center' }}>
        <Text color='505050' fontWeight='600' text='Estimated Amount: ' />

        <Text text={`£${getEstimate()}`} />
      </span>

      <GridOption
        headerText=''
        showGridOption={showNextInvoiceDetails}
        setShowGridOption={setShowNextInvoiceDetails}
        gridOption='nextInvoiceDetails'
      />

      {showNextInvoiceDetails &&
        (hasSubscriptions ? (
          <>
            <span style={{ margin: '25px 0 30px 0', textAlign: 'center' }}>
              <Text text='Your next invoice is due to be produced on ' />

              <Text
                color='#505050'
                fontWeight='600'
                text={moment.unix(currentPeriodEnd).format('Do MMMM YYYY')}
              />

              <Text text='.' />
            </span>

            {collectionMethod === 'send_invoice' && (
              <span style={{ marginBottom: '30px', textAlign: 'center' }}>
                <Text text='You will need to arrange a manual payment ' />

                <Text color='#505050' fontWeight='600' text='within 30 days' />

                <Text text=' of the invoice being produced.' />
              </span>
            )}

            {collectionMethod === 'charge_automatically' && (
              <Text
                marginBottom='30px'
                text='Payment will be taken automatically using the card details you previously provided.'
                textAlign='center'
              />
            )}

            <Text
              marginBottom='30px'
              text='Your next invoice estimate is based on the number of users you currently have and their tiers, and any charges you have received so far this period.'
              textAlign='center'
            />

            <HeaderSecondary
              marginBottom='10px'
              text='User charges:'
              textAlign='center'
            />

            <ul style={{ alignSelf: 'center' }}>
              {dailyUsage?.[1].split(',').map((chargePart, index) => {
                if (index === 3)
                  return (
                    <li
                      key={index}
                      style={{ color: '#333333', listStyle: 'inside' }}
                    >{`-${chargePart}% discount`}</li>
                  );

                const splitChargePart = chargePart.split('-');

                return (
                  <li
                    key={index}
                    style={{ color: '#333333', listStyle: 'inside' }}
                  >
                    {`${splitChargePart[2]} x ${
                      rolesTranslations[splitChargePart[0]]
                    } user${splitChargePart[2] === '1' ? '' : 's'} @ £${
                      splitChargePart[1]
                    } pppm`}
                  </li>
                );
              })}
            </ul>

            <span
              style={{
                marginBottom: '10px',
                marginTop: '30px',
                textAlign: 'center',
              }}
            >
              <Text
                color='505050'
                fontWeight='600'
                text='Charges for current period so far: '
              />

              <Text
                text={`(${moment
                  .unix(currentPeriodStart)
                  .format('Do MMMM YYYY')} - ${moment
                  .unix(currentPeriodEnd)
                  .format('Do MMMM YYYY')})`}
              />
            </span>

            {loggedUsage?.length ? (
              <div
                className='table-container table-container-table'
                style={{ maxHeight: '300px' }}
              >
                <ul className='table'>
                  <li className='header-row'>
                    {[
                      'Date',
                      'Amount',
                      'QA vol.',
                      'Manager vol.',
                      'Agent vol.',
                      'Discount',
                    ].map((header) => (
                      <HeaderSecondary
                        key={header}
                        classes='header-cell'
                        text={header}
                      />
                    ))}
                  </li>

                  {loggedUsage?.map((usageItem, index) => {
                    const { description } = usageItem;

                    const chargeParts = description.split(',');

                    const qaData = chargeParts[0].split('-');
                    const managerData = chargeParts[1].split('-');
                    const agentData = chargeParts[2].split('-');
                    const discount = parseFloat(chargeParts[3]);

                    return (
                      <li
                        key={usageItem.reported_at}
                        className={`${
                          index === 0 ? 'first-grid-row ' : ''
                        } grid-row permissions-grid-row`}
                      >
                        <div className='grid-row-cell'>
                          {moment
                            .unix(usageItem.reported_at)
                            .format('Do MMMM YYYY')}
                        </div>
                        <div className='grid-row-cell'>{`£${(
                          usageItem.amount / 100
                        ).toFixed(2)}`}</div>
                        <div className='grid-row-cell'>{`${qaData[2]} (£${qaData[1]} pppm)`}</div>
                        <div className='grid-row-cell'>{`${managerData[2]} (£${managerData[1]} pppm)`}</div>
                        <div className='grid-row-cell'>{`${agentData[2]} (£${agentData[1]} pppm)`}</div>
                        <div className='grid-row-cell'>{`${discount}%`}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <Text marginBottom='10px' text='NA' textAlign='center' />
            )}
          </>
        ) : (
          <>
            <Text
              marginTop='25px'
              marginBottom='30px'
              text='The estimated amount is calculated based on the number of users you have and their tiers, for a 31 day month.'
              textAlign='center'
            />

            <Text
              marginBottom='30px'
              text='The amount will vary depending on the number of days in the month.'
              textAlign='center'
            />

            <HeaderSecondary
              marginBottom='10px'
              text='User charges:'
              textAlign='center'
            />

            <ul style={{ alignSelf: 'center', marginBottom: '10px' }}>
              {dailyUsage?.[1].split(',').map((chargePart, index) => {
                if (index === 3)
                  return (
                    <li
                      key={index}
                      style={{ color: '#333333', listStyle: 'inside' }}
                    >{`-${chargePart}% discount`}</li>
                  );

                const splitChargePart = chargePart.split('-');

                return (
                  <li
                    key={index}
                    style={{ color: '#333333', listStyle: 'inside' }}
                  >{`${splitChargePart[2]} x ${
                    rolesTranslations[splitChargePart[0]]
                  } user${splitChargePart[2] === '1' ? '' : 's'} @ £${
                    splitChargePart[1]
                  } pppm`}</li>
                );
              })}
            </ul>
          </>
        ))}
    </PanelsSection>
  );
};

export default NextInvoice;
