export const customError = (msg) => {
  return { response: { custom: msg } };
};

export const handleApiError = ({ response = {} }) => {
  const { data, status, custom } = response;
  if (custom) return custom;

  if (data?.msg) {
    return `${data.msg} - Code: ${status}`;
  }

  if (data?.error) {
    const id = data.error?.id ?? data.error?.ids;

    return `Item: ${data.error?.item}${id ? ` ID: ${id}` : ''} - ${
      data.error?.msg
    }`;
  }

  return 'Unknown error. If this persists please contact us.';
};
