import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import AuthenticationButton from '../Authentication/AuthenticationButton';
import ButtonIcon from '../Elements/ButtonIcon';
import FlexContainer from '../Elements/FlexContainer';
import Icon from '../Elements/Icon';

import './HomeNav.css';

const HomeNav = ({
  handleInstallClick,
  isPromptVisible,
  isNavOpen,
  setIsNavOpen,
  userFromDb,
}) => {
  const { isAuthenticated } = useAuth0();

  const location = useLocation();

  const currentRoute = location.pathname;

  return (
    <>
      {isAuthenticated && userFromDb?.portal_id ? (
        <button
          className={`nav-expander ${
            isNavOpen ? 'nav-expander-nav-open' : ''
          } ignore-detect-click-outside `}
          onClick={() => setIsNavOpen((curr) => !curr)}
        >
          <div className='nav-expander-icon'>
            <Icon
              className='ignore-detect-click-outside'
              iconName='navigation'
            />
          </div>
        </button>
      ) : (
        <></>
      )}

      <FlexContainer classes='home-nav-buttons home-nav-only'>
        <Link to='/about'>
          <button className='nav-left-button'>
            <div
              className={`nav-button-inner ${
                currentRoute === '/about' ? 'selected-nav-button' : ''
              }`}
            >
              About
            </div>
          </button>
        </Link>

        <Link to='/pricing'>
          <button className='nav-left-button'>
            <div
              className={`nav-button-inner ${
                currentRoute === '/pricing' ? 'selected-nav-button' : ''
              }`}
            >
              Pricing
            </div>
          </button>
        </Link>

        {/* <Link to='/faq'>
          <button className='nav-left-button'>
            <div
              className={`nav-button-inner ${
                currentRoute === '/faq' ? 'selected-nav-button' : ''
              }`}
            >
              FAQ's
            </div>
          </button>
        </Link> */}

        <Link to='/contact'>
          <button className='nav-left-button'>
            <div
              className={`nav-button-inner ${
                currentRoute === '/contact' ? 'selected-nav-button' : ''
              }`}
            >
              Contact
            </div>
          </button>
        </Link>

        {isPromptVisible && (
          <ButtonIcon
            buttonClass='nav-left-button install-app-button'
            iconName='install'
            onClick={handleInstallClick}
            title='Install as app'
          />
        )}

        <FlexContainer
          classes={`home-nav-special-buttons ${
            isAuthenticated ? 'home-nav-special-buttons-authenticated' : ''
          }`}
          justifyContent='flex-end'
        >
          <Link
            className='demo-button'
            to='/demo'
            state={{ isNavigating: true }}
          >
            <div className='nav-button-inner'>Demo</div>
          </Link>

          {!isAuthenticated ? (
            <>
              <AuthenticationButton classes='login-button' />
            </>
          ) : (
            <></>
          )}
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export default HomeNav;
