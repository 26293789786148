import { Link } from 'react-router-dom';

import FlexContainer from '../Elements/FlexContainer';
import HeaderPrimary from '../Elements/HeaderPrimary';
import HeaderSecondary from '../Elements/HeaderSecondary';
import Icon from '../Elements/Icon';
import Text from '../Elements/Text';

import './PortalCreated.css';

const PortalCreated = ({ setShouldDisplayPortalCreated }) => {
  return (
    <FlexContainer alignItems='flex-start' classes='portal-created'>
      <FlexContainer classes='portal-created-inner' flexDirection='column'>
        <Icon
          className='portal-created-close'
          iconName='circle-cross'
          onClick={() => setShouldDisplayPortalCreated(false)}
        />

        <HeaderSecondary
          margin='50px 20px 20px'
          text='Your portal has been created!'
          textAlign='center'
        />

        <HeaderPrimary
          margin='0 20px 50px'
          text='Getting Started...'
          style={{ textAlign: 'center' }}
        />

        <FlexContainer margin='0 50px 20px' flexWrap='wrap'>
          <Icon
            style={{
              height: '20px',
              margin: '10px',
              width: '20px',
            }}
            iconName='circle-info'
          />

          <span style={{ textAlign: 'center' }}>
            <Text text='Take a look at our guide ' />

            <Link to='/guide'>here</Link>

            <Text text='.' />
          </span>
        </FlexContainer>

        <HeaderPrimary classes='or-header' text='Or' />

        <FlexContainer margin='0 50px 50px' flexWrap='wrap'>
          <FlexContainer flexWrap='wrap'>
            <Icon
              style={{
                height: '20px',
                margin: '10px',
                width: '20px',
              }}
              iconName='envelope'
            />

            <Icon
              style={{
                height: '20px',
                margin: '10px',
                width: '20px',
              }}
              iconName='phone'
            />
          </FlexContainer>

          <span style={{ textAlign: 'center' }}>
            <Text text='Contact us for help with getting your portal set up, and for any other queries, ' />

            <Link to='/contact'>here</Link>

            <Text text='.' />
          </span>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default PortalCreated;
