import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import apiDataService from '../../../api/scorecards';
import EvaluationsScorecardWrapper from './EvaluationsScorecardWrapper';
import ExpandableSection from '../../Elements/ExpandableSection';
import FlexContainer from '../../Elements/FlexContainer';
import GridOption from '../DataGrid/GridContent/GridOption';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import MainOnly from '../MainAndOptions/MainOnly';
import OptionsTemplates from '../Scorecards/OptionsTemplates';
import Panels from '../../Elements/Panels';
import PanelsSection from '../../Elements/PanelsSection';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';

const EvaluationsNew = ({ demo }) => {
  const navigate = useNavigate();

  const alert = useAlert();

  const { id } = useParams();

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { getAccessTokenSilently } = useAuth0();

  const [scorecards, setScorecards] = useState();

  const [isLoadingScorecards, setIsLoadingScorecards] = useState(false);

  const [showEvalsSections, setShowEvalsSections] = useState(
    id ? '' : 'evaluationsScorecardTemplate'
  );

  const handleSelectScorecard = (type, scorecardId, vcid) => {
    navigate(`/${demo ? 'demo' : portalId}/evaluations/new/` + vcid);
  };

  const getScorecards = async () => {
    setIsLoadingScorecards(true);

    const { scorecards } = await apiDataService.getAll({
      params: [`q=${encodeURIComponent('((status==eq::active))')}`],
      portalId: demo ? 'demo' : portalId,
      userId: demo ? 1 : userId,
      token: demo ? undefined : await getAccessTokenSilently(),
    });

    setScorecards(scorecards);
    setIsLoadingScorecards(false);
  };

  useEffect(() => {
    if (!demo && !userFromDb.permissions?.includes('evaluations.change')) {
      navigate(`/${demo ? 'demo' : portalId}/dashboard`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      getScorecards();
    }

    return () => {
      setScorecards();
      setIsLoadingScorecards();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scorecard = scorecards?.find(
    (scorecard) => scorecard.vcid === parseInt(id)
  );
  const scorecardActualId = scorecard?.id;
  const scorecardName = scorecard?.name;

  useEffect(() => {
    setBreadcrumbs(() => {
      const newBreadcrumbs = [
        [
          'Dashboard',
          'dashboard',
          '',
          `/${demo ? 'demo' : portalId}/dashboard`,
        ],
        [
          'Evaluations',
          'evaluations',
          '',
          `/${demo ? 'demo' : portalId}/evaluations`,
        ],
        [
          'New',
          'plus',
          '',
          `${id ? `/${demo ? 'demo' : portalId}/evaluations/new` : ''}`,
          () => setShowEvalsSections('evaluationsScorecardTemplate'),
        ],
      ];

      if (id) {
        newBreadcrumbs.push([scorecardName, 'scorecards']);
      }

      return newBreadcrumbs;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, scorecardName]);

  return (
    <MainOnly>
      <Panels>
        <PanelsHeaderPrimary
          iconName='evaluations'
          subIcon={'plus'}
          text={`New Evaluation${scorecardName ? ` - ${scorecardName}` : ''}`}
        />

        <PanelsSection>
          <GridOption
            headerText='Scorecards'
            showGridOption={showEvalsSections}
            setShowGridOption={setShowEvalsSections}
            gridOption='evaluationsScorecardTemplate'
          />

          {isLoadingScorecards ? (
            <LoadingSpinner />
          ) : (
            <ExpandableSection
              isOpen={showEvalsSections === 'evaluationsScorecardTemplate'}
            >
              <OptionsTemplates
                handleChangeSelectedTemplate={handleSelectScorecard}
                selectedTemplateUniqueId={`existing${scorecardActualId}`}
                noTemplatesText='No scorecards'
                templates={scorecards}
                type='existing'
              />
            </ExpandableSection>
          )}
        </PanelsSection>

        {!scorecards ? (
          <LoadingSpinner />
        ) : id &&
          scorecards.some(
            ({ id: scorecardId }) =>
              parseInt(scorecardId) === parseInt(scorecardActualId)
          ) ? (
          <FlexContainer
            alignSelf='center'
            padding='20px'
            marginBottom='110px'
            flexDirection='column'
          >
            <EvaluationsScorecardWrapper
              id={id}
              demo={demo}
              handleSelectScorecard={handleSelectScorecard}
              selectedScorecardId={scorecardActualId}
            />
          </FlexContainer>
        ) : (
          <></>
        )}
      </Panels>
    </MainOnly>
  );
};

export default EvaluationsNew;
