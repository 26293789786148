import { useAuth0 } from '@auth0/auth0-react';
import Icon from '../Elements/Icon';

import './Login.css';

const Login = ({ classes = '' }) => {
  const { isLoading, loginWithRedirect } = useAuth0();

  return (
    <button
      className={classes}
      disabled={isLoading}
      onClick={() => loginWithRedirect()}
      title='Log in / Sign up'
    >
      <Icon className='portal-login-icon' iconName='user-solid' />

      <div className='nav-button-inner'>Log In / Sign Up</div>
    </button>
  );
};

export default Login;
