export const navItemsMain = [
  {
    name: 'Agents',
    icon: 'headset',
    link: 'agents',
    permissions: ['agents.view'],
  },
  {
    name: 'Billing',
    icon: 'credit-card',
    link: 'billing',
    permissions: ['billing.view'],
  },
  {
    name: 'Calibrations',
    icon: 'bullseye',
    link: 'calibrations',
  },
  {
    name: 'Dashboard',
    icon: 'dashboard',
    link: 'dashboard',
  },
  {
    name: 'Data Lists',
    icon: 'list-ol',
    link: 'data-lists',
    permissions: ['data_lists.view'],
  },
  {
    name: 'Drafts',
    icon: 'draft',
    link: 'drafts',
  },
  {
    name: 'Evaluations',
    icon: 'evaluations',
    link: 'evaluations',
  },
  {
    name: 'Imports',
    icon: 'file-import',
    link: 'imports',
    permissions: ['agents.change', 'evaluations.change', 'users.change'],
  },
  {
    name: 'Logs',
    icon: 'book-open',
    link: 'logs',
    permissions: ['logs.view'],
  },
  {
    name: 'Org Structure',
    icon: 'sitemap',
    link: 'org-structure',
    permissions: ['org_structure.view'],
  },
  {
    name: 'Reports',
    icon: 'chart-pie',
    link: 'reports',
  },
  {
    name: 'Scorecard Templates',
    icon: 'templates',
    link: 'scorecard-templates',
    permissions: ['scorecard_templates.view'],
  },
  {
    name: 'Scorecards',
    icon: 'scorecards',
    link: 'scorecards',
    permissions: ['scorecards.view'],
  },
  {
    name: 'Users',
    icon: 'users',
    link: 'users',
    permissions: ['users.view'],
  },
];

export const navItemsNew = [
  {
    name: 'Agent',
    icon: 'headset',
    link: 'agents/new',
    subIcon: 'add',
    permissions: ['agents.change'],
  },
  {
    name: 'Calibration',
    icon: 'bullseye',
    link: 'calibrations/new',
    subIcon: 'add',
    permissions: ['calibrations.change'],
  },
  {
    name: 'Data List',
    icon: 'list-ol',
    link: 'data-lists/new',
    subIcon: 'add',
    permissions: ['data_lists.change'],
  },
  {
    name: 'Evaluation',
    icon: 'evaluations',
    link: 'evaluations/new',
    subIcon: 'add',
    permissions: ['evaluations.change'],
  },
  {
    name: 'Report',
    icon: 'chart-pie',
    link: 'reports/new',
    subIcon: 'add',
    permissions: ['reports.change'],
  },
  {
    name: 'Scorecard',
    icon: 'scorecards',
    link: 'scorecards/new',
    subIcon: 'add',
    permissions: ['scorecards.change'],
  },
  {
    name: 'Scorecard Template',
    icon: 'templates',
    link: 'scorecard-templates/new',
    subIcon: 'add',
    permissions: ['scorecard_templates.change'],
  },
  {
    name: 'User',
    icon: 'users',
    link: 'users/new',
    subIcon: 'add',
    permissions: ['users.change'],
  },
];
