import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { handleApiError } from '../../../utils/error-handling';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  shouldNavigateOnNotFound,
  validatePresenceRequired,
} from '../../../utils/helpers';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useRef, useState } from 'react';

import apiDataService from '../../../api/calibrations';
import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import CalibrationResults from './CalibrationResults';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import Icon from '../../Elements/Icon';
import InputStandard from '../../Elements/InputStandard';
import InfoText from '../../Elements/InfoText';
import InputTextareaNew from '../../Elements/InputTextareaNew';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import MainOnly from '../MainAndOptions/MainOnly';
import Panels from '../../Elements/Panels';
import PanelsControlBar from '../../Elements/PanelsControlBar';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import PanelsSections from '../../Elements/PanelsSections';
import ParticipantsListItem from './ParticipantsListItem';
import portalConfirmAlert from '../../../utils/portalConfirmAlert';
import SelectStandard from '../../Elements/SelectStandard';
import Toggle from '../../Elements/Toggle';

import './CalibrationsNew.css';

const CalibrationsNew = ({ demo, isShowScreen }) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  const { getAccessTokenSilently } = useAuth0();
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;
  const hasChangePermission =
    demo || userFromDb.permissions?.includes('calibrations.change');
  const hasUsersViewPermission =
    demo || userFromDb.permissions?.includes('users.view');

  const alert = useAlert();

  const [users, setUsers] = useState([]);
  const [usersNotDeleted, setUsersNotDelete] = useState([]);
  const [scorecards, setScorecards] = useState([]);

  const [originalCalibration, setOriginalCalibration] = useState();

  const [host, setHost] = useState(demo ? 1 : userId);
  const [scorecard, setScorecard] = useState();
  const [details, setDetails] = useState();
  const [calibrationDate, setCalibrationDate] = useState('');
  const [type, setType] = useState('consensus');
  const [isLocked, setIsLocked] = useState();
  const [isReleased, setIsReleased] = useState();
  const [participantToAdd, setParticipantToAdd] = useState();
  const [addedParticipants, setAddedParticipants] = useState([]);

  const [lockOrUnlock, setLockOrUnlock] = useState();
  const [shouldRecalculate, setShouldRecalculate] = useState(false);

  const [allUsersEvaluations, setAllUsersEvaluations] = useState([]);

  const [scorecardName, setScorecardName] = useState('');

  const [showInvalid, setShowInvalid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [changes, setChanges] = useState({});

  const [isLoadingCalibration, setIsLoadingCalibration] = useState(
    isShowScreen ? true : false
  );

  const saveButtonRef = useRef(null);

  const setupShowScreen = async () => {
    const { users, usersNotDeleted } = await getDataForNewCalibration();

    await getCalibration(users, usersNotDeleted);
  };

  const getCalibration = async (users, usersNotDeleted) => {
    if (isShowScreen) setIsLoadingCalibration(true);

    try {
      const { calibration, allUsersEvaluations } =
        await apiDataService.getCalibrationById({
          token: demo ? undefined : await getAccessTokenSilently(),
          userId: demo ? 1 : userId,
          portalId: demo ? 'demo' : portalId,
          calibrationId: id,
        });

      setAllUsersEvaluations(allUsersEvaluations);

      setOriginalCalibration(calibration);

      setScorecardName(calibration.scorecard_name);

      setBreadcrumbs([
        [
          'Dashboard',
          'dashboard',
          '',
          `/${demo ? 'demo' : portalId}/dashboard`,
        ],
        [
          'Calibrations',
          'bullseye',
          '',
          `/${demo ? 'demo' : portalId}/calibrations`,
        ],
        [calibration.scorecard_name, 'magnifying-glass'],
      ]);

      setScorecard(calibration.scorecard_id ?? '0');
      setHost(
        usersNotDeleted?.find(({ id }) => id === calibration.host_user_id)
          ?.id ?? ''
      );
      setDetails(calibration.details ?? '');
      setCalibrationDate(calibration.calibration_date);
      setType(calibration.type);
      setIsLocked(calibration.locked);
      setIsReleased(calibration.released);

      setAddedParticipants(
        calibration.participants
          .map((participant) =>
            users.find((user) => parseInt(user.id) === parseInt(participant))
          )
          .sort((a, b) => a.username.localeCompare(b.username))
      );
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      if (shouldNavigateOnNotFound(error)) {
        navigate(`/${demo ? 'demo' : portalId}/calibrations`, {
          state: { isNavigating: true },
        });
      }
    } finally {
      setIsLoadingCalibration(false);
    }
  };

  const handleSave = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    const canSave = host && scorecard && calibrationDate;

    setShowInvalid(true);

    if (!canSave)
      return alert.show(
        'Unable to save. Please check calibration has a Scorecard, Host, and Date',
        {
          timeout: 5000,
        }
      );

    if (isSaving || saveButtonRef?.current?.disabled) return;
    if (!saveButtonRef?.current?.disabled && saveButtonRef?.current)
      saveButtonRef.current.disabled = true;

    setIsSaving(true);

    try {
      if (isShowScreen) {
        const reqBody = getReqBody();

        if (reqBody.inserts.length || reqBody.deletes.length) {
          await apiDataService.updateParticipants({
            portalId: demo ? 'demo' : portalId,
            reqBody: {
              userId: demo ? 1 : userId,
              inserts: reqBody.inserts,
              deletes: reqBody.deletes,
            },
            token: demo ? undefined : await getAccessTokenSilently(),
          });
        }

        if (Object.keys(reqBody.calibrationChanges).length) {
          await apiDataService.updateCalibrations({
            portalId: demo ? 'demo' : portalId,
            reqBody: {
              calibrations: [{ ...reqBody.calibrationChanges, id }],
              lockOrUnlock: shouldRecalculate
                ? !lockOrUnlock?.lock
                  ? { lock: [id] }
                  : lockOrUnlock
                : lockOrUnlock,
              userId: demo ? 1 : userId,
            },
            token: demo ? undefined : await getAccessTokenSilently(),
          });
        }
      } else {
        await apiDataService.post({
          portalId: demo ? 'demo' : portalId,
          reqBody: getReqBody(),
          token: demo ? undefined : await getAccessTokenSilently(),
        });
      }

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;

      if (isShowScreen) {
        setChanges({});
        setLockOrUnlock(null);
        setShowInvalid(false);

        await getCalibration(users, usersNotDeleted);
      } else {
        navigate(`/${demo ? 'demo' : portalId}/calibrations`, {
          state: { isNavigating: true },
        });
      }

      alert.success(isShowScreen ? 'Changes saved' : 'Calibration added');
    } catch (error) {
      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;

      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const getReqBody = () => {
    if (isShowScreen) {
      const calibrationChanges = {};
      const inserts = [];
      const deletes = [];

      Object.keys(changes).forEach((change) => {
        if (change === 'details') {
          calibrationChanges.details = changes.details ?? null;
        } else if (change === 'type') {
          calibrationChanges.type = changes.type;
        } else if (change === 'host') {
          calibrationChanges.host_user_id = changes.host;
        } else if (change === 'scorecard') {
          calibrationChanges.scorecard_id = changes.scorecard;
        } else if (change === 'locked') {
          calibrationChanges.locked = changes.locked;
        } else if (change === 'released') {
          calibrationChanges.released = changes.released;
        } else if (change === 'calibrationDate') {
          calibrationChanges.calibration_date = changes.calibrationDate ?? null;
        } else if (change === 'participants') {
          addedParticipants.forEach((participant) => {
            if (
              !originalCalibration.participants.includes(
                parseInt(participant.id)
              )
            )
              inserts.push({
                calibration_id: id,
                user_id: parseInt(participant.id),
              });
          });

          originalCalibration.participants.forEach((participant) => {
            if (
              !addedParticipants.some(
                (addedParticipant) =>
                  parseInt(addedParticipant.id) === participant
              )
            ) {
              deletes.push({ calibrationId: id, userId: participant });
            }
          });
        }
      });

      return { calibrationChanges, inserts, deletes };
    } else {
      return {
        calibrations: [
          {
            scorecard_id: scorecard,
            host_user_id: host,
            type,
            details,
            participants: addedParticipants.map(({ id }) => id),
            calibration_date: calibrationDate,
          },
        ],
        userId: demo ? 1 : userId,
      };
    }
  };

  const handleChangeIsLocked = async (value) => {
    setIsLocked(value);

    setLockOrUnlock({ [value ? 'lock' : 'unlock']: [id] });

    if (!value && isReleased) handleChangeValue('released', false);
  };

  const handleChangeValue = async (property, value) => {
    if (property === 'host') setHost(value === '0' ? '' : value);
    else if (property === 'scorecard') {
      if (isShowScreen) {
        const confirmed = await portalConfirmAlert({
          message:
            'Any evaluations that have already been submitted for this calibration will be deleted when you save the changes. Do you wish to continue?',
        });

        if (!confirmed) return;
      }

      setScorecard(value === '0' ? '' : value);
    } else if (property === 'details') setDetails(value);
    else if (property === 'calibrationDate') setCalibrationDate(value);
    else if (property === 'type') {
      if (type === 'benchmark' && originalCalibration.benchmark_evaluation_id) {
        const confirmed = await portalConfirmAlert({
          message:
            'The submitted benchmark evaluation will be deleted when you save the changes. Do you wish to continue?',
        });

        if (!confirmed) return;
      }

      setType(value);

      setShouldRecalculate(true);
    } else if (property === 'locked') handleChangeIsLocked(value);
    else if (property === 'released') setIsReleased(value);

    if (isShowScreen) {
      setChanges((curr) => {
        const copiedCurr = { ...curr };

        if (property === 'scorecard') {
          copiedCurr.scorecard = value === '0' ? '' : value;
        } else if (property === 'type') {
          copiedCurr.type = value;
        } else if (property === 'host') {
          copiedCurr.host = value === '0' ? '' : value;
        } else copiedCurr[property] = value;

        return copiedCurr;
      });
    }
  };

  const handleAddParticipant = () => {
    setParticipantToAdd('0');

    if (
      addedParticipants.some(
        (participant) => parseInt(participant.id) === parseInt(participantToAdd)
      )
    )
      return alert.show('Already added');

    setAddedParticipants((curr) =>
      [
        ...curr,
        {
          id: participantToAdd,
          username: usersNotDeleted.find(
            (user) => parseInt(user.id) === parseInt(participantToAdd)
          ).username,
        },
      ].sort((a, b) => a.username.localeCompare(b.username))
    );

    if (isShowScreen) {
      setChanges((curr) => {
        const copiedCurr = { ...curr };

        copiedCurr.participants = true;

        return copiedCurr;
      });
    }
  };

  const getDataForNewCalibration = async () => {
    try {
      const { users, scorecards } =
        await apiDataService.getDataForNewCalibration({
          portalId: demo ? 'demo' : portalId,
          userId: demo ? 1 : userId,
          token: demo ? undefined : await getAccessTokenSilently(),
        });

      const usersNotDeleted = users.filter((user) => user.status !== 'deleted');

      setUsers(users);
      setUsersNotDelete(usersNotDeleted);
      setScorecards(scorecards);

      return { users, usersNotDeleted };
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const deleteCalibration = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    const confirmed = await portalConfirmAlert({
      message: `Are you sure you want to delete this calibration?`,
    });

    if (!confirmed) return;

    try {
      await apiDataService.gridDeleteMultiple({
        selectedRecordIds: [id],
        portalId: demo ? 'demo' : portalId,
        reqBody: { userId: demo ? 1 : userId },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      alert.success('Calibration Deleted');

      navigate(`/${demo ? 'demo' : portalId}/calibrations`, {
        state: { isNavigating: true },
      });
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  useEffect(() => {
    if (isShowScreen) setupShowScreen();
    else {
      if (hasChangePermission) {
        setBreadcrumbs([
          [
            'Dashboard',
            'dashboard',
            '',
            `/${demo ? 'demo' : portalId}/dashboard`,
          ],
          [
            'Calibrations',
            'bullseye',
            '',
            `/${demo ? 'demo' : portalId}/calibrations`,
          ],
          ['New', 'plus'],
        ]);

        getDataForNewCalibration();
      } else {
        navigate(`/${demo ? 'demo' : portalId}/dashboard`, {
          state: { isNavigating: true },
        });

        alert.info(
          'You do not have the required permission to access this screen'
        );
      }
    }

    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoadingCalibration || (isSaving && isLocked)) return <LoadingSpinner />;
  else
    return (
      <MainOnly>
        <Panels>
          {hasChangePermission ? (
            <PanelsControlBar>
              <ButtonStandardNoFill
                buttonRef={saveButtonRef}
                disabled={
                  !host ||
                  !scorecard ||
                  (isShowScreen && !Object.keys(changes).length) ||
                  isSaving
                }
                iconName='save-solid'
                margin='5px'
                onClick={handleSave}
                text={`Save${isShowScreen ? ' Changes' : ''}`}
                typeStyle='type-4'
              />
              {isShowScreen && (
                <ButtonStandardNoFill
                  margin='5px'
                  disabled={isSaving}
                  iconName='trash'
                  onClick={deleteCalibration}
                  text='Delete Calibration'
                  typeStyle={'type-5'}
                />
              )}
            </PanelsControlBar>
          ) : (
            <></>
          )}

          <PanelsHeaderPrimary
            iconName='bullseye'
            subIcon={isShowScreen ? null : 'plus'}
            text={
              isShowScreen ? `${scorecardName} Calibration` : 'New Calibration'
            }
          />

          <PanelsSections>
            <PanelsSection
              alignItems='stretch'
              classes='calibrations-panel'
              typeStyle='type-2'
            >
              <FlexContainer>
                <SelectStandard
                  containerClasses='calib-scorecard-select-container'
                  disabled={isLocked}
                  hasPermission={hasChangePermission}
                  id='calib-new-scorecard'
                  invalid={
                    scorecard
                      ? null
                      : [validatePresenceRequired(scorecard, "'Scorecard'")]
                  }
                  isRequired
                  labelText='Scorecard'
                  marginBottom='5px'
                  onChange={(event) =>
                    handleChangeValue('scorecard', event.target.value)
                  }
                  optionKeyValue={{
                    key: 'id',
                    value: 'name',
                  }}
                  // id or vcid?
                  options={[
                    isShowScreen &&
                    !scorecards.some(
                      (scorecard) =>
                        scorecard.id === originalCalibration?.scorecard_id
                    )
                      ? {
                          id: originalCalibration?.scorecard_id,
                          name: originalCalibration?.scorecard_name,
                        }
                      : { id: '0', name: '< Scorecard >' },

                    ...scorecards,
                  ]}
                  showInvalid={showInvalid}
                  value={scorecard}
                />

                {isShowScreen && (
                  <Link
                    style={{ alignSelf: 'flex-end', marginBottom: '10px' }}
                    to={
                      originalCalibration.locked
                        ? !allUsersEvaluations.find(
                            (obj) =>
                              obj.participant_user_id === (demo ? 1 : userId)
                          )?.evaluation_id
                          ? ''
                          : `/${
                              demo ? 'demo' : portalId
                            }/calibrations/${id}/evaluations/${
                              allUsersEvaluations.find(
                                (obj) =>
                                  obj.participant_user_id ===
                                  (demo ? 1 : userId)
                              )?.evaluation_id
                            }`
                        : `/${
                            demo ? 'demo' : portalId
                          }/calibrations/${id}/scorecard`
                    }
                  >
                    <ButtonIconOnly
                      disabled={
                        originalCalibration.locked &&
                        !allUsersEvaluations.find(
                          (obj) =>
                            obj.participant_user_id === (demo ? 1 : userId)
                        )?.evaluation_id
                      }
                      iconName='arrow-up-right-from-square'
                      title='My Evaluation'
                    />
                  </Link>
                )}
              </FlexContainer>

              <SelectStandard
                hasPermission={hasChangePermission}
                id='calib-new-host'
                invalid={
                  host ? null : [validatePresenceRequired(host, "'Host'")]
                }
                isRequired
                labelText='Host'
                marginBottom='5px'
                onChange={(event) =>
                  handleChangeValue('host', event.target.value)
                }
                optionKeyValue={{
                  key: 'id',
                  value: 'username',
                }}
                options={[
                  { id: '0', username: '< Host >' },
                  ...usersNotDeleted.filter(
                    (user) =>
                      user.permissions?.includes('calibrations.change') ||
                      user.id === host
                  ),
                ]}
                showInvalid={showInvalid}
                value={host}
              />

              <InputTextareaNew
                hasPermission={hasChangePermission}
                id='calib-new-details'
                onChange={(event) =>
                  handleChangeValue('details', event.target.value)
                }
                alignItems='stretch'
                direction='vertical'
                labelText='Details'
                marginBottom='5px'
                resize='vertical'
                value={details}
              />

              <InputStandard
                direction='vertical'
                hasPermission={hasChangePermission}
                id='calib-new-calib-date'
                invalid={
                  calibrationDate
                    ? null
                    : [
                        validatePresenceRequired(
                          calibrationDate,
                          "'Calibration Date'"
                        ),
                      ]
                }
                isRequired
                labelText='Calibration Date'
                onChange={(event) =>
                  handleChangeValue('calibrationDate', event.target.value)
                }
                showInvalid={showInvalid}
                type='date'
                value={calibrationDate}
              />

              <FlexContainer>
                <SelectStandard
                  id='calib-new-bench'
                  containerClasses='calib-scorecard-select-container'
                  hasPermission={hasChangePermission}
                  labelText='Type'
                  marginTop='5px'
                  marginBottom='5px'
                  onChange={(event) =>
                    handleChangeValue('type', event.target.value)
                  }
                  options={[
                    { code: 'consensus', name: 'Group Average' },
                    { code: 'benchmark', name: 'Benchmark' },
                  ]}
                  value={type}
                />

                {type === 'benchmark' && isShowScreen && (
                  <>
                    <Link
                      style={{ alignSelf: 'flex-end', marginBottom: '10px' }}
                      to={
                        !scorecard ||
                        !originalCalibration.scorecard_id ||
                        originalCalibration.type === 'consensus'
                          ? ''
                          : `/${
                              demo ? 'demo' : portalId
                            }/calibrations/${id}/benchmark`
                      }
                    >
                      <ButtonIconOnly
                        disabled={
                          !scorecard ||
                          !originalCalibration.scorecard_id ||
                          originalCalibration.type === 'consensus'
                        }
                        iconName='arrow-up-right-from-square'
                        title={
                          parseInt(originalCalibration.host_user_id) !==
                          (demo ? 1 : parseInt(userId))
                            ? 'View the benchmark evaluation'
                            : originalCalibration.benchmark_evaluation_id
                            ? 'Amend benchmark evaluation'
                            : 'Submit benchmark evaluation'
                        }
                      />
                    </Link>

                    <Icon
                      iconName={
                        originalCalibration.benchmark_evaluation_id
                          ? 'tick'
                          : 'xmark'
                      }
                      style={{
                        alignSelf: 'flex-end',
                        fill: originalCalibration.benchmark_evaluation_id
                          ? '#0c4c14'
                          : '#641314',
                        height: '16px',
                        marginBottom: '17px',
                        marginLeft: '5px',
                        width: '16px',
                      }}
                      title={
                        originalCalibration.benchmark_evaluation_id
                          ? 'Benchmark evaluation submitted'
                          : 'Benchmark evaluation not submitted'
                      }
                    />
                  </>
                )}
              </FlexContainer>

              {isShowScreen && (
                <FlexContainer justifyContent='space-evenly'>
                  <Toggle
                    hasPermission={hasChangePermission}
                    id='calib-new-locked'
                    labelText='Lock'
                    onChange={(event) =>
                      handleChangeValue('locked', event.target.checked)
                    }
                    toggled={isLocked}
                  />

                  <Toggle
                    disabled={!isLocked}
                    hasPermission={hasChangePermission}
                    id='calib-new-released'
                    labelText='Share'
                    onChange={(event) =>
                      handleChangeValue('released', event.target.checked)
                    }
                    toggled={isReleased}
                  />
                </FlexContainer>
              )}
            </PanelsSection>

            <PanelsSection
              classes='participants-panel'
              maxHeight='415px'
              typeStyle='type-2'
            >
              {isShowScreen && (
                <InfoText
                  text={`${
                    originalCalibration.submitted_percent ?? '0'
                  }% submitted`}
                />
              )}
              <div
                className='table-container table-container-table'
                style={{
                  borderBottom: '1px solid #bbbbbb',
                  paddingBottom: '0',
                }}
              >
                <ul className='table'>
                  <>
                    <li className='header-row'>
                      <HeaderSecondary
                        classes='header-cell'
                        text='Participants'
                      />
                    </li>

                    {addedParticipants.map((participant, index) => {
                      const userId = participant?.id;
                      const username = participant?.username;

                      return (
                        <ParticipantsListItem
                          key={index}
                          calibrationId={id}
                          demo={demo}
                          hasChangePermission={hasChangePermission}
                          hasUsersViewPermission={hasUsersViewPermission}
                          hasSubmitted={allUsersEvaluations.some(
                            (evaluation) =>
                              evaluation.participant_user_id === userId
                          )}
                          id={userId}
                          isLocked={isLocked}
                          isReleased={isReleased}
                          isShowScreen={isShowScreen}
                          firstRow={index === 0}
                          originalIsLocked={originalCalibration?.locked}
                          originalIsReleased={originalCalibration?.released}
                          portalId={portalId}
                          setAddedParticipants={setAddedParticipants}
                          setChanges={setChanges}
                          showEvaluationLink={isLocked}
                          userEvaluationId={
                            allUsersEvaluations.find(
                              (obj) => obj.participant_user_id === userId
                            )?.evaluation_id
                          }
                          username={username}
                          usersNotDeleted={usersNotDeleted}
                        />
                      );
                    })}
                  </>
                </ul>
              </div>

              {hasChangePermission ? (
                <FlexContainer marginTop='10px'>
                  <SelectStandard
                    disabled={isLocked}
                    labelText='Add Participant'
                    onChange={(event) => {
                      setParticipantToAdd(event.target.value);
                    }}
                    optionKeyValue={{
                      key: 'id',
                      value: 'username',
                    }}
                    options={[
                      { id: '0', username: '< Add participant >' },
                      ...usersNotDeleted,
                    ]}
                    selectStyle={{ maxWidth: '180px' }}
                    value={participantToAdd}
                  />

                  <ButtonIconOnly
                    alignSelf='flex-end'
                    disabled={
                      !participantToAdd || participantToAdd === '0' || isLocked
                    }
                    iconName='plus'
                    title='Add'
                    margin='0 5px 5px'
                    onClick={handleAddParticipant}
                  />
                </FlexContainer>
              ) : (
                <div style={{ marginTop: '10px' }}></div>
              )}
            </PanelsSection>

            {((isShowScreen &&
              originalCalibration.locked &&
              host === (demo ? 1 : userId)) ||
              originalCalibration?.released) && (
              <>
                <PanelsHeaderPrimary
                  classes='calibration-results-header'
                  iconName='gauge'
                  text='Results'
                />
                <PanelsSection typeStyle='type-3'>
                  <CalibrationResults
                    calibration={originalCalibration}
                    isLoading={isSaving || isLoadingCalibration}
                    allUsersEvaluations={allUsersEvaluations}
                    demo={demo}
                    type={originalCalibration.type}
                  />
                </PanelsSection>
              </>
            )}
          </PanelsSections>
        </Panels>
      </MainOnly>
    );
};

export default CalibrationsNew;
