import { Link } from 'react-router-dom';

import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';

import './SubscriptionSuccess.css';

const SubscriptionSuccess = ({ customerEmail, hasChanged, portalId }) => {
  return (
    <section id='success' className='subscription-success'>
      <p style={{ fontWeight: '600' }}>{` You have successfully ${
        hasChanged ? 'changed your subscription' : 'set up a subscription'
      } for qaportal.co.uk!`}</p>

      {/* <p>A confirmation email will be sent to {customerEmail}.</p> */}

      <p>
        If you have any questions, please email{' '}
        <a href='mailto:info@qaportal.com'>info@qaportal.com</a>.
      </p>

      <Link style={{ margin: '20px' }} to={`/${portalId}/billing`}>
        <ButtonStandardNoFill
          iconName='circle-arrow-left'
          text='Return to Billing'
          typeStyle='type-4'
        />
      </Link>
    </section>
  );
};

export default SubscriptionSuccess;
