import { apiUrl, headers } from './config';

const billingApi = {
  createCheckoutSession: async ({
    billingDate,
    customerId,
    portalId,
    subscriptionId,
    token,
    userId,
  }) => {
    const url = `/api/portals/${portalId}/checkout-session`;

    const { data } = await apiUrl.post(
      url,
      { billingDate, customerId, subscriptionId },
      headers({ token, userId })
    );

    return data;
  },

  getSessionStatus: async ({ portalId, sessionId, token, userId }) => {
    const url = `/api/portals/${portalId}/session-status?session_id=${sessionId}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getInvoices: async ({ portalId, token, userId }) => {
    const url = `/api/portals/${portalId}/billing/invoices`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  update: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/billing`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  postSubscription: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/billing`;

    await apiUrl.post(url, reqBody, headers({ token }));
  },
};

export default billingApi;
