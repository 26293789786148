import { Link } from 'react-router-dom';

import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import Icon from '../../Elements/Icon';
import TextBreak from '../../Elements/TextBreak';

import './ParticipantsListItem.css';

const ParticipantsListItem = ({
  calibrationId,
  demo,
  firstRow,
  hasChangePermission,
  hasUsersViewPermission,
  hasSubmitted,
  id,
  isLocked,
  isReleased,
  isShowScreen,
  originalIsLocked,
  originalIsReleased,
  portalId,
  setAddedParticipants,
  setChanges,
  userEvaluationId,
  username,
  usersNotDeleted,
}) => {
  return (
    <li
      className={`${
        firstRow ? 'first-grid-row ' : ''
      } grid-row linked-agents-grid-row`}
    >
      {hasUsersViewPermission &&
      usersNotDeleted.find((user) => user.id === id) ? (
        <a
          className='grid-row-cell grid-row-cell-participants linked-agents-grid-row-cell'
          href={`/${demo ? 'demo' : portalId}/users/${id}`}
        >
          {hasSubmitted && (
            <Icon className='participant-submitted-tick' iconName='tick'></Icon>
          )}
          <TextBreak text={username} classes='grid-row-cell-data' />
        </a>
      ) : (
        <>
          <div className='grid-row-cell grid-row-cell-participants no-permission-list-item'>
            {hasSubmitted && (
              <Icon
                className='participant-submitted-tick'
                iconName='tick'
              ></Icon>
            )}
            <TextBreak text={username} classes='grid-row-cell-data' />
          </div>
        </>
      )}

      {hasChangePermission ? (
        <div className='grid-row-cell linked-agents-unlink-button'>
          <ButtonIconOnly
            disabled={isLocked}
            onClick={() => {
              setAddedParticipants((curr) => {
                const newAddedParticipants = curr.filter(
                  (item) => item.id !== id
                );

                return newAddedParticipants;
              });

              if (isShowScreen) {
                setChanges((curr) => {
                  const copiedCurr = { ...curr };

                  copiedCurr.participants = true;

                  return copiedCurr;
                });
              }
            }}
            title='Remove'
            iconName='minus'
            typeStyle='type-2'
          />
        </div>
      ) : (
        <></>
      )}

      {isLocked && originalIsLocked && isReleased && originalIsReleased ? (
        <div className='grid-row-cell linked-agents-unlink-button'>
          {userEvaluationId && hasSubmitted ? (
            <Link
              to={`/${
                demo ? 'demo' : portalId
              }/calibrations/${calibrationId}/evaluations/${userEvaluationId}`}
            >
              <ButtonIconOnly
                title='View Evaluation'
                iconName='evaluations'
                typeStyle='type-2'
              />
            </Link>
          ) : (
            <ButtonIconOnly
              disabled
              title='No evaluation submitted'
              iconName='evaluations'
              typeStyle='type-2'
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </li>
  );
};

export default ParticipantsListItem;
