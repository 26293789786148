import { useEffect, useRef, useState } from 'react';

import './AutoWidthInput.css';

const AutoWidthInput = ({
  disabled,
  title,
  value,
  handleChangeValue,
  placeholder,
  initialWidth = 100,
  additionalWidth = 0,
  className,
  type = 'text',
  columnType,
}) => {
  const [inputWidth, setInputWidth] = useState(initialWidth);

  const ref = useRef(null);

  useEffect(() => {
    if (!value) setInputWidth(initialWidth);
    else setInputWidth(ref.current ? ref.current.offsetWidth : initialWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, value, columnType]);

  return (
    <div className='auto-width-input'>
      <div className={`${className} hidden-text`} ref={ref}>
        {value}
      </div>

      <input
        disabled={disabled}
        title={title}
        style={{
          width: `${inputWidth + additionalWidth}px`,
        }}
        className={`${className} visible-text`}
        placeholder={placeholder}
        value={value}
        onChange={handleChangeValue}
        type={type}
      />
    </div>
  );
};

export default AutoWidthInput;
