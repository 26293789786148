import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ButtonIconOnly from './ButtonIconOnly';
import ButtonToggle from './ButtonToggle';

import './DraggableToggleListItem.css';

const DraggableToggleListItem = memo(function Card({
  id,
  disabled,
  disabledText,
  cardsLength,
  moveCard,
  findCard,
  card,
  itemType,
  handlePreToggle,
  handleToggle,
  optionIconToggle,
  optionIconToggleClasses = '',
  title,
  toggleClasses = '',
  toggleHeight,
  uniqueSectionId,
  noToggle,
}) {
  const ItemTypes = {
    CARD: itemType,
  };
  const originalIndex = findCard(id).index;
  const [, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      canDrop: () => false,
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );

  const dndRefFunc = (node) => drag(drop(node));

  return (
    <li
      className={`draggable-toggle-container ${
        card.toggled ? 'card-toggled' : 'card-not-toggled'
      }`}
      id={card.id}
      ref={card.toggled && !disabled ? dndRefFunc : null}
    >
      <ButtonToggle
        key={card.id}
        uniqueRef={`${uniqueSectionId}${card.id}`}
        disabled={disabled}
        cardsLength={cardsLength}
        title={disabledText ?? title ?? card.value}
        onClick={() => {
          if (noToggle) return;

          if (!handlePreToggle || handlePreToggle(card)) handleToggle(card.id);
        }}
        noToggle={noToggle}
        toggled={card.toggled}
        text={card.value}
        containerClasses={`draggable-toggle ${toggleClasses}`}
      />

      {optionIconToggle ? (
        <ButtonIconOnly
          disabled={!card.toggled || disabledText}
          title={
            disabledText ||
            card[optionIconToggle.cardParam] === optionIconToggle.options[0]
              ? optionIconToggle.text[0]
              : optionIconToggle.text[1]
          }
          classes={`toggle-option-icon ${optionIconToggleClasses}`}
          typeStyle='type-3'
          iconName={
            card[optionIconToggle.cardParam] === optionIconToggle.options[0]
              ? optionIconToggle.icons[0]
              : optionIconToggle.icons[1]
          }
          onClick={() => {
            if (card.toggled)
              handleToggle(card.id, card[optionIconToggle.cardParam]);
          }}
        />
      ) : (
        <></>
      )}
    </li>
  );
});

export default DraggableToggleListItem;
