import HeaderPrimary from './HeaderPrimary';

import './PanelsHeaderPrimary.css';

const PanelsHeaderPrimary = ({
  text = '',
  classes = '',
  iconName,
  subIcon,
  style,
  margin,
}) => {
  return (
    <HeaderPrimary
      text={text}
      classes={`panels-header-primary ${classes}`}
      iconName={iconName}
      subIcon={subIcon}
      style={style}
      margin={margin}
    />
  );
};

export default PanelsHeaderPrimary;
