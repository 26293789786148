import { apiUrl, headers } from './config';

const usersApi = {
  getAll: async ({ params, portalId, token, userId }) => {
    const baseUrl = `/api/portals/${portalId}/users`;
    const urlParams = `${params.length ? `?${params.join('&')}` : ''}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getUserById: async ({ idOfUser, portalId, token, userId }) => {
    const url = `/api/portals/${portalId}/users/${idOfUser}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getWithoutPortalId: async ({ params, token }) => {
    const baseUrl = `/api/users`;
    const urlParams = `${params.length ? `?${params.join('&')}` : ''}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token }));

    return data;
  },

  gridDeleteMultiple: async ({
    selectedRecordIds,
    portalId,
    reqBody,
    token,
  }) => {
    reqBody.updates = selectedRecordIds.map((id) => ({
      id,
      status: 'deleted',
    }));

    const url = `/api/portals/${portalId}/users`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  gridUpdate: async ({ gridChanges, portalId, reqBody, token }) => {
    reqBody.updates = Object.entries(gridChanges).map(([id, changes]) => ({
      id,
      ...changes,
    }));

    const url = `/api/portals/${portalId}/users`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  post: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/users`;

    const { data } = await apiUrl.post(url, reqBody, headers({ token }));

    return data;
  },

  update: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/users`;

    const { data } = await apiUrl.patch(url, reqBody, headers({ token }));

    return data;
  },
};

export default usersApi;
