import Icon from './Icon';
import IconWithSub from './IconWithSub';

import './ButtonIconOnly.css';

const ButtonIconOnly = ({
  alignSelf,
  backgroundColor,
  classes = '',
  disabled = false,
  iconClasses = '',
  iconName,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  onClick,
  subIconName,
  title,
  type = 'button',
  typeStyle = 'type-1',
}) => {
  const style = {};

  if (alignSelf) style.alignSelf = alignSelf;
  if (margin) style.margin = margin;
  if (marginLeft) style.marginLeft = marginLeft;
  if (marginRight) style.marginRight = marginRight;
  if (marginBottom) style.marginBottom = marginBottom;
  if (marginTop) style.marginTop = marginTop;
  if (backgroundColor) style.backgroundColor = backgroundColor;

  return (
    <button
      className={`button-icon-only button-icon-only-${typeStyle} ${classes}`}
      title={title}
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      {subIconName ? (
        <IconWithSub iconName={iconName} subIconName={subIconName} />
      ) : (
        <Icon className={iconClasses} iconName={iconName} />
      )}
    </button>
  );
};

export default ButtonIconOnly;
