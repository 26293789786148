import { memo, useCallback, useRef } from 'react';
import { useDrop } from 'react-dnd';

import update from 'immutability-helper';

import DraggableToggleListItem from './DraggableToggleListItem';

import './DraggableToggleList.css';

const DraggableList = memo(function Container({
  cards,
  disabled,
  disabledText,
  setCards,
  setIsMoving,
  itemType,
  handleToggle,
  handlePreToggle,
  containerClasses = '',
  listClasses = '',
  optionIconToggleClasses,
  toggleClasses = '',
  withOrdering,
  title,
  toggleHeight = 40,
  tableHeaders = ['', ''],
  uniqueSectionId,
  optionIconToggle,
  disableHeaderColumn,
  noToggle,
}) {
  const scrollableSections = useRef(null);

  const ItemTypes = {
    CARD: itemType,
  };

  const findCard = useCallback(
    (id) => {
      const card = cards.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards]
  );
  const moveCard = useCallback(
    (id, atIndex) => {
      if (setIsMoving) setIsMoving(true);
      const { card, index } = findCard(id);
      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [findCard, cards, setCards]
  );
  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

  if (!cards) return <div>loading</div>;
  return (
    <div
      className={`draggable-toggles-container ${containerClasses}`}
      ref={drop}
    >
      {withOrdering && cards.filter((card) => card.toggled).length ? (
        <ul className='toggle-ordering-column'>
          <li
            className='toggle-ordering-cell'
            style={{ alignItems: 'flex-start', height: `${toggleHeight}px` }}
          >
            <h2>{tableHeaders[0]}</h2>
          </li>
          {cards
            .filter((card) => card.toggled)
            .map((card, index) => (
              <div
                key={card.id}
                className='toggle-ordering-cell'
                style={{ height: `${toggleHeight}px` }}
              >
                <p>{index + 1}</p>
              </div>
            ))}
        </ul>
      ) : (
        <></>
      )}
      <ul
        className={`draggable-toggles-list ${listClasses}`}
        ref={scrollableSections}
      >
        {withOrdering && cards.filter((card) => card.toggled).length ? (
          <li style={{ height: `${toggleHeight}px` }}>{tableHeaders[1]}</li>
        ) : (
          <></>
        )}
        {cards.map((card, index) => {
          return (
            <DraggableToggleListItem
              key={card.id}
              id={card.id}
              cardsLength={cards.length}
              disabledText={
                disabledText ||
                (disableHeaderColumn && index === 0
                  ? card.value + ' must be the first column in grid view'
                  : undefined)
              }
              // will this cause issues for if it's used by other components ^
              moveCard={moveCard}
              findCard={findCard}
              card={card}
              itemType={itemType}
              handlePreToggle={handlePreToggle}
              handleToggle={handleToggle}
              optionIconToggle={optionIconToggle}
              optionIconToggleClasses={optionIconToggleClasses}
              title={title}
              toggleClasses={toggleClasses}
              toggleHeight={toggleHeight}
              uniqueSectionId={uniqueSectionId}
              disabled={(disableHeaderColumn && index === 0) || disabled}
              noToggle={noToggle}
            />
          );
        })}
      </ul>
    </div>
  );
});

export default DraggableList;
