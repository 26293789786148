import { translateSourceTables } from '../../../utils/reports';

import './AvailableSection.css';

const AvailableSection = ({
  section,
  handleSetSelectedReportData,
  selectedReportData,
  showRelatedScorecardData,
  uniqueData,
}) => {
  return (
    <li className='reports-available-section-list-item'>
      <input
        checked={selectedReportData[uniqueData] || false}
        id={uniqueData}
        type='checkbox'
        onChange={() => handleSetSelectedReportData(section, uniqueData)}
      />

      <label className='reports-available-section-label' htmlFor={uniqueData}>
        {`${section.title}${
          showRelatedScorecardData
            ? ` - ${translateSourceTables[section.source_table]}`
            : ''
        }`}
      </label>
    </li>
  );
};

export default AvailableSection;
