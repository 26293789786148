import Login from './Login';
import Logout from './Logout';

import { useAuth0 } from '@auth0/auth0-react';

const AuthenticationButton = ({ classes = '' }) => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? (
    <Logout classes={classes} />
  ) : (
    <Login classes={classes} />
  );
};

export default AuthenticationButton;
