import { useDetectClickOutside } from 'react-detect-click-outside';
import { useState } from 'react';

import ButtonIconOnly from './ButtonIconOnly';
import ButtonStandardNoFill from './ButtonStandardNoFill';

import './ButtonWithOptions.css';

const ButtonWithOptions = ({
  buttonData,
  classes = '',
  containerClasses = '',
  containerStyles,
  direction = 'down',
  margin,
  optionsDisabled,
  onTriggerOptions,
  secondaryContainerClasses = '',
}) => {
  const detectClickOutsideRef = useDetectClickOutside({
    onTriggered: () => setShowSecondaryOptions(false),
  });

  const [showSecondaryOptions, setShowSecondaryOptions] = useState(false);

  const style = {
    flexDirection: direction === 'up' ? 'column-reverse' : 'column',
    ...containerStyles,
  };

  if (margin) style.margin = margin;

  return (
    <div
      className={`button-with-options-buttons-list ${containerClasses}`}
      ref={detectClickOutsideRef}
      style={style}
    >
      <div className='button-with-options-container'>
        <ButtonStandardNoFill
          buttonRef={buttonData[0].buttonRef}
          classes={classes}
          disabled={buttonData[0].disabled}
          iconName={buttonData[0].iconName}
          onClick={buttonData[0].onClick}
          text={buttonData[0].text}
          type={buttonData[0].type ?? 'button'}
          title={buttonData[0].title}
          typeStyle={buttonData[0].typeStyle}
        />

        <ButtonIconOnly
          classes={
            showSecondaryOptions ? 'button-with-options-ellipses-showing' : ''
          }
          disabled={optionsDisabled}
          marginLeft='5px'
          iconName='ellipsis-vertical'
          onClick={() => {
            const currentState = showSecondaryOptions;

            setShowSecondaryOptions(!currentState);

            onTriggerOptions?.(!currentState);
          }}
          title='Options'
          type='button'
        />
      </div>

      {showSecondaryOptions && (
        <div
          className={`button-with-options-secondary-buttons-container ${secondaryContainerClasses}`}
          style={{
            flexDirection: direction === 'down' ? 'column' : 'column-reverse',
          }}
        >
          {buttonData.slice(1).map((button, index) => (
            <ButtonStandardNoFill
              key={index}
              classes={button.classes}
              iconName={button.iconName}
              text={button.text}
              buttonRef={button.buttonRef}
              onClick={button.onClick}
              disabled={button.disabled}
              title={button.title}
              type={button.type ?? 'button'}
              typeStyle={button.typeStyle}
              marginBottom='5px'
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ButtonWithOptions;
