import {
  Area,
  AreaChart,
  Legend,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { getMinAndMaxValues, handleAlternateX } from '../../../utils/reports';
import React, { useEffect, useRef, useState } from 'react';

const PortalAreaChart = ({
  alternateInterval,
  barChartMarginBottom,
  barChartXLabelOffset,
  chartKey,
  colours,
  height,
  mutatedReportRows,
  originalReportRows,
  reportColumns,
  shouldIncludeLegend,
  showXAxisLabel,
  values,
  width,
  xDataKey,
  xLabelHandling,
  yAxisLabel = '',
}) => {
  const yAxisLabelRef = useRef(null);

  const [yLabelHeight, setYLabelHeight] = useState(null);

  const [opacity, setOpacity] = useState({});

  const handleMouseEnter = (o) => {
    const { dataKey } = o;

    setOpacity({ ...opacity, [dataKey]: 0.5 });
  };

  const handleMouseLeave = (o) => {
    const { dataKey } = o;

    setOpacity({ ...opacity, [dataKey]: 1 });
  };

  const extraMinus = xLabelHandling === 'hideAllX' ? 40 : 0;
  const extraPlus =
    xLabelHandling === 'alternateX' ? alternateInterval * 15 - 15 : 0;

  const CustomYAxisLabel = () => {
    useEffect(() => {
      if (yAxisLabelRef.current)
        setYLabelHeight(yAxisLabelRef.current.getBBox().width);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yAxisLabelRef?.current]);

    return (
      <text
        className='chart-axis-label'
        x={
          -(
            (height -
              extraMinus +
              extraPlus -
              barChartMarginBottom +
              barChartXLabelOffset -
              yLabelHeight) /
            2
          )
        }
        y={20}
        ref={yAxisLabelRef}
        fill='#1a1a1a'
        fontSize='14px'
        textAnchor='end'
        transform='rotate(-90)'
      >
        {yAxisLabel}
      </text>
    );
  };

  return (
    <ResponsiveContainer>
      <AreaChart
        margin={{ bottom: barChartMarginBottom }}
        width={width}
        height={height}
        data={originalReportRows}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          interval={xLabelHandling === 'alternateX' ? 0 : null}
          dataKey={xDataKey}
          tick={
            xLabelHandling === 'hideAllX'
              ? () => {}
              : ({ x, y, payload, index }) => {
                  return (
                    <text
                      x={x}
                      y={y}
                      dy={
                        xLabelHandling === 'alternateX'
                          ? handleAlternateX(index, alternateInterval)
                          : 15
                      }
                      textAnchor='middle'
                      fill='#333333'
                    >
                      {
                        mutatedReportRows[payload.index].find(
                          (colObj) => colObj.col === xDataKey
                        )?.mutatedVal
                      }
                    </text>
                  );
                }
          }
        >
          {showXAxisLabel && (
            <Label
              className='chart-axis-label'
              value={reportColumns[parseInt(xDataKey?.slice(4)) - 1]?.value}
              offset={barChartXLabelOffset}
              position='insideBottom'
              fill='#1a1a1a'
            />
          )}
        </XAxis>
        <YAxis
          allowDataOverflow={true}
          allowDecimals={false}
          domain={getMinAndMaxValues(originalReportRows, values)}
          style={{ fill: '#333333' }}
          label={<CustomYAxisLabel />}
        />
        <Tooltip
          content={({ label, payload, index }) => {
            if (payload && payload.length) {
              return (
                <div className='recharts-tooltip-wrapper'>
                  <div
                    className='recharts-default-tooltip'
                    style={{
                      margin: '0px',
                      padding: '10px',
                      backgroundColor: 'rgb(255, 255, 255)',
                      border: '1px solid rgb(204, 204, 204)',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <p>
                      {
                        mutatedReportRows.flat().find((row) => {
                          return row.rawVal === label;
                        })?.mutatedVal
                      }
                    </p>

                    {payload.map((entry, index) => {
                      return (
                        <p key={index}>{`${
                          reportColumns[parseInt(entry.dataKey.slice(4)) - 1]
                            .value
                        }: ${
                          mutatedReportRows
                            .flat()
                            .find(
                              (row) =>
                                row.col === entry.dataKey &&
                                row.rawVal === entry.value
                            ).mutatedVal
                        }`}</p>
                      );
                    })}
                  </div>
                </div>
              );
            }
            return null;
          }}
        />

        {shouldIncludeLegend && (
          <Legend
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            wrapperStyle={{ bottom: showXAxisLabel ? '-5px' : '15px' }}
          />
        )}

        {values.map((value, index) => {
          return (
            <Area
              key={`${chartKey}-cell-${index}`}
              dataKey={value}
              fill={`${
                colours[reportColumns[parseInt(value.slice(4)) - 1].id] ||
                '#978ba6'
              }${opacity[value] === 0.5 ? '80' : ''}`}
              stroke={`${
                colours[reportColumns[parseInt(value.slice(4)) - 1].id] ||
                '#978ba6'
              }`}
              strokeOpacity={opacity[value]}
              name={reportColumns[parseInt(value.slice(4)) - 1].value}
            ></Area>
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default PortalAreaChart;
