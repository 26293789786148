import Icon from './Icon';
import FlexContainer from './FlexContainer';
import IconWithSub from './IconWithSub';

import './HeaderPrimary.css';

const HeaderPrimary = ({
  text = '',
  classes = '',
  iconName,
  justifyContent = 'flex-start',
  subIcon,
  style,
  margin,
}) => {
  return (
    <FlexContainer
      classes={`header-primary ${classes}`}
      justifyContent={justifyContent}
    >
      {iconName ? (
        <>
          {subIcon ? (
            <IconWithSub iconName={iconName} subIconName={subIcon} />
          ) : (
            <div className='header-primary-icon'>
              <Icon iconName={iconName} />
            </div>
          )}
        </>
      ) : (
        <></>
      )}

      <h1 style={{ ...style, margin }}>{text}</h1>
    </FlexContainer>
  );
};

export default HeaderPrimary;
