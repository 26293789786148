import { UserPortal } from '../../../contexts';
import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import apiDataServiceReports from '../../../api/reports';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import Icon from '../../Elements/Icon';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import ReportsFocusDistinctDataDate from './ReportsFocusDistinctDataDate';
import ReportsFocusDistinctDataList from './ReportsFocusDistinctDataList';
import ReportsFocusDistinctDataSlider from './ReportsFocusDistinctDataSlider';

import './ReportsFocusToggledAvailableSectionPanel.css';

const ReportsFocusToggledAvailableSectionPanel = ({
  availableSectionsDistinctData,
  code,
  demo,
  handleAvailableSectionsHeightChanges,
  handleToggleAvailableSection,
  reportParameters,
  section,
  setAvailableSectionsDistinctData,
  setReportParameters,
}) => {
  const elementRef = useRef(null);

  const alert = useAlert();
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { getAccessTokenSilently } = useAuth0();

  const [
    isLoadingAvailableSectionRelatedData,
    setIsLoadingAvailableSectionRelatedData,
  ] = useState(false);

  const [isSelectingFromList, setIsSelectingFromList] = useState(
    reportParameters[code]?.type === 'inc'
  );

  const getAvailableSectionRelatedData = async () => {
    if (availableSectionsDistinctData[code]) return;

    if (
      section.control === 'Check box' ||
      section.source_table === 'scoring' ||
      (section.source_table === 'evals' && section.title === 'RAG') ||
      (section.source_table === 'evals' && section.title === 'Grade')
    ) {
      setAvailableSectionsDistinctData((curr) => {
        const copiedCurr = { ...curr };

        copiedCurr[code] =
          section.control === 'Check box'
            ? ['Yes', 'No']
            : section.source_table === 'scoring'
            ? ['Pass', 'Fail', 'NA']
            : section.source_table === 'evals' && section.title === 'RAG'
            ? ['Green', 'Amber', 'Red']
            : section.source_table === 'evals' && section.title === 'Grade'
            ? ['Pass', 'Fail']
            : ['-'];

        return copiedCurr;
      });

      return;
    }

    try {
      setIsLoadingAvailableSectionRelatedData(true);

      const params = [
        'type=distinct',
        `date=${encodeURIComponent(section.control === 'Date')}`,
        `number=${encodeURIComponent(section.control === 'Number')}`,
        `code=${encodeURIComponent(code)}`,
      ];

      const { sectionDistinctData } = await apiDataServiceReports.getFocus({
        params,
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setAvailableSectionsDistinctData((curr) => {
        const copiedCurr = { ...curr };

        copiedCurr[code] = sectionDistinctData;

        return copiedCurr;
      });
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoadingAvailableSectionRelatedData(false);
    }
  };

  useEffect(() => {
    getAvailableSectionRelatedData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (elementRef.current?.clientHeight)
      handleAvailableSectionsHeightChanges(
        code,
        elementRef.current?.clientHeight
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    elementRef.current,
    elementRef.current?.clientHeight, // eslint-disable-next-line react-hooks/exhaustive-deps
    reportParameters[code]?.params,
    isSelectingFromList,
    isLoadingAvailableSectionRelatedData,
  ]);

  return (
    <FlexContainer
      alignItems='stretch'
      classes='focus-report-filter-panel'
      flexDirection='column'
      flexRef={elementRef}
      position='relative'
    >
      <div
        className='nav-favourite-cross focus-panel-cross'
        onClick={() => handleToggleAvailableSection(code)}
      >
        <Icon iconName='circle-cross' />
      </div>

      <FlexContainer alignItems='stretch' flexDirection='column'>
        <HeaderSecondary
          marginBottom='10px'
          text={section.title}
          textAlign='center'
        />

        {isLoadingAvailableSectionRelatedData ? (
          <LoadingSpinnerNew />
        ) : availableSectionsDistinctData[code] ? (
          section.control === 'Number' ? (
            <ReportsFocusDistinctDataSlider
              code={code}
              data={availableSectionsDistinctData[code]}
              isSelectingFromList={isSelectingFromList}
              sectionControl={section.control}
              sectionParameters={reportParameters[code]}
              sectionTitle={section.title}
              setIsSelectingFromList={setIsSelectingFromList}
              setReportParameters={setReportParameters}
            />
          ) : section.control === 'Date' ? (
            <ReportsFocusDistinctDataDate
              code={code}
              data={availableSectionsDistinctData[code]}
              isSelectingFromList={isSelectingFromList}
              sectionControl={section.control}
              sectionParameters={reportParameters[code]}
              sectionTitle={section.title}
              setIsSelectingFromList={setIsSelectingFromList}
              setReportParameters={setReportParameters}
            />
          ) : (
            <ReportsFocusDistinctDataList
              code={code}
              data={availableSectionsDistinctData[code]}
              sectionControl={section.control}
              sectionParameters={reportParameters[code]}
              sectionTitle={section.title}
              setReportParameters={setReportParameters}
            />
          )
        ) : (
          <></>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default ReportsFocusToggledAvailableSectionPanel;
