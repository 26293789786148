import { rolesOptions } from '../../utils/constants';
import { useAlert } from 'react-alert';
import { useContext, useState } from 'react';
import { UserPortal } from '../../contexts';
import { validatePresenceRequired } from '../../utils/helpers';

import ButtonIconOnly from './ButtonIconOnly';
import FlexContainer from './FlexContainer';
import HeaderSecondary from './HeaderSecondary';
import InputSelect from './InputSelect';
import InputStandard from './InputStandard';
import InputTextareaNew from './InputTextareaNew';
import SelectStandard from './SelectStandard';

import './NewLineItem.css';

const NewLineItem = ({
  demo,
  itemId,
  itemKey,
  lineIndex,
  lineItem,
  handleChangeValue,
  showInvalid,
  showInvalidIfEdited,
}) => {
  const alert = useAlert();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;

  const [addFromListItems, setAddFromListItems] = useState([]);
  const [itemToLink, setItemToLink] = useState();

  const [hasBeenEdited, setHasBeenEdited] = useState(false);

  const handleLinkItem = () => {
    if (
      addFromListItems.some(
        (item) => item[lineItem.addFromListKey] === parseInt(itemToLink)
      )
    ) {
      alert.show('Already added');

      setItemToLink(0);

      return;
    }

    const newListItems = [
      ...addFromListItems,
      lineItem.addFromList.find(
        (item) => item[lineItem.addFromListKey] === parseInt(itemToLink)
      ),
    ];

    handleChangeValue({ target: { value: newListItems } }, lineIndex, itemKey);

    setAddFromListItems(
      newListItems.sort((a, b) =>
        a[lineItem.addFromListValue].localeCompare(b[lineItem.addFromListValue])
      )
    );

    setItemToLink(0);
  };

  return lineItem.type === 'text' ? (
    <InputStandard
      id={itemId}
      disabled={lineItem.disabled}
      invalid={lineItem.validation?.(lineItem.value, lineItem.title)}
      labelText={lineItem.title}
      name='new-agent-item'
      onChange={(event) => {
        setHasBeenEdited(true);

        handleChangeValue(event, lineIndex, itemKey);
      }}
      placeholder={`< ${lineItem.title} >`}
      isRequired={lineItem.isRequired}
      value={lineItem.value}
      direction='vertical'
      marginBottom='5px'
      showInvalid={showInvalid || (showInvalidIfEdited && hasBeenEdited)}
      typeStyle='type-2'
    />
  ) : lineItem.type === 'array' ? (
    <InputTextareaNew
      id={itemId}
      classes='new-line-item-array'
      labelText={lineItem.title}
      onChange={(event) => handleChangeValue(event, lineIndex, itemKey)}
      placeholder={`< ${lineItem.title} >`}
      value={lineItem.value}
      rows={5}
      marginBottom='5px'
      typeStyle='type-2'
      direction='vertical'
    />
  ) : lineItem.type === 'addFromList' ? (
    <FlexContainer classes='new-line-item-add-from-list' flexDirection='column'>
      <div
        className='table-container table-container-table'
        style={{ paddingTop: '0' }}
      >
        <ul className='table'>
          <>
            <li className='header-row'>
              <HeaderSecondary classes='header-cell' text='Linked Agents' />
            </li>

            {addFromListItems?.map((item, index) => {
              return (
                <li
                  key={item.id}
                  className={`${
                    index === 0 ? 'first-grid-row ' : ''
                  } grid-row linked-agents-grid-row`}
                >
                  <a
                    href={`/${demo ? 'demo' : portalId}/${
                      lineItem.addFromListPath
                    }/${item.id}`}
                    className='grid-row-cell linked-agents-grid-row-cell'
                  >
                    <div className='grid-row-cell-data'>
                      {item[lineItem.addFromListValue]}
                    </div>
                  </a>

                  <div className='grid-row-cell linked-agents-unlink-button'>
                    <ButtonIconOnly
                      onClick={() => {
                        const newListItems = addFromListItems.filter(
                          (addFromListItem) => addFromListItem.id !== item.id
                        );

                        handleChangeValue(
                          { target: { value: newListItems } },
                          lineIndex,
                          itemKey
                        );

                        setAddFromListItems(newListItems);
                      }}
                      title={lineItem.addFromListRemoveTitle}
                      iconName={lineItem.addFromListRemoveIcon}
                      typeStyle='type-2'
                    />
                  </div>
                </li>
              );
            })}
          </>
        </ul>
      </div>

      <FlexContainer classes='new-line-item-add-from-list-select'>
        <SelectStandard
          containerStyle={{ marginLeft: '5px' }}
          labelText={lineItem.addFromListLabel}
          onChange={(event) => {
            setItemToLink(event.target.value);
          }}
          optionKeyValue={{
            key: lineItem.addFromListKey,
            value: lineItem.addFromListValue,
          }}
          options={[
            {
              [lineItem.addFromListKey]: 0,
              [lineItem.addFromListValue]: lineItem.addFromListPlaceholder,
            },
            ...lineItem.addFromList,
          ]}
          value={itemToLink}
        />

        <ButtonIconOnly
          alignSelf='flex-end'
          disabled={!itemToLink || itemToLink === '0'}
          iconName={lineItem.addFromListIcon}
          margin='0 5px 0'
          onClick={handleLinkItem}
          title='Link'
        />
      </FlexContainer>
    </FlexContainer>
  ) : lineItem.type === 'select' ? (
    <SelectStandard
      containerClasses='new-line-item-select'
      marginBottom='5px'
      id='user-data-role'
      invalid={
        lineItem.value
          ? null
          : [validatePresenceRequired(lineItem.value, "'Role'")]
      }
      isRequired={lineItem.isRequired}
      labelText='Role'
      onChange={(event) => {
        handleChangeValue(event, lineIndex, itemKey);
      }}
      options={rolesOptions}
      showInvalid={showInvalid}
      value={lineItem.value}
    />
  ) : (
    <InputSelect options={[]} />
  );
};

export default NewLineItem;
