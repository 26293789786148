import tickSmall from '../../images/tick-small.png';

import './About.css';

const BrandingFeatures = () => {
  return (
    <ul className='about-screen-list'>
      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Upload your company logo and it will be displayed on the navigation
          bar instead of the QA Portal logo for all users
        </p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Your company name will appear on the navigation bar and within the
          navigation screen for all users
        </p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Logos and company names will appear in your users' account areas</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Scorecards can be personalised with your company name and logo</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          You have the freedom to choose alternative branding for individual
          scorecards, or you can set your brand defaults and use the same
          details for each scorecard you create
        </p>
      </li>
    </ul>
  );
};

export default BrandingFeatures;
