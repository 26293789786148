const calculateQualityScore = (
  behaviourTotals,
  previousMark,
  passFailNa,
  section,
  markingChoice,
  sectionResults,
  uniqueRef
) => {
  const sectionWeight = parseInt(section.weight);
  const sectionRa = parseInt(section.ra);
  const sectionCsm = parseInt(section.csm);

  // weight, ra, and csm can't have decimals?

  const [sectionPos, sectionNeg] = markingChoice;
  const { title } = section;

  const newBehaviourTotals = { ...behaviourTotals };
  let { weightOutOf, weightTotal, ra, csm } = newBehaviourTotals;
  ra = parseInt(ra);
  let newCsm = [...csm];

  const newSectionResults = { ...sectionResults };
  let {
    markedCount,
    passedSections,
    failedSections,
    failedRa,
    failedWeight,
    failedCsm,
  } = newSectionResults;
  let newPassedSections = [...passedSections];
  let newFailedSections = [...failedSections];
  let newFailedRa = [...failedRa];
  let newFailedWeight = [...failedWeight];
  let newFailedCsm = [...failedCsm];

  if (previousMark === 'NA' && passFailNa !== 'NA') markedCount++;
  else if (previousMark !== 'NA' && passFailNa === 'NA') markedCount--;

  if (previousMark !== sectionPos && passFailNa === sectionPos)
    newPassedSections.push({
      ...section,
      uniqueRef: section.type + section.id,
    });
  else if (previousMark === sectionPos && passFailNa !== sectionPos)
    newPassedSections = newPassedSections.filter(
      (currSection) => currSection.uniqueRef !== uniqueRef
    );

  if (previousMark !== sectionNeg && passFailNa === sectionNeg)
    newFailedSections.push({
      ...section,
      uniqueRef: section.type + section.id,
    });
  else if (previousMark === sectionNeg && passFailNa !== sectionNeg)
    newFailedSections = newFailedSections.filter(
      (currSection) => currSection.uniqueRef !== uniqueRef
    );

  if (sectionWeight) {
    if (previousMark === 'NA' && passFailNa !== 'NA') {
      weightOutOf += sectionWeight;
    }
    if (previousMark !== sectionPos && passFailNa === sectionPos) {
      weightTotal += sectionWeight;
    }
    if (previousMark !== 'NA' && passFailNa === 'NA') {
      weightOutOf -= sectionWeight;
    }
    if (previousMark === sectionPos && passFailNa !== sectionPos) {
      weightTotal -= sectionWeight;
    }
    if (previousMark !== sectionNeg && passFailNa === sectionNeg) {
      newFailedWeight.push({
        ...section,
        uniqueRef: section.type + section.id,
      });
    } else if (previousMark === sectionNeg && passFailNa !== sectionNeg) {
      newFailedWeight = newFailedWeight.filter(
        (currSection) => currSection.uniqueRef !== uniqueRef
      );
    }
  }
  if (sectionRa) {
    if (previousMark !== sectionNeg && passFailNa === sectionNeg) {
      ra += sectionRa;
      newFailedRa.push({
        ...section,
        uniqueRef: section.type + section.id,
      });
    } else if (previousMark === sectionNeg && passFailNa !== sectionNeg) {
      ra -= sectionRa;
      newFailedRa = newFailedRa.filter(
        (currSection) => currSection.uniqueRef !== uniqueRef
      );
    }
  }

  if (sectionCsm) {
    if (previousMark !== sectionNeg && passFailNa === sectionNeg) {
      newCsm.push({
        csmAmount: sectionCsm,
        sectionName: title,
        sectionRef: uniqueRef,
      });
      newCsm.sort((a, b) => a.csmAmount - b.csmAmount);

      newFailedCsm.push({
        ...section,
        uniqueRef: section.type + section.id,
      });
    } else if (previousMark === sectionNeg && passFailNa !== sectionNeg) {
      newCsm = newCsm.filter((currSection) => {
        return currSection.sectionRef !== uniqueRef;
      });
      newFailedCsm = newFailedCsm.filter(
        (currSection) => currSection.uniqueRef !== uniqueRef
      );
    }
  }

  let newQualityScore = 0;

  const csmValue = newCsm.length > 0 ? parseInt(newCsm[0].csmAmount) : 100;

  const preCsm = Math.floor(
    (weightOutOf > 0 ? (weightTotal / weightOutOf) * 100 : 100) - ra
  );

  let wasCsmUsed = false;

  if (preCsm > csmValue) {
    wasCsmUsed = true;
    newQualityScore = csmValue;
  } else {
    newQualityScore = preCsm <= 0 ? 0 : preCsm;
  }

  newBehaviourTotals.csm = newCsm;
  newBehaviourTotals.weightOutOf = weightOutOf;
  newBehaviourTotals.weightTotal = weightTotal;
  newBehaviourTotals.ra = ra;

  newSectionResults.markedCount = markedCount;
  newSectionResults.passedSections = newPassedSections;
  newSectionResults.failedSections = newFailedSections;
  newSectionResults.failedWeight = newFailedWeight;
  newSectionResults.failedRa = newFailedRa;
  newSectionResults.failedCsm = newFailedCsm;

  return {
    preCsm,
    newQualityScore,
    newBehaviourTotals,
    wasCsmUsed,
    newSectionResults,
  };
};

export default calculateQualityScore;
