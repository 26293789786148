import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useContext, useState } from 'react';
import { UserPortal } from '../../../../contexts';

import apiDataService from '../../../../api/billing';
import moment from 'moment';
import SelectStandard from '../../../Elements/SelectStandard';
import Text from '../../../Elements/Text';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AutomaticPayments = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const [billingDate, setBillingDate] = useState('1');

  const fetchClientSecret = useCallback(async () => {
    let today = moment();

    let newBillingDate = moment().date(billingDate);

    if (newBillingDate.isBefore(today, 'day')) {
      newBillingDate.add(1, 'months');
    }

    const billingCycleAnchor = newBillingDate.unix();

    const response = await apiDataService.createCheckoutSession({
      billingDate: billingCycleAnchor,
      portalId,
      token: await getAccessTokenSilently(),
      userId,
    });

    return response.clientSecret;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingDate]);

  const options = { fetchClientSecret };

  return (
    <>
      <SelectStandard
        id='select-bill-date'
        labelText='Invoice date'
        marginBottom='10px'
        onChange={(event) => setBillingDate(event.target.value)}
        options={[
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
        ]}
        value={billingDate}
      />

      <Text
        marginBottom='10px'
        text='Payments will be taken automatically when your invoices are produced.'
        textAlign='center'
      />
      <Text
        marginBottom='10px'
        text="You can check the estimated amount of your next invoice at any time on this screen under 'Next Invoice'."
        textAlign='center'
      />
      <Text
        marginBottom='10px'
        text="Choose 'Manual Payments' if you would prefer to defer payment due dates to 30 days after your invoices are produced."
        textAlign='center'
      />

      <div id='checkout'>
        <EmbeddedCheckoutProvider
          key={billingDate}
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </>
  );
};

export default AutomaticPayments;
