import tickSmall from '../../images/tick-small.png';

import './About.css';

const IntegrationFeatures = () => {
  return (
    <ul className='about-screen-list'>
      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Import your existing data into the portal</p>

        <ul className='about-screen-list about-screen-list-nested'>
          <li>
            <p>Evaluations / Agents / Users</p>
          </li>
        </ul>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>We provide templates for importing data</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>We can help format your import data if you require any assistance</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Import in CSV file format</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Export data from any of the main data grids in csv format</p>
      </li>
    </ul>
  );
};

export default IntegrationFeatures;
