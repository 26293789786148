import { emptyValue } from '../../../../utils/helpers';

import AutoWidthInput from '../../../Elements/AutoWidthInput';
import ClauseSelect from './ClauseSelect';
import Text from '../../../Elements/Text';

import './ClauseCriteria.css';

const inputClassName = 'criteria-input';

const ClauseCriteria = ({
  column,
  columnType,
  disabled,
  title,
  operator,
  handleChangeValueCriteria,
  handleChangeRangeCriteria1,
  handleChangeRangeCriteria2,
  valueCriteria,
  rangeCriteria,
  selectFrom,
}) => {
  if (
    ['null', 'ntnull', 'wk', 'lwk', 'mnth', 'lmnth', 'yr', 'lyr'].includes(
      operator
    )
  ) {
    return <></>;
  } else if (columnType === 'string') {
    return (
      <AutoWidthInput
        className={`${inputClassName} ${
          emptyValue(valueCriteria) ? 'placeholder' : 'has-value'
        }`}
        disabled={disabled}
        title={title}
        placeholder='< criteria >'
        value={valueCriteria}
        handleChangeValue={handleChangeValueCriteria}
        initialWidth={72}
        additionalWidth={6}
        type='text'
        columnType={columnType}
      />
    );
  } else if (columnType === 'int') {
    if (operator.includes('bet')) {
      return (
        <>
          <AutoWidthInput
            className={`${inputClassName} ${
              emptyValue(rangeCriteria[0]) ? 'placeholder' : 'has-value'
            }`}
            disabled={disabled}
            title={title}
            placeholder='< 0 >'
            value={rangeCriteria[0]}
            handleChangeValue={handleChangeRangeCriteria1}
            initialWidth={30}
            additionalWidth={25}
            type='number'
          />

          <Text text='&' marginLeft='10px' />

          <AutoWidthInput
            className={`${inputClassName} ${
              emptyValue(rangeCriteria[1]) ? 'placeholder' : 'has-value'
            }`}
            disabled={disabled}
            title={title}
            placeholder='< 0 >'
            value={rangeCriteria[1]}
            handleChangeValue={handleChangeRangeCriteria2}
            initialWidth={30}
            additionalWidth={25}
            type='number'
          />
        </>
      );
    } else {
      return (
        <AutoWidthInput
          className={`${inputClassName} ${
            emptyValue(valueCriteria) ? 'placeholder' : 'has-value'
          }`}
          disabled={disabled}
          title={title}
          placeholder='< 0 >'
          value={valueCriteria}
          handleChangeValue={handleChangeValueCriteria}
          initialWidth={30}
          additionalWidth={25}
          type='number'
        />
      );
    }
  } else if (columnType === 'datetime' || columnType === 'time') {
    const type = columnType === 'datetime' ? 'date' : 'time';

    if (operator.includes('bet')) {
      return (
        <>
          <input
            className={`${inputClassName} date-input ${
              emptyValue(rangeCriteria[0]) ? 'placeholder' : 'has-value'
            }`}
            disabled={disabled}
            title={title}
            value={rangeCriteria[0]}
            onChange={handleChangeRangeCriteria1}
            type={type}
          />

          <Text text='&' marginLeft='10px' />

          <input
            className={`${inputClassName} date-input ${
              emptyValue(rangeCriteria[1]) ? 'placeholder' : 'has-value'
            }`}
            disabled={disabled}
            title={title}
            value={rangeCriteria[1]}
            onChange={handleChangeRangeCriteria2}
            type={type}
          />
        </>
      );
    } else if (operator === 'prev') {
      return (
        <>
          <AutoWidthInput
            className={`${inputClassName} ${
              emptyValue(rangeCriteria[0]) ? 'placeholder' : 'has-value'
            }`}
            disabled={disabled}
            title={title}
            placeholder='< 0 >'
            value={rangeCriteria[0]}
            handleChangeValue={handleChangeRangeCriteria1}
            initialWidth={30}
            additionalWidth={25}
            type='number'
          />

          <ClauseSelect
            disabled={disabled}
            title={title}
            value={rangeCriteria[1]}
            handleChangeOption={handleChangeRangeCriteria2}
            options={[
              ['days', 'day(s)'],
              ['weeks', 'week(s)'],
              ['months', 'month(s)'],
              ['years', 'year(s)'],
            ]}
            placeholder={['', '< select >']}
            initialWidth={65}
          />
        </>
      );
    } else {
      return (
        <input
          className={`${inputClassName} date-input ${
            emptyValue(valueCriteria) ? 'placeholder' : 'has-value'
          }`}
          disabled={disabled}
          title={title}
          value={valueCriteria}
          onChange={handleChangeValueCriteria}
          type={type}
        />
      );
    }
  } else if (columnType === 'bool') {
    return (
      <ClauseSelect
        disabled={disabled}
        title={title}
        value={valueCriteria}
        handleChangeOption={handleChangeValueCriteria}
        options={[
          ['true', 'Yes'],
          ['false', 'No'],
        ]}
        placeholder={['', '< select >']}
        initialWidth={65}
      />
    );
  } else if (columnType === 'string bool') {
    return (
      <ClauseSelect
        disabled={disabled}
        title={title}
        value={valueCriteria}
        handleChangeOption={handleChangeValueCriteria}
        options={[
          ['Yes', 'Yes'],
          ['No', 'No'],
        ]}
        placeholder={['', '< select >']}
        initialWidth={65}
      />
    );
  } else if (columnType === 'selectFrom') {
    return (
      <ClauseSelect
        disabled={disabled}
        title={title}
        value={valueCriteria}
        handleChangeOption={handleChangeValueCriteria}
        options={selectFrom[column]}
        placeholder={['', '< select >']}
        initialWidth={65}
      />
    );
  } else if (columnType === 'array') {
    return (
      <AutoWidthInput
        className={`${inputClassName} ${
          emptyValue(valueCriteria) ? 'placeholder' : 'has-value'
        }`}
        disabled={disabled}
        title={title}
        placeholder='< criteria >'
        value={valueCriteria}
        handleChangeValue={handleChangeValueCriteria}
        initialWidth={72}
        additionalWidth={6}
        type='text'
        columnType={columnType}
      />
    );
  } else {
    return <></>;
  }
};

export default ClauseCriteria;
