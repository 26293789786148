import './PanelsSections.css';

const PanelsSections = ({
  children,
  alignItems,
  classes = '',
  justifyContent,
  margin,
  typeStyle,
}) => {
  const style = {};

  if (alignItems) style.alignItems = alignItems;
  if (justifyContent) style.justifyContent = justifyContent;
  if (margin) style.margin = margin;

  return (
    <div
      className={`panels-sections ${
        typeStyle ? `panels-sections-${typeStyle}` : ''
      } ${classes}`}
      style={style}
    >
      {children}
    </div>
  );
};

export default PanelsSections;
