import './InfoText.css';

const InfoText = ({ className = '', classes = '', style = {}, text, type }) => {
  return (
    <span
      className={`info-text ${
        type ? `info-text--${type}` : ''
      }  ${classes} ${className}`}
      style={style}
    >
      {text}
    </span>
  );
};

export default InfoText;
