import './ReportsTable.css';

const ReportsTable = ({ fromPrintable, reportData }) => {
  const { columnsForReports, customColumnHeaders, reportRows } = reportData;

  const reportColumns = columnsForReports ?? [];

  return (
    <>
      <div
        className={`report-results-table-container ${
          fromPrintable ? 'from-printable' : ''
        }`}
      >
        <table className='report-results-table'>
          <thead>
            <tr
              className={`report-results-table-header-row ${
                reportColumns.length === 1 ? 'single-column-report-header' : ''
              }`}
            >
              {reportColumns.map((column) => {
                return (
                  <th key={column.id}>
                    {customColumnHeaders[column.id] ||
                      `${column.value}${
                        column.dateFrequency === 'week' ? ' (w/c)' : ''
                      }`}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {reportRows?.map((reportRow, index) => {
              return (
                <tr key={index}>
                  {reportRow.map((cell, index) => {
                    return <td key={index}>{cell.mutatedVal}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ReportsTable;
