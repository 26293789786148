import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { UserPortal } from '../../contexts';

import logo from '../../images/qaportal-logo.png';
import reportsMeeting from '../../images/qa_no_background4.png';

import './HomeHeader.css';

const HomeHeader = () => {
  const { userFromDb } = useContext(UserPortal);

  return (
    <div className='home-header'>
      <div className='home-header-text'>
        <img className='home-header-logo' src={logo} alt='QA Portal Logo' />
        <h2 className='home-header-subtitle'>
          Quality Assurance Monitoring & Reporting Software
        </h2>
        <h3 className='home-header-desc'>
          An all-in-one solution for QA teams and call centre managers
        </h3>

        {userFromDb.id && userFromDb.portal_id ? (
          <Link to={`/${userFromDb.portal_id}/dashboard`}>
            <button className='home-header-btn'>View Dashboard</button>
          </Link>
        ) : (
          <Link to='/try'>
            <button className='home-header-btn'>Try for Free</button>
          </Link>
        )}
      </div>
      <img
        className='home-header-pic'
        src={reportsMeeting}
        alt='reports meeting'
      />
    </div>
  );
};

export default HomeHeader;
