import { handleApiError } from '../../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import apiDataServiceBilling from '../../../../api/billing';
import ButtonStandardNoFill from '../../../Elements/ButtonStandardNoFill';
import Text from '../../../Elements/Text';

const ChangeToManual = ({
  billingEmail,
  isLoading,
  portalId,
  setIsLoading,
  subscriptionId,
  userId,
}) => {
  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const handleAction = async () => {
    try {
      setIsLoading(true);

      await apiDataServiceBilling.update({
        portalId,
        reqBody: {
          updates: [{ subscriptionId, type: 'toManual' }],
          userId,
        },
        token: await getAccessTokenSilently(),
      });

      setIsLoading(false);

      navigate(`/${portalId}/return?email=${billingEmail}&has_changed=true`, {
        state: { isNavigating: true },
      });
    } catch (error) {
      setIsLoading(false);

      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  return (
    <>
      <Text
        marginBottom='10px'
        text='Payments will be due 30 days after your invoices are produced.'
        textAlign='center'
      />

      <ButtonStandardNoFill
        alignSelf='center'
        disabled={isLoading}
        iconName='save-solid'
        onClick={handleAction}
        text='Confirm'
        typeStyle='type-4'
      />
    </>
  );
};

export default ChangeToManual;
