import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { scrollToBottom } from '../../../utils/createScorecardUtils/createScorecardUtils';
import { useDrop } from 'react-dnd';

import AdminSection from './AdminSection';
import ButtonToggle from '../../Elements/ButtonToggle';
import FormInfoText from '../../Elements/FormInfoText';
import update from 'immutability-helper';

import './AdminSectionsContainer.css';

const AdminSectionsContainer = memo(function Container({
  cards,
  currentlyEditing,
  edgeMargins,
  handleSetHasChanges,
  hasChangePermission,
  setCards,
  setControl,
  setCurrentlyEditing,
  setDropdownList,
  setIsNewListChecked,
  setIsRequired,
  setSectionName,
  setShouldScrollToBtm,
  shouldScrollToBtm,
}) {
  const scrollableSections = useRef(null);

  const [isDragOn, setIsDragOn] = useState(true);

  useEffect(() => {
    if (shouldScrollToBtm) {
      scrollToBottom(scrollableSections);
      setShouldScrollToBtm(false);
    }
  }, [shouldScrollToBtm, setShouldScrollToBtm]);

  const ItemTypes = {
    CARD: 'admin-card',
  };

  const findCard = useCallback(
    (id) => {
      const card = cards.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards]
  );
  const moveCard = useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id);

      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      );
    },
    [findCard, cards, setCards]
  );
  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

  return (
    <div className='admin-sections-container' ref={drop}>
      <ul
        ref={scrollableSections}
        className='create-admin-sections-list'
        style={{
          marginLeft: edgeMargins.left,
          marginRight: edgeMargins.right,
        }}
      >
        {cards.map((card) => {
          return (
            <AdminSection
              key={card.id}
              id={`${card.id}`}
              moveCard={moveCard}
              findCard={findCard}
              hasChangePermission={hasChangePermission}
              isDndOn={hasChangePermission && isDragOn}
              section={card}
              adminSections={cards}
              setAdminSections={setCards}
              setSectionName={setSectionName}
              setControl={setControl}
              setDropdownList={setDropdownList}
              setIsNewListChecked={setIsNewListChecked}
              setIsRequired={setIsRequired}
              currentlyEditing={currentlyEditing}
              setCurrentlyEditing={setCurrentlyEditing}
              handleSetHasChanges={handleSetHasChanges}
            />
          );
        })}
      </ul>

      <div className='added-scoring-section-counters'>
        <ButtonToggle
          margin='10px'
          onClick={() => setIsDragOn((curr) => !curr)}
          text={`Dragging - ${isDragOn ? 'On' : 'Off'}`}
          toggled={isDragOn}
        />

        {cards.length > 0 && (
          <FormInfoText
            style={{ margin: '10px' }}
            text={
              cards.length === 1
                ? `${cards.length} section`
                : `${cards.length} sections`
            }
          />
        )}
      </div>
    </div>
  );
});

export default AdminSectionsContainer;
