function updateScoringSections(command) {
  const { add, del, edit } = command;

  if (add) {
    const {
      type,
      scoringSections,
      setScoringSections,
      sectionName,
      setSectionName,
      sectionDescription,
      setSectionDescription,
      weight,
      setWeight,
      ra,
      setRa,
      csm,
      setCsm,
      isRequired,
      setIsRequired,
      setSectionCount,
      currentlyEditingSec,
      setCurrentlyEditingSec,
      setShouldScrollToBtm,
    } = add;

    const requiredOrOptional = isRequired ? 'Required' : 'Optional';

    let overwrittenRef = '';
    let overwrittenSec = '';

    const copiedScoringSections = scoringSections.map((section, index) => {
      if (section.uniqueRef === currentlyEditingSec) {
        const { uniqueRef, title } = section;

        overwrittenRef = uniqueRef;
        overwrittenSec = title;

        return {
          ...section,
          type: type,
          uniqueRef: uniqueRef,
          title: sectionName,
          description: sectionDescription,
          weight: weight,
          ra: ra,
          csm: csm,
          required: requiredOrOptional,
          originalRequired: isRequired,
          id: index,
        };
      } else return { ...section, id: index };
    });
    if (overwrittenRef) setCurrentlyEditingSec('');
    else {
      const newUniqueRef = `${Math.floor(
        Math.random() * 100000000000
      )}${Date.now()}`;

      copiedScoringSections.push({
        type: type,
        uniqueRef: newUniqueRef,
        title: sectionName,
        description: sectionDescription,
        weight: weight,
        ra: ra,
        csm: csm,
        required: requiredOrOptional,
        originalRequired: isRequired,
        id: copiedScoringSections.length,
      });
      setSectionCount((currVal) => currVal + 1);
      setShouldScrollToBtm(true);
    }
    setScoringSections(copiedScoringSections);
    setSectionName('');
    setSectionDescription('');
    setWeight('');
    setRa('');
    setCsm('');
    setIsRequired(true);

    return { overwrittenRef: overwrittenRef, overwrittenSec: overwrittenSec };
  } else if (del) {
    const {
      sectionType,
      sectionUniqueRef,
      scoringSections,
      setScoringSections,
      setCategoryCount,
      setSectionCount,
      currentlyEditingCat,
      setCurrentlyEditingCat,
      currentlyEditingSec,
      setCurrentlyEditingSec,
    } = del;

    if (currentlyEditingCat === sectionUniqueRef) setCurrentlyEditingCat('');
    else if (currentlyEditingSec === sectionUniqueRef)
      setCurrentlyEditingSec('');

    if (sectionType === 'scoring-category')
      setCategoryCount((currCount) => currCount - 1);
    else if (sectionType === 'scoring-section')
      setSectionCount((currCount) => currCount - 1);

    const copiedScoringSections = scoringSections.filter((section) => {
      return section.uniqueRef !== sectionUniqueRef;
    });

    setScoringSections(copiedScoringSections);
  } else if (edit) {
    const {
      sectionToEdit,
      setCategoryName,
      setSectionName,
      setSectionDescription,
      setWeight,
      setRa,
      setCsm,
      setIsRequired,
      currentlyEditingCat,
      setCurrentlyEditingCat,
      currentlyEditingSec,
      setCurrentlyEditingSec,
    } = edit;

    const {
      uniqueRef,
      type,
      title,
      description,
      weight,
      ra,
      csm,
      originalRequired,
    } = sectionToEdit;

    if (currentlyEditingCat === uniqueRef) setCurrentlyEditingCat('');
    else if (currentlyEditingSec === uniqueRef) setCurrentlyEditingSec('');
    else {
      if (type === 'scoring-category') {
        setCurrentlyEditingCat(uniqueRef);
        setCategoryName(title);
      } else {
        setCurrentlyEditingSec(uniqueRef);
        setSectionName(title);
        setSectionDescription(description);
        setWeight(weight);
        setRa(ra);
        setCsm(csm);
        setIsRequired(originalRequired);
      }
    }
  }
}

export default updateScoringSections;
