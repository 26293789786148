import { Link } from 'react-router-dom';

import FlexContainer from '../Elements/FlexContainer';
import Text from '../Elements/Text';

import './AboutPanel.css';

const AboutPanel = ({
  children,
  headerText = '',
  id,
  images = [],
  link,
  swap,
}) => {
  return (
    <div
      className={`about-screen-panel-container ${
        swap ? 'about-screen-panel-container-swap' : ''
      } ${!images.length ? 'about-screen-panel-container-no-images' : ''}`}
      id={id}
    >
      <FlexContainer
        classes='about-screen-panel'
        flexDirection='column'
        justifyContent='center'
      >
        <h1 className='about-screen-header' style={{ margin: '30px 0' }}>
          {headerText}
        </h1>

        <div className='about-features-section'>{children}</div>

        {link ? (
          <Link className='about-screen-panel-header-link' to={`/demo${link}`}>
            <Text textDecoration='underline' text='Take a look' />
          </Link>
        ) : (
          <></>
        )}
      </FlexContainer>

      <div className='about-screen-panel-background'></div>

      <FlexContainer
        classes='about-screen-panel-images'
        justifyContent='space-evenly'
        flexDirection='column'
      >
        {images.map((image, index) => {
          return (
            <img
              key={index}
              style={{
                alignSelf: index % 2 === 0 ? 'flex-start' : 'flex-end',
                maxWidth: '90%',
              }}
              src={image.src}
              alt=''
            />
          );
        })}
      </FlexContainer>
    </div>
  );
};

export default AboutPanel;
