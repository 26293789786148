import { rolesOptions } from '../../../utils/constants';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { UserPortal } from '../../../contexts';
import {
  usersAvailableColumns,
  usersDefaultColumns,
  usersRequiredFields,
  usersUnEditableColumns,
  usersValidationColumns,
} from '../columns';

import apiDataService from '../../../api/users';
import DataGrid from '../DataGrid/DataGrid';

const Users = ({ demo }) => {
  const { getUserFromDb } = useOutletContext();

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const alert = useAlert();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const getAvailableColumns = (columns = {}) => {
    return { ...usersAvailableColumns, ...columns };
  };

  const defaultOptions = {
    customColumns: null,
    limit: 10,
    page: 1,
    query: null,
    sortBy: null,
    view: 'table',
  };

  const selectFrom = {
    role: rolesOptions,
    status: demo
      ? [['demo', 'Demo']]
      : [
          ['active', 'Active'],
          ['inactive', 'Inactive'],
        ],
  };

  const onDelete = async (selectedRecordIds) => {
    const { users: adminUsers } = await apiDataService.getAll({
      params: [
        `q=${encodeURIComponent('((role==eq::admin&&sub==nt::not set))')}`,
      ],
      portalId,
      userId,
      token: await getAccessTokenSilently(),
    });

    const everyAdminUserSelected = adminUsers.every((adminUser) =>
      selectedRecordIds.includes(adminUser.id)
    );

    if (everyAdminUserSelected) {
      alert.info(
        'Unable to delete as no other admin users belong to this portal. Please assign another user to the role of ADMIN first.',
        { timeout: 10000 }
      );

      return false;
    } else {
      return true;
    }
  };

  const onAfterSuccessfulDelete = async (ids) => {
    if (ids.includes(userId)) {
      navigate('/', {
        state: { isNavigating: true },
      });
    } else {
      await getUserFromDb();
    }
  };

  return (
    <DataGrid
      apiDataService={apiDataService}
      defaultColumns={usersDefaultColumns}
      defaultOptions={defaultOptions}
      demo={demo}
      getAvailableColumns={getAvailableColumns}
      gridName='users'
      iconName='users'
      newScreenRoute='new'
      onAfterSuccessfulDelete={onAfterSuccessfulDelete}
      onDelete={onDelete}
      requiredFields={usersRequiredFields}
      selectFrom={selectFrom}
      unEditableColumns={usersUnEditableColumns}
      validationColumns={usersValidationColumns}
    />
  );
};

export default Users;
