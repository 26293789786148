import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { UserPortal } from '../../contexts';

import apiDataService from '../../api/dd-lists';

const AdminSectionDropDown = ({
  demo,
  elementId,
  ddListTable,
  ddListId,
  fromPrintable,
  onChange,
  title,
  value,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const alert = useAlert();

  const [ddList, setDdList] = useState([]);
  const [ddListName, setDdListName] = useState('');

  const getDdList = async () => {
    try {
      if (ddListTable && ddListId) {
        const { ddList } = await apiDataService.getDdListById({
          table: ddListTable,
          tableId: ddListId,
          token: demo ? undefined : await getAccessTokenSilently(),
          userId: demo ? 1 : userId,
          portalId: demo ? 'demo' : portalId,
        });

        setDdList(ddList?.list || []);
        setDdListName(ddList?.name || 'No list set');
      } else {
        alert.error(`'${title}' Drop down list not found`, { timeout: 10000 });

        setDdList([]);
        setDdListName('No list set');
        onChange({ target: { value: '' } });
      }
    } catch (error) {
      alert.error(`'${title}' Drop down list not found`, { timeout: 10000 });

      setDdList([]);
      setDdListName('No list set');
      onChange({ target: { value: '' } });
    }
  };

  useEffect(() => {
    if (!fromPrintable) getDdList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ddListTable, ddListId]);

  return (
    <select
      className='admin-section-control-dropdown'
      id={elementId}
      onChange={onChange}
      value={value}
    >
      <option value=''>{`< ${ddListName} >`}</option>

      {ddList.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
};

export default AdminSectionDropDown;
