import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { handleApiError } from '../../../utils/error-handling';
import { shouldNavigateOnNotFound } from '../../../utils/helpers';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import apiDataService from '../../../api/calibrations';
import EvaluationsNewChosenScorecard from '../Evaluations/EvaluationsNewChosenScorecard';
import FlexContainer from '../../Elements/FlexContainer';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import MainOnly from '../MainAndOptions/MainOnly';
import Panels from '../../Elements/Panels';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';

const CalibrationEvaluation = ({ demo }) => {
  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;

  const { id } = useParams();
  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  const navigate = useNavigate();

  const [calibration, setCalibration] = useState();
  const [currentUserEvaluation, setCurrentUserEvaluation] = useState();

  const getCalibration = async () => {
    try {
      const userId = demo ? 1 : userFromDb.id;

      const { calibration, currentUserEvaluation } =
        await apiDataService.getCalibrationById({
          token: demo ? undefined : await getAccessTokenSilently(),
          userId,
          portalId: demo ? 'demo' : portalId,
          calibrationId: id,
        });

      if (!calibration.participants.includes(userId)) {
        alert.error(
          'Must be added as a participant to access this page. Please speak to the calibration Host.',
          { timeout: 10000 }
        );

        navigate(`/${demo ? 'demo' : portalId}/calibrations/${id}`, {
          state: { isNavigating: true },
        });
      }

      setCalibration(calibration);
      setCurrentUserEvaluation(currentUserEvaluation);

      setBreadcrumbs(() => {
        return [
          [
            'Dashboard',
            'dashboard',
            '',
            `/${demo ? 'demo' : portalId}/dashboard`,
          ],
          [
            'Calibrations',
            'bullseye',
            '',
            `/${demo ? 'demo' : portalId}/calibrations`,
          ],
          [
            calibration?.scorecard_name,
            'magnifying-glass',
            '',
            `/${demo ? 'demo' : portalId}/calibrations/${id}`,
          ],
          ['Scorecard', 'scorecards'],
        ];
      });
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      if (shouldNavigateOnNotFound(error)) {
        navigate(`/${demo ? 'demo' : portalId}/calibrations`, {
          state: { isNavigating: true },
        });
      }
    }
  };

  useEffect(() => {
    getCalibration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!calibration?.scorecard_id) return <LoadingSpinner />;
  else
    return (
      <MainOnly>
        <Panels>
          <PanelsHeaderPrimary
            iconName='scorecards'
            subIcon='bullseye'
            text='Calibration Scorecard'
          />

          <FlexContainer padding='0 20px 20px' marginBottom='110px'>
            <EvaluationsNewChosenScorecard
              calibrationLocked={calibration.locked}
              calibrationId={calibration.id}
              currentUserEvaluation={currentUserEvaluation}
              demo={demo}
              fromCalibrations
              isUserAParticipant={calibration?.participants?.includes(
                userFromDb.id
              )}
              scorecardId={calibration.scorecard_id}
            />
          </FlexContainer>
        </Panels>
      </MainOnly>
    );
};

export default CalibrationEvaluation;
