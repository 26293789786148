import InputValidationText from './InputValidationText';
import InputValidityIcon from './InputValidityIcon';
import Text from './Text';

import './SelectStandard.css';

const SelectStandard = ({
  containerClasses = '',
  containerStyle = {},
  disabled = false,
  hasPermission = true,
  id,
  invalid,
  isPlaceholderSelected,
  isRequired,
  labelClasses = '',
  labelText = '',
  onChange,
  onClick,
  options,
  selectClasses = '',
  selectContainerClasses = '',
  selectStyle,
  showInvalid,
  title,
  value,
  margin,
  marginBottom,
  marginTop,
  optionKeyValue,
}) => {
  const style = { ...containerStyle };

  if (marginBottom) style.marginBottom = marginBottom;
  if (marginTop) style.marginTop = marginTop;
  if (margin) style.margin = margin;

  return (
    <div
      className={`select-standard-container ${containerClasses}`}
      style={style}
    >
      {labelText ? (
        <label
          htmlFor={id}
          className={`select-standard-label ${
            disabled && hasPermission ? 'select-standard-label-disabled' : ''
          } ${labelClasses}`}
        >
          {labelText}
        </label>
      ) : (
        <></>
      )}

      <div className={`select-standard-and-arrow ${selectContainerClasses}`}>
        {hasPermission ? (
          <>
            <InputValidityIcon
              isInvalid={invalid?.length}
              isRequired={isRequired}
              right='20'
              showInvalid={showInvalid}
            />

            <select
              id={id}
              className={`select-standard ${
                isPlaceholderSelected ? 'select-standard-placeholder' : ''
              } ${selectClasses} ${
                isRequired ? 'select-standard-required' : ''
              } ${invalid?.length > 0 && showInvalid ? 'input-invalid' : ''}`}
              value={value}
              disabled={disabled}
              onChange={(event) => onChange(event)}
              onClick={onClick}
              style={selectStyle}
              title={title}
            >
              {options.map((option) => {
                if (optionKeyValue) {
                  return (
                    <option
                      key={option[optionKeyValue.key]}
                      value={option[optionKeyValue.key]}
                    >
                      {option[optionKeyValue.value]}
                    </option>
                  );
                }
                if (typeof option === 'string')
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                else if (option.key || option.code || option.name)
                  return (
                    <option
                      disabled={option.disabled}
                      key={option.key ?? option.code}
                      value={option.code}
                    >
                      {option.name}
                    </option>
                  );
                else
                  return (
                    <option key={option[0]} value={option[2] ?? option[0]}>
                      {option[1]}
                    </option>
                  );
              })}
            </select>

            {invalid?.length > 0 && <InputValidationText text={invalid} />}

            <div className='select-standard-arrow'></div>
          </>
        ) : (
          <Text
            classes='select-no-permission-text'
            text={
              optionKeyValue
                ? options.find(
                    (option) => option[optionKeyValue.key] === value
                  )?.[optionKeyValue.value]
                : typeof options[0] === 'string'
                ? value
                : options[0].key || options[0].code || options[0].name
                ? options.find((option) => option.code === value).name
                : options.find((option) => option[0] === value)[1]
            }
          />
        )}
      </div>
    </div>
  );
};

export default SelectStandard;
