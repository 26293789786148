import {
  amendedReportRows,
  generateReportDataParams,
} from '../../../utils/reports';
import { handleApiError } from '../../../utils/error-handling';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { UserPortal } from '../../../contexts';

import React, { useContext, useEffect, useState } from 'react';

import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import GridItemOptions from './GridItemOptions';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import Icon from '../../Elements/Icon';
import InfoText from '../../Elements/InfoText';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import reportsApi from '../../../api/reports';
import ReportsChart from '../Reports/ReportsChart';
import ReportsTable from '../Reports/ReportsTable';

import './GridItem.css';

const GridItem = ({
  currentlyOpenOptions,
  demo,
  gridItemsData,
  isLocked,
  item,
  removeGridItem,
  reports,
  setCurrentlyOpenOptions,
  setPreventDragResize,
  handleChangeGridItemsData,
}) => {
  const alert = useAlert();
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { getAccessTokenSilently } = useAuth0();

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [header, setHeader] = useState(gridItemsData?.[item.i]?.header || '');
  const [selectedReport, setSelectedReport] = useState(
    reports.find(
      (report) => report.id === gridItemsData?.[item.i]?.selectedReport.id
    ) || { id: '' }
  );
  const [display, setDisplay] = useState(gridItemsData?.[item.i]?.display);
  const [reportData, setReportData] = useState();
  const [loadingReportData, setLoadingReportData] = useState(false);

  const getReportData = async () => {
    if (!selectedReport?.report_data) return;

    const { aggregate, columnOrder, grouping, query, sort } =
      selectedReport.report_data;

    const { columnsForReports, params } = generateReportDataParams({
      aggregate,
      columnOrder,
      grouping,
      query,
      sort,
    });

    if (!params.length) return;

    try {
      setLoadingReportData(true);

      const { reportRows } = await reportsApi.generateReport({
        params,
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setReportData({
        reportRows: amendedReportRows({ columnsForReports, reportRows }),
        chartDataRows: reportRows,
        columnsForReports: columnsForReports,
        charts: selectedReport?.report_data?.charts || {},
        customColumnHeaders: selectedReport.report_data.customColumnHeaders,
      });
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setLoadingReportData(false);
    }
  };

  useEffect(() => {
    getReportData();

    return () => {
      setReportData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  useEffect(() => {
    const currentData = gridItemsData?.[item.i];

    if (
      currentData?.header !== header ||
      currentData?.display !== display ||
      currentData?.selectedReport?.id !== selectedReport?.id
    ) {
      handleChangeGridItemsData(header, selectedReport, display, item.i);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header, selectedReport, display]);

  useEffect(() => {
    if (currentlyOpenOptions === item.i) {
      if (isOptionsOpen) {
        setPreventDragResize(true);
      } else {
        setPreventDragResize(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsOpen]);

  return (
    <div className='dashboard-grid-item-inner'>
      {gridItemsData?.[item.i]?.selectedReport?.id ? (
        <Link
          className='go-to-dashboard-report'
          title='Go to report'
          to={`/${demo ? 'demo' : portalId}/reports/${
            gridItemsData[item.i].selectedReport.id
          }`}
        >
          <ButtonIconOnly
            iconName='arrow-up-right-from-square'
            onClick={() => {}}
          />
        </Link>
      ) : (
        <></>
      )}

      {!isLocked && (
        <>
          <div onClick={() => removeGridItem(item)}>
            <Icon
              className='nav-favourite-cross dashboard-grid-cross'
              iconName='circle-cross'
            />
          </div>

          <div
            onClick={() => {
              setCurrentlyOpenOptions(item.i);
              setIsOptionsOpen((curr) => !curr);
            }}
          >
            <Icon
              className='nav-favourite-cross dashboard-grid-options'
              iconName='ellipsis'
            />
          </div>
        </>
      )}

      {isOptionsOpen && (
        <GridItemOptions
          chartObjects={
            selectedReport.report_data?.charts
              ? Object.entries(selectedReport.report_data.charts)
              : null
          }
          display={display}
          header={header}
          reports={reports}
          selectedReportId={selectedReport.id}
          setDisplay={setDisplay}
          setHeader={setHeader}
          setIsOptionsOpen={setIsOptionsOpen}
          setSelectedReport={setSelectedReport}
        />
      )}

      {header ? (
        <HeaderSecondary
          classes='dashboard-grid-item-header'
          marginBottom={display === 'table' ? '0' : '10px'}
          overflowX='auto'
          text={header}
          textAlign='center'
        />
      ) : (
        <></>
      )}

      {loadingReportData && <LoadingSpinnerNew />}

      {selectedReport.id && reportData && reportData?.reportRows?.length ? (
        display === 'table' ? (
          <ReportsTable reportData={reportData} />
        ) : display?.slice(0, 6) === 'chart-' ? (
          <ReportsChart
            reportData={{
              ...reportData,
              charts: {
                [display.slice(6)]: reportData.charts[display.slice(6)],
              },
            }}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {selectedReport.id && reportData && !reportData?.reportRows?.length ? (
        <InfoText style={{ margin: 'auto' }} text='No data' />
      ) : (
        <></>
      )}
    </div>
  );
};

export default GridItem;
