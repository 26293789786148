import InputValidationText from './InputValidationText';
import InputValidityIcon from './InputValidityIcon';

import './TextareaStandard.css';

const TextareaStandard = ({
  id,
  invalid,
  isRequired,
  labelText = '',
  name,
  onChange,
  placeholder,
  elementRef,
  classes = '',
  flex,
  flexDirection,
  showInvalid,
  style,
  textareaClasses = '',
  value,
  rows = 3,
  wrap,
}) => {
  style = {};

  if (flex) style.flex = flex;
  if (flexDirection) style.flexDirection = flexDirection;
  if (wrap) style.flexWrap = wrap;

  return (
    <div className={`textarea-standard ${classes}`} style={style}>
      {labelText ? <label htmlFor={id}>{labelText}</label> : <></>}

      <div className='textarea-standard-container'>
        <InputValidityIcon
          isInvalid={invalid?.length}
          isRequired={isRequired}
          showInvalid={showInvalid}
        />
        <textarea
          id={id}
          ref={elementRef}
          className={`${isRequired ? 'textarea-standard-required' : ''} ${
            invalid?.length > 0 && showInvalid ? 'input-invalid' : ''
          } ${textareaClasses}`}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          rows={rows}
          style={style}
          type='text'
          value={value}
        />

        {invalid?.length > 0 && <InputValidationText text={invalid} />}
      </div>
    </div>
  );
};

export default TextareaStandard;
