import {
  isBannedChars,
  validatePresenceRequired,
} from '../../../utils/helpers';
import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';

import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import Divider from '../../Elements/Divider';
import FlexContainer from '../../Elements/FlexContainer';
import InputStandard from '../../Elements/InputStandard';
import TextareaStandard from '../../Elements/TextareaStandard';
import Toggle from '../../Elements/Toggle';
import updateScoringCategories from '../../../utils/createScorecardUtils/updateScoringCategories';
import updateScoringCommentsBoxes from '../../../utils/createScorecardUtils/updateScoringCommentsBoxes';
import updateScoringSections from '../../../utils/createScorecardUtils/updateScoringSections';

import './CreateScoringSectionForm.css';

const CreateScoringSectionForm = ({
  scoringSections,
  setScoringSections,
  sectionName,
  setSectionName,
  categoryName,
  setCategoryName,
  sectionDescription,
  setSectionDescription,
  weight,
  setWeight,
  ra,
  setRa,
  csm,
  setCsm,
  isRequired,
  setIsRequired,
  currentlyEditingCat,
  setCurrentlyEditingCat,
  currentlyEditingSec,
  setCurrentlyEditingSec,
  setSectionCount,
  setCategoryCount,
  setShouldScrollToBtm,
  handleSetHasChanges,
}) => {
  const alert = useAlert();

  const scoringCatNameRef = useRef(null);
  const scoringSecNameRef = useRef(null);

  useEffect(() => {
    if (currentlyEditingCat) scoringCatNameRef.current.focus();
  }, [currentlyEditingCat]);
  useEffect(() => {
    if (currentlyEditingSec) scoringSecNameRef.current.focus();
  }, [currentlyEditingSec]);

  const [categoryNameInvalid, setCategoryNameInvalid] = useState();
  const [showInvalidCategory, setShowInvalidCategory] = useState();
  const [sectionNameInvalid, setSectionNameInvalid] = useState();
  const [showInvalidSection, setShowInvalidSection] = useState();

  useEffect(() => {
    const val = validatePresenceRequired(categoryName, "'Category Title'");

    if (val) setCategoryNameInvalid([val]);
    else setCategoryNameInvalid(null);
  }, [categoryName]);

  useEffect(() => {
    const val = validatePresenceRequired(sectionName, "'Section Title'");

    if (val) setSectionNameInvalid([val]);
    else setSectionNameInvalid(null);
  }, [sectionName]);

  const handleCategoryNameChange = (event) => {
    const newVal = event.target.value;

    const isBannedCharsText = isBannedChars(newVal, 'Category Title');

    if (isBannedCharsText)
      return alert.error(isBannedCharsText, { timeout: 10000 });

    setCategoryName(newVal);
  };
  const handleSectionNameChange = (event) => {
    const newVal = event.target.value;

    const isBannedCharsText = isBannedChars(newVal, 'Section Title');

    if (isBannedCharsText)
      return alert.error(isBannedCharsText, { timeout: 10000 });

    setSectionName(newVal);
  };

  const handleSectionDescriptionChange = (event) => {
    setSectionDescription(() => {
      return event.target.value;
    });
  };

  const handleSectionWeightChange = (event) => {
    const newWeight = event.target.value;
    if (/^0[0-9]/.test(newWeight) || newWeight === '') setWeight('');
    else if (/^[0-9][\d]*?/.test(newWeight)) setWeight(parseFloat(newWeight));
  };
  const handleSectionRaChange = (event) => {
    const newRa = event.target.value;
    if (/^0+/.test(newRa)) setRa('');
    else if (/^[1-9][0-9]?$|^100$/.test(newRa) || newRa === '') setRa(newRa);
  };
  const handleSectionCsmChange = (event) => {
    const newCsm = event.target.value;
    if (/^0.+/.test(newCsm)) setCsm('');
    else if (newCsm === '100') setCsm('');
    else if (/^[0-9][0-9]?$/.test(newCsm) || newCsm === '') setCsm(newCsm);
  };

  const handleChangeRequired = () => setIsRequired((curr) => !curr);

  const handleSaveCategory = () => {
    if (categoryNameInvalid) return setShowInvalidCategory(true);
    else setShowInvalidCategory(false);

    handleSetHasChanges?.();

    const addedCategory = categoryName.trim();

    const { overwrittenRef, overwrittenCat } = updateScoringCategories({
      add: {
        type: 'scoring-category',
        scoringSections: scoringSections,
        setScoringSections: setScoringSections,
        categoryName: addedCategory,
        setCategoryName: setCategoryName,
        setCategoryCount: setCategoryCount,
        currentlyEditingCat: currentlyEditingCat,
        setCurrentlyEditingCat: setCurrentlyEditingCat,
        setShouldScrollToBtm: setShouldScrollToBtm,
      },
    });

    if (overwrittenRef)
      alert.success(`Overwritten: ${overwrittenCat}`, { timeout: 5000 });
    else alert.success(`Added: ${addedCategory}`, { timeout: 5000 });
  };

  const handleSaveSection = () => {
    if (sectionNameInvalid) return setShowInvalidSection(true);
    else setShowInvalidSection(false);

    handleSetHasChanges?.();

    const addedSection = sectionName.trim();
    const addedDescription = sectionDescription.trim();
    const { overwrittenRef, overwrittenSec } = updateScoringSections({
      add: {
        type: 'scoring-section',
        scoringSections: scoringSections,
        setScoringSections: setScoringSections,
        sectionName: addedSection,
        setSectionName: setSectionName,
        sectionDescription: addedDescription,
        setSectionDescription: setSectionDescription,
        weight: weight,
        setWeight: setWeight,
        ra: ra,
        setRa: setRa,
        csm: csm,
        setCsm: setCsm,
        isRequired: isRequired,
        setIsRequired: setIsRequired,
        setSectionCount: setSectionCount,
        currentlyEditingSec: currentlyEditingSec,
        setCurrentlyEditingSec: setCurrentlyEditingSec,
        setShouldScrollToBtm: setShouldScrollToBtm,
      },
    });
    if (overwrittenRef)
      alert.success(`Overwritten: ${overwrittenSec}`, { timeout: 5000 });
    else alert.success(`Added: ${addedSection}`, { timeout: 5000 });
  };

  return (
    <div className='add-scoring-section-form-container'>
      <form
        className='add-category-form'
        onSubmit={(event) => {
          event.preventDefault();

          handleSaveCategory();
        }}
      >
        <InputStandard
          inputClasses={currentlyEditingCat ? 'edit-section-background' : ''}
          id='scoring-category-title-input'
          elementRef={scoringCatNameRef}
          labelText='Category Title'
          name='section-name'
          onChange={handleCategoryNameChange}
          placeholder='< Category Title >'
          invalid={categoryNameInvalid}
          isRequired
          showInvalid={showInvalidCategory}
          value={categoryName}
          margin='10px'
          wrap='wrap'
        />

        <FlexContainer flexWrap='wrap'>
          <ButtonStandardNoFill
            alignSelf='center'
            iconName={currentlyEditingCat ? 'circle-check' : 'circle-plus'}
            text={currentlyEditingCat ? 'Confirm Edit' : 'Add category'}
            type='submit'
            typeStyle='type-4'
            margin='10px'
          />

          {currentlyEditingCat ? (
            <ButtonIconOnly
              iconName='xmark'
              marginBottom='5px'
              marginRight='10px'
              onClick={() => setCurrentlyEditingCat('')}
              title='Cancel edit category'
            />
          ) : (
            <></>
          )}
        </FlexContainer>
      </form>

      <Divider />

      <form
        className='add-scoring-section-form'
        onSubmit={(event) => {
          event.preventDefault();

          handleSaveSection();
        }}
      >
        <div className='add-scoring-section-form-controls'>
          <InputStandard
            inputClasses={currentlyEditingSec ? 'edit-section-background' : ''}
            id={'scoring-section-title-input'}
            elementRef={scoringSecNameRef}
            labelText='Section Title'
            name='section-name'
            onChange={handleSectionNameChange}
            placeholder='< Section Title >'
            value={sectionName}
            invalid={sectionNameInvalid}
            isRequired
            showInvalid={showInvalidSection}
          />

          <InputStandard
            classes='behaviour-inputs'
            inputClasses={currentlyEditingSec ? 'edit-section-background' : ''}
            id='add-behaviour-weight'
            labelText='Weight'
            name='weight-amount'
            onChange={handleSectionWeightChange}
            placeholder='0'
            step='any'
            value={weight}
            type='number'
          />
          <InputStandard
            classes='behaviour-inputs'
            inputClasses={currentlyEditingSec ? 'edit-section-background' : ''}
            id='add-behaviour-ra'
            labelText='RA'
            name='ra-amount'
            onChange={handleSectionRaChange}
            placeholder='0'
            value={ra}
            step='any'
            type='number'
          />
          <InputStandard
            classes='behaviour-inputs'
            inputClasses={currentlyEditingSec ? 'edit-section-background' : ''}
            id='add-behaviour-csm'
            labelText='CSM'
            name='csm-amount'
            onChange={handleSectionCsmChange}
            placeholder='100'
            value={csm}
            step='any'
            type='number'
          />

          <Toggle
            id='scoring-section-required-input'
            labelText='Required?'
            onChange={handleChangeRequired}
            sliderClasses={currentlyEditingSec ? 'edit-section-background' : ''}
            toggled={isRequired}
          />
        </div>

        <TextareaStandard
          id={'scoring-section-description-input'}
          labelText='Description'
          name='section-description'
          onChange={handleSectionDescriptionChange}
          placeholder='< Description >'
          textareaClasses={`scoring-section-textarea ${
            currentlyEditingSec ? 'edit-section-background' : ''
          }`}
          value={sectionDescription}
          rows={2}
          wrap='wrap'
          flexDirection='column'
          flex='1'
        />

        <FlexContainer flexWrap='wrap' margin='10px'>
          <ButtonStandardNoFill
            alignSelf='center'
            iconName={currentlyEditingSec ? 'circle-check' : 'circle-plus'}
            margin='10px'
            text={currentlyEditingSec ? 'Confirm Edit' : 'Add section'}
            type='submit'
            typeStyle='type-4'
          />

          {currentlyEditingSec ? (
            <ButtonIconOnly
              iconName='xmark'
              marginRight='10px'
              onClick={() => setCurrentlyEditingSec('')}
              title='Cancel edit section'
            />
          ) : (
            <></>
          )}
        </FlexContainer>

        <Divider />

        <ButtonStandardNoFill
          alignSelf='center'
          classes='scoring-section-add-comments-button'
          iconName='circle-plus'
          margin='20px 10px 10px 10px'
          text='Add comments box'
          type='button'
          typeStyle='type-4'
          onClick={() => {
            updateScoringCommentsBoxes({
              add: {
                type: 'comments-box',
                scoringSections,
                setScoringSections,
                setShouldScrollToBtm,
              },
            });

            handleSetHasChanges?.();

            alert.success('Added comments box');
          }}
        />
      </form>
    </div>
  );
};

export default CreateScoringSectionForm;
