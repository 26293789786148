import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../utils/createScorecardUtils/createScorecardUtils';
import { useEffect, useRef, useState } from 'react';

import ButtonIconOnly from '../Elements/ButtonIconOnly';
import Divider from '../Elements/Divider';
import FlexContainer from '../Elements/FlexContainer';
import guideContents from '../../utils/guide-contents';
import guideMainInfo from '../../utils/guide-main-info';
import HeaderPrimary from '../Elements/HeaderPrimary';
import HeaderSecondary from '../Elements/HeaderSecondary';

import Text from '../Elements/Text';

import './Contact.css';
import './Guide.css';

const Guide = () => {
  const elementRef = useRef(null);

  const [isArrowVisible, setIsArrowVisible] = useState(false);

  useEffect(() => {
    const checkScrollPosition = () => {
      if (elementRef.current) {
        if (elementRef.current.scrollTop > 500) setIsArrowVisible(true);
        else setIsArrowVisible(false);
      }
    };

    const element = elementRef.current;

    if (element) {
      element.addEventListener('scroll', checkScrollPosition);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, []);

  return (
    <div className='contact-screen home-screen' ref={elementRef}>
      {isArrowVisible && (
        <ButtonIconOnly
          classes='scroll-to-top'
          iconName='arrow-up'
          onClick={() => scrollToTop(elementRef)}
          title='Scroll to top of page'
        />
      )}

      <HeaderPrimary
        margin='30px auto 30px auto'
        style={{ textAlign: 'center' }}
        text='QA Portal User Guide'
      />

      <span style={{ marginBottom: '30px', textAlign: 'center' }}>
        <Text text="If you have any questions that aren't covered in the guide, please contact us " />

        <Link to='/contact'>here</Link>

        <Text text='.' />
      </span>

      <FlexContainer
        classes='user-guide'
        flexDirection='column'
        alignItems='flex-start'
        marginBottom='140px'
      >
        <HeaderSecondary
          marginBottom='30px'
          text='Contents'
          alignSelf='center'
          textDecoration='underline'
        />
        <ol className='user-guide-contents-list'>
          {guideContents.map((contentsListItem) => {
            const contentsHeader = contentsListItem[0];
            const contentsSubHeaders = contentsListItem[1];

            return (
              <li key={contentsHeader}>
                <HashLink
                  smooth
                  to={`/guide/#${contentsHeader
                    .replaceAll(' ', '')
                    .toLowerCase()}`}
                >
                  <h2 className='user-guide-contents-list-item-header'>
                    {contentsHeader}
                  </h2>
                </HashLink>

                {contentsSubHeaders ? (
                  <ol className='user-guide-contents-sub-list'>
                    {contentsSubHeaders.map((contentsSubHeader) => (
                      <li
                        key={contentsSubHeader}
                        className='user-guide-contents-sub-list-item'
                      >
                        <HashLink
                          smooth
                          to={`/guide/#${contentsSubHeader
                            .replaceAll(' ', '')
                            .toLowerCase()}`}
                        >
                          <Text text={contentsSubHeader} />
                        </HashLink>
                      </li>
                    ))}
                  </ol>
                ) : (
                  <></>
                )}
              </li>
            );
          })}
        </ol>

        <Divider />

        <ol className='user-guide-main-list'>
          {guideContents.map((contentsListItem, index) => {
            const contentsHeader = contentsListItem[0];
            const contentsSubHeaders = contentsListItem[1];

            return (
              <li key={contentsHeader}>
                {index !== 0 && <Divider />}

                <h2
                  className='user-guide-contents-list-item-header'
                  id={contentsHeader.replaceAll(' ', '').toLowerCase()}
                >
                  {contentsHeader}
                </h2>

                {guideMainInfo[contentsHeader]?.map((info, index) => {
                  if (info.type === 'image') {
                    return (
                      <img
                        key={index}
                        alt=''
                        className='user-guide-image'
                        src={info.content}
                      />
                    );
                  }

                  if (info.type === 'text') {
                    return (
                      <Text
                        key={index}
                        classes='user-guide-main-text'
                        text={info.content}
                      />
                    );
                  }

                  return <></>;
                })}

                {contentsSubHeaders?.length ? (
                  <ol className='user-guide-contents-sub-list'>
                    {contentsSubHeaders.map((contentsSubHeader) => (
                      <li key={contentsSubHeader}>
                        <Text
                          id={contentsSubHeader
                            .replaceAll(' ', '')
                            .toLowerCase()}
                          classes='user-guide-contents-list-item-sub-header'
                          text={contentsSubHeader}
                        />

                        {guideMainInfo[contentsSubHeader]?.length ? (
                          guideMainInfo[contentsSubHeader].map(
                            (info, index) => {
                              if (info.type === 'image') {
                                return (
                                  <img
                                    key={index}
                                    alt=''
                                    className='user-guide-image'
                                    src={info.content}
                                  />
                                );
                              }

                              if (info.type === 'text') {
                                return (
                                  <Text
                                    key={index}
                                    classes='user-guide-text'
                                    text={info.content}
                                  />
                                );
                              }

                              return <></>;
                            }
                          )
                        ) : (
                          <></>
                        )}
                      </li>
                    ))}
                  </ol>
                ) : (
                  <></>
                )}
              </li>
            );
          })}
        </ol>
      </FlexContainer>
    </div>
  );
};

export default Guide;
