const logsTableNameTranslations = (demo, portalId) => {
  return [
    [`qap${demo ? '1' : portalId}.agents`, 'Agents'],
    [
      `qap${demo ? '1' : portalId}.calibration_participants`,
      'Calibration Participants',
    ],
    [`qap${demo ? '1' : portalId}.calibration_results`, 'Calibration Results'],
    [`qap${demo ? '1' : portalId}.calibrations`, 'Calibrations'],
    [`qap${demo ? '1' : portalId}.data_lists`, 'Data Lists'],
    [
      `qap${demo ? '1' : portalId}.sc_results_admin`,
      'Evaluation Admin Sections',
    ],
    [
      `qap${demo ? '1' : portalId}.sc_results_cat`,
      'Evaluation Category Sections',
    ],
    [
      `qap${demo ? '1' : portalId}.sc_results_comments`,
      'Evaluation Comments Sections',
    ],
    [
      `qap${demo ? '1' : portalId}.sc_results_scoring`,
      'Evaluation Scoring Sections',
    ],
    [`qap${demo ? '1' : portalId}.evaluations`, 'Evaluations'],
    [`qap${demo ? '1' : portalId}.grid_options`, 'Grid Options'],
    [`qap${demo ? '1' : portalId}.logs`, 'Logs'],
    [`qap${demo ? '1' : portalId}.org_levels`, 'Org Structure'],
    [`qap${demo ? '1' : portalId}.permissions`, 'Permissions'],
    ['portals', 'Portal'],
    [`qap${demo ? '1' : portalId}.reports`, 'Reports'],
    [`qap${demo ? '1' : portalId}.sc_secs_admin`, 'Scorecard Admin Sections'],
    [`qap${demo ? '1' : portalId}.sc_secs_cat`, 'Scorecard Category Sections'],
    [
      `qap${demo ? '1' : portalId}.sc_secs_comments`,
      'Scorecard Comments Sections',
    ],
    [
      `qap${demo ? '1' : portalId}.sc_secs_scoring`,
      'Scorecard Scoring Sections',
    ],
    [`qap${demo ? '1' : portalId}.scorecards`, 'Scorecards'],
    ['public.users', 'Users'],
  ];
};

export default logsTableNameTranslations;
