import Icon from './Icon';

import './InputRequiredIcon.css';

const InputRequiredIcon = ({ right = 10, top }) => {
  const style = { right: `${right}px` };

  if (top) style.top = `${top}px`;

  return (
    <div className='input-required-icon' style={style} title='Required'>
      <Icon iconName='asterisk' />
    </div>
  );
};

export default InputRequiredIcon;
