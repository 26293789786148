import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { UserPortal } from '../../contexts';

import accountIcon from '../../images/account_icon.png';
import accountIcon2 from '../../images/account_icon_2.png';
import AuthenticationButton from '../Authentication/AuthenticationButton';
import FlexContainer from '../Elements/FlexContainer';
import HeaderPrimary from '../Elements/HeaderPrimary';
import HeaderSecondary from '../Elements/HeaderSecondary';
import PricingTier from './PricingTier';
import Text from '../Elements/Text';

import './Pricing.css';

const Pricing = () => {
  const { isAuthenticated } = useAuth0();

  const { userFromDb } = useContext(UserPortal);

  return (
    <div className='contact-screen home-screen'>
      <HeaderPrimary margin='30px auto 30px auto' text='Pricing' />

      <Text
        marginBottom='20px'
        text='The cost of your portal will depend on how many users have been added, and which tier they are on.'
        textAlign='center'
      />

      <HeaderSecondary
        marginBottom='20px'
        text='Main features of each tier:'
        textAlign='center'
      />

      <FlexContainer alignItems='flex-start' classes='pricing-tiers'>
        <PricingTier
          tier='QA'
          features={[
            'Create evaluations',
            'Create scorecards and templates',
            'Can be assigned as a portal administrator',
            'Host and edit calibrations',
            'Amend org structure',
            'Edit portal',
            'Change data lists',
            'All of the manager and agent features',
          ]}
        />

        <PricingTier
          tier='Manager'
          features={[
            'Create reports for teams and/or individuals',
            'Change agent data',
            'Change user data',
            'All of the agent features',
          ]}
        />

        <PricingTier
          tier='Agent'
          features={[
            'View personal stats and evaluations',
            'View stats / evaluations of other agents (where permission has been granted)',
            'Participate in calibrations',
            'View scorecards',
            'View reports',
          ]}
        />
      </FlexContainer>

      <Text
        marginBottom='20px'
        text='Admin roles are charged on the QA tier.'
        textAlign='center'
      />

      <span style={{ marginBottom: '20px', textAlign: 'center' }}>
        <Text text='User permissions can be adjusted.' />

        <Link to='/demo/users/11'> Click here to view a demo user screen</Link>

        <Text text=" and amend the 'Role' option to see the different permission availabilities." />
      </span>

      <Text
        text='Portals are free for the first 30 days regardless of how many users you have, or their roles.'
        textAlign='center'
      />

      <HeaderPrimary margin='30px auto 30px auto' text='Plus!' />

      <HeaderSecondary
        marginBottom='30px'
        text='The more users you have, the greater your discount'
        textAlign='center'
      />

      <Text
        marginBottom='10px'
        text='Sign up now and get 0.1% off the cost of your portal for every user you register, up to 20%. (Applicable for all users you add now and in the future.)'
        textAlign='center'
      />

      <Text marginBottom='10px' text='For example:' textAlign='center' />

      <ul style={{ alignSelf: 'center', marginBottom: '30px' }}>
        {['50', '200'].map((noOfUsers) => (
          <li key={noOfUsers} style={{ listStyle: 'inside' }}>
            <span>
              <Text text={`${noOfUsers} users - `} />

              <Text
                color='#0c4c14'
                fontWeight='600'
                text={`${noOfUsers / 10}% discount`}
              />
            </span>
          </li>
        ))}
      </ul>

      <Text
        marginBottom='20px'
        text={`${
          isAuthenticated
            ? 'Create a portal now'
            : 'Sign up now and create a portal'
        } with no obligation and no requirement for payment information, and find out if it meets your needs.`}
        textAlign='center'
      />

      {isAuthenticated ? (
        <FlexContainer marginBottom='30px'>
          <span style={{ textAlign: 'center' }}>
            <Text text='Click your account icon (' />

            <span>
              <img
                style={{ height: '12px' }}
                src={userFromDb.portal_id ? accountIcon2 : accountIcon}
                alt='account'
              />
            </span>

            <Text text=') at the top of the screen and select' />

            <Text
              color='#505050'
              fontWeight='600'
              text=" 'Create new portal'."
            />
          </span>
        </FlexContainer>
      ) : (
        <FlexContainer marginBottom='30px'>
          <AuthenticationButton classes='try-screen-info-login-button' />
        </FlexContainer>
      )}

      <span style={{ marginBottom: '20px', textAlign: 'center' }}>
        <Text
          fontWeight='600'
          text='Find out more about QA Portal'
          textAlign='center'
        />

        <Link to='/about'> here</Link>
      </span>

      <span style={{ marginBottom: '180px', textAlign: 'center' }}>
        <Text fontWeight='600' text='Contact us' textAlign='center' />

        <Link to='/contact'> here</Link>
      </span>
    </div>
  );
};

export default Pricing;
