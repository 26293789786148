import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useContext } from 'react';
import { UserPortal } from '../../../../contexts';

import apiDataService from '../../../../api/billing';
import Text from '../../../Elements/Text';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ChangeToAutomatic = ({ customerId, subscriptionId }) => {
  const { getAccessTokenSilently } = useAuth0();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const fetchClientSecret = useCallback(async () => {
    const response = await apiDataService.createCheckoutSession({
      customerId,
      portalId,
      subscriptionId,
      token: await getAccessTokenSilently(),
      userId,
    });

    return response.clientSecret;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = { fetchClientSecret };

  return (
    <>
      <Text
        marginBottom='10px'
        text='Payments will be taken automatically when your invoices are produced.'
        textAlign='center'
      />

      <Text
        marginBottom='10px'
        text="You can check the estimated amount of your next invoice at any time on this screen under 'Next Invoice'."
        textAlign='center'
      />

      <div id='checkout'>
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </>
  );
};

export default ChangeToAutomatic;
