import './_CreateAdminSections.css';

import { useState } from 'react';

import CreateAdminSectionForm from './CreateAdminSectionForm';
import AdminSectionsContainer from './AdminSectionsContainer';

const CreateAdminSections = ({
  hasChangePermission,
  status,
  adminSections,
  ddLists,
  edgeMargins,
  setAdminSections,
  currentlyEditingAdmin,
  setCurrentlyEditingAdmin,
  handleSetHasChanges,
}) => {
  const [sectionNameAdmin, setSectionNameAdmin] = useState('');
  const [control, setControl] = useState('');
  const [dropdownList, setDropdownList] = useState('');
  const [isNewListChecked, setIsNewListChecked] = useState(false);
  const [isRequiredAdmin, setIsRequiredAdmin] = useState(false);
  const [shouldScrollToBtm, setShouldScrollToBtm] = useState(false);

  return (
    <div className='create-admin-sections-area'>
      <div className='create-scorecard-admin-form-and-sections'>
        {hasChangePermission ? (
          <CreateAdminSectionForm
            status={status}
            adminSections={adminSections}
            ddLists={ddLists}
            edgeMargins={edgeMargins}
            setAdminSections={setAdminSections}
            sectionName={sectionNameAdmin}
            setSectionName={setSectionNameAdmin}
            control={control}
            setControl={setControl}
            dropdownList={dropdownList}
            setDropdownList={setDropdownList}
            isNewListChecked={isNewListChecked}
            setIsNewListChecked={setIsNewListChecked}
            isRequired={isRequiredAdmin}
            setIsRequired={setIsRequiredAdmin}
            currentlyEditing={currentlyEditingAdmin}
            setCurrentlyEditing={setCurrentlyEditingAdmin}
            setShouldScrollToBtm={setShouldScrollToBtm}
            handleSetHasChanges={handleSetHasChanges}
          />
        ) : (
          <></>
        )}

        {adminSections.length > 0 && (
          <AdminSectionsContainer
            cards={adminSections}
            edgeMargins={edgeMargins}
            hasChangePermission={hasChangePermission}
            setCards={setAdminSections}
            setSectionName={setSectionNameAdmin}
            setControl={setControl}
            setDropdownList={setDropdownList}
            setIsNewListChecked={setIsNewListChecked}
            setIsRequired={setIsRequiredAdmin}
            currentlyEditing={currentlyEditingAdmin}
            setCurrentlyEditing={setCurrentlyEditingAdmin}
            shouldScrollToBtm={shouldScrollToBtm}
            setShouldScrollToBtm={setShouldScrollToBtm}
            handleSetHasChanges={handleSetHasChanges}
          />
        )}
      </div>
    </div>
  );
};

export default CreateAdminSections;
