import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { handleApiError } from '../../../utils/error-handling';
import {
  isBannedChars,
  shouldNavigateOnNotFound,
} from '../../../utils/helpers';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AgentRankedSections from './AgentRankedSections';
import AgentRecentEvaluations from './AgentRecentEvaluations';
import AgentScores from './AgentScores';
import apiDataServiceAgents from '../../../api/agents';
import apiDataServiceOrgLevels from '../../../api/org-levels';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import InputStandard from '../../Elements/InputStandard';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import Panels from '../../Elements/Panels';
import PanelsControlBar from '../../Elements/PanelsControlBar';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import PanelsSections from '../../Elements/PanelsSections';
import portalConfirmAlert from '../../../utils/portalConfirmAlert';

import './AgentsShowContent.css';

const AgentsShowContent = ({ demo }) => {
  const { id } = useParams();

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;
  const hasChangePermission =
    demo || userFromDb.permissions?.includes('agents.change');

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const alert = useAlert();

  const saveButtonRef = useRef(null);

  const [orgLevels, setOrgLevels] = useState();
  const [agent, setAgent] = useState();
  const [recentEvaluations, setRecentEvaluations] = useState([]);
  const [qualityScores, setQualityScores] = useState({});
  const [topRanked, setTopRanked] = useState([]);
  const [bottomRanked, setBottomRanked] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState();
  const [changes, setChanges] = useState();

  const canViewAllEvals =
    demo ||
    userFromDb.linked_agents?.includes(parseInt(agent?.alt_id)) ||
    userFromDb.permissions?.includes('evaluations.view_all');

  const getAgent = async () => {
    setIsLoading(true);

    try {
      const {
        agent,
        bottomRanked,
        qualityScores,
        recentEvaluations,
        topRanked,
      } = await apiDataServiceAgents.getAgentById({
        token: demo ? undefined : await getAccessTokenSilently(),
        userId: demo ? 1 : userId,
        portalId: demo ? 'demo' : portalId,
        agentId: id,
      });

      setAgent(agent);

      if (qualityScores) {
        qualityScores.sixMonth.push(...qualityScores.month);
        qualityScores.year.push(...qualityScores.sixMonth);

        setQualityScores(qualityScores);
      }

      if (recentEvaluations) setRecentEvaluations(recentEvaluations);

      if (topRanked) setTopRanked(topRanked);

      if (bottomRanked) setBottomRanked(bottomRanked);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      alert.error(handleApiError(error), { timeout: 10000 });

      if (shouldNavigateOnNotFound(error)) {
        navigate(`/${demo ? 'demo' : portalId}/agents`, {
          state: { isNavigating: true },
        });
      }
    }
  };

  const getOrgLevels = async () => {
    setIsLoading(true);

    try {
      const { orgLevels } = await apiDataServiceOrgLevels.getAll({
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setOrgLevels(orgLevels);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAgent = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    const confirmed = await portalConfirmAlert({
      message: `Are you sure you want to delete '${
        agent[`level${orgLevels[0].level}`]
      }'?`,
    });

    if (!confirmed) return;

    setIsLoading(true);

    try {
      await apiDataServiceAgents.gridDeleteMultiple({
        selectedRecordIds: [id],
        portalId: demo ? 'demo' : portalId,
        reqBody: { userId: demo ? 1 : userId },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      alert.success('Agent Deleted');

      setIsLoading(false);

      navigate(`/${demo ? 'demo' : portalId}/agents`, {
        state: { isNavigating: true },
      });
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      setIsLoading(false);
    }
  };

  const updateAgent = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (isSaving || saveButtonRef?.current?.disabled) return;
    if (!saveButtonRef?.current?.disabled && saveButtonRef?.current)
      saveButtonRef.current.disabled = true;

    setIsSaving(true);

    try {
      const reqBody = {
        updates: [{ ...changes, id: agent.id }],
        userId: demo ? 1 : userId,
      };

      await apiDataServiceAgents.update({
        portalId: demo ? 'demo' : portalId,
        reqBody,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;

      alert.success('Changes saved');
    } catch (error) {
      console.error(error);

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;
    } finally {
      setChanges(null);
    }
  };

  const handleChangeAgent = (level, newData) => {
    setAgent((curr) => ({ ...curr, [`level${level}`]: newData }));
    setChanges((curr) => ({ ...curr, [`level${level}`]: newData }));
  };

  useEffect(() => {
    if (!demo && !userFromDb.permissions?.includes('agents.view')) {
      navigate(`/${demo ? 'demo' : portalId}/dashboard`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      getOrgLevels();
      getAgent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setBreadcrumbs([
      ['Dashboard', 'dashboard', '', `/${demo ? 'demo' : portalId}/dashboard`],
      ['Agents', 'headset', '', `/${demo ? 'demo' : portalId}/agents`],
      [agent?.[`level${orgLevels?.[0]?.level}`] || 'Agent', 'magnifying-glass'],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent, orgLevels]);

  if (!agent || !orgLevels || isLoading) return <LoadingSpinner />;
  else
    return (
      <Panels>
        {hasChangePermission ? (
          <PanelsControlBar>
            <ButtonStandardNoFill
              margin='5px'
              disabled={isSaving}
              iconName='trash'
              onClick={deleteAgent}
              text='Delete Agent'
              typeStyle='type-5'
            />
          </PanelsControlBar>
        ) : (
          <></>
        )}

        <PanelsHeaderPrimary
          iconName='headset'
          text={agent?.[`level${orgLevels?.[0]?.level}`] || ''}
        />

        <PanelsSections>
          <PanelsSection
            classes={`agent-data-panel ${
              !qualityScores.year?.length
                ? 'agent-data-panel-no-evals-in-year'
                : ''
            }`}
            typeStyle='type-2'
          >
            {orgLevels.map((level) => (
              <InputStandard
                disabled={isSaving}
                direction='vertical'
                hasPermission={hasChangePermission}
                id={`agent-data-${level.level}`}
                key={level.level}
                labelText={level.name}
                marginBottom='5px'
                onChange={(event) => {
                  const newValue = event.target.value;

                  const isBannedCharsText = isBannedChars(newValue);

                  if (isBannedCharsText) {
                    return alert.error(isBannedCharsText, { timeout: 10000 });
                  } else {
                    handleChangeAgent(level.level, newValue);
                  }
                }}
                placeholder={level.name}
                typeStyle='type-2'
                value={agent[`level${level.level}`] || ''}
              />
            ))}

            {hasChangePermission ? (
              <FlexContainer
                classes='agent-data-panel-save-button-container'
                flex='0.8'
              >
                <ButtonStandardNoFill
                  key='agents-data-box-save-button-key'
                  disabled={isSaving || !changes}
                  margin='10px 0 5px 0'
                  iconName='save-solid'
                  onClick={updateAgent}
                  text='Save Changes'
                  buttonRef={saveButtonRef}
                  typeStyle='type-4'
                />
              </FlexContainer>
            ) : (
              <></>
            )}
          </PanelsSection>

          {canViewAllEvals ? (
            <>
              <AgentScores qualityScores={qualityScores} />

              <AgentRecentEvaluations
                demo={demo}
                recentEvaluations={recentEvaluations}
                noEvalsInYear={!qualityScores.year?.length}
              />

              <AgentRankedSections
                agent={agent?.[`level${orgLevels?.[0]?.level}`]}
                alert={alert}
                demo={demo}
                getAccessTokenSilently={getAccessTokenSilently}
                handleApiError={handleApiError}
                headerText='Development Areas'
                portalId={portalId}
                rankedSections={bottomRanked}
                topOrBottom='bottom'
                userId={userId}
              />

              <AgentRankedSections
                agent={agent?.[`level${orgLevels?.[0]?.level}`]}
                alert={alert}
                demo={demo}
                getAccessTokenSilently={getAccessTokenSilently}
                handleApiError={handleApiError}
                headerText='Top Sections'
                portalId={portalId}
                rankedSections={topRanked}
                topOrBottom='top'
                userId={userId}
              />
            </>
          ) : (
            <></>
          )}
        </PanelsSections>
      </Panels>
    );
};

export default AgentsShowContent;
