import { useEffect, useRef, useState } from 'react';
import { scrollToTop } from '../../utils/createScorecardUtils/createScorecardUtils';

import About from './About';
import ButtonIconOnly from '../Elements/ButtonIconOnly';
import Divider from '../Elements/Divider';
import FlexContainer from '../Elements/FlexContainer';
import HomeFeatures from './HomeFeatures';
import HomeHeader from './HomeHeader';
import HomeInfo from './HomeInfo';

import './HomeScreen.css';

const HomeScreen = () => {
  const elementRef = useRef(null);

  const [isArrowVisible, setIsArrowVisible] = useState(false);

  useEffect(() => {
    const checkScrollPosition = () => {
      if (elementRef.current) {
        if (elementRef.current.scrollTop > 500) setIsArrowVisible(true);
        else setIsArrowVisible(false);
      }
    };

    const element = elementRef.current;

    if (element) {
      element.addEventListener('scroll', checkScrollPosition);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, []);

  return (
    <FlexContainer
      classes='home-screen'
      flexDirection='column'
      justifyContent='flex-start'
      flexRef={elementRef}
    >
      {isArrowVisible && (
        <ButtonIconOnly
          classes='scroll-to-top'
          iconName='arrow-up'
          onClick={() => scrollToTop(elementRef)}
          title='Scroll to top of page'
        />
      )}

      <HomeHeader />

      <HomeFeatures />

      <HomeInfo />

      <Divider />

      <div className='nested-about-screen'>
        <About />
      </div>
    </FlexContainer>
  );
};

export default HomeScreen;
