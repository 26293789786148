import { isBannedChars } from '../../utils/helpers';
import { useAlert } from 'react-alert';

import InputStandard from '../Elements/InputStandard';

import './ScorecardInformation.css';

const ScorecardInformation = ({
  hasChangePermission,
  scorecardName,
  setScorecardName,
  scorecardDescription,
  setScorecardDescription,
  scorecardNameInvalid,
  handleSetHasChanges,
  showInvalid,
}) => {
  const alert = useAlert();

  const handleScorecardNameChange = (event) => {
    const newVal = event.target.value;

    const isBannedCharsText = isBannedChars(newVal, 'Scorecard Name');

    if (isBannedCharsText)
      return alert.error(isBannedCharsText, { timeout: 10000 });

    setScorecardName(newVal);

    handleSetHasChanges?.();
  };

  const handleScorecardDescriptionChange = (event) => {
    setScorecardDescription(event.target.value);

    handleSetHasChanges?.();
  };

  return (
    <>
      <InputStandard
        classes='scorecard-name-input'
        hasPermission={hasChangePermission}
        id='scorecard-name'
        invalid={scorecardNameInvalid}
        isRequired
        labelText='Scorecard Name'
        name='scorecard-name'
        onChange={handleScorecardNameChange}
        placeholder='< Scorecard Name >'
        showInvalid={showInvalid}
        value={scorecardName}
      />

      <InputStandard
        classes='scorecard-description-input'
        hasPermission={hasChangePermission}
        id='scorecard-description'
        labelText='Scorecard Description'
        name='scorecard-description'
        onChange={handleScorecardDescriptionChange}
        placeholder='< Scorecard Description >'
        showInvalid={showInvalid}
        value={scorecardDescription}
      />
    </>
  );
};

export default ScorecardInformation;
