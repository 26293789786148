import { useState } from 'react';

import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import InfoText from '../../Elements/InfoText';
import ReactSlider from 'react-slider';
import ReportsFocusDistinctDataList from './ReportsFocusDistinctDataList';
import Text from '../../Elements/Text';

import './ReportsFocusDistinctDataSlider.css';

const ReportsFocusDistinctDataSlider = ({
  code,
  data,
  isSelectingFromList,
  sectionControl,
  sectionParameters,
  sectionTitle,
  setIsSelectingFromList,
  setReportParameters,
}) => {
  const dataOnlyNums = data.filter((dataItem) => typeof dataItem === 'number');

  const min = Number(dataOnlyNums[0]);
  const max = Number(dataOnlyNums[dataOnlyNums.length - 1]);

  const invalid =
    !sectionParameters?.params[0] ||
    !sectionParameters?.params[1] ||
    (min === parseInt(sectionParameters?.params[0]) &&
      max === parseInt(sectionParameters?.params[1]));

  const [value, setValue] = useState([
    sectionParameters?.params[0] || min,
    sectionParameters?.params[1] || max,
  ]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleAfterChange = (newValue) => {
    setReportParameters((curr) => {
      const copiedCurr = { ...curr };

      const invalid =
        min === parseInt(newValue[0]) && max === parseInt(newValue[1]);

      if (!copiedCurr[code]) {
        copiedCurr[code] = {
          params: newValue,
          control: sectionControl,
          title: sectionTitle,
          type: 'bet',
          invalid,
        };

        return copiedCurr;
      }

      const copiedSectionParameters = { ...copiedCurr[code] };

      copiedSectionParameters.params = newValue;
      copiedSectionParameters.invalid = invalid;
      copiedSectionParameters.type = 'bet';

      copiedCurr[code] = copiedSectionParameters;

      return copiedCurr;
    });
  };

  if (!dataOnlyNums.length) return <InfoText text='No data' />;
  else
    return (
      <FlexContainer alignItems='stretch' flexDirection='column'>
        <ButtonStandardNoFill
          alignSelf='center'
          classes='focus-date-data-button-select'
          iconName={isSelectingFromList ? 'sliders' : 'bars'}
          margin='5px 0 10px'
          onClick={() => {
            setIsSelectingFromList((curr) => !curr);
          }}
          text={isSelectingFromList ? 'Use slider' : 'Select from list'}
          toggled={isSelectingFromList}
        />

        {isSelectingFromList ? (
          <ReportsFocusDistinctDataList
            code={code}
            data={data}
            sectionControl={sectionControl}
            sectionParameters={sectionParameters}
            sectionTitle={sectionTitle}
            setReportParameters={setReportParameters}
          />
        ) : (
          <>
            <ReactSlider
              className='focus-slider'
              max={max}
              min={min}
              minDistance={0}
              onChange={handleChange}
              onAfterChange={handleAfterChange}
              thumbClassName={`focus-slider-thumb${
                invalid ? ' focus-slider-thumb-invalid' : ''
              }`}
              trackClassName={`focus-slider-track${
                invalid ? ' focus-slider-track-invalid' : ''
              }`}
              value={value}
            />

            <FlexContainer marginTop='50px'>
              <Text text={`${value[0]} - ${value[1]}`} />
            </FlexContainer>

            {sectionParameters &&
              !sectionParameters.invalid &&
              sectionParameters.type === 'bet' && (
                <ButtonStandardNoFill
                  alignSelf='center'
                  iconName='xmark'
                  margin='15px 0 5px'
                  onClick={() => {
                    handleChange([min, max]);
                    handleAfterChange([min, max]);
                  }}
                  text='Clear'
                  typeStyle='type-2'
                />
              )}
          </>
        )}
      </FlexContainer>
    );
};

export default ReportsFocusDistinctDataSlider;
