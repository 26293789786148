import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InfoText from '../../Elements/InfoText';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import Text from '../../Elements/Text';
import VarianceResultChart from './VarianceResultChart';
import VarianceResultLists from './VarianceResultLists';

const CalibrationResults = ({
  allUsersEvaluations,
  calibration,
  isLoading,
  type,
}) => {
  const isEnoughCompleted =
    (type === 'consensus' && allUsersEvaluations.length > 1) ||
    (type === 'benchmark' && allUsersEvaluations.length > 0);

  const isMIssingBenchmark =
    calibration.type === 'benchmark' && !calibration.benchmark_evaluation_id;

  if (!isEnoughCompleted && isMIssingBenchmark)
    return (
      <>
        <InfoText
          text={`A Benchmark Evaluation and at least 1 submitted evaluation are required for results to be generated.`}
        />
      </>
    );
  else if (!isEnoughCompleted)
    return (
      <InfoText
        text={`At least ${
          type === 'consensus' ? '2' : '1'
        } submitted evaluation${
          type === 'consensus' ? 's are' : ' is'
        } required for results to be generated.`}
      />
    );
  else if (isMIssingBenchmark)
    return (
      <InfoText
        text={'A Benchmark Evaluation is required for results to be generated.'}
      />
    );
  else if (isLoading) return <LoadingSpinnerNew />;
  else if (type === 'consensus' || type === 'benchmark')
    return (
      <FlexContainer flexDirection='column'>
        <HeaderSecondary
          marginBottom='15px'
          text='Variance'
          textDecoration='underline'
        />

        <FlexContainer flexWrap='wrap'>
          {(calibration.variance || calibration.variance === 0) && (
            <VarianceResultChart score={calibration.variance} text='Overall' />
          )}

          {(calibration.qs_variance || calibration.qs_variance === 0) && (
            <VarianceResultChart
              score={calibration.qs_variance}
              text='Quality Score'
            />
          )}

          {(calibration.pf_variance || calibration.pf_variance === 0) && (
            <VarianceResultChart
              score={calibration.pf_variance}
              text='Pass / Fail'
            />
          )}
        </FlexContainer>

        {calibration.type === 'consensus' ? (
          <FlexContainer marginBottom='15px' flexDirection='column'>
            <FlexContainer flexWrap='wrap'>
              <HeaderSecondary
                textAlign='center'
                margin='5px'
                text='Group Average Quality Score:'
              />

              <Text
                margin='0 5px'
                text={calibration.calibrationResults.averageQs}
              />
            </FlexContainer>

            {calibration.calibrationResults.averagePf ? (
              <FlexContainer flexWrap='wrap' marginTop='5px'>
                <HeaderSecondary
                  textAlign='center'
                  margin='5px'
                  text='Group Average Pass %:'
                />
                <Text
                  margin='0 5px'
                  text={calibration.calibrationResults.averagePf}
                />
              </FlexContainer>
            ) : (
              <></>
            )}
          </FlexContainer>
        ) : (
          <></>
        )}

        <VarianceResultLists
          calibrationResults={calibration.calibrationResults}
        />
      </FlexContainer>
    );

  return <div></div>;
};

export default CalibrationResults;
