import { useState } from 'react';

import FlexContainer from '../../Elements/FlexContainer';
import GridOption from '../DataGrid/GridContent/GridOption';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import VarianceResultList from './VarianceResultList';

const VarianceResultLists = ({ calibrationResults, fromCalibrationReport }) => {
  const [showResultList, setShowResultList] = useState('');

  const hasSecs =
    calibrationResults.secsAdmin?.length ||
    calibrationResults.secsScoring?.length ||
    calibrationResults.secsCat?.length;
  const hasUsers =
    calibrationResults.usersScoring?.length ||
    calibrationResults.usersQs?.length ||
    calibrationResults.usersPf?.length;

  return (
    <FlexContainer flexDirection='column'>
      {hasSecs ? (
        <>
          <HeaderSecondary
            margin='0 0 10px 0'
            text='Sections'
            textDecoration='underline'
          />

          <FlexContainer marginBottom='10px' flexWrap='wrap'>
            {calibrationResults.secsAdmin?.length > 0 && (
              <GridOption
                headerText='Admin'
                showGridOption={showResultList}
                setShowGridOption={setShowResultList}
                gridOption='adminSections'
              />
            )}

            {calibrationResults.secsScoring?.length > 0 && (
              <GridOption
                headerText='Scoring'
                showGridOption={showResultList}
                setShowGridOption={setShowResultList}
                gridOption='scoringSections'
              />
            )}

            {calibrationResults.secsCat?.length > 0 && (
              <GridOption
                headerText='Category'
                showGridOption={showResultList}
                setShowGridOption={setShowResultList}
                gridOption='catSections'
              />
            )}
          </FlexContainer>
        </>
      ) : (
        <></>
      )}

      {hasUsers ? (
        <>
          <HeaderSecondary
            margin='0 0 10px 0'
            text='Participants'
            textDecoration='underline'
          />

          <FlexContainer marginBottom='10px' flexWrap='wrap'>
            {calibrationResults.usersScoring?.length > 0 && (
              <GridOption
                headerText='Overall'
                showGridOption={showResultList}
                setShowGridOption={setShowResultList}
                gridOption='scoringSectionsParticipants'
              />
            )}

            {calibrationResults.usersQs?.length > 0 && (
              <GridOption
                headerText='Quality Score'
                showGridOption={showResultList}
                setShowGridOption={setShowResultList}
                gridOption='qualityScore'
              />
            )}

            {calibrationResults.usersPf?.length > 0 && (
              <GridOption
                headerText='Pass / Fail'
                showGridOption={showResultList}
                setShowGridOption={setShowResultList}
                gridOption='passFail'
              />
            )}
          </FlexContainer>
        </>
      ) : (
        <></>
      )}

      {calibrationResults.secsAdmin?.length > 0 &&
        showResultList === 'adminSections' && (
          <VarianceResultList
            fromCalibrationReport={fromCalibrationReport}
            headerText={`Admin Section Variance${
              fromCalibrationReport ? ':' : ''
            }`}
            listData={calibrationResults.secsAdmin}
          />
        )}

      {calibrationResults.secsScoring?.length > 0 &&
        showResultList === 'scoringSections' && (
          <VarianceResultList
            fromCalibrationReport={fromCalibrationReport}
            headerText={`Scoring Section Variance${
              fromCalibrationReport ? ':' : ''
            }`}
            listData={calibrationResults.secsScoring}
          />
        )}

      {calibrationResults.secsCat?.length > 0 &&
        showResultList === 'catSections' && (
          <VarianceResultList
            fromCalibrationReport={fromCalibrationReport}
            headerText={`Category Variance${fromCalibrationReport ? ':' : ''}`}
            listData={calibrationResults.secsCat}
          />
        )}

      {calibrationResults.usersScoring?.length > 0 &&
        showResultList === 'scoringSectionsParticipants' && (
          <VarianceResultList
            fromCalibrationReport={fromCalibrationReport}
            headerText={`Overall Variance (Participants)${
              fromCalibrationReport ? ':' : ''
            }`}
            listData={calibrationResults.usersScoring}
          />
        )}

      {calibrationResults.usersQs?.length > 0 &&
        showResultList === 'qualityScore' && (
          <VarianceResultList
            fromCalibrationReport={fromCalibrationReport}
            headerText={`Quality Score Variance (Participants)${
              fromCalibrationReport ? ':' : ''
            }`}
            listData={calibrationResults.usersQs}
          />
        )}

      {calibrationResults.usersPf?.length > 0 &&
        showResultList === 'passFail' && (
          <VarianceResultList
            fromCalibrationReport={fromCalibrationReport}
            headerText={`Pass / Fail Variance (Participants)${
              fromCalibrationReport ? ':' : ''
            }`}
            listData={calibrationResults.usersPf}
          />
        )}
    </FlexContainer>
  );
};

export default VarianceResultLists;
