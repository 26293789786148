import ButtonToggle from '../../Elements/ButtonToggle';

const BillingOptionButton = ({
  disabled,
  option,
  selectedBillingOption,
  setSelectedBillingOption,
  title,
}) => {
  return (
    <ButtonToggle
      disabled={disabled}
      margin='10px'
      onClick={() => {
        setSelectedBillingOption((curr) => (curr === option ? null : option));
      }}
      toggled={selectedBillingOption === option}
      text={option}
      title={title}
    />
  );
};

export default BillingOptionButton;
