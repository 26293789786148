import './PanelsSection.css';

const PanelsSection = ({
  children,
  alignItems,
  alignSelf,
  classes = '',
  flexDirection,
  flexWrap,
  height,
  justifyContent,
  margin,
  marginBottom,
  marginTop,
  maxHeight,
  maxWidth,
  noMaxWidth,
  overflowY,
  overflowX,
  padding,
  position,
  typeStyle = 'type-1',
  width,
}) => {
  const style = {};

  if (alignItems) style.alignItems = alignItems;
  if (alignSelf) style.alignSelf = alignSelf;
  if (flexDirection) style.flexDirection = flexDirection;
  if (flexWrap) style.flexWrap = flexWrap;
  if (height) style.height = height;
  if (justifyContent) style.justifyContent = justifyContent;
  if (margin) style.margin = margin;
  if (marginBottom) style.marginBottom = marginBottom;
  if (marginTop) style.marginTop = marginTop;
  if (maxHeight) style.maxHeight = maxHeight;
  if (maxWidth) style.maxWidth = maxWidth;
  if (overflowX) style.overflowX = overflowX;
  if (overflowY) style.overflowY = overflowY;
  if (padding) style.padding = padding;
  if (position) style.position = position;
  if (width) style.width = width;

  return (
    <div
      className={`panels-section ${
        noMaxWidth ? 'panels-section-no-max-width' : ''
      } ${typeStyle ? `panels-section-${typeStyle}` : ''} ${classes}`}
      style={style}
    >
      {children}
    </div>
  );
};

export default PanelsSection;
