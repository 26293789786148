import './FillScoringSections.css';

import { useEffect, useState } from 'react';
import Icon from '../Elements/Icon';

const FillScoringSections = ({
  fromPrintable,
  section,
  sectionPos,
  sectionNeg,
}) => {
  const [sectionMarkClass, setSectionMarkClass] = useState('section-mark-none');
  const [selectedMark, setSelectedMark] = useState();
  const [isMarkingChoiceChanging, setIsMarkingChoiceChanging] = useState();

  const handleMarkStatusChange = (event) => {
    setSelectedMark(event.target.value);

    if (sectionPos !== sectionNeg) {
      if (event.target.value === sectionPos) {
        setSectionMarkClass('section-mark-pass');
        return;
      } else if (event.target.value === sectionNeg) {
        setSectionMarkClass('section-mark-fail');
        return;
      } else if (event.target.value === 'NA') {
        setSectionMarkClass('section-mark-na');
        return;
      }
    }

    setSectionMarkClass('section-mark-none');
  };

  useEffect(() => {
    if (!isMarkingChoiceChanging) setIsMarkingChoiceChanging(true);

    if (sectionMarkClass === 'section-mark-pass') setSelectedMark(sectionPos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionPos]);

  useEffect(() => {
    if (!isMarkingChoiceChanging) setIsMarkingChoiceChanging(true);

    if (sectionMarkClass === 'section-mark-fail') setSelectedMark(sectionNeg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionNeg]);

  useEffect(() => {
    if (isMarkingChoiceChanging) setIsMarkingChoiceChanging(false);
  }, [isMarkingChoiceChanging]);

  const sectionType = section.type;

  if (sectionType === 'comments-box') {
    return (
      <li className='scorecard-comments-box-container' key={section.uniqueRef}>
        {fromPrintable ? (
          <div className='scorecard-comments-box'></div>
        ) : (
          <textarea className='scorecard-comments-box' placeholder='Comments' />
        )}
      </li>
    );
  } else if (sectionType === 'scoring-category') {
    return (
      <li className='scorecard-cat-heading' key={section.uniqueRef}>
        <h2 className='scorecard-cat-title wrap-unbroken'>{section.title}</h2>

        {fromPrintable ? (
          <></>
        ) : (
          <p className='scorecard-cat-score'>{'<Score>'}</p>
        )}
      </li>
    );
  } else {
    return (
      <li key={section.uniqueRef} className='scorecard-section-container'>
        {section.title && (
          <div className='scorecard-section-title-container'>
            <label
              htmlFor={'a' + section.uniqueRef}
              className='scorecard-section-title wrap-unbroken'
            >
              {section.title}
            </label>
          </div>
        )}

        <div className='scorecard-section-select-container'>
          <select
            value={selectedMark}
            id={'a' + section.uniqueRef}
            className={`scorecard-section-select ${sectionMarkClass}`}
            onChange={handleMarkStatusChange}
          >
            {isMarkingChoiceChanging && (
              <>
                <option></option>
                <option>placeholder</option>
              </>
            )}

            {!isMarkingChoiceChanging && (
              <>
                <option value=''></option>
                <option value={sectionPos}>{sectionPos}</option>
                <option value={sectionNeg}>{sectionNeg}</option>
                <option value='NA'>NA</option>
              </>
            )}
          </select>

          <div
            className={`scorecard-section-select-icon-container icon-container-${sectionMarkClass}`}
          >
            <Icon
              className={`scorecard-section-select-icon icon-${sectionMarkClass}`}
              iconName='circle-chevron-down'
            />
          </div>
        </div>

        {section.description && (
          <div className='scorecard-section-description-container'>
            <label
              htmlFor={'a' + section.uniqueRef}
              className='scorecard-section-description wrap-unbroken'
            >
              {section.description}
            </label>
          </div>
        )}
        {(section.weight || section.ra || section.csm) && (
          <div className='scorecard-behaviours-container'>
            {!!section.weight && (
              <div className='scorecard-behaviour-container'>
                <p className='scorecard-behaviour-name'>Weight: </p>
                <p className='scorecard-behaviour-amount'>{section.weight}</p>
              </div>
            )}
            {!!section.ra && (
              <div className='scorecard-behaviour-container'>
                <p className='scorecard-behaviour-name'>RA: </p>
                <p className='scorecard-behaviour-amount'>{section.ra}</p>
              </div>
            )}
            {!!section.csm && (
              <div className='scorecard-behaviour-container'>
                <p className='scorecard-behaviour-name'>CSM: </p>
                <p className='scorecard-behaviour-amount'>{section.csm}</p>
              </div>
            )}
          </div>
        )}
      </li>
    );
  }
};

export default FillScoringSections;
