import { apiUrl, headers } from './config';

const orgLevelsApi = {
  getAll: async ({ portalId, token, userId }) => {
    const url = `/api/portals/${portalId}/org-levels`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getDistinctLevels: async ({ portalId, token, userId }) => {
    const url = `/api/portals/${portalId}/org-levels/distinct`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  post: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/org-levels`;

    const { data } = await apiUrl.post(url, reqBody, headers({ token }));

    return data;
  },
};

export default orgLevelsApi;
