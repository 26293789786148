import { useAuth0 } from '@auth0/auth0-react';

const Logout = ({ classes = '' }) => {
  const { logout } = useAuth0();

  return (
    <button
      className={`portal-logout-button ${classes}`}
      onClick={() => logout({ returnTo: window?.location?.origin })}
      title='Log out'
    >
      <div className='nav-button-inner'>Log Out</div>
    </button>
  );
};

export default Logout;
