import tickSmall from '../../images/tick-small.png';

import './About.css';

const RiskFeatures = () => {
  return (
    <ul className='about-screen-list'>
      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Risk analysis report built-in</p>

        <ul className='about-screen-list about-screen-list-nested'>
          <li>
            <p>Filter by which scorecards you want to see risk data for</p>
          </li>
        </ul>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Risk scores are used along with other factors in calculating an
          agent's 'Top Sections' and 'Development Areas'
        </p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Calculation of risk scores uses a combination of the section's pass %,
          the weight, RA, and/or CSM applied to the section, and how frequently
          it has been marked as applicable on evaluations.
        </p>
      </li>
    </ul>
  );
};

export default RiskFeatures;
