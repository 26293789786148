import './ButtonText.css';

const ButtonText = ({
  text = '',
  disabled = false,
  type = 'button',
  onClick,
  classes = '',
  title,
}) => {
  return (
    <button
      className={`button-text ${classes}`}
      type={type}
      disabled={disabled}
      onClick={onClick}
      title={title}
    >
      {text}
    </button>
  );
};

export default ButtonText;
