import Icon from './Icon';

import './IconWithSub.css';

const IconWithSub = ({
  classes = '',
  subClasses = '',
  fill,
  iconName,
  subIconName,
}) => {
  return (
    <div className='icon-with-sub-container'>
      <Icon
        className={`main-icon ${classes}`}
        iconName={iconName}
        fill={fill}
      />
      <Icon
        className={`sub-icon ${subClasses}`}
        iconName={subIconName}
        style={{ borderColor: fill }}
        fill={fill}
      />
    </div>
  );
};

export default IconWithSub;
