import {
  charsCantEndIn,
  charsCantEqual,
  charsCantInclude,
} from '../utils/constants';

import moment from 'moment';

export const generateUniqueRef = () =>
  `${Math.floor(Math.random() * 100000000000)}${Date.now()}`;

export const getFilterCondition = (filterObject) => {
  return Object.keys(filterObject).filter((key) => key !== 'uniqueRef')[0];
};

export const capitalise = (string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;

export const emptyValue = (value) => {
  return value === null || value === undefined || value === '';
};

export const copyLines = (curr) => {
  return [...curr.map((line) => ({ ...line }))];
};

export const sortAlphaCaseInsensitive = (a, b) => {
  return a.toLowerCase().localeCompare(b.toLowerCase());
};
export const hasDuplicates = (array) => {
  return new Set(array).size !== array.length;
};
export const removeDuplicates = (array) => {
  return [...new Set(array)];
};

export const shouldNavigateOnNotFound = (error) => {
  return (
    error?.response?.status === 404 &&
    error?.response?.statusText === 'Not Found'
  );
};

export const doArrayValuesMatch = (array1, array2) => {
  return (
    ((array1?.length && array2?.length && array1.length === array2.length) ||
      (array1.length === 0 && array2.length === 0)) &&
    array1.every((value, index) => value === array2[index])
  );
};

export const doArrayValuesMatchAnyOrder = (array1, array2) => {
  return (
    ((array1?.length && array2?.length && array1.length === array2.length) ||
      (array1?.length === 0 && array2?.length === 0)) &&
    array1.every((value) => array2.includes(value))
  );
};

export const buildApiParams = (limit, page, query, sortBy) => {
  const params = [];

  if (limit) params.push(`limit=${encodeURIComponent(limit)}`);
  if (page) params.push(`page=${encodeURIComponent(page)}`);
  if (query) params.push(`q=${encodeURIComponent(query)}`);
  if (sortBy) params.push(`sort_by=${encodeURIComponent(sortBy)}`);

  return params;
};

export const selectOrDeselectArrayValue = (value, array) => {
  const valueIndex = array.indexOf(value);

  const newArray = [...array];

  if (valueIndex !== -1) {
    newArray.splice(valueIndex, 1);

    return newArray;
  } else {
    newArray.push(value);

    return newArray;
  }
};

export const validatePresenceRequired = (value, name) => {
  if (value === '' || value === undefined || value === null)
    return `${name} is required`;
};
export const validateContainsALetter = (value, name) => {
  if (value === '' || value === undefined || value === null)
    return `${name} must contain at least one letter`;
};

export const validAsNumber = (value) => {
  return value !== undefined && value !== null && value !== '';
};

export const kebabToCamel = (kebabStr) => {
  return kebabStr.replace(/-([a-z])/g, (match, group1) => group1.toUpperCase());
};
export const kebabToSnake = (kebabStr) => {
  return kebabStr.replace(/-/g, '_');
};
export const kebabToTitle = (kebabStr) => {
  return kebabStr
    .replace(/-/g, ' ')
    .toLowerCase()
    .replace(/\b\w/g, (c) => c.toUpperCase());
};
export const kebabToLowerTitle = (kebabStr) => {
  return kebabStr.replace(/-/g, ' ').toLowerCase();
};

export const mapEntriesToObjects = (entries) => {
  return entries.map(([id, data]) => ({
    id,
    data,
  }));
};

export const organiseColumns = (columnArray) => [
  ...columnArray.filter((item) => item.toggled),
  ...columnArray
    .filter((item) => !item.toggled)
    .sort((a, b) => sortAlphaCaseInsensitive(a.value, b.value)),
];

export const titleise = (str) => {
  return str.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
};

export const mutateGridData = ([column, rawValue]) => {
  const newValue =
    rawValue === null
      ? '-'
      : column === 'created_at'
      ? moment(rawValue).format('YYYY-MM-DD')
      : column === 'last_modified_at'
      ? moment(rawValue).format('YYYY-MM-DD')
      : rawValue === true
      ? 'Yes'
      : rawValue === false
      ? 'No'
      : rawValue;

  return [column, newValue];
};

export const lettersOnly = (value) => {
  return value.match(/[a-zA-Z]/g)?.join('') ?? '';
};

export const checkIsStringNumber = (str) => {
  if (typeof str !== 'string') return false;

  return !isNaN(str) && !isNaN(parseFloat(str));
};

export const isBannedChars = (val, property = 'Value') => {
  for (let i = 0; i < charsCantInclude.length; i++) {
    if (val.includes(charsCantInclude[i]))
      return `${property} can't include '${charsCantInclude[i]}'`;
  }

  if (charsCantEqual.includes(val)) return `${property} can't equal '${val}'`;

  for (let i = 0; i < charsCantEndIn.length; i++) {
    if (charsCantEndIn[i] === val.slice(val.length - charsCantEndIn[i].length))
      return `${property} can't end in '${charsCantEndIn[i]}'`;
  }
};

export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return re.test(email);
};
