import { translateSourceTables } from '../../../utils/reports';
import { useEffect, useState } from 'react';

import AggOnPercent from './AggOnPercent';
import AggregateItem from './AggregateItem';
import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import SelectStandard from '../../Elements/SelectStandard';

import './ReportAggregate.css';

const ReportAggregate = ({
  aggregate,
  demo,
  handleChangeAggregate,
  selectedReportData,
  columnOrder,
}) => {
  const [aggBy, setAggBy] = useState('');
  const [aggOn, setAggOn] = useState('');
  const [percentOf, setPercentOf] = useState([]);
  const [outOf, setOutOf] = useState([]);
  const [source, setSource] = useState('');
  const [title, setTitle] = useState('');
  const [control, setControl] = useState('');
  const [canNumberAgg, setCanNumberAgg] = useState();

  const handleChangeAggBy = (event) => {
    const aggBy = event.target.value;

    const source = aggBy.slice(0, aggBy.indexOf('_'));
    const title = aggBy.slice(aggBy.lastIndexOf('_') + 1);
    const control = aggBy.slice(aggBy.indexOf('_') + 1, aggBy.lastIndexOf('_'));

    setSource(source);
    setTitle(title);
    setControl(control);

    setCanNumberAgg(
      (source === 'evals' && title === 'Quality Score') ||
        (source === 'admin' && control === 'Number') ||
        source === 'cat'
    );

    setAggBy(event.target.value);
    setAggOn('');
    setPercentOf([]);
    setOutOf([]);
  };

  useEffect(() => {
    if (!selectedReportData[aggBy])
      handleChangeAggBy({ target: { value: '' } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportData]);

  return (
    <FlexContainer classes='report-aggregate-area' flexWrap='wrap'>
      <SelectStandard
        containerClasses='select-agg-by-select'
        id='select-agg-by'
        labelText='Select data to aggregate'
        margin='5px'
        onChange={handleChangeAggBy}
        options={[
          { key: '', code: '', name: '< Select >' },
          ...Object.entries(selectedReportData).map(
            ([reportDataKey, { source_table, title }]) => ({
              key: `agg-by-select-option-${reportDataKey}`,
              code: reportDataKey,
              name: `${title} (${translateSourceTables[source_table]})`,
            })
          ),
        ]}
        value={aggBy}
      />

      {aggBy && (
        <SelectStandard
          id='select-agg-on'
          labelText='Select aggregate type'
          margin='5px'
          onChange={(event) => setAggOn(event.target.value)}
          options={[
            { code: '', name: '< Select >' },
            { code: 'avg', disabled: !canNumberAgg, name: 'AVERAGE' },
            { code: 'count', name: 'COUNT' },
            { code: 'sum', disabled: !canNumberAgg, name: 'SUM' },
            { code: 'percent', name: 'PERCENT' },
            { code: 'max', disabled: !canNumberAgg, name: 'MAX' },
            { code: 'min', disabled: !canNumberAgg, name: 'MIN' },
          ]}
          value={aggOn}
        />
      )}

      {aggOn === 'percent' && (
        <AggOnPercent
          aggBy={aggBy}
          control={control}
          demo={demo}
          source={source}
          title={title}
          percentOf={percentOf}
          outOf={outOf}
          setOutOf={setOutOf}
          setPercentOf={setPercentOf}
        />
      )}

      <div style={{ width: '100%' }}>
        <ButtonIconOnly
          disabled={
            !aggBy ||
            !aggOn ||
            (aggOn === 'percent' &&
              (percentOf.length === 0 ||
                percentOf.some((value) => !outOf.includes(value)) ||
                !outOf.some((value) => !percentOf.includes(value))))
          }
          iconName='plus'
          margin='5px auto'
          onClick={() => handleChangeAggregate(aggBy, aggOn, percentOf, outOf)}
          title='Add aggregate'
        />
      </div>

      <HeaderSecondary
        margin='10px 0'
        text='Aggregated Data:'
        textAlign='center'
        width='100%'
      />

      {columnOrder
        .filter((col) => aggregate.includes(col.id))
        .map((col) => {
          return (
            <AggregateItem
              key={`aggregateitem_${col.id}`}
              aggItem={col}
              handleChangeAggregate={handleChangeAggregate}
            />
          );
        })}
    </FlexContainer>
  );
};

export default ReportAggregate;
