import {
  buildApiParams,
  doArrayValuesMatch,
  kebabToCamel,
  kebabToLowerTitle,
  kebabToSnake,
  kebabToTitle,
  removeDuplicates,
  sortAlphaCaseInsensitive,
} from '../../../utils/helpers';
import { customError, handleApiError } from '../../../utils/error-handling';
import { QueryParams, UserPortal } from '../../../contexts';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import BulletPoint from '../../Elements/BulletPoint';
import GridContent from './GridContent/GridContent';
import MainOnly from '../MainAndOptions/MainOnly';
import portalConfirmAlert from '../../../utils/portalConfirmAlert';

import './DataGrid.css';
// columns can't begin with '-'. Look into changing to '--' so this can be allowed.

const DataGrid = ({
  altApiKey,
  apiDataService,
  defaultColumns,
  defaultOptions,
  demo,
  excludeFromFilterPanel,
  getAvailableColumns,
  gridName,
  newScreenRoute,
  noEditCanDelete,
  noEditNew,
  noShow,
  noNew,
  iconName,
  onAfterSuccessfulDelete,
  onDelete,
  requiredFields,
  selectFrom,
  showRecordProp,
  unEditableColumns = [],
  validationColumns,
}) => {
  const navigate = useNavigate();

  const gridNameCamel = kebabToCamel(gridName);
  const gridNameLowerTitle = kebabToLowerTitle(gridName);
  const gridNameSnake = kebabToSnake(gridName);
  const gridNameTitle = kebabToTitle(gridName);

  const { queryParams, setQueryParams } = useContext(QueryParams);

  const location = useLocation();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { getAccessTokenSilently } = useAuth0();

  const alert = useAlert();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedOptions, setSelectedOptions] = useState();

  const [gridUnEditableColumns, setGridUnEditableColumns] =
    useState(unEditableColumns);

  const [columnsBeforeEditing, setColumnsBeforeEditing] = useState([]);

  const hasNavigated = location.state?.isNavigating;

  const storedParams =
    queryParams?.[location.pathname] || hasNavigated
      ? JSON.parse(localStorage.getItem('queryParams'))?.[location.pathname]
      : null;

  // FUNCTIONS

  const handleChangeOptions = (event) => {
    const optionsTargetValue = event.target.value;

    if (
      optionsTargetValue === 'default' ||
      optionsTargetValue === 'placeholder'
    ) {
      setSelectedOptions({
        id: optionsTargetValue,
        options: defaultOptions,
      });
    } else {
      const optionsId = parseInt(optionsTargetValue);

      const selectedSavedOptions = savedOptions.filter(
        (options) => options.id === optionsId
      )[0].options;

      const savedCustomColumns = selectedSavedOptions.customColumns;

      const amendedCustomCols =
        savedCustomColumns === defaultColumns.join(',,')
          ? null
          : savedCustomColumns;

      setSelectedOptions({
        id: optionsId,
        options: { ...selectedSavedOptions, customColumns: amendedCustomCols },
      });
    }
  };

  const validateSetSearchParams = (newParams) => {
    const paramsObj = {};

    const newParamsList = Object.keys(newParams);

    const searchParamsLookup = {
      page: 'page',
      limit: 'limit',
      view: 'view',
      query: 'q',
      customColumns: 'custom_columns',
      sortBy: 'sort_by',
    };

    Object.entries(searchParamsLookup).forEach(([stateName, paramName]) => {
      // if its a new param and it's different to default and it's not null - set to searchParams
      // if it's not a new param but it currently exists on searchParams and it's different to default - set that existing param to searchParams

      if (
        newParams[stateName] &&
        newParams[stateName] !== defaultOptions[stateName]
      ) {
        paramsObj[paramName] = newParams[stateName];
      } else if (
        !newParamsList.includes(stateName) &&
        searchParams.get(paramName) &&
        searchParams.get(paramName) !== defaultOptions[stateName]
      ) {
        paramsObj[paramName] = searchParams.get(paramName);
      }
    });

    setSearchParams(paramsObj);

    setQueryParams((curr) => {
      const returnObj = {
        ...curr,
        [location.pathname]: paramsObj,
      };

      localStorage.setItem('queryParams', JSON.stringify(returnObj));

      return returnObj;
    });
  };

  const onUpdateOptions = (optionChanged, newValue) => {
    if (optionChanged === 'saved-options') {
      const {
        customColumns: newCustomColumns,
        limit: newLimit,
        page: newPage,
        query: newQuery,
        sortBy: newSortBy,
        view: newView,
      } = newValue;

      if (customColumns !== newCustomColumns)
        setCustomColumns(newCustomColumns);
      if (limit !== newLimit) setLimit(newLimit);
      if (page !== newPage) setPage(newPage);
      if (sortBy !== newSortBy) setSortBy(newSortBy);
      if (view !== newView) setView(newView);
      if (query !== newQuery) setQuery(newQuery);

      validateSetSearchParams({
        customColumns: newCustomColumns,
        limit: newLimit,
        page: newPage,
        query: newQuery,
        sortBy: newSortBy,
        view: newView,
      });

      getAll(newLimit, newPage, newQuery, newSortBy);
    } else {
      if (selectedOptions) {
        if (selectedOptions.options[optionChanged] !== newValue) {
          setSelectedOptions(null);
        } else return;
      }

      // if  selectedoptions is truthy, and the new value of the
      // option changed is different to corresponding value in
      // selected options, then set selected options to null and continue
      // to the update. If it isn't different then it means it is a side effect
      // from the selectedoptions being selected and so we should ignore the
      // update here as it will be handled correctly above.

      // if selectedoptions is falsy just continue

      if (optionChanged === 'limit') {
        const newPage = Math.ceil((limit * (page - 1) + 1) / newValue);

        setLimit(newValue);
        setPage(newPage);

        validateSetSearchParams({ page: newPage, limit: newValue });

        getAll(newValue, newPage, query, sortBy);
      } else if (optionChanged === 'query') {
        if (query !== newValue) {
          setQuery(newValue);
          setPage(1);

          validateSetSearchParams({ query: newValue, page: 1 });

          getAll(limit, 1, newValue, sortBy);
        }
      } else if (optionChanged === 'page') {
        setPage(newValue);

        validateSetSearchParams({ page: newValue });

        getAll(limit, newValue, query, sortBy);
      } else if (optionChanged === 'sortBy') {
        setSortBy(newValue);
        setPage(1);

        validateSetSearchParams({ sortBy: newValue, page: 1 });

        getAll(limit, 1, query, newValue);
      } else if (optionChanged === 'customColumns') {
        setCustomColumns(newValue);

        validateSetSearchParams({ customColumns: newValue });
      } else if (optionChanged === 'view') {
        setView(newValue);

        validateSetSearchParams({ view: newValue });
      }
    }
  };

  const initialiseCustomColumns = () => {
    const defaultColumnsAsParams = defaultColumns.join(',,');
    const customColumnParams = searchParams.get('custom_columns');
    if (defaultColumnsAsParams === customColumnParams || !customColumnParams)
      return null;
    else return customColumnParams;
  };

  const initialiseNumOption = (option) => {
    const value = searchParams.get(option) ?? storedParams?.[option];

    if (value) return parseInt(value);
    else return defaultOptions[option];
  };

  const initialiseSortColumns = (sortBy) => {
    if (sortBy) {
      return sortBy.split(',,').map((column, index) => {
        const direction = column[0] === '-' ? '-' : '';

        return {
          id: direction ? column.slice(1) : column,
          direction,
          order: index + 1,
          toggled: true,
        };
      });
    } else return [];
  };

  const handleClearSortOptions = () =>
    setSortColumns((curr) =>
      curr
        .map((columnObj) => ({
          ...columnObj,
          toggled: false,
          direction: '',
        }))
        .sort((a, b) => sortAlphaCaseInsensitive(a.value, b.value))
    );

  const handleSetDisplayColumnsToDefault = (defaultColumns) => {
    setDisplayColumns((currentDisplayColumns) => {
      const newDisplayColumns = currentDisplayColumns
        .map((columnObj) => {
          if (defaultColumns.includes(columnObj.id)) {
            return {
              ...columnObj,
              toggled: true,
              order: defaultColumns.indexOf(columnObj.id) + 1,
            };
          } else return { ...columnObj, toggled: false, order: null };
        })
        .sort((a, b) =>
          sortAlphaCaseInsensitive(a.value ?? a.id, b.value ?? b.id)
        )
        .sort((a, b) => a.order - b.order)
        .sort((x, y) => (x.toggled === y.toggled ? 0 : x.toggled ? -1 : 1));

      return newDisplayColumns;
    });
  };

  // URL PARAMS (server)

  const [limit, setLimit] = useState(initialiseNumOption('limit'));
  const [page, setPage] = useState(initialiseNumOption('page'));
  const [query, setQuery] = useState(
    searchParams.get('q') ?? storedParams?.q ?? defaultOptions.query
  );

  const sortByStartVal =
    searchParams.get('sort_by') ??
    storedParams?.sort_by ??
    defaultOptions.sortBy;

  const [sortBy, setSortBy] = useState(sortByStartVal);

  // URL PARAMS (non-server)

  const [customColumns, setCustomColumns] = useState(
    initialiseCustomColumns() ??
      storedParams?.custom_columns ??
      defaultOptions.customColumns
  );

  const [view, setView] = useState(
    searchParams.get('view') ?? storedParams?.view ?? defaultOptions.view
  );

  const initialiseDisplayColumns = () => {
    const getNewColumns = (columnNameArray) => {
      return columnNameArray.map((column, index) => ({
        id: column,
        order: index + 1,
        toggled: true,
      }));
    };

    if (!customColumns) return getNewColumns(defaultColumns);
    else return getNewColumns(customColumns.split(',,'));
  };

  const handleApiReturn = (returnBody) => {
    setReturnedRecords(returnBody[altApiKey || gridNameCamel]);
    setTotalRecordCount(returnBody.totalRecordCount);

    const newAvailableColumns = getAvailableColumns(returnBody.columns);

    if (gridNameCamel === 'evaluations')
      setGridUnEditableColumns((curr) => {
        return [...curr, ...Object.keys(returnBody.columns)];
      });

    const shouldAvailableColumnsBeUpdated = !doArrayValuesMatch(
      Object.keys(newAvailableColumns),
      Object.keys(availableColumns)
    );

    if (shouldAvailableColumnsBeUpdated) {
      setAvailableColumns(newAvailableColumns);
    }

    setColumnTypes(returnBody.columnTypes);
  };

  useEffect(() => {
    getAll(limit, page, query, sortBy);

    if (hasNavigated) setSearchParams(storedParams);
    else {
      setQueryParams(() => {
        const returnObj = {
          ...JSON.parse(localStorage.getItem('queryParams')),
          [location.pathname]: Object.fromEntries(searchParams.entries()),
        };

        localStorage.setItem('queryParams', JSON.stringify(returnObj));

        return returnObj;
      });
    }

    return () => {
      setIsLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !demo &&
      gridName !== 'reports' &&
      gridName !== 'drafts' &&
      gridName !== 'calibrations' &&
      gridName !== 'evaluations' &&
      !userFromDb.permissions?.includes(`${gridNameSnake}.view`)
    ) {
      navigate(`/${demo ? 'demo' : portalId}/dashboard`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridName]);

  // STATES

  const [availableColumns, setAvailableColumns] = useState({});
  const [columnTypes, setColumnTypes] = useState({});
  const [displayColumns, setDisplayColumns] = useState(
    initialiseDisplayColumns()
  );
  const [gridChanges, setGridChanges] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [returnedRecords, setReturnedRecords] = useState([]);
  const [savedOptions, setSavedOptions] = useState([]);
  const [selectedRecordIds, setSelectedRecordIds] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0);

  const [sortColumns, setSortColumns] = useState(
    initialiseSortColumns(sortByStartVal)
  );

  const [clearFilters, setClearFilters] = useState(false);

  const [showInvalid, setShowInvalid] = useState(false);

  // API CALLS

  const getAll = async (limit, page, query, sortBy, retry) => {
    setIsLoading(true);

    try {
      const params = buildApiParams(limit, page, query, sortBy);

      const data = await apiDataService.getAll({
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
        params,
      });

      const numberOfPages = Math.ceil(data.totalRecordCount / limit || 1);

      if (numberOfPages >= page) {
        handleApiReturn(data);
      } else {
        if (retry) {
          alert.error(
            handleApiError(
              customError('Please refresh the page and try again')
            ),
            { timeout: 10000 }
          );
          // there is an error with paging
        } else {
          setPage(numberOfPages);
          await getAll(limit, numberOfPages, query, sortBy, true);
        }
      }
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      if (!Object.keys(columnTypes).length) {
        if (selectedOptions?.id !== 'placeholder')
          handleChangeOptions({ target: { value: 'placeholder' } });

        alert.error(
          handleApiError(
            customError('URL query reset due to invalid parameters')
          ),
          { timeout: 10000 }
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const update = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    setIsLoading(true);

    const invalid = [];

    const gridChangesValues = Object.entries(gridChanges);

    gridChangesValues.forEach(([id, gridChange]) => {
      const gridChangeEntries = Object.entries(gridChange);

      gridChangeEntries.forEach((entry) => {
        const invalidTextArray = validationColumns?.[entry[0]]?.(
          entry[1],
          availableColumns[entry[0]],
          returnedRecords.find((record) => record.id === parseInt(id)),
          gridChange
        );

        if (invalidTextArray?.length) invalid.push(...invalidTextArray);
      });
    });

    if (invalid.length) {
      setShowInvalid(true);
      setIsLoading(false);

      alert.show(
        <ul>
          {removeDuplicates(invalid).map((msg, index) => (
            <li key={index}>
              <BulletPoint />

              <span>{msg}</span>
            </li>
          ))}
        </ul>,
        { timeout: 10000 }
      );

      return;
    } else setShowInvalid(false);

    try {
      await apiDataService.gridUpdate({
        gridChanges,
        portalId: demo ? 'demo' : portalId,
        reqBody: { userId: demo ? 1 : userId },
        token: demo ? undefined : await getAccessTokenSilently(),
        returnedRecords,
      });

      setGridChanges({});
      setSelectedRecordIds([]);

      await getAll(limit, page, query, sortBy);

      alert.success('Saved');

      if (columnsBeforeEditing.length)
        handleSetDisplayColumnsToDefault(columnsBeforeEditing);
      setIsEditing(false);
      setShowInvalid(false);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteMultiple = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (onDelete) {
      const shouldContinue = await onDelete(selectedRecordIds);

      if (!shouldContinue) return;
    }

    const selectedRecordIdsToCheckAfterDelete = selectedRecordIds;

    const confirmed = await portalConfirmAlert({
      message: 'Are you sure you want to delete?',
    });

    if (!confirmed) return;

    setIsLoading(true);

    try {
      await apiDataService.gridDeleteMultiple({
        selectedRecordIds,
        recordData: returnedRecords,
        portalId: demo ? 'demo' : portalId,
        reqBody: { userId: demo ? 1 : userId },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      const deleteCount = selectedRecordIds.length;

      if (Object.keys(gridChanges).length) setGridChanges({});
      setSelectedRecordIds([]);

      await getAll(limit, page, query, sortBy);

      alert.success(
        `${deleteCount} ${
          deleteCount > 1 ? gridNameTitle : gridNameTitle.slice(0, -1)
        } deleted`
      );

      if (columnsBeforeEditing.length)
        handleSetDisplayColumnsToDefault(columnsBeforeEditing);
      setIsEditing(false);
      setShowInvalid(false);

      await onAfterSuccessfulDelete?.(selectedRecordIdsToCheckAfterDelete);
    } catch (error) {
      if (Object.keys(gridChanges).length) setGridChanges({});
      setSelectedRecordIds([]);

      await getAll(limit, page, query, sortBy);

      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MainOnly>
      <GridContent
        availableColumns={availableColumns}
        clearFilters={clearFilters}
        columnsBeforeEditing={columnsBeforeEditing}
        columnTypes={columnTypes}
        customColumns={customColumns}
        defaultColumns={defaultColumns}
        defaultOptions={defaultOptions}
        deleteMultiple={deleteMultiple}
        demo={demo}
        displayColumns={displayColumns}
        excludeFromFilterPanel={excludeFromFilterPanel}
        gridChanges={gridChanges}
        gridName={gridName}
        gridNameSnake={gridNameSnake}
        gridNameTitle={gridNameTitle}
        gridNameLowerTitle={gridNameLowerTitle}
        handleChangeOptions={handleChangeOptions}
        handleClearSortOptions={handleClearSortOptions}
        handleSetDisplayColumnsToDefault={handleSetDisplayColumnsToDefault}
        initialiseSortColumns={initialiseSortColumns}
        iconName={iconName}
        isEditing={isEditing}
        isLoading={isLoading}
        limit={limit}
        newScreenRoute={newScreenRoute}
        noEditCanDelete={noEditCanDelete}
        noEditNew={noEditNew}
        noNew={noNew}
        noShow={noShow}
        onUpdateOptions={onUpdateOptions}
        page={page}
        query={query}
        returnedRecords={returnedRecords}
        requiredFields={requiredFields}
        savedOptions={savedOptions}
        selectedOptions={selectedOptions}
        selectedRecordIds={selectedRecordIds}
        selectFrom={selectFrom}
        setClearFilters={setClearFilters}
        setColumnsBeforeEditing={setColumnsBeforeEditing}
        setDisplayColumns={setDisplayColumns}
        setGridChanges={setGridChanges}
        setIsEditing={setIsEditing}
        setSavedOptions={setSavedOptions}
        setSelectedOptions={setSelectedOptions}
        setSelectedRecordIds={setSelectedRecordIds}
        setShowInvalid={setShowInvalid}
        setSortColumns={setSortColumns}
        showInvalid={showInvalid}
        showRecordProp={showRecordProp}
        sortBy={sortBy}
        sortColumns={sortColumns}
        toggledColumns={displayColumns.filter((column) => column.toggled)}
        totalRecordCount={totalRecordCount}
        unEditableColumns={gridUnEditableColumns}
        update={update}
        validationColumns={validationColumns}
        view={view}
      />
    </MainOnly>
  );
};

export default DataGrid;
