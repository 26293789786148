import ButtonIconOnly from '../../../Elements/ButtonIconOnly';
import FlexContainer from '../../../Elements/FlexContainer';

import './View.css';

const View = ({ handleChangeView, view }) => {
  return (
    <FlexContainer classes='grid-options-view' margin='5px 0'>
      <ButtonIconOnly
        marginLeft='10px'
        marginRight='10px'
        disabled={view === 'grid'}
        iconName='grid'
        onClick={() => handleChangeView('grid')}
        text='Grid'
        title='Grid'
      />
      <ButtonIconOnly
        marginRight='5px'
        disabled={view === 'table'}
        iconName='double-lines'
        onClick={() => handleChangeView('table')}
        text='Table'
        title='Table'
      />
    </FlexContainer>
  );
};

export default View;
