import { apiUrl, headers } from './config';

const gridOptionsApi = {
  deleteMultiple: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/grid-options`;

    const { data } = await apiUrl.patch(url, reqBody, headers({ token }));

    return data;
  },
  getAll: async ({ params, portalId, token, userId }) => {
    const baseUrl = `/api/portals/${portalId}/grid-options`;
    const urlParams = `${params.length ? `?${params.join('&')}` : ''}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },
  post: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/grid-options`;

    const { data } = await apiUrl.post(url, reqBody, headers({ token }));

    return data;
  },
};

export default gridOptionsApi;
