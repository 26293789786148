import Divider from '../Elements/Divider';
// import routeToPass from '../../utils/scoringAndFeedbackUtils/routeToPass';

import './Feedback.css';

const Feedback = ({
  qualityScore,
  passMark,
  categoryOnlyList,
  categoryScores,
  behaviourTotals,
  sectionResults,
}) => {
  const { weightOutOf, weightTotal, ra, csm } = behaviourTotals;

  const failedRaLength = sectionResults.failedRa.length;

  const lowestCsmSections = csm.filter((csmSec) => {
    return csmSec.csmAmount === csm[0].csmAmount;
  });
  const lowestCsmSectionsLength = lowestCsmSections.length;

  const weighPercent = Math.floor((weightTotal / weightOutOf) * 100);

  const subTotal = weighPercent - ra;

  const wasCsmUsed = csm.some(
    ({ csmAmount }) => parseInt(csmAmount) < subTotal
  );

  const filteredCatList = categoryOnlyList.filter((catObj) =>
    categoryScores.hasOwnProperty(`cat${catObj.id}`)
  );

  return (
    <div className='scorecard-main-results'>
      <div className='scorecard-qspf-and-cat-container'>
        <div className='scorecard-qspf-score-container'>
          <div
            className='scorecard-qspf-score-item'
            style={{ backgroundColor: '#edfcfd' }}
          >
            <p className='scorecard-qspf-score-header'>Quality Score</p>
            <p className='scorecard-qspf-score-amount'>
              {sectionResults.markedCount > 0 ? qualityScore : ''}
            </p>
          </div>

          {passMark || passMark === 0 ? (
            <div
              className='scorecard-qspf-score-item'
              style={{ backgroundColor: '#f7fceb' }}
            >
              <p className='scorecard-qspf-score-header'>Grade</p>
              <p className='scorecard-qspf-score-amount'>
                {sectionResults.markedCount > 0
                  ? qualityScore >= passMark
                    ? 'Pass'
                    : 'Fail'
                  : ''}
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>

        {filteredCatList.length ? (
          <div className='scorecard-cat-score-container'>
            {filteredCatList.map((catObj) => {
              const { id, title } = catObj;
              return (
                <div
                  key={id}
                  className='scorecard-cat-score-item'
                  style={{ backgroundColor: '#fbf2ff' }}
                >
                  <p className='scorecard-cat-score-header'>{title}</p>
                  <p className='scorecard-cat-score-amount'>
                    {categoryScores[`cat${catObj.id}`]}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>

      <h2 className='feedback-scores-breakdown-heading'>
        How your score has been calculated:
      </h2>

      {weightOutOf > 0 && (
        <div className='feedback-scores-breakdown-grid'>
          <p className='feedback-scores-breakdown-text'>
            Total from weighted sections:{' '}
          </p>
          <p className='feedback-scores-breakdown-amount'>
            {Math.floor((weightTotal / weightOutOf) * 100)}%
          </p>
          <p className='feedback-scores-breakdown-detail'>
            ({weightTotal} point{weightTotal === 1 ? '' : 's'} out of{' '}
            {weightOutOf})
          </p>
        </div>
      )}
      {ra > 0 && (
        <div className='feedback-scores-breakdown-grid'>
          <p className='feedback-scores-breakdown-text'>
            Total reduction amount (RA):{' '}
          </p>
          <p className='feedback-scores-breakdown-amount'>{ra}</p>
          <p className='feedback-scores-breakdown-detail'>
            (from {failedRaLength} section
            {failedRaLength === 1 ? '' : 's'})
          </p>
        </div>
      )}

      <Divider isLighter isThinner margin='0 0 20px 0' />

      {wasCsmUsed ? (
        <div className='feedback-scores-breakdown-grid'>
          <p className='feedback-scores-breakdown-text feedback-scores-bolder'>
            Subtotal:{' '}
          </p>
          <p className='feedback-scores-breakdown-amount feedback-scores-bolder'>
            {subTotal < 0 ? 0 : subTotal}
          </p>
        </div>
      ) : (
        <></>
      )}
      {wasCsmUsed ? (
        <>
          <div className='feedback-scores-breakdown-grid'>
            <p className='feedback-scores-breakdown-text'>
              Call Score Maximum (CSM):{' '}
            </p>
            <p className='feedback-scores-breakdown-amount'>
              {parseInt(csm[0].csmAmount)}
            </p>
            <p className='feedback-scores-breakdown-detail'>
              (from {lowestCsmSectionsLength} section
              {lowestCsmSectionsLength === 1 ? '' : 's'})
            </p>
          </div>

          <Divider isLighter isThinner margin='0 0 20px 0' />
        </>
      ) : (
        <></>
      )}

      {sectionResults.markedCount > 0 && (
        <div className='feedback-scores-breakdown-grid'>
          <p className='feedback-scores-breakdown-text feedback-scores-bolder'>
            Final score:{' '}
          </p>
          <p className='feedback-scores-breakdown-amount feedback-scores-bolder'>
            {qualityScore}
          </p>
        </div>
      )}
      {passMark && (
        <div className='feedback-scores-breakdown-grid'>
          <p className='feedback-scores-breakdown-text'>Pass mark: </p>
          <p className='feedback-scores-breakdown-amount'>{passMark}</p>
        </div>
      )}
      {/* {passMark && qualityScore < passMark && (
        <div>
          <h2 className='feedback-scores-breakdown-heading'>Quickest wins</h2>
          <p>{routeToPass(behaviourTotals, sectionResults, passMark)}</p>
        </div>
      )} */}
    </div>
  );
};

export default Feedback;
