import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import InfoText from '../../Elements/InfoText';
import InputStandard from '../../Elements/InputStandard';
import ReportsFocusDistinctDataList from './ReportsFocusDistinctDataList';

import './ReportsFocusDistinctDataDate.css';

const ReportsFocusDistinctDataDate = ({
  code,
  data,
  isSelectingFromList,
  sectionControl,
  sectionParameters,
  sectionTitle,
  setIsSelectingFromList,
  setReportParameters,
}) => {
  if (!data.length) return <InfoText text='No data' />;
  else
    return (
      <FlexContainer alignItems='stretch' flexDirection='column'>
        <ButtonStandardNoFill
          alignSelf='center'
          classes='focus-date-data-button-select'
          iconName={isSelectingFromList ? 'calendar' : 'bars'}
          margin='5px 0 10px'
          onClick={() => {
            setIsSelectingFromList((curr) => !curr);
          }}
          text={isSelectingFromList ? 'Use calendar' : 'Select from list'}
          toggled={isSelectingFromList}
        />

        {isSelectingFromList ? (
          <ReportsFocusDistinctDataList
            code={code}
            data={data}
            sectionControl={sectionControl}
            sectionParameters={sectionParameters}
            sectionTitle={sectionTitle}
            setReportParameters={setReportParameters}
          />
        ) : (
          <>
            <InputStandard
              classes={
                sectionParameters?.type === 'bet' &&
                sectionParameters?.params[0]
                  ? 'focus-selected-param-date'
                  : ''
              }
              direction='vertical'
              id='focus-date-select-from'
              labelText='From'
              margin='5px 0 0 0'
              onChange={(event) =>
                setReportParameters((curr) => {
                  const copiedCurr = { ...curr };

                  if (!copiedCurr[code] || copiedCurr[code].type === 'inc') {
                    copiedCurr[code] = {
                      params: [event.target.value, ''],
                      control: sectionControl,
                      title: sectionTitle,
                      type: 'bet',
                      invalid: true,
                    };

                    return copiedCurr;
                  }

                  if (!event.target.value) {
                    copiedCurr[code].params[0] = '';
                    copiedCurr[code].invalid = true;

                    return copiedCurr;
                  }

                  const copiedSectionParameters = { ...copiedCurr[code] };

                  if (
                    !copiedSectionParameters.params ||
                    !Array.isArray(copiedSectionParameters.params)
                  ) {
                    copiedSectionParameters.params = [event.target.value, ''];
                    copiedSectionParameters.invalid = true;

                    copiedCurr[code] = copiedSectionParameters;

                    return copiedCurr;
                  }

                  const copiedParams = [...copiedSectionParameters.params];
                  const newParams = [event.target.value, copiedParams[1]];

                  copiedSectionParameters.params = newParams;
                  copiedSectionParameters.invalid = !newParams[1];
                  copiedCurr[code] = copiedSectionParameters;

                  return copiedCurr;
                })
              }
              type='date'
              value={sectionParameters?.params[0] || ''}
            />

            <InputStandard
              classes={
                sectionParameters?.type === 'bet' &&
                sectionParameters?.params[1]
                  ? 'focus-selected-param-date'
                  : ''
              }
              direction='vertical'
              id='focus-date-select-to'
              labelText='To'
              margin='5px 0 0 0'
              onChange={(event) =>
                setReportParameters((curr) => {
                  const copiedCurr = { ...curr };

                  if (!copiedCurr[code] || copiedCurr[code].type === 'inc') {
                    copiedCurr[code] = {
                      params: ['', event.target.value],
                      control: sectionControl,
                      title: sectionTitle,
                      type: 'bet',
                      invalid: true,
                    };

                    return copiedCurr;
                  }

                  if (!event.target.value) {
                    copiedCurr[code].params[1] = '';
                    copiedCurr[code].invalid = true;

                    return copiedCurr;
                  }

                  const copiedSectionParameters = { ...copiedCurr[code] };

                  if (
                    !copiedSectionParameters.params ||
                    !Array.isArray(copiedSectionParameters.params)
                  ) {
                    copiedSectionParameters.params = ['', event.target.value];
                    copiedSectionParameters.invalid = true;

                    copiedCurr[code] = copiedSectionParameters;

                    return copiedCurr;
                  }

                  const copiedParams = [...copiedSectionParameters.params];
                  const newParams = [copiedParams[0], event.target.value];

                  copiedSectionParameters.params = newParams;
                  copiedSectionParameters.invalid = !newParams[0];
                  copiedCurr[code] = copiedSectionParameters;

                  return copiedCurr;
                })
              }
              type='date'
              value={sectionParameters?.params[1] || ''}
            />

            {(sectionParameters?.params[0] || sectionParameters?.params[1]) && (
              <ButtonStandardNoFill
                alignSelf='center'
                iconName='xmark'
                margin='15px 0 5px'
                onClick={() => {
                  setReportParameters((curr) => {
                    const copiedCurr = { ...curr };
                    const copiedSectionParameters = { ...copiedCurr[code] };

                    copiedSectionParameters.params = [];
                    copiedSectionParameters.invalid = true;

                    copiedCurr[code] = copiedSectionParameters;

                    return copiedCurr;
                  });
                }}
                text='Clear'
                typeStyle='type-2'
              />
            )}
          </>
        )}
      </FlexContainer>
    );
};

export default ReportsFocusDistinctDataDate;
