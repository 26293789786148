import { BreadcrumbsContext, UserPortal } from '../../../../contexts';
import {
  buildApiParams,
  getFilterCondition,
  mutateGridData,
  selectOrDeselectArrayValue,
  sortAlphaCaseInsensitive,
} from '../../../../utils/helpers';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';

import ButtonIconOnly from '../../../Elements/ButtonIconOnly';
import ButtonStandardNoFill from '../../../Elements/ButtonStandardNoFill';
import Columns from '../GridOptions/Columns';
import extractUrlQuery from '../../../../utils/urlQuery/extractUrlQuery';
import Filter from '../GridOptions/Filter';
import FlexContainer from '../../../Elements/FlexContainer';
import GridOption from './GridOption';
import gridOptions from '../../../../api/grid-options';
import GridRow from './GridRow';
import HeaderPrimary from '../../../Elements/HeaderPrimary';
import HeaderSecondary from '../../../Elements/HeaderSecondary';
import InfoText from '../../../Elements/InfoText';
import LoadingSpinnerNew from '../../../Elements/LoadingSpinnerNew';
import ManageOptions from '../GridOptions/ManageOptions';
import Records from '../GridOptions/Records';
import ReportsAllPortalReports from '../../Reports/ReportsAllPortalReports';
import SelectStandard from '../../../Elements/SelectStandard';
import Sort from '../GridOptions/Sort';
import View from '../GridOptions/View';

import './GridContent.css';

const GridContent = ({
  availableColumns,
  clearFilters,
  columnsBeforeEditing,
  columnTypes,
  customColumns,
  defaultColumns,
  deleteMultiple,
  demo,
  displayColumns,
  excludeFromFilterPanel,
  gridChanges,
  gridName,
  gridNameSnake,
  gridNameTitle,
  gridNameLowerTitle,
  handleSetDisplayColumnsToDefault,
  newScreenRoute,
  handleChangeOptions,
  handleClearSortOptions,
  iconName,
  initialiseSortColumns,
  isEditing,
  isLoading,
  limit,
  noEditCanDelete,
  noEditNew,
  noNew,
  noShow,
  onUpdateOptions,
  page,
  query,
  returnedRecords,
  requiredFields,
  savedOptions,
  selectedOptions,
  selectedRecordIds,
  selectFrom,
  setClearFilters,
  setColumnsBeforeEditing,
  setDisplayColumns,
  setGridChanges,
  setIsEditing,
  setSavedOptions,
  setSelectedOptions,
  setSelectedRecordIds,
  setShowInvalid,
  setSortColumns,
  showInvalid,
  sortBy,
  sortColumns,
  showRecordProp,
  toggledColumns,
  totalRecordCount,
  unEditableColumns,
  update,
  validationColumns,
  view,
}) => {
  const { setBreadcrumbs } = useContext(BreadcrumbsContext);

  const getExportData = () => {
    return returnedRecords.map((record) =>
      Object.fromEntries(
        Object.entries(record)
          .filter(([key]) => toggledColumns.map((col) => col.id).includes(key))
          .map((data) => {
            return mutateGridData(data);
          })
      )
    );
  };

  const handleClickToEdit = () => {
    if (isEditing) {
      if (Object.keys(gridChanges).length) setGridChanges({});
      if (selectedRecordIds.length) setSelectedRecordIds([]);

      if (columnsBeforeEditing.length)
        handleSetDisplayColumnsToDefault(columnsBeforeEditing);
    } else {
      setManageOptions(false);

      if (!noEditCanDelete) {
        const columnsDisplayed = toggledColumns.map((column) => column.id);

        const columnsToToggle = Object.keys(availableColumns).filter(
          (column) =>
            ![...unEditableColumns, ...columnsDisplayed].includes(column)
        );

        setColumnsBeforeEditing(columnsDisplayed);

        handleSetDisplayColumnsToDefault([
          ...columnsDisplayed,
          ...columnsToToggle.sort((a, b) =>
            sortAlphaCaseInsensitive(availableColumns[a], availableColumns[b])
          ),
        ]);
      }
    }

    setIsEditing((curr) => !curr);
    setShowInvalid(false);
  };

  const handleSelectAll = () => {
    if (selectedRecordIds.length === returnedRecords.length) {
      setSelectedRecordIds([]);
      setGridChanges({});
    } else {
      setSelectedRecordIds(
        returnedRecords.map((record) =>
          gridName === 'drafts' ? `${record.type}-${record.id}` : record.id
        )
      );
    }
  };

  const handleUndoAll = () => {
    setGridChanges({});
  };

  const handleSelectOne = (recordId) => {
    setSelectedRecordIds((curr) => {
      const selectedRecordIndex = curr.indexOf(recordId);

      const newArr = [...curr];

      if (selectedRecordIndex !== -1) {
        newArr.splice(selectedRecordIndex, 1);

        if (gridChanges[recordId]) removeRecordFromGridChanges(recordId);

        return newArr;
      } else {
        newArr.push(recordId);

        return newArr;
      }
    });
  };

  const removeRecordFromGridChanges = (recordId) => {
    setGridChanges((curr) => {
      const newGridChanges = { ...curr };

      newGridChanges[recordId] = { ...curr[recordId] };

      delete newGridChanges[recordId];

      return newGridChanges;
    });
  };

  const handleUndoOne = (recordId) => {
    removeRecordFromGridChanges(recordId);
  };

  const onSetGridChanges = (
    id,
    column,
    newValue,
    originalValue,
    columnType
  ) => {
    setGridChanges((curr) => {
      // rowAlreadyChanged = true if the row id exists in the gridChanges object
      const rowAlreadyChanged = !!curr[id];

      // itemAlreadyChanged = true if the column name exists in the gridChanges.id object
      const itemAlreadyChanged = rowAlreadyChanged
        ? Object.keys(curr[id]).includes(column)
        : false;

      // rowHasOtherChangedItems = true if the row has changes to other columns
      const rowHasOtherChangedItems = rowAlreadyChanged
        ? Object.keys(curr[id]).filter((rowKey) => rowKey !== column).length
        : false;

      const newGridChanges = { ...curr };

      if (rowAlreadyChanged) newGridChanges[id] = { ...curr[id] };

      const valuesAreSame =
        columnType === 'array'
          ? newValue?.join() === originalValue?.join()
          : newValue === originalValue;

      if (itemAlreadyChanged && valuesAreSame) {
        if (rowHasOtherChangedItems) delete newGridChanges[id][column];
        else delete newGridChanges[id];
      } else if (!valuesAreSame) {
        if (rowAlreadyChanged) newGridChanges[id][column] = newValue;
        else newGridChanges[id] = { [column]: newValue };
      }

      return newGridChanges;
    });
  };

  const handleUpdateGridChanges = (
    id,
    column,
    newValue,
    originalValue,
    isAppliedToAll,
    columnType
  ) => {
    if (isAppliedToAll) {
      selectedRecordIds.forEach((id) => {
        onSetGridChanges(
          id,
          column,
          newValue,
          returnedRecords.filter((record) => record.id === id)[0][column],
          columnType
        );
      });
    } else onSetGridChanges(id, column, newValue, originalValue, columnType);
  };

  const handleApplyToAll = (column) => {
    setApplyToAll((curr) => {
      return selectOrDeselectArrayValue(column, curr);
    });
  };

  const handleAddRecord = () => {
    if (newScreenRoute) {
      // navigate to new screen
    } else {
      if (!isAddingNew) setIsAddingNew(true);
    }
  };

  const [applyToAll, setApplyToAll] = useState([]);

  const [isAddingNew, setIsAddingNew] = useState(false);

  const [savedQuery, setSavedQuery] = useState(query);

  const [showGridOption, setShowGridOption] = useState('');

  const [noOfFilters, setNoOfFilters] = useState(0);

  const [numberOfPages, setNumberOfPages] = useState();

  const masterFilterInitialState = query
    ? extractUrlQuery(query)
    : { uniqueRef: 1, and: [] };

  const [masterFilter, setMasterFilter] = useState(masterFilterInitialState);

  const [isSavingOptions, setIsSavingOptions] = useState(false);
  const [manageOptions, setManageOptions] = useState(false);

  const handleChangeView = (newView) => {
    if (newView === 'grid') {
      const headerColumnIsCorrect =
        displayColumns[0].id === defaultColumns[0] && displayColumns[0].toggled;

      if (!headerColumnIsCorrect) {
        setDisplayColumns((curr) => {
          const newColumnOrder = [...curr];

          const mainColumnIndex = newColumnOrder.findIndex(
            (columnObj) => columnObj.id === defaultColumns[0]
          );

          const newMainColumnObj = Object.assign(
            ...newColumnOrder.splice(mainColumnIndex, 1),
            { toggled: true }
          );

          newColumnOrder.unshift(newMainColumnObj);

          return newColumnOrder;
        });
      }
    }

    onUpdateOptions('view', newView);
  };

  useEffect(() => {
    setNumberOfPages(Math.ceil(totalRecordCount / limit || 1));
  }, [totalRecordCount, limit]);

  useEffect(() => {
    if (selectedRecordIds.length < 2) {
      if (applyToAll.length) setApplyToAll([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecordIds]);

  const handleChangedAvailableColumns = (currentColumnObjectArray) => {
    const newToggledColumns = currentColumnObjectArray
      .filter(
        (columnObj) => columnObj.toggled && availableColumns[columnObj.id]
      )
      .map((columnObj, index) => {
        const newColumnObj = { ...columnObj, order: index + 1 };

        if (!columnObj.value)
          newColumnObj.value = availableColumns[columnObj.id];

        return newColumnObj;
      });

    const newNonToggledColumns = Object.keys(availableColumns)
      .filter(
        (availableColumn) =>
          !newToggledColumns.some(
            (columnObj) => columnObj.id === availableColumn
          )
      )
      .map((availableColumn) => ({
        id: availableColumn,
        direction: '',
        order: null,
        toggled: false,
        value: availableColumns[availableColumn],
      }))
      .sort((a, b) => sortAlphaCaseInsensitive(a.value, b.value));

    return [...newToggledColumns, ...newNonToggledColumns];
  };

  useEffect(() => {
    if (Object.keys(availableColumns).length) {
      setSortColumns((curr) => {
        return handleChangedAvailableColumns(curr);
      });

      setDisplayColumns((curr) => {
        return handleChangedAvailableColumns(curr);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableColumns]);

  useEffect(() => {
    if (selectedOptions) {
      const { customColumns, query, sortBy } = selectedOptions.options;

      handleSetDisplayColumnsToDefault(
        customColumns ? customColumns.split(',,') : defaultColumns
      );
      setSortColumns(
        handleChangedAvailableColumns(initialiseSortColumns(sortBy))
      );
      setSavedQuery(query || '');

      if (
        query === null &&
        masterFilter[getFilterCondition(masterFilter)]?.length
      )
        setClearFilters(true);

      onUpdateOptions('saved-options', selectedOptions.options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;
  const hasChangePermission =
    demo ||
    gridNameSnake === 'drafts' ||
    userFromDb.permissions?.includes(`${gridNameSnake}.change`);

  useEffect(() => {
    setBreadcrumbs([
      ['Dashboard', 'dashboard', '', `/${demo ? 'demo' : portalId}/dashboard`],
      [gridNameTitle, iconName],
    ]);

    getAll().then((returnBody) => {
      setSavedOptions(returnBody.gridOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getAccessTokenSilently } = useAuth0();

  const deleteMultipleOptions = async (reqBody) => {
    if (demo) return alert.info('Unable to save changes in demo');

    await gridOptions.deleteMultiple({
      portalId: demo ? 'demo' : portalId,
      reqBody,
      token: demo ? undefined : await getAccessTokenSilently(),
    });
  };

  const getAll = async () => {
    const data = await gridOptions.getAll({
      portalId: demo ? 'demo' : portalId,
      userId: demo ? 1 : userId,
      token: demo ? undefined : await getAccessTokenSilently(),
      params: buildApiParams(
        null,
        null,
        `((grid_name==eq::${gridNameSnake}))`,
        null
      ),
      // add to query to return only ones created by this user
    });

    return data;
  };

  const post = async (reqBody) => {
    if (demo) return alert.info('Unable to save changes in demo');

    await gridOptions.post({
      portalId: demo ? 'demo' : portalId,
      reqBody,
      token: demo ? undefined : await getAccessTokenSilently(),
    });
  };

  const sortDisabledDueToEditingText = 'Unable to change sort while editing';
  const filterDisabledDueToEditingText =
    'Unable to change filter while editing';
  const columnsDisabledDueToEditingText =
    'Unable to toggle editable columns while editing';
  const recordsDisabledDueToEditingText =
    'Unable to change records while editing';
  const selectOptionsDisabledDueToEditingText =
    'Unable to change options while editing';

  return (
    <div className='grid-background'>
      <div className='data-grid-control-bar'>
        {isEditing || isLoading ? (
          <ButtonStandardNoFill
            disabled
            title={
              isEditing ? `Unable to export data while editing` : undefined
            }
            classes='grid-control-bar-button'
            iconName='cloud-download'
            onClick={() => {}}
            text='Export'
          />
        ) : (
          <CSVLink
            className='export-data-grid-link'
            data={getExportData()}
            filename={gridNameSnake}
            headers={toggledColumns.map((toggledColumn) => ({
              label: toggledColumn.value,
              key: toggledColumn.id,
            }))}
            target='_blank'
          >
            <ButtonStandardNoFill
              classes='grid-control-bar-button'
              iconName='cloud-download'
              onClick={() => {}}
              text='Export'
            />
          </CSVLink>
        )}

        {!noEditNew && hasChangePermission ? (
          <>
            <FlexContainer
              classes='data-grid-control-bar-edit-buttons'
              justifyContent='flex-start'
            >
              {!noEditCanDelete && (
                <ButtonStandardNoFill
                  classes={`grid-control-bar-button ${
                    isEditing
                      ? ''
                      : 'cancel-transition-duration data-grid-control-hide-button'
                  }`}
                  disabled={!Object.keys(gridChanges).length || isLoading}
                  iconName='save-solid'
                  onClick={update}
                  text='Save Changes'
                  typeStyle='type-4'
                />
              )}

              <ButtonStandardNoFill
                classes={`grid-control-bar-button ${
                  isEditing
                    ? ''
                    : 'cancel-transition-duration data-grid-control-hide-button'
                }`}
                disabled={!selectedRecordIds.length || isLoading}
                iconName='trash'
                onClick={deleteMultiple}
                text='Delete Selected'
                typeStyle='type-5'
              />

              <ButtonStandardNoFill
                classes='grid-control-bar-button'
                disabled={isLoading}
                iconName='edit-solid'
                onClick={handleClickToEdit}
                text='Edit'
                typeStyle={isEditing ? 'type-3' : 'type-1'}
              />

              {!noNew && (
                <Link to={newScreenRoute || ''}>
                  <ButtonStandardNoFill
                    disabled={isEditing || isLoading}
                    title={
                      isEditing
                        ? `Unable to add new records while editing`
                        : undefined
                    }
                    classes='grid-control-bar-button'
                    iconName='circle-plus'
                    onClick={handleAddRecord}
                    text='New'
                  />
                </Link>
              )}
            </FlexContainer>
          </>
        ) : (
          <></>
        )}
      </div>

      <HeaderPrimary text={gridNameTitle} margin='30px 20px' />

      {gridName === 'reports' &&
      (demo ||
        userFromDb.permissions?.includes('reports.focus') ||
        userFromDb.permissions?.includes('reports.hot_topics') ||
        userFromDb.permissions?.includes('reports.risk') ||
        userFromDb.permissions?.includes('reports.qa') ||
        userFromDb.permissions?.includes('reports.calibrations')) ? (
        <ReportsAllPortalReports
          demo={demo}
          portalId={portalId}
          userFromDb={userFromDb}
        />
      ) : (
        <></>
      )}

      <FlexContainer
        flexDirection='column'
        alignItems='stretch'
        classes='grid-panel grid-filters'
        marginBottom={
          masterFilter[getFilterCondition(masterFilter)]?.length > 0 ||
          sortColumns.filter((columnObj) => columnObj.toggled).length > 0 ||
          customColumns !== null
            ? '10px'
            : '20px'
        }
      >
        <FlexContainer classes='grid-options' justifyContent='space-between'>
          <FlexContainer
            justifyContent='center'
            flexWrap='wrap'
            marginLeft='5px'
          >
            {!excludeFromFilterPanel?.includes('filter') && (
              <GridOption
                headerText='Filter'
                iconName='filters'
                showGridOption={showGridOption}
                setShowGridOption={setShowGridOption}
                gridOption={'filter'}
                setManageOptions={setManageOptions}
              />
            )}

            {!excludeFromFilterPanel?.includes('sort') && (
              <GridOption
                headerText='Sort'
                iconName='sort'
                showGridOption={showGridOption}
                setShowGridOption={setShowGridOption}
                gridOption={'sort'}
                setManageOptions={setManageOptions}
              />
            )}

            <GridOption
              headerText='Columns'
              iconName='table-columns'
              showGridOption={showGridOption}
              setShowGridOption={setShowGridOption}
              gridOption={'columns'}
              setManageOptions={setManageOptions}
            />

            {!excludeFromFilterPanel?.includes('records') && (
              <GridOption
                headerText='Records'
                iconName='scroll'
                showGridOption={showGridOption}
                setShowGridOption={setShowGridOption}
                gridOption={'records'}
                setManageOptions={setManageOptions}
              />
            )}

            <View handleChangeView={handleChangeView} view={view} />
          </FlexContainer>

          {!excludeFromFilterPanel?.includes('manageOptions') && (
            <FlexContainer classes='choose-options-container' flexWrap='wrap'>
              <SelectStandard
                containerClasses='saved-options-container'
                selectContainerClasses='saved-options-select'
                onChange={handleChangeOptions}
                disabled={isSavingOptions || manageOptions || isEditing}
                options={[
                  ['placeholder', '< Saved Options >'],
                  ['default', 'Default'],
                  ...savedOptions.map((savedOptions) => [
                    savedOptions.id,
                    savedOptions.options_name,
                  ]),
                ]}
                title={
                  isEditing ? selectOptionsDisabledDueToEditingText : undefined
                }
                value={selectedOptions?.id || 'placeholder'}
              />
              <ButtonIconOnly
                margin={'5px'}
                classes={`manage-options-button ${
                  manageOptions ? 'managing-options' : ''
                }`}
                disabled={isSavingOptions || isEditing}
                iconName='wrench'
                onClick={() => {
                  setManageOptions((curr) => !curr);
                  setShowGridOption('manageOptions');
                }}
                title={
                  isEditing
                    ? selectOptionsDisabledDueToEditingText
                    : 'Manage options'
                }
              />
            </FlexContainer>
          )}
        </FlexContainer>

        <Filter
          availableColumns={availableColumns}
          columnTypes={columnTypes}
          disabledDueToEditingText={filterDisabledDueToEditingText}
          isEditing={isEditing}
          noOfFilters={noOfFilters}
          onUpdateOptions={onUpdateOptions}
          query={query}
          savedQuery={savedQuery}
          selectFrom={selectFrom}
          setSavedQuery={setSavedQuery}
          showFilter={showGridOption === 'filter'}
          clearFilters={clearFilters}
          setClearFilters={setClearFilters}
          setNoOfFilters={setNoOfFilters}
          masterFilterInitialState={masterFilterInitialState}
          masterFilter={masterFilter}
          setMasterFilter={setMasterFilter}
        />

        <Sort
          handleClearSortOptions={handleClearSortOptions}
          disabledDueToEditingText={sortDisabledDueToEditingText}
          isEditing={isEditing}
          onUpdateOptions={onUpdateOptions}
          setSortColumns={setSortColumns}
          showSort={showGridOption === 'sort'}
          sortBy={sortBy}
          sortColumns={sortColumns}
        />

        <Columns
          customColumns={customColumns}
          showColumns={showGridOption === 'columns'}
          defaultColumns={defaultColumns}
          disabledDueToEditingText={columnsDisabledDueToEditingText}
          displayColumns={displayColumns}
          handleSetDisplayColumnsToDefault={handleSetDisplayColumnsToDefault}
          isEditing={isEditing}
          onUpdateOptions={onUpdateOptions}
          setDisplayColumns={setDisplayColumns}
          unEditableColumns={unEditableColumns}
          view={view}
        />

        <Records
          disabledDueToEditingText={recordsDisabledDueToEditingText}
          isEditing={isEditing}
          limit={limit}
          numberOfPages={numberOfPages}
          onUpdateOptions={onUpdateOptions}
          page={page}
          showRecords={showGridOption === 'records'}
        />

        {manageOptions && (
          <ManageOptions
            demo={demo}
            deleteMultiple={deleteMultipleOptions}
            getAll={getAll}
            savedOptions={savedOptions}
            setSavedOptions={setSavedOptions}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            setIsSavingOptions={setIsSavingOptions}
            isSavingOptions={isSavingOptions}
            gridNameSnake={gridNameSnake}
            page={page}
            limit={limit}
            query={query}
            sortBy={sortBy}
            customColumns={customColumns}
            defaultColumns={defaultColumns}
            post={post}
            view={view}
          />
        )}
      </FlexContainer>

      <FlexContainer
        padding='1px'
        marginTop='4px'
        justifyContent='center'
        flexWrap='wrap'
      >
        {masterFilter[getFilterCondition(masterFilter)]?.length > 0 && (
          <ButtonStandardNoFill
            classes='grid-filter-clear-button'
            text={`Clear filter${noOfFilters ? ` (${noOfFilters})` : ''}`}
            title={isEditing ? filterDisabledDueToEditingText : undefined}
            type='button'
            disabled={
              !masterFilter[getFilterCondition(masterFilter)]?.length ||
              isEditing
            }
            iconName='filters'
            subIconName='xmark'
            onClick={() => setClearFilters(true)}
          />
        )}

        {sortColumns.filter((columnObj) => columnObj.toggled).length > 0 && (
          <ButtonStandardNoFill
            classes='grid-filter-clear-button'
            title={isEditing ? sortDisabledDueToEditingText : undefined}
            text={`Clear sort${
              sortColumns.filter((columnObj) => columnObj.toggled).length
                ? ` (${
                    sortColumns.filter((columnObj) => columnObj.toggled).length
                  })`
                : ''
            }`}
            type='button'
            disabled={
              !sortColumns.filter((columnObj) => columnObj.toggled).length ||
              isEditing
            }
            iconName='sort'
            subIconName='xmark'
            onClick={handleClearSortOptions}
          />
        )}

        {customColumns !== null && (
          <ButtonStandardNoFill
            classes='grid-filter-clear-button'
            text='Default columns'
            type='button'
            disabled={customColumns === null || isEditing}
            title={isEditing ? columnsDisabledDueToEditingText : undefined}
            typeStyle='type-2'
            iconName='table-columns'
            onClick={() => handleSetDisplayColumnsToDefault(defaultColumns)}
          />
        )}
      </FlexContainer>

      {isEditing && view === 'grid' && (
        <FlexContainer
          classes='grid-content-select-undo-all-button-container'
          margin='5px'
        >
          <ButtonIconOnly
            classes='grid-content-select-undo-all-button'
            disabled={isLoading || !returnedRecords.length}
            iconName={
              selectedRecordIds.length === returnedRecords.length &&
              selectedRecordIds.length
                ? 'square-tick-solid'
                : 'square-tick-regular'
            }
            onClick={handleSelectAll}
            title={
              selectedRecordIds.length === returnedRecords.length &&
              selectedRecordIds.length
                ? 'De-select all'
                : 'Select all'
            }
            typeStyle='type-4'
          />
          {!noEditCanDelete && (
            <ButtonIconOnly
              classes='grid-content-select-undo-all-button'
              disabled={!Object.keys(gridChanges).length || isLoading}
              iconName='undo'
              onClick={handleUndoAll}
              title='Undo all changes'
              typeStyle='type-4'
            />
          )}
        </FlexContainer>
      )}

      {isLoading ? (
        <LoadingSpinnerNew />
      ) : (
        returnedRecords.length > 0 &&
        toggledColumns.length > 0 && (
          <FlexContainer
            classes={`grid-panel grid-data ${
              view === 'grid' ? 'grid-view-grid-table' : ''
            }`}
            flexDirection='column'
            alignItems='stretch'
            marginTop={
              masterFilter[getFilterCondition(masterFilter)]?.length > 0 ||
              sortColumns.filter((columnObj) => columnObj.toggled).length > 0 ||
              customColumns !== null
                ? view === 'grid'
                  ? '0'
                  : '10px'
                : view === 'grid'
                ? '10px'
                : '20px'
            }
          >
            <div className={`table-container table-container-${view}`}>
              <ul className={view === 'grid' ? 'grid' : 'table'}>
                {view === 'table' && (
                  <li className='header-row'>
                    {isEditing && (
                      <>
                        {!noEditCanDelete && (
                          <div className='header-cell'>
                            <ButtonIconOnly
                              classes={` cancel-click-record grid-edit-icon-${view}`}
                              iconClasses='grid-content-select-all-icon'
                              disabled={
                                !Object.keys(gridChanges).length || isLoading
                              }
                              iconName='undo'
                              onClick={handleUndoAll}
                              title='Undo all changes'
                              typeStyle='type-4'
                            />
                          </div>
                        )}

                        <div className='header-cell'>
                          <ButtonIconOnly
                            classes={`grid-edit-icon-${view}`}
                            iconClasses='grid-content-select-all-icon'
                            disabled={isLoading || !returnedRecords.length}
                            iconName={
                              selectedRecordIds.length ===
                                returnedRecords.length &&
                              selectedRecordIds.length
                                ? 'square-tick-solid'
                                : 'square-tick-regular'
                            }
                            onClick={handleSelectAll}
                            title={
                              selectedRecordIds.length ===
                                returnedRecords.length &&
                              selectedRecordIds.length
                                ? 'De-select all'
                                : 'Select all'
                            }
                            typeStyle='type-4'
                          />
                        </div>
                      </>
                    )}

                    {toggledColumns.map((column) => (
                      <HeaderSecondary
                        key={column.id}
                        classes='header-cell'
                        text={column.value}
                      />
                    ))}
                  </li>
                )}

                {returnedRecords.map((record, index) => (
                  <GridRow
                    firstRow={index === 0}
                    valueFromGridChanges={gridChanges[record.id]}
                    key={
                      gridName === 'drafts'
                        ? `${record.type}-${record.id}`
                        : record.id
                    }
                    applyToAll={applyToAll}
                    canApplyToAll={selectedRecordIds.length > 1}
                    availableColumns={availableColumns}
                    columns={toggledColumns.map((column) => column.id)}
                    columnTypes={columnTypes}
                    demo={demo}
                    handleApplyToAll={handleApplyToAll}
                    handleSelectOne={handleSelectOne}
                    handleUndoOne={handleUndoOne}
                    handleUpdateGridChanges={handleUpdateGridChanges}
                    isLoading={isLoading}
                    noEditCanDelete={noEditCanDelete}
                    firstColumn={displayColumns[0].id}
                    gridName={gridName}
                    mainColumn={defaultColumns[0]}
                    noShow={noShow}
                    isEditing={isEditing}
                    isSelected={selectedRecordIds.includes(
                      gridName === 'drafts'
                        ? `${record.type}-${record.id}`
                        : record.id
                    )}
                    record={record}
                    requiredFields={requiredFields}
                    selectFrom={selectFrom}
                    showInvalid={showInvalid}
                    showRecordProp={showRecordProp}
                    unEditableColumns={unEditableColumns}
                    validationColumns={validationColumns}
                    view={view}
                  />
                ))}
              </ul>
            </div>
          </FlexContainer>
        )
      )}

      <FlexContainer
        classes={`grid-panel grid-info grid-info-${view}`}
        flexDirection='column'
        alignItems='stretch'
        marginTop={
          returnedRecords.length
            ? '0'
            : masterFilter[getFilterCondition(masterFilter)]?.length > 0 ||
              sortColumns.filter((columnObj) => columnObj.toggled).length > 0 ||
              customColumns !== null
            ? '10px'
            : '20px'
        }
      >
        <div className='table-info'>
          {toggledColumns.length ? (
            <InfoText
              classes='data-grid-info-label'
              text={
                isLoading
                  ? `Loading ${gridNameLowerTitle} ...`
                  : returnedRecords.length
                  ? `Displaying ${page * limit - limit + 1} to ${Math.min(
                      page * limit,
                      totalRecordCount
                    )} of ${totalRecordCount} ${gridNameLowerTitle.slice(
                      0,
                      -1
                    )}${returnedRecords.length === 1 ? '' : 's'}${
                      query ? ' (filtered)' : ''
                    }`
                  : `No ${gridNameLowerTitle} to display${
                      query ? ' (filtered)' : ''
                    }`
              }
            />
          ) : (
            <InfoText
              classes='data-grid-info-label'
              text='No columns selected'
              type='warning'
            />
          )}

          {isEditing && (
            <InfoText
              classes='data-grid-records-selected-count'
              text={`${selectedRecordIds.length} selected`}
            />
          )}
        </div>
      </FlexContainer>
    </div>
  );
};

export default GridContent;
