import InputInvalidIcon from './InputInvalidIcon';
import InputRequiredIcon from './InputRequiredIcon';

const InputValidityIcon = ({
  isInvalid,
  isRequired,
  right = '10',
  showInvalid,
  top,
}) => {
  if (isInvalid && showInvalid)
    return <InputInvalidIcon right={right} top={top} />;
  else {
    if (isRequired) return <InputRequiredIcon right={right} top={top} />;
  }

  return <></>;
};

export default InputValidityIcon;
