import { Link } from 'react-router-dom';

import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';

import './SwitchPortal.css';

const SwitchPortal = ({ userFromDb }) => {
  return (
    <FlexContainer alignItems='stretch' flexDirection='column'>
      <HeaderSecondary
        margin='0 30px'
        text='Switch Portal'
        textDecoration='underline'
      />

      <FlexContainer
        alignItems='stretch'
        classes='switch-portal-container'
        flexDirection='column'
        justifyContent='flex-start'
      >
        {userFromDb.other_portals.map((portal) => (
          <Link
            className='switch-portal-card'
            key={portal.id}
            state={{ isNavigating: true }}
            to={`/${portal.id}`}
          >
            <FlexContainer
              classes='grid-card'
              justifyContent='flex-start'
              padding='10px 5px'
            >
              <div className='account-logo-img-container'>
                <img
                  className='create-logo-img'
                  src={portal.logo_url}
                  alt='Logo'
                />
              </div>

              <HeaderSecondary text={portal.org} />
            </FlexContainer>
          </Link>
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

export default SwitchPortal;
