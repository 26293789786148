export const rolesTranslations = {
  admin: 'Admin',
  qa: 'QA',
  manager: 'Manager',
  agent: 'Agent',
};
export const rolesOptions = [
  ['admin', 'Admin'],
  ['qa', 'QA'],
  ['manager', 'Manager'],
  ['agent', 'Agent'],
];
export const tiers = {
  admin: 'qa',
  qa: 'qa',
  manager: 'manager',
  agent: 'agent',
};

export const prices = {
  qa: '10',
  manager: '7',
  agent: '4',
};

export const iconsList = [
  { name: 'Arrow Back', code: 'undo' },
  { name: 'Arrow Down', code: 'sort-down' },
  { name: 'Arrow Left', code: 'circle-left' },
  { name: 'Arrow Right', code: 'circle-right' },
  { name: 'Arrow Up', code: 'sort-up' },
  { name: 'Arrow Up Right', code: 'arrow-up-right-from-square' },
  { name: 'Arrows Down', code: 'arrows-down' },
  { name: 'Arrows Up', code: 'arrows-up' },
  { name: 'Arrows Focus', code: 'arrows-to-dot' },
  { name: 'Asterisk', code: 'asterisk' },
  { name: 'Bars', code: 'bars' },
  { name: 'Book', code: 'book-open' },
  { name: 'Boxes', code: 'dashboard' },
  { name: 'Calendar', code: 'calendar' },
  { name: 'Circle', code: 'circle-solid' },
  { name: 'Clipboard', code: 'clipboard' },
  { name: 'Credit Card', code: 'credit-card' },
  { name: 'Cross', code: 'xmark' },
  { name: 'Database', code: 'database' },
  { name: 'Dot', code: 'circle-dot-thin' },
  { name: 'Disc', code: 'save-solid' },
  { name: 'Download', code: 'cloud-download' },
  { name: 'Draft', code: 'draft' },
  { name: 'Ellipsis', code: 'ellipsis' },
  { name: 'Ellipsis Vertical', code: 'ellipsis-vertical' },
  { name: 'Envelope', code: 'envelope' },
  { name: 'Exclamation Mark', code: 'circle-exclamation' },
  { name: 'Eye', code: 'eye' },
  { name: 'File', code: 'templates' },
  { name: 'Filter', code: 'filters' },
  { name: 'Flame', code: 'fire' },
  { name: 'Gauge', code: 'gauge' },
  { name: 'Gear', code: 'gear' },
  { name: 'Grid', code: 'grid' },
  { name: 'Headset', code: 'headset' },
  { name: 'Import', code: 'file-import' },
  { name: 'Info', code: 'circle-info' },
  { name: 'Install', code: 'install' },
  { name: 'Lightning', code: 'bolt' },
  { name: 'Lines', code: 'double-lines' },
  { name: 'Link', code: 'link' },
  { name: 'List Item', code: 'list-ul-single' },
  { name: 'List Items', code: 'list-ul' },
  { name: 'List Numbers', code: 'list-ol' },
  { name: 'List Ticks', code: 'scorecards' },
  { name: 'Lock', code: 'locked' },
  { name: 'Magnifying Glass', code: 'magnifying-glass' },
  { name: 'Minus', code: 'minus' },
  { name: 'Navigate', code: 'navigation' },
  { name: 'One', code: 'one' },
  { name: 'Organisation', code: 'sitemap' },
  { name: 'Palette', code: 'palette' },
  { name: 'Pen', code: 'pen' },
  { name: 'PDF', code: 'pdf' },
  { name: 'Phone', code: 'phone' },
  { name: 'Pie Chart', code: 'chart-pie' },
  { name: 'Plane', code: 'plane' },
  { name: 'Plus', code: 'plus' },
  { name: 'Print', code: 'print' },
  { name: 'Question Mark', code: 'question' },
  { name: 'Ranking', code: 'ranking-star' },
  { name: 'Scroll', code: 'scroll' },
  { name: 'Share', code: 'share' },
  { name: 'Sign', code: 'sign' },
  { name: 'Sliders', code: 'sliders' },
  { name: 'Table', code: 'table-columns' },
  { name: 'Tag', code: 'tag' },
  { name: 'Target', code: 'bullseye' },
  { name: 'Three', code: 'three' },
  { name: 'Thumbs Up', code: 'thumbs-up' },
  { name: 'Tick', code: 'square-tick-regular' },
  { name: 'Trash', code: 'trash' },
  { name: 'Two', code: 'two' },
  { name: 'Unlink', code: 'unlink' },
  { name: 'Unlock', code: 'unlocked' },
  { name: 'Up/Down Arrows', code: 'sort' },
  { name: 'User', code: 'user-solid' },
  { name: 'User Tick', code: 'evaluations' },
  { name: 'Users', code: 'users' },
  { name: 'Wrench', code: 'wrench' },
  { name: 'Write', code: 'edit-solid' },
];

export const demoDashboard = {
  1: {
    layout: [
      { w: 35, h: 22, x: 0, y: 81, i: '1-item-4', moved: false, static: false },
      {
        w: 25,
        h: 22,
        x: 35,
        y: 81,
        i: '1-item-5',
        moved: false,
        static: false,
      },
      { w: 36, h: 19, x: 0, y: 3, i: '1-item-8', moved: false, static: false },
      { w: 24, h: 36, x: 36, y: 3, i: '1-item-9', moved: false, static: false },
      { w: 60, h: 3, x: 0, y: 0, i: '1-item-10', moved: false, static: false },
      {
        w: 36,
        h: 17,
        x: 0,
        y: 22,
        i: '1-item-11',
        moved: false,
        static: false,
      },
      { w: 60, h: 3, x: 0, y: 78, i: '1-item-12', moved: false, static: false },
      { w: 60, h: 3, x: 0, y: 39, i: '1-item-13', moved: false, static: false },
      {
        w: 24,
        h: 36,
        x: 0,
        y: 42,
        i: '1-item-14',
        moved: false,
        static: false,
      },
      {
        w: 36,
        h: 18,
        x: 24,
        y: 42,
        i: '1-item-15',
        moved: false,
        static: false,
      },
      {
        w: 36,
        h: 18,
        x: 24,
        y: 60,
        i: '1-item-16',
        moved: false,
        static: false,
      },
      {
        w: 20,
        h: 15,
        x: 0,
        y: 103,
        i: '1-item-17',
        moved: false,
        static: false,
      },
      {
        w: 20,
        h: 15,
        x: 20,
        y: 103,
        i: '1-item-18',
        moved: false,
        static: false,
      },
      {
        w: 20,
        h: 15,
        x: 40,
        y: 103,
        i: '1-item-19',
        moved: false,
        static: false,
      },
      {
        w: 60,
        h: 3,
        x: 0,
        y: 118,
        i: '1-item-20',
        moved: false,
        static: false,
      },
      {
        w: 24,
        h: 17,
        x: 0,
        y: 121,
        i: '1-item-21',
        moved: false,
        static: false,
      },
      {
        w: 36,
        h: 17,
        x: 24,
        y: 121,
        i: '1-item-22',
        moved: false,
        static: false,
      },
      {
        w: 60,
        h: 36,
        x: 0,
        y: 138,
        i: '1-item-24',
        moved: false,
        static: false,
      },
      {
        w: 30,
        h: 17,
        x: 0,
        y: 174,
        i: '1-item-25',
        moved: false,
        static: false,
      },
      {
        w: 30,
        h: 17,
        x: 30,
        y: 174,
        i: '1-item-26',
        moved: false,
        static: false,
      },
    ],
    counter: 27,
    gridItemsData: {
      '1-item-4': {
        header: 'Average Quality Scores by Scorecard',
        selectedReport: {
          id: 5,
          name: 'Overall Quality Score',
          description:
            'Average quality scores by scorecard for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-507590990901711544878974',
      },
      '1-item-5': {
        header: 'Average Quality Scores by Scorecard',
        selectedReport: {
          id: 5,
          name: 'Overall Quality Score',
          description:
            'Average quality scores by scorecard for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'table',
      },
      '1-item-8': {
        header: 'Quality Scores and Pass Rates',
        selectedReport: {
          id: 12,
          name: 'QS Overview - Sales',
          description:
            'Sales quality score and grade stats for the previous 12 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-801733390371710834972704',
      },
      '1-item-9': {
        header: 'Quality Scores and Pass Rates',
        selectedReport: {
          id: 12,
          name: 'QS Overview - Sales',
          description:
            'Sales quality score and grade stats for the previous 12 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'table',
      },
      '1-item-10': { header: 'Sales', selectedReport: { id: '' } },
      '1-item-11': {
        header: 'Sales Closed',
        selectedReport: {
          id: 4,
          name: 'Sales Closed',
          description: 'Sales closure volumes for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-919938309551711546513016',
      },
      '1-item-12': {
        header: 'Overall Quality Scores',
        selectedReport: { id: '' },
      },
      '1-item-13': { header: 'General Enquiries', selectedReport: { id: '' } },
      '1-item-14': {
        header: 'Quality Scores and Pass Rates',
        selectedReport: {
          id: 8,
          name: 'QS Overview - General Enquiries',
          description:
            'General Enquiries quality score and grade stats for the previous 12 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'table',
      },
      '1-item-15': {
        header: 'Quality Scores and Pass Rates',
        selectedReport: {
          id: 8,
          name: 'QS Overview - General Enquiries',
          description:
            'General Enquiries quality score and grade stats for the previous 12 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-801733390371710834972704',
      },
      '1-item-16': {
        header: 'AHT (By Manager)',
        selectedReport: {
          id: 7,
          name: 'GE Call Lengths',
          description:
            'Average General Enquiries call lengths by manager for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-919432320491711547621330',
      },
      '1-item-17': {
        header: 'Collections',
        selectedReport: {
          id: 13,
          name: 'QS Needle - Collections',
          description:
            'Average Collections quality score for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-101747005501711548604671',
      },
      '1-item-18': {
        header: 'Refunds',
        selectedReport: {
          id: 10,
          name: 'QS Needle - Refunds',
          description:
            'Average Refunds quality score for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-672048715451711548463544',
      },
      '1-item-19': {
        header: 'Correspondence',
        selectedReport: {
          id: 9,
          name: 'QS Needle - Correspondence',
          description:
            'Average Correspondence quality score for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-538351007031711548716093',
      },
      '1-item-20': { header: 'Other', selectedReport: { id: '' } },
      '1-item-21': {
        header: 'Refund Codes',
        selectedReport: {
          id: 11,
          name: 'Refund Codes',
          description: 'Refund code volumes for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-280776821591711549713268',
      },
      '1-item-22': {
        header: 'Compliance',
        selectedReport: {
          id: 6,
          name: 'Overall Compliance',
          description:
            'Average compliance scores for all scorecards for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-526369311761711549842771',
      },
      '1-item-24': {
        header: 'QA Marked Ratios',
        selectedReport: {
          id: 2,
          name: 'QA Marked Ratios',
          description:
            'This table shows which scorecards are used by which QA staff members as a percentage based on all of their completed evaluations',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'table',
      },
      '1-item-25': {
        header: 'RAG',
        selectedReport: {
          id: 15,
          name: 'RAG',
          description: 'Comparison of Red, Amber, and Green scores',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-771236124711711553178325',
      },
      '1-item-26': {
        header: 'Retained %',
        selectedReport: {
          id: 14,
          name: 'Retained Accounts',
          description:
            'Percentage of retained accounts during the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T18:21:31.373Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-222025590531711552540103',
      },
    },
    theme: 'Blue',
    title: 'Main Dashboard',
  },
  2: {
    layout: [
      { w: 60, h: 17, x: 0, y: 34, i: '2-item-1', moved: false, static: false },
      { w: 60, h: 17, x: 0, y: 51, i: '2-item-2', moved: false, static: false },
      { w: 60, h: 17, x: 0, y: 85, i: '2-item-3', moved: false, static: false },
      {
        w: 60,
        h: 17,
        x: 0,
        y: 133,
        i: '2-item-4',
        moved: false,
        static: false,
      },
      {
        w: 60,
        h: 17,
        x: 0,
        y: 150,
        i: '2-item-5',
        moved: false,
        static: false,
      },
      { w: 60, h: 17, x: 0, y: 0, i: '2-item-6', moved: false, static: false },
      { w: 60, h: 17, x: 0, y: 17, i: '2-item-7', moved: false, static: false },
      { w: 60, h: 17, x: 0, y: 68, i: '2-item-8', moved: false, static: false },
      {
        w: 60,
        h: 14,
        x: 0,
        y: 102,
        i: '2-item-9',
        moved: false,
        static: false,
      },
      {
        w: 60,
        h: 17,
        x: 0,
        y: 116,
        i: '2-item-10',
        moved: false,
        static: false,
      },
    ],
    theme: 'Blue',
    title: 'Dashboard for Mobile',
    counter: 11,
    gridItemsData: {
      '2-item-1': {
        header: 'Sales',
        selectedReport: {
          id: 12,
          name: 'QS Overview - Sales',
          description:
            'Sales quality score and grade stats for the previous 12 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T11:00:01.228Z',
          created_by: 'QAPortalP',
          last_modified_at: '2024-03-27T15:10:32.034Z',
          last_modified_by: 'QAPortalP',
        },
        display: 'chart-801733390371710834972704',
      },
      '2-item-2': {
        header: 'General Enquiries',
        selectedReport: {
          id: 8,
          name: 'QS Overview - General Enquiries',
          description:
            'General Enquiries quality score and grade stats for the previous 12 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T11:00:01.228Z',
          created_by: 'QAPortalP',
          last_modified_at: '2024-03-27T15:10:01.783Z',
          last_modified_by: 'QAPortalP',
        },
        display: 'chart-801733390371710834972704',
      },
      '2-item-3': {
        header: 'Refund Codes',
        selectedReport: {
          id: 11,
          name: 'Refund Codes',
          description: 'Refund code volumes for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T14:29:36.000Z',
          created_by: 'QAPortalP',
          last_modified_at: '2024-03-27T15:12:50.001Z',
          last_modified_by: 'QAPortalP',
        },
        display: 'chart-280776821591711549713268',
      },
      '2-item-4': {
        header: 'Sales Closed',
        selectedReport: {
          id: 4,
          name: 'Sales Closed',
          description: 'Sales closure volumes for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T13:37:03.895Z',
          created_by: 'QAPortalP',
          last_modified_at: '2024-03-27T15:13:33.884Z',
          last_modified_by: 'QAPortalP',
        },
        display: 'chart-919938309551711546513016',
      },
      '2-item-5': {
        header: 'GE AHT (By Manager)',
        selectedReport: {
          id: 7,
          name: 'GE Call Lengths',
          description:
            'Average General Enquiries call lengths by manager for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T13:54:56.013Z',
          created_by: 'QAPortalP',
          last_modified_at: '2024-03-27T15:03:49.415Z',
          last_modified_by: 'QAPortalP',
        },
        display: 'chart-919432320491711547621330',
      },
      '2-item-6': {
        header: 'Average Quality Scores',
        selectedReport: {
          id: 5,
          name: 'Overall Quality Score',
          description:
            'Average quality scores by scorecard for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T13:10:11.612Z',
          created_by: 'QAPortalP',
          last_modified_at: '2024-03-27T14:58:25.407Z',
          last_modified_by: 'QAPortalP',
        },
        display: 'chart-507590990901711544878974',
      },
      '2-item-7': {
        header: 'Average Pass Rates',
        selectedReport: {
          id: 3,
          name: 'Overall Pass Rates',
          description:
            'Average pass rates by scorecard for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T13:22:09.526Z',
          created_by: 'QAPortalP',
          last_modified_at: '2024-03-27T14:57:39.542Z',
          last_modified_by: 'QAPortalP',
        },
        display: 'chart-446068403741711545811806',
      },
      '2-item-8': {
        header: 'Compliance',
        selectedReport: {
          id: 6,
          name: 'Overall Compliance',
          description:
            'Average compliance scores for all scorecards for the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T14:33:09.321Z',
          created_by: 'QAPortalP',
          last_modified_at: '2024-03-27T15:08:24.231Z',
          last_modified_by: 'QAPortalP',
        },
        display: 'chart-526369311761711549842771',
      },
      '2-item-9': {
        header: 'Retained %',
        selectedReport: {
          id: 14,
          name: 'Retained Accounts',
          description:
            'Percentage of retained accounts during the previous 3 months',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T15:16:51.102Z',
          created_by: 'QAPortalP',
          last_modified_at: null,
          last_modified_by: null,
        },
        display: 'chart-222025590531711552540103',
      },
      '2-item-10': {
        header: 'RAG',
        selectedReport: {
          id: 15,
          name: 'RAG',
          description: 'Comparison of Red, Amber, and Green scores',
          users_with_access: [],
          is_portal_report: false,
          created_at: '2024-03-27T15:30:02.640Z',
          created_by: 'QAPortalP',
          last_modified_at: '2024-03-27T15:34:56.929Z',
          last_modified_by: 'QAPortalP',
        },
        display: 'chart-771236124711711553178325',
      },
    },
  },
};

export const charsCantEqual = [
  'brag',
  'comments_main',
  'created_at',
  'created_by',
  'fed_back',
  'last_modified_by',
  'last_modified_at',
  'pass_fail',
  'quality_score',
  'scorecard_name',
  'sign_off_required',
  'signed_off',
  'status',
  'null',
];

export const charsCantInclude = [
  '=',
  ':',
  '(',
  ')',
  'evals_',
  'scoring_',
  'cat_',
  'admin_',
  'comments_',
  '___',
  'x___x',
  'x____x',
  '.%I',
  '< Select >',
];

export const charsCantEndIn = [
  '_SUM',
  '_AVG',
  '_COUNT',
  '_count',
  '_MIN',
  '_MAX',
  '(COUNT)',
  '(AVG)',
  '(SUM)',
  '(MIN)',
  '(MAX)',
];

export const invoiceHeaders = [
  'status',
  'created',
  'due_date',
  'currency',
  'amount_due',
  'amount_paid',
  'amount_remaining',
  'collection_method',
  'next_payment_attempt',
];

export const invoiceHeaderTranslations = {
  amount_due: 'Amount Due',
  amount_paid: 'Paid',
  amount_remaining: 'Remaining',
  collection_method: 'Payment Method',
  created: 'Created',
  currency: '',
  due_date: 'Date Due',
  next_payment_attempt: 'Next Payment Attempt',
  status: 'Status',
};

export const invoiceCollectionMethods = {
  charge_automatically: 'Automatic',
  send_invoice: 'Manual',
};
