import { capitalise } from '../../../utils/helpers';
import { useContext } from 'react';
import { UserPortal } from '../../../contexts';

import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import moment from 'moment';
import PanelsSection from '../../Elements/PanelsSection';
import useViewport from '../../useViewport';

const AgentRecentEvaluations = ({ demo, noEvalsInYear, recentEvaluations }) => {
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;

  const showGrade = recentEvaluations.some(
    (evaluation) => evaluation.pass_fail
  );

  const showBrag = recentEvaluations.some((evaluation) => evaluation.brag);

  const { screenWidth } = useViewport();

  const tableData = [
    { code: 'scorecard_name', name: 'Scorecard' },
    { code: 'quality_score', name: 'Quality Score' },
    { code: 'pass_fail', name: 'Grade' },
    { code: 'brag', name: 'RAG' },
    { code: 'created_at', name: 'Created' },
  ].filter(
    (item) =>
      (item.code !== 'brag' || (screenWidth > 500 && showBrag)) &&
      (item.code !== 'pass_fail' || (screenWidth > 410 && showGrade)) &&
      (item.code !== 'created_at' || screenWidth > 350)
  );

  return (
    <PanelsSection
      classes={`recent-evaluations-panel ${
        noEvalsInYear ? 'recent-evaluations-panel-no-evals-in-year' : ''
      }`}
      noMaxWidth
      typeStyle='type-2'
    >
      <HeaderSecondary textAlign='center' text='Recent Evaluations' />

      <FlexContainer
        classes='table-container table-container-table recent-evaluations-table'
        marginTop='10px'
      >
        <ul className='table'>
          <li className='header-row'>
            {tableData.map((attribute) => (
              <HeaderSecondary
                key={attribute.code}
                classes='header-cell'
                text={attribute.name}
              />
            ))}
          </li>

          {recentEvaluations?.map((evaluation, index) => {
            return (
              <a
                key={evaluation.evaluation_id}
                href={`/${demo ? 'demo' : portalId}/evaluations/${
                  evaluation.evaluation_id
                }`}
                className={`${index === 0 ? 'first-grid-row ' : ''} grid-row`}
              >
                {tableData.map((attribute) => (
                  <div key={attribute.code} className='grid-row-cell'>
                    <div className='grid-row-cell-data'>
                      {!evaluation[attribute.code] &&
                      evaluation[attribute.code] !== 0
                        ? '-'
                        : attribute.code === 'pass_fail' ||
                          attribute.code === 'brag'
                        ? capitalise(evaluation[attribute.code])
                        : attribute.code === 'created_at'
                        ? moment(evaluation[attribute.code]).format(
                            'Do MMMM YYYY'
                          )
                        : evaluation[attribute.code]}
                    </div>
                  </div>
                ))}
              </a>
            );
          })}
        </ul>
      </FlexContainer>
    </PanelsSection>
  );
};

export default AgentRecentEvaluations;
