import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { UserPortal } from '../../../contexts';

import AvailableSections from './AvailableSections';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InputStandard from '../../Elements/InputStandard';
import reportsApi from '../../../api/reports';
import Toggle from '../../Elements/Toggle';

import './SelectData.css';

const SelectData = ({
  demo,
  handleSetSelectedReportData,
  handleDeselectReportData,
  selectedReportData,
}) => {
  const startingSourceTables = {
    evals: true,
    admin: false,
    scoring: false,
    cat: false,
    comments: false,
  };

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { getAccessTokenSilently } = useAuth0();

  const [showRelatedScorecardData, setShowRelatedScorecardData] =
    useState(false);
  const [sourceTables, setSourceTables] = useState(startingSourceTables);
  const [isLoadingReportingData, setIsLoadingReportingData] = useState(false);
  const [availableSections, setAvailableSections] = useState([]);
  const [searchText, setSearchText] = useState('');

  const getReportingData = async () => {
    setIsLoadingReportingData(true);

    const params = [];

    const sourceTableParams = Object.keys(sourceTables)
      .filter((table) => sourceTables[table])
      .join('_');

    if (sourceTableParams) params.push(`source_tables=${sourceTableParams}`);
    if (searchText)
      params.push(`q=${encodeURIComponent(`((title==lk::${searchText}))`)}`);

    const { availableSections } = await reportsApi.getReportingData({
      params,
      portalId: demo ? 'demo' : portalId,
      userId: demo ? 1 : userId,
      token: demo ? undefined : await getAccessTokenSilently(),
    });

    setAvailableSections(availableSections || []);

    setIsLoadingReportingData(false);
  };

  const handleChangeSourceTables = (event) => {
    setSourceTables((curr) => ({
      ...curr,
      [event.target.value]: event.target.checked,
    }));
  };

  const handleChangeSearchCriteria = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    const debounceGetReportingData = setTimeout(() => {
      getReportingData();
    }, 800);

    return () => clearTimeout(debounceGetReportingData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceTables, searchText]);

  return (
    <div className='select-report-data-area'>
      <div className='report-section-types'>
        <div className='report-section-type'>
          <input
            id='evals-check'
            // className='admin-section-control-checkbox'
            onChange={handleChangeSourceTables}
            type='checkbox'
            value='evals'
            checked={sourceTables.evals}
          />
          <label htmlFor='evals-check'>Evaluations</label>
        </div>
        <div className='report-section-type'>
          <input
            id='admin-check'
            // className='admin-section-control-checkbox'
            onChange={handleChangeSourceTables}
            type='checkbox'
            value='admin'
            checked={sourceTables.admin}
          />
          <label htmlFor='admin-check'>Admin sections</label>
        </div>
        <div className='report-section-type'>
          <input
            id='scoring-check'
            // className='admin-section-control-checkbox'
            onChange={handleChangeSourceTables}
            type='checkbox'
            value='scoring'
            checked={sourceTables.scoring}
          />
          <label htmlFor='scoring-check'>Scoring sections</label>
        </div>
        <div className='report-section-type'>
          <input
            id='cat-check'
            // className='admin-section-control-checkbox'
            onChange={handleChangeSourceTables}
            type='checkbox'
            value='cat'
            checked={sourceTables.cat}
          />
          <label htmlFor='cat-check'>Scorecard categories</label>
        </div>
        <div className='report-section-type'>
          <input
            id='comments-check'
            // className='admin-section-control-checkbox'
            onChange={handleChangeSourceTables}
            type='checkbox'
            value='comments'
            checked={sourceTables.comments}
          />
          <label htmlFor='comments-check'>Custom comments</label>
        </div>
      </div>

      <FlexContainer justifyContent='space-between' flexWrap='wrap'>
        <Toggle
          flexDirection='row'
          id='reports-add-data-toggle'
          labelText='Additional Data'
          onChange={() => setShowRelatedScorecardData((curr) => !curr)}
          toggled={showRelatedScorecardData}
        />

        <InputStandard
          wrap='wrap'
          id='reports-available-sections-search'
          onChange={handleChangeSearchCriteria}
          placeholder='Enter search text'
          value={searchText}
          labelText='Search'
        />
      </FlexContainer>

      <AvailableSections
        availableSections={availableSections}
        handleSetSelectedReportData={handleSetSelectedReportData}
        isLoadingReportingData={isLoadingReportingData}
        selectedReportData={selectedReportData}
        showRelatedScorecardData={showRelatedScorecardData}
      />

      {Object.keys(selectedReportData).length > 0 && (
        <div className='selected-report-data'>
          <HeaderSecondary text='Selected Data:' />

          <AvailableSections
            availableSections={availableSections}
            handleSetSelectedReportData={handleSetSelectedReportData}
            selectedReportData={selectedReportData}
            showRelatedScorecardData={showRelatedScorecardData}
            showSelectedOnly
          />

          <ButtonStandardNoFill
            alignSelf='flex-end'
            iconName='square-cross'
            marginTop='5px'
            onClick={handleDeselectReportData}
            text='De-select all'
          />
        </div>
      )}
    </div>
  );
};

export default SelectData;
