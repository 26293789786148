import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { colourRange } from '../../../utils/reports';
import { doArrayValuesMatchAnyOrder } from '../../../utils/helpers';
import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiDataServiceReports from '../../../api/reports';
import ButtonToggle from '../../Elements/ButtonToggle';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InfoText from '../../Elements/InfoText';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import MainOnly from '../MainAndOptions/MainOnly';
import Panels from '../../Elements/Panels';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import Text from '../../Elements/Text';

const ReportsRisk = ({ demo }) => {
  const navigate = useNavigate();

  const alert = useAlert();

  const { getAccessTokenSilently } = useAuth0();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);

  const [isLoading, setIsLoading] = useState(true);

  const [riskData, setRiskData] = useState({});
  const [scorecards, setScorecards] = useState([]);
  const [scorecardLookup, setScorecardLookup] = useState({});

  const [selectedScorecards, setSelectedScorecards] = useState([]);

  const getReportData = async () => {
    try {
      setIsLoading(true);

      const { distinctScorecards, riskData } =
        await apiDataServiceReports.getRisk({
          portalId: demo ? 'demo' : portalId,
          token: demo ? undefined : await getAccessTokenSilently(),
          userId: demo ? 1 : userId,
        });

      setRiskData(riskData);
      setScorecards(distinctScorecards);
      setSelectedScorecards(
        distinctScorecards.map(({ scorecard_vcid }) => scorecard_vcid)
      );

      const newScorecardLookup = {};

      distinctScorecards.forEach(
        (scorecard) =>
          (newScorecardLookup[scorecard.scorecard_vcid] =
            scorecard.scorecard_name)
      );

      setScorecardLookup(newScorecardLookup);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeScorecard = (scorecardVcid) => {
    setSelectedScorecards((curr) => {
      const copiedCurr = [...curr];

      if (!scorecardVcid) {
        if (
          doArrayValuesMatchAnyOrder(
            copiedCurr,
            scorecards.map(({ scorecard_vcid }) => scorecard_vcid)
          )
        ) {
          return [];
        } else {
          return scorecards.map(({ scorecard_vcid }) => scorecard_vcid);
        }
      } else {
        const vcidIndex = copiedCurr.indexOf(scorecardVcid);

        if (vcidIndex !== -1) {
          copiedCurr.splice(vcidIndex, 1);

          return copiedCurr;
        } else {
          copiedCurr.push(scorecardVcid);

          return copiedCurr;
        }
      }
    });
  };

  useEffect(() => {
    if (!demo && !userFromDb.permissions?.includes('reports.risk')) {
      navigate(`/${demo ? 'demo' : portalId}/reports`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      setBreadcrumbs([
        [
          'Dashboard',
          'dashboard',
          '',
          `/${demo ? 'demo' : portalId}/dashboard`,
        ],
        ['Reports', 'chart-pie', '', `/${demo ? 'demo' : portalId}/reports`],
        ['Risk', 'magnifying-glass'],
      ]);

      getReportData();
    }

    return () => {
      setRiskData();
      setScorecards();
      setSelectedScorecards();
      setScorecardLookup();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LoadingSpinner />;
  return (
    <MainOnly>
      <Panels>
        <PanelsHeaderPrimary iconName='chart-pie' subIcon='bolt' text='Risk' />

        <PanelsSection margin='0 30px'>
          <Text
            text='Scorecard sections ranked in order of risk (from highest risk to lowest)'
            textAlign='center'
          />
        </PanelsSection>

        <HeaderSecondary
          textAlign='center'
          marginTop='30px'
          text='Scorecards'
        />

        <FlexContainer flexWrap='wrap' margin='10px 30px 15px 15px'>
          <ButtonToggle
            margin='0 0 15px 15px'
            onClick={() => handleChangeScorecard()}
            text='All'
            toggled={doArrayValuesMatchAnyOrder(
              selectedScorecards,
              scorecards.map(({ scorecard_vcid }) => scorecard_vcid)
            )}
          />
          {scorecards.map(({ scorecard_name, scorecard_vcid }) => (
            <ButtonToggle
              key={scorecard_vcid}
              margin='0 0 15px 15px'
              onClick={() => handleChangeScorecard(scorecard_vcid)}
              text={scorecard_name}
              toggled={selectedScorecards.includes(scorecard_vcid)}
            />
          ))}
        </FlexContainer>

        <PanelsSection alignSelf='center' margin='0 30px 25px'>
          <FlexContainer
            classes='focus-results-table-container'
            flexDirection='column'
            alignItems='stretch'
            justifyContent='flex-start'
          >
            <table>
              <thead>
                <tr className='report-results-table-header-row'>
                  <th>Section</th>
                  <th>Scorecard</th>
                  <th>Rank</th>
                </tr>
              </thead>

              <tbody>
                {riskData
                  .filter(({ scorecard_vcid }) =>
                    selectedScorecards.includes(scorecard_vcid)
                  )
                  .map(({ rank, scorecard_vcid, title }, index, arr) => {
                    const backgroundColor = colourRange(
                      rank,
                      arr[arr.length - 1].rank,
                      [163, 0, 0],
                      [0, 122, 0]
                    );

                    return (
                      <tr
                        key={index}
                        className={
                          index === 0 ? 'focus-results-first-grid-row' : ''
                        }
                      >
                        <td className='centred-table-cell'>{title}</td>
                        <td className='centred-table-cell'>
                          {scorecardLookup[scorecard_vcid]}
                        </td>
                        <td
                          className='centred-table-cell'
                          style={{ backgroundColor, color: 'white' }}
                        >
                          {rank}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </FlexContainer>
        </PanelsSection>

        <InfoText
          style={{ margin: '0 20px' }}
          text='Risk calculation and ranking is based on a combination of pass %, section weighting, and marked count'
        />
      </Panels>
    </MainOnly>
  );
};

export default ReportsRisk;
