import './PanelsControlBar.css';

const PanelsControlBar = ({ children, justifyContent, buttonOptionsOpen }) => {
  const style = {};

  if (justifyContent) style.justifyContent = justifyContent;

  return (
    <div
      className={`panels-control-bar ${
        buttonOptionsOpen ? 'panels-control-bar-button-options-open' : ''
      }`}
      style={style}
    >
      {children}
    </div>
  );
};

export default PanelsControlBar;
