import { getChartSize } from '../../../utils/reports';

import PortalAreaChart from './PortalAreaChart';
import PortalBarChart from './PortalBarChart';
import PortalLineChart from './PortalLineChart';
import PortalNeedleChart from './PortalNeedleChart';
import PortalPieChart from './PortalPieChart';
import PortalScatterChart from './PortalScatterChart';

import './ReportsChart.css';
import './Charts.css';

const ReportsChart = ({ fromPrintable, reportData }) => {
  const reportColumns = reportData.columnsForReports.map((col) => ({
    ...col,
    value: reportData.customColumnHeaders[col.id] || col.value,
  }));

  const mutatedReportRows = reportData.reportRows;

  if (!mutatedReportRows?.length) return <></>;

  return Object.entries(reportData.charts).map(([chartKey, chartData]) => {
    const barChartMarginBottom =
      chartData?.xLabelHandling === 'alternateX'
        ? (chartData?.alternateInterval - 1) * 20 + 20
        : chartData?.xLabelHandling === 'hideAllX'
        ? 0
        : 30;

    const barChartXLabelOffset =
      chartData?.xLabelHandling === 'alternateX'
        ? -barChartMarginBottom + 5
        : chartData?.xLabelHandling === 'hideAllX'
        ? 5
        : -20;

    const [width, height] = getChartSize(chartData);

    if (chartData?.type === 'bar') {
      return (
        <div
          key={chartKey}
          style={{
            width: '100%',
            maxWidth: `${width}px`,
            height: `${height}px`,
          }}
          className={`reports-chart-container reports-chart-container-with-padding ${
            fromPrintable ? 'from-printable' : ''
          }`}
        >
          <PortalBarChart
            alternateInterval={chartData.alternateInterval}
            barChartMarginBottom={barChartMarginBottom}
            barChartXLabelOffset={barChartXLabelOffset}
            barDataKey={`col_${
              reportColumns.findIndex((col) => col.id === chartData.bar) + 1
            }`} // ? dont think this is right
            chartKey={chartKey}
            colours={chartData.colours}
            height={height}
            mutatedReportRows={mutatedReportRows}
            originalReportRows={reportData.chartDataRows}
            reportColumns={reportColumns}
            shouldIncludeLegend={chartData.shouldIncludeLegend}
            showXAxisLabel={chartData.showXAxisLabel}
            values={chartData.values.map((value) => {
              return `col_${
                reportColumns.findIndex((col) => col.id === value) + 1
              }`;
            })}
            width={width}
            xDataKey={`col_${
              reportColumns.findIndex((col) => col.id === chartData.xAxis) + 1
            }`}
            xLabelHandling={chartData.xLabelHandling}
            yAxisLabel={chartData.yAxisLabel}
          />
        </div>
      );
    } else if (chartData?.type === 'line') {
      return (
        <div
          key={chartKey}
          className={`reports-chart-container reports-chart-container-with-padding ${
            fromPrintable ? 'from-printable' : ''
          }`}
          style={{
            width: '100%',
            maxWidth: `${width}px`,
            height: `${height}px`,
          }}
        >
          <PortalLineChart
            alternateInterval={chartData.alternateInterval}
            barChartMarginBottom={barChartMarginBottom}
            barChartXLabelOffset={barChartXLabelOffset}
            barDataKey={`col_${
              reportColumns.findIndex((col) => col.id === chartData.bar) + 1
            }`} // ? dont think this is right
            chartKey={chartKey}
            colours={chartData.colours}
            height={height}
            mutatedReportRows={mutatedReportRows}
            originalReportRows={reportData.chartDataRows}
            reportColumns={reportColumns}
            shouldIncludeLegend={chartData.shouldIncludeLegend}
            showXAxisLabel={chartData.showXAxisLabel}
            values={chartData.values.map((value) => {
              return `col_${
                reportColumns.findIndex((col) => col.id === value) + 1
              }`;
            })}
            width={width}
            xDataKey={`col_${
              reportColumns.findIndex((col) => col.id === chartData.xAxis) + 1
            }`}
            xLabelHandling={chartData.xLabelHandling}
            yAxisLabel={chartData.yAxisLabel}
          />
        </div>
      );
    } else if (chartData?.type === 'area') {
      return (
        <div
          key={chartKey}
          className={`reports-chart-container reports-chart-container-with-padding ${
            fromPrintable ? 'from-printable' : ''
          }`}
          style={{
            width: '100%',
            maxWidth: `${width}px`,
            height: `${height}px`,
          }}
        >
          <PortalAreaChart
            alternateInterval={chartData.alternateInterval}
            barChartMarginBottom={barChartMarginBottom}
            barChartXLabelOffset={barChartXLabelOffset}
            barDataKey={`col_${
              reportColumns.findIndex((col) => col.id === chartData.bar) + 1
            }`} // ? dont think this is right
            chartKey={chartKey}
            colours={chartData.colours}
            height={height}
            mutatedReportRows={mutatedReportRows}
            originalReportRows={reportData.chartDataRows}
            reportColumns={reportColumns}
            shouldIncludeLegend={chartData.shouldIncludeLegend}
            showXAxisLabel={chartData.showXAxisLabel}
            values={chartData.values.map((value) => {
              return `col_${
                reportColumns.findIndex((col) => col.id === value) + 1
              }`;
            })}
            width={width}
            xDataKey={`col_${
              reportColumns.findIndex((col) => col.id === chartData.xAxis) + 1
            }`}
            xLabelHandling={chartData.xLabelHandling}
            yAxisLabel={chartData.yAxisLabel}
          />
        </div>
      );
    } else if (chartData?.type === 'scatter') {
      return (
        <div
          key={chartKey}
          className={`reports-chart-container reports-chart-container-with-padding ${
            fromPrintable ? 'from-printable' : ''
          }`}
          style={{
            width: '100%',
            maxWidth: `${width}px`,
            height: `${height}px`,
          }}
        >
          <PortalScatterChart
            alternateInterval={chartData.alternateInterval}
            barChartMarginBottom={barChartMarginBottom}
            barChartXLabelOffset={barChartXLabelOffset}
            barDataKey={`col_${
              reportColumns.findIndex((col) => col.id === chartData.bar) + 1
            }`} // ? dont think this is right
            chartKey={chartKey}
            colours={chartData.colours}
            height={height}
            mutatedReportRows={mutatedReportRows}
            originalReportRows={reportData.chartDataRows}
            reportColumns={reportColumns}
            shouldIncludeLegend={chartData.shouldIncludeLegend}
            showXAxisLabel={chartData.showXAxisLabel}
            values={chartData.values.map((value) => {
              return `col_${
                reportColumns.findIndex((col) => col.id === value) + 1
              }`;
            })}
            width={width}
            xDataKey={`col_${
              reportColumns.findIndex((col) => col.id === chartData.xAxis) + 1
            }`}
            xLabelHandling={chartData.xLabelHandling}
            yAxisLabel={chartData.yAxisLabel}
          />
        </div>
      );
    } else if (chartData?.type === 'pie') {
      return (
        <div
          key={chartKey}
          className={`reports-chart-container ${
            fromPrintable ? 'from-printable' : ''
          }`}
          style={{
            width: '100%',
            maxWidth: `${width}px`,
            height: `${height}px`,
          }}
        >
          <PortalPieChart
            alternateInterval={chartData.alternateInterval}
            barChartMarginBottom={barChartMarginBottom}
            barChartXLabelOffset={barChartXLabelOffset}
            chartKey={chartKey}
            height={height}
            mutatedReportRows={mutatedReportRows}
            originalReportRows={reportData.chartDataRows}
            reportColumns={reportColumns}
            showPieValues={chartData.showPieValues}
            showPieLabels={chartData.showXAxisLabel}
            shouldIncludeLegend={chartData.shouldIncludeLegend}
            valueDataLabel={`col_${
              reportColumns.findIndex((col) => col.id === chartData.pieValue) +
              1
            }`}
            xDataKey={`col_${
              reportColumns.findIndex((col) => col.id === chartData.xAxis) + 1
            }`}
            width={width}
            yAxisLabel={chartData.yAxisLabel}
          />
        </div>
      );
    } else if (chartData?.type === 'needle') {
      return (
        <div
          key={chartKey}
          className={`reports-chart-container ${
            fromPrintable ? 'from-printable' : ''
          }`}
          style={{
            width: '100%',
            maxWidth: `${width}px`,
            height: `${height}px`,
          }}
        >
          <PortalNeedleChart
            chartKey={chartKey}
            min={chartData.needleMin}
            max={chartData.needleMax}
            red={chartData.needleRed}
            amber={chartData.needleAmber}
            green={chartData.needleGreen}
            originalReportRows={reportData.chartDataRows}
            valueDataLabel={`col_${
              reportColumns.findIndex((col) => col.id === chartData.pieValue) +
              1
            }`}
          />
        </div>
      );
    }
    return <></>;
  });
};

export default ReportsChart;
