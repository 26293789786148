import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useNavigate } from 'react-router-dom';
import { UserPortal } from '../../../contexts';

import AccountDetails from './AccountDetails';
import AddPortal from './AddPortal';
import apiDataServicePortals from '../../../api/portals';
import AuthenticationButton from '../../Authentication/AuthenticationButton';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import CreatePortal from './CreatePortal';
import Divider from '../../Elements/Divider';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderPrimary from '../../Elements/HeaderPrimary';
import Icon from '../../Elements/Icon';
import portalConfirmAlert from '../../../utils/portalConfirmAlert';
import PortalInvite from './PortalInvite';
import PortalSettings from './PortalSettings';
import SwitchPortal from './SwitchPortal';
import Text from '../../Elements/Text';

import './Account.css';

const Account = ({
  clickedOutside,
  isRespondingToInvite,
  setIsRespondingToInvite,
  setShowAccount,
}) => {
  const alert = useAlert();

  const navigate = useNavigate();

  const { getAccessTokenSilently, logout, user } = useAuth0();

  const { setUserFromDb, userFromDb } = useContext(UserPortal);

  const [isDeleting, setIsDeleting] = useState(false);

  const detectClickOutsideRef = useDetectClickOutside({
    onTriggered: (event) => {
      clickedOutside(event);
    },
  });

  const handleDeletePortal = async () => {
    const confirmed = await portalConfirmAlert({
      message: `This action will delete the '${
        userFromDb.org
      }' portal for ALL users. If you only wish to remove yourself as a user from this portal, ${
        !userFromDb.permissions?.includes('users.change')
          ? 'ask your portal administrator to '
          : ''
      }navigate to the 'Users' screen and delete your user record instead. Do you still want to delete this portal?`,
    });

    if (!confirmed) return;

    try {
      setIsDeleting(true);

      const token = await getAccessTokenSilently();

      await apiDataServicePortals.delete({
        portalId: userFromDb.portal_id,
        token,
        userId: userFromDb.id,
      });

      setIsDeleting(false);

      alert.success(`Portal deleted`);

      navigate(`/`, {
        state: { isNavigating: true },
      });
    } catch (error) {
      setIsDeleting(false);

      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const handleDeleteAccount = async () => {
    const deleteAccountMessage = userFromDb.portal_id
      ? `Deleting your QA Portal account will also remove you as a user from all portals you belong to. If you wish to keep your account active but remove yourself as a user from a portal, ${
          !userFromDb.permissions?.includes('users.change')
            ? 'ask the portal administrator to '
            : ''
        }navigate to the 'Users' screen in the portal and delete your user record. Do you still want to delete your QA Portal account?`
      : 'Are you sure you want to delete your QA Portal account?';

    const confirmed = await portalConfirmAlert({
      message: deleteAccountMessage,
    });

    if (!confirmed) return;

    if (userFromDb.only_admin_portals?.length) {
      const isOnlyAdminPortalsPlural = userFromDb.only_admin_portals.length > 1;

      const confirmed = await portalConfirmAlert({
        message: `You are the only Admin user in the following portal${
          isOnlyAdminPortalsPlural ? 's' : ''
        }: ${userFromDb.only_admin_portals
          .map((portal) => `ORGANISATION: ${portal.org}, ID: ${portal.id}`)
          .join(', ')}. Therefore deleting your account will also result in ${
          isOnlyAdminPortalsPlural ? 'these' : 'this'
        } portal${
          isOnlyAdminPortalsPlural ? 's' : ''
        } being deleted. If you don't want ${
          isOnlyAdminPortalsPlural ? 'them' : 'it'
        } to be deleted, please cancel this action by clicking 'NO' and assign another user to the role of Admin in ${
          isOnlyAdminPortalsPlural ? 'these' : 'this'
        } portal${
          isOnlyAdminPortalsPlural ? 's' : ''
        } before deleting your account. Alternatively, click yes to proceed with deleting your account (and your associated portal${
          isOnlyAdminPortalsPlural ? 's' : ''
        }).`,
        title: 'IMPORTANT',
      });

      if (!confirmed) return;
    }

    try {
      setIsDeleting(true);

      const token = await getAccessTokenSilently();

      const windowLocationOrigin = window?.location?.origin;

      await apiDataServicePortals.deleteAccount({
        sub: user.sub,
        token,
        userId: userFromDb.id,
      });

      setIsDeleting(false);

      alert.success(`Account deleted`);

      logout({ returnTo: windowLocationOrigin });

      if (!windowLocationOrigin) {
        navigate(`/`, {
          state: { isNavigating: true },
        });
      }
    } catch (error) {
      setIsDeleting(false);

      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  return (
    <FlexContainer
      alignItems='flex-end'
      classes='user-account-background'
      flexDirection='column'
    >
      <FlexContainer
        alignItems='stretch'
        classes='user-account-container'
        flexDirection='column'
        flexRef={detectClickOutsideRef}
        justifyContent='flex-start'
      >
        <HeaderPrimary margin='0 30px 0 30px' text='Account' />

        {user.email ? (
          <Text classes='account-email-text' text={user.email} />
        ) : (
          <></>
        )}

        {userFromDb.id && userFromDb.portal_id ? (
          <>
            <AccountDetails
              setShowAccount={setShowAccount}
              setUserFromDb={setUserFromDb}
              userFromDb={userFromDb}
            />

            <Divider isLighter isThinner />
          </>
        ) : (
          <></>
        )}

        {userFromDb?.portal_invites?.length ? (
          <FlexContainer
            alignSelf='flex-start'
            margin={`${
              !userFromDb.id && !userFromDb.portal_id ? '30px' : '0'
            } 30px 30px 30px`}
          >
            <Icon
              className='nav-notification-icon nav-notification-icon-invites'
              iconName='circle-exclamation'
            />

            <Text
              text={`You've been invited to join the below portal${
                userFromDb.portal_invites.length === 1 ? '' : 's'
              }:`}
            />
          </FlexContainer>
        ) : (
          <></>
        )}

        {userFromDb?.portal_invites?.map((portalInvite) => {
          return (
            <PortalInvite
              key={portalInvite.id}
              isRespondingToInvite={isRespondingToInvite}
              portalInvite={portalInvite}
              setIsRespondingToInvite={setIsRespondingToInvite}
            />
          );
        })}

        {userFromDb?.portal_invites?.length ? (
          <Divider isLighter isThinner />
        ) : (
          <></>
        )}

        {!userFromDb.id && !userFromDb.portal_id ? (
          <>
            <CreatePortal hasInvites={userFromDb?.portal_invites?.length} />

            <Divider isLighter isThinner />
          </>
        ) : (
          <></>
        )}

        {userFromDb.id && userFromDb.portal_id ? (
          <>
            <PortalSettings userFromDb={userFromDb} />

            <Divider isLighter isThinner />
          </>
        ) : (
          <></>
        )}

        {userFromDb?.id &&
        userFromDb?.portal_id &&
        userFromDb?.other_portals?.length ? (
          <>
            <SwitchPortal userFromDb={userFromDb} />

            <Divider isLighter isThinner />
          </>
        ) : (
          <></>
        )}

        {userFromDb?.id && userFromDb?.portal_id ? (
          <>
            <AddPortal userFromDb={userFromDb} />

            <Divider isLighter isThinner />
          </>
        ) : (
          <></>
        )}

        <AuthenticationButton />

        <div
          style={{ alignSelf: 'center', marginBottom: '40px' }}
          className='account-logo-img-container'
        >
          <img
            className='create-logo-img'
            src='https://i.ibb.co/bb7szFx/logo3.png'
            alt='Logo'
          />
        </div>

        <FlexContainer
          margin='20px 10px 140px 30px'
          flexWrap='wrap'
          justifyContent='space-evenly'
        >
          {userFromDb?.portal_id &&
          userFromDb?.permissions?.includes('portals.delete') ? (
            <ButtonStandardNoFill
              disabled={isDeleting}
              iconName='trash'
              margin='0 20px 20px 0'
              onClick={handleDeletePortal}
              text='Delete Portal'
              typeStyle='type-5'
            />
          ) : (
            <></>
          )}

          <ButtonStandardNoFill
            classes='delete-qa-portal-account-button'
            disabled={isDeleting}
            iconName='trash'
            margin='0 20px 20px 0'
            onClick={handleDeleteAccount}
            text='Delete Your QA Portal Account'
            typeStyle='type-5'
          />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Account;
