import { translateSourceTablesTitles } from '../../../utils/reports';
import { useEffect, useRef } from 'react';

import ButtonToggle from '../../Elements/ButtonToggle';
import FlexContainer from '../../Elements/FlexContainer';
import GridOption from '../DataGrid/GridContent/GridOption';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import Icon from '../../Elements/Icon';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';

import './ReportsFocusAvailableSectionsPanel.css';

const ReportsFocusAvailableSectionsPanel = ({
  availableSectionsAdmin,
  availableSectionsCat,
  availableSectionsComments,
  availableSectionsEvals,
  handleAvailableSectionsHeightChanges,
  handleToggleAvailableSection,
  isLoadingAvailableSections,
  reportParameters,
  setSourceTable,
  sourceTable,
  toggledAvailableSections,
}) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current?.clientHeight && !isLoadingAvailableSections)
      handleAvailableSectionsHeightChanges(
        'availableSections',
        elementRef.current?.clientHeight
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    elementRef.current,
    elementRef.current?.clientHeight,
    isLoadingAvailableSections,
    sourceTable,
  ]);

  const getParamsType = (params, invalid) => {
    if (!params || invalid) return null;

    if (Array.isArray(params)) return 'range';

    return 'list';
  };

  return (
    <FlexContainer
      alignItems='stretch'
      classes='focus-report-filter-panel'
      flexDirection='column'
      flexRef={elementRef}
    >
      <FlexContainer
        classes={`focus-source-table-options ${
          !sourceTable && 'focus-no-source'
        }`}
        flexDirection='column'
      >
        {['evals', 'admin', 'cat'].map((sourceTableCode) => (
          <GridOption
            key={sourceTableCode}
            headerText={translateSourceTablesTitles[sourceTableCode]}
            showGridOption={sourceTable}
            setShowGridOption={setSourceTable}
            gridOption={sourceTableCode}
          />
        ))}
      </FlexContainer>

      {sourceTable ? (
        <FlexContainer alignItems='stretch' flexDirection='column'>
          <HeaderSecondary
            marginBottom='10px'
            text={translateSourceTablesTitles[sourceTable]}
            textAlign='center'
          />

          {isLoadingAvailableSections ? (
            <LoadingSpinnerNew />
          ) : (
            <FlexContainer
              alignItems='stretch'
              classes='focus-available-sections-list'
              flexDirection='column'
            >
              {(sourceTable === 'evals'
                ? availableSectionsEvals
                : sourceTable === 'admin'
                ? availableSectionsAdmin
                : sourceTable === 'cat'
                ? availableSectionsCat
                : sourceTable === 'comments'
                ? availableSectionsComments
                : []
              )
                ?.sort((a, b) => {
                  const aValid =
                    reportParameters[a.code] &&
                    !reportParameters[a.code].invalid;

                  const bValid =
                    reportParameters[b.code] &&
                    !reportParameters[b.code].invalid;

                  return aValid === bValid
                    ? a.title.toLowerCase().localeCompare(b.title.toLowerCase())
                    : aValid
                    ? -1
                    : 1;
                })
                .map((section) => {
                  const { code, control, title } = section;

                  const paramsType = getParamsType(
                    reportParameters[code]?.params,
                    reportParameters[code]?.invalid
                  );

                  return (
                    <FlexContainer
                      classes='focus-available-section-toggle-container'
                      key={code}
                    >
                      <ButtonToggle
                        onClick={() =>
                          handleToggleAvailableSection(code, section)
                        }
                        text={title}
                        toggled={toggledAvailableSections[code]}
                      />

                      {paramsType === 'range' ? (
                        control === 'Date' ? (
                          <Icon iconName='calendar' />
                        ) : (
                          <Icon iconName='sliders' />
                        )
                      ) : paramsType === 'list' ? (
                        <Icon iconName='bars' />
                      ) : (
                        <></>
                      )}
                    </FlexContainer>
                  );
                })}
            </FlexContainer>
          )}
        </FlexContainer>
      ) : (
        <></>
      )}
    </FlexContainer>
  );
};

export default ReportsFocusAvailableSectionsPanel;
