import { confirmAlert } from 'react-confirm-alert';

import 'react-confirm-alert/src/react-confirm-alert.css';

const portalConfirmAlert = ({ message, title = 'Confirm' }) => {
  return new Promise((resolve) => {
    confirmAlert({
      title,
      message,
      buttons: [
        { label: 'Yes', onClick: () => resolve(true) },
        { label: 'No', onClick: () => resolve(false) },
      ],
    });
  });
};

export default portalConfirmAlert;
