import { apiUrl, headers } from './config';

const scorecardsApi = {
  getAll: async ({ params, portalId, token, userId }) => {
    const baseUrl = `/api/portals/${portalId}/scorecards`;
    const urlParams = `${params.length ? `?${params.join('&')}` : ''}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getScorecardById: async ({
    calibrationId,
    fromCalibrations,
    fromDrafts,
    portalId,
    scorecardId,
    token,
    userId,
  }) => {
    const url = `/api/portals/${portalId}/${
      fromDrafts ? 'drafts/' : ''
    }scorecards/${scorecardId}${
      fromCalibrations ? `/calibration/${calibrationId}` : ''
    }`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  gridUpdate: async ({
    gridChanges,
    portalId,
    reqBody,
    token,
    returnedRecords,
  }) => {
    reqBody.updates = {
      scorecards: {
        toUpdate: Object.entries(gridChanges).map(([id, changes]) => {
          return {
            id,
            vcid: returnedRecords.find((record) => record.id === parseInt(id))
              .vcid,
            ...changes,
          };
        }),
      },
    };

    const url = `/api/portals/${portalId}/scorecards`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  gridDeleteMultiple: async ({
    selectedRecordIds,
    recordData,
    portalId,
    reqBody,
    token,
  }) => {
    reqBody.deletes = selectedRecordIds.map((id) => {
      const { vcid, count_evaluations_id } = recordData.find(
        (record) => parseInt(record.id) === parseInt(id)
      );

      return {
        id,
        vcid,
        hasEvaluations: !!count_evaluations_id,
      };
    });

    const url = `/api/portals/${portalId}/scorecards`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  post: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/scorecards`;

    const { data } = await apiUrl.post(url, reqBody, headers({ token }));

    return data;
  },

  update: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/scorecards`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },
};

export default scorecardsApi;
