import './InputLabel.css';

const InputLabel = ({ className = '', htmlFor, style, text = '' }) => {
  return (
    <label
      className={`input-label ${className}`}
      htmlFor={htmlFor}
      style={style}
    >
      {text}
    </label>
  );
};

export default InputLabel;
