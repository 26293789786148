import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';

import apiDataServicePortal from '../../../api/portals';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import GridOption from '../DataGrid/GridContent/GridOption';
import SelectStandard from '../../Elements/SelectStandard';
import Text from '../../Elements/Text';

const PortalSettings = ({ userFromDb }) => {
  const hasEditPortalPermission =
    userFromDb.permissions?.includes('portals.edit');

  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();

  const [hasChanges, setHasChanges] = useState(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const [showSettings, setShowSettings] = useState('');
  const [evaluationsAvailable, setEvaluationsAvailable] = useState(
    userFromDb.settings?.evaluations_available
  );

  // const [evaluationNotifications, setEvaluationNotifications] = useState('Off');

  const handleSaveSettings = async () => {
    try {
      setIsSavingSettings(true);

      const reqBody = {
        updates: [
          {
            id: userFromDb.portal_id,
            updateDetails: {
              settings: { evaluations_available: evaluationsAvailable },
            },
          },
        ],
        userId: userFromDb.id,
      };

      await apiDataServicePortal.update({
        portalId: userFromDb.portal_id,
        reqBody,
        token: await getAccessTokenSilently(),
      });

      setHasChanges(false);

      alert.success('Settings Saved');
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsSavingSettings(false);
    }
  };

  return (
    <FlexContainer
      alignItems='stretch'
      flexDirection='column'
      classes='grid-panel'
      padding={showSettings ? '10px 5px 5px 5px' : '10px 5px'}
      margin='0 30px'
    >
      <GridOption
        headerText='Settings'
        showGridOption={showSettings}
        setShowGridOption={setShowSettings}
        gridOption='settings'
      />

      {showSettings && (
        <FlexContainer alignItems='stretch' flexDirection='column' margin='0'>
          <FlexContainer
            classes='grid-options-background'
            flexDirection='column'
          >
            <SelectStandard
              id='evaluation-notifications-select'
              hasPermission={hasEditPortalPermission}
              labelText='Evaluations available to view'
              marginTop='5px'
              onChange={(event) => {
                setEvaluationsAvailable(event.target.value);

                setHasChanges(true);
              }}
              options={['When marked', 'When fed back']}
              value={evaluationsAvailable}
            />

            <Text
              margin='15px 5px 0'
              maxWidth='395px'
              text='(This setting determines when managers and agents can view evaluations)'
              textAlign='center'
            />

            {/* <SelectStandard
              id='evaluation-notifications-select'
              labelText='Evaluation Notifications'
              onChange={(event) =>
                setEvaluationNotifications(event.target.value)
              }
              options={[
                'Off',
                'On - Notify when marked',
                'On - Notify when fed back',
              ]}
              value={evaluationNotifications}
            />

            <Text
              margin='15px 5px 0'
              maxWidth='395px'
              text='When this setting is on, users will receive notifications in their portal when linked agents have new evaluations.'
              textAlign='center'
            /> */}

            {hasEditPortalPermission ? (
              <ButtonStandardNoFill
                disabled={!hasChanges || isSavingSettings}
                iconName='save-solid'
                margin='20px 0 10px 0'
                onClick={handleSaveSettings}
                text='Save Settings'
                typeStyle='type-4'
              />
            ) : (
              <></>
            )}
          </FlexContainer>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default PortalSettings;
