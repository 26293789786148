import './ExpandableSection.css';

const ExpandableSection = ({ children, containerClasses = '', isOpen }) => {
  return (
    <div
      className={`${containerClasses} ${
        isOpen ? 'expandable-section-displayed' : 'expandable-section-hidden'
      }`}
      style={{
        height: `${isOpen ? 'auto' : '0px'}`,
        minHeight: `${isOpen ? 'auto' : '0px'}`,
      }}
    >
      <div className='expandable-children-container'>{children}</div>
    </div>
  );
};

export default ExpandableSection;
