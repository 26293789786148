import { apiUrl, headers } from './config';

const evaluationsApi = {
  getAll: async ({ params, portalId, token, userId }) => {
    const baseUrl = `/api/portals/${portalId}/evaluations`;
    const urlParams = `${params.length ? `?${params.join('&')}` : ''}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getEvaluationById: async ({
    evaluationId,
    portalId,
    token,
    userId,
    fromDrafts,
    fromSubmittedEvaluations,
  }) => {
    const url = `/api/portals/${portalId}/${
      fromDrafts ? 'drafts/' : fromSubmittedEvaluations ? 'calibrations/' : ''
    }evaluations/${evaluationId}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getEvaluationsBySectionResults: async ({
    agent,
    endDate,
    portalId,
    scorecardName,
    startDate,
    title,
    token,
    type,
    userId,
  }) => {
    const url = `/api/portals/${portalId}/evaluations?agent=${agent}&end_date=${endDate}&scorecard_name=${scorecardName}&start_date=${startDate}&title=${title}&type=${type}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  gridDeleteMultiple: async ({
    selectedRecordIds,
    portalId,
    reqBody,
    token,
  }) => {
    reqBody.deletes = selectedRecordIds;

    const url = `/api/portals/${portalId}/evaluations`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  gridUpdate: async ({ gridChanges, portalId, reqBody, token }) => {
    reqBody.updates = {
      tables: {
        evaluations: Object.entries(gridChanges).map(([id, changes]) => ({
          id,
          ...changes,
        })),
      },
    };

    const url = `/api/portals/${portalId}/evaluations`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  post: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/evaluations`;

    const { data } = await apiUrl.post(url, reqBody, headers({ token }));

    return data;
  },

  update: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/evaluations`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },
};

export default evaluationsApi;
