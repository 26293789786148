import BillingOptionButton from './BillingOptionButton';
import FlexContainer from '../../Elements/FlexContainer';

const BillingOptionButtons = ({
  customer,
  hasBillingChangePermission,
  isLoading,
  selectedBillingOption,
  setSelectedBillingOption,
  subscription,
}) => {
  return (
    <FlexContainer flexWrap='wrap'>
      {customer?.email ? (
        <BillingOptionButton
          disabled={!hasBillingChangePermission || isLoading}
          option='Change email'
          selectedBillingOption={selectedBillingOption}
          setSelectedBillingOption={setSelectedBillingOption}
          title={
            !hasBillingChangePermission
              ? 'You do not have the required permission'
              : ''
          }
        />
      ) : (
        <></>
      )}

      {subscription ? (
        <>
          {subscription.collection_method === 'charge_automatically' && (
            <BillingOptionButton
              disabled={!hasBillingChangePermission || isLoading}
              option='Change payment card'
              selectedBillingOption={selectedBillingOption}
              setSelectedBillingOption={setSelectedBillingOption}
              title={
                !hasBillingChangePermission
                  ? 'You do not have the required permission'
                  : ''
              }
            />
          )}

          {subscription.collection_method !== 'send_invoice' && (
            <BillingOptionButton
              disabled={!hasBillingChangePermission || isLoading}
              option='Change to manual payment'
              selectedBillingOption={selectedBillingOption}
              setSelectedBillingOption={setSelectedBillingOption}
              title={
                !hasBillingChangePermission
                  ? 'You do not have the required permission'
                  : ''
              }
            />
          )}

          {subscription.collection_method !== 'charge_automatically' && (
            <BillingOptionButton
              disabled={!hasBillingChangePermission || isLoading}
              option='Change to automatic payment'
              selectedBillingOption={selectedBillingOption}
              setSelectedBillingOption={setSelectedBillingOption}
              title={
                !hasBillingChangePermission
                  ? 'You do not have the required permission'
                  : ''
              }
            />
          )}

          <BillingOptionButton
            disabled={!hasBillingChangePermission || isLoading}
            option='Change invoice date'
            selectedBillingOption={selectedBillingOption}
            setSelectedBillingOption={setSelectedBillingOption}
            title={
              !hasBillingChangePermission
                ? 'You do not have the required permission'
                : ''
            }
          />
        </>
      ) : (
        <>
          <BillingOptionButton
            disabled={!hasBillingChangePermission || isLoading}
            option='Automatic payments'
            selectedBillingOption={selectedBillingOption}
            setSelectedBillingOption={setSelectedBillingOption}
            title={
              !hasBillingChangePermission
                ? 'You do not have the required permission'
                : ''
            }
          />

          <BillingOptionButton
            disabled={!hasBillingChangePermission || isLoading}
            option='Manual payments'
            selectedBillingOption={selectedBillingOption}
            setSelectedBillingOption={setSelectedBillingOption}
            title={
              !hasBillingChangePermission
                ? 'You do not have the required permission'
                : ''
            }
          />
        </>
      )}
    </FlexContainer>
  );
};

export default BillingOptionButtons;
