import './HomeInfo.css';

import agentsScores from '../../images/agent_scores_merged.png';
import calibrationReport from '../../images/calibration_report_merged.png';
import calibrationResults from '../../images/calibration_results_merged.png';
import createScorecard from '../../images/create_scorecard_merged.png';
import dashboardReports from '../../images/dashboard_reports_merged.png';
import evaluation from '../../images/evaluation_merged.png';
import focus from '../../images/focus_merged.png';
import qap from '../../images/logo3.png';
import scorecardOptions from '../../images/scorecard_options_merged.png';

const HomeInfo = () => {
  return (
    <div className='home-info'>
      <div className='home-info-banner'>
        <h2>Scoring</h2>
        <h4>Start by crafting effective scorecards</h4>
      </div>

      <div className='home-info-container-textleft'>
        <div className='home-info-text-background'>
          <p className='home-info-text'>
            Creating customised flexible scorecards is easy. Our scorecard
            building tool is fast and responsive and there is no limit to the
            number of scorecards you can create.
          </p>
        </div>

        <div className='home-info-image-container'>
          <img
            className='home-info-image'
            src={createScorecard}
            alt='create scorecard'
          />
        </div>
      </div>

      <div className='home-info-container-textright'>
        <div className='home-info-text-background'>
          <p className='home-info-text'>
            We have a range of scoring options available that empower you to
            create the metrics your company needs in order to drive performance
            and customer satisfaction.
          </p>
        </div>

        <div className='home-info-image-container'>
          <img
            className='home-info-image'
            src={scorecardOptions}
            alt='scorecard options'
          />
        </div>
      </div>

      <div className='home-info-banner'>
        <h2>Feedback</h2>
        <h4>
          Get the best out of your staff and set them and the business up for
          success
        </h4>
      </div>

      <div className='home-info-container-textleft'>
        <div className='home-info-text-background'>
          <p className='home-info-text'>
            Giving quality coaching to agents is straightforward with our
            scorecards and reporting tools. Prep for a feedback session by
            accessing an agent's key development areas and top sections.
          </p>
        </div>

        <div className='home-info-image-container'>
          <img
            className='home-info-image'
            src={agentsScores}
            alt='agent scores'
          />
        </div>
      </div>

      <div className='home-info-container-textright'>
        <div className='home-info-text-background'>
          <p className='home-info-text'>
            With a clear breakdown of the agent's performance and how their
            scores are calculated, you can be transparent in giving agents the
            coaching they need to identify and overcome their development areas
            and realise their potential.
          </p>
        </div>

        <div className='home-info-image-container'>
          <img className='home-info-image' src={evaluation} alt='evaluation' />
        </div>
      </div>

      <div className='home-info-banner'>
        <h2>Reporting</h2>
        <h4>Take advantage of our full suite of reports</h4>
      </div>

      <div className='home-info-container-textleft'>
        <div className='home-info-text-background'>
          <p className='home-info-text'>
            Compiling reports based on your evaluations doesn't need to be a
            time consuming or difficult task. Our reports are thorough in their
            insight and allow for drilling down on the key areas of your choice.
          </p>
        </div>

        <div className='home-info-image-container'>
          <img className='home-info-image' src={focus} alt='report' />
        </div>
      </div>

      <div className='home-info-container-textright'>
        <div className='home-info-text-background'>
          <p className='home-info-text'>
            Fill your dashboard with custom reports so that you can see an
            overview of all the important bits as soon as you log in to your
            portal, and then deep dive into any area you choose.
          </p>
        </div>

        <div className='home-info-image-container'>
          <img
            className='home-info-image'
            src={dashboardReports}
            alt='dashboard reports'
          />
        </div>
      </div>

      <div className='home-info-banner'>
        <h2>Calibration</h2>
        <h4>Gain insight into the variances within your team</h4>
      </div>

      <div className='home-info-container-textleft'>
        <div className='home-info-text-background'>
          <p className='home-info-text'>
            Our post calibration analysis shows a breakdown of variances across
            all metrics, highlighting the different ways your QA team judge
            situations.
          </p>
        </div>

        <div className='home-info-image-container'>
          <img
            className='home-info-image'
            src={calibrationResults}
            alt='calibration results'
          />
        </div>
      </div>

      <div className='home-info-container-textright'>
        <div className='home-info-text-background'>
          <p className='home-info-text'>
            Drill down on specific areas and view trends over time to make sure
            your QA team is heading in the right direction.
          </p>
        </div>

        <div className='home-info-image-container'>
          <img
            className='home-info-image'
            src={calibrationReport}
            alt='calibration report'
          />
        </div>
      </div>

      <img src={qap} className='home-qap3d-logo' alt='QA Portal Logo' />
    </div>
  );
};

export default HomeInfo;
