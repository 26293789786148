import { useState } from 'react';

import ExpandableSection from '../../Elements/ExpandableSection';
import FlexContainer from '../../Elements/FlexContainer';
import GridOption from '../DataGrid/GridContent/GridOption';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import OptionsTemplates from './OptionsTemplates';

import './ChooseScorecardTemplate.css';

const ChooseScorecardTemplate = ({
  handleChangeSelectedTemplate,
  isLoading,
  scorecardTemplates,
  selectedTemplateUniqueId,
}) => {
  const [showTemplateOption, setShowTemplateOption] = useState('');

  const changeSelectedTemplate = (...args) => {
    handleChangeSelectedTemplate(...args);
  };

  if (isLoading) return <LoadingSpinnerNew />;
  else
    return (
      <>
        <FlexContainer
          flexWrap='wrap'
          justifyContent='center'
          classes='templates-headers-container'
        >
          <div
            className={`options-templates-list-item grid-card options-templates-blank ${
              selectedTemplateUniqueId === '0'
                ? 'selected-options-template'
                : ''
            }`}
            onClick={() => changeSelectedTemplate('', '0')}
          >
            <p className='grid-card-header'>Blank Template</p>
          </div>

          <FlexContainer
            justifyContent='center'
            flexWrap='wrap'
            marginLeft='15px'
          >
            <GridOption
              headerText='My Templates'
              showGridOption={showTemplateOption}
              setShowGridOption={setShowTemplateOption}
              gridOption='myTemplates'
            />

            <GridOption
              headerText='Existing Scorecards'
              showGridOption={showTemplateOption}
              setShowGridOption={setShowTemplateOption}
              gridOption='existingScorecards'
            />

            <GridOption
              headerText='Portal Templates'
              showGridOption={showTemplateOption}
              setShowGridOption={setShowTemplateOption}
              gridOption='portalTemplates'
            />
          </FlexContainer>
        </FlexContainer>

        <ExpandableSection isOpen={showTemplateOption === 'myTemplates'}>
          <OptionsTemplates
            handleChangeSelectedTemplate={changeSelectedTemplate}
            selectedTemplateUniqueId={selectedTemplateUniqueId}
            templates={scorecardTemplates?.portalTemplates}
            type='portal'
          />
        </ExpandableSection>

        <ExpandableSection isOpen={showTemplateOption === 'portalTemplates'}>
          <OptionsTemplates
            handleChangeSelectedTemplate={changeSelectedTemplate}
            selectedTemplateUniqueId={selectedTemplateUniqueId}
            templates={scorecardTemplates?.publicTemplates}
            type='public'
          />
        </ExpandableSection>

        <ExpandableSection isOpen={showTemplateOption === 'existingScorecards'}>
          <OptionsTemplates
            handleChangeSelectedTemplate={changeSelectedTemplate}
            selectedTemplateUniqueId={selectedTemplateUniqueId}
            templates={scorecardTemplates?.existingScorecards}
            type='existing'
          />
        </ExpandableSection>
      </>
    );
};

export default ChooseScorecardTemplate;
