import { apiUrl, headers } from './config';

const logsApi = {
  getAll: async ({ params, portalId, token, userId }) => {
    const baseUrl = `/api/portals/${portalId}/logs`;
    const urlParams = `${params.length ? `?${params.join('&')}` : ''}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },
};

export default logsApi;
