import AvailableSection from './AvailableSection';
import InfoText from '../../Elements/InfoText';
import LoadingSpinner from '../../Elements/LoadingSpinner';

import './AvailableSections.css';

const AvailableSections = ({
  availableSections,
  handleSetSelectedReportData,
  isLoadingReportingData,
  selectedReportData,
  showRelatedScorecardData,
  showSelectedOnly,
}) => {
  const dataToMap = showSelectedOnly
    ? Object.values(selectedReportData)
    : availableSections;

  return (
    <ul
      style={{
        minHeight: showSelectedOnly
          ? 'auto'
          : isLoadingReportingData
          ? '150px'
          : '50px',
      }}
      className={`reports-available-sections${
        showSelectedOnly ? ' show-selected-only' : ''
      }`}
    >
      {isLoadingReportingData ? (
        <LoadingSpinner />
      ) : dataToMap.length ? (
        dataToMap.map((section) => {
          const uniqueData = `${section.source_table}_${
            section.control + '_' + section.title
          }`;

          return (
            <AvailableSection
              key={uniqueData}
              section={{ ...section, id: uniqueData }}
              handleSetSelectedReportData={handleSetSelectedReportData}
              selectedReportData={selectedReportData}
              showRelatedScorecardData={showRelatedScorecardData}
              uniqueData={uniqueData}
            />
          );
        })
      ) : (
        <InfoText
          style={{ display: 'block', marginTop: '20px' }}
          text='No data to display'
        />
      )}
    </ul>
  );
};

export default AvailableSections;
