import {
  capitalise,
  checkIsStringNumber,
  isBannedChars,
  lettersOnly,
  validatePresenceRequired,
} from '../../../utils/helpers';
import { handleApiError } from '../../../utils/error-handling';
import { tiers } from '../../../utils/constants';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

import apiDataServiceAgents from '../../../api/agents';
import apiDataServiceEvaluations from '../../../api/evaluations';
import apiDataServiceOrgLevels from '../../../api/org-levels';
import apiDataServiceUsers from '../../../api/users';
import apiDataServiceScorecards from '../../../api/scorecards';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import InputStandard from '../../Elements/InputStandard';
import LoadingSpinnerNew from '../../Elements/LoadingSpinnerNew';
import moment from 'moment';
import Papa from 'papaparse';
import party, { Color } from 'party-js';
import SelectStandard from '../../Elements/SelectStandard';

const CSVUploader = ({
  agents,
  demo,
  evaluations,
  portalId,
  userId,
  users,
}) => {
  const alert = useAlert();

  const { getAccessTokenSilently } = useAuth0();

  const [csvImportFile, setCsvImportFile] = useState('');
  const [data, setData] = useState([]);
  const [expectedColumns, setExpectedColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidImportCells, setInvalidImportCells] = useState({});
  const [showInvalid, setShowInvalid] = useState('');

  // Evaluations Uploads:   ///////////////////////////////////////
  const [flattenedScoringSecs, setFlattenedScoringSecs] = useState([]);
  const [scorecards, setScorecards] = useState([]);
  const [selectedScorecardId, setSelectedScorecardId] = useState('');
  const [selectedScorecardData, setSelectedScorecardData] = useState();

  const handleSetScorecards = async () => {
    setIsLoading(true);

    try {
      const { scorecards } = await apiDataServiceScorecards.getAll({
        params: [`q=${encodeURIComponent('((status==eq::active))')}`],
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setScorecards(scorecards);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangeScorecard = async (event) => {
    const scorecardId = event.target.value;

    setSelectedScorecardId(scorecardId);

    if (scorecardId) await handleSetScorecardData(scorecardId);
    else {
      setSelectedScorecardData(null);
      setExpectedColumns([]);
    }
  };
  const handleSetScorecardData = async (scorecardId) => {
    setIsLoading(true);

    try {
      const { scorecard } = await apiDataServiceScorecards.getScorecardById({
        scorecardId,
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setSelectedScorecardData(scorecard);

      const flattenedScoringSecsData = scorecard.scoringSecs
        .map((item) => {
          if (item.type === 'cat')
            return [
              { type: 'cat', id: item.id },
              ...item.sections.map((section) => {
                return {
                  id: section.id,
                  required: section.required,
                  type: section.type,
                };
              }),
            ];
          else
            return item.sections.map((section) => {
              return {
                id: section.id,
                required: section.required,
                type: section.type,
              };
            });
        })
        .flat();

      setFlattenedScoringSecs(flattenedScoringSecsData);

      const expectedColumnTitles = [
        ...scorecard.adminSecs.map(({ title }) => title),
      ];

      scorecard.scoringSecs.forEach((cat) => {
        if (cat.type !== 'no-cat') expectedColumnTitles.push(cat.title);

        cat.sections.forEach((section) => {
          expectedColumnTitles.push(section.title || 'COMMENTS');
        });
      });

      if (scorecard.green || scorecard.amber || scorecard.red)
        expectedColumnTitles.unshift('RAG');
      if (scorecard.pass_mark) expectedColumnTitles.unshift('Grade');

      expectedColumnTitles.unshift('Quality Score');
      expectedColumnTitles.push(
        'Main Comments',
        'Fed Back',
        'Fed Back Date',
        'Fed Back By',
        'Created Date',
        'Created By (Username)'
      );

      setExpectedColumns(expectedColumnTitles);

      handleSetInvalidEvaluationData(
        scorecard,
        flattenedScoringSecsData,
        expectedColumnTitles,
        data
      );
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      setExpectedColumns([]);
      setSelectedScorecardData(null);
      setInvalidImportCells({});
    } finally {
      setIsLoading(false);
    }
  };
  const handleSetInvalidEvaluationData = (
    selectedScorecardData,
    flattenedScoringSecsData,
    expectedColumns,
    data
  ) => {
    if (!selectedScorecardData || !expectedColumns.length || !data.length)
      return setInvalidImportCells({});

    const hasPassMark = selectedScorecardData.pass_mark;

    const hasBrag =
      selectedScorecardData.green ||
      selectedScorecardData.amber ||
      selectedScorecardData.red;

    const hasPassMarkAndBrag = hasPassMark && hasBrag;

    const adminSecsStartIndex = hasPassMarkAndBrag
      ? 3
      : hasPassMark || hasBrag
      ? 2
      : 1;

    const scoringSecsStartIndex =
      adminSecsStartIndex + selectedScorecardData.adminSecs.length;

    const invalidImportCellsObj = {};

    data.forEach((dataRow, rowIndex) => {
      invalidImportCellsObj[rowIndex] = [];

      expectedColumns.forEach((_, expectedColIndex) => {
        const dataValue = dataRow[expectedColIndex];

        const dataValueLowerCase = dataValue?.toLowerCase?.();

        if (expectedColIndex === 0) {
          const convertedDataValue = Number(dataValue);

          if (
            !(
              convertedDataValue >= 0 &&
              convertedDataValue <= 100 &&
              dataValue &&
              Number.isInteger(convertedDataValue)
            )
          ) {
            invalidImportCellsObj[rowIndex].push(expectedColIndex);
          }
        } else if (expectedColIndex === 1 && (hasPassMark || hasBrag)) {
          if (hasPassMark) {
            if (
              !['p', 'f', 'pass', 'fail', 'y', 'n', 'yes', 'no'].includes(
                dataValueLowerCase
              )
            ) {
              invalidImportCellsObj[rowIndex].push(expectedColIndex);
            }
          }

          if (!hasPassMark && hasBrag) {
            if (
              !['green', 'amber', 'red', 'g', 'a', 'r'].includes(
                dataValueLowerCase
              )
            ) {
              invalidImportCellsObj[rowIndex].push(expectedColIndex);
            }
          }
        } else if (expectedColIndex === 2 && hasPassMarkAndBrag) {
          if (
            !['green', 'amber', 'red', 'g', 'a', 'r'].includes(
              dataValueLowerCase
            )
          ) {
            invalidImportCellsObj[rowIndex].push(expectedColIndex);
          }
        } else if (
          expectedColIndex >= adminSecsStartIndex &&
          expectedColIndex < scoringSecsStartIndex
        ) {
          const positionInAdminSecs = hasPassMarkAndBrag
            ? expectedColIndex - 3
            : hasPassMark || hasBrag
            ? expectedColIndex - 2
            : expectedColIndex - 1;

          const { control, required } =
            selectedScorecardData.adminSecs[positionInAdminSecs];

          if (
            (required && !dataValue) ||
            dataValue?.length > 500 ||
            (control === 'Check box' &&
              !['yes', 'y', 'no', 'n', null].includes(dataValueLowerCase)) ||
            (control === 'Time' &&
              !moment(dataValue, 'HH:mm', true).isValid() &&
              dataValue) ||
            (control === 'Date' &&
              !moment(dataValue, 'DD/MM/YYYY', true).isValid() &&
              dataValue) ||
            (control === 'Number' &&
              !checkIsStringNumber(dataValue) &&
              dataValue)
          ) {
            invalidImportCellsObj[rowIndex].push(expectedColIndex);
          }
        } else if (
          expectedColIndex >= scoringSecsStartIndex &&
          expectedColIndex < expectedColumns.length - 6
        ) {
          const positionInScoringSecs =
            expectedColIndex -
            selectedScorecardData.adminSecs.length -
            (hasPassMarkAndBrag ? 3 : hasPassMark || hasBrag ? 2 : 1);

          const { required, type } =
            flattenedScoringSecsData[positionInScoringSecs] || {};

          if (
            (required && !dataValue) ||
            (type === 'cat' && !checkIsStringNumber(dataValue) && dataValue) ||
            (type === 'scoring' &&
              !['pass', 'fail', 'p', 'f', 'yes', 'no', 'y', 'n', 'na'].includes(
                dataValueLowerCase
              ) &&
              dataValue) ||
            (type === 'comments' && dataValue?.length > 2000)
          ) {
            invalidImportCellsObj[rowIndex].push(expectedColIndex);
          }
        } else if (expectedColIndex === expectedColumns.length - 6) {
          //main comments

          if (dataValue?.length > 5000) {
            invalidImportCellsObj[rowIndex].push(expectedColIndex);
          }
        } else if (expectedColIndex === expectedColumns.length - 5) {
          // fed back

          if (!['yes', 'no', 'y', 'n'].includes(dataValueLowerCase)) {
            invalidImportCellsObj[rowIndex].push(expectedColIndex);
          }
        } else if (expectedColIndex === expectedColumns.length - 4) {
          // fed back date

          if (
            ['yes', 'y'].includes(
              dataRow[expectedColIndex - 1]?.toLowerCase?.()
            )
          ) {
            if (!moment(dataValue, 'DD/MM/YYYY', true).isValid()) {
              invalidImportCellsObj[rowIndex].push(expectedColIndex);
            }
          } else if (dataValue) {
            invalidImportCellsObj[rowIndex].push(expectedColIndex);
          }
        } else if (expectedColIndex === expectedColumns.length - 3) {
          // fed back by

          if (
            ['yes', 'y'].includes(
              dataRow[expectedColIndex - 2]?.toLowerCase?.()
            )
          ) {
            if (!checkIsStringNumber(dataValue) && dataValue) {
              invalidImportCellsObj[rowIndex].push(expectedColIndex);
            }
          } else if (dataValue) {
            invalidImportCellsObj[rowIndex].push(expectedColIndex);
          }
        } else if (expectedColIndex === expectedColumns.length - 2) {
          // created date

          if (!moment(dataValue, 'DD/MM/YYYY', true).isValid()) {
            invalidImportCellsObj[rowIndex].push(expectedColIndex);
          }
        } else if (expectedColIndex === expectedColumns.length - 1) {
          // created by (username)

          if (dataValue?.length > 500 || !dataValue) {
            invalidImportCellsObj[rowIndex].push(expectedColIndex);
          }
        }
      });
    });

    setInvalidImportCells(invalidImportCellsObj);
  };
  const convertCheckBoxData = (checkBoxData) => {
    const checkBoxDataLowerCase = checkBoxData.toLowerCase();

    if (['yes', 'y'].includes(checkBoxDataLowerCase)) return 'Yes';
    if (['no', 'n', null].includes(checkBoxDataLowerCase)) return 'No';
  };
  const convertBrag = (bragVal) => {
    const bragValLowerCase = bragVal.toLowerCase();

    if (bragValLowerCase === 'b') return 'black';
    if (bragValLowerCase === 'r') return 'red';
    if (bragValLowerCase === 'a') return 'amber';
    if (bragValLowerCase === 'g') return 'green';
    return bragValLowerCase;
  };
  const convertPassMark = (passMark) => {
    const passMarkLowerCase = passMark.toLowerCase();

    if (['pass', 'p', 'yes', 'y'].includes(passMarkLowerCase)) return 'pass';
    if (['fail', 'f', 'no', 'n'].includes(passMarkLowerCase)) return 'fail';
  };
  const convertFedBack = (fedBack) => {
    const fedBackLowerCase = fedBack.toLowerCase();

    if (['yes', 'y'].includes(fedBackLowerCase)) return true;
    if (['no', 'n'].includes(fedBackLowerCase)) return false;
  };
  const convertScoringMark = (scoringMark) => {
    const scoringMarkLowerCase = scoringMark.toLowerCase();

    if (['pass', 'p', 'yes', 'y'].includes(scoringMarkLowerCase)) return 'pass';
    if (['fail', 'f', 'no', 'n'].includes(scoringMarkLowerCase)) return 'fail';
    if (scoringMarkLowerCase === 'na') return 'na';
  };
  const handleSaveEvaluations = async () => {
    try {
      setIsLoading(true);

      const hasPassMark = selectedScorecardData.pass_mark;

      const hasBrag =
        selectedScorecardData.green ||
        selectedScorecardData.amber ||
        selectedScorecardData.red;

      const hasPassMarkAndBrag = hasPassMark && hasBrag;

      const adminSecsStartIndex = hasPassMarkAndBrag
        ? 3
        : hasPassMark || hasBrag
        ? 2
        : 1;

      const scoringSecsStartIndex =
        adminSecsStartIndex + selectedScorecardData.adminSecs.length;

      const evaluationsData = data.map((evaluationRow) => {
        const createdDate = evaluationRow[expectedColumns.length - 2];
        const fedBackDate = evaluationRow[expectedColumns.length - 4];

        return {
          scorecard_id: scorecards.find(
            ({ vcid }) => parseInt(vcid) === parseInt(selectedScorecardId)
          ).id,
          scorecard_vcid: selectedScorecardId,
          // // agent_id: ,
          quality_score: evaluationRow[0],
          brag: hasBrag
            ? convertBrag(evaluationRow[hasPassMark ? 2 : 1])
            : null,
          pass_fail: hasPassMark ? convertPassMark(evaluationRow[1]) : null,
          created_by: evaluationRow[expectedColumns.length - 1],
          orderMarkedDate: createdDate
            ? moment(createdDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : null,
          comments_main: evaluationRow[expectedColumns.length - 6] || null,
          fed_back: convertFedBack(evaluationRow[expectedColumns.length - 5]),
          fed_back_at: fedBackDate
            ? moment(fedBackDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : null,
          fed_back_by_user_id:
            evaluationRow[expectedColumns.length - 3] || null,
          imported: true,
          status: 'completed',

          sc_results_admin: selectedScorecardData.adminSecs.map(
            (section, adminSecsIndex) => {
              const value = evaluationRow[adminSecsStartIndex + adminSecsIndex];

              return {
                section_id: section.id,
                data:
                  section.control === 'Check box'
                    ? convertCheckBoxData(value) || null
                    : section.control === 'Date' && value
                    ? moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD') || null
                    : value || null,
              };
            }
          ),

          sc_results_cat: flattenedScoringSecs
            .filter((secs) => secs.type === 'cat')
            .map((cat) => {
              const catScore =
                evaluationRow[
                  scoringSecsStartIndex +
                    flattenedScoringSecs.findIndex(
                      (sec) => sec.id === cat.id && sec.type === 'cat'
                    )
                ];

              return {
                section_id: cat.id,
                data: catScore || catScore === 0 ? catScore : null,
              };
            }),

          sc_results_scoring: flattenedScoringSecs
            .filter((secs) => secs.type === 'scoring')
            .map((scoringSec) => {
              const scoringSecData =
                evaluationRow[
                  scoringSecsStartIndex +
                    flattenedScoringSecs.findIndex(
                      (sec) =>
                        sec.id === scoringSec.id && sec.type === 'scoring'
                    )
                ];

              return {
                section_id: scoringSec.id,
                data: convertScoringMark(scoringSecData) || null,
              };
            }),

          sc_results_comments: flattenedScoringSecs
            .filter((secs) => secs.type === 'comments')
            .map((commentsSec) => {
              const commentsSecData =
                evaluationRow[
                  scoringSecsStartIndex +
                    flattenedScoringSecs.findIndex(
                      (sec) =>
                        sec.id === commentsSec.id && sec.type === 'comments'
                    )
                ];

              return {
                section_id: commentsSec.id,
                data: commentsSecData || null,
              };
            }),
        };
      });

      await apiDataServiceEvaluations.post({
        portalId: demo ? 'demo' : portalId,
        reqBody: {
          evaluations: evaluationsData,
          userId: demo ? 1 : userId,
        },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      const green = new Color(12, 76, 20);

      alert.success(
        `${evaluationsData.length} evaluation${
          evaluationsData.length === 1 ? '' : 's'
        } imported`
      );

      party.sparkles(
        document.getElementById('__react-alert__').querySelector('span'),
        { color: green }
      );

      setData([]);
      setInvalidImportCells({});
      setExpectedColumns([]);
      setSelectedScorecardData(null);
      setSelectedScorecardId('');
      setCsvImportFile('');
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoading(false);
    }
  };

  //////////////////////////////////////////////////////////////////

  // Agents Uploads:   ///////////////////////////////////////

  const [orgLevels, setOrgLevels] = useState([]);

  const handleSetOrgLevels = async () => {
    setIsLoading(true);

    try {
      const { orgLevels } = await apiDataServiceOrgLevels.getAll({
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      setOrgLevels(orgLevels);
      setExpectedColumns(orgLevels.map(({ name }) => name));
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      setExpectedColumns([]);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSetInvalidAgentData = (expectedColumns, data) => {
    if (!data.length) return setInvalidImportCells({});

    const invalidImportCellsObj = {};

    const errorsList = [];

    data.forEach((dataRow, rowIndex) => {
      invalidImportCellsObj[rowIndex] = [];

      expectedColumns.forEach((_, expectedColIndex) => {
        const dataValue = dataRow[expectedColIndex];

        const isBannedCharsText = isBannedChars(dataValue);

        if (isBannedCharsText) {
          invalidImportCellsObj[rowIndex].push(expectedColIndex);

          if (!errorsList.includes(isBannedCharsText))
            errorsList.push(isBannedCharsText);
        }
      });
    });

    setInvalidImportCells(invalidImportCellsObj);

    if (errorsList.length) {
      return alert.error(
        errorsList.map((error) => <p key={error}>{error}</p>),
        { timeout: 10000 }
      );
    }
  };
  const handleSaveAgents = async () => {
    try {
      setIsLoading(true);

      const agentsData = data.map((agentRow) => {
        const agentObj = {};

        orgLevels.forEach((orgLevel, orgLevelIndex) => {
          agentObj[`level${orgLevel.level}`] = agentRow[orgLevelIndex];
        });

        return agentObj;
      });

      await apiDataServiceAgents.post({
        portalId: demo ? 'demo' : portalId,
        reqBody: {
          agents: agentsData,
          userId: demo ? 1 : userId,
        },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      const green = new Color(12, 76, 20);

      alert.success(
        `${agentsData.length} agent${
          agentsData.length === 1 ? '' : 's'
        } imported`
      );

      party.sparkles(
        document.getElementById('__react-alert__').querySelector('span'),
        { color: green }
      );

      setData([]);
      setCsvImportFile('');
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoading(false);
    }
  };

  //////////////////////////////////////////////////////////////////

  // Users Uploads:   ///////////////////////////////////////

  const [agentIds, setAgentIds] = useState({});

  const handleSetAgentIds = async () => {
    setIsLoading(true);

    try {
      const { agents } = await apiDataServiceAgents.getAll({
        params: [],
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      const agentIdsObj = {};

      agents.forEach(({ id, alt_id }) => (agentIdsObj[id] = alt_id));

      setAgentIds(agentIdsObj);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      setAgentIds({});
    } finally {
      setIsLoading(false);
    }
  };
  const handleSetInvalidUserData = (expectedColumns, data) => {
    if (!data.length) return setInvalidImportCells({});

    const invalidImportCellsObj = {};

    const errorsList = [];

    data.forEach((dataRow, rowIndex) => {
      invalidImportCellsObj[rowIndex] = [];

      expectedColumns.forEach((_, expectedColIndex) => {
        const dataValue = dataRow[expectedColIndex];

        const isBannedCharsText = isBannedChars(dataValue);

        if (isBannedCharsText) {
          invalidImportCellsObj[rowIndex].push(expectedColIndex);

          if (!errorsList.includes(isBannedCharsText))
            errorsList.push(isBannedCharsText);
        } else {
          const dataValueLowerCase = dataValue?.toLowerCase?.();

          if (expectedColIndex === 0) {
            // first name

            if (!lettersOnly(dataValue)) {
              invalidImportCellsObj[rowIndex].push(expectedColIndex);
            }
          } else if (expectedColIndex === 1) {
            // last name

            if (!lettersOnly(dataValue)) {
              invalidImportCellsObj[rowIndex].push(expectedColIndex);
            }
          } else if (expectedColIndex === 2) {
            // email

            if (!dataValue.trim()) {
              invalidImportCellsObj[rowIndex].push(expectedColIndex);
            }
          } else if (expectedColIndex === 3) {
            // role

            if (
              !['admin', 'qa', 'manager', 'agent'].includes(dataValueLowerCase)
            ) {
              invalidImportCellsObj[rowIndex].push(expectedColIndex);
            }
          } else if (expectedColIndex === 4) {
            // linked agents

            const splitIds = dataValue.split(',');

            if (
              (splitIds.length > 1 || splitIds[0] !== '') &&
              !splitIds.every((id) => agentIds[id])
            ) {
              invalidImportCellsObj[rowIndex].push(expectedColIndex);
            }
          }
        }
      });
    });

    setInvalidImportCells(invalidImportCellsObj);

    if (errorsList.length) {
      return alert.error(
        errorsList.map((error) => <p key={error}>{error}</p>),
        { timeout: 10000 }
      );
    }
  };

  const handleSaveUsers = async () => {
    try {
      setIsLoading(true);

      const usersData = data.map((userRow) => {
        const roleLowerCase = userRow[3].toLowerCase();

        const username = `${capitalise(
          lettersOnly(userRow[1]).toLowerCase()
        )}${capitalise(lettersOnly(userRow[0]).slice(0, 1))}`;

        const linkedAgents = userRow[4].split(',');

        const userObj = {
          first_name: userRow[0],
          last_name: userRow[1],
          username,
          email: userRow[2].trim(),
          role: roleLowerCase,
          linked_agents:
            linkedAgents[0] === ''
              ? ''
              : linkedAgents.map((id) => agentIds[id]),
          portal_id: demo ? 1 : portalId,
          tier: tiers[roleLowerCase],
          setPermissions: 'default',
        };

        return userObj;
      });

      await apiDataServiceUsers.post({
        portalId: demo ? 'demo' : portalId,
        reqBody: {
          users: usersData,
          userId: demo ? 1 : userId,
        },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      const green = new Color(12, 76, 20);

      alert.success(
        `${usersData.length} user${usersData.length === 1 ? '' : 's'} imported`
      );

      party.sparkles(
        document.getElementById('__react-alert__').querySelector('span'),
        { color: green }
      );

      setData([]);
      setCsvImportFile('');
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    } finally {
      setIsLoading(false);
    }
  };

  //////////////////////////////////////////////////////////////////

  const handleFileChange = (event) => {
    setCsvImportFile(event.target.value);

    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          const data = results.data
            .filter(
              (row) =>
                (row.length > 1 || row[0] !== '') && row.some((item) => item)
            )
            .slice(1);

          setData(data);

          if (evaluations)
            handleSetInvalidEvaluationData(
              selectedScorecardData,
              flattenedScoringSecs,
              expectedColumns,
              data
            );

          if (users) handleSetInvalidUserData(expectedColumns, data);

          if (agents) handleSetInvalidAgentData(expectedColumns, data);
        },
      });
    } else {
      setData([]);
      setInvalidImportCells({});
    }
  };

  const handleImport = async () => {
    if (evaluations) {
      setShowInvalid(!selectedScorecardId);

      if (!selectedScorecardId) return alert.error('Please select a scorecard');
    }

    if (Object.values(invalidImportCells).some((arr) => arr.length > 0))
      return alert.error('Please check errors highlighted in the preview');

    if (demo) return alert.info('Unable to import in demo');

    if (evaluations) await handleSaveEvaluations();
    else if (agents) await handleSaveAgents();
    else if (users) await handleSaveUsers();
  };

  useEffect(() => {
    if (evaluations) handleSetScorecards();
    else if (agents) handleSetOrgLevels();
    else if (users) {
      setExpectedColumns([
        'First Name',
        'Last Name',
        'Email',
        'Role',
        'Linked Agents',
      ]);

      handleSetAgentIds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <FlexContainer flexDirection='column' marginTop='20px'>
        {evaluations && (
          <SelectStandard
            disabled={isLoading}
            invalid={
              selectedScorecardId
                ? null
                : [validatePresenceRequired(selectedScorecardId, 'Scorecard')]
            }
            isPlaceholderSelected={!selectedScorecardId}
            isRequired
            marginBottom='20px'
            selectStyle={{ maxWidth: '180px' }}
            onChange={handleChangeScorecard}
            options={[
              { key: 0, code: '', name: '< Scorecard >' },
              ...scorecards.map(({ id, vcid, name }) => ({
                key: id,
                code: vcid,
                name,
              })),
            ]}
            showInvalid={showInvalid}
            value={selectedScorecardId}
          />
        )}

        <InputStandard
          accept='.csv'
          classes='create-logo-file-input'
          disabled={isLoading}
          marginBottom='20px'
          onChange={handleFileChange}
          type='file'
          value={csvImportFile}
        />

        <ButtonStandardNoFill
          disabled={!data.length || isLoading}
          iconName='file-import'
          marginBottom='20px'
          onClick={handleImport}
          text='Import'
          typeStyle='type-4'
        />
      </FlexContainer>

      <FlexContainer
        classes='grid-options-background'
        flexDirection='column'
        paddingBottom={expectedColumns.length ? '20px' : '10px'}
        alignItems='stretch'
      >
        <HeaderSecondary text='Preview' textAlign='center' />

        {expectedColumns.length > 0 &&
          (isLoading ? (
            <LoadingSpinnerNew />
          ) : (
            <div>
              <FlexContainer
                classes='table-container table-container-table imports-table'
                justifyContent='flex-start'
              >
                <ul className='table'>
                  <li className='header-row'>
                    {expectedColumns.map((col, index) => (
                      <HeaderSecondary
                        key={index}
                        classes='header-cell'
                        text={col}
                      />
                    ))}
                  </li>

                  {data.map((row, dataIndex) => (
                    <li
                      className={`${
                        dataIndex === 0 ? 'first-grid-row ' : ''
                      } grid-row permissions-grid-row`}
                      key={dataIndex}
                    >
                      {expectedColumns.map((_, colIndex) => (
                        <div
                          key={colIndex}
                          className={`grid-row-cell permissions-grid-row-cell ${
                            invalidImportCells[dataIndex]?.includes(colIndex)
                              ? 'invalid-import-data'
                              : 'valid-import-data'
                          }`}
                        >
                          <div className={`grid-row-cell-data `}>
                            {row[colIndex] || '-'}
                          </div>
                        </div>
                      ))}
                    </li>
                  ))}
                </ul>
              </FlexContainer>
            </div>
          ))}
      </FlexContainer>
    </div>
  );
};

export default CSVUploader;
