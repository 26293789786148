import FlexContainer from '../../Elements/FlexContainer';
import ReportAddChart from './ReportAddChart';

const ReportAddCharts = ({
  charts,
  columnOrder,
  customColumnHeaders,
  hasGroupingOrAggregate,
  setCharts,
}) => {
  const handleUpdateChartType = (chartRef, type) => {
    setCharts((curr) => {
      const copiedCurr = { ...curr };

      copiedCurr[chartRef].type = type;

      if (type === 'bar') {
        if (!copiedCurr[chartRef].xLabelHandling)
          copiedCurr[chartRef].xLabelHandling = 'hideXOverlap';
        if (!copiedCurr[chartRef].alternateInterval)
          copiedCurr[chartRef].alternateInterval = '2';
      }

      return copiedCurr;
    });
  };

  const handleUpdateChartProp = (chartRef, prop, value, barId) => {
    setCharts((curr) => {
      if (prop === 'colours') {
        const copiedCurr = { ...curr };

        copiedCurr[chartRef][prop][barId] = value;

        return copiedCurr;
      } else {
        const copiedCurr = { ...curr };

        copiedCurr[chartRef][prop] = value;

        return copiedCurr;
      }
    });
  };

  const handleUpdateChartValues = (chartRef, value, toAdd) => {
    setCharts((curr) => {
      const copiedCurr = { ...curr };

      const copiedValues = copiedCurr[chartRef]?.values
        ? [...copiedCurr[chartRef].values]
        : [];

      if (toAdd) {
        if (copiedValues.includes(value)) return copiedCurr;

        copiedValues.push(value);
      } else copiedValues.splice(copiedValues.indexOf(value), 1);

      copiedCurr[chartRef] = {
        ...copiedCurr[chartRef],
        values: [...copiedValues],
      };

      return copiedCurr;
    });
  };

  const handleUpdateAndReorderChartValues = (chartRef, values) => {
    setCharts((curr) => {
      const copiedCurr = { ...curr };

      const newValues = [];

      columnOrder.forEach((col) => {
        if (values?.includes(col.id)) {
          newValues.push(col.id);
        }
      });

      copiedCurr[chartRef] = {
        ...copiedCurr[chartRef],
        values: [...newValues],
      };

      return copiedCurr;
    });
  };

  return (
    <FlexContainer flexDirection='column'>
      <FlexContainer flexWrap='wrap' margin='0 10px' alignItems='flex-start'>
        {Object.entries(charts).map(([uniqueRef, chartData]) => (
          <ReportAddChart
            key={uniqueRef}
            chartData={chartData}
            columnOrder={columnOrder}
            customColumnHeaders={customColumnHeaders}
            handleUpdateAndReorderChartValues={
              handleUpdateAndReorderChartValues
            }
            handleUpdateChartProp={handleUpdateChartProp}
            handleUpdateChartType={handleUpdateChartType}
            handleUpdateChartValues={handleUpdateChartValues}
            hasGroupingOrAggregate={hasGroupingOrAggregate}
            setCharts={setCharts}
            uniqueRef={uniqueRef}
          />
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

export default ReportAddCharts;
