import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const GeneralLineChart = ({
  classes = '',
  data,
  extraTooltipHeaders,
  hideLegend,
  lineColoursLookup,
  lineDataKeys,
  minMax,
  trendLabel = '',
  trendLine,
  xDataKey,
  yLabel = '',
  yLabelOffsets = [-65, 30],
}) => {
  const getMinMax = () => {
    if (minMax) return minMax;
    else {
      let max = 0;

      data.forEach((dataItem) => {
        lineDataKeys.forEach((lineItem) => {
          const newVal = parseFloat(dataItem[lineItem]);

          if (newVal > max) max = newVal;
        });
      });

      return [0, max];
    }
  };

  const CustomYAxisLabel = () => {
    return (
      <text
        className='chart-axis-label'
        x={yLabelOffsets[0]}
        y={yLabelOffsets[1]}
        textAnchor='end'
        transform='rotate(-90)'
      >
        {yLabel}
      </text>
    );
  };

  return (
    <div className={`general-line-chart ${classes}`}>
      <ResponsiveContainer width='100%'>
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />

          <XAxis dataKey={xDataKey} />

          <YAxis domain={getMinMax()} label={<CustomYAxisLabel />} />

          <Tooltip
            content={({ label, payload, index }) => {
              if (payload && payload.length) {
                return (
                  <div className='recharts-tooltip-wrapper'>
                    <div
                      className='recharts-default-tooltip'
                      style={{
                        margin: '0',
                        padding: '10px',
                        backgroundColor: 'rgb(255, 255, 255)',
                        border: '1px solid rgb(204, 204, 204)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <p style={{ color: '#505050', fontWeight: '600' }}>
                        {label}
                      </p>

                      {extraTooltipHeaders ? (
                        <p
                          style={{
                            color: '#505050',
                            fontWeight: '500',
                            marginTop: '5px',
                          }}
                        >
                          {extraTooltipHeaders(payload[0].payload)}
                        </p>
                      ) : (
                        <></>
                      )}

                      {payload.map((entry, index) => (
                        <p
                          key={index}
                          style={{ color: '#333333', margin: '5px 0 0' }}
                        >{`${entry.dataKey}: ${entry.value}`}</p>
                      ))}
                    </div>
                  </div>
                );
              }
              return null;
            }}
          />

          {trendLine ? (
            <ReferenceLine
              label={trendLabel}
              segment={[
                {
                  x: data[trendLine.trendStart?.xDataPoint - 1]?.[xDataKey],
                  y: trendLine.trendStart?.yDataPoint,
                },
                {
                  x: data[trendLine.trendEnd?.xDataPoint - 1]?.[xDataKey],
                  y: trendLine.trendEnd?.yDataPoint,
                },
              ]}
              stroke='#8a6c0c'
              strokeDasharray='3 3'
            />
          ) : (
            <></>
          )}

          {!hideLegend && <Legend wrapperStyle={{ bottom: '0' }} />}

          {lineDataKeys.map((dataKey) => (
            <Line
              connectNulls
              dataKey={dataKey}
              key={dataKey}
              stroke={lineColoursLookup?.[dataKey] || '#4c3b4d'}
              type='monotone'
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GeneralLineChart;
