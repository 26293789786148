import { apiUrl, headers } from './config';

const reportsApi = {
  generateReport: async ({ params, portalId, token, userId }) => {
    if (!params.length) return;

    const baseUrl = `/api/portals/${portalId}/reports/generate`;
    const urlParams = `?${params.join('&')}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getAll: async ({ params, portalId, token, userId, type = 'custom' }) => {
    const baseUrl = `/api/portals/${portalId}/reports`;

    const urlParams = `?${params.join('&')}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getAggregateColumnData: async ({ params, portalId, token, userId }) => {
    if (!params.length) return;

    const baseUrl = `/api/portals/${portalId}/reports/aggregate-column-data`;
    const urlParams = `?${params.join('&')}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getReportById: async ({ reportId, portalId, token, userId }) => {
    const url = `/api/portals/${portalId}/reports/${reportId}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getReportingData: async ({ params, portalId, token, userId }) => {
    const baseUrl = `/api/portals/${portalId}/reports/reporting-data`;
    const urlParams = `${params.length ? `?${params.join('&')}` : ''}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getCalibrations: async ({ params, portalId, token, userId }) => {
    const baseUrl = `/api/portals/${portalId}/reports/calibrations`;
    const urlParams = `${params.length ? `?${params.join('&')}` : ''}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getFocus: async ({ params, portalId, token, userId }) => {
    const baseUrl = `/api/portals/${portalId}/reports/focus`;
    const urlParams = `${params.length ? `?${params.join('&')}` : ''}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getHotTopics: async ({ portalId, token, userId, withData }) => {
    const baseUrl = `/api/portals/${portalId}/reports/hot-topics`;

    const withParams = `${
      withData.length
        ? `?${withData.map((data) => `with_${data}=true`).join('&')}`
        : ''
    }`;

    const url = `${baseUrl}${withParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getQa: async ({ params, portalId, token, userId }) => {
    const baseUrl = `/api/portals/${portalId}/reports/qa`;
    const urlParams = `${params.length ? `?${params.join('&')}` : ''}`;

    const url = `${baseUrl}${urlParams}`;

    const { data } = await apiUrl.get(url, headers({ token, userId }));

    return data;
  },

  getRisk: async ({ params, portalId, token, userId }) => {
    const baseUrl = `/api/portals/${portalId}/reports/risk`;

    const { data } = await apiUrl.get(baseUrl, headers({ token, userId }));

    return data;
  },

  gridDeleteMultiple: async ({
    selectedRecordIds,
    portalId,
    reqBody,
    token,
  }) => {
    reqBody.deletes = selectedRecordIds;

    const url = `/api/portals/${portalId}/reports`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  gridUpdate: async ({ gridChanges, portalId, reqBody, token }) => {
    reqBody.updates = Object.entries(gridChanges).map(([id, changes]) => ({
      id,
      ...changes,
    }));

    const url = `/api/portals/${portalId}/reports`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },

  post: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/reports`;

    const { data } = await apiUrl.post(url, reqBody, headers({ token }));

    return data;
  },

  update: async ({ portalId, reqBody, token }) => {
    const url = `/api/portals/${portalId}/reports`;

    await apiUrl.patch(url, reqBody, headers({ token }));
  },
};

export default reportsApi;
