import { calculatePercentageInRange } from '../../../utils/reports';
import { validAsNumber } from '../../../utils/helpers';

import GaugeChart from 'react-gauge-chart';

const PortalNeedleChart = ({
  chartKey,
  red = 0,
  amber = 80,
  green = 90,
  min = 0,
  max = 100,
  originalReportRows,
  valueDataLabel,
}) => {
  const preValue = originalReportRows[0][valueDataLabel];

  const originalValue = preValue ? Math.round(Number(preValue)) : 0;

  const displayValue = calculatePercentageInRange(originalValue, min, max);

  const convertedRed = calculatePercentageInRange(red, min, max);
  const convertedAmber = calculatePercentageInRange(amber, min, max);
  const convertedGreen = calculatePercentageInRange(green, min, max);

  const arcs = [];

  if (validAsNumber(convertedRed)) {
    arcs.push({ colour: '#c82728', value: convertedRed });
  }
  if (validAsNumber(convertedAmber)) {
    arcs.push({ colour: '#ffd717', value: convertedAmber });
  }
  if (validAsNumber(convertedGreen)) {
    arcs.push({ colour: '#169928', value: convertedGreen });
  }

  arcs.sort((a, b) => a.value - b.value);

  const arcsVals = [];

  if (arcs.length <= 1) {
    arcsVals.push(1);
  } else {
    arcs.forEach(({ value }, index) => {
      if (index === 0) {
        arcsVals.push(arcs[1].value / 100);
      } else if (index === 1) {
        arcsVals.push(((arcs[2]?.value ?? 100) - value) / 100);
      } else if (index === 2) {
        arcsVals.push((100 - value) / 100);
      }
    });
  }

  // lowest value must equal the min
  // can't have 2 values the same
  // highest value must be lower than the max
  // must be at least 2 values

  return (
    <GaugeChart
      id={`gauge-chart-${chartKey}`}
      colors={arcs.length ? arcs.map(({ colour }) => colour) : ['#978aa6']}
      arcWidth={0.25}
      arcPadding={0.02}
      cornerRadius={10}
      arcsLength={arcsVals}
      percent={displayValue / 100}
      needleColor='#978aa670'
      needleBaseColor='#978aa6bf'
      textColor='#333333'
      formatTextValue={() => originalValue}
    />
  );
};

export default PortalNeedleChart;
