import {
  created,
  evaluations,
  lastModified,
} from '../../../utils/columns/columnTypes';
import { useEffect, useState } from 'react';

import extractUrlQuery from '../../../utils/urlQuery/extractUrlQuery';
import Filter from '../DataGrid/GridOptions/Filter';

import './ReportFilter.css';

const ReportFilter = ({
  filterColumns,
  filterColumnTypes,
  query,
  selectedReportData,
  setExternalIsChangingCriteria,
  setFilterColumns,
  setFilterColumnTypes,
  setQuery,
  translateEvaluations,
}) => {
  const masterFilterInitialState = query
    ? extractUrlQuery(query)
    : { uniqueRef: 1, and: [] };

  const [savedQuery, setSavedQuery] = useState(query);

  const [masterFilter, setMasterFilter] = useState(masterFilterInitialState);

  const [noOfFilters, setNoOfFilters] = useState(0);

  const onUpdateFilter = (optionChanged, newValue) => {
    if (query !== newValue) {
      setQuery(newValue);
    }
  };

  const findColumnType = {
    'Drop down': 'string',
    'Check box': 'string bool', // ?
    Date: 'datetime',
    Time: 'time', // ?
    'Text box': 'string',
    Number: 'int', // ?
  };

  const evalsColumnTypes = {
    ...created,
    ...evaluations,
    ...lastModified,
    scorecard_name: 'string',
    pass_fail: 'selectFrom',
    brag: 'selectFrom',
    status: 'selectFrom',
  };

  const [selectFrom, setSelectFrom] = useState({
    'evals_Text box_Status': [
      ['completed', 'Completed'],
      ['draft', 'Draft'],
    ],
    'evals_Text box_RAG': [
      ['green', 'Green'],
      ['amber', 'Amber'],
      ['red', 'Red'],
    ],
    'evals_Text box_Grade': [
      ['pass', 'Pass'],
      ['fail', 'Fail'],
    ],
  });

  const handleSetFilterColumnData = () => {
    const columns = {};
    const columnTypes = {};

    Object.entries(selectedReportData).forEach(([key, data]) => {
      if (data.source_table === 'scoring') {
        setSelectFrom((curr) => {
          const copiedSelectFrom = { ...curr };

          copiedSelectFrom[key] = [
            ['pass', 'Pass'],
            ['fail', 'Fail'],
            ['na', 'NA'],
          ];

          return copiedSelectFrom;
        });
      }

      columns[key] = data.title;

      columnTypes[key] =
        data.source_table === 'admin'
          ? findColumnType[data.control]
          : data.source_table === 'cat'
          ? 'int'
          : data.source_table === 'comments'
          ? 'string'
          : data.source_table === 'scoring'
          ? 'selectFrom'
          : data.source_table === 'evals'
          ? evalsColumnTypes[translateEvaluations[data.title]]
          : 'string';
    });

    setFilterColumns(columns);
    setFilterColumnTypes(columnTypes);
  };

  useEffect(() => {
    handleSetFilterColumnData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportData]);

  return (
    <div className='filter-report-data-area'>
      <Filter
        availableColumns={filterColumns}
        columnTypes={filterColumnTypes}
        isReportsFilter
        noOfFilters={noOfFilters}
        onUpdateOptions={onUpdateFilter}
        query={query}
        savedQuery={savedQuery}
        selectFrom={selectFrom}
        setExternalIsChangingCriteria={setExternalIsChangingCriteria}
        setNoOfFilters={setNoOfFilters}
        setSavedQuery={setSavedQuery}
        setShowFilter={() => true}
        showFilter={true}
        masterFilterInitialState={masterFilterInitialState}
        masterFilter={masterFilter}
        setMasterFilter={setMasterFilter}
      />
    </div>
  );
};

export default ReportFilter;
