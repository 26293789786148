import { ThreeDots } from 'react-loader-spinner';

import './LoadingSpinnerNew.css';

const LoadingSpinnerNew = () => {
  return (
    <ThreeDots
      visible={true}
      height='50'
      width='50'
      color='#4c3b4d'
      radius='9'
      ariaLabel='three-dots-loading'
      wrapperStyle={{}}
      wrapperClass='loading-spinner-new'
    />
  );
};

export default LoadingSpinnerNew;
