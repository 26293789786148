import { Link } from 'react-router-dom';
import { navItemsMain, navItemsNew } from './allNavItems';
import { scrollToTop } from '../../../utils/createScorecardUtils/createScorecardUtils';
import { useContext, useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { UserPortal } from '../../../contexts';

import AddFavourite from './AddFavourite';
import DataBox from '../../Elements/DataBox';
import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';
import Icon from '../../Elements/Icon';
import logo from '../../../images/qaportal-logo.png';
import NavItem from './NavItem';
import Text from '../../Elements/Text';

import './Nav.css';

const Nav = ({
  demo,
  isNavOpen,
  navFavourites,
  setIsNavOpen,
  setNavFavourites,
}) => {
  const { userFromDb } = useContext(UserPortal);

  const [isEditingFavourites, setIsEditingFavourites] = useState(false);
  const [isAddingNewFavourite, setIsAddingNewFavourite] = useState(false);

  const detectClickOutsideRef = useDetectClickOutside({
    onTriggered: (event) => {
      if (
        event?.target?.parentElement?.classList?.value?.includes(
          'ignore-detect-click-outside'
        ) ||
        event?.target?.classList?.value?.includes('ignore-detect-click-outside')
      )
        return;

      setIsNavOpen(false);
    },
  });

  const portalIdPath = demo ? 'demo' : userFromDb?.portal_id;

  const handleEditNavFavourites = () => {
    setIsEditingFavourites((curr) => !curr);
  };

  const handleAddNavFav = () => {
    setIsAddingNewFavourite(true);
  };

  useEffect(() => {
    scrollToTop(detectClickOutsideRef);
    setIsEditingFavourites(false);
    setIsAddingNewFavourite(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNavOpen]);

  const addNavButton = (
    <div
      key='add-new-nav-item'
      className={`nav-item ${
        isAddingNewFavourite ? 'nav-item-current disabled-nav-item' : ''
      }`}
      onClick={handleAddNavFav}
    >
      <div className='nav-item-icon-container'>
        <Icon className='nav-item-icon' iconName='add' />
      </div>
    </div>
  );

  const navFavouriteItems = navFavourites?.map((itemLink, index) => {
    let item;

    if (itemLink.slice(0, 8) === 'custom--') {
      const afterIsCustom = itemLink.slice(8);

      const icon = afterIsCustom.slice(0, afterIsCustom.indexOf('--'));

      const afterIcon = afterIsCustom.slice(icon.length + 2);

      const subIcon = afterIcon.slice(0, afterIcon.indexOf('--'));

      const afterSubIcon = afterIcon.slice(subIcon.length + 2);

      const name = afterSubIcon.slice(0, afterSubIcon.indexOf('--..//'));

      const link = afterSubIcon.slice(name.length + 6);

      item = { name, icon, link };

      if (subIcon !== 'none') item.subIcon = subIcon;
    } else {
      item = [...navItemsMain, ...navItemsNew].find(
        (navItem) => navItem.link === itemLink
      );

      item.noPermission =
        !demo &&
        item.permissions &&
        !item.permissions.some((permission) =>
          userFromDb.permissions?.includes(permission)
        );
    }

    return (
      <NavItem
        key={index}
        disabled={item.disabled}
        name={item.name}
        icon={item.icon}
        isEditingFavourites={isEditingFavourites}
        link={`/${portalIdPath}/${item.link}`}
        itemLink={itemLink}
        subIcon={item.subIcon}
        setIsNavOpen={setIsNavOpen}
        setNavFavourites={setNavFavourites}
        navFavourites={navFavourites}
        noPermission={item.noPermission}
        demo={demo}
      />
    );
  });

  if (isEditingFavourites) navFavouriteItems.push(addNavButton);

  const filteredNavItemsNew = navItemsNew.filter(
    (item) =>
      demo ||
      !item.permissions ||
      item.permissions.some((permission) =>
        userFromDb.permissions?.includes(permission)
      )
  );

  const daysRemaining = parseInt(userFromDb.portal_trial_info?.daysRemaining);

  return (
    <div className='portal-nav'>
      {isAddingNewFavourite && (
        <AddFavourite
          demo={demo}
          navFavourites={navFavourites}
          navItemsMain={navItemsMain}
          navItemsNew={navItemsNew}
          setIsAddingNewFavourite={setIsAddingNewFavourite}
          setNavFavourites={setNavFavourites}
        />
      )}

      <div
        className={`nav-container ${
          isNavOpen ? 'nav-container-open' : ''
        } ignore-detect-click-outside`}
        style={{
          transform: `translate(${isNavOpen ? '0' : `calc(-100%)`})`,
          width: '100%',
        }}
        ref={detectClickOutsideRef}
      >
        {userFromDb.portal_trial_info?.isInTrial ? (
          <div
            className={`trial-info-banner ${
              daysRemaining <= 3 ? 'trial-info-banner-red' : ''
            }`}
          >
            <span>QA Portal free trial - </span>
            <span className='trial-info-banner-bold'>{daysRemaining} </span>
            <span className='trial-info-banner-bold'>
              day{daysRemaining === 1 ? '' : 's'} remaining -{' '}
            </span>
            <Link
              onClick={() => setIsNavOpen(false)}
              to={`/${portalIdPath}/billing`}
            >
              Click here to arrange a subscription
            </Link>
          </div>
        ) : (
          <></>
        )}

        <FlexContainer justifyContent='space-between' alignItems='flex-start'>
          <FlexContainer flexDirection='column' alignItems='flex-start'>
            {userFromDb?.first_name || userFromDb?.org || demo ? (
              <>
                <HeaderSecondary
                  classes='nav-user-name-header'
                  text={
                    demo
                      ? 'Demo User'
                      : userFromDb?.first_name
                      ? `${userFromDb.first_name}'s Portal`
                      : ''
                  }
                />

                <HeaderSecondary
                  classes='nav-user-org-header'
                  text={demo ? 'Demo Company' : userFromDb?.org || ''}
                />
              </>
            ) : (
              <div style={{ marginBottom: '100px' }}></div>
            )}
          </FlexContainer>

          <FlexContainer
            alignItems='flex-end'
            flexDirection='column'
            margin='25px 30px 0 10px'
          >
            <Link
              onClick={() => setIsNavOpen(false)}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '12px',
              }}
              to='/guide'
            >
              <Icon
                iconName='circle-info'
                style={{ height: '20px', margin: '0 10px', width: '20px' }}
              />

              <Text text='User Guide' />
            </Link>

            <Link
              onClick={() => setIsNavOpen(false)}
              style={{ display: 'flex', alignItems: 'center' }}
              to='/contact'
            >
              <Icon
                iconName='envelope'
                style={{ height: '20px', margin: '0 10px', width: '20px' }}
              />

              <Text text='Contact Us' />
            </Link>
          </FlexContainer>
        </FlexContainer>

        {navFavourites ? (
          <DataBox
            classes='nav-data-box'
            name='Favourites'
            items={navFavouriteItems}
            canEditDataBox
            handleEditDataBox={handleEditNavFavourites}
            isCollapsible={!isEditingFavourites}
            isEditingFavourites={isEditingFavourites}
            setNavFavourites={setNavFavourites}
            navFavourites={navFavourites}
            addNavButton={addNavButton}
            setIsNavOpen={setIsNavOpen}
            demo={demo}
          />
        ) : (
          <></>
        )}

        <DataBox
          classes='nav-data-box'
          headerBackgroundColour='#fafafa'
          name='Main Menu'
          items={navItemsMain
            .filter(
              (item) =>
                demo ||
                !item.permissions ||
                item.permissions.some((permission) =>
                  userFromDb.permissions?.includes(permission)
                )
            )
            .map((item, index) => (
              <NavItem
                key={index}
                disabled={item.disabled}
                name={item.name}
                icon={item.icon}
                link={`/${portalIdPath}/${item.link}`}
                subIcon={item.subIcon}
                setIsNavOpen={setIsNavOpen}
              />
            ))}
          isCollapsible
        />

        {filteredNavItemsNew.length ? (
          <DataBox
            classes='nav-data-box nav-data-box-last'
            headerBackgroundColour='#fafafa'
            name='Add New'
            items={filteredNavItemsNew.map((item, index) => (
              <NavItem
                key={index}
                disabled={item.disabled}
                name={item.name}
                icon={item.icon}
                link={`/${portalIdPath}/${item.link}`}
                subIcon={item.subIcon}
                setIsNavOpen={setIsNavOpen}
              />
            ))}
            isCollapsible
          />
        ) : (
          <></>
        )}
        <img className='nav-screen-logo' src={logo} alt='QA Portal Logo' />
      </div>
    </div>
  );
};

export default Nav;
