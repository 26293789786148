import './Divider.css';

const Divider = ({ classes = '', isLighter, isThinner, margin }) => {
  const style = {};

  if (margin) style.margin = margin;

  return (
    <div
      className={`divider divider-transparent ${
        isLighter ? 'divider-transparent-lighter' : ''
      } ${isThinner ? 'divider-transparent-thinner' : ''} ${classes}`}
      style={style}
    />
  );
};

export default Divider;
