import {
  agentsAvailableColumns,
  agentsUnEditableColumns,
  agentsValidationColumns,
} from '../columns';
import { handleApiError } from '../../../utils/error-handling';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { UserPortal } from '../../../contexts';

import apiDataService from '../../../api/agents';
import apiDataServiceOrgLevels from '../../../api/org-levels';
import DataGrid from '../DataGrid/DataGrid';
import LoadingSpinner from '../../Elements/LoadingSpinner';

const Agents = ({ demo }) => {
  const alert = useAlert();

  const { getAccessTokenSilently } = useAuth0();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const [defaultColumns, setDefaultColumns] = useState();

  const getAvailableColumns = (columns = {}) => {
    return { ...agentsAvailableColumns, ...columns };
  };

  const defaultOptions = {
    customColumns: null,
    limit: 10,
    page: 1,
    query: null,
    sortBy: null,
    view: 'table',
  };

  const getOrgLevels = async () => {
    try {
      const { orgLevels } = await apiDataServiceOrgLevels.getAll({
        portalId: demo ? 'demo' : portalId,
        userId: demo ? 1 : userId,
        token: demo ? undefined : await getAccessTokenSilently(),
        params: [],
      });

      const columnTitles = orgLevels.map((orgLevel, index) => `level${index}`);

      const columnsArray = columnTitles.sort(
        (a, b) => parseInt(a.slice(5)) - parseInt(b.slice(5))
      );

      const slicedColumns =
        columnsArray.length > 10 ? columnsArray.slice(0, 10) : columnsArray;

      setDefaultColumns(slicedColumns);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  useEffect(() => {
    getOrgLevels();

    return () => {
      setDefaultColumns();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!defaultColumns) return <LoadingSpinner />;
  return (
    <DataGrid
      apiDataService={apiDataService}
      defaultColumns={defaultColumns}
      defaultOptions={defaultOptions}
      demo={demo}
      getAvailableColumns={getAvailableColumns}
      gridName='agents'
      iconName='headset'
      newScreenRoute='new'
      unEditableColumns={agentsUnEditableColumns}
      validationColumns={agentsValidationColumns(defaultColumns)}
    />
  );
};

export default Agents;
