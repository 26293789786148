import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { UserPortal } from '../../contexts';

import accountIcon from '../../images/account_icon.png';
import accountIcon2 from '../../images/account_icon_2.png';
import About from '../../components/Home/About';
import AuthenticationButton from '../Authentication/AuthenticationButton';
import FlexContainer from '../Elements/FlexContainer';
import HeaderPrimary from '../Elements/HeaderPrimary';
import logo from '../../images/qaportal-logo.png';

import './Contact.css';
import './Try.css';

const Try = () => {
  const { isAuthenticated } = useAuth0();

  const { userFromDb } = useContext(UserPortal);

  return (
    <div className='contact-screen home-screen'>
      <HeaderPrimary
        classes='try-screen-header'
        margin='60px 0 0 0'
        style={{ fontWeight: '300' }}
        text='Try'
      />

      <img className='try-screen-logo' src={logo} alt='QA Portal Logo' />

      <HeaderPrimary
        classes='try-screen-header'
        justifyContent='flex-end'
        margin='0 0 60px 0'
        style={{ fontWeight: '300' }}
        text='For Free'
      />

      <p
        style={{
          color: '#4c3b4d',
          fontSize: '40px',
          fontWeight: '600',
          lineHeight: '1.05',
          textAlign: 'center',
          margin: '30px 0',
        }}
      >
        Create and administer a portal for free for up to 30 days
      </p>

      <br />
      <br />

      <div className='try-screen-info'>
        {isAuthenticated ? (
          <FlexContainer>
            <span>
              <span className='try-screen-text'>Click your account icon (</span>

              <span>
                <img
                  style={{ height: '16px' }}
                  src={userFromDb.portal_id ? accountIcon2 : accountIcon}
                  alt='account'
                />
              </span>

              <span className='try-screen-text'>
                ) at the top of the screen to get started.
              </span>
            </span>
          </FlexContainer>
        ) : (
          <FlexContainer>
            <AuthenticationButton classes='try-screen-info-login-button' />
          </FlexContainer>
        )}

        <br />
        <br />

        <p className='try-screen-text'>
          You won't be required to enter any payment details during your free
          trial, and you can get started straight away.
        </p>

        <br />

        {/* <p className='try-screen-text'>
          For further information please take a look at our
          <span>
            <Link style={{ fontSize: '20px' }} to='/faq'>
              {' '}
              FAQ's
            </Link>
          </span>
          .
        </p>

        <br /> */}

        <p className='try-screen-text'>
          If you have any questions you'd like to ask before signing up, please
          <span>
            <Link style={{ fontSize: '20px' }} to='/contact'>
              {' '}
              contact us{' '}
            </Link>
          </span>
          and we'll be happy to help.
        </p>

        <br />

        <p className='try-screen-text'>
          View pricing details
          <span>
            <Link style={{ fontSize: '20px' }} to='/pricing'>
              {' '}
              here
            </Link>
          </span>
          .
        </p>

        <br />
        <br />

        <p className='try-screen-text'>
          Take a look at our Demo portal and explore it's features.
        </p>

        <br />

        <FlexContainer>
          <Link
            className='try-screen-info-demo-button'
            to='/demo'
            state={{ isNavigating: true }}
          >
            <div className='nav-button-inner'>Demo</div>
          </Link>
        </FlexContainer>

        <br />
      </div>

      <div className='nested-about-screen'>
        <About />
      </div>
    </div>
  );
};

export default Try;
