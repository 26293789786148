import { organiseColumns } from '../../../utils/helpers';

import DraggableToggleList from '../../Elements/DraggableToggleList';

import './ReportFilter.css';

const ReportSort = ({ sort, setSort }) => {
  const handleToggleSort = (column, direction) => {
    if (direction !== '' && direction !== '-') {
      setSort((currentColumns) => {
        const newColumns = currentColumns.map((columnObj) => {
          if (columnObj.id === column)
            return {
              ...columnObj,
              toggled: !columnObj.toggled,
              direction: '',
            };
          else return columnObj;
        });

        return organiseColumns(newColumns);
      });
    } else {
      setSort((currentColumns) =>
        currentColumns.map((columnObj) => {
          if (columnObj.id === column)
            return {
              ...columnObj,
              direction: columnObj.direction === '' ? '-' : '',
            };
          else return columnObj;
        })
      );
    }
  };

  return (
    <div className='report-aggregate-area'>
      <DraggableToggleList
        cards={sort}
        setCards={setSort}
        itemType='sort-item'
        handleToggle={handleToggleSort}
        optionIconToggle={{
          cardParam: 'direction',
          icons: ['sort-up', 'sort-down'],
          options: ['', '-'],
          text: ['Ascending', 'Descending'],
        }}
        toggleHeight={40}
        uniqueSectionId='sort'
      />
    </div>
  );
};

export default ReportSort;
