import { handleApiError } from '../../utils/error-handling';
import { mapEntriesToObjects } from '../../utils/helpers';
import { useAlert } from 'react-alert';
import { useContext, useState } from 'react';
import { UserPortal } from '../../contexts';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import party, { Color } from 'party-js';

import AdminSection from './AdminSection';
import apiDataServiceAgents from '../../api/agents';
import apiDataServiceCalibrations from '../../api/calibrations';
import apiDataServiceEvaluations from '../../api/evaluations';
import Feedback from './Feedback';
import FillScoringCategories from './FillScoringCategories';
import FillScoringSections from '../CreateScorecard/FillScoringSections';
import FloatingResults from '../Portal/Evaluations/FloatingResults';
import InputInvalidIcon from '../Elements/InputInvalidIcon';
import portalConfirmAlert from '../../utils/portalConfirmAlert';
import Text from '../Elements/Text';

import './Scorecard.css';

const Scorecard = ({
  adminSections,
  bragAmber,
  bragBlack,
  bragGreen,
  bragRed,
  calibrationId,
  calibrationLocked,
  categoryOnlyList,
  demo,
  evaluation,
  fromCalibrations,
  fromDrafts,
  fromSubmittedEvaluations,
  handlePrint,
  id,
  isCalibrationBenchmark,
  isEditingEvaluation,
  isEditingScorecard,
  isEvaluation,
  isNew,
  isNewEvaluation,
  isUserAParticipant,
  isUserHost,
  logo,
  moduleStyles,
  orgName,
  passMark,
  scorecardName,
  scoringSections,
  sectionPos,
  sectionNeg,
  setIsEditingEvaluation,
  vcid,
}) => {
  const brag = [
    { colour: 'green', value: bragGreen },
    { colour: 'amber', value: bragAmber },
    { colour: 'red', value: bragRed },
    { colour: 'black', value: bragBlack },
  ].sort((a, b) => b.value - a.value);

  const { getAccessTokenSilently } = useAuth0();

  const alert = useAlert();

  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;

  const hasEvaluationsChangePermission =
    demo ||
    (!isCalibrationBenchmark &&
      userFromDb.permissions?.includes('evaluations.change')) ||
    isUserAParticipant ||
    isUserHost;

  const navigate = useNavigate();

  const [qualityScore, setQualityScore] = useState(
    evaluation?.quality_score ?? 100
  );

  const [bragBackground, setBragBackground] = useState(
    `scorecard-bragback-${evaluation?.brag ?? 'plain'}`
  );

  const categoryScoresFromEval = {};

  let ra = 0;
  let weightOutOf = 0;
  let weightTotal = 0;
  const csm = [];

  let markedCount = 0;
  const passedSections = [];
  const failedSections = [];
  const failedRa = [];
  const failedWeight = [];
  const failedCsm = [];

  const scoringResultsFromEval = {};

  const commentsResultsFromEval = {};

  evaluation?.evaluatedSections?.scoringSecs.forEach((cat) => {
    if (cat.type !== 'no-cat')
      categoryScoresFromEval[`cat${cat.id}`] = cat.data;

    cat.sections.forEach((sec) => {
      if (sec.data === 'pass' || sec.data === 'fail') {
        markedCount++;

        if (sec.data === 'fail') {
          if (sec.ra) ra += parseInt(sec.ra);
          if (sec.csm) {
            csm.push({
              csmAmount: sec.csm,
              sectionName: sec.title,
              sectionRef: sec.type + sec.id,
            });

            csm.sort((a, b) => a.csmAmount - b.csmAmount);
          }
        }

        if (sec.weight) {
          weightOutOf += Number(sec.weight);

          if (sec.data === 'pass') weightTotal += Number(sec.weight);
        }

        if (sec.data === 'pass') {
          passedSections.push({
            ...sec,
            uniqueRef: sec.type + sec.id,
          });
        } else {
          failedSections.push({
            ...sec,
            uniqueRef: sec.type + sec.id,
          });
          if (sec.weight)
            failedWeight.push({
              ...sec,
              uniqueRef: sec.type + sec.id,
            });
          if (sec.ra)
            failedRa.push({
              ...sec,
              uniqueRef: sec.type + sec.id,
            });
          if (sec.csm)
            failedCsm.push({
              ...sec,
              uniqueRef: sec.type + sec.id,
            });
        }
      }

      if (sec.data === 'pass' || sec.data === 'fail' || sec.data === 'na') {
        scoringResultsFromEval[sec.id] = sec.data;
      }

      if (sec.type === 'comments' && sec.data)
        commentsResultsFromEval[sec.id] = sec.data;
    });
  });

  const [categoryScores, setCategoryScores] = useState(
    evaluation ? categoryScoresFromEval : {}
  );

  const [behaviourTotals, setBehaviourTotals] = useState(
    evaluation
      ? { weightOutOf, weightTotal, ra, csm }
      : {
          weightOutOf: 0,
          weightTotal: 0,
          ra: 0,
          csm: [],
        }
  );

  const [sectionResults, setSectionResults] = useState(
    evaluation
      ? {
          markedCount,
          passedSections,
          failedSections,
          failedRa,
          failedWeight,
          failedCsm,
        }
      : {
          markedCount: 0,
          passedSections: [],
          failedSections: [],
          failedRa: [],
          failedWeight: [],
          failedCsm: [],
        }
  );

  const adminResultsFromEval = {};

  evaluation?.evaluatedSections?.adminSecs.forEach((sec) => {
    adminResultsFromEval[sec.id] = sec.data;
  });

  const presetCheckBoxSections = {};

  adminSections.forEach((section) => {
    if (section.control === 'Check box')
      presetCheckBoxSections[section.id] = 'No';
  });

  const [adminResults, setAdminResults] = useState(
    evaluation ? adminResultsFromEval : presetCheckBoxSections
  );
  const [commentsResults, setCommentsResults] = useState(
    evaluation ? commentsResultsFromEval : {}
  );
  const [scoringResults, setScoringResults] = useState(
    evaluation ? scoringResultsFromEval : {}
  );

  const [showInvalid, setShowInvalid] = useState(false);

  const [invalidSections, setInvalidSections] = useState({});

  const [mainComments, setMainComments] = useState(
    evaluation?.comments_main ?? ''
  );

  const [editChangesAdmin, setEditChangesAdmin] = useState({});
  const [editChangesScoring, setEditChangesScoring] = useState({});
  const [editChangesCat, setEditChangesCat] = useState({});
  const [editChangesComments, setEditChangesComments] = useState({});

  const handleUpdateAdminSection = async (
    sectionId,
    value,
    ddListTable,
    ddListId
  ) => {
    if (value === 'Invalid date') value = '';

    setAdminResults((curr) => {
      const currCopy = { ...curr };

      if (!value && value !== false && currCopy[sectionId])
        delete currCopy[sectionId];
      else currCopy[sectionId] = value;

      return currCopy;
    });

    if (evaluation)
      setEditChangesAdmin((curr) => {
        const currCopy = { ...curr };

        currCopy[sectionId] = value;

        return currCopy;
      });

    if (ddListTable === 'org_levels' && value) {
      const { agentData } =
        await apiDataServiceAgents.getAgentRelatedDataByName({
          token: demo ? undefined : await getAccessTokenSilently(),
          userId: demo ? 1 : userId,
          portalId: demo ? 'demo' : portalId,
          listId: ddListId,
          name: value,
        });

      Object.keys(agentData).forEach((listId) => {
        const newAdminSections = adminSections.filter((section) => {
          return (
            section.dd_list_id?.toString() === listId &&
            section.dd_list_table === 'org_levels'
          );
        });

        newAdminSections.forEach((section) => {
          const currentData = adminResults[section.id];
          const potentialDatas = agentData[listId];

          if (!potentialDatas.includes(currentData)) {
            setAdminResults((curr) => {
              const currCopy = { ...curr };

              currCopy[section.id] = potentialDatas[0];

              return currCopy;
            });

            if (evaluation)
              setEditChangesAdmin((curr) => {
                const currCopy = { ...curr };

                currCopy[section.id] = potentialDatas[0];

                return currCopy;
              });
          }
        });
      });
    }
  };

  const handleSetCommentsResults = async (sectionId, value) => {
    setCommentsResults((curr) => {
      const currCopy = { ...curr };

      if (!value && currCopy[sectionId]) delete currCopy[sectionId];
      else currCopy[sectionId] = value || null;

      return currCopy;
    });

    if (evaluation)
      setEditChangesComments((curr) => {
        const currCopy = { ...curr };

        currCopy[sectionId] = value || null;

        return currCopy;
      });
  };

  const handleSetScoringResults = async (sectionId, value) => {
    setScoringResults((curr) => {
      const currCopy = { ...curr };

      if (!value && currCopy[sectionId]) delete currCopy[sectionId];
      else currCopy[sectionId] = value;

      return currCopy;
    });

    if (evaluation)
      setEditChangesScoring((curr) => {
        const currCopy = { ...curr };

        currCopy[sectionId] = value;

        return currCopy;
      });
  };

  const handleSetInvalidSections = (sectionRef, isInvalid) => {
    setInvalidSections((curr) => {
      const currCopy = { ...curr };

      if (isInvalid) currCopy[sectionRef] = true;
      else if (currCopy[sectionRef]) delete currCopy[sectionRef];

      return currCopy;
    });
  };

  const updateMainComments = (event) => {
    const { value } = event.target;

    if (value.length > 5000) setMainComments(value.slice(0, 4999));
    else setMainComments(value);
  };

  const handleDeleteEvaluation = async () => {
    // if deleting from calibrations, navigate back to the calibration

    if (demo) return alert.info('Unable to save changes in demo');

    const confirmed = await portalConfirmAlert({
      message: `Are you sure you want to delete this ${
        fromCalibrations
          ? 'calibration scorecard'
          : fromDrafts
          ? 'draft evaluation'
          : 'evaluation'
      }?`,
    });

    if (!confirmed) return;

    try {
      await apiDataServiceEvaluations.gridDeleteMultiple({
        selectedRecordIds: [evaluation.id],
        portalId: demo ? 'demo' : portalId,
        reqBody: { userId: demo ? 1 : userId },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      alert.success('Evaluation Deleted');

      navigate(
        `/${demo ? 'demo' : portalId}/${
          fromDrafts
            ? 'drafts'
            : fromCalibrations
            ? `calibrations/${calibrationId}`
            : 'evaluations'
        }`,
        {
          state: { isNavigating: true },
        }
      );
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const handleUpdateEvaluation = async (saveStatus) => {
    if (demo) return alert.info('Unable to save changes in demo');

    const evaluations = {};

    if (saveStatus === 'completed' || saveStatus === 'submitted') {
      setShowInvalid(true);

      if (Object.keys(invalidSections).length)
        return alert.show(
          <div className='invalid-sections-alert'>
            <div>Required</div>
            <div>data</div>
            <div>missing.</div>
            <div>Please</div>
            <div>complete</div>
            <div>sections</div>
            <div>marked</div>
            <div>with</div>
            <InputInvalidIcon />
          </div>,
          { timeout: 5000 }
        );
    }

    if (qualityScore !== evaluation.quality_score) {
      evaluations.quality_score = sectionResults.markedCount
        ? qualityScore
        : null;

      if (bragRed || bragGreen || bragAmber || bragBlack) {
        const newBrag = evaluations.quality_score
          ? brag.find((bragObj) => qualityScore >= bragObj.value).colour
          : null;

        if (newBrag !== evaluation.brag) evaluations.brag = newBrag;
      }

      if (passMark) {
        const newPassMark = evaluations.quality_score
          ? qualityScore >= passMark
            ? 'pass'
            : 'fail'
          : null;

        if (newPassMark !== evaluation.pass_fail)
          evaluations.pass_fail = newPassMark;
      }
    }

    if (mainComments !== evaluation.comments_main)
      evaluations.comments_main = mainComments || null;

    if (saveStatus !== evaluation.status) evaluations.status = saveStatus;

    if (
      isCalibrationBenchmark &&
      (saveStatus === 'completed' || saveStatus === 'submitted')
    ) {
      evaluations.status = 'benchmark';
    }

    if (
      !isCalibrationBenchmark &&
      (saveStatus === 'completed' || saveStatus === 'submitted')
    ) {
      evaluations.status = saveStatus;
    }

    if (Object.keys(evaluations).length) evaluations.id = evaluation.id;

    const adminChanges = Object.entries(editChangesAdmin);
    const scoringChanges = Object.entries(editChangesScoring);
    const commentsChanges = Object.entries(editChangesComments);
    const catChanges = Object.entries(editChangesCat);

    const newAdminChanges = adminChanges.map(([id, data]) => {
      const { result_id } = evaluation.evaluatedSections.adminSecs.find(
        (sec) => sec.id === parseInt(id)
      );

      return [result_id, data];
    });

    const newScoringChanges = scoringChanges.map(([id, data]) => {
      const { result_id } = evaluation.evaluatedSections.scoringSecs
        .flatMap((obj) => obj.sections)
        .find((sec) => sec.id === parseInt(id) && sec.type === 'scoring');

      return [result_id, data];
    });

    const newCommentsChanges = commentsChanges.map(([id, data]) => {
      const { result_id } = evaluation.evaluatedSections.scoringSecs
        .flatMap((obj) => obj.sections)
        .find((sec) => sec.id === parseInt(id) && sec.type === 'comments');

      return [result_id, data];
    });

    const newCatChanges = catChanges.map(([id, data]) => {
      const { result_id } = evaluation.evaluatedSections.scoringSecs.find(
        (sec) => sec.id === parseInt(id) && sec.type === 'cat'
      );

      const catScore = categoryScores[`cat${id}`];

      return catScore || catScore === 0 ? [result_id, data] : [result_id, null];
    });

    const tables = {};

    if (Object.keys(evaluations).length) tables.evaluations = [evaluations];
    if (newAdminChanges.length)
      tables.sc_results_admin = mapEntriesToObjects(newAdminChanges);
    if (newScoringChanges.length)
      tables.sc_results_scoring = mapEntriesToObjects(newScoringChanges);
    if (newCommentsChanges.length)
      tables.sc_results_comments = mapEntriesToObjects(newCommentsChanges);
    if (newCatChanges.length)
      tables.sc_results_cat = mapEntriesToObjects(newCatChanges);

    if (!Object.keys(tables).length) return alert.show('No changes');

    try {
      await apiDataServiceEvaluations.update({
        portalId: demo ? 'demo' : portalId,
        reqBody: { updates: { tables }, userId: demo ? 1 : userId },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      if (isCalibrationBenchmark && calibrationLocked) {
        await apiDataServiceCalibrations.updateCalibrations({
          portalId: demo ? 'demo' : portalId,
          reqBody: {
            lockOrUnlock: { lock: [calibrationId] },
            userId: demo ? 1 : userId,
          },
          token: demo ? undefined : await getAccessTokenSilently(),
        });
      }

      const green = new Color(12, 76, 20);

      alert.success('Evaluation saved');

      party.sparkles(
        document.getElementById('__react-alert__').querySelector('span'),
        { color: green }
      );

      navigate(
        `/${demo ? 'demo' : portalId}/${
          saveStatus === 'draft'
            ? 'drafts'
            : fromCalibrations
            ? `calibrations/${calibrationId}`
            : 'evaluations'
        }`,
        {
          state: { isNavigating: true },
        }
      );
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  // what if there's no agent id?
  const handleSaveEvaluation = async (saveStatus) => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (saveStatus === 'completed' || saveStatus === 'submitted') {
      setShowInvalid(true);

      if (Object.keys(invalidSections).length)
        return alert.show(
          <div className='invalid-sections-alert'>
            <div>Required</div>
            <div>data</div>
            <div>missing.</div>
            <div>Please</div>
            <div>complete</div>
            <div>sections</div>
            <div>marked</div>
            <div>with</div>
            <InputInvalidIcon />
          </div>,
          { timeout: 5000 }
        );
    }

    const evaluationStatus =
      isCalibrationBenchmark &&
      (saveStatus === 'completed' || saveStatus === 'submitted')
        ? 'benchmark'
        : saveStatus;

    const evaluationData = {
      scorecard_id: id,
      scorecard_vcid: vcid,
      // agent_id: ,
      quality_score: sectionResults.markedCount ? qualityScore : null,
      brag:
        (!bragAmber && !bragBlack && !bragGreen && !bragRed) ||
        !sectionResults.markedCount
          ? null
          : brag.find((bragObj) => qualityScore >= bragObj.value).colour,
      pass_fail:
        passMark && sectionResults.markedCount
          ? qualityScore >= passMark
            ? 'pass'
            : 'fail'
          : null,
      comments_main: mainComments || null,
      fed_back: false,
      status: evaluationStatus,

      sc_results_admin: adminSections.map((section) => {
        if (adminResults[section.id] || adminResults[section.id] === false)
          return { section_id: section.id, data: adminResults[section.id] };
        else return { section_id: section.id, data: null };
      }),

      sc_results_cat: scoringSections
        .filter((cat) => cat.type !== 'no-cat')
        .map((category) => {
          const catScore = categoryScores[`cat${category.id}`];

          if (catScore || catScore === 0)
            return {
              section_id: category.id,
              data: catScore,
            };
          else return { section_id: category.id, data: null };
        }),

      sc_results_scoring: scoringSections
        .flatMap((cat) => cat.sections)
        .filter((section) => section.type === 'scoring')
        .map((section) => {
          if (scoringResults[section.id])
            return { section_id: section.id, data: scoringResults[section.id] };
          else return { section_id: section.id, data: null };
        }),

      sc_results_comments: scoringSections
        .flatMap((cat) => cat.sections)
        .filter((section) => section.type === 'comments')
        .map((section) => {
          if (commentsResults[section.id])
            return {
              section_id: section.id,
              data: commentsResults[section.id],
            };
          else return { section_id: section.id, data: null };
        }),
    };

    if (fromCalibrations) evaluationData.calibration_id = calibrationId;

    try {
      const { evaluationRows } = await apiDataServiceEvaluations.post({
        portalId: demo ? 'demo' : portalId,
        reqBody: {
          evaluations: [evaluationData],
          userId: demo ? 1 : userId,
        },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      if (isCalibrationBenchmark) {
        await apiDataServiceCalibrations.updateCalibrations({
          portalId: demo ? 'demo' : portalId,
          reqBody: {
            calibrations: [
              {
                benchmark_evaluation_id: evaluationRows[0].id,
                id: calibrationId,
              },
            ],
            lockOrUnlock: calibrationLocked
              ? { lock: [calibrationId] }
              : { unlock: [calibrationId] },
            userId: demo ? 1 : userId,
          },
          token: demo ? undefined : await getAccessTokenSilently(),
        });
      }

      const green = new Color(12, 76, 20);

      alert.success('Evaluation saved');

      party.sparkles(
        document.getElementById('__react-alert__').querySelector('span'),
        { color: green }
      );

      navigate(
        `/${demo ? 'demo' : portalId}/${
          saveStatus === 'draft'
            ? 'drafts'
            : fromCalibrations
            ? `calibrations/${calibrationId}`
            : 'evaluations'
        }`,
        {
          state: { isNavigating: true },
        }
      );
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const convertPassMark = (passMark) => {
    if (passMark === 'pass') return 'Pass';
    if (passMark === 'fail') return 'Fail';
    return 'Grade';
  };

  return (
    <>
      <div
        className={`scorecard-background ${
          isNew ? moduleStyles?.scorecardBackground ?? '' : ''
        }`}
      >
        {isEvaluation && (
          <FloatingResults
            hasEvaluationsChangePermission={hasEvaluationsChangePermission}
            categoryOnlyList={categoryOnlyList}
            categoryScores={categoryScores}
            fromCalibrations={fromCalibrations}
            fromDrafts={fromDrafts}
            fromSubmittedEvaluations={fromSubmittedEvaluations}
            handleDelete={
              calibrationLocked && !isCalibrationBenchmark
                ? () => {
                    alert.show('Calibration has been locked');
                  }
                : handleDeleteEvaluation
            }
            handlePrint={handlePrint}
            handleSaveEvaluation={
              calibrationLocked && !isCalibrationBenchmark
                ? () => {
                    alert.show('Calibration has been locked');
                  }
                : !!evaluation && !fromDrafts
                ? handleUpdateEvaluation
                : handleSaveEvaluation
            }
            handleSaveEvaluationAsDraft={handleSaveEvaluation}
            isEditingEvaluation={isEditingEvaluation}
            isNewEvaluation={isNewEvaluation}
            passMark={passMark}
            qualityScore={qualityScore}
            sectionResults={sectionResults}
            setIsEditingEvaluation={setIsEditingEvaluation}
            showScreen={!!evaluation && !fromDrafts}
          />
        )}

        <div className='scorecard-header'>
          {logo && (
            <img
              className={`scorecard-logo ${moduleStyles?.scorecardLogo ?? ''}`}
              src={logo}
              alt='logo'
            />
          )}

          {orgName && (
            <h2
              className={`scorecard-org-name ${
                moduleStyles?.scorecardOrgName ?? ''
              } wrap-unbroken`}
            >
              {orgName}
            </h2>
          )}

          <h1
            className={`scorecard-title ${
              moduleStyles?.scorecardTitle ?? ''
            } wrap-unbroken`}
          >
            {scorecardName ? scorecardName : '<scorecard name>'}
          </h1>
        </div>

        <div className='scorecard-top-results-container'>
          <div
            className={`scorecard-top-results ${
              moduleStyles?.scorecardTopResults ?? ''
            }`}
          >
            {evaluation?.fed_back ? (
              <div
                className={`scorecard-top-result-container scorecard-top-result-container-fed-back`}
              >
                <h1 className='scorecard-top-result'>Fed Back</h1>
              </div>
            ) : (
              <></>
            )}

            <div
              className={`scorecard-top-result-container ${
                sectionResults.markedCount ? bragBackground : ''
              }`}
            >
              <h1 className='scorecard-top-result'>
                {sectionResults.markedCount ? qualityScore : 'Score'}
              </h1>
            </div>

            {passMark && (
              <div className='scorecard-top-result-container scorecard-passmark-container'>
                <h1 className='scorecard-top-result'>
                  {evaluation?.imported && evaluation?.pass_fail
                    ? convertPassMark(evaluation.pass_fail)
                    : sectionResults.markedCount
                    ? qualityScore >= passMark
                      ? 'Pass'
                      : 'Fail'
                    : 'Grade'}
                </h1>
              </div>
            )}
          </div>
        </div>

        {adminSections.length > 0 && (
          <div className='scorecard-admin-sections-container'>
            <ul
              className={`scorecard-admin-sections ${
                (scoringSections.length &&
                  scoringSections[0]?.type === 'scoring-category') ||
                scoringSections[0]?.type === 'cat'
                  ? 'scorecard-admin-sections-less-margin'
                  : ''
              } ${moduleStyles?.scorecardAdminSections ?? ''}`}
            >
              {adminSections.map((section) => {
                const sectionId = isEvaluation ? section.id : section.uniqueRef;
                const elementId = 'a' + sectionId;

                return (
                  <AdminSection
                    key={elementId}
                    adminResults={adminResults}
                    demo={demo}
                    elementId={elementId}
                    fromDrafts={fromDrafts}
                    handleUpdateAdminSection={handleUpdateAdminSection}
                    isEvaluation={isEvaluation}
                    section={section}
                    sectionId={sectionId}
                    handleSetInvalidSections={handleSetInvalidSections}
                    showInvalid={showInvalid}
                    isEditingEvaluation={isEditingEvaluation}
                    isEditingScorecard={isEditingScorecard}
                  />
                );
              })}
            </ul>
          </div>
        )}

        {adminSections.length &&
        scoringSections.length &&
        scoringSections[0]?.type !== 'scoring-category' &&
        scoringSections[0]?.type !== 'cat' ? (
          <div
            style={{ marginBottom: '10px' }}
            className='scorecard-comments-divider'
          ></div>
        ) : (
          <></>
        )}

        {scoringSections.length > 0 && (
          <ul className='scorecard-scoring-sections'>
            {isNew
              ? scoringSections.map((section) => {
                  return (
                    <FillScoringSections
                      key={section.uniqueRef || section.position}
                      section={section}
                      sectionPos={sectionPos}
                      sectionNeg={sectionNeg}
                    />
                  );
                })
              : scoringSections.map((category) => {
                  return (
                    <FillScoringCategories
                      key={`cat${category.id}`}
                      sections={category.sections}
                      sectionPos={sectionPos}
                      sectionNeg={sectionNeg}
                      catTitle={category.title}
                      commentsResults={commentsResults}
                      scoringResults={scoringResults}
                      handleSetCommentsResults={handleSetCommentsResults}
                      handleSetScoringResults={handleSetScoringResults}
                      type={category.type}
                      id={`cat${category.id || 'nocat'}`}
                      sectionResults={sectionResults}
                      setSectionResults={setSectionResults}
                      scorecardBehaviourTotals={behaviourTotals}
                      setScorecardBehaviourTotals={setBehaviourTotals}
                      setQualityScore={setQualityScore}
                      setCategoryScores={setCategoryScores}
                      setEditChangesCat={setEditChangesCat}
                      brag={brag}
                      setBragBackground={setBragBackground}
                      categoryScores={categoryScores}
                      handleSetInvalidSections={handleSetInvalidSections}
                      showInvalid={showInvalid}
                      evaluatedCategorySection={evaluation?.evaluatedSections?.scoringSecs.find(
                        (cat) => cat.id === category.id
                      )}
                      showScreen={!!evaluation && !fromDrafts}
                      isEditingEvaluation={isEditingEvaluation}
                    />
                  );
                })}
          </ul>
        )}
        {(adminSections.length > 0 || scoringSections.length > 0) && (
          <div className='scorecard-comments-divider'></div>
        )}

        <div className='scorecard-main-comments-container'>
          <textarea
            disabled={!isEditingEvaluation && !isEditingScorecard}
            id='scorecard-comments'
            className={`scorecard-main-comments ${
              moduleStyles?.scorecardMainComments ?? ''
            }`}
            maxLength='5000'
            onChange={updateMainComments}
            placeholder='Comments'
            value={mainComments}
          />
        </div>
      </div>

      {isEvaluation &&
      !fromSubmittedEvaluations &&
      !fromCalibrations &&
      !evaluation?.imported ? (
        <Feedback
          qualityScore={qualityScore}
          passMark={passMark}
          categoryOnlyList={categoryOnlyList}
          categoryScores={categoryScores}
          behaviourTotals={behaviourTotals}
          sectionResults={sectionResults}
        />
      ) : (
        <></>
      )}

      {evaluation?.imported ? (
        <Text
          margin='30px 0'
          text='Imported evaluation - data may not have been generated by QA Portal'
          textAlign='center'
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Scorecard;
