import { capitalise } from '../../../utils/helpers';
import {
  invoiceCollectionMethods,
  invoiceHeaders,
  invoiceHeaderTranslations,
} from '../../../utils/constants';

import HeaderSecondary from '../../Elements/HeaderSecondary';
import moment from 'moment';

const InvoiceTable = ({ invoices }) => {
  return (
    <div
      className='table-container table-container-table'
      style={{ maxHeight: '300px' }}
    >
      <ul className='table'>
        <li className='header-row'>
          {invoiceHeaders.map((header) => (
            <HeaderSecondary
              key={header}
              classes='header-cell'
              text={invoiceHeaderTranslations[header]}
            />
          ))}
        </li>

        {invoices?.map((invoice, index) => {
          return (
            <a
              key={invoice.id}
              href={invoice.hosted_invoice_url}
              rel='noreferrer'
              target='_blank'
              className={`${index === 0 ? 'first-grid-row ' : ''} grid-row ${
                !invoice.hosted_invoice_url ? 'permissions-grid-row' : ''
              }`}
            >
              {invoiceHeaders.map((invoiceHeader, index) => {
                const invoiceData = invoice[invoiceHeader];

                return (
                  <div
                    className='grid-row-cell'
                    key={`${index}${invoiceHeader}`}
                  >
                    {invoiceData === null
                      ? '-'
                      : invoiceHeader === 'status'
                      ? capitalise(invoiceData)
                      : invoiceHeader === 'amount_due' ||
                        invoiceHeader === 'amount_paid' ||
                        invoiceHeader === 'amount_remaining'
                      ? (invoiceData / 100).toFixed(2)
                      : invoiceHeader === 'currency'
                      ? invoiceData.toUpperCase()
                      : invoiceHeader === 'created' ||
                        invoiceHeader === 'due_date' ||
                        invoiceHeader === 'next_payment_attempt'
                      ? moment.unix(invoiceData).format('Do MMMM YYYY')
                      : invoiceHeader === 'collection_method'
                      ? invoiceCollectionMethods[invoiceData]
                      : invoiceData}
                  </div>
                );
              })}
            </a>
          );
        })}
      </ul>
    </div>
  );
};

export default InvoiceTable;
