import './GridItemOptions.css';

import { capitalise } from '../../../utils/helpers';
import { useDetectClickOutside } from 'react-detect-click-outside';

import FlexContainer from '../../Elements/FlexContainer';
import Icon from '../../Elements/Icon';
import InputStandard from '../../Elements/InputStandard';
import SelectStandard from '../../Elements/SelectStandard';

const GridItemOptions = ({
  chartObjects,
  display,
  header,
  reports,
  selectedReportId,
  setDisplay,
  setHeader,
  setIsOptionsOpen,
  setSelectedReport,
}) => {
  const detectClickOutsideRef = useDetectClickOutside({
    onTriggered: () => {
      setIsOptionsOpen(false);
    },
  });

  const mappedChartObjects = chartObjects?.map((chart, i, arr) => [
    `chart-${chart[0]}`,
    `${
      arr.length > 1
        ? `Chart ${i + 1} (${capitalise(chart[1].type)})`
        : `${capitalise(chart[1].type)} Chart`
    }`,
  ]);

  return (
    <FlexContainer
      classes='add-nav-favourite dashboard-grid-options-menu'
      flexDirection='column'
      flexRef={detectClickOutsideRef}
    >
      <div
        className='nav-favourite-cross dashboard-grid-cross'
        onClick={() => setIsOptionsOpen(false)}
      >
        <Icon iconName='circle-cross' />
      </div>

      <InputStandard
        classes='add-nav-item-input'
        inputClasses='ignore-detect-click-outside'
        labelText='Header Text'
        onChange={(event) => setHeader(event.target.value)}
        placeholder='Header Text'
        value={header}
        wrap='wrap'
      />

      <SelectStandard
        containerClasses=''
        labelText='Report Data'
        marginTop='10px'
        onChange={(event) => {
          setSelectedReport(
            reports.find(
              (report) => report.id === parseInt(event.target.value)
            ) || { id: '' }
          );
        }}
        options={[{ id: '', name: '< Select >' }, ...reports]}
        optionKeyValue={{ key: 'id', value: 'name' }}
        selectContainerClasses='dashboard-report-select'
        value={selectedReportId}
      />

      {selectedReportId ? (
        <SelectStandard
          containerClasses=''
          labelText='Display'
          marginTop='10px'
          onChange={(event) => setDisplay(event.target.value)}
          options={
            mappedChartObjects
              ? [['', '< Select >'], ['table', 'Table'], ...mappedChartObjects]
              : [
                  ['', '< Select >'],
                  ['table', 'Table'],
                ]
          }
          selectContainerClasses='dashboard-report-select'
          value={display}
        />
      ) : (
        <></>
      )}
    </FlexContainer>
  );
};

export default GridItemOptions;
