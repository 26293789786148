import { BreadcrumbsContext, UserPortal } from '../../../contexts';
import { handleApiError } from '../../../utils/error-handling';
import {
  shouldNavigateOnNotFound,
  validatePresenceRequired,
} from '../../../utils/helpers';
import { useAuth0 } from '@auth0/auth0-react';
import { useAlert } from 'react-alert';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import apiDataService from '../../../api/data-lists';
import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import InputTextareaNew from '../../Elements/InputTextareaNew';
import InputStandard from '../../Elements/InputStandard';
import LoadingSpinner from '../../Elements/LoadingSpinner';
import Panels from '../../Elements/Panels';
import PanelsControlBar from '../../Elements/PanelsControlBar';
import PanelsHeaderPrimary from '../../Elements/PanelsHeaderPrimary';
import PanelsSection from '../../Elements/PanelsSection';
import PanelsSections from '../../Elements/PanelsSections';
import portalConfirmAlert from '../../../utils/portalConfirmAlert';

import './DataListsShowContent.css';

const DataListsShowContent = ({ demo }) => {
  const { id } = useParams();

  const { setBreadcrumbs } = useContext(BreadcrumbsContext);
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;
  const userId = userFromDb.id;
  const hasChangePermission =
    demo || userFromDb.permissions?.includes('data_lists.change');

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const alert = useAlert();

  const saveButtonRef = useRef(null);

  const [dataList, setDataList] = useState();
  const [originalDataListName, setOriginalDataListName] = useState();
  const [isSaving, setIsSaving] = useState();
  const [changes, setChanges] = useState({});
  const [showInvalid, setShowInvalid] = useState(false);

  const getDataList = async () => {
    try {
      const { dataList } = await apiDataService.getDataListById({
        token: demo ? undefined : await getAccessTokenSilently(),
        userId: demo ? 1 : userId,
        portalId: demo ? 'demo' : portalId,
        dataListId: id,
      });

      setDataList(dataList);
      setOriginalDataListName(dataList.name);
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      if (shouldNavigateOnNotFound(error)) {
        navigate(`/${demo ? 'demo' : portalId}/data-lists`, {
          state: { isNavigating: true },
        });
      }
    }
  };

  const deleteList = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    const confirmed = await portalConfirmAlert({
      message: `Are you sure you want to delete '${dataList.name}'?`,
    });

    if (!confirmed) return;

    try {
      await apiDataService.gridDeleteMultiple({
        selectedRecordIds: [id],
        portalId: demo ? 'demo' : portalId,
        reqBody: { userId: demo ? 1 : userId },
        token: demo ? undefined : await getAccessTokenSilently(),
      });

      alert.success('Data List Deleted');

      navigate(`/${demo ? 'demo' : portalId}/data-lists`, {
        state: { isNavigating: true },
      });
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  const updateDataList = async () => {
    if (demo) return alert.info('Unable to save changes in demo');

    if (isSaving || saveButtonRef?.current?.disabled) return;
    if (!saveButtonRef?.current?.disabled && saveButtonRef?.current)
      saveButtonRef.current.disabled = true;

    setIsSaving(true);

    const hasName =
      (dataList.name && !Object.keys(changes).includes('name')) || changes.name;

    try {
      const reqBody = {
        updates: [{ ...changes, id: dataList.id }],
        userId: demo ? 1 : userId,
      };

      if (hasName) {
        await apiDataService.update({
          portalId: demo ? 'demo' : portalId,
          reqBody,
          token: demo ? undefined : await getAccessTokenSilently(),
        });
      }

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;

      if (hasName) alert.success('Changes saved');
      else
        alert.show('Data list must have a name.', {
          timeout: 5000,
        });
    } catch (error) {
      alert.error(handleApiError(error), { timeout: 10000 });

      setIsSaving(false);

      if (saveButtonRef?.current?.disabled)
        saveButtonRef.current.disabled = false;
    } finally {
      setChanges({});
      setShowInvalid(true);
    }
  };

  const handleChangeDataListList = (event) => {
    setDataList((curr) => ({ ...curr, list: event.target.value.split('\n') }));
    setChanges((curr) => ({ ...curr, list: event.target.value.split('\n') }));
  };
  const handleChangeDataListName = (event) => {
    const newName = event.target.value;

    setDataList((curr) => ({ ...curr, name: newName }));

    if (originalDataListName === newName)
      setChanges((curr) => {
        const currCopy = { ...curr };
        delete currCopy.name;

        return { ...currCopy };
      });
    else setChanges((curr) => ({ ...curr, name: newName }));
  };

  useEffect(() => {
    if (!demo && !userFromDb.permissions?.includes('data_lists.view')) {
      navigate(`/${demo ? 'demo' : portalId}/dashboard`, {
        state: { isNavigating: true },
      });

      alert.info(
        'You do not have the required permission to access this screen'
      );
    } else {
      getDataList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setBreadcrumbs([
      ['Dashboard', 'dashboard', '', `/${demo ? 'demo' : portalId}/dashboard`],
      ['Data Lists', 'list-ol', '', `/${demo ? 'demo' : portalId}/data-lists`],
      [dataList?.name || 'Data List', 'magnifying-glass'],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataList]);

  if (!dataList) return <LoadingSpinner />;
  else
    return (
      <Panels>
        {hasChangePermission ? (
          <PanelsControlBar>
            <ButtonStandardNoFill
              margin='5px'
              disabled={isSaving}
              iconName='trash'
              onClick={deleteList}
              text='Delete List'
              typeStyle={'type-5'}
            />
          </PanelsControlBar>
        ) : (
          <></>
        )}

        <PanelsHeaderPrimary text={dataList?.name || ''} iconName='list-ol' />

        <PanelsSections>
          <PanelsSection typeStyle='type-2' noMaxWidth>
            <InputStandard
              direction='vertical'
              hasPermission={hasChangePermission}
              id='data-list-name-input'
              invalid={
                dataList.name
                  ? null
                  : [validatePresenceRequired(dataList.name, 'Data List name')]
              }
              isRequired
              labelText={'Name'}
              marginBottom='5px'
              onChange={handleChangeDataListName}
              placeholder={'Name'}
              showInvalid={showInvalid}
              typeStyle='type-2'
              value={dataList.name}
            />

            <InputTextareaNew
              direction='vertical'
              hasPermission={hasChangePermission}
              id='data-list-list-input'
              labelText='List'
              marginBottom='5px'
              onChange={handleChangeDataListList}
              placeholder='< Data list >'
              rows={dataList.list.length < 5 ? 5 : dataList.list.length}
              typeStyle='type-2'
              value={dataList.list.join('\n')}
            />

            {hasChangePermission ? (
              <ButtonStandardNoFill
                disabled={isSaving || !Object.keys(changes).length}
                iconName='save-solid'
                margin='10px 0 5px 0'
                onClick={updateDataList}
                text='Save Changes'
                buttonRef={saveButtonRef}
                typeStyle={'type-4'}
              />
            ) : (
              <></>
            )}
          </PanelsSection>
        </PanelsSections>
      </Panels>
    );
};

export default DataListsShowContent;
