import { colourRange } from '../../../utils/reports';

import FlexContainer from '../../Elements/FlexContainer';
import HeaderSecondary from '../../Elements/HeaderSecondary';

const VarianceResultList = ({
  fromCalibrationReport,
  headerText,
  listData,
}) => {
  const maxVariance = parseInt(listData[0].average_variance);

  return (
    <FlexContainer classes='variance-result-list' flexDirection='column'>
      <div
        className='variance-separator'
        style={{ textAlign: 'center', margin: '0 0 10px 0' }}
      >
        -------------------------
      </div>

      <HeaderSecondary
        marginBottom='10px'
        text={headerText}
        textAlign='center'
      />
      <div
        style={{ margin: '0 0 30px' }}
        className='report-results-table-container'
      >
        <table className='report-results-table'>
          <thead>
            <tr className='report-results-table-header-row'>
              <th>Name</th>

              {fromCalibrationReport ? (
                <>
                  <th>Scorecard</th>

                  <th>Evaluations</th>
                </>
              ) : (
                <></>
              )}

              <th>Variance</th>
            </tr>
          </thead>

          <tbody>
            {listData.map((row, index) => {
              const rowVariance = parseInt(row.average_variance);

              const backgroundColor =
                rowVariance === 0
                  ? 'rgb(0, 122, 0)'
                  : colourRange(maxVariance - rowVariance, maxVariance);

              return (
                <tr key={index}>
                  <td>{row.title || row.username}</td>

                  {fromCalibrationReport ? (
                    <>
                      <td>{row.scorecard_name}</td>

                      <td>{row.evals_count}</td>
                    </>
                  ) : (
                    <></>
                  )}

                  <td
                    style={
                      fromCalibrationReport
                        ? { backgroundColor, color: 'white' }
                        : {}
                    }
                  >
                    {row.average_variance}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </FlexContainer>
  );
};

export default VarianceResultList;
