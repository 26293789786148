import { useContext } from 'react';
import { UserPortal } from '../../../contexts';

import ButtonIconOnly from '../../Elements/ButtonIconOnly';
import TextBreak from '../../Elements/TextBreak';

import './LinkedAgentsListItem.css';

const LinkedAgentsListItem = ({
  agent,
  demo,
  firstRow,
  hasChangePermission,
  setAllAgents,
  setChanges,
  setLinkedAgents,
}) => {
  const { userFromDb } = useContext(UserPortal);

  const portalId = userFromDb.portal_id;

  return (
    <li
      className={`${
        firstRow ? 'first-grid-row ' : ''
      } grid-row linked-agents-grid-row`}
    >
      {demo || userFromDb.permissions?.includes('agents.view') ? (
        <a
          className='grid-row-cell linked-agents-grid-row-cell'
          href={`/${demo ? 'demo' : portalId}/agents/${agent.id}`}
        >
          <TextBreak text={agent.level0} classes='grid-row-cell-data' />
        </a>
      ) : (
        <div className='grid-row-cell linked-agents-no-permission'>
          <TextBreak text={agent.level0} classes='grid-row-cell-data' />
        </div>
      )}

      {hasChangePermission ? (
        <div className='grid-row-cell linked-agents-unlink-button'>
          <ButtonIconOnly
            onClick={() => {
              setLinkedAgents((curr) => {
                const newLinkedAgents = curr.filter(
                  (item) => item.id !== agent.id
                );

                setChanges((curr) => ({
                  ...curr,
                  linked_agents: newLinkedAgents.map((agent) => agent.alt_id),
                }));

                return newLinkedAgents;
              });

              setAllAgents((curr) => {
                return [...curr, agent].sort((a, b) =>
                  a.level0.localeCompare(b.level0)
                );
              });
            }}
            title='Unlink'
            iconName='unlink'
            typeStyle='type-2'
          />
        </div>
      ) : (
        <></>
      )}
    </li>
  );
};

export default LinkedAgentsListItem;
