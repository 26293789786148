import { useLayoutEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import ButtonStandardNoFill from '../../Elements/ButtonStandardNoFill';
import ButtonWithOptions from '../../Elements/ButtonWithOptions';
import FlexContainer from '../../Elements/FlexContainer';
import useViewport from '../../../components/useViewport';

import './FloatingResults.css';

const FloatingResults = ({
  categoryOnlyList,
  categoryScores,
  fromCalibrations,
  fromDrafts,
  fromSubmittedEvaluations,
  handleDelete,
  handlePrint,
  handleSaveEvaluation,
  handleSaveEvaluationAsDraft,
  hasEvaluationsChangePermission,
  isEditingEvaluation,
  isNewEvaluation,
  passMark,
  qualityScore,
  sectionResults,
  setIsEditingEvaluation,
  showScreen,
}) => {
  const heightRef = useRef(null);

  const [height, setHeight] = useState(0);

  const { screenWidth } = useViewport();

  const { isNavOpen } = useOutletContext();

  useLayoutEffect(() => {
    if (heightRef.current?.offsetHeight !== height)
      setHeight(heightRef.current.offsetHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    heightRef.current?.offsetHeight,
    heightRef.current,
    sectionResults,
    isNavOpen,
    screenWidth,
  ]);

  return (
    <div className='scorecard-floating-results' ref={heightRef}>
      <div
        className='scorecard-floating-results-buttons'
        style={{
          bottom: `${height + 5}px`,
        }}
      >
        {handlePrint ? (
          <ButtonStandardNoFill
            classes='floating-results-print-button'
            iconName='print'
            onClick={handlePrint}
            text='Print'
          />
        ) : (
          <></>
        )}

        {hasEvaluationsChangePermission ? (
          <>
            {(fromDrafts || showScreen) && isEditingEvaluation && (
              <FlexContainer
                height='30px'
                margin='0 10px 5px 0'
                alignItems='stretch'
                alignSelf='flex-start'
              >
                <ButtonStandardNoFill
                  iconName='trash'
                  onClick={handleDelete}
                  text={`Delete${fromCalibrations ? '' : ' Evaluation'}`}
                  typeStyle='type-5'
                />
              </FlexContainer>
            )}

            {fromCalibrations && (
              <ButtonStandardNoFill
                iconName='save-solid'
                margin='0 10px 5px 0'
                onClick={() => handleSaveEvaluation('submitted')}
                text='Save'
                typeStyle='type-4'
              />
            )}

            {(isEditingEvaluation || isNewEvaluation) && !fromCalibrations && (
              <ButtonWithOptions
                buttonData={
                  fromDrafts
                    ? [
                        {
                          iconName: 'draft',
                          onClick: () => handleSaveEvaluation('draft'),
                          text: 'Save draft',
                          typeStyle: 'type-4',
                        },
                        {
                          iconName: 'save-solid',
                          onClick: () => handleSaveEvaluation('completed'),
                          text: 'Save as completed',
                        },
                        // { iconName: 'magnifying-glass', text: 'Submit for review' },
                      ]
                    : [
                        {
                          iconName: 'save-solid',
                          onClick: () => handleSaveEvaluation('completed'),
                          text: 'Save',
                          typeStyle: 'type-4',
                        },
                        {
                          iconName: 'draft',
                          onClick: () => handleSaveEvaluationAsDraft('draft'),
                          text: 'Save as draft',
                        },
                        // { iconName: 'magnifying-glass', text: 'Submit for review' },
                      ]
                }
                containerClasses='scorecard-floating-results-save-button'
                direction='up'
                iconName='save-solid'
                margin='0 10px 5px 0'
                text='Submit'
              />
            )}

            {showScreen && !fromCalibrations && !fromSubmittedEvaluations && (
              <FlexContainer
                height='30px'
                margin='0 0 5px 0'
                alignItems='stretch'
                alignSelf='flex-start'
              >
                <ButtonStandardNoFill
                  iconName='edit-solid'
                  onClick={() => setIsEditingEvaluation((curr) => !curr)}
                  text='Edit'
                  typeStyle={isEditingEvaluation ? 'type-3' : 'type-1'}
                />
              </FlexContainer>
            )}
          </>
        ) : (
          <></>
        )}
      </div>

      <div className='scorecard-float-score-container scorecard-float-score-main-container'>
        <p className='scorecard-float-score-header'>Quality Score</p>
        <p className='scorecard-float-score-amount'>
          {sectionResults.markedCount ? qualityScore : ''}
        </p>
      </div>

      {passMark > 0 && (
        <div className='scorecard-float-score-container scorecard-float-score-main-container'>
          <p className='scorecard-float-score-header scorecard-float-score-main-header'>
            Grade
          </p>
          <p className='scorecard-float-score-amount'>
            {sectionResults.markedCount
              ? qualityScore >= passMark
                ? 'Pass'
                : 'Fail'
              : ''}
          </p>
        </div>
      )}

      {categoryOnlyList
        .filter(
          (catObj) =>
            categoryScores.hasOwnProperty('cat' + catObj.id) &&
            categoryScores['cat' + catObj.id] !== null
        )
        .map((catObj) => {
          const { id, title } = catObj;
          return (
            <div
              key={'catfloat' + id}
              className='scorecard-float-score-container'
            >
              <p className='scorecard-float-score-header'>{title}</p>
              <p className='scorecard-float-score-amount'>
                {categoryScores['cat' + id]}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default FloatingResults;
