import moment from 'moment';
import { capitalise } from './helpers';

export const translateSourceTables = {
  evals: 'Evaluations',
  admin: 'Admin sections',
  scoring: 'Scoring sections',
  cat: 'Scorecard categories',
  comments: 'Custom comments',
};

export const translateSourceTablesTitles = {
  evals: 'Evaluations',
  admin: 'Admin Sections',
  scoring: 'Scoring Sections',
  cat: 'Scorecard Categories',
  comments: 'Custom Comments',
};

export const translateEvaluationsToTitle = {
  brag: 'RAG',
  comments_main: 'Main Comments',
  logs_created_at: 'Created Date',
  created_by: 'Created By',
  fed_back: 'Fed Back',
  last_modified_at: 'Last Modified Date',
  last_modified_by: 'Last Modified By',
  pass_fail: 'Grade',
  quality_score: 'Quality Score',
  scorecard_name: 'Scorecard',
  status: 'Status',
};

export const translateEvaluationsToKey = {
  RAG: 'brag',
  'Main Comments': 'comments_main',
  'Created Date': 'created_at',
  'Created By': 'created_by',
  'Fed Back': 'fed_back',
  'Last Modified Date': 'last_modified_at',
  'Last Modified By': 'last_modified_by',
  Grade: 'pass_fail',
  'Quality Score': 'quality_score',
  Scorecard: 'scorecard_name',
  Status: 'status',
};

export const tableOrder = {
  evals: 1,
  admin: 2,
  scoring: 3,
  cat: 4,
  comments: 5,
};
export const checkBoxOrder = { Yes: 1, No: 2 };
export const scoringOrder = { Pass: 1, Fail: 2, NA: 3 };
export const gradeOrder = { Pass: 1, Fail: 2 };
export const ragOrder = { Green: 1, Amber: 2, Red: 3 };

export const getSortOrderCustom = (a, b, tableCode, control, title) => {
  if (control === 'Check box') {
    return checkBoxOrder[a] - checkBoxOrder[b];
  }

  if (control === 'Check box') {
    return checkBoxOrder[a] - checkBoxOrder[b];
  }

  if (tableCode === 'scoring') {
    return scoringOrder[a] - scoringOrder[b];
  }

  if (tableCode === 'evals') {
    if (title === 'RAG') {
      return ragOrder[a] - ragOrder[b];
    }
    if (title === 'Grade') {
      return gradeOrder[a] - gradeOrder[b];
    }
  }

  return 'unsorted';
};

export const mutateColumn = (column) => {
  if (translateEvaluationsToTitle[column])
    return translateEvaluationsToTitle[column];
  else if (column.slice(0, 6) === 'admin_') {
    const amendedColumn = column.slice(6);

    return amendedColumn.slice(amendedColumn.indexOf('_') + 1);
  } else if (column.slice(0, 8) === 'scoring_') {
    return column.slice(8);
  } else if (column.slice(0, 4) === 'cat_') {
    return column.slice(4);
  } else if (column.slice(0, 9) === 'comments_') {
    return column.slice(9);
  } else return column;
};

export const mutateData = (column, data, dateFrequency) => {
  if (data === '' || data === null) return '-';
  else if (data === true) return 'Yes';
  else if (data === false) return 'No';
  else if (!data) return data;
  else if (column === 'brag' || column === 'pass_fail' || column === 'status')
    return capitalise(data);
  else if (
    column === 'last_modified_at' ||
    column === 'logs_created_at' ||
    (column.slice(0, 6) === 'admin_' &&
      column.slice(6, 11) === 'Date_' &&
      column.slice(-6) !== '_COUNT')
  ) {
    const dateFormat =
      dateFrequency === 'month'
        ? 'MMMM'
        : dateFrequency === 'year'
        ? 'YYYY'
        : 'Do MMMM YYYY';

    return moment(data).format(dateFormat);
  } else if (column.slice(0, 8) === 'scoring_') return capitalise(data);
  else if (column.slice(-4) === '_AVG') {
    return Math.round(data);
  } else return data;
};
export const mutateDataNew = ({
  aggOn,
  source,
  control,
  column,
  data,
  dateFrequency,
}) => {
  if (data === '' || data === null) return '-';
  else if (data === true) return 'Yes';
  else if (data === false) return 'No';
  else if (!data) return data;
  else if (aggOn === 'avg' || aggOn === 'percent') {
    const newVal = Math.round(data);

    if (aggOn === 'percent' && isNaN(newVal)) {
      if (control === 'Date') {
        const dateFormat =
          dateFrequency === 'month'
            ? 'MMMM'
            : dateFrequency === 'year'
            ? 'YYYY'
            : 'Do MMMM YYYY';

        return moment(data).format(dateFormat);
      }

      if (column === 'Custom Comments' || column === 'Main Comments')
        return data;

      if (column === 'Scorecard' && source === 'evals') return data;

      return capitalise(data);
    } else return newVal;
  } else if (aggOn === 'count') {
    return data;
  } else if (
    source === 'evals' &&
    (column === 'RAG' || column === 'Grade' || column === 'Status')
  ) {
    return capitalise(data);
  } else if (
    (source === 'evals' &&
      (column === 'Last Modified Date' || column === 'Created Date')) ||
    (source === 'admin' && control === 'Date')
  ) {
    const dateFormat =
      dateFrequency === 'month'
        ? 'MMMM'
        : dateFrequency === 'year'
        ? 'YYYY'
        : 'Do MMMM YYYY';

    return moment(data).format(dateFormat);
  } else if (source === 'scoring') {
    if (data === 'na') return 'NA';
    else return capitalise(data);
  } else return data;
};

export const handleAlternateX = (index, alternateInterval) => {
  const something = (index % parseInt(alternateInterval)) + 1;

  const multiplyBy = something === 1 ? 15 : 20;

  const minus = something === 1 ? 0 : 5;

  return ((index % parseInt(alternateInterval)) + 1) * multiplyBy - minus;
};

export const getReportColumns = (columnOrder, grouping) => {
  const reportColumns = {};

  columnOrder.forEach((column) => {
    const agg = ['_COUNT', '_SUM', '_AVG', '_MIN', '_MAX'].find(
      (agg) => agg === column.slice(column.lastIndexOf('_'))
    );

    const newColumn = agg ? column.slice(0, column.lastIndexOf('_')) : column;

    const toMatchGroupingName = translateEvaluationsToTitle[newColumn]
      ? `evals_${translateEvaluationsToTitle[newColumn]}`
      : newColumn;

    const dateFrequency = grouping?.[toMatchGroupingName]?.dateFrequency;

    const mutatedColumn = `${mutateColumn(newColumn)}${
      agg ? ` (${agg.slice(1)})` : dateFrequency === 'week' ? ' - w/c' : ''
    }`;

    reportColumns[column] = mutatedColumn;
  });

  return reportColumns;
};

export const getMutatedReportRows = (reportRows, reportColumns, grouping) => {
  return reportRows.map((row) => ({
    ...Object.fromEntries(
      Object.entries(row).map(([colName, val]) => {
        const toMatchGroupingName = translateEvaluationsToTitle[colName]
          ? `evals_${translateEvaluationsToTitle[colName]}`
          : colName;

        const dateFrequency = grouping?.[toMatchGroupingName]?.dateFrequency;

        return [
          reportColumns[colName],
          mutateData(colName, val, dateFrequency),
        ];
      })
    ),
  }));
};

export const getChartSize = (chartData) => {
  return chartData?.size === 'small'
    ? [300, 150]
    : chartData?.size === 'medium'
    ? [450, 225]
    : chartData?.size === 'large'
    ? [600, 300]
    : chartData?.size === 'custom'
    ? [chartData?.width, chartData?.height]
    : [0, 0];
};

export const extractColumnData = (columnString) => {
  const table = columnString.slice(0, columnString.indexOf('_'));
  const afterTable = columnString.slice(table.length + 1);

  const control = afterTable.slice(0, afterTable.indexOf('_'));
  const afterControl = afterTable.slice(control.length + 1);

  const groupBy = afterControl.slice(0, afterControl.indexOf('_')) !== 'null';
  const afterGroupBy = afterControl.slice(groupBy ? 6 : 5);

  if (groupBy) {
    if (control !== 'Date') {
      return { table, control, groupBy, column: afterGroupBy };
    } else {
      const dateTrunc =
        groupBy && control === 'Date'
          ? afterGroupBy.slice(0, afterGroupBy.indexOf('_'))
          : '';

      const afterDateTrunc = groupBy
        ? afterGroupBy.slice(dateTrunc ? dateTrunc.length + 1 : 5)
        : afterGroupBy;

      return { table, control, dateTrunc, groupBy, column: afterDateTrunc };
    }
  }

  const aggOn = afterGroupBy.slice(0, afterGroupBy.indexOf('_'));
  const afterAggOn = afterGroupBy.slice(aggOn.length + 1);

  if (aggOn === 'null') {
    return { table, control, column: afterAggOn };
  } else if (aggOn !== 'percent') {
    return { table, control, aggOn, column: afterAggOn };
  } else {
    const percentOfText = afterAggOn.slice(0, afterAggOn.indexOf('x____x'));
    const percentOf = percentOfText.split('x___x');
    const afterPercentOf = afterAggOn.slice(percentOfText.length + 6);

    const outOfText = afterPercentOf.slice(0, afterPercentOf.indexOf('x____x'));

    const column = afterPercentOf.slice(outOfText.length + 6);

    if (outOfText === 'null')
      return { table, control, aggOn, percentOf, outOf: null, column };

    const outOf = outOfText.split('x___x');

    return {
      table,
      control,
      aggOn,
      percentOf,
      outOf,
      column,
    };
  }
};

export const extractColumnFromBarId = (barId, hasGroupingOrAggregate) => {
  if (hasGroupingOrAggregate) {
    const { aggOn, column } = extractColumnData(barId);

    return `${column}${aggOn ? ` (${aggOn.toUpperCase()})` : ''}`;
  } else {
    const source = barId.slice(0, barId.indexOf('_'));
    const afterSource = barId.slice(source.length + +1);
    return afterSource.slice(afterSource.indexOf('_') + 1);
  }
};

export const calculatePercentageInRange = (value, min, max) => {
  if (value === null || value === undefined || value === '') return value;

  const parsedValue = Number(value);
  const parsedMin = Number(min);
  const parsedMax = Number(max);

  if (parsedValue < parsedMin) return 0;
  if (parsedValue > parsedMax) return 100;

  return ((parsedValue - parsedMin) / (parsedMax - parsedMin)) * 100;
};

export const distinctColourGenerator = (numColors) => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    // Calculate the hue value for each segment evenly around the color wheel
    const hue = (i * 360) / numColors;
    // Convert HSV to RGB
    const rgbColor = hsvToRgb(hue, 0.8, 0.8);
    // Convert RGB to hex
    const hexColor = rgbToHex(rgbColor);
    colors.push(hexColor);
  }
  return colors;
};

export const generateReportDataParams = ({
  aggregate,
  columnOrder,
  grouping,
  query,
  sort,
}) => {
  const columnsForReports = [];

  const columns = columnOrder.map(({ id, value }) => {
    if (Object.keys(grouping).length || aggregate.length) {
      const { table, control, dateTrunc, groupBy, aggOn, column } =
        extractColumnData(id);

      columnsForReports.push({
        source: table,
        control,
        groupBy,
        aggOn,
        dateFrequency: dateTrunc,
        column,
        value,
        id,
      });

      return id;
    } else {
      const source = id.slice(0, id.indexOf('_'));
      const afterSource = id.slice(source.length + +1);
      const control = afterSource.slice(0, afterSource.indexOf('_'));
      const column = afterSource.slice(afterSource.indexOf('_') + 1);

      columnsForReports.push({
        source,
        control,
        column,
        value,
        id,
      });

      return `${source}_${control}_null_null_${column}`;
    }
  });

  const params = [];

  if (query) params.push(`q=${encodeURIComponent(query)}`);

  if (columns.length)
    params.push(`columns=${encodeURIComponent(columns.join('&&'))}`);

  const toggledSort = sort.filter((sortItem) => sortItem.toggled);

  if (toggledSort.length) {
    const amendedSort = toggledSort.map((sortCol) => {
      const colNumber =
        columnOrder.findIndex((col) => col.id === sortCol.filterColumnTitle) +
        1;

      return `${sortCol.direction}col_${colNumber}`;
    });

    params.push(`sort_by=${encodeURIComponent(amendedSort.join(',,'))}`);
  }

  return { columnsForReports, params };
};

export const amendedReportRows = ({ columnsForReports, reportRows }) => {
  return reportRows.map((item) => {
    const row = Object.entries(item)
      .sort(([a], [b]) => {
        const numA = parseInt(a.slice(a.indexOf('_') + 1));
        const numB = parseInt(b.slice(b.indexOf('_') + 1));

        return numA - numB;
      })
      .map(([column, value], index) => {
        return {
          col: column,
          rawVal: value,
          mutatedVal: mutateDataNew({
            ...columnsForReports[index],
            data: value,
          }),
        };
      });

    return row;
  });
};

// Function to convert HSV to RGB
function hsvToRgb(h, s, v) {
  h /= 360;
  s = Math.max(0, Math.min(1, s));
  v = Math.max(0, Math.min(1, v));

  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);

  let r, g, b;
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
    default:
      r = 0;
      g = 0;
      b = 0;
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

// Function to convert RGB to hex
function rgbToHex(rgb) {
  return `#${rgb.map((value) => value.toString(16).padStart(2, '0')).join('')}`;
}

export const getMinAndMaxValues = (originalReportRows, cols) => {
  let min;
  let max;

  originalReportRows.forEach((reportRow) => {
    cols.forEach((col) => {
      const value = parseFloat(reportRow[col]);

      if ((!min && min !== 0) || value < min) min = value;

      if ((!max && min !== 0) || value > max) max = value;
    });
  });

  return [Math.floor(min) || 0, Math.ceil(max) || 100];
};

export const getTableCode = (code) => {
  return code.slice(0, code.indexOf('_'));
};

const getParsedValue = (value, incDate) => {
  if (value === '-') return '-';
  if (incDate) return moment(value, 'Do MMMM YYYY').format('YYYY-MM-DD');
  return value;
};

const getParameterTableColumn = (parameter) => {
  return `${parameter.tableCode}_${parameter.control}_${parameter.title}`;
};

export const buildFocusQuery = (parameterData) => {
  let paramStrs = [];

  parameterData.forEach((parameter) => {
    if (parameter.type === 'bet') {
      paramStrs.push(
        getParameterTableColumn(parameter) +
          '==bet::' +
          getParsedValue(parameter.params[0]) +
          '--' +
          getParsedValue(parameter.params[1])
      );
    } else if (Object.keys(parameter.params).length === 1) {
      paramStrs.push(
        getParameterTableColumn(parameter) +
          '==eq::' +
          getParsedValue(
            Object.keys(parameter.params)[0],
            parameter.control === 'Date'
          )
      );
    } else {
      paramStrs.push(
        `((${Object.keys(parameter.params)
          .map(
            (param) =>
              `${getParameterTableColumn(parameter)}==eq::${getParsedValue(
                param,
                parameter.control === 'Date'
              )}`
          )
          .join(',,')}))`
      );
    }
  });

  return `((${paramStrs.join('&&')}))`;
};

export const colourRange = (
  index,
  totalItems,
  startColor = [163, 0, 0],
  endColor = [0, 122, 0],
  middleColor = [195, 146, 0]
) => {
  let color;

  if (index < totalItems / 2) {
    const step = [
      (middleColor[0] - startColor[0]) / (totalItems / 2),
      (middleColor[1] - startColor[1]) / (totalItems / 2),
      (middleColor[2] - startColor[2]) / (totalItems / 2),
    ];
    color = [
      Math.round(startColor[0] + step[0] * index),
      Math.round(startColor[1] + step[1] * index),
      Math.round(startColor[2] + step[2] * index),
    ];
  } else {
    const step = [
      (endColor[0] - middleColor[0]) / (totalItems / 2),
      (endColor[1] - middleColor[1]) / (totalItems / 2),
      (endColor[2] - middleColor[2]) / (totalItems / 2),
    ];
    color = [
      Math.round(middleColor[0] + step[0] * (index - totalItems / 2)),
      Math.round(middleColor[1] + step[1] * (index - totalItems / 2)),
      Math.round(middleColor[2] + step[2] * (index - totalItems / 2)),
    ];
  }

  return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
};

const getXDataPoint = (dataIndex, dataLength, originalData, valuesLabel) => {
  const originalDataLength = originalData.length;

  if (originalDataLength === dataLength) return dataIndex;

  let counter = 0;

  for (let i = 1; i <= originalDataLength; i++) {
    if (
      originalData[i - 1][valuesLabel] !== null &&
      originalData[i - 1][valuesLabel] !== undefined
    ) {
      counter++;

      if (counter === dataIndex) return i;
    }
  }
};

const getTrendStart = (
  slope,
  yIntercept,
  yAxisMinMax,
  dataLength,
  originalData,
  valuesLabel
) => {
  for (let i = 1; i <= dataLength; i++) {
    const potentialTrendStart = slope * i + yIntercept;

    if (
      potentialTrendStart <= yAxisMinMax[1] &&
      potentialTrendStart >= yAxisMinMax[0]
    ) {
      return {
        yDataPoint: potentialTrendStart,
        xDataPoint: getXDataPoint(i, dataLength, originalData, valuesLabel),
      };
    }
  }
};

const getTrendEnd = (
  slope,
  yIntercept,
  yAxisMinMax,
  dataLength,
  originalData,
  valuesLabel
) => {
  for (let i = dataLength; i >= 1; i--) {
    const potentialTrendEnd = slope * i + yIntercept;

    if (
      potentialTrendEnd <= yAxisMinMax[1] &&
      potentialTrendEnd >= yAxisMinMax[0]
    ) {
      return {
        yDataPoint: potentialTrendEnd,
        xDataPoint: getXDataPoint(i, dataLength, originalData, valuesLabel),
      };
    }
  }
};

export const calculateTrendLine = (data, valuesLabel, yAxisMinMax) => {
  const newData = data.filter(
    (item) => item[valuesLabel] !== null && item[valuesLabel] !== undefined
  );

  const dataLength = newData.length;

  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;

  for (let i = 0; i < dataLength; i++) {
    const point = newData[i];
    const xPosition = i + 1;

    if (point[valuesLabel] === null || point[valuesLabel] === undefined) break;

    sumX += xPosition;
    sumY += parseFloat(point[valuesLabel]);
    sumXY += xPosition * parseFloat(point[valuesLabel]);
    sumXX += xPosition * xPosition;
  }

  const slope =
    (dataLength * sumXY - sumX * sumY) / (dataLength * sumXX - sumX * sumX);
  const yIntercept = (sumY - slope * sumX) / dataLength;

  const trendStart = getTrendStart(
    slope,
    yIntercept,
    yAxisMinMax,
    dataLength,
    data,
    valuesLabel
  );
  const trendEnd = getTrendEnd(
    slope,
    yIntercept,
    yAxisMinMax,
    dataLength,
    data,
    valuesLabel
  );

  return { trendStart, trendEnd };
};

export const getMax = (ObjArray, keys) => {
  let max = 0;

  ObjArray.forEach((obj) => {
    keys.forEach((key) => {
      const newVal = parseFloat(obj[key]);

      if (newVal > max) max = newVal;
    });
  });

  return max;
};
