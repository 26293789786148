import './FillScoringCategories.css';

import { useState } from 'react';

import FillScoringSections from './FillScoringSections';

const FillScoringCategories = ({
  sections,
  catTitle,
  id,
  sectionPos,
  sectionNeg,
  type,
  commentsResults,
  fromPrintable,
  scoringResults,
  sectionResults,
  setSectionResults,
  scorecardBehaviourTotals,
  setScorecardBehaviourTotals,
  setQualityScore,
  brag,
  setBragBackground,
  categoryScores,
  setCategoryScores,
  handleSetCommentsResults,
  handleSetScoringResults,
  handleSetInvalidSections,
  evaluatedCategorySection,
  showInvalid,
  handleMarkStatusChange,
  setEditChangesCat,
  isEditingEvaluation,
  showScreen,
}) => {
  let ra = 0;
  let weightOutOf = 0;
  let weightTotal = 0;
  const csm = [];

  let markedCount = 0;
  const passedSections = [];
  const failedSections = [];
  const failedRa = [];
  const failedWeight = [];
  const failedCsm = [];

  evaluatedCategorySection?.sections.forEach((sec) => {
    if (sec.data === 'pass' || sec.data === 'fail') {
      markedCount++;

      if (sec.data === 'fail') {
        if (sec.ra) ra += parseInt(sec.ra);
        if (sec.csm) {
          csm.push({
            csmAmount: Number(sec.csm),
            sectionName: sec.title,
            sectionRef: sec.type + sec.id,
          });

          csm.sort((a, b) => a.csmAmount - b.csmAmount);
        }
      }

      if (sec.weight) {
        weightOutOf += Number(sec.weight);

        if (sec.data === 'pass') weightTotal += Number(sec.weight);
      }

      if (sec.data === 'pass') {
        passedSections.push({
          ...sec,
          uniqueRef: sec.type + sec.id,
        });
      } else {
        failedSections.push({
          ...sec,
          uniqueRef: sec.type + sec.id,
        });

        if (sec.weight)
          failedWeight.push({
            ...sec,
            uniqueRef: sec.type + sec.id,
          });

        if (sec.ra)
          failedRa.push({
            ...sec,
            uniqueRef: sec.type + sec.id,
          });

        if (sec.csm)
          failedCsm.push({
            ...sec,
            uniqueRef: sec.type + sec.id,
          });
      }
    }
  });

  const [categoryBehaviourTotals, setCategoryBehaviourTotals] = useState(
    evaluatedCategorySection
      ? { weightOutOf, weightTotal, ra, csm }
      : {
          weightOutOf: 0,
          weightTotal: 0,
          ra: 0,
          csm: [],
        }
  );

  const [sectionResultsCat, setSectionResultsCat] = useState(
    evaluatedCategorySection
      ? {
          markedCount,
          passedSections,
          failedSections,
          failedRa,
          failedWeight,
          failedCsm,
        }
      : {
          markedCount: 0,
          passedSections: [],
          failedSections: [],
          failedRa: [],
          failedWeight: [],
          failedCsm: [],
        }
  );

  return (
    <li className='preview-scoring-cat-row'>
      {type !== 'no-cat' && (
        <>
          <div className='scorecard-cat-heading' colSpan='4'>
            <h2 className='scorecard-cat-title'>{catTitle}</h2>
            <p className='scorecard-cat-score'>
              {sectionResultsCat.markedCount > 0 ? categoryScores[id] : ''}
            </p>
          </div>
        </>
      )}

      <ul className='scorecard-scoring-sections-two'>
        {sections.map((section) => {
          return (
            <FillScoringSections
              key={`${section.type}${section.id}`}
              brag={brag}
              categoryId={id}
              commentsResults={commentsResults}
              scoringResults={scoringResults}
              setBragBackground={setBragBackground}
              categoryBehaviourTotals={categoryBehaviourTotals}
              fromPrintable={fromPrintable}
              handleSetCommentsResults={handleSetCommentsResults}
              handleSetScoringResults={handleSetScoringResults}
              scorecardBehaviourTotals={scorecardBehaviourTotals}
              sectionResults={sectionResults}
              setScorecardBehaviourTotals={setScorecardBehaviourTotals}
              section={section}
              sectionPos={sectionPos}
              sectionNeg={sectionNeg}
              setCategoryBehaviourTotals={setCategoryBehaviourTotals}
              setCategoryScores={setCategoryScores}
              handleSetInvalidSections={handleSetInvalidSections}
              setQualityScore={setQualityScore}
              sectionResultsCat={sectionResultsCat}
              setSectionResults={setSectionResults}
              setSectionResultsCat={setSectionResultsCat}
              showInvalid={showInvalid}
              uniqueRef={section.type + section.id}
              evaluatedScoringSection={evaluatedCategorySection?.sections?.find(
                (sec) => sec.id === section.id
              )}
              handleMarkStatusChange={handleMarkStatusChange}
              setEditChangesCat={setEditChangesCat}
              showScreen={showScreen}
              isEditingEvaluation={isEditingEvaluation}
            />
          );
        })}
      </ul>
    </li>
  );
};

export default FillScoringCategories;
