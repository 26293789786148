import tickSmall from '../../images/tick-small.png';

import './About.css';

const ScorecardFeatures = () => {
  return (
    <ul className='about-screen-list'>
      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Highly customisable with most of the features being optional</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Set pass marks</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Set Red / Amber / Green (RAG) boundaries</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Apply logos and company names to the scorecard banners</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Utilise custom marking choices (eg. Pass/ Fail, Yes / No, etc)</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Give scorecards a description</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Add admin sections as well as scoring sections</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Give admin secitons a type, eg. number, date, text, etc</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Group your scorecard sections into categories</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Place additional comments boxes on your scorecards</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Create data lists that can be used as drop down options within your
          scorecard admin area
        </p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Give scoring sections a description</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Choose any combination of weight / RA / CSM for calculating the scores
        </p>

        <ul className='about-screen-list about-screen-list-nested'>
          <li>
            <p>
              <span style={{ color: '#333333', fontWeight: '600' }}>
                Weight:{' '}
              </span>
              <span>
                Apply weight to sections, with the amount depending on the
                impact you want those sections to have on the overall quality
                score
              </span>
            </p>
          </li>

          <li>
            <p>
              <span style={{ color: '#333333', fontWeight: '600' }}>RA: </span>
              <span>
                Reduction Amount - a fixed amount by which the final score of
                the evaluation will be reduced if the section is failed
              </span>
            </p>
          </li>

          <li>
            <p>
              <span style={{ color: '#333333', fontWeight: '600' }}>CSM: </span>
              <span>
                Call Score Maximum - if the agent fails one of these sections,
                the maximum quality score for the evaluation will be limited to
                the amount you have set
              </span>
            </p>
          </li>
        </ul>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Set individual admin / scoring sections as mandatory or optional</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Save and load default settings</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>
          Save as draft or set the status to inactive if you don't want the
          scorecard to be available right away
        </p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Save or print a printer friendly PDF version</p>
      </li>

      <li className='about-features-main-list-item'>
        <img className='about-features-tick' src={tickSmall} alt='' />

        <p>Scorecard templates</p>

        <ul className='about-screen-list about-screen-list-nested'>
          <li>
            <p>Create templates to aid adding future scorecards</p>
          </li>

          <li>
            <p>Use your existing scorecards as templates</p>
          </li>

          <li>
            <p>Take advantage of the QA Portal preset templates</p>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default ScorecardFeatures;
