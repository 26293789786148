import BulletPoint from './BulletPoint';
import './InputValidationText.css';

const InputValidationText = ({ text }) => {
  return (
    <ul className='input-validation-text'>
      {text?.map?.((listItem, index) => (
        <li key={index}>
          {text.length > 1 && <BulletPoint />}

          <span>{`${listItem}`}</span>
        </li>
      ))}
    </ul>
  );
};

export default InputValidationText;
