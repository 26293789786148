function updateAdminSections(command) {
  const { add, del, edit } = command;

  if (add) {
    const {
      type,
      adminSections,
      setAdminSections,
      sectionName,
      setSectionName,
      control,
      setControl,
      isRequired,
      setIsRequired,
      dropdownList,
      setDropdownList,
      currentlyEditing,
      setCurrentlyEditing,
      setShouldScrollToBtm,
    } = add;

    const requiredOrOptional = isRequired ? 'Required' : 'Optional';

    let newControl = control === 'Drop down' ? 'DD: ' : control;

    if (control === 'Drop down')
      newControl = dropdownList
        ? 'DD: ' + dropdownList.split(',')[2]
        : 'DD: no list set';
    else newControl = control;

    let overwrittenRef = '';
    let overwrittenSec = '';

    const copiedAdminSections = adminSections.map((section, index) => {
      if (section.uniqueRef === currentlyEditing) {
        const { uniqueRef, title } = section;

        overwrittenRef = uniqueRef;
        overwrittenSec = title;

        let ddListId = '';
        let ddListTable = '';
        let ddListName = '';

        if (dropdownList) {
          [ddListId, ddListTable, ddListName] = dropdownList.split(',');
        }

        return {
          ...section,
          type: type,
          uniqueRef: uniqueRef,
          title: sectionName,
          control: newControl,
          required: requiredOrOptional,
          originalRequired: isRequired,
          originalControl: control,
          originalDdList: ddListName,
          originalDdListId: ddListId,
          originalDdListTable: ddListTable,
          id: index,
        };
      } else return { ...section, id: index };
    });
    if (overwrittenRef) setCurrentlyEditing('');
    else {
      const newUniqueRef = `${Math.floor(
        Math.random() * 100000000000
      )}${Date.now()}`;

      let ddListId = '';
      let ddListTable = '';
      let ddListName = '';

      if (dropdownList) {
        [ddListId, ddListTable, ddListName] = dropdownList.split(',');
      }

      copiedAdminSections.push({
        type: type,
        uniqueRef: newUniqueRef,
        title: sectionName,
        control: newControl,
        required: requiredOrOptional,
        originalRequired: isRequired,
        originalControl: control,
        originalDdList: ddListName,
        originalDdListId: ddListId,
        originalDdListTable: ddListTable,
        id: copiedAdminSections.length,
      });
      setShouldScrollToBtm(true);
    }

    setAdminSections(copiedAdminSections);
    setSectionName('');
    setControl('');
    setDropdownList('');
    setIsRequired(false);

    return { overwrittenRef: overwrittenRef, overwrittenSec: overwrittenSec };
  } else if (del) {
    const {
      adminSections,
      setAdminSections,
      sectionUniqueRef,
      currentlyEditing,
      setCurrentlyEditing,
    } = del;

    if (currentlyEditing === sectionUniqueRef) {
      setCurrentlyEditing('');
    }

    const copiedAdminSections = adminSections.filter((section) => {
      return section.uniqueRef !== sectionUniqueRef;
    });

    setAdminSections(copiedAdminSections);
  } else if (edit) {
    const {
      sectionToEdit,
      currentlyEditing,
      setCurrentlyEditing,
      setSectionName,
      setControl,
      setDropdownList,
      setIsNewListChecked,
      setIsRequired,
    } = edit;

    const {
      uniqueRef,
      title,
      originalControl,
      originalDdList,
      originalDdListId,
      originalDdListTable,
      originalRequired,
    } = sectionToEdit;

    if (currentlyEditing === uniqueRef) setCurrentlyEditing('');
    else {
      const hasDropDownList =
        originalDdListId && originalDdListTable && originalDdList;

      setCurrentlyEditing(uniqueRef);
      setIsNewListChecked(false);
      setSectionName(title);
      setControl(originalControl);
      setDropdownList(
        hasDropDownList
          ? `${originalDdListId},${originalDdListTable},${originalDdList}`
          : ''
      );
      setIsRequired(originalRequired);
    }
  }
}

export default updateAdminSections;
