import { handleApiError } from '../../../../utils/error-handling';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  validateEmail,
  validatePresenceRequired,
} from '../../../../utils/helpers';

import apiDataServiceBilling from '../../../../api/billing';
import ButtonStandardNoFill from '../../../Elements/ButtonStandardNoFill';
import InputStandard from '../../../Elements/InputStandard';
import SelectStandard from '../../../Elements/SelectStandard';
import Text from '../../../Elements/Text';

const ManualPayments = ({
  customer,
  isLoading,
  portalId,
  setIsLoading,
  userId,
}) => {
  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [billingEmail, setBillingEmail] = useState(customer?.email || '');
  const [billingEmailInvalid, setBillingEmailInvalid] = useState();
  const [billingDate, setBillingDate] = useState('1');
  const [showInvalid, setShowInvalid] = useState(false);

  const handleAction = async () => {
    setShowInvalid(true);

    if (billingEmailInvalid || !billingEmail)
      return alert.error(`Invalid email`);

    try {
      setIsLoading(true);

      await apiDataServiceBilling.postSubscription({
        portalId,
        reqBody: {
          billing: { billingDate, email: billingEmail },
          userId,
        },
        token: await getAccessTokenSilently(),
      });

      setIsLoading(false);

      navigate(`/${portalId}/return?email=${billingEmail}`, {
        state: { isNavigating: true },
      });
    } catch (error) {
      setIsLoading(false);

      alert.error(handleApiError(error), { timeout: 10000 });
    }
  };

  useEffect(() => {
    const val = validatePresenceRequired(billingEmail, "'Email'");

    if (val) setBillingEmailInvalid([val]);
    else
      setBillingEmailInvalid(
        validateEmail(billingEmail) ? null : ['Invalid email address']
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingEmail]);

  return (
    <>
      <span style={{ marginBottom: '10px', textAlign: 'center' }}>
        <Text text='You will be invoiced monthly based on the number of users you have and their tiers. See ' />

        <Link target='_blank' to='/pricing'>
          qaportal.co.uk/pricing
        </Link>

        <Text text=' for more information.' />
      </span>

      <Text
        marginBottom='20px'
        text="The estimated cost of your QA Portal (based on current users) is displayed on this screen under 'Next Invoice'."
        textAlign='center'
      />

      <InputStandard
        classes='input-bill-email'
        direction='vertical'
        disabled={isLoading || customer?.email}
        id='input-bill-email'
        invalid={billingEmailInvalid}
        isRequired
        labelText='Email'
        marginBottom='10px'
        onChange={(event) => {
          setBillingEmail(event.target.value);
        }}
        placeholder='Email'
        showInvalid={showInvalid}
        value={billingEmail}
      />

      <SelectStandard
        disabled={isLoading}
        id='select-bill-date'
        labelText='Invoice date'
        marginBottom='10px'
        onChange={(event) => setBillingDate(event.target.value)}
        options={[
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
        ]}
        value={billingDate}
      />

      <Text
        marginBottom='10px'
        textAlign='center'
        text='Payments are due 30 days from the date of the invoice being produced.'
      />

      <ButtonStandardNoFill
        marginTop='10px'
        alignSelf='center'
        disabled={isLoading || !billingEmail}
        iconName='save-solid'
        onClick={handleAction}
        text='Subscribe'
        typeStyle='type-4'
      />
    </>
  );
};

export default ManualPayments;
