import { organiseColumns } from '../../../../utils/helpers';
import { useEffect, useState } from 'react';

import DraggableToggleList from '../../../Elements/DraggableToggleList';
import ExpandableSection from '../../../Elements/ExpandableSection';

import './Sort.css';

const Sort = ({
  disabledDueToEditingText,
  isEditing,
  onUpdateOptions,
  setSortColumns,
  showSort,
  sortBy,
  sortColumns,
}) => {
  const handleToggleSort = (column, direction) => {
    if (direction !== '' && direction !== '-') {
      setSortColumns((currentColumns) => {
        const newColumns = currentColumns.map((columnObj) => {
          if (columnObj.id === column)
            return {
              ...columnObj,
              toggled: !columnObj.toggled,
              direction: '',
            };
          else return columnObj;
        });

        return organiseColumns(newColumns);
      });
    } else {
      setSortColumns((currentColumns) =>
        currentColumns.map((columnObj) => {
          if (columnObj.id === column)
            return {
              ...columnObj,
              direction: columnObj.direction === '' ? '-' : '',
            };
          else return columnObj;
        })
      );
    }
  };

  const sortColumnsToSortBy = (columns) => {
    return (
      columns
        .filter((columnObj) => columnObj.toggled)
        .map((columnObj) => `${columnObj.direction}${columnObj.id}`)
        .join(',,') || null
    );
  };

  const [dndSortColumns, setDndSortColumns] = useState();
  const [isMovingSortColumns, setIsMovingSortColumns] = useState();

  useEffect(() => {
    setDndSortColumns(sortColumns);

    const newSortBy = sortColumnsToSortBy(sortColumns);

    if (newSortBy !== sortBy) onUpdateOptions('sortBy', newSortBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumns]);

  useEffect(() => {
    const debounceHandleDnd = setTimeout(() => {
      if (isMovingSortColumns) {
        setSortColumns(dndSortColumns);
        setIsMovingSortColumns(false);
      }
    }, 350);

    return () => clearTimeout(debounceHandleDnd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dndSortColumns]);

  return (
    <>
      <ExpandableSection
        isOpen={showSort}
        containerClasses={showSort ? 'grid-options-background' : ''}
      >
        <DraggableToggleList
          cards={dndSortColumns}
          disabled={isEditing}
          disabledText={isEditing ? disabledDueToEditingText : undefined}
          setCards={setDndSortColumns}
          setIsMoving={setIsMovingSortColumns}
          itemType='sort-item'
          handleToggle={handleToggleSort}
          optionIconToggleClasses='data-grid-sort-option-icon'
          optionIconToggle={{
            cardParam: 'direction',
            icons: ['sort-up', 'sort-down'],
            options: ['', '-'],
            text: ['Ascending', 'Descending'],
          }}
          toggleHeight={40}
          uniqueSectionId='sort'
        />
      </ExpandableSection>
    </>
  );
};

export default Sort;
